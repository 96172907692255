import React from 'react';
import { REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH } from 'lib/routerPaths';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import PlatformClosureForm from 'components/registrar/PlatformClosureForm';
import PlatformClosureWarning from 'components/registrar/PlatformClosureWarning';

function PlatformClosureCreatePage() {
  return (
    <RegistrarTemplate>
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Settings', href: '/registrar/settings' },
                { label: 'Platform Closures', href: REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH },
                { label: 'Schedule Closure' },
              ]}
            />

            <h1 className="mb-0">Schedule Platform Closure</h1>
          </div>
        </div>
      </div>

      <div className="flush px-4 my-4">
        <PlatformClosureWarning />

        <PlatformClosureForm />
      </div>
    </RegistrarTemplate>
  );
}

export default PlatformClosureCreatePage;
