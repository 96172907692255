import React from 'react';

function Checkmark() {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMinYMin meet" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" fill="currentColor" d="M20.782 5.47a.75.75 0 0 1 0 1.061l-10.5 10.5a.75.75 0 0 1-1.062 0l-5.25-5.25a.751.751 0 0 1 1.062-1.062l4.719 4.72 9.969-9.97a.75.75 0 0 1 1.062 0z" />
    </svg>
  );
}

export default Checkmark;
