import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { CURRICULUM_COURSE_SHOW_PATH } from 'lib/routerPaths';
import { buildRoutePath } from 'lib/routerHelpers';
import { SHORT_DATE } from 'lib/dateFormats';
import { CoursePropType } from 'lib/propTypes';
import { selectCourseTypeName } from 'store/courseTypes/selectors';
import Button from 'react-bootstrap/Button';
import Badge from 'components/shared/Badge/Badge';
import CourseDropDown from '../CourseDropDown';
import './styles.scss';

function CourseRow({ course, setShowGroupsCourseId }) {
  const courseTypeName = useSelector((state) => selectCourseTypeName(state, course.courseTypeId));
  const workspace = useWorkspace();
  const handleGroupsClick = useCallback((e) => {
    e.target.blur();
    setShowGroupsCourseId(course.id);
  }, [course.id, setShowGroupsCourseId]);
  const courseLink = useMemo(() => {
    const linkClass = 'link-dark text-decoration-none';
    const linkText = courseTypeName;

    return (<Link className={linkClass} to={buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course?.id })}>{linkText}</Link>);
  }, [course?.id, courseTypeName]);

  if (!course) return null;

  return (
    <tr className="table-row" key={course.id}>
      <td className="course-name">
        {courseLink}
        <span className="d-block fw-normal text-muted">{course.description}</span>
      </td>
      <td>{format(parseISO(course.createdAt), SHORT_DATE)}</td>
      <td>
        {!course.groupIds?.length && (
          <span className="text-muted">0 groups</span>
        )}
        {course.groupIds?.length > 0 && (
          <Button variant="primary" className="btn-plain p-0" onClick={handleGroupsClick}>
            {`${course.groupIds?.length} groups`}
          </Button>
        )}
      </td>
      <td className="status"><Badge title={course.status} /></td>
      <td>
        <div className="row">
          <div>
            {formatDistanceToNow(parseISO(course.updatedAt), { addSuffix: true })}
          </div>
        </div>
      </td>
      <td>
        {workspace.id !== 'facilitator' && <CourseDropDown course={course} />}
      </td>
    </tr>
  );
}

CourseRow.defaultProps = {
  course: null,
};

CourseRow.propTypes = {
  course: CoursePropType,
  setShowGroupsCourseId: PropTypes.func.isRequired,
};

export default CourseRow;
