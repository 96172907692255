import React from 'react';
import parseISO from 'date-fns/parseISO';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { LANGUAGE_PREFERENCES } from 'lib/constants';
import { DT_IMPORTANT, TZ_PACIFIC } from 'lib/dateFormats';
import { GroupPropType } from 'lib/propTypes';
import { ListGroup } from 'react-bootstrap';

const KEY_DATES = {
  expireReservationsAt: { label: 'Expire Reservations', description: 'If the group has reservations, this is the date the reservations expire.' },
  closeRegistrationAt: { label: 'Close Registration', description: 'Registration closes. The current copy of the course is assigned 2 days before registration closes. Participants are assigned to pods.' },
  sendParticipantListsAt: { label: 'Send Participant Lists', description: 'Send facilitators the participant list, so they can flag any conflicts of interest.' },
  sendWelcomePacksAt: { label: 'Send Welcome Packs', description: 'Send the participants the Welcome Pack email.' },
  beginSessionAt: { label: 'Begin Session', description: 'Participants can log in and start the course.' },
  closeSessionAt: { label: 'Close Session', description: 'The group closes. Participants can no longer do any course work.' },
  certificateAvailableAt: { label: 'Certificate Available', description: 'The participants can log in and download their course certificate.' },
};

function GroupSettingsTabPage({ group }) {
  return (
    <div className="row">
      <div className="col-md-8 pt-2">
        <h2>Basic Information</h2>

        <dl>
          <dt>Group name</dt>
          <dd>{group.name}</dd>
          <dt>Preferred language</dt>
          <dd>{group.isAvailableInFrench ? LANGUAGE_PREFERENCES[group.languagePreference] : LANGUAGE_PREFERENCES.en}</dd>
          <dt>Max participants</dt>
          <dd>{group.maxParticipants}</dd>
        </dl>

        <h2>Key Dates</h2>

        <ListGroup variant="flush">
          {Object.entries(KEY_DATES).map(([key, { label, description }]) => {
            const date = group[key] ? parseISO(group[key]) : null;

            return (
              <ListGroup.Item key={key} className="align-items-start px-0">
                <div className="fw-semibold">
                  {label}
                  <span className="ps-2 fs-3">{date ? formatInTimeZone(date, TZ_PACIFIC, DT_IMPORTANT) : 'Not set'}</span>
                </div>
                <small className="text-secondary d-block">{description}</small>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </div>

      <div className="col-md-4 col-xl-3 offset-xl-1">
        <dl className="my-3 mt-md-0 border rounded bg-light px-3 py-2">
          <dt>HA Reservations</dt>
          <dd>{group.reservationsState === 'res_active' ? 'Active' : 'Expired'}</dd>
          <dt>Total Reserved Seats</dt>
          <dd>{group.reservedSeatsCount}</dd>
        </dl>
      </div>
    </div>
  );
}

GroupSettingsTabPage.propTypes = {
  group: GroupPropType.isRequired,
};

export default GroupSettingsTabPage;
