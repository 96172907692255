import { delay, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_TIMEOUT } from 'lib/constants';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, SET_NOTIFICATION } from 'store/flashNotifications/actions';

function* setNotification({ payload }) {
  const { message, type, timeout = DEFAULT_TIMEOUT } = payload;
  const id = uuidv4();

  yield put(ADD_NOTIFICATION.action({ id, message, type, timeout }));

  if (timeout > 0) {
    yield delay(timeout);
    yield put(REMOVE_NOTIFICATION.action(id));
  }
}

export default function* sagas() {
  // Called from other Sagas to initiate a notification
  yield takeEvery(SET_NOTIFICATION.SYNC, setNotification);
}
