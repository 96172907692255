import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { TaskPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_TASK_PATH, TASK_EDIT_PATH } from 'lib/routerPaths';
import { toLetter } from 'lib/utils';
import { RESET_TASK } from 'store/courses/actions';
import { Draggable } from '@hello-pangea/dnd';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import SanyasIcon from 'components/shared/SanyasIcon';
import { useWorkspace } from 'lib/hooks/useWorkspace';

const TASK_PATHS = {
  facilitator: FACILITATOR_TASK_PATH,
  curriculum: TASK_EDIT_PATH,
};

/* eslint-disable react/jsx-props-no-spreading */

function ModuleTask({ task, index }) {
  const { id: workspaceId } = useWorkspace();
  const dispatch = useDispatch();
  const history = useHistory();
  const { course, currentTask } = useCourseShowContext();

  const handleClick = useCallback(() => {
    dispatch(RESET_TASK.action());
    history.push(buildRoutePath(TASK_PATHS[workspaceId], { courseId: course?.id, taskId: task?.id }));
  }, [task?.id, course?.id, workspaceId, dispatch, history]);

  if (!task?.id) return null;

  return (
    <Draggable draggableId={`${task.id}`} index={index} isDragDisabled={course?.isLocked}>
      {(provided, snapshot) => (
        <li
          className={`list-group-item d-flex align-items-center border-0 ${task.id === currentTask?.id || snapshot.isDragging ? 'list-group-item-active' : ''}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {!course?.isLocked && (
            <span
              className={`cursor-grab p-1 outline-visible ${snapshot.isDragging ? 'visible' : ''}`}
              {...provided.dragHandleProps}
            >
              <SanyasIcon name="gripVertical" style={{ width: '10px' }} />
              <span className="sr-only">Drag to reorder</span>
            </span>
          )}

          <button
            type="button"
            className="task w-100 border-0 text-start font-weight-semibold"
            style={{ backgroundColor: 'transparent' }}
            onClick={handleClick}
          >
            <span className="accordion-position">{`${toLetter(index + 1).toUpperCase()}. `}</span>
            {task.name}
            <br />
            <small className="text-muted fw-normal">{task.summary}</small>
          </button>
        </li>
      )}
    </Draggable>
  );
}

ModuleTask.defaultProps = {
  task: null,
};

ModuleTask.propTypes = {
  task: TaskPropType,
  index: PropTypes.number.isRequired,
};

export default ModuleTask;
