import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { REDUX_STATUS, GROUP_ACTIVE_STATES } from 'lib/constants';
import usePrevious from 'lib/hooks/usePrevious';
import { GroupPropType } from 'lib/propTypes';
import { LOAD_EMAIL_TEMPLATES } from 'store/emailTemplates/actions';
import { selectEmailTemplateTypeByContext } from 'store/emailTemplates/selectors';
import Alert from 'react-bootstrap/Alert';
import SanyasIcon from 'components/shared/SanyasIcon';
import Spinner from 'components/shared/Spinner';
import EmailTemplatesListItem from 'components/shared/EmailTemplates/ListItem';
import EmailTemplateForm from 'components/shared/EmailTemplates/Form';

function GroupEmailTemplatesTabPage(props) {
  const { group } = props;

  const dispatch = useDispatch();
  const emailTemplates = useSelector((state) => state.emailTemplates.templates);
  const emailTemplateTypes = useSelector((state) => selectEmailTemplateTypeByContext(state, 'Course'));
  const emailTemplateTypesStatus = useSelector((state) => state.emailTemplates.types.status);
  const prevEmailTemplatesStatus = usePrevious(emailTemplates.status);
  const prevEmailTemplateTypesStatus = usePrevious(emailTemplateTypesStatus);
  const [selectedTemplate, setSelectedTemplate] = useState(-1);

  const emailTemplatesLoaded = [REDUX_STATUS.PENDING, REDUX_STATUS.SUCCESS].includes(prevEmailTemplatesStatus)
    && emailTemplates.status === REDUX_STATUS.SUCCESS;
  const emailTemplateTypesLoaded = [REDUX_STATUS.PENDING, REDUX_STATUS.SUCCESS].includes(prevEmailTemplateTypesStatus)
    && emailTemplateTypesStatus === REDUX_STATUS.SUCCESS;

  const activeEmailTemplateTypeIds = emailTemplates.data.map((template) => template.emailTemplateTypeId);
  const activeTemplateTypes = emailTemplateTypes.filter((type) => activeEmailTemplateTypeIds.includes(type.id));
  const moreTemplateTypes = emailTemplateTypes.filter((type) => !activeEmailTemplateTypeIds.includes(type.id));

  useEffect(() => {
    dispatch(LOAD_EMAIL_TEMPLATES.request({ entityId: group.id, context: 'groups', contextType: 'Group' }));
  }, [group.id, dispatch]);

  useEffect(() => {
    if (emailTemplatesLoaded && emailTemplateTypesLoaded && selectedTemplate === -1) {
      setSelectedTemplate(activeTemplateTypes?.[0]?.id || moreTemplateTypes?.[0]?.id);
    }
  }, [emailTemplatesLoaded, emailTemplateTypesLoaded, selectedTemplate, activeTemplateTypes, moreTemplateTypes]);

  useEffect(() => {
    dispatch(LOAD_EMAIL_TEMPLATES.request({ entityId: group.id, context: 'groups', contextType: 'Group' }));
  }, [group.id, dispatch]);

  return (
    <div style={{ margin: '-1.5rem -0.75rem' }}>
      {(!GROUP_ACTIVE_STATES.includes(group.state) || !group.courseId) && (
        <Alert variant="warning" className="w-75 my-5 mx-auto">
          {/* `my-5` above is large-ish to counter the negative margin for the layout */}
          <span className="fas fa-info-circle fa-fw me-1" />
          Email templates for this group have not yet been applied. When this
          group&rsquo;s course is assigned, email templates will be available
          to modify. Please check back after the course has been assigned.
        </Alert>
      )}

      {(GROUP_ACTIVE_STATES.includes(group.state) && group.courseId) && (
        <div className="row">
          <div className="col-6 border-end pb-4 px-0" style={{ maxWidth: '40rem' }}>
            {/* Min-height to match next column header w/ button */}
            <div className="d-flex align-items-center py-3 px-4 border-bottom" style={{ minHeight: '4rem' }}>
              <h4 className="m-0">
                <SanyasIcon name="envelope" className="align-top me-2" />
                Email Templates
              </h4>
            </div>

            {emailTemplates.status === REDUX_STATUS.PENDING && <Spinner />}

            {activeTemplateTypes.length > 0 && (
              <ul className="list-group list-group-flush">
                {activeTemplateTypes.map((template) => (
                  <EmailTemplatesListItem key={template.id} template={template} setSelectedTemplate={setSelectedTemplate} active />
                ))}
              </ul>
            )}

            {moreTemplateTypes.length > 0 && (
              <>
                {activeTemplateTypes.length > 0 && (
                  <div className="d-flex align-items-center py-3 px-4 border-bottom">
                    <h4 className="m-0">
                      More email templates available
                    </h4>
                  </div>
                )}

                <ul className="list-group list-group-flush">
                  {moreTemplateTypes.map((template) => (
                    <EmailTemplatesListItem key={template.id} template={template} setSelectedTemplate={setSelectedTemplate} />
                  ))}
                </ul>
              </>
            )}
          </div>

          <div className="col px-0">
            {selectedTemplate > -1 && (
              <EmailTemplateForm template={selectedTemplate} context="groups" setSelectedTemplate={setSelectedTemplate} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

GroupEmailTemplatesTabPage.propTypes = {
  group: GroupPropType.isRequired,
};

export default withRouter(GroupEmailTemplatesTabPage);
