import { useLocation } from 'react-router-dom';
import { sentenceCase } from 'lib/utils';

export function useWorkspace() {
  const { pathname } = useLocation();
  const workspace = pathname.split('/')?.[1];

  return {
    id: workspace,
    path: `/${workspace}`,
    name: sentenceCase(workspace),
  };
}
