import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { LOCALES, TASK_TYPES } from 'lib/constants';
import { getLocaleSuffix } from 'lib/utils';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import Button from 'react-bootstrap/Button';
import SanyasIcon from 'components/shared/SanyasIcon';
import TextareaField from 'components/shared/FormFields/TextareaField';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';

function OptionsTable({ index, locale, options, hasError }) {
  // Note: hasError is specific to the correct answer field
  // If hasError refers to anything more than that, this component will need to be refactored
  const { currentTask } = useCourseShowContext();
  const { values, setFieldValue } = useFormikContext();
  const { type: taskType } = values;

  const handleCorrectOption = useCallback((event) => {
    const currentIndex = options.findIndex((option) => option.correct);
    setFieldValue(`questions[${index}].options[${currentIndex}].correct`, false);

    const newIndex = options.findIndex((option) => String(option.id) === event.target.value);
    setFieldValue(`questions[${index}].options[${newIndex}].correct`, true);
  }, [options, index, setFieldValue]);

  const handleRemove = useCallback((optionIndex) => {
    // If the option being deleted is the correct answer, set the first option as the correct answer
    // At least one correct answer is required, for Multiple Choice Quiz questions
    if (options[optionIndex].correct) {
      setFieldValue(`questions[${index}].options[0].correct`, true);
    }

    // If the option being deleted is the only option, just clear the body
    // At least one option is required
    if (options?.filter((option) => !option._destroy).length === 1) { // eslint-disable-line no-underscore-dangle
      setFieldValue(`questions[${index}].options[${optionIndex}].body`, '');
      setFieldValue(`questions[${index}].options[${optionIndex}].bodyFr`, '');
      return;
    }

    setFieldValue(`questions[${index}].options[${optionIndex}]`, { ...options[optionIndex], _destroy: true });
  }, [options, index, setFieldValue]);

  if (options?.filter((option) => !option._destroy).length < 1) return null; // eslint-disable-line no-underscore-dangle

  return (
    <table className="options-table">
      <thead>
        <tr>
          <th style={{ width: '1rem' }}>{}</th>
          <th style={{ height: '1rem', width: '80%' }}>{}</th>
          {taskType === TASK_TYPES.MULTIPLE_CHOICE_QUIZ && (
            <>
              <th
                className={`text-center text-nowrap px-3 ${hasError ? 'border border-danger border-bottom-0 text-danger' : ''}`}
              >
                Correct answer
              </th>
              <th style={{ width: '2rem' }}>{}</th>
            </>
          )}
          {taskType === TASK_TYPES.SURVEY && (
            <th>{}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {options?.map((option, optionIndex) => {
          if (option._destroy) return null; // eslint-disable-line no-underscore-dangle
          let errorBorder = hasError ? 'border border-danger border-top-0' : '';
          errorBorder += (hasError && optionIndex < options.length - 1) ? ' border-bottom-0' : '';

          return (
            <tr key={option.id}>
              <td>{}</td>
              <td>
                <TextareaField
                  hideLabel
                  name={`questions[${index}].options[${optionIndex}].body${getLocaleSuffix(locale)}`}
                />
              </td>

              {taskType === TASK_TYPES.MULTIPLE_CHOICE_QUIZ && (
                <td className={`text-center ${errorBorder}`}>
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={option.correct}
                    name={`questions[${index}].correctAnswer${getLocaleSuffix(locale)}`}
                    value={option.id}
                    onChange={handleCorrectOption}
                  />
                </td>
              )}

              {!currentTask?.isLocked && (
                <td className="text-end">
                  <SimpleToolTip text="Delete" placement="top">
                    <Button
                      variant="secondary"
                      size="sm"
                      className="icon-background btn-plain"
                      title="Delete question"
                      onClick={() => handleRemove(optionIndex)}
                    >
                      <SanyasIcon name="trashCan" />
                    </Button>
                  </SimpleToolTip>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

OptionsTable.defaultProps = {
  locale: 'en',
  options: [],
};

OptionsTable.propTypes = {
  index: PropTypes.number.isRequired,
  locale: PropTypes.oneOf(Object.values(LOCALES)),
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    body: PropTypes.string,
    bodyFr: PropTypes.string,
    correct: PropTypes.bool,
  })),
  hasError: PropTypes.bool.isRequired,
};

export default OptionsTable;
