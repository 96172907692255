import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'components/shared/Pagination';
import VoucherLogItem from './VoucherLogItem';

function VoucherLogs({ items }) {
  const [page, setPage] = useState(1);
  const PAGE_MAX = 10;
  const totalPages = useMemo(() => Math.max(Math.ceil(items.length / PAGE_MAX), 1), [items]);
  const pagedItems = useMemo(() => [...items].slice(((page - 1) * PAGE_MAX), page * PAGE_MAX), [items, page]);

  return (
    <div className="mt-4">
      {items.length > 0 && <p className="mb-0">{`Latest ${items.length} changes of a max of 50`}</p>}
      {totalPages > 1 && <p className="mb-0">{`Page ${page} of ${totalPages}`}</p>}

      <ol className="list-group list-group-numbered mt-2">
        {items.length === 0 ? (
          <VoucherLogItem
            key={-1}
            name="No history found"
            createdAt=""
            invokedBy=""
            args={{}}
          />
        ) : pagedItems.map((i) => (
          <VoucherLogItem
            key={i.id}
            name={i.name}
            createdAt={i.createdAt}
            invokedBy={i.invokedBy}
            args={i.args}
          />
        ))}
      </ol>

      {totalPages > 1 && (
        <Pagination
          page={page}
          totalPages={totalPages}
          onPageClick={setPage}
          window={1}
          previous="Newer"
          next="Older"
        />
      )}
    </div>
  );
}

VoucherLogs.defaultProps = {
  items: [],
};

VoucherLogs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    invokedBy: PropTypes.string,
    args: PropTypes.shape({}),
  })),
};

export default VoucherLogs;
