import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { COMPACT_DATE_TIME } from 'lib/dateFormats';
import { Alert } from 'react-bootstrap';

function EmailsTable({ emailConfirmations }) {
  return (
    <>
      {!emailConfirmations?.length && (
      <Alert variant="info">No email confirmations recorded for this person.</Alert>
      )}

      {emailConfirmations?.length > 0 && (
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Recipient</th>
              <th>Subject</th>
              <th>Status</th>
              <th>Date Sent</th>
            </tr>
          </thead>
          <tbody>
            { emailConfirmations.map((ec) => (
              <tr key={ec.id}>
                <td>{ec.to}</td>
                <td>{ec.subject}</td>
                <td>{ec.status}</td>
                <td className="text-nowrap">
                  {format(parseISO(ec.createdAt), COMPACT_DATE_TIME)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <p className="mt-4">
          Status details:
          <br />
          <strong>Sent</strong>
          {' '}
          - the email was sent
          <br />
          <strong>Opened</strong>
          {' '}
          - Postmark was able to detect that the participant read the email *
          <br />
          <strong>Error</strong>
          {' '}
          - the email could not be sent and Postmark stopped trying to send it
          <br />
          <br />
          <small>
            * Postmark cannot track all emails as Opened even if they are,
            because some participants may have privacy features active in their email clients.
          </small>
          <br />
          <small>
            ** Email confirmations started being recorded on Jul 26th, 2018.
          </small>
        </p>
      </>
      )}
    </>
  );
}

EmailsTable.defaultProps = {
  emailConfirmations: [],
};

EmailsTable.propTypes = {
  emailConfirmations: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string,
    from: PropTypes.string,
    id: PropTypes.number,
    status: PropTypes.string,
    subject: PropTypes.string,
    to: PropTypes.string,
    userId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    groupMembershipId: PropTypes.number,
  })),
};

export default EmailsTable;
