import { fetchGet, handleSimpleFetchError } from 'lib/apiHelpers';
import { put, takeLatest } from 'redux-saga/effects';
import { GET_EMAIL_CONFIRMATIONS } from 'store/actions';

export default function* sagas() {
  yield takeLatest(GET_EMAIL_CONFIRMATIONS.REQUEST, function* getEmailConfirmations({ payload: filters }) {
    try {
      const emailConfirmations = yield fetchGet('/api/registrar/email_confirmations', filters);
      yield put(GET_EMAIL_CONFIRMATIONS.success(emailConfirmations));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_EMAIL_CONFIRMATIONS);
    }
  });
}
