import { GET_HARM_PREVENTION_REPORTS, REQUEST_HARM_PREVENTION_REPORT } from 'store/actions';

const initialState = {
  loading: true,
  formLoading: true,
  filters: {},
  submitting: false,
  reports: {
    byId: {},
    allIds: [],
  },
  courseTypes: {
    byId: {},
    allIds: [],
  },
  formData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HARM_PREVENTION_REPORTS.SUCCESS:
      return {
        ...state,
        reports: {
          allIds: [...action.payload.reportIds],
          byId: { ...action.payload.reports },
        },
        loading: false,
      };
    case REQUEST_HARM_PREVENTION_REPORT.REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case REQUEST_HARM_PREVENTION_REPORT.SUCCESS:
      return {
        ...state,
        reports: {
          allIds: [...action.payload.reportIds, ...state.reports.allIds],
          byId: { ...state.reports.byId, ...action.payload.reports },
        },
        submitting: false,
        loading: false,
      };
    case REQUEST_HARM_PREVENTION_REPORT.FAILURE:
      return {
        ...state,
        submitting: false,
        loading: false,
      };
    default:
      return state;
  }
};
