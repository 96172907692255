import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns-tz/format';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { DT_LONG_Z, DT_LONG, TZ_ATLANTIC, TZ_PACIFIC } from 'lib/dateFormats';
import { REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH } from 'lib/routerPaths';

function PlatformClosureListItem({ event, editEvents }) {
  const history = useHistory();
  const clickPath = `${REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH}/${event.id}${editEvents ? '/edit' : ''}`;
  const handleTrClick = useCallback(() => {
    history.push(clickPath);
  }, [clickPath, history]);

  return (
    <tr onClick={handleTrClick}>
      <td>{ formatInTimeZone(parseISO(event.closePlatformFrom), TZ_PACIFIC, DT_LONG_Z) }</td>
      <td>{ formatInTimeZone(parseISO(event.closePlatformUntil), TZ_ATLANTIC, DT_LONG_Z) }</td>
      <td>{ event.registrar }</td>
      <td>{ format(parseISO(event.createdAt), DT_LONG) }</td>
      <td>
        <Link to={clickPath}>
          <Button variant="outline-secondary" size="sm">
            View
            {editEvents ? '/Edit' : ''}
          </Button>
        </Link>
      </td>
    </tr>
  );
}

PlatformClosureListItem.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    closePlatformFrom: PropTypes.string,
    closePlatformUntil: PropTypes.string,
    message: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    registrar: PropTypes.string,
  }).isRequired,
  editEvents: PropTypes.bool.isRequired,
};

export default PlatformClosureListItem;
