import React from 'react';

function Unlock() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_246_5053)">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.4825 12H3.4995C3.1035 12 2.9025 12.102 2.793 12.1815C2.64461 12.2981 2.52887 12.451 2.457 12.6255C2.33316 12.9049 2.26294 13.2051 2.25 13.5105V21C2.25 21.513 2.364 21.7965 2.46 21.9525C2.556 22.1115 2.6865 22.2225 2.844 22.308C3.04674 22.4154 3.26929 22.4802 3.498 22.4985L3.5175 22.5H14.5005C14.8965 22.5 15.0975 22.398 15.207 22.3185C15.3554 22.2019 15.4711 22.049 15.543 21.8745C15.6668 21.5951 15.737 21.2949 15.75 20.9895V13.5C15.75 12.987 15.636 12.7035 15.54 12.5475C15.4467 12.3968 15.3146 12.274 15.1575 12.192C14.9485 12.0813 14.7185 12.0159 14.4825 12ZM14.5005 10.5H3.4995C0.75 10.5 0.75 13.5 0.75 13.5V21C0.75 24 3.4995 24 3.4995 24H14.5005C17.25 24 17.25 21 17.25 21V13.5C17.25 10.5 14.5005 10.5 14.5005 10.5ZM12.75 6C12.75 5.31056 12.8858 4.62787 13.1496 3.99091C13.4135 3.35395 13.8002 2.7752 14.2877 2.28769C14.7752 1.80018 15.354 1.41347 15.9909 1.14963C16.6279 0.885795 17.3106 0.75 18 0.75C18.6894 0.75 19.3721 0.885795 20.0091 1.14963C20.646 1.41347 21.2248 1.80018 21.7123 2.28769C22.1998 2.7752 22.5865 3.35395 22.8504 3.99091C23.1142 4.62787 23.25 5.31056 23.25 6V10.5H21.75V6C21.75 5.00544 21.3549 4.05161 20.6516 3.34835C19.9484 2.64509 18.9946 2.25 18 2.25C17.0054 2.25 16.0516 2.64509 15.3483 3.34835C14.6451 4.05161 14.25 5.00544 14.25 6V10.5H12.75V6Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_5053">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Unlock;
