import React from 'react';
import { REGISTRAR_VOUCHERS_PATH } from 'lib/routerPaths';
import { TrainingPaymentPropType, VoucherPropType } from 'lib/propTypes';
import { Alert, Badge, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const badgeBackground = (state) => {
  switch (state) {
    case 'successful':
      return 'success';
    case 'failed':
      return 'danger';
    case 'pending':
      return 'warning';
    case 'waived':
      return 'info';
    default:
      return 'dark';
  }
};

function PaymentDetails(props) {
  const { payment, voucher } = props;
  return (
    <>
      {(!payment && !voucher) && (
        <Alert variant="secondary" className="py-2">No payment details</Alert>
      )}

      {(payment || voucher) && (
        <ButtonGroup className="w-100" aria-label="Payment details and voucher">
          {payment && (
            <a className="btn btn-outline-secondary" href={`/registrar/payments/${payment.id}/edit`}>
              Payment
              <Badge className={`ms-2 ${payment.state === 'pending' ? 'text-dark' : ''}`} bg={badgeBackground(payment.state)} pill>{payment.state}</Badge>
            </a>
          )}
          {voucher && (
            <Link className="btn btn-outline-secondary" to={`${REGISTRAR_VOUCHERS_PATH}/${voucher.id}`}>
              Voucher
            </Link>
          )}
        </ButtonGroup>
      )}
    </>
  );
}

PaymentDetails.defaultProps = {
  payment: null,
  voucher: null,
};

PaymentDetails.propTypes = {
  payment: TrainingPaymentPropType,
  voucher: VoucherPropType,
};

export default PaymentDetails;
