import { replaceSingleObject } from 'lib/reducerHelpers';
import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import {
  LOAD_EMAIL_TEMPLATES,
  CREATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  RESET_EMAIL_TEMPLATES,
  LOAD_EMAIL_TEMPLATE_TYPES,
  RESET_EMAIL_TEMPLATE_TYPES,
  LOAD_EMAIL_TEMPLATE_TAGS,
  RESET_EMAIL_TEMPLATE_TAGS,
  UPDATE_EMAIL_TEMPLATE,
  REMOVE_EMAIL_TEMPLATE,
  RESET_EMAIL_TEMPLATE,
} from './actions';

const initialState = {
  tags: {
    status: REDUX_STATUS.IDLE,
    data: [],
    error: undefined,
  },
  templates: {
    status: REDUX_STATUS.IDLE,
    data: [],
    error: undefined,
  },
  template: {
    status: REDUX_STATUS.IDLE,
    id: undefined,
    data: {},
    error: undefined,
    success: undefined,
  },
  types: {
    status: REDUX_STATUS.IDLE,
    data: [],
    error: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EMAIL_TEMPLATES.REQUEST:
      return {
        ...state,
        templates: {
          ...state.templates,
          status: REDUX_STATUS.PENDING,
        },
      };
    case LOAD_EMAIL_TEMPLATES.SUCCESS: {
      if (action.meta.cached) return { ...state, templates: { ...state.templates, status: REDUX_STATUS.SUCCESS, error: undefined } };

      return {
        ...state,
        templates: {
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
          error: undefined,
        },
      };
    }
    case LOAD_EMAIL_TEMPLATES.ERROR:
      return {
        ...state,
        templates: {
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case CREATE_EMAIL_TEMPLATE.REQUEST:
      return {
        ...state,
        template: {
          ...state.template,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case CREATE_EMAIL_TEMPLATE.SUCCESS:
      return {
        ...state,
        template: {
          ...state.template,
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
        templates: {
          ...state.templates,
          data: [
            ...state.templates.data,
            action.payload,
          ],
        },
      };
    case CREATE_EMAIL_TEMPLATE.ERROR:
      return {
        ...state,
        template: {
          ...state.template,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case UPDATE_EMAIL_TEMPLATE.REQUEST:
      return {
        ...state,
        template: {
          ...state.template,
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case UPDATE_EMAIL_TEMPLATE.SUCCESS:
      return {
        ...state,
        template: {
          ...state.template,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
        templates: {
          ...state.templates,
          data: replaceSingleObject(state.templates.data, action.payload.id, action.payload),
        },
      };
    case UPDATE_EMAIL_TEMPLATE.ERROR:
      return {
        ...state,
        template: {
          ...state.template,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case DELETE_EMAIL_TEMPLATE.REQUEST:
      return {
        ...state,
        template: {
          ...state.template,
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case DELETE_EMAIL_TEMPLATE.SUCCESS:
      return {
        ...state,
        template: {
          ...state.template,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.DELETED,
        },
      };
    case DELETE_EMAIL_TEMPLATE.ERROR:
      return {
        ...state,
        template: {
          ...state.template,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case REMOVE_EMAIL_TEMPLATE.SYNC:
      return {
        ...state,
        template: {
          ...initialState.template,
        },
        templates: {
          ...state.templates,
          data: state.templates.data.filter((template) => template.id !== action.payload),
          status: REDUX_STATUS.IDLE,
          error: undefined,
        },
      };

    case RESET_EMAIL_TEMPLATE.SYNC:
      return {
        ...state,
        template: {
          ...initialState.template,
        },
      };

    case RESET_EMAIL_TEMPLATES.SYNC:
      return {
        ...state,
        templates: {
          ...initialState.templates,
        },
      };

    case LOAD_EMAIL_TEMPLATE_TYPES.REQUEST:
      return {
        ...state,
        types: {
          ...state.types,
          status: REDUX_STATUS.PENDING,
        },
      };
    case LOAD_EMAIL_TEMPLATE_TYPES.SUCCESS: {
      if (action.meta.cached) return { ...state, types: { ...state.types, status: REDUX_STATUS.SUCCESS, error: undefined } };

      return {
        ...state,
        types: {
          ...state.types,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
          error: undefined,
        },
      };
    }
    case LOAD_EMAIL_TEMPLATE_TYPES.ERROR:
      return {
        ...state,
        types: {
          ...state.types,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_EMAIL_TEMPLATE_TYPES.SYNC:
      return {
        ...state,
        types: {
          ...initialState.types,
        },
      };

    case LOAD_EMAIL_TEMPLATE_TAGS.REQUEST:
      return {
        ...state,
        tags: {
          ...state.tags,
          status: REDUX_STATUS.PENDING,
        },
      };
    case LOAD_EMAIL_TEMPLATE_TAGS.SUCCESS: {
      if (action.meta.cached) return { ...state, tags: { ...state.tags, status: REDUX_STATUS.SUCCESS, error: undefined } };

      return {
        ...state,
        tags: {
          ...state.tags,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
          error: undefined,
        },
      };
    }
    case LOAD_EMAIL_TEMPLATE_TAGS.ERROR:
      return {
        ...state,
        tags: {
          ...state.tags,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_EMAIL_TEMPLATE_TAGS.SYNC:
      return {
        ...state,
        tags: {
          ...initialState.tags,
        },
      };

    default:
      return state;
  }
};
