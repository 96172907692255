import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_VOUCHER_PAGES, SORT_VOUCHERS } from 'store/vouchers/actions';
import { Spinner } from 'react-bootstrap';
import Pagination from 'components/shared/Pagination';
import VouchersListItem from 'components/registrar/Vouchers/VouchersListItem';

function VouchersList() {
  const { submitting, voucherIds, currentPage, totalPages } = useSelector((state) => state.vouchers);
  const dispatch = useDispatch();

  const handlePageClick = useCallback((page) => dispatch(GET_VOUCHER_PAGES.request(page)), [dispatch]);
  const requestSort = useCallback(({ key, direction }) => {
    const sortedBy = {
      sorted_by: `${key}_${direction === 'descending' ? 'desc' : 'asc'}`,
    };
    dispatch(SORT_VOUCHERS.request(sortedBy));
  }, [dispatch]);

  const [sortConfig, setSortConfig] = useState({ key: 'email', direction: 'ascending' });

  const setSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    requestSort({ key, direction });
  };

  const getClassNamesFor = useCallback((name) => {
    if (!sortConfig) {
      return null;
    }
    return sortConfig.key === name ? `${sortConfig.direction} btn btn-link p-0` : 'btn btn-link p-0';
  }, [sortConfig]);

  const getIconFor = useCallback((name) => {
    if (sortConfig.key === name) {
      return sortConfig.direction === 'ascending' ? <i className="fas fa-sort-up" /> : <i className="fas fa-sort-down" />;
    }
    return <i className="fas fa-sort" />;
  }, [sortConfig]);

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="text-muted text-center">
            {`Page ${currentPage} of ${totalPages}`}
          </div>
        </div>
      </div>

      <div className="row my-5">
        <div className="col">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>
                    <button type="button" className={getClassNamesFor('email')} onClick={() => setSort('email')}>
                      Person
                      {' '}
                      {getIconFor('email')}
                    </button>
                  </th>
                  <th>
                    <button type="button" className={getClassNamesFor('state')} onClick={() => setSort('state')}>
                      Status
                      {' '}
                      {getIconFor('state')}
                    </button>
                  </th>
                  <th>
                    <button type="button" className={getClassNamesFor('markAs')} onClick={() => setSort('markAs')}>
                      Marked As
                      {' '}
                      {getIconFor('markAs')}
                    </button>
                  </th>
                  <th>
                    <button type="button" className={getClassNamesFor('courseType')} onClick={() => setSort('courseType')}>
                      Course Type
                      {' '}
                      {getIconFor('courseType')}
                    </button>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {submitting
                  ? (
                    <tr>
                      <td colSpan="5">
                        <div className="d-flex justify-content-center align-items-center">
                          Loading...
                          <Spinner animation="border" role="status" className="ms-2" />
                        </div>
                      </td>
                    </tr>
                  )
                  : voucherIds.map((id) => <VouchersListItem key={id} voucherId={id} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            onPageClick={handlePageClick}
            window={1}
          />
        </div>
      </div>
    </>
  );
}

export default VouchersList;
