import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REDUX_STATUS } from 'lib/constants';
import usePrevious from 'lib/hooks/usePrevious';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_SHOW_PATH, CURRICULUM_COURSE_TYPE_SHOW_PATH, CURRICULUM_PATH } from 'lib/routerPaths';
import { LOAD_COURSE } from 'store/courses/actions';
import { selectCourseById } from 'store/courses/selectors';
import { LOAD_COURSE_TYPES } from 'store/courseTypes/actions';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import { LOAD_EMAIL_TEMPLATES } from 'store/emailTemplates/actions';
import { selectEmailTemplateTypeByContext } from 'store/emailTemplates/selectors';
import CurriculumTemplate from 'components/templates/CurriculumTemplate';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import SanyasIcon from 'components/shared/SanyasIcon';
import EmailTemplatesListItem from 'components/shared/EmailTemplates/ListItem';
import Spinner from 'components/shared/Spinner';
import EmailTemplateForm from 'components/shared/EmailTemplates/Form';

function CourseEmailTemplatesPage() {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const course = useSelector((state) => selectCourseById(state, courseId));
  const courseType = useSelector((state) => selectCourseTypeById(state, course?.courseTypeId));
  const emailTemplates = useSelector((state) => state.emailTemplates.templates);
  const emailTemplateTypes = useSelector((state) => selectEmailTemplateTypeByContext(state, 'Course'));
  const emailTemplateTypesStatus = useSelector((state) => state.emailTemplates.types.status);
  const prevEmailTemplatesStatus = usePrevious(emailTemplates.status);
  const prevEmailTemplateTypesStatus = usePrevious(emailTemplateTypesStatus);
  const [selectedTemplate, setSelectedTemplate] = useState(-1);

  const emailTemplatesLoaded = [REDUX_STATUS.PENDING, REDUX_STATUS.SUCCESS].includes(prevEmailTemplatesStatus)
    && emailTemplates.status === REDUX_STATUS.SUCCESS;
  const emailTemplateTypesLoaded = [REDUX_STATUS.PENDING, REDUX_STATUS.SUCCESS].includes(prevEmailTemplateTypesStatus)
    && emailTemplateTypesStatus === REDUX_STATUS.SUCCESS;

  const activeEmailTemplateTypeIds = emailTemplates.data.map((template) => template.emailTemplateTypeId);
  const activeTemplateTypes = emailTemplateTypes.filter((type) => activeEmailTemplateTypeIds.includes(type.id));
  const moreTemplateTypes = emailTemplateTypes.filter((type) => !activeEmailTemplateTypeIds.includes(type.id));

  useEffect(() => {
    dispatch(LOAD_COURSE_TYPES.request());
  }, [dispatch]);

  useEffect(() => {
    if (!course?.id) {
      dispatch(LOAD_COURSE.request(courseId));
    }
  }, [course?.id, courseId, dispatch]);

  useEffect(() => {
    dispatch(LOAD_EMAIL_TEMPLATES.request({ entityId: Number(courseId), context: 'courses', contextType: 'Course' }));
  }, [courseId, dispatch]);

  useEffect(() => {
    if (emailTemplatesLoaded && emailTemplateTypesLoaded && selectedTemplate === -1) {
      setSelectedTemplate(activeTemplateTypes?.[0]?.id || moreTemplateTypes?.[0]?.id);
    }
  }, [emailTemplatesLoaded, emailTemplateTypesLoaded, selectedTemplate, activeTemplateTypes, moreTemplateTypes]);

  return (
    <CurriculumTemplate className="bg-white">
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <SanyasIcon name="book" size="3x" />
          <div>
            <Breadcrumbs
              className="course-show-breadcrumbs my-0"
              navItems={[
                { label: 'Courses', href: CURRICULUM_PATH },
                { label: courseType?.name || '...', href: buildRoutePath(CURRICULUM_COURSE_TYPE_SHOW_PATH, { id: courseType?.id }) },
                { label: course?.name || '...', href: buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course?.id }) },
              ]}
              ignoreLast
            />

            <h1>{courseType?.name}</h1>
          </div>
        </div>
      </div>

      <div className="flush bg-white">
        <div className="row mx-0">
          <div className="col-6 border-end px-0" style={{ maxWidth: '40rem' }}>
            {/* Min-height to match next column header w/ button */}
            <div className="d-flex align-items-center py-3 px-4 border-bottom" style={{ minHeight: '4rem' }}>
              <h4 className="m-0">
                <SanyasIcon name="envelope" className="me-2" />
                Email Templates
              </h4>
            </div>

            {emailTemplates.status === REDUX_STATUS.PENDING && <Spinner />}

            {activeTemplateTypes.length > 0 && (
              <ul className="list-group list-group-flush">
                {activeTemplateTypes.map((template) => (
                  <EmailTemplatesListItem key={template.id} template={template} setSelectedTemplate={setSelectedTemplate} active />
                ))}
              </ul>
            )}

            {moreTemplateTypes.length > 0 && (
              <>
                {activeTemplateTypes.length > 0 && (
                  <div className="d-flex align-items-center py-3 px-4 border-bottom">
                    <h4 className="m-0">
                      More email templates available
                    </h4>
                  </div>
                )}

                <ul className="list-group list-group-flush">
                  {moreTemplateTypes.map((template) => (
                    <EmailTemplatesListItem key={template.id} template={template} setSelectedTemplate={setSelectedTemplate} />
                  ))}
                </ul>
              </>
            )}
          </div>

          <div className="col px-0">
            {selectedTemplate > -1 && (
              <EmailTemplateForm template={selectedTemplate} context="courses" setSelectedTemplate={setSelectedTemplate} />
            )}
          </div>
        </div>
      </div>
    </CurriculumTemplate>
  );
}

export default CourseEmailTemplatesPage;
