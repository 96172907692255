import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { REDUX_STATUS } from 'lib/constants';
import { Alert, Button, ProgressBar } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';

function Processing({ onReset, importData, setImportData, setActiveStep }) {
  const { id } = useParams();
  const { id: groupId, isImporting } = useSelector((state) => state.groupShow.group) || {};
  const { progress, status, error } = useSelector((state) => state.bulkImport.groups[id]) || {};
  const [progressValue, setProgressValue] = useState(5);

  const handleReset = () => {
    if (importData?.status === 'complete') {
      setImportData(null);
    }

    if (typeof onReset === 'function') {
      onReset();
    }
  };

  useEffect(() => {
    // Simulate continuous progress
    const intervalId = setInterval(() => {
      const MAX_PROGRESS = 95;
      setProgressValue((prev) => {
        if (prev < MAX_PROGRESS) {
          return Math.min(prev + 2, MAX_PROGRESS);
        }

        clearInterval(intervalId);
        return MAX_PROGRESS;
      });
    }, 800);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (progress) {
      setProgressValue(progress);
    }
  }, [progress]);

  useEffect(() => {
    if (([REDUX_STATUS.SUCCESS, REDUX_STATUS.ERROR].includes(status) && importData?.status === 'active')
      || (importData?.status === 'active' && groupId === Number(id) && !isImporting)) {
      setImportData({ status: 'complete', error });
      setActiveStep('COMPLETE');
    }
  }, [status, error, id, groupId, isImporting, importData, setImportData, setActiveStep]);

  return (
    <div className="col-7 my-4 mx-auto">
      {importData?.status === 'active' && (
        <>
          <h3>Importing participants</h3>
          <p className="text-small">You can close this window.</p>

          <ProgressBar animated variant="success" now={progressValue} key={1} />
        </>
      )}

      {importData?.status === 'complete' && (
        <>
          {!!importData.error && (
            <>
              <h3>Error</h3>
              <p className="text-small">There was an error with the bulk import. Participants have not been added to the group.</p>
              <Alert variant="danger" className="py-2 text-small">{importData.error}</Alert>
            </>
          )}
          {!importData.error && (
            <>
              <h3>Success!</h3>
              <p className="text-small">Participants have been successfully added to the group.</p>
            </>
          )}

          <p className="d-flex align-items-center">
            <Link className="btn btn-primary me-2" to={`/registrar/groups/${id}`} onClick={handleReset}>Go to participant list</Link>

            <Button variant="outline-secondary me-2" onClick={handleReset}>
              <SanyasIcon name="upload" className="me-1" />
              Bulk import
            </Button>

            {!importData.error && (
              <a className="px-2 text-decoration-none" href={`/registrar/groups/${id}/bulk_import.csv`} download>Download .csv</a>
            )}
          </p>
        </>
      )}
    </div>
  );
}

Processing.defaultProps = {
  onReset: null,
  importData: null,
  setImportData: () => {},
  setActiveStep: () => {},
};

Processing.propTypes = {
  onReset: PropTypes.func,
  importData: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.string,
  }),
  setImportData: PropTypes.func,
  setActiveStep: PropTypes.func,
};

export default Processing;
