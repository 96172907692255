import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import _upperFirst from 'lodash/upperFirst';
import { Formik } from 'formik';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_TYPE_SHOW_PATH } from 'lib/routerPaths';
import { REDUX_STATUS } from 'lib/constants';
import { CourseTypePropType } from 'lib/propTypes';
import { CREATE_COURSE_TYPE, GET_COURSE_TYPE_FORM_DATA } from 'store/courseTypes/actions';
import { selectOrganizationById } from 'store/organizations/selectors';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import CourseForm from 'components/curriculum/CourseForm';
import CourseFormSchema from 'components/curriculum/CourseForm/validation';
import DefaultModal from 'components/shared/Modal/DefaultModal';

function CourseCreateDialog(props) {
  const { showCreateModal, handleEditModalClose, courseTypes, organizationId } = props;
  const courseType = useSelector((state) => state.courseTypes.courseType);
  const { attributes } = useSelector((state) => state.courseTypes.formData);
  const organization = useSelector((state) => selectOrganizationById(state, organizationId));
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValues = {
    name: '',
    nameFr: '',
    organizationId,
    ...attributes,
  };

  const handleSave = useCallback((values) => {
    dispatch(CREATE_COURSE_TYPE.request(values));
  }, [dispatch]);

  const closeModal = useCallback((resetForm) => {
    handleEditModalClose();
    resetForm();
  }, [handleEditModalClose]);

  useEffect(() => {
    if (organizationId) {
      dispatch(GET_COURSE_TYPE_FORM_DATA.request({ organizationId }));
    }
  }, [organizationId, dispatch]);

  useEffect(() => {
    if (courseType?.id && courseType?.status === REDUX_STATUS.SUCCESS) {
      history.push(buildRoutePath(CURRICULUM_COURSE_TYPE_SHOW_PATH, { id: courseType.id }));
    }
  }, [courseType?.id, courseType?.status, history]);

  const getCourseTypeOptions = useMemo(() => courseTypes.map((ct) => ({ label: ct.name, value: ct.id })), [courseTypes]);

  return (
    <Formik
      onSubmit={(values, resetForm) => handleSave(values, resetForm)}
      initialValues={initialValues}
      validationSchema={CourseFormSchema}
      enableReinitialize
    >
      {({ resetForm, handleSubmit, errors, touched, dirty, isSubmitting }) => (
        <DefaultModal
          size="lg"
          isOpen={showCreateModal}
          header={`Create a course for ${_upperFirst(organization?.name)}`}
          centered={false}
          onClose={() => closeModal(resetForm)}
          footerComponent={(
            <Button variant="primary" onClick={handleSubmit} type="submit" disabled={isSubmitting}>
              Create
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
            )}
        >
          <CourseForm courseTypeOptions={getCourseTypeOptions} errors={errors} handleSubmit={handleSubmit} touched={touched} dirty={dirty} mode="new" />
        </DefaultModal>
      )}
    </Formik>
  );
}

CourseCreateDialog.defaultProps = {
  organizationId: null,
};

CourseCreateDialog.propTypes = {
  showCreateModal: PropTypes.bool.isRequired,
  handleEditModalClose: PropTypes.func.isRequired,
  courseTypes: PropTypes.arrayOf(CourseTypePropType).isRequired,
  organizationId: PropTypes.number,
};

export default CourseCreateDialog;
