import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_SHOW_PATH, CURRICULUM_COURSE_TYPE_SHOW_PATH, CURRICULUM_PATH } from 'lib/routerPaths';
import { LOAD_COURSE, FIND_AND_REPLACE_SEARCH } from 'store/courses/actions';
import { selectCourseById } from 'store/courses/selectors';
import { LOAD_COURSE_TYPES } from 'store/courseTypes/actions';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import { Button, Spinner } from 'react-bootstrap';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import InputField from 'components/shared/FormFields/InputField';
import SearchResults from 'components/curriculum/FindAndReplace/SearchResults';

function CourseFindAndReplacePage() {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const course = useSelector((state) => selectCourseById(state, courseId));
  const courseType = useSelector((state) => selectCourseTypeById(state, course?.courseTypeId));
  const [findTerm, setFindTerm] = useState('');
  const [replaceTerm, setReplaceTerm] = useState('');
  const { allIds, byId, searchPerformed } = useSelector((state) => state.courses.findAndReplace);

  useEffect(() => {
    dispatch(LOAD_COURSE_TYPES.request());
  }, [dispatch]);

  useEffect(() => {
    if (!course?.id) {
      dispatch(LOAD_COURSE.request(courseId));
    }
  }, [course?.id, courseId, dispatch]);

  const searchInitialValues = {
    findTerm: '',
    replaceTerm: '',
    courseId,
  };

  const handleSearch = useCallback(
    (values, actions) => {
      setFindTerm(values.findTerm);
      setReplaceTerm(values.replaceTerm);
      dispatch(FIND_AND_REPLACE_SEARCH.request(values, { formikActions: actions }));
    },
    [dispatch],
  );

  return (
    <div className="container-fluid">
      {/* # HEADER */}
      <div className="row pt-4 header">
        <h3 className="col-md-2 mb-0 pe-0">Find and Replace</h3>
        <div className="d-flex col-md-8 ps-0">
          <Breadcrumbs
            className="course-show-breadcrumbs my-0"
            navItems={[
              { label: 'Courses', href: CURRICULUM_PATH },
              { label: courseType?.name || '...', href: buildRoutePath(CURRICULUM_COURSE_TYPE_SHOW_PATH, { id: courseType?.id }) },
              { label: course?.name || '...', href: buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course?.id }) },
            ]}
            ignoreLast
          />
        </div>
        <div className="d-flex justify-content-end col-md-2">
          <Link to={`/curriculum/courses/${courseType?.id}`}>
            Cancel
          </Link>
        </div>
      </div>

      {/* # INTRO / SEARCH */}
      <div className="row">
        <div className="col-md-8 my-4 mx-auto">
          <h3>Find and Replace</h3>
          <p className="vw-75">
            This tool can be used to quickly make changes to any number of tasks in a course. You will
            be presented with a list allowing you to individually review and update each task.
            <br />
            Please ensure you thoroughly review each task before updating it!
          </p>
          <Formik
            initialValues={searchInitialValues}
            validate={(values) => {
              const errors = {};
              if (!values.findTerm) {
                errors.findTerm = 'Required';
              }
              return errors;
            }}
            onSubmit={handleSearch}
          >
            {({
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className="row g-3">
                <div className="col-md-3">
                  <InputField
                    label="Find"
                    name="findTerm"
                    size="md"
                  />
                </div>
                <div className="col-md-3">
                  <InputField
                    label="Replace"
                    name="replaceTerm"
                  />
                </div>
                <div className="col-md-2 d-flex align-items-end pb-2">
                  <Button value="primary" type="submit" disabled={isSubmitting}>
                    Search
                    {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>

      {/* # RESULTS */}
      {(searchPerformed && (
        <SearchResults
          taskIds={allIds}
          data={byId}
          findTerm={findTerm}
          replaceTerm={replaceTerm}
          searchPerformed={searchPerformed}
        />
      ))}
    </div>
  );
}

export default CourseFindAndReplacePage;
