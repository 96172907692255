import React from 'react';

function HowToUseGlossary() {
  return (
    <>
      <p>
        When editing a task, surround a glossary term with double curly braces to activate the glossary feature for the
        term.
      </p>

      <div>
        For example:
        <pre className="border-0 rounded p-2 bg-light text-wrap">{'British Columbia is home to 203 {{First Nations}} communities.'}</pre>
      </div>

      <p>
        This will show as:
        <br />
        <img src="/images/curriculum/glossary-first-nations.png" alt="Glossary example: First Nations" className="w-50" />
      </p>

      <p>You can also override the term with different text and still associate it with a given glossary term.</p>

      <div>
        For example:
        <pre className="border-0 rounded p-2 bg-light text-wrap">{'{{ethnically:ethnicity}}'}</pre>
      </div>

      <p>
        This will show as:
        <br />
        <img src="/images/curriculum/glossary-ethically-ethnicity.png" alt="Glossary example: override ethnicity with ethnically" className="w-50" />
      </p>
    </>
  );
}

export default HowToUseGlossary;
