import { COURSE_STATUS } from 'lib/constants';
import { createSelector } from 'reselect';

export const selectCourseIds = (state) => state.courses.courseIds;
export const selectCourses = (state) => state.courses.data;
export const selectGlossaryEntries = (state) => state.courses.glossaryEntries.byId;
export const selectPhrasebookEntries = (state) => state.courses.phrasebookEntries.byId;
export const selectArticles = (state) => state.courses.articles.byId;

export const selectCourseById = createSelector(
  [selectCourses,
    (_, id) => id],
  (courses, id) => courses?.[id] || {},
);

export const selectDraftCourses = createSelector(
  [selectCourseIds, selectCourses],
  (courseIds, courses) => courseIds.filter((id) => [COURSE_STATUS.DRAFT].includes(courses[id].status)).map((id) => courses[id]),
);

export const selectPublishedCourses = createSelector(
  [selectCourseIds, selectCourses],
  (courseIds, courses) => courseIds.filter((id) => ![COURSE_STATUS.ARCHIVED, COURSE_STATUS.DRAFT, COURSE_STATUS.DUPLICATING].includes(courses[id].status)).map((id) => courses[id]),
);

export const selectGlossaryEntryById = createSelector(
  [selectGlossaryEntries,
    (_, id) => id],
  (entries, id) => entries?.[id],
);

export const selectGlossaryEntryTerms = createSelector(
  [selectGlossaryEntries,
    (_, id) => id],
  (entries, id) => entries && Object.values(entries).filter((entry) => entry.id !== Number(id)).map((entry) => `${entry.term}${entry.isFrench ? 'fr' : 'en'}`),
);

export const selectPhrasebookEntryById = createSelector(
  [selectPhrasebookEntries,
    (_, id) => id],
  (entries, id) => entries?.[id],
);

export const selectPhrasebookEntryKeys = createSelector(
  [selectPhrasebookEntries,
    (_, id) => id],
  (entries, id) => entries && Object.values(entries).filter((entry) => entry.id !== Number(id)).map((entry) => entry.key),
);

export const selectArticleById = createSelector(
  [selectArticles,
    (_, id) => id],
  (entries, id) => entries?.[id],
);
