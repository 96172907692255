export const REGISTRAR_GROUP_NEW_PATH = '/registrar/groups/new';
export const REGISTRAR_GROUP_SHOW_PATH = '/registrar/groups/:id';
export const REGISTRAR_GROUP_IMPORT_PATH = '/registrar/groups/:id/import';
export const REGISTRAR_GROUP_DISCUSSIONS_PATH = `${REGISTRAR_GROUP_SHOW_PATH}/discussions`;
export const REGISTRAR_GROUP_EMAIL_TEMPLATES_PATH = `${REGISTRAR_GROUP_SHOW_PATH}/email_templates`;
export const REGISTRAR_GROUP_JOURNALS_PATH = `${REGISTRAR_GROUP_SHOW_PATH}/journals`;
export const REGISTRAR_GROUP_LIVE_FEED_PATH = `${REGISTRAR_GROUP_SHOW_PATH}/live_feed`;
export const REGISTRAR_GROUP_REPORTS_PATH = `${REGISTRAR_GROUP_SHOW_PATH}/reports`;
export const REGISTRAR_GROUP_RESERVATIONS_PATH = `${REGISTRAR_GROUP_SHOW_PATH}/reservations`;
export const REGISTRAR_GROUP_SETTINGS_PATH = `${REGISTRAR_GROUP_SHOW_PATH}/settings`;
export const REGISTRAR_REPORTS_EMAIL_TRANSMISSION_PATH = '/registrar/reports/email_transmission';
export const REGISTRAR_REPORTS_PAYMENTS_PATH = '/registrar/reports/payments';
export const REGISTRAR_REPORTS_PLATFORM_PTQ_PATH = '/registrar/reports/platform_ptq';
export const REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH = '/registrar/settings/platform_closures';
export const REGISTRAR_SETTINGS_PLATFORM_CLOSURES_NEW_PATH = '/registrar/settings/platform_closures/new';
export const REGISTRAR_SETTINGS_PLATFORM_CLOSURES_SHOW_PATH = '/registrar/settings/platform_closures/:id';
export const REGISTRAR_SETTINGS_PLATFORM_CLOSURES_EDIT_PATH = '/registrar/settings/platform_closures/:id/edit';
export const REGISTRAR_SETTINGS_ORGANIZATION_EMAIL_TEMPLATES_PATH = '/registrar/settings/organizations/:id/email_templates';
export const REGISTRAR_REPORTS_RESPONSES_PATH = '/registrar/reports/responses';
export const REGISTRAR_VOUCHERS_PATH = '/registrar/vouchers';
export const REGISTRAR_VOUCHERS_NEW_PATH = '/registrar/vouchers/new';
export const REGISTRAR_VOUCHERS_SHOW_PATH = '/registrar/vouchers/:id';
export const REGISTRAR_USERS_SHOW_PATH = '/registrar/people/:id';
export const REGISTRAR_USERS_EMAILS_PATH = `${REGISTRAR_USERS_SHOW_PATH}/emails`;
export const REGISTRAR_USERS_GROUP_MEMBERSHIPS_PATH = `${REGISTRAR_USERS_SHOW_PATH}/group_memberships`;
export const REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH = `${REGISTRAR_USERS_GROUP_MEMBERSHIPS_PATH}/:groupMembershipId`;

export const FACILITATOR = '/facilitator';
export const FACILITATOR_DASHBOARD_PATH = '/facilitator/dashboard';
export const FACILITATOR_MESSAGES_PATH = '/facilitator/messages';
export const FACILITATOR_INDIGENOUS_WELCOME_LETTERS_PATH = '/facilitator/indigenous_welcome_letters';
export const FACILITATOR_PARTICIPANTS_SHOW_PATH = '/facilitator/participants/:groupMembershipId';
export const FACILITATOR_CURRICULUM_PATH = '/facilitator/curriculum';
export const FACILITATOR_COURSE_TYPE_PATH = '/facilitator/courses/:id';
export const FACILITATOR_COURSE_PATH = '/facilitator/versions/:courseId';
export const FACILITATOR_COURSE_GLOSSARY_PATH = `${FACILITATOR_COURSE_PATH}/glossary`;
export const FACILITATOR_COURSE_PHRASEBOOK_PATH = `${FACILITATOR_COURSE_PATH}/phrasebook`;
export const FACILITATOR_COURSE_SEARCH_PATH = `${FACILITATOR_COURSE_PATH}/search`;
export const FACILITATOR_COURSE_SELF_CARE_PATH = `${FACILITATOR_COURSE_PATH}/self_care`;
export const FACILITATOR_ARTICLE_PATH = `${FACILITATOR_COURSE_PATH}/self_care/:articleId`;
export const FACILITATOR_COURSE_COMPLETION_REPORT_PATH = `${FACILITATOR_COURSE_PATH}/completion_report`;
export const FACILITATOR_MODULE_PATH = `${FACILITATOR_COURSE_PATH}/modules/:sectionId`;
export const FACILITATOR_TASK_PATH = `${FACILITATOR_COURSE_PATH}/tasks/:taskId`;
export const FACILITATOR_DISCUSSION_BOARDS_PATH = '/facilitator/discussion_boards';
export const FACILITATOR_GROUP_SHOW_PATH = '/facilitator/groups/:id';
export const FACILITATOR_GROUP_REPORTS_PATH = `${FACILITATOR_GROUP_SHOW_PATH}/reports`;
export const FACILITATOR_GROUP_LIVE_FEED_PATH = `${FACILITATOR_GROUP_SHOW_PATH}/live_feed`;
export const FACILITATOR_GROUP_DISCUSSIONS_PATH = `${FACILITATOR_GROUP_SHOW_PATH}/discussions`;
export const FACILITATOR_GROUP_JOURNALS_PATH = `${FACILITATOR_GROUP_SHOW_PATH}/journals`;
export const FACILITATOR_GROUP_SETTINGS_PATH = `${FACILITATOR_GROUP_SHOW_PATH}/settings`;

export const SUPER_FACILITATOR = '/lead_facilitator';
export const SUPER_FACILITATOR_DASHBOARD_PATH = '/lead_facilitator/dashboard';
export const SUPER_FACILITATOR_REPORTS_PATH = '/lead_facilitator/reports';
export const SUPER_FACILITATOR_DISCUSSION_BOARD_REPORT_PATH = '/lead_facilitator/reports/discussion_board';
export const SUPER_FACILITATOR_JOURNAL_REPORT_PATH = '/lead_facilitator/reports/facilitator';

export const CURRICULUM_PATH = '/curriculum';
export const CURRICULUM_COURSE_TYPE_SHOW_PATH = '/curriculum/courses/:id';
export const CURRICULUM_COURSE_TYPE_ARCHIVE_PATH = `${CURRICULUM_COURSE_TYPE_SHOW_PATH}/archive`;
export const CURRICULUM_COURSE_TYPE_SETTINGS_PATH = `${CURRICULUM_COURSE_TYPE_SHOW_PATH}/settings`;
export const CURRICULUM_COURSE_SHOW_PATH = '/curriculum/versions/:courseId';
export const CURRICULUM_COURSE_EMAIL_TEMPLATES_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/email_templates`;
export const CURRICULUM_COURSE_GLOSSARY_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/glossary`;
export const CURRICULUM_COURSE_PHRASEBOOK_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/phrasebook`;
export const CURRICULUM_COURSE_COMPLETION_REPORT_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/completion_report`;
export const CURRICULUM_COURSE_FIND_AND_REPLACE_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/find_and_replace`;
export const CURRICULUM_COURSE_PROACTIVE_TEMPLATES_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/proactive_templates`;
export const CURRICULUM_COURSE_SELF_CARE_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/self_care`;
export const MODULE_NEW_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/modules/new`;
export const MODULE_EDIT_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/modules/:sectionId`;
export const TASK_NEW_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/tasks/new`;
export const TASK_EDIT_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/tasks/:taskId`;
export const ARTICLE_NEW_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/self_care/new`;
export const ARTICLE_EDIT_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/self_care/:articleId`;
export const CURRICULUM_COURSE_GLOSSARY_NEW_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/glossary/new`;
export const CURRICULUM_COURSE_GLOSSARY_SHOW_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/glossary/:glossaryId`;
export const CURRICULUM_COURSE_PHRASEBOOK_NEW_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/phrasebook/new`;
export const CURRICULUM_COURSE_PHRASEBOOK_SHOW_PATH = `${CURRICULUM_COURSE_SHOW_PATH}/phrasebook/:phrasebookId`;

export const REPORTING = '/reporting';
export const REPORTING_HARM_REPORT = '/reporting/reports/harm_prevention';

export default [
  REGISTRAR_GROUP_NEW_PATH,
  REGISTRAR_GROUP_SHOW_PATH,
  REGISTRAR_GROUP_IMPORT_PATH,
  REGISTRAR_GROUP_DISCUSSIONS_PATH,
  REGISTRAR_GROUP_EMAIL_TEMPLATES_PATH,
  REGISTRAR_GROUP_JOURNALS_PATH,
  REGISTRAR_GROUP_LIVE_FEED_PATH,
  REGISTRAR_GROUP_REPORTS_PATH,
  REGISTRAR_GROUP_RESERVATIONS_PATH,
  REGISTRAR_GROUP_SETTINGS_PATH,
  REGISTRAR_REPORTS_EMAIL_TRANSMISSION_PATH,
  REGISTRAR_REPORTS_PLATFORM_PTQ_PATH,
  REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH,
  REGISTRAR_SETTINGS_PLATFORM_CLOSURES_NEW_PATH,
  REGISTRAR_SETTINGS_PLATFORM_CLOSURES_SHOW_PATH,
  REGISTRAR_SETTINGS_PLATFORM_CLOSURES_EDIT_PATH,
  REGISTRAR_SETTINGS_ORGANIZATION_EMAIL_TEMPLATES_PATH,
  REGISTRAR_REPORTS_RESPONSES_PATH,
  REGISTRAR_VOUCHERS_PATH,
  REGISTRAR_VOUCHERS_NEW_PATH,
  REGISTRAR_VOUCHERS_SHOW_PATH,
  REGISTRAR_USERS_SHOW_PATH,
  REGISTRAR_USERS_EMAILS_PATH,
  REGISTRAR_USERS_GROUP_MEMBERSHIPS_PATH,
  REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH,
  FACILITATOR,
  FACILITATOR_DASHBOARD_PATH,
  FACILITATOR_MESSAGES_PATH,
  FACILITATOR_INDIGENOUS_WELCOME_LETTERS_PATH,
  FACILITATOR_PARTICIPANTS_SHOW_PATH,
  SUPER_FACILITATOR,
  SUPER_FACILITATOR_DASHBOARD_PATH,
  SUPER_FACILITATOR_REPORTS_PATH,
  SUPER_FACILITATOR_DISCUSSION_BOARD_REPORT_PATH,
  SUPER_FACILITATOR_JOURNAL_REPORT_PATH,
  CURRICULUM_PATH,
  CURRICULUM_COURSE_TYPE_SHOW_PATH,
  CURRICULUM_COURSE_TYPE_ARCHIVE_PATH,
  CURRICULUM_COURSE_TYPE_SETTINGS_PATH,
  CURRICULUM_COURSE_EMAIL_TEMPLATES_PATH,
  CURRICULUM_COURSE_GLOSSARY_PATH,
  CURRICULUM_COURSE_PHRASEBOOK_PATH,
  CURRICULUM_COURSE_COMPLETION_REPORT_PATH,
  CURRICULUM_COURSE_FIND_AND_REPLACE_PATH,
  CURRICULUM_COURSE_PROACTIVE_TEMPLATES_PATH,
  CURRICULUM_COURSE_SELF_CARE_PATH,
  CURRICULUM_COURSE_SHOW_PATH,
  MODULE_EDIT_PATH,
  TASK_EDIT_PATH,
  ARTICLE_EDIT_PATH,
  CURRICULUM_COURSE_GLOSSARY_NEW_PATH,
  CURRICULUM_COURSE_GLOSSARY_SHOW_PATH,
  CURRICULUM_COURSE_PHRASEBOOK_NEW_PATH,
  CURRICULUM_COURSE_PHRASEBOOK_SHOW_PATH,
  REPORTING,
  REPORTING_HARM_REPORT,
  FACILITATOR_CURRICULUM_PATH,
  FACILITATOR_COURSE_TYPE_PATH,
  FACILITATOR_COURSE_PATH,
  FACILITATOR_COURSE_GLOSSARY_PATH,
  FACILITATOR_COURSE_PHRASEBOOK_PATH,
  FACILITATOR_COURSE_SEARCH_PATH,
  FACILITATOR_COURSE_SELF_CARE_PATH,
  FACILITATOR_COURSE_COMPLETION_REPORT_PATH,
  FACILITATOR_MODULE_PATH,
  FACILITATOR_TASK_PATH,
  FACILITATOR_ARTICLE_PATH,
  FACILITATOR_GROUP_SHOW_PATH,
  FACILITATOR_GROUP_REPORTS_PATH,
  FACILITATOR_GROUP_LIVE_FEED_PATH,
  FACILITATOR_GROUP_DISCUSSIONS_PATH,
  FACILITATOR_GROUP_JOURNALS_PATH,
  FACILITATOR_GROUP_SETTINGS_PATH,
  FACILITATOR_COURSE_COMPLETION_REPORT_PATH,
  FACILITATOR_DISCUSSION_BOARDS_PATH,
];
