import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_JOURNAL_AND_DISCUSSION_BOARDS } from 'store/groupMemberships/actions';
import PropTypes from 'prop-types';
import { LEGACY_APP_DOMAIN } from 'config';
import List from 'components/shared/ActionsMenu/List';

function JournalAndDiscussionBoards({ groupMembershipId, groupId, subGroupId, userId }) {
  const { discussionBoards, journalTasks } = useSelector((state) => state.groupMemberships.journalsDiscussionBoards);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LOAD_JOURNAL_AND_DISCUSSION_BOARDS.request({ groupMembershipId, userId }));
  }, [dispatch, groupMembershipId, userId]);

  const menuItems = useMemo(() => {
    const items = [];
    if (!discussionBoards && !journalTasks) return [];
    discussionBoards.forEach((task) => {
      items.push({
        label: `${task.internalName}: ${task.name}`,
        href: `${LEGACY_APP_DOMAIN}/groups/${groupId}/sub_groups/${subGroupId}/discussion_tasks/${task.id}`,
        icon: 'chat',
      });
    });
    journalTasks.forEach((task) => {
      items.push({
        label: `${task.internalName}: ${task.name}`,
        href: `${LEGACY_APP_DOMAIN}/group_memberships/${groupMembershipId}/journal_tasks/${task.id}`,
        icon: 'book',
      });
    });
    return items;
  }, [discussionBoards, groupId, groupMembershipId, journalTasks, subGroupId]);

  return (
    <ul className="list-unstyled">
      <List items={menuItems} />
    </ul>
  );
}

JournalAndDiscussionBoards.defaultProps = {
  subGroupId: undefined,
};

JournalAndDiscussionBoards.propTypes = {
  groupMembershipId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  groupId: PropTypes.number.isRequired,
  subGroupId: PropTypes.number,
};

export default JournalAndDiscussionBoards;
