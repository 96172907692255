import fetchPonyfill from 'fetch-ponyfill';
import { camelCaseObjectOrArray, snakeCaseObject, encodeGetURLParamsForRails, formDataBody } from 'lib/utils';
import { put } from 'redux-saga/effects';
import { SHOW_CONNECTION_ERROR, SHOW_SOMETHING_WENT_WRONG } from 'store/actions';

const { fetch, Headers } = fetchPonyfill({ promise: window.Promise });

const defaultOptions = {
  headers: new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
  }),
  // Only send cookies to the same domain
  credentials: 'same-origin',
};

export const OTHER_ERROR = 'OtherError';
export const NETWORK_ERROR = 'NetworkError';
export const INVALID_REQUEST_ERROR = 'InvalidRequestError';
export const UNAUTHORIZED_ERROR = 'UnauthorizedError';
export const RESOURCE_NOT_FOUND_ERROR = 'ResourceNotFoundError';

/**
 * A wrapper around the fetch API that returns a promise that rejects non-200
 * HTTP responses.
 * The fetch spec apparently requires that fetch resolve all responses aside
 * from network errors. See: https://github.com/github/fetch/issues/155
 * The err.name in the rejected error matches the API dev convention on the backend.
 * @param {string} url - the URL to use for the request
 * @param {object} options - fetch request options. see fetch api docs.
 */
const fetchAndRejectErrors = (url, options = {}) => new Promise((resolve, reject) => {
  fetch(url, options)
    .then((response) => {
      let error;
      if (response.status >= 200 && response.status < 300) {
        // We got a valid response from the server. Resolve.
        return resolve(response);
      }
      switch (response.status) {
        case 400:
          error = new Error('ApiError: Invalid request.');
          error.name = INVALID_REQUEST_ERROR;
          response.json()
            .then((body) => {
              error.details = camelCaseObjectOrArray(body);
              reject(error);
            });
          return error;
        case 401:
          error = new Error('ApiError: Unauthorized.');
          error.name = UNAUTHORIZED_ERROR;
          return reject(error);
        case 404:
          error = new Error('ApiError: Resource not found.');
          error.name = RESOURCE_NOT_FOUND_ERROR;
          return reject(error);
        case 503:
          // The server is in maintenance mode.
          // Redirect to the home page which will display the maintenance message
          window.location.href = '/';
          return null;
        default:
          error = new Error(`ApiError: Generic error. Status code: ${response.status}`);
          error.name = OTHER_ERROR;
          return reject(error);
      }
    })
    .catch(() => {
      // This is some kind of network or timeout error
      const error = new Error('ApiError: Network error.');
      error.name = NETWORK_ERROR;
      return reject(error);
    });
});

export const fetchGet = (path, urlParams, options = {}) => {
  const fullOptions = { ...defaultOptions, ...options };
  const paramsString = encodeGetURLParamsForRails(snakeCaseObject(urlParams));
  return fetchAndRejectErrors(`${path}?${paramsString}`, fullOptions)
    .then((response) => response.json())
    .then((responseBody) => camelCaseObjectOrArray(responseBody));
};

export const fetchDownload = (path, urlParams, options = {}) => {
  const fullOptions = { ...options };
  const paramsString = encodeGetURLParamsForRails(snakeCaseObject(urlParams));
  return fetchAndRejectErrors(`${path}?${paramsString}`, fullOptions)
    .then((response) => response);
};

const fetchNonGet = async (path, payload, options = {}) => {
  const defaults = { ...defaultOptions };

  let body;
  if (options.formData) {
    body = formDataBody(payload, options.preserveKeys);
    defaults.headers.delete('Content-Type'); // don't set content-type for FormData
  } else {
    body = JSON.stringify(snakeCaseObject(payload, options.preserveKeys));
  }

  const fullOptions = {
    ...defaults,
    body,
    ...options,
  };

  const response = await fetchAndRejectErrors(path, fullOptions);
  const responseBody = await response.json();
  return camelCaseObjectOrArray(responseBody);
};

export const fetchPost = (path, payload, options = {}) => fetchNonGet(path, payload, { ...options, method: 'POST' });

export const fetchPatch = (path, payload, options = {}) => fetchNonGet(path, payload, { ...options, method: 'PATCH' });

export const fetchPut = (path, payload, options = {}) => fetchNonGet(path, payload, { ...options, method: 'PUT' });

export const fetchDelete = (path, payload, options = {}) => fetchNonGet(path, payload, { ...options, method: 'DELETE' });

/**
 * Saga handler that dispatches the right error action when a network error occurs.
 *
 * @param {Error} err The error that was raised by fetch
 * @param {Object} actionObj The action object, as it was created by the action creator creators. Ex: actions.GET_ASSIGNMENTS
 * @param {Object} meta Metadata regarding error context
 */
export function* handleSimpleFetchError(err, actionObj, meta = {}) {
  yield put(actionObj.error(err, meta));

  if (err && (err.name === NETWORK_ERROR)) {
    yield put(SHOW_CONNECTION_ERROR.action());
  }
  if (err && (err.name === OTHER_ERROR)) {
    // this is most likely a 500 error
    yield put(SHOW_SOMETHING_WENT_WRONG.action());
  }
}
