import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RESET_TASK } from 'store/courses/actions';
import { CourseSectionPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { TASK_NEW_PATH } from 'lib/routerPaths';
import { toLetter } from 'lib/utils';
import { Droppable } from '@hello-pangea/dnd';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import AccordionBody from 'react-bootstrap/AccordionBody';
import ModuleTask from './ModuleTask';

/* eslint-disable react/jsx-props-no-spreading */

function ModuleTasks({ section }) {
  const tasks = useSelector((state) => state.courses.tasks) || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { course, setCurrentSection } = useCourseShowContext();

  const taskNewPath = buildRoutePath(TASK_NEW_PATH, { courseId: course?.id });
  const newTask = pathname === taskNewPath ? (section?.taskIds?.length || 0) + 1 : 0;

  const handleClick = useCallback(() => {
    dispatch(RESET_TASK.action());
    setCurrentSection(section);
    history.push(taskNewPath);
  }, [section, taskNewPath, dispatch, setCurrentSection, history]);

  return (
    <AccordionBody className="p-0">
      <Droppable droppableId={`${section?.id}`} type="task">
        {(provided) => (
          <ol className="p-0 mb-2" ref={provided.innerRef} {...provided.droppableProps}>
            {section?.taskIds?.map((id, index) => (
              <ModuleTask key={id} task={tasks[id]} index={index} />
            ))}

            {/* New task */}
            {newTask > 0 && (
              <li className="list-group-item d-flex align-items-center border-0 list-group-item-active">
                <span className="w-100 border-0 text-start font-weight-semibold" style={{ paddingLeft: '24px' }}>
                  <span className="accordion-position">{`${toLetter(newTask).toUpperCase()}. `}</span>
                  New task
                </span>
              </li>
            )}

            {provided.placeholder}
          </ol>
        )}
      </Droppable>

      {!course?.isLocked && (
        <button type="button" onClick={handleClick} className="btn btn-plain btn-primary ms-4">+ Add task</button>
      )}
    </AccordionBody>
  );
}

ModuleTasks.defaultProps = {
  section: null,
};

ModuleTasks.propTypes = {
  section: CourseSectionPropType,
};

export default ModuleTasks;
