import React from 'react';
import { Link } from 'react-router-dom';
import { copyPhrasebookEntry } from 'lib/utils';
import { PhrasebookEntryPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_PHRASEBOOK_SHOW_PATH } from 'lib/routerPaths';
import CopyButton from 'components/shared/Button/CopyButton';
import './styles.scss';

function PhrasebookEntry({ entry }) {
  if (!entry) return null;

  return (
    <li className="list-group-item py-3 ps-4 position-relative" data-pe-hover>
      <Link
        className="btn-plain w-75 px-0 text-start text-black"
        to={(entry?.id && entry?.courseId) ? buildRoutePath(CURRICULUM_COURSE_PHRASEBOOK_SHOW_PATH, { courseId: entry?.courseId, phrasebookId: entry?.id }) : null}
      >
        <h4>{entry.title}</h4>
      </Link>

      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: entry.description }} />

      <CopyButton variant={null} size="sm" className="position-absolute top-0 end-0 mt-2 me-2 bg-white" result={copyPhrasebookEntry(entry)} />
    </li>
  );
}

PhrasebookEntry.defaultProps = {
  entry: null,
};

PhrasebookEntry.propTypes = {
  entry: PhrasebookEntryPropType,
};

export default PhrasebookEntry;
