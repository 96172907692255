import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import NavItem from './NavItem';

const variants = {
  tabs: 'nav-tabs',
  underline: 'underline-nav flex-col-gap-sm',
};

function TabbedNav({ navItems, className, innerClassName, itemClassName, variant }) {
  return (
    <ErrorBoundary>
      <nav className={className || ''}>
        <ul className={`nav ${variants[variant]} ${innerClassName || ''}`} role="tablist">
          {navItems.map((navItem) => (
            <NavItem
              key={navItem.href}
              navItem={navItem}
              className={itemClassName}
            />
          ))}
        </ul>
      </nav>
    </ErrorBoundary>
  );
}

TabbedNav.defaultProps = {
  className: null,
  innerClassName: null,
  itemClassName: null,
  variant: 'tabs',
};

TabbedNav.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    match: PropTypes.shape({
      path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]).isRequired,
      exact: PropTypes.bool,
    }),
  })).isRequired,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  variant: PropTypes.oneOf(['tabs', 'underline']),
};

export default TabbedNav;
