// https://github.com/diegohaz/arc/wiki/Reducers
import camelCase from 'lodash/camelCase';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { asyncAction, syncAction } from 'lib/actionCreatorCreators';

// TODO: move this somewhere else....
export const SHOW_SOMETHING_WENT_WRONG = syncAction('SHOW_SOMETHING_WENT_WRONG');
export const CLOSE_SOMETHING_WENT_WRONG = syncAction('CLOSE_SOMETHING_WENT_WRONG');
export const GET_CURRENT_USER = asyncAction('GET_CURRENT_USER');

const reducers = {
  somethingWentWrong(state = false, action) {
    switch (action.type) {
      case SHOW_SOMETHING_WENT_WRONG.SYNC: return true;
      case CLOSE_SOMETHING_WENT_WRONG.SYNC: return false;
      default:
        return state;
    }
  },
  currentUser(state = null, action) {
    switch (action.type) {
      case GET_CURRENT_USER.SUCCESS:
        if (action.meta.cached) return state;
        return action.payload;
      default:
        return state;
    }
  },
};

const req = require.context('.', true, /\.\/.+\/reducer\.js$/);

req.keys().forEach((key) => {
  const storeName = camelCase(key.replace(/\.\/(.+)\/.+$/, '$1'));
  reducers[storeName] = req(key).default;
});

export default (history) => combineReducers({
  router: connectRouter(history),
  ...reducers,
});
