import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { DT_BULK_IMPORT } from 'lib/dateFormats';
import { GroupMembershipPropType } from 'lib/propTypes';
import { sentenceCase } from 'lib/utils';

function ParticipantStatus({ gm, className }) {
  if (!gm) return null;

  return (
    <p className={`${className || 'mt-3 text-small'}`}>
      {sentenceCase(gm.completion)}
      {' '}
      {`${gm.percentageDoneCourse}%`}
      {' • '}
      {gm.isBulkImported ? 'Bulk imported ' : 'Created '}
      on
      {' '}
      {format(parseISO(gm.createdAt), DT_BULK_IMPORT)}
    </p>
  );
}

ParticipantStatus.defaultProps = {
  gm: null,
  className: '',
};

ParticipantStatus.propTypes = {
  gm: GroupMembershipPropType,
  className: PropTypes.string,
};

export default ParticipantStatus;
