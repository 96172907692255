import { removeSingleObjectFromState } from 'lib/reducerHelpers';
import { REDUX_STATUS } from 'lib/constants';
import { GET_ASSIGNMENTS, MARK_CONFLICT, SEND_LETTERS, CANCEL_LETTER, RESET_LETTER_MESSAGE } from './actions';

const initialState = {
  assignments: {
    facilitatorId: undefined,
    byId: {},
    allIds: [],
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
  showSuccessMessage: false,
  alertMessage: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSIGNMENTS.REQUEST:
      return { ...state, assignments: { ...state.assignments, status: REDUX_STATUS.PENDING } };
    case GET_ASSIGNMENTS.SUCCESS: {
      if (action.meta.cached) return { ...state, assignments: { ...state.assignments, status: REDUX_STATUS.SUCCESS } };

      return {
        ...state,
        assignments: {
          facilitatorId: action.payload.userId,
          byId: action.payload.indigenousWelcomeLetterAssignments,
          allIds: action.payload.indigenousWelcomeLetterAssignmentIds,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case MARK_CONFLICT.SUCCESS:
      return {
        ...state,
        assignments: removeSingleObjectFromState(state.assignments, action.payload.assignmentId),
      };
    case SEND_LETTERS.SUCCESS:
      return {
        ...state,
        assignments: {
          ...state.assignments,
          allIds: action.payload.indigenousWelcomeLetterAssignmentIds,
          byId: action.payload.indigenousWelcomeLetterAssignments,
        },
      };
    case CANCEL_LETTER.SUCCESS:
      return {
        ...state,
        assignments: removeSingleObjectFromState(state.assignments, action.payload.assignmentId),
      };
    case RESET_LETTER_MESSAGE.SYNC:
      return { ...state, alertMessage: undefined };
    default:
      return state;
  }
};
