import React from 'react';
import Alert from 'react-bootstrap/Alert';

function PlatformClosureWarning() {
  return (
    <div className="row">
      <div className="col-md-9">
        <p>Turns off the platform for a set amount of time. Participants will still be able to register for courses.</p>

        <p>
          Participants belonging to active groups will not be able to access any training related tabs.
          Participants will not receive any nudges or daily reminders during the time the platform is off.
        </p>

        <Alert variant="warning">
          <i className="fas fa-exclamation-circle me-1" />
          Please remember to manually extend the group close dates for all groups that are in session,
          and adjust the start dates for any groups that are scheduled to start during the closure.
        </Alert>
      </div>
    </div>
  );
}

export default PlatformClosureWarning;
