import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Confirmation from 'components/shared/Modal/Confirmation';
import SanyasIcon from 'components/shared/SanyasIcon';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GET_IWL_STATUS, RESEND_IWL, UPDATE_MARKED_AS_INDIGENOUS } from 'store/groupMemberships/actions';
import { REDUX_STATUS } from 'lib/constants';

const IWL_CONFIRM_TEXT = {
  info: 'Ok',
  stop: 'Stop',
  send: 'Send',
  resend: 'Re-Send',
};

function IndigenousWelcomeLetterAction({ id }) {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const groupMembership = useSelector((state) => state.groupMemberships.data?.find((gm) => gm.id === id));
  const iwlStatus = useSelector((state) => state.groupMemberships.iwlStatus);
  const dispatch = useDispatch();
  const iwlConfirmText = IWL_CONFIRM_TEXT[iwlStatus.data?.action] || 'Ok';

  useEffect(() => {
    if ((!iwlStatus.data || iwlStatus.data?.groupMembershipId !== id) && iwlStatus?.status !== REDUX_STATUS.PENDING) {
      dispatch(GET_IWL_STATUS.request({ id, userId: groupMembership.userId }));
    }
  }, [iwlStatus, dispatch, id, groupMembership.userId]);

  const handleConfirmModalClose = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  const handleIwl = useCallback(() => {
    switch (iwlStatus.data?.action) {
      case 'info':
        handleConfirmModalClose();
        break;
      case 'stop':
        dispatch(UPDATE_MARKED_AS_INDIGENOUS.request({
          id: groupMembership.id,
          userId: groupMembership.userId,
          markedAsIndigenous: false,
        }));
        break;
      case 'send':
        dispatch(UPDATE_MARKED_AS_INDIGENOUS.request({
          id: groupMembership.id,
          userId: groupMembership.userId,
          markedAsIndigenous: true,
        }));
        break;
      case 'resend':
        dispatch(RESEND_IWL.request({
          id: groupMembership.id,
          userId: groupMembership.userId,
        }));
        break;
      default:
        handleConfirmModalClose();
    }
  }, [iwlStatus.data?.action, groupMembership.id, groupMembership.userId, handleConfirmModalClose, dispatch]);

  if (!iwlStatus.data) return null;

  return (
    <Fragment key={iwlStatus.data?.label}>
      <li className="d-flex align-items-baseline mb-3 px-3 text-primary">
        <SanyasIcon name="flower" className="me-2" />
        <span>
          <Button variant="link" className="d-block btn-plain p-0 text-start" onClick={() => setConfirmationModalOpen(true)}>
            {iwlStatus.data?.label}
          </Button>
          <span className="d-block text-small text-secondary">
            {!!iwlStatus.data?.message && (
            <>
              <strong>
                {iwlStatus.data?.status}
                :
              </strong>
              {' '}
              {iwlStatus.data?.message}
            </>
            )}
          </span>
        </span>
      </li>

      <Confirmation
        title={iwlStatus.data?.label}
        open={confirmationModalOpen}
        close={handleConfirmModalClose}
        text={iwlStatus.data?.actionMessage ? iwlStatus.data?.actionMessage : iwlStatus.data?.message}
        confirmAction={handleIwl}
        confirmText={iwlConfirmText}
        cancelText={false}
        delay={400}
      />
    </Fragment>
  );
}

IndigenousWelcomeLetterAction.propTypes = {
  id: PropTypes.number.isRequired,
};

export default IndigenousWelcomeLetterAction;
