import React from 'react';

function Pencil() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22 4.07902C22 3.85182 21.9099 3.63388 21.7495 3.47297V3.47125L19.5293 1.25134C19.3683 1.09041 19.15 1 18.9223 1C18.6946 1 18.4763 1.09041 18.3153 1.25134L16.5244 3.04375L19.9586 6.47749L21.7495 4.68508C21.9099 4.52417 22 4.30623 22 4.07902ZM15.3104 4.25586L18.7446 7.6896V7.69132L7.04604 19.3901C6.95138 19.4839 6.83615 19.5545 6.70949 19.5961L2.56443 20.9782C2.48887 21.0032 2.40782 21.0068 2.33035 20.9884C2.25287 20.9701 2.18203 20.9306 2.12573 20.8743C2.06943 20.818 2.0299 20.7472 2.01155 20.6697C1.99321 20.5922 1.99677 20.5112 2.02183 20.4356L3.40409 16.2911C3.44621 16.1643 3.51736 16.0491 3.61186 15.9546L15.3104 4.25586ZM2 23C2 22.5 2.21967 22.2929 2.21967 22.2929C2.21967 22.2929 2.5 22 3 22H20.5C20.6989 22 21.1397 22.1054 21.2803 22.2929C21.421 22.4804 21.5 22.7348 21.5 23C21.5 23.2652 21.421 23.5196 21.2803 23.7071C21.1397 23.8946 20.6989 24 20.5 24H3C2.5 24 2.36032 23.8946 2.21967 23.7071C2.21967 23.7071 2 23.5 2 23Z" fill="currentColor" />
    </svg>
  );
}

export default Pencil;
