import * as Yup from 'yup';

export const FormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is invalid')
    .required('Email is required'),
  firstName: Yup.string()
    .required('First name is required')
    .test(
      'commas-quotes',
      'First name cannot include commas or double quotes',
      (value) => !value?.includes(',') && !value?.includes('"'),
    ),
  lastName: Yup.string()
    .required('Last name is required')
    .test(
      'commas-quotes',
      'Last name cannot include commas or double quotes',
      (value) => !value?.includes(',') && !value?.includes('"'),
    ),
  healthAuthorityId: Yup.string().required('Health Authority is required'),
  authorizerEmail: Yup.string().email('Email is invalid'),
});
