import React from 'react';
import BaseSkeleton from 'components/shared/Skeleton';

function Skeleton() {
  return (
    <div className="row">
      <div className="col-lg-10 col-xl-9 ps-4">
        <div className="d-flex justify-content-between align-content-center mb-2">
          <BaseSkeleton classes="title w-50" />
          <BaseSkeleton classes="button" />
        </div>

        <div className="border rounded p-3">
          <BaseSkeleton classes="title w-25 mb-1" />
          <BaseSkeleton classes="input border w-100 mb-2" />
          <BaseSkeleton classes="title w-25 mb-1" />
          <BaseSkeleton classes="input border w-100 mb-2" />
        </div>
      </div>
    </div>
  );
}

export default Skeleton;
