import _camelCase from 'lodash/camelCase';
import _isArray from 'lodash/isArray';
import _isPlainObject from 'lodash/isPlainObject';
import _snakeCase from 'lodash/snakeCase';
import _reduce from 'lodash/reduce';
import _startCase from 'lodash/startCase';
import _toLower from 'lodash/toLower';
import _capitalize from 'lodash/capitalize';
import _lowerCase from 'lodash/lowerCase';
import * as R from 'ramda';
import { LEGACY_APP_DOMAIN } from 'config';

export const findDuplicates = (arr) => arr?.filter((item, index) => arr.indexOf(item) !== index);

export const getEmails = R.pluck('email');

const filterHasValue = (v) => v;

export const checkEmailDuplicates = R.pipe(getEmails, R.filter(filterHasValue), findDuplicates, R.uniq);

export function snakeCaseObject(obj, preserveKeys = []) {
  if (!_isPlainObject(obj)) { return obj; }

  return Object.keys(obj).reduce((acc, key) => {
    const currentValue = obj[key];
    const snakeCaseKey = preserveKeys.includes(key) ? key : _snakeCase(key);
    let newValue;
    if (currentValue && _isArray(currentValue)) {
      newValue = currentValue.map((valueInArray) => snakeCaseObject(valueInArray, preserveKeys));
    } else if (currentValue && _isPlainObject(currentValue)) {
      newValue = snakeCaseObject(currentValue, preserveKeys);
    } else {
      newValue = currentValue;
    }
    return { ...acc, [snakeCaseKey]: newValue };
  }, {});
}

export function camelCaseObjectOrArray(objOrArray) {
  if (_isArray(objOrArray)) {
    return camelCaseArray(objOrArray);
  }
  return camelCaseObject(objOrArray);
}

function camelCaseArray(array) {
  return array.reduce((resultArray, obj) => [...resultArray, camelCaseObject(obj)], []);
}

function camelCaseObject(obj, preserveKeys = []) {
  if (!_isPlainObject(obj)) { return obj; }
  return Object.keys(obj).reduce((acc, key) => {
    const currentValue = obj[key];
    const camelCaseKey = preserveKeys.includes(key) ? key : _camelCase(key);
    let newValue;
    if (currentValue && _isArray(currentValue)) {
      newValue = currentValue.map((valueInArray) => camelCaseObject(valueInArray));
    } else if (currentValue && _isPlainObject(currentValue)) {
      newValue = camelCaseObject(currentValue);
    } else if (typeof currentValue !== 'string') {
      newValue = currentValue;
    } else {
      // Remove carriage returns from strings
      // Remove closing slashes from self-closing tags
      // Add noopener to target="_blank" links
      newValue = currentValue
        .replace(/[\r]/g, '')
        .replace(/[\s]?\/>/g, '>')
        .replaceAll('target="_blank"', 'target="_blank" rel="noopener"');
    }
    return { ...acc, [camelCaseKey]: newValue };
  }, {});
}

/*
 * Example: encodeGetURLParamsForRails({foo: "bar"})
 *   -> "&foo=bar"
 * Example: encodeGetURLParamsForRails({foo: {one: 1, two: 2}})
 *   -> "&foo[one]=1&foo[two]=2"
 * Example: encodeGetURLParamsForRails({foo: [1, 2]})
 *   -> "&foo[]=1&foo[]=2"
 */
export function encodeGetURLParamsForRails(urlParams) {
  const snakeCasedUrlParams = snakeCaseObject(urlParams);
  return _reduce(snakeCasedUrlParams, (urlString, value, key) => {
    if (Array.isArray(value)) {
      const arrayOfValues = value;
      const arrayParams = _reduce(arrayOfValues, (acc, curr) => `${acc}&${key}[]=${encodeURIComponent(curr)}`, '');
      return `${urlString}${arrayParams}`;
    }
    if (value instanceof Date) {
      return `${urlString}&${key}=${value.toISOString()}`;
    }
    if (typeof value === 'object') {
      return `${urlString}&${Object.keys(value).map((childKey) => `${key}[${childKey}]=${encodeURIComponent(value[childKey])}`).join('&')}`;
    }
    return `${urlString}&${key}=${encodeURIComponent(value)}`;
  }, '');
}

/*
 * Trim a string and add a ... or some other postfix to it
 * @param str [String] the string to trim
 * @param maxLength [Integer] the maximum number of characters to display (not including the postfix)
 * @param postfix [String] a string to add at the end
 *
 * Ex: trim("Hello there stranger!", 7) -> "Hello t..."
 *     trim("Hello!", 6) -> "Hello!"
 */
export function trim(str, maxLength, postfix = '...') {
  // Don't trim beyond the string's length
  const trimIndex = Math.min(str.length, maxLength);
  if (trimIndex > (maxLength - 1)) {
    return `${str.substring(0, trimIndex)}${postfix}`;
  }
  return str;
}

export const randomHash = () => Math.floor(Math.random() * 16777215).toString(16);

/*
 * Get the label type for completion percentage
 * @param {number} percentage - percentage complete
 * @returns {('default'|'success'|'warning')} default, success, or warning status
*/
export function labelTypeForCompletion(percentage) {
  if (percentage > 0 && percentage < 100) {
    return 'warning';
  }
  if (percentage === 100) {
    return 'success';
  }
  return 'default';
}

export const sentenceCase = (string) => _capitalize(_lowerCase(string));

export const titleCase = (string) => _startCase(_toLower(_snakeCase(string)));

/*
 * Get a time in hh:mm:ss format from an integer of seconds
 * @param {number} seconds
 * @returns {string} time in hh:mm:ss format
*/
export const formatSeconds = (seconds) => {
  let remainingSeconds = seconds;
  const h = Math.floor(remainingSeconds / 3600);
  remainingSeconds %= 3600;
  const m = Math.floor(remainingSeconds / 60);
  remainingSeconds %= 60;
  const s = Math.floor(remainingSeconds);

  return [h, m, s].map((n) => n.toString().padStart(2, '0')).join(':');
};

/*
 * Shallowly replace null values in an object with empty strings
 * @param {Object} obj - object to replace null values in
 * @returns {Object} data - new object with null values replaced with empty strings
*/
export function nullToString(obj) {
  const data = { ...obj };
  Object.keys(data).forEach((key) => {
    if (data[key] === null) {
      data[key] = '';
    }
  });
  return data;
}

/*
 * Shallowly replace empty strings in an object with null values
 * @param {Object} obj - object to replace empty strings in
 * @returns {Object} data - new object with empty strings replaced with null values
*/
export const stringToNull = (obj) => {
  const data = { ...obj };
  Object.keys(data).forEach((key) => {
    if (data[key] === '') {
      data[key] = null;
    }
  });
  return data;
};

/*
 * Get an amount in dollar format from an integer of cents
 * @param {number} cents
 * @returns {string} dollars in 0.00 format
*/
export const formatCentsToDollars = (cents) => {
  const value = parseFloat(cents);
  return value ? value / 100 : 0;
};

/*
 * Get a 1- or 2-letter string from a number
 *   1–26: a–z; 27–52: aa–az; 53–78: ba–bz; etc.
 *
 * Less than 1 has no corresponding letter
 * (26 × 26) + 26 = 702: zz
 *
 * If the modulus is 0, the first letter is the previous letter in the alphabet (div - 1)
 *   and the second letter is z (26)
 *
 * @param {number} integer
 * @returns {string} 1- or 2-letter string
*/
export function toLetter(number) {
  if (!Number.isInteger(number) || number < 1 || number > 702) {
    throw new Error('toLetter() only accepts positive integers less than 703');
  }

  const mod = number % 26;
  const div = parseInt(number / 26, 10);

  return number < 27 ? String.fromCharCode(96 + parseInt(number, 10)) : String.fromCharCode(96 + (mod ? div : div - 1)) + String.fromCharCode(96 + (mod || 26));
}

/*
 * Format HTTP POST request body as FormData
  * @param {Object} payload - object to format
  * @param {Array} preserveKeys - keys to not snake_case
  * @returns {FormData} body - FormData object
 */
export function formDataBody(payload, preserveKeys = []) {
  const body = new FormData();

  Object.entries(payload).forEach(([key, value]) => {
    const snakeCaseKey = preserveKeys.includes(key) ? key : _snakeCase(key);
    body.append(snakeCaseKey, value);
  });

  return body;
}

/*
 * Normalize an email address
 * - lowercase
 * - trim whitespace
 * - mirrors Services::Email::Normalize
 * @param {email} email address
 * @returns {string} normalized email address
*/
export function normalizeEmail(email) {
  return email.toLowerCase().replace(/\s/g, '');
}

/*
  * Get a locale suffix for a given locale
  * @param {string} locale
  * @returns {string} locale suffix
*/
export function getLocaleSuffix(locale) {
  return locale !== 'en' ? _capitalize(locale) : '';
}

/*
  * Get the bottom height of the element
  * @param {HTMLElement} element
  * @returns {number}
*/
export const getElementBottomHeight = (element) => {
  if (!element) return Infinity;
  const rect = element?.getBoundingClientRect();
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  return rect.bottom + scrollTop;
};

/*
  Content formatting functions
*/

/*
 * Format a Glossary entry to be copied to the clipboard
 * @param {Object} entry - Glossary entry to format
 * @returns {string} formatted entry
 */
export function copyGlossaryEntry({ term }) {
  if (!term) return null;
  return `{{${term}}}`;
}

/*
 * Format a Phrasebook entry to be copied to the clipboard
 * @param {Object} entry - Phrasebook entry to format
 * @returns {string} formatted entry
 */
export function copyPhrasebookEntry({ title, key }) {
  if (!title || !key) return null;
  return `[[ "type": "phrase", "text": "${title}", "key": "${key}", "width": "auto" ]]`;
}

/*
 * Format a resend confirmation link to be copied to the clipboard
 * @param {Object} registrationAttrs - registration attributes
 * @returns {string} formatted entry
 */
export function copyResendConfirmationLink({ userId, permalink, token }) {
  if (!userId || !permalink || !token) return null;
  return `${LEGACY_APP_DOMAIN}/register/continue/${permalink}/${userId}/${token}`;
}
