import React, { useEffect } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MODULE_EDIT_PATH, MODULE_NEW_PATH, TASK_NEW_PATH, TASK_EDIT_PATH } from 'lib/routerPaths';
import { LOAD_COURSE, LOAD_COURSE_SECTIONS } from 'store/courses/actions';
import CurriculumCourseTemplate from 'components/templates/CurriculumCourseTemplate';
import { LOAD_COURSE_TYPES } from 'store/courseTypes/actions';
import CourseShowProvider from './context';
import ModuleEditPage from '../ModuleEditPage';
import TaskEditPage from '../TaskEditPage';

function CourseShowPage() {
  const { courseId } = useParams();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const course = useSelector((state) => state.courses.data?.[courseId]);
  const sections = useSelector((state) => state.courses.sections);
  const firstSection = sections?.[course?.sectionIds?.[0]];

  useEffect(() => {
    dispatch(LOAD_COURSE_TYPES.request());
  }, [dispatch]);

  useEffect(() => {
    if (!course?.id) {
      dispatch(LOAD_COURSE.request(courseId));
    }
  }, [course?.id, courseId, dispatch]);

  useEffect(() => {
    dispatch(LOAD_COURSE_SECTIONS.request(courseId));
  }, [courseId, dispatch]);

  return (
    <CourseShowProvider>
      <CurriculumCourseTemplate>
        <Switch>
          <Route path={[MODULE_NEW_PATH, MODULE_EDIT_PATH]}>
            <ModuleEditPage />
          </Route>
          <Route path={[TASK_NEW_PATH, TASK_EDIT_PATH]}>
            <TaskEditPage />
          </Route>
          <Route path={path}>
            {firstSection?.taskIds?.length ? <TaskEditPage /> : <ModuleEditPage />}
          </Route>
        </Switch>
      </CurriculumCourseTemplate>
    </CourseShowProvider>
  );
}

export default CourseShowPage;
