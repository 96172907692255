import React, { useState, useEffect, useCallback, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { REDUX_STATUS } from 'lib/constants';
import { DESTROY_RESOURCE, RESET_RESOURCE } from 'store/courses/actions';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import SanyasIcon from 'components/shared/SanyasIcon';
import ErrorMessage from 'components/shared/ErrorMessage';
import AddResources from 'components/curriculum/MediaAndResources/AddResources';
import MediaResource from './MediaResource';
import './styles.scss';

function fadeOutHighlight(el) {
  setTimeout(() => {
    el.classList.remove('resource-enter-done');
  }, 800);
}

function MediaAndResources() {
  const { taskId } = useParams();
  const mediaResources = useSelector((state) => state.courses.tasks?.[taskId]?.mediaResources);
  const { status, error } = useSelector((state) => state.courses.resource);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const dispatch = useDispatch();

  const handleDelete = useCallback(() => {
    if (!selectedResource) return;
    dispatch(DESTROY_RESOURCE.request({ ...selectedResource, taskId: Number(taskId) }));
  }, [dispatch, taskId, selectedResource]);

  const handleModalClose = useCallback(() => {
    dispatch(RESET_RESOURCE.action());
    setShowDeleteModal(false);
    setSelectedResource(null);
  }, [dispatch]);

  useEffect(() => {
    if (status && status === REDUX_STATUS.SUCCESS) {
      handleModalClose();
    }
  }, [status, handleModalClose]);

  if (!taskId) return null;

  return (
    <div className="border rounded mt-2 py-3 px-2">
      <h6 className="px-2">Media and Resources</h6>

      {mediaResources?.length > 0 && (
        <TransitionGroup key={taskId} component={null}>
          {mediaResources.map((resource) => {
            const nodeRef = createRef(null);
            return (
              <CSSTransition
                key={resource.id}
                nodeRef={nodeRef}
                timeout={300}
                classNames="resource"
                onEntered={() => fadeOutHighlight(nodeRef.current)}
              >
                <MediaResource innerRef={nodeRef} key={resource.id} resource={resource} setShowDeleteModal={setShowDeleteModal} setSelectedResource={setSelectedResource} />
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      )}

      <AddResources />

      <DefaultModal
        isOpen={showDeleteModal}
        header="Delete resource"
        onClose={handleModalClose}
        footerComponent={(
          <Button variant="danger" onClick={handleDelete} disabled={status === REDUX_STATUS.PENDING}>
            Delete
            {status === REDUX_STATUS.PENDING && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
          </Button>
        )}
      >
        <ErrorMessage error={error} className="mt-1" />

        <p>Are you sure you want to delete this resource?</p>

        {selectedResource && (
          <p className="text-small">
            {selectedResource?.type && (
              <SanyasIcon name={selectedResource.type} className="me-1" />
            )}
            {`${selectedResource?.name}: ${selectedResource?.filename || selectedResource?.path}`}
          </p>
        )}
      </DefaultModal>
    </div>
  );
}

export default MediaAndResources;
