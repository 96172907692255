import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

function ArticleKey({ articleKey }) {
  const { articleId } = useParams();

  if (!articleId) return null;

  return (
    <div className="border rounded mt-2 py-3 px-3">
      <div className="d-flex align-items-center">
        <h6 className="mb-0">Key</h6>

        <span className="ms-auto text-primary text-small">
          {articleKey ?? '...'}
        </span>
      </div>
    </div>
  );
}

ArticleKey.defaultProps = {
  articleKey: null,
};

ArticleKey.propTypes = {
  articleKey: PropTypes.string,
};

export default ArticleKey;
