import React from 'react';
import PropTypes from 'prop-types';
import { SIZES } from 'lib/constants';
import { ComponentPropType } from 'lib/propTypes';
import FieldWrapper from './FieldWrapper';
import ClearableInput from './ClearableInput';

/* eslint-disable react/jsx-props-no-spreading */

function ClearableInputField(props) {
  const { id, name, label, helpText, infoText, size, hideLabel, required, initialValidation, showTooltip, className, onFieldChange, onFieldBlur, ...inputProps } = props;

  return (
    <FieldWrapper
      id={id}
      name={name}
      label={label}
      helpText={helpText}
      infoText={infoText}
      size={size}
      hideLabel={hideLabel}
      showTooltip={showTooltip}
      required={required}
      initialValidation={initialValidation}
      onFieldChange={onFieldChange}
      onFieldBlur={onFieldBlur}
      className={className}
    >
      <ClearableInput
        name={name}
        size={size}
        required={required}
        {...inputProps}
      />
    </FieldWrapper>
  );
}

ClearableInputField.defaultProps = {
  id: null,
  label: null,
  helpText: null,
  infoText: null,
  size: SIZES.md,
  hideLabel: false,
  required: false,
  initialValidation: null,
  showTooltip: false,
  className: null,
  onFieldChange: null,
  onFieldBlur: null,
  addOnStart: null,
  addOnEnd: null,
};

ClearableInputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  infoText: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
  initialValidation: PropTypes.string,
  showTooltip: PropTypes.bool,
  onFieldChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
  addOnStart: PropTypes.oneOfType([PropTypes.string, ComponentPropType]),
  addOnEnd: PropTypes.oneOfType([PropTypes.string, ComponentPropType]),
  className: PropTypes.string,
};

export default ClearableInputField;
