import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { REORDER_COURSE_SECTION, REORDER_COURSE_SECTION_TASK, RESET_SECTION } from 'store/courses/actions';
import { MODULE_NEW_PATH } from 'lib/routerPaths';
import { buildRoutePath } from 'lib/routerHelpers';
import Accordion from 'react-bootstrap/Accordion';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import Module from './Module';
import Skeleton from './Skeleton';

/* eslint-disable react/jsx-props-no-spreading */

function CurriculumEditNav() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { course, setCurrentSection, activeKey, setActiveKey } = useCourseShowContext();
  const dispatch = useDispatch();
  const buttonRef = useRef();

  // 57px s/be the height of the button, but we'll calculate it when the button is rendered
  const defaultButtonHeight = course?.isLocked ? 0 : 57;

  const moduleNewPath = buildRoutePath(MODULE_NEW_PATH, { courseId: course?.id });
  const newSection = (pathname === moduleNewPath || !course?.sectionIds?.length) ? (course?.sectionIds?.length || 0) + 1 : 0;

  const handleClick = useCallback(() => {
    setCurrentSection(null);
    setActiveKey(newSection);
    dispatch(RESET_SECTION.action());
    history.push(moduleNewPath);
  }, [moduleNewPath, newSection, setCurrentSection, setActiveKey, dispatch, history]);

  const handleSelect = useCallback((key) => {
    setActiveKey(key);
  }, [setActiveKey]);

  const handleDragEnd = useCallback(({ destination, source, draggableId, type }) => {
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    if (type === 'section') {
      dispatch(REORDER_COURSE_SECTION.action({ courseId: destination.droppableId, sectionId: draggableId, fromIndex: source.index, toIndex: destination.index }));
    } else if (type === 'task') {
      dispatch(REORDER_COURSE_SECTION_TASK.action({ sectionId: destination.droppableId, taskId: draggableId, fromIndex: source.index, toIndex: destination.index }));
    }
  }, [dispatch]);

  if (!course?.id) {
    return (
      <Skeleton />
    );
  }

  return (
    <>
      {course?.id && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={`${course?.id}`} type="section">
            {(provided) => (
              <Accordion
                className="outline"
                style={{
                  height: `calc(100% - ${buttonRef.current?.offsetHeight || defaultButtonHeight}px)`,
                  overflowY: 'scroll',
                }}
                flush
                onSelect={handleSelect}
                activeKey={activeKey}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {course?.sectionIds?.map((id, index) => <Module key={`${id}`} sectionId={id} index={index} />)}

                {/* New module */}
                {newSection > 0 && (
                  <Accordion.Item eventKey={newSection}>
                    <h3 className="accordion-header accordion-header-active d-flex align-items-center">
                      {/* Drag handle plus padding = 26px */}
                      <span className="accordion-title" style={{ paddingLeft: '26px' }}>
                        <span className="accordion-position">{`${newSection}. `}</span>
                        New module
                      </span>
                      <Accordion.Button className="w-auto" style={{ backgroundColor: 'transparent' }} />
                    </h3>
                  </Accordion.Item>
                )}

                {provided.placeholder}
              </Accordion>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {!course?.isLocked && (
        <button
          ref={buttonRef}
          className={`btn btn-primary ${course.sectionIds?.length ? 'btn-plain' : 'rounded-0'} w-100 text-start text-nowrap border-top py-3`}
          type="button"
          onClick={handleClick}
        >
          + Add module
        </button>
      )}
    </>
  );
}

export default CurriculumEditNav;
