import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import uaParser from 'ua-parser-js';
import parseISO from 'date-fns/parseISO';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { REDUX_STATUS } from 'lib/constants';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';
import { RESET_USER } from 'store/user/actions';
import { LOAD_GROUP_MEMBERSHIP, RESET_GROUP_MEMBERSHIPS } from 'store/groupMemberships/actions';
import { RESET_LETTER_MESSAGE } from 'store/indigenousWelcomeLetters/actions';
import RBSpinner from 'react-bootstrap/Spinner';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import TabbedNav from 'components/shared/Navigation/TabbedNav';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import Spinner from 'components/shared/Spinner';
import SanyasIcon from 'components/shared/SanyasIcon';
import ParticipantBadge from 'components/shared/Badge/ParticipantBadge';
import Skeleton from 'components/shared/Skeleton';
import ErrorMessage from 'components/shared/ErrorMessage';
import ProfileTabPage from './ProfileTabPage';

function ParticipantsShowPage() {
  const { groupMembershipId } = useParams();
  const user = useSelector((state) => state.user);
  const groupMemberships = useSelector((state) => state.groupMemberships);
  const dispatch = useDispatch();

  const groupMembership = groupMemberships.data?.[0];
  const { browser, os } = !!groupMembership?.userAgent && uaParser(groupMembership.userAgent);

  useEffect(() => {
    dispatch(LOAD_GROUP_MEMBERSHIP.request({ id: groupMembershipId }));
  }, [groupMembershipId, dispatch]);

  useEffect(() => () => {
    dispatch(RESET_USER.action());
    dispatch(RESET_GROUP_MEMBERSHIPS.action());
    dispatch(RESET_LETTER_MESSAGE.action());
  }, [dispatch]);

  return (
    <FacilitatorTemplate>
      {groupMemberships.error ? (
        <ErrorMessage error={groupMemberships.error} className="my-4" />
      ) : (
        <div className="d-flex flex-column flush">
          <div className="header pb-0">
            <div className="d-flex align-items-center flex-col-gap-sm">
              <SanyasIcon name="person" size="3x" />
              <div>
                <Breadcrumbs
                  navItems={[
                    { label: 'Participants' },
                    { label: user.data?.fullName ?? <Skeleton classes="text" /> },
                  ]}
                />

                <div className="d-flex align-items-center mb-2">
                  <h1 className="mb-0">
                    { user.data?.fullName ?? <Skeleton classes="title" /> }
                  </h1>

                  {(user.status === REDUX_STATUS.PENDING || groupMemberships.status === REDUX_STATUS.PENDING) && (
                  <RBSpinner variant="primary" size="sm" className="ms-1" animation="border" role="status" />
                  )}
                  <span className="ms-2 text-small">
                    <ParticipantBadge
                      state={groupMembership?.completion}
                      completion={groupMembership?.decoratedCompletion}
                      activation={user.data?.activation}
                    />
                    <span className="ms-2">
                      {!!groupMembership?.lastSeenAt && `Last seen ${formatDistanceToNow(parseISO(groupMembership.lastSeenAt))} ago |`}
                      {(browser && os) && ` ${browser.name} ${browser.version}, ${os.name} ${os.version}`}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className="position-relative">
              <TabbedNav
                variant="underline"
                navItems={[
                  {
                    title: 'Profile',
                    href: `/facilitator/participants/${groupMembershipId}`,
                    match: {
                      path: FACILITATOR_PARTICIPANTS_SHOW_PATH,
                      exact: true,
                    },
                  },
                ]}
              />
            </div>
          </div>

          <Switch>
            <Route path="*">
              {([groupMemberships.status, user.status].includes(REDUX_STATUS.PENDING) || !groupMembership) ? <div className="m-4"><Spinner /></div> : <ProfileTabPage user={user} gm={groupMembership} hasGroupMembership={groupMemberships.data?.length > 0} />}
            </Route>
          </Switch>
        </div>
      )}
    </FacilitatorTemplate>
  );
}

export default ParticipantsShowPage;
