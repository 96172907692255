const HOSTS = {
  'localhost:3000': 'DEV',
  'next-qa.sanyas.ca': 'QA',
  'next-stg.sanyas.ca': 'STG',
  'ics2.sanyas.ca': 'PROD',
};

const LEGACY_DOMAINS = {
  DEV: 'http://localhost:3001',
  QA: 'https://legacy-stg.sanyas.ca',
  STG: 'https://legacy-stg.sanyas.ca',
  PROD: 'https://ics.sanyas.ca',
};

// Default to STG, if the host is not found in the HOSTS object
// This is to prevent the app from breaking if the host is not found,
// and to prevent the app from using the PROD legacy app
export const LEGACY_APP_DOMAIN = LEGACY_DOMAINS[HOSTS[window.location.host] || 'STG'];
