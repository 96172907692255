import React, { useEffect } from 'react';
import SanyasIcon from 'components/shared/SanyasIcon';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';

function Introduction() {
  const { currentArticle, setCurrentArticle } = useCourseShowContext();

  useEffect(() => {
    if (currentArticle?.id) {
      setCurrentArticle(null);
    }
  }, [currentArticle?.id, setCurrentArticle]);

  return (
    <>
      <SanyasIcon name="infoCircle" size="lg" className="pt-1 text-info" />
      <span className="ms-2">
        <strong>What is Self-care?</strong>
        {' '}
        Self-care describes a conscious act one takes in order to promote their own physical, mental, and emotional health. There are many forms self-care may take. It could be ensuring you get enough sleep every night or stepping outside for a few minutes for some fresh air.
      </span>
    </>
  );
}

export default Introduction;
