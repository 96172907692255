import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_SHOW_PATH, MODULE_EDIT_PATH } from 'lib/routerPaths';
import usePrevious from 'lib/hooks/usePrevious';
import { SAVE_SECTION, CREATE_SECTION, DELETE_SECTION } from 'store/courses/actions';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner';
import InputField from 'components/shared/FormFields/InputField';
import ErrorMessage from 'components/shared/ErrorMessage';
import Confirmation from 'components/shared/Modal/Confirmation';
import SanyasIcon from 'components/shared/SanyasIcon';
import Skeleton from 'components/curriculum/Module/Skeleton';
import Confirm from 'components/curriculum/CurriculumEditNav/Confirm';
import { useCourseShowContext } from '../CourseShowPage/context';

function ModuleEditPage() {
  const { sectionId } = useParams();
  const { course, currentSection, setCurrentSection, setCurrentTask, setActiveKey } = useCourseShowContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { error: courseError, status: courseStatus, sections, section: activeSection } = useSelector((state) => state.courses);
  const prevSectionSuccess = usePrevious(activeSection.success);
  const section = sections?.[sectionId];
  const firstSection = sections?.[course?.sectionIds?.[0]];

  const [confirmOpen, setConfirmOpen] = useState(false);

  const sectionsLoaded = useMemo(
    () => {
      if (!course?.sectionIds?.length) return true;
      return sections && _isEqual(Object.keys(sections).map((s) => Number(s)), course.sectionIds);
    },
    [course?.sectionIds, sections],
  );

  useEffect(() => {
    if (!prevSectionSuccess && activeSection.success === REDUX_SUCCESS.CREATED) {
      setActiveKey(activeSection.data?.id);
      history.push(buildRoutePath(MODULE_EDIT_PATH, { courseId: course?.id, sectionId: activeSection.data?.id }));
    }
  }, [prevSectionSuccess, activeSection.success, activeSection.data?.id, course?.id, setActiveKey, history]);

  useEffect(() => {
    if (section && Number(sectionId) !== currentSection?.id) {
      setCurrentSection(section);
      setActiveKey(Number(sectionId));
    }
  }, [sectionId, currentSection?.id, section, setCurrentSection, setActiveKey]);

  useEffect(() => {
    // Course Show or New Module
    if (path === CURRICULUM_COURSE_SHOW_PATH && course?.id && firstSection?.id) {
      history.replace(buildRoutePath(MODULE_EDIT_PATH, { courseId: course?.id, sectionId: firstSection?.id }));
    }
  }, [sectionId, course?.id, firstSection?.id, history, path]);

  useEffect(() => {
    setCurrentTask(null);
  }, [setCurrentTask]);

  const handleSubmit = useCallback((values, actions) => {
    if (values.id) {
      dispatch(SAVE_SECTION.request(values, { formikActions: actions }));
    } else {
      dispatch(CREATE_SECTION.request(values, { formikActions: actions }));
    }
  }, [dispatch]);

  const confirmDelete = useCallback(() => {
    setConfirmOpen(true);
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(DELETE_SECTION.request(currentSection?.id));
    setCurrentSection(null);
    setActiveKey(null);
    history.push(buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course?.id }));
  }, [course?.id, currentSection?.id, dispatch, history, setCurrentSection, setActiveKey]);

  const FormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  const initialValues = {
    name: section?.name ?? '',
    nameFr: section?.nameFr ?? '',
    courseId: course?.id,
    id: Number(sectionId) || null,
  };

  if (!course?.id || courseStatus === REDUX_STATUS.PENDING || !sectionsLoaded) {
    return <Skeleton />;
  }

  return (
    <>
      <ErrorMessage error={courseError || activeSection.error} className="mt-2" />

      <Formik
        onSubmit={handleSubmit}
        validationSchema={FormSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ dirty, isSubmitting }) => (
          <Form className="row">
            {dirty && <Confirm />}

            <div className="col-lg-10 col-xl-9 ps-4">
              <div className="d-flex justify-content-between align-content-center mb-2">
                <h2 className="m-0">
                  {initialValues.id ? `Edit: ${initialValues.name}` : 'New module'}
                </h2>
                <div>
                  <Button
                    size="sm"
                    variant={(initialValues.id && !dirty) ? 'secondary' : 'primary'}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                    {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
                  </Button>

                  {!!currentSection && (
                    <Dropdown align="end" className="d-inline-block ms-2 py-0">
                      <Dropdown.Toggle variant="secondary" className="btn-sm">
                        Actions
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={confirmDelete} className={`d-flex align-items-center ${currentSection.isLocked ? 'text-muted' : 'text-danger'}`} disabled={currentSection.isLocked}>
                          <SanyasIcon name="trashCan" className="me-1" />
                          Delete module
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
              </div>

              <div className="border rounded p-3">
                <InputField placeholder="e.g. Introduction" label="Title (English)" name="name" autoFocus={!initialValues.id} />

                <InputField placeholder="e.g. Introduction" label="Title (French)" name="name_fr" />
              </div>

              {(course?.id && !course.sectionIds?.length) && (
                <div className="mt-5 text-center">
                  <img src="/images/curriculum/course-empty-state.svg" alt="Empty state" className="w-25" />
                  <p className="text-muted">Start building a course by creating your first module.</p>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>

      <Confirmation
        variant="danger"
        title="Delete module?"
        open={confirmOpen}
        close={() => setConfirmOpen(false)}
        text={(
          <>
            <p
              className="bg-danger bg-opacity-25"
              style={{
                margin: 'calc(-1 * var(--bs-modal-padding)) calc(-1 * var(--bs-modal-padding)) 1rem',
                padding: '0.25rem var(--bs-modal-padding)',
              }}
            >
              Deleting this module will delete
              {' '}
              <strong>all tasks</strong>
              {' '}
              within the module as well.
              <br />
              This action cannot be undone.
            </p>

            <p className="d-flex justify-content-between">
              <strong>Confirm</strong>
              <span className="ps-2">Please confirm that you want to delete this module.</span>
            </p>
          </>
        )}
        confirmText="Delete"
        confirmAction={handleDelete}
        cancelText={false}
        delay={800}
      />
    </>
  );
}

export default ModuleEditPage;
