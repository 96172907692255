import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATUS } from 'lib/constants';
import { UserPropType } from 'lib/propTypes';
import { LOAD_EMAIL_CONFIRMATIONS } from 'store/user/actions';
import { Alert } from 'react-bootstrap';
import Spinner from 'components/shared/Spinner';
import EmailsTable from 'components/users/EmailsTable';

function EmailsTabPage({ user }) {
  const emailConfirmations = useSelector((state) => state.user.emailConfirmations);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && !emailConfirmations.data && emailConfirmations.status !== REDUX_STATUS.PENDING) {
      dispatch(LOAD_EMAIL_CONFIRMATIONS.request({ id: user.id }));
    }
  }, [user, emailConfirmations, dispatch]);

  return (
    <div className="my-4 px-4">
      <p className="lead">Confirmations received from Postmark that an email was sent.</p>

      {emailConfirmations.status === REDUX_STATUS.PENDING && <Spinner />}

      {emailConfirmations.status === REDUX_STATUS.SUCCESS && (
        <EmailsTable emailConfirmations={emailConfirmations.data} />
      )}

      {emailConfirmations.status === REDUX_STATUS.ERROR && (
        <Alert variant="warning">
          <span className="fas fa-exclamation-triangle fa-fw me-1" />
          Unable to load email confirmations
        </Alert>
      )}
    </div>
  );
}

EmailsTabPage.defaultProps = {
  user: null,
};

EmailsTabPage.propTypes = {
  user: UserPropType,
};

export default EmailsTabPage;
