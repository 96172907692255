import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FindAndReplaceDataPropType, CoursePropType } from 'lib/propTypes';
import { LEGACY_APP_DOMAIN } from 'config';
import { useDispatch } from 'react-redux';
import { FIND_AND_REPLACE_UPDATE } from 'store/courses/actions';

function ReviewChange({ data, taskId, findTerm, replaceTerm, index, course }) {
  const dispatch = useDispatch();

  const handleUpdate = useCallback(
    () => {
      dispatch(FIND_AND_REPLACE_UPDATE.request({ findTerm, replaceTerm, taskId }));
    },
    [dispatch, findTerm, replaceTerm, taskId],
  );

  return (
    <>
      <h3>
        <a
          href={`${LEGACY_APP_DOMAIN}/tasks/${taskId}`}
          className="task-link"
          target="_blank"
          rel="noreferrer"
        >
          {`${index + 1}. ${data.name}`}
        </a>
      </h3>
      <div className="col-md-6">
        Current
        {/* eslint-disable-next-line react/no-danger */}
        <div className="highlighted mt-1" dangerouslySetInnerHTML={{ __html: data.highlighted }} />

        <div className="row">
          <div className="col d-flex">
            <Button target="_blank" size="sm" className="ms-auto" href={`/curriculum/versions/${course?.id}/tasks/${taskId}`} variant="secondary">
              Edit
            </Button>
          </div>
        </div>

      </div>
      {(!(replaceTerm === '')) && (
        <div className="col-md-6">
          Change to
          {/* eslint-disable-next-line react/no-danger */}
          <div className="highlighted mt-1" dangerouslySetInnerHTML={{ __html: data.replaced }} />

          <div className="row">
            <div className="col d-flex">
              <Button size="sm" className="ms-auto" onClick={handleUpdate}>
                Update
              </Button>
            </div>
          </div>
        </div>
      )}

    </>
  );
}

ReviewChange.propTypes = {
  data: FindAndReplaceDataPropType.isRequired,
  taskId: PropTypes.number.isRequired,
  findTerm: PropTypes.string.isRequired,
  replaceTerm: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  course: CoursePropType.isRequired,
};

export default ReviewChange;
