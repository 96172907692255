import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

function CompletionThreshold({ seconds }) {
  const { taskId } = useParams();

  if (!taskId) return null;

  return (
    <div className="border rounded mt-2 py-3 px-3">
      <div className="d-flex align-items-center">
        <h6 className="mb-0">Completion Threshold</h6>
        <span className="ms-auto text-primary text-small">
          {`${seconds ?? '...'} sec`}
        </span>
      </div>
    </div>
  );
}

CompletionThreshold.defaultProps = {
  seconds: null,
};

CompletionThreshold.propTypes = {
  seconds: PropTypes.number,
};

export default CompletionThreshold;
