import React from 'react';
import PropTypes from 'prop-types';
import { LOCALES, TASK_TYPES } from 'lib/constants';
import { getLocaleSuffix } from 'lib/utils';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import SanyasIcon from 'components/shared/SanyasIcon';

function OptionsTable({ locale, options }) {
  const { currentTask } = useCourseShowContext();

  return (
    <table className="options-table">
      <thead>
        <tr>
          <th style={{ width: '1rem' }}>{}</th>
          <th style={{ height: '1rem', width: `${currentTask?.type === TASK_TYPES.MULTIPLE_CHOICE_QUIZ ? '80%' : null}` }}>{}</th>
          {currentTask?.type === TASK_TYPES.MULTIPLE_CHOICE_QUIZ && (
            <th className="text-center text-nowrap px-3">
              Correct answer
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {options?.map((option) => (
          <tr key={option.id}>
            <td>{/* will be populated with the option # using CSS */}</td>
            <td>
              {option[`body${getLocaleSuffix(locale)}`]}
            </td>

            {currentTask?.type === TASK_TYPES.MULTIPLE_CHOICE_QUIZ && (
              <td className="text-center">
                {option.correct ? <SanyasIcon name="checkmark" /> : ''}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

OptionsTable.defaultProps = {
  locale: 'en',
  options: [],
};

OptionsTable.propTypes = {
  locale: PropTypes.oneOf(Object.values(LOCALES)),
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    body: PropTypes.string,
    bodyFr: PropTypes.string,
    correct: PropTypes.bool,
  })),
};

export default OptionsTable;
