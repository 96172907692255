import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectGlossaryEntryById } from 'store/courses/selectors';

function GlossaryEntryDetail({ entry }) {
  const glossaryEntry = useSelector((state) => selectGlossaryEntryById(state, entry)) || {};

  return (
    <div className="sticky-top">
      <div className="d-flex align-items-center py-3 px-4 border-bottom">
        <h4 className="m-0 text-truncate">{glossaryEntry.term}</h4>
      </div>

      <div className="py-3 px-4">
        {/* eslint-disable-next-line react/no-danger */}
        <div className="text-break" dangerouslySetInnerHTML={{ __html: glossaryEntry.definition }} />

        {glossaryEntry.isFrench && <p className="mt-3 text-small text-secondary">This is a French entry</p>}
      </div>
    </div>
  );
}

GlossaryEntryDetail.propTypes = {
  entry: PropTypes.number.isRequired,
};

export default GlossaryEntryDetail;
