import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { QUESTION_TASK_TYPES, REDUX_STATUS, TASK_TYPES } from 'lib/constants';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_TASK_PATH, FACILITATOR_COURSE_PATH } from 'lib/routerPaths';
import { LOAD_TASK_QUESTIONS, RESET_TASK_QUESTIONS, LOAD_TASK_PROACTIVE, RESET_TASK_PROACTIVE } from 'store/courses/actions';
import { selectCourseTypeIsAvailableInFrench } from 'store/courseTypes/selectors';
import { Nav, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import JournalTask from 'components/facilitator/Courses/JournalTask';
import PhrasebookLink from 'components/curriculum/Phrasebook/TaskPageLink';
import GlossaryLink from 'components/curriculum/Glossary/TaskPageLink';
import MediaAndResources from 'components/facilitator/Courses/MediaAndResources';
import CompletionThreshold from 'components/facilitator/Courses/CompletionThreshold';
import ProactiveTemplate from 'components/facilitator/Courses/ProactiveTemplate';
import Questions from 'components/facilitator/Courses/Questions/Questions';
import Skeleton from 'components/facilitator/Courses/TaskSkeleton';
import ActionsMenu from 'components/curriculum/ActionsMenu';

function TaskPage() {
  const { taskId } = useParams();
  const { course, currentSection, setCurrentSection, currentTask, setCurrentTask, setActiveKey } = useCourseShowContext();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { data: courses, sections, status: courseStatus, tasks } = useSelector((state) => state.courses);
  const task = tasks?.[taskId];
  const firstSectionId = courses[course.id]?.sectionIds[0];
  const firstTaskId = sections?.[firstSectionId]?.taskIds[0];
  const section = sections?.[task?.sectionId] || currentSection || sections?.[firstSectionId];
  const { data: questions } = useSelector((state) => state.courses.questions);
  const { data: proactiveTemplate } = useSelector((state) => state.courses.proactiveTemplate);
  const isAvailableInFrench = useSelector((state) => selectCourseTypeIsAvailableInFrench(state, course?.courseTypeId));
  const [key, setKey] = useState('english');

  useEffect(() => {
    if (section && section?.id !== currentSection?.id) {
      setCurrentSection(section);
      setActiveKey(section?.id);
    }
  }, [sections, section, currentSection?.id, firstSectionId, setCurrentSection, setActiveKey]);

  useEffect(() => {
    if (task && task?.id !== currentTask?.id) {
      setCurrentTask(task);
      if (QUESTION_TASK_TYPES.includes(task?.type)) {
        dispatch(LOAD_TASK_QUESTIONS.request({ id: task.id, workspace: 'facilitator' }));
      } else if (task?.type && questions?.length) {
        dispatch(RESET_TASK_QUESTIONS.action());
      }

      if (task?.type === TASK_TYPES.POD_DISCUSSION) {
        dispatch(LOAD_TASK_PROACTIVE.request({ id: task.id, workspace: 'facilitator' }));
      } else if (task?.type && !_isEmpty(proactiveTemplate)) {
        dispatch(RESET_TASK_PROACTIVE.action());
      }
    }
  }, [task, currentTask, setCurrentTask, questions?.length, proactiveTemplate, dispatch]);

  useEffect(() => {
    // Course Show or New Task w/o Section
    if ((path === FACILITATOR_COURSE_PATH || (!taskId && !currentSection?.id)) && course?.id && firstTaskId) {
      history.replace(buildRoutePath(FACILITATOR_TASK_PATH, { courseId: course?.id, taskId: firstTaskId }));
    }
  }, [taskId, currentSection?.id, course?.id, firstTaskId, history, path]);

  if (!course?.id || courseStatus === REDUX_STATUS.PENDING) {
    return <Skeleton />;
  }

  return (
    <div className="row">
      <div className={`col-12 d-flex justify-content-between ${isAvailableInFrench ? 'border-bottom' : ''}`}>
        {isAvailableInFrench && (
          <Nav
            variant="tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            style={{ marginBottom: -1 }}
          >
            <Nav.Item>
              <Nav.Link eventKey="english">English</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="french">Français</Nav.Link>
            </Nav.Item>
          </Nav>
        )}
        {!isAvailableInFrench && (
          <h2 className="mb-0">
            {currentTask?.name}
            <br />
            <small className="text-secondary">{currentTask?.summary}</small>
          </h2>
        )}

        <ActionsMenu itemId={currentTask?.id} type="task" courseId={course?.id} />
      </div>

      <div className="col-12">
        <div className={`row ${isAvailableInFrench ? 'mt-2' : ''}`}>
          <div className={`col-xxl-9 ${isAvailableInFrench ? 'my-3' : ''}`}>
            <TabContainer activeKey={key}>
              <TabContent>
                <TabPane eventKey="english">
                  {isAvailableInFrench && (
                    <h2>
                      {currentTask?.name}
                      <br />
                      <small className="text-secondary">{currentTask?.summary}</small>
                    </h2>
                  )}

                  {/* eslint-disable-next-line react/no-danger */}
                  <div className="col-12 mt-3 p-3 border rounded bg-light clearfix" dangerouslySetInnerHTML={{ __html: currentTask?.description }} />

                  {currentTask?.type === TASK_TYPES.JOURNAL && (<JournalTask />)}
                  {currentTask?.type === TASK_TYPES.POD_DISCUSSION && <ProactiveTemplate />}
                  {[TASK_TYPES.SURVEY, TASK_TYPES.MULTIPLE_CHOICE_QUIZ].includes(currentTask?.type) && (<Questions />)}
                </TabPane>
              </TabContent>

              {isAvailableInFrench && (
                <TabContent>
                  <TabPane eventKey="french">
                    <h2>
                      {currentTask?.nameFr || currentTask?.name}
                      {currentTask?.summaryFr && (
                        <>
                          <br />
                          <small className="text-secondary">{currentTask?.summaryFr}</small>
                        </>
                      )}
                    </h2>

                    {/* eslint-disable-next-line react/no-danger */}
                    <div className="col-12 mt-3 p-3 border rounded bg-light clearfix" dangerouslySetInnerHTML={{ __html: currentTask?.descriptionFr }} />

                    {currentTask?.type === TASK_TYPES.JOURNAL && (<JournalTask locale="fr" />)}
                    {[TASK_TYPES.SURVEY, TASK_TYPES.MULTIPLE_CHOICE_QUIZ].includes(currentTask?.type) && (<Questions locale="fr" />)}
                  </TabPane>
                </TabContent>
              )}
            </TabContainer>
          </div>

          <div className="col-xxl-3 pt-2">
            <CompletionThreshold seconds={currentTask?.completionThresholdInSeconds} />
            <MediaAndResources />

            <GlossaryLink />
            <PhrasebookLink />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskPage;
