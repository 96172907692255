import React from 'react';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import PtqReportsForm from 'components/reporting/PtqReports/PtqReportsForm';
import PtqReportsList from 'components/reporting/PtqReports/PtqReportsList';
import Breadcrumbs from 'components/shared/Breadcrumbs';

function PtqReport() {
  return (
    <RegistrarTemplate>
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Reports', href: '/registrar/reports' },
                { label: 'Platform-Wide PTQ' },
              ]}
            />

            <h1 className="mb-0">Platform-Wide PTQ Report</h1>
          </div>
        </div>
      </div>

      <div className="flush px-4 my-4">
        <p className="lead">
          Request a new report, view reports that are in-progress, and
          download completed reports.
        </p>

        <p>
          Reports are generated in the background. Once a report is
          requested, this page can be closed and the report will continue
          generating. This page is auto-updating and will display reports as
          they are in-progress and eventually completed. Completed reports
          can be downloaded in comma-separated value (CSV) format.
        </p>

        <p>
          Including multiple groups or a large date range will result in a
          report taking longer to generate, and may end up failing. If you
          need to generate a report for a large date range, consider
          requesting multiple reports for smaller date ranges.
        </p>

        <PtqReportsForm />

        <PtqReportsList />
      </div>
    </RegistrarTemplate>
  );
}

export default PtqReport;
