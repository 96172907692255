import React from 'react';

function Textarea() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_246_4925)">
        <path fillRule="evenodd" clipRule="evenodd" d="M21 13.5C21.3978 13.5 21.7794 13.342 22.0607 13.0607C22.342 12.7794 22.5 12.3978 22.5 12C22.5 11.6022 22.342 11.2206 22.0607 10.9393C21.7794 10.658 21.3978 10.5 21 10.5C20.6022 10.5 20.2206 10.658 19.9393 10.9393C19.658 11.2206 19.5 11.6022 19.5 12C19.5 12.3978 19.658 12.7794 19.9393 13.0607C20.2206 13.342 20.6022 13.5 21 13.5ZM21 15C21.7956 15 22.5587 14.6839 23.1213 14.1213C23.6839 13.5587 24 12.7956 24 12C24 11.2044 23.6839 10.4413 23.1213 9.87868C22.5587 9.31607 21.7956 9 21 9C20.2044 9 19.4413 9.31607 18.8787 9.87868C18.3161 10.4413 18 11.2044 18 12C18 12.7956 18.3161 13.5587 18.8787 14.1213C19.4413 14.6839 20.2044 15 21 15ZM3 13.5C3.39782 13.5 3.77936 13.342 4.06066 13.0607C4.34196 12.7794 4.5 12.3978 4.5 12C4.5 11.6022 4.34196 11.2206 4.06066 10.9393C3.77936 10.658 3.39782 10.5 3 10.5C2.60218 10.5 2.22064 10.658 1.93934 10.9393C1.65804 11.2206 1.5 11.6022 1.5 12C1.5 12.3978 1.65804 12.7794 1.93934 13.0607C2.22064 13.342 2.60218 13.5 3 13.5ZM3 15C3.79565 15 4.55871 14.6839 5.12132 14.1213C5.68393 13.5587 6 12.7956 6 12C6 11.2044 5.68393 10.4413 5.12132 9.87868C4.55871 9.31607 3.79565 9 3 9C2.20435 9 1.44129 9.31607 0.87868 9.87868C0.316071 10.4413 0 11.2044 0 12C0 12.7956 0.316071 13.5587 0.87868 14.1213C1.44129 14.6839 2.20435 15 3 15Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.25 3.75C2.25 3.15326 2.48705 2.58097 2.90901 2.15901C3.33097 1.73705 3.90326 1.5 4.5 1.5H19.5C20.0967 1.5 20.669 1.73705 21.091 2.15901C21.5129 2.58097 21.75 3.15326 21.75 3.75V9.75H20.25V3.75C20.25 3.55109 20.171 3.36032 20.0303 3.21967C19.8897 3.07902 19.6989 3 19.5 3H4.5C4.30109 3 4.11032 3.07902 3.96967 3.21967C3.82902 3.36032 3.75 3.55109 3.75 3.75V9.75H2.25V3.75ZM3.75 14.25V20.25C3.75 20.4489 3.82902 20.6397 3.96967 20.7803C4.11032 20.921 4.30109 21 4.5 21H19.5C19.6989 21 19.8897 20.921 20.0303 20.7803C20.171 20.6397 20.25 20.4489 20.25 20.25V14.25H21.75V20.25C21.75 20.8467 21.5129 21.419 21.091 21.841C20.669 22.2629 20.0967 22.5 19.5 22.5H4.5C3.90326 22.5 3.33097 22.2629 2.90901 21.841C2.48705 21.419 2.25 20.8467 2.25 20.25V14.25H3.75Z" fill="currentColor" />
        <path d="M17.151 6H6.849L6.75 8.991H7.329C7.644 7.113 8.247 6.822 10.5885 6.7485L11.103 6.732V16.2465C11.103 17.052 10.929 17.244 9.5295 17.3685V18H14.4705V17.3685C13.0635 17.244 12.8895 17.0535 12.8895 16.2465V6.732L13.4115 6.747C15.7515 6.822 16.356 7.113 16.671 8.991H17.25L17.151 6Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_4925">
          <rect width="100%" height="100%" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Textarea;
