import React from 'react';
import { useSelector } from 'react-redux';
import { format, isValid, parseISO } from 'date-fns';
import { DT_PLATFORM_STATUS } from 'lib/dateFormats';
import { Alert } from 'react-bootstrap';

const formatDate = (date, dateFormat = DT_PLATFORM_STATUS) => {
  const parsedDate = parseISO(date);
  return isValid(parsedDate) ? format(parsedDate, dateFormat) : 'unknown date';
};

function PlatformStatus() {
  const { closePlatformFrom, closePlatformUntil, platformClosed = false } = useSelector((state) => state.platformClosure.status.data) || {};

  return platformClosed && (
    <Alert variant="warning" className="mt-2">
      {`Platform is closed from ${formatDate(closePlatformFrom)} to ${formatDate(closePlatformUntil)}.`}
    </Alert>
  );
}

export default PlatformStatus;
