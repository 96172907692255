import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from './FieldWrapper';
import Textarea from './Textarea';

/* eslint-disable react/jsx-props-no-spreading */

function TextareaField(props) {
  const { id, name, label, helpText, infoText, hideLabel, inline, onFieldChange, required, initialValidation, onFieldBlur, className, ...inputProps } = props;

  return (
    <FieldWrapper
      id={id}
      name={name}
      label={label}
      helpText={helpText}
      infoText={infoText}
      hideLabel={hideLabel}
      inline={inline}
      required={required}
      initialValidation={initialValidation}
      onFieldChange={onFieldChange}
      onFieldBlur={onFieldBlur}
      className={className}
    >
      <Textarea
        name={name}
        required={required}
        {...inputProps}
      />
    </FieldWrapper>
  );
}

TextareaField.defaultProps = {
  id: null,
  label: null,
  helpText: null,
  infoText: null,
  hideLabel: false,
  inline: false,
  required: false,
  initialValidation: null,
  className: null,
  onFieldChange: null,
  onFieldBlur: null,
};

TextareaField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  infoText: PropTypes.string,
  hideLabel: PropTypes.bool,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  initialValidation: PropTypes.string,
  className: PropTypes.string,
  onFieldChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
};

export default TextareaField;
