import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_PATH, CURRICULUM_COURSE_TYPE_SHOW_PATH, MODULE_EDIT_PATH, CURRICULUM_COURSE_SHOW_PATH, FACILITATOR_CURRICULUM_PATH, FACILITATOR_COURSE_TYPE_PATH, FACILITATOR_COURSE_PATH, FACILITATOR_MODULE_PATH } from 'lib/routerPaths';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import SharedBreadcrumbs from 'components/shared/Breadcrumbs';
import Skeleton from 'components/shared/Skeleton';
import { useCourseShowContext } from './context';

const BREADCRUMB_PATHS = {
  curriculum: {
    COURSES: CURRICULUM_PATH,
    COURSE_TYPE: CURRICULUM_COURSE_TYPE_SHOW_PATH,
    COURSE: CURRICULUM_COURSE_SHOW_PATH,
    MODULE: MODULE_EDIT_PATH,
  },
  facilitator: {
    COURSES: FACILITATOR_CURRICULUM_PATH,
    COURSE_TYPE: FACILITATOR_COURSE_TYPE_PATH,
    COURSE: FACILITATOR_COURSE_PATH,
    MODULE: FACILITATOR_MODULE_PATH,
  },
};

function Breadcrumbs({ selfCare }) {
  const { id: workspaceId } = useWorkspace();
  const { course, currentSection, currentTask, currentArticle } = useCourseShowContext();
  const courseType = useSelector((state) => selectCourseTypeById(state, course?.courseTypeId));

  const navItems = useMemo(() => {
    const localNavItems = [
      { label: 'Courses', href: BREADCRUMB_PATHS[workspaceId].COURSES },
      { label: courseType?.name || <Skeleton classes="text" />, href: buildRoutePath(BREADCRUMB_PATHS[workspaceId].COURSE_TYPE, { id: courseType?.id }) },
      { label: course?.displayName || <Skeleton classes="text" />, href: buildRoutePath(BREADCRUMB_PATHS[workspaceId].COURSE, { courseId: course?.id }) },
    ];

    if (currentSection && !currentTask) {
      localNavItems.push({ label: currentSection?.name || <Skeleton classes="text" /> });
    }

    if (currentSection && currentTask) {
      localNavItems.push({ label: currentSection?.name || <Skeleton classes="text" />, href: buildRoutePath(BREADCRUMB_PATHS[workspaceId].MODULE, { courseId: course?.id, sectionId: currentSection?.id }) });
      localNavItems.push({ label: currentTask?.name || <Skeleton classes="text" /> });
    }

    if (selfCare && !currentArticle) {
      localNavItems.push({ label: 'Self Care' || <Skeleton classes="text" /> });
    }

    if (currentArticle) {
      localNavItems.push({ label: 'Self Care' || <Skeleton classes="text" />, href: `${buildRoutePath(BREADCRUMB_PATHS[workspaceId].COURSE, { courseId: course?.id })}/self_care` });
      localNavItems.push({ label: currentArticle?.nameEn || <Skeleton classes="text" /> });
    }

    return localNavItems;
  }, [courseType, course, currentSection, currentTask, selfCare, currentArticle, workspaceId]);

  return (
    <SharedBreadcrumbs
      className="my-3"
      navItems={navItems}
      ignoreLast={!currentSection && !currentTask && !currentArticle}
    />
  );
}

Breadcrumbs.defaultProps = {
  selfCare: false,
};

Breadcrumbs.propTypes = {
  selfCare: PropTypes.bool,
};

export default Breadcrumbs;
