import React from 'react';
import PropTypes from 'prop-types';
import { GlossaryEntryPropType } from 'lib/propTypes';
import Button from 'react-bootstrap/Button';

function GlossaryEntry({ entry, setSelectedEntry }) {
  if (!entry) return null;

  return (
    <li className="list-group-item py-3 ps-4 position-relative">
      <Button variant={null} className="w-100 m-0 py-2 ps-0 text-start h4 text-black" onClick={() => setSelectedEntry(entry.id)}>
        {entry.term}
      </Button>
    </li>
  );
}

GlossaryEntry.defaultProps = {
  entry: null,
};

GlossaryEntry.propTypes = {
  entry: GlossaryEntryPropType,
  setSelectedEntry: PropTypes.func.isRequired,
};

export default GlossaryEntry;
