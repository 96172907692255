import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType } from 'lib/propTypes';
import Button from 'react-bootstrap/Button';
import { Spinner } from 'react-bootstrap';
import DefaultModal from './DefaultModal';

function Confirmation(props) {
  const { open, close, title, text, textSecondary, variant, confirmText, confirmAction, cancelText, delay, delayText } = props;
  const [inProgress, setInProgress] = useState(false);

  const handleConfirm = useCallback(() => {
    confirmAction();
    if (delay) {
      setInProgress(true);
      setTimeout(() => {
        setInProgress(false);
        close();
      }, delay);
    } else {
      close();
    }
  }, [confirmAction, close, delay]);

  let footer;

  if (variant === 'primary') {
    footer = (
      <>
        {!!cancelText && (
          <Button variant="outline-secondary" onClick={close} disabled={inProgress}>
            {cancelText}
          </Button>
        )}

        <Button
          variant="primary"
          onClick={handleConfirm}
          disabled={inProgress}
        >
          {(inProgress && delayText) ? delayText : confirmText}
          {inProgress && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
        </Button>
      </>
    );
  } else if (variant === 'danger') {
    footer = (
      <>
        <Button
          variant="danger"
          onClick={handleConfirm}
          disabled={inProgress}
        >
          {(inProgress && delayText) ? delayText : confirmText}
          {inProgress && <Spinner size="sm" className="" animation="border" role="status" />}
        </Button>

        {!!cancelText && (
          <Button variant="outline-secondary" onClick={close} disabled={inProgress}>
            {cancelText}
          </Button>
        )}
      </>
    );
  }

  return (
    <DefaultModal
      isOpen={open}
      onClose={close}
      header={title}
      footerComponent={footer}
    >
      {text}
      {!!textSecondary && <div className="mt-2">{textSecondary}</div>}
    </DefaultModal>
  );
}

Confirmation.defaultProps = {
  title: 'Confirm',
  text: 'Are you sure?',
  textSecondary: null,
  variant: 'primary',
  confirmText: 'Confirm',
  confirmAction: () => {},
  cancelText: 'Cancel',
  delay: null,
  delayText: null,
};

Confirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: ComponentPropType,
  textSecondary: ComponentPropType,
  variant: PropTypes.oneOf(['primary', 'danger']),
  confirmText: PropTypes.string,
  confirmAction: PropTypes.func,
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  delay: PropTypes.number,
  delayText: PropTypes.string,
};

export default Confirmation;
