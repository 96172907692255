import { REDUX_STATUS } from 'lib/constants';
import { LOAD_USER, UPDATE_USER, RESET_USER, LOAD_EMAIL_CONFIRMATIONS, RESET_LOGIN, RESET_USER_STATUS, UPDATE_USER_ACTIVATION } from './actions';

const initialState = {
  status: REDUX_STATUS.IDLE,
  data: undefined,
  error: undefined,
  emailConfirmations: {
    status: REDUX_STATUS.IDLE,
    data: undefined,
    error: undefined,
  },
  resetLogin: {
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
        error: undefined,
      };
    case LOAD_USER.SUCCESS: {
      if (action.meta.cached) return { ...state, status: REDUX_STATUS.SUCCESS };

      return {
        ...state,
        data: action.payload,
        status: REDUX_STATUS.SUCCESS,
      };
    }
    case LOAD_USER.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case UPDATE_USER.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
        error: undefined,
      };
    case UPDATE_USER.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        status: REDUX_STATUS.SUCCESS,
      };
    }
    case UPDATE_USER.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case LOAD_EMAIL_CONFIRMATIONS.REQUEST:
      return {
        ...state,
        emailConfirmations: {
          ...state.emailConfirmations,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case LOAD_EMAIL_CONFIRMATIONS.SUCCESS: {
      if (action.meta.cached) return { ...state, emailConfirmations: { ...state.emailConfirmations, status: REDUX_STATUS.SUCCESS } };

      return {
        ...state,
        emailConfirmations: {
          ...state.emailConfirmations,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case LOAD_EMAIL_CONFIRMATIONS.ERROR:
      return {
        ...state,
        emailConfirmations: {
          ...state.emailConfirmations,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_LOGIN.REQUEST:
      return {
        ...state,
        resetLogin: {
          ...state.resetLogin,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case RESET_LOGIN.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        resetLogin: {
          ...state.resetLogin,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case RESET_LOGIN.ERROR:
      return {
        ...state,
        resetLogin: {
          ...state.resetLogin,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case UPDATE_USER_ACTIVATION.SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case UPDATE_USER_ACTIVATION.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case RESET_USER_STATUS.SYNC:
      return {
        ...state,
        status: REDUX_STATUS.IDLE,
        error: undefined,
        emailConfirmations: {
          ...state.emailConfirmations,
          status: REDUX_STATUS.IDLE,
          error: undefined,
        },
        resetLogin: {
          ...state.resetLogin,
          status: REDUX_STATUS.IDLE,
          error: undefined,
        },
      };

    case RESET_USER.SYNC:
      return initialState;

    default:
      return state;
  }
};
