import React from 'react';
import Table from 'react-bootstrap/Table';
import BaseSkeleton from 'components/shared/Skeleton';

function Skeleton() {
  return (
    <Table bordered hover className="sn-table bg-white mb-0">
      <thead>
        <tr>
          <th>
            <BaseSkeleton classes="title width-half" />
          </th>
          <th>
            <BaseSkeleton classes="title width-100" />
          </th>
          <th>
            <BaseSkeleton classes="title width-100" />
          </th>
          <th>
            <BaseSkeleton classes="title width-100" />
          </th>
          <th>
            <BaseSkeleton classes="title width-100" />
          </th>
          <th>
            <BaseSkeleton classes="title width-100" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><BaseSkeleton classes="text" /></td>
          <td><BaseSkeleton classes="text" /></td>
          <td><BaseSkeleton classes="text" /></td>
          <td><BaseSkeleton classes="text" /></td>
          <td><BaseSkeleton classes="text" /></td>
          <td><BaseSkeleton classes="button" /></td>
        </tr>
        <tr>
          <td><BaseSkeleton classes="text" /></td>
          <td><BaseSkeleton classes="text" /></td>
          <td><BaseSkeleton classes="text" /></td>
          <td><BaseSkeleton classes="text" /></td>
          <td><BaseSkeleton classes="text" /></td>
          <td><BaseSkeleton classes="button" /></td>
        </tr>
      </tbody>
    </Table>
  );
}

export default Skeleton;
