import React from 'react';

function Person() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13 14C13 14 14 14 14 13C14 12 13 9 8 9C3 9 2 12 2 13C2 14 3 14 3 14H13ZM3.005 13.056V13.054V13.056ZM3.022 13H12.978C12.9827 12.9995 12.9874 12.9988 12.992 12.998L13 12.996C12.999 12.75 12.846 12.01 12.168 11.332C11.516 10.68 10.289 10 8 10C5.71 10 4.484 10.68 3.832 11.332C3.154 12.01 3.002 12.75 3 12.996C3.00732 12.9974 3.01465 12.9987 3.022 13ZM12.996 13.056V13.054V13.056ZM8 7C8.53043 7 9.03914 6.78929 9.41421 6.41421C9.78929 6.03914 10 5.53043 10 5C10 4.46957 9.78929 3.96086 9.41421 3.58579C9.03914 3.21071 8.53043 3 8 3C7.46957 3 6.96086 3.21071 6.58579 3.58579C6.21071 3.96086 6 4.46957 6 5C6 5.53043 6.21071 6.03914 6.58579 6.41421C6.96086 6.78929 7.46957 7 8 7ZM11 5C11 5.79565 10.6839 6.55871 10.1213 7.12132C9.55871 7.68393 8.79565 8 8 8C7.20435 8 6.44129 7.68393 5.87868 7.12132C5.31607 6.55871 5 5.79565 5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5Z" fill="currentColor" />
    </svg>
  );
}

export default Person;
