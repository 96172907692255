import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { GET_SUB_GROUPS, UPDATE_SELECTED_RECIPIENTS, SET_PRESELECTED_USER_ID, SEND_MESSAGES } from 'store/actions';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import Spinner from 'components/shared/Spinner';
import RecipientSelect from 'components/facilitator/RecipientSelect';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';

function MessagesNewPage({ userId, availableSubGroups }) {
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const dispatch = useDispatch();

  const updateSelectedRecipients = (values) => {
    dispatch(UPDATE_SELECTED_RECIPIENTS.action(values));
  };

  const sendMessages = () => {
    dispatch(SEND_MESSAGES.request({ subject, message }));
  };

  useEffect(() => {
    dispatch(GET_SUB_GROUPS.request());
    if (userId) {
      dispatch(SET_PRESELECTED_USER_ID.action({ userId }));
    }
  }, [dispatch, userId]);

  const availableRecipients = useSelector((state) => state.messages.compose);
  const preselectedRecipient = (availableRecipients && userId && availableRecipients.find((r) => r.userId === userId));
  const currentUser = useSelector((state) => state.currentUser);

  if (!currentUser || !availableRecipients) {
    return <FacilitatorTemplate className="messages-page" style={{ marginTop: 10 }}><Spinner message="Loading..." /></FacilitatorTemplate>;
  }
  return (
    <FacilitatorTemplate className="messages-new-page">
      <h1>New Message</h1>

      <Breadcrumbs
        navItems={
           [
             { label: 'Messages', href: '/facilitator/messages' },
             { label: 'New Message', href: '#' },
           ]
         }
      />

      { availableSubGroups.length === 0
          && (
          <div className="row row-space-top">
            <div className="col-md-12">
              <p>You cannot compose a message to participants, because you are not currently facilitating any groups.</p>
            </div>
          </div>
          )}

      { availableSubGroups.length > 0
          && (
          <div>
            <div className="row row-space-top">
              <div className="col-md-12">
                <strong>From:</strong>
                {' '}
                {currentUser.firstName}
                {' '}
                {currentUser.lastName}
              </div>
            </div>

            <div className="row row-space-top">
              <div className="col-md-12">
                <strong>To: </strong>
                { !preselectedRecipient
                  && (
                  <RecipientSelect
                    availableSubGroups={availableSubGroups}
                    availableRecipients={availableRecipients}
                    onChange={updateSelectedRecipients}
                  />
                  )}
                <span>{ preselectedRecipient && preselectedRecipient.fullName }</span>
              </div>
            </div>

            <div className="row row-space-top">
              <div className="col-md-12">
                <input type="text" className="form-control" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
              </div>
            </div>

            <div className="row row-space-top">
              <div className="col-md-12">
                <textarea className="form-control" rows="10" placeholder="Type message here" value={message} onChange={(e) => setMessage(e.target.value)} />
              </div>
            </div>

            <div className="row row-space-top">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => { e.preventDefault(); sendMessages(); }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
          )}
    </FacilitatorTemplate>
  );
}

const RecipientPropType = PropTypes.shape({
  id: PropTypes.number,
  userId: PropTypes.number,
  fullName: PropTypes.string,
  subGroupId: PropTypes.number,
});

MessagesNewPage.defaultProps = {
  userId: null,
  availableSubGroups: [],
  preSelectedRecipient: null,
};

MessagesNewPage.propTypes = {
  userId: PropTypes.number,
  availableSubGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    groupName: PropTypes.string,
    beginSessionAt: PropTypes.string,
  })),
  preSelectedRecipient: RecipientPropType,
};

export default MessagesNewPage;
