import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { LOAD_RESERVATIONS, UPDATE_RESERVATIONS } from 'store/actions';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { GroupPropType } from 'lib/propTypes';
import { Alert } from 'react-bootstrap';
import ErrorMessage from 'components/shared/ErrorMessage';
import Spinner from 'react-bootstrap/Spinner';
import { REDUX_STATUS } from 'lib/constants';

const FormSchema = (maxParticipants) => Yup.object().shape({
  reservations: Yup.array().of(
    Yup.object().shape({
      seats: Yup.number().optional('Seats are required'),
    }).test('totalSeats', `Total seats must not exceed Max Participants ${maxParticipants}`, (value, context) => {
      const totalSeats = context.parent.reduce((total, reservation) => total + parseInt(reservation.seats || 0, 10), 0);
      return totalSeats <= maxParticipants;
    }),
  ),
});

function GroupSettingsReservationsPage({ group }) {
  const dispatch = useDispatch();
  const handleSubmit = useCallback((values, { resetForm }) => {
    const returnVal = { ...values, groupId: group.id };
    dispatch(UPDATE_RESERVATIONS.request(returnVal));
    resetForm();
  }, [dispatch, group.id]);

  const orgId = group.organizationId;
  const haById = useSelector((state) => state.healthAuthorities.byId);
  const { status, error, byId: reservationsById } = useSelector((state) => state.groupShow.reservations);
  const healthAuthorities = Object.values(haById).filter(({ organizationId, parentId }) => organizationId === orgId && !parentId);
  const reservations = useMemo(() => healthAuthorities.map((healthAuthority) => {
    const reservation = reservationsById[healthAuthority.id];
    if (reservation) {
      return (
        {
          healthAuthorityName: healthAuthority.name,
          seats: reservation.seats,
          healthAuthorityId: healthAuthority.id,
          id: reservation.id,
          _delete: false,
        }
      );
    }
    return (
      {
        healthAuthorityName: healthAuthority.name,
        seats: 0,
        healthAuthorityId: healthAuthority.id,
        _delete: false,
      }
    );
  }), [healthAuthorities, reservationsById]);

  useEffect(() => {
    dispatch(LOAD_RESERVATIONS.request({ groupId: group.id }));
  }, [group.id, dispatch]);

  if (status === REDUX_STATUS.PENDING) return <Spinner />;

  return (
    <>
      <ErrorMessage error={error} />
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ reservations } || []}
        validationSchema={FormSchema(group.maxParticipants)}
        enableReinitialize
        validateOnBlur
      >
        {({ values, resetForm, errors }) => (
          <Form>
            <ul className="list-unstyled list-striped">
              {errors.reservations && errors.reservations.length > 0 && (
              <Alert variant="danger" className="py-2">{errors.reservations[0]}</Alert>
              )}
              {values.reservations.map((reservation, index) => (
                <li
                  key={reservation.healthAuthorityId}
                  className="d-flex align-items-center"
                >
                  <label htmlFor={`reservations-${index}-seats`} className="col-4 col-md-3 text-truncate pe-2">{reservation.healthAuthorityName}</label>
                  <div className="col-2 col-xl-1 me-2">
                    <Field
                      className="form-control"
                      id={`reservations-${index}-seats`}
                      min="0"
                      type="number"
                      name={`reservations.${index}.seats`}
                      value={reservation.seats}
                    />
                  </div>

                  <div className="ms-3">
                    <Field name={`reservations.${index}._delete`} id={`reservations.${index}._delete`} className="form-check-input me-2" type="checkbox" />
                    <label htmlFor={`reservations.${index}._delete`}>Delete</label>
                  </div>
                </li>
              ))}
            </ul>

            <div>
              <button disabled={Object.keys(errors).length > 0} type="submit" className="btn btn-primary me-3">Save</button>
              <button type="button" onClick={() => resetForm()} className="btn btn-plain">Reset form</button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

GroupSettingsReservationsPage.defaultProps = {
  error: null,
};

GroupSettingsReservationsPage.propTypes = {
  group: GroupPropType.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default GroupSettingsReservationsPage;
