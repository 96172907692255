import React from 'react';
import { LEGACY_APP_DOMAIN } from 'config';
import { GroupMembershipPropType } from 'lib/propTypes';
import { titleCase } from 'lib/utils';

function JournalDetails({ gm }) {
  return (
    <li className="py-2 px-3">
      <a
        href={`${LEGACY_APP_DOMAIN}/group_memberships/${gm.id}/journal_tasks`}
        target="_blank"
        rel="noreferrer"
        title={titleCase(gm.completion)}
        className="text-decoration-none"
      >
        {`${gm.firstName} ${gm.lastName}`}
      </a>
    </li>
  );
}

JournalDetails.propTypes = {
  gm: GroupMembershipPropType.isRequired,
};

export default JournalDetails;
