import React from 'react';

function Link45() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.7156 6.542L3.3436 7.914C2.78094 8.47666 2.46484 9.23978 2.46484 10.0355C2.46484 10.8312 2.78094 11.5943 3.3436 12.157C3.90625 12.7197 4.66938 13.0358 5.4651 13.0358C6.26081 13.0358 7.02394 12.7197 7.5866 12.157L9.4146 10.328C9.75389 9.98866 10.0068 9.57287 10.1522 9.11555C10.2975 8.65822 10.3311 8.1727 10.25 7.69974C10.1688 7.22677 9.97546 6.78016 9.68603 6.3974C9.3966 6.01464 9.01957 5.7069 8.5866 5.5L8.0006 6.086C7.9411 6.14559 7.88936 6.21245 7.8466 6.285C8.18113 6.38117 8.48476 6.56298 8.72749 6.81246C8.97022 7.06194 9.14364 7.37044 9.2306 7.70748C9.31756 8.04452 9.31506 8.39842 9.22333 8.73419C9.13161 9.06996 8.95384 9.37598 8.7076 9.622L6.8806 11.45C6.50532 11.8253 5.99633 12.0361 5.4656 12.0361C4.93487 12.0361 4.42588 11.8253 4.0506 11.45C3.67532 11.0747 3.46449 10.5657 3.46449 10.035C3.46449 9.50427 3.67532 8.99528 4.0506 8.62L4.8436 7.828C4.73171 7.40873 4.68849 6.9741 4.7156 6.541V6.542Z" fill="currentColor" />
      <path d="M5.71177 6.95977L5.87877 6.79277C6.12653 6.5441 6.43565 6.36539 6.77477 6.27477C6.86539 5.93564 7.0441 5.62652 7.29277 5.37877L7.45977 5.21177C6.95674 5.18349 6.45467 5.28218 5.99977 5.49877C5.77977 5.95877 5.68377 6.46177 5.71177 6.95877V6.95977Z" fill="currentColor" />
      <path d="M6.58556 4.67209C6.24627 5.01143 5.99333 5.42721 5.84797 5.88454C5.70261 6.34186 5.66909 6.82739 5.7502 7.30035C5.83131 7.77332 6.0247 8.21992 6.31413 8.60268C6.60356 8.98544 6.98059 9.29319 7.41356 9.50009L8.18856 8.72409C7.84951 8.63314 7.54036 8.45456 7.2922 8.20629C7.04403 7.95802 6.86557 7.6488 6.77477 7.30971C6.68397 6.97062 6.68402 6.6136 6.77491 6.27453C6.8658 5.93547 7.04433 5.62629 7.29256 5.37809L9.11956 3.55009C9.49484 3.1748 10.0038 2.96397 10.5346 2.96397C11.0653 2.96397 11.5743 3.1748 11.9496 3.55009C12.3248 3.92537 12.5357 4.43436 12.5357 4.96509C12.5357 5.49581 12.3248 6.0048 11.9496 6.38009L11.1566 7.17209C11.2686 7.59209 11.3116 8.02709 11.2846 8.45909L12.6566 7.08709C13.2192 6.52443 13.5353 5.7613 13.5353 4.96559C13.5353 4.16987 13.2192 3.40674 12.6566 2.84409C12.0939 2.28143 11.3308 1.96533 10.5351 1.96533C9.73934 1.96533 8.97622 2.28143 8.41356 2.84409L6.58556 4.67209Z" fill="currentColor" />
      <path d="M9.99906 9.50004C10.2166 9.04542 10.3156 8.5432 10.2871 8.04004L10.1201 8.20704C9.87231 8.45571 9.56319 8.63442 9.22406 8.72504C9.13344 9.06416 8.95473 9.37329 8.70606 9.62104L8.53906 9.78804C9.0421 9.81632 9.54416 9.71763 9.99906 9.50104V9.50004Z" fill="currentColor" />
    </svg>
  );
}

export default Link45;
