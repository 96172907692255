import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import SuperFacilitatorTemplate from 'components/templates/SuperFacilitatorTemplate';
import Spinner from 'components/shared/Spinner';

function SuperFacilitatorReports() {
  const currentUser = useSelector((state) => state.currentUser) || {};
  const hasAccess = currentUser.isSuperFacilitator || currentUser.isSuperAdmin || currentUser.isRegistrar;

  return (
    <SuperFacilitatorTemplate className="dashboard-page">
      <div className="header flush">
        <h1 className="mb-0">Reports</h1>
      </div>

      <div className="flush px-4 my-4">
        {_isEmpty(currentUser) && (
          <Spinner />
        )}

        {(!hasAccess && !_isEmpty(currentUser)) && (
          <p className="mb-3">You do not have access to any reports</p>
        )}

        {hasAccess && (
          <>
            <div className="d-flex align-items-center mb-3">
              <Link to="/lead_facilitator/reports/discussion_board" className="btn-clean btn-centered btn-clean-wide">
                <i className="icon-md fas fa-tachometer-alt" />
                <span className="btn-label">Discussion Board Report</span>
              </Link>

              <p className="mb-0 ms-3">
                View statistics about facilitator discussion board responses.
              </p>
            </div>

            <div className="d-flex align-items-center mb-3">
              <Link to="/lead_facilitator/reports/facilitator" className="btn-clean btn-centered btn-clean-wide" aria-label="Faciliator Journal Report">
                <i className="icon-md fas fa-chart-bar" />
                <span className="btn-label">Facilitator Journal Report</span>
              </Link>

              <p className="mb-0 ms-3">
                View statistics about facilitator journal responses.
              </p>
            </div>
          </>
        )}
      </div>
    </SuperFacilitatorTemplate>
  );
}

export default SuperFacilitatorReports;
