import React from 'react';

function ToastCheck() {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="9" fill="#5ADC8E" />
      <path d="M8.14097 11.5152C8.10614 11.5501 8.06476 11.5778 8.0192 11.5967C7.97364 11.6156 7.9248 11.6254 7.87547 11.6254C7.82615 11.6254 7.77731 11.6156 7.73175 11.5967C7.68619 11.5778 7.64481 11.5501 7.60997 11.5152L7.46855 11.6566L7.46837 11.6564L4.84355 9.03162C4.73563 8.92369 4.675 8.77732 4.675 8.62469C4.675 8.47207 4.73563 8.3257 4.84355 8.21777C4.95147 8.10985 5.09785 8.04922 5.25047 8.04922C5.4031 8.04922 5.54947 8.10985 5.6574 8.21777L5.65742 8.2178L7.87549 10.4366L12.7184 5.59295L12.86 5.73419L12.7185 5.59278L12.7184 5.59291C12.7718 5.53938 12.8353 5.49691 12.9051 5.46793C12.975 5.43894 13.0498 5.42402 13.1255 5.42402C13.2011 5.42402 13.276 5.43894 13.3459 5.46793C13.4157 5.49692 13.4792 5.5394 13.5326 5.59295L13.391 5.73419L13.5322 5.5926C13.5858 5.64601 13.6282 5.70946 13.6572 5.77932C13.6862 5.84917 13.7011 5.92406 13.7011 5.99969C13.7011 6.07533 13.6862 6.15022 13.6572 6.22007C13.6283 6.28985 13.5859 6.35324 13.5324 6.40662C13.5323 6.40668 13.5323 6.40674 13.5322 6.40679L8.28257 11.6564L8.28239 11.6566L8.14097 11.5152ZM8.14097 11.5152L13.391 6.26519L8.14097 11.5152Z" fill="white" stroke="white" strokeWidth="0.4" />
    </svg>
  );
}

export default ToastCheck;
