import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FACILITATOR_CURRICULUM_PATH, FACILITATOR_DASHBOARD_PATH, FACILITATOR_INDIGENOUS_WELCOME_LETTERS_PATH, FACILITATOR_DISCUSSION_BOARDS_PATH } from 'lib/routerPaths';
import { GET_ASSIGNMENTS } from 'store/indigenousWelcomeLetters/actions';
import { Badge } from 'react-bootstrap';

// Duplicates app/views/layouts/facilitator/_nav_items.html.erb

function FacilitatorNav() {
  const { id: userId, isFacilitator, isIndigenousPlatformLead, isSuperFacilitator } = useSelector((state) => state.currentUser || {});
  const assignments = useSelector((state) => state.indigenousWelcomeLetters.assignments.allIds);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isIndigenousPlatformLead) {
      dispatch(GET_ASSIGNMENTS.request({ userId }));
    }
  }, [userId, isIndigenousPlatformLead, dispatch]);

  return (
    <div className="side-nav bg-light border-end p-2">
      <ul className="nav flex-column">
        <li className="nav-item">
          <Link className="nav-link" to={FACILITATOR_DASHBOARD_PATH}>Dashboard</Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={FACILITATOR_DISCUSSION_BOARDS_PATH}>Discussion Boards</Link>
        </li>

        <li className="nav-item">
          <a className="nav-link" href="/facilitator/groups">Groups</a>
        </li>

        {(isFacilitator || isSuperFacilitator) && (
          <li className="nav-item">
            <a className="nav-link" href="/facilitator/settings">Settings</a>
          </li>
        )}

        {isIndigenousPlatformLead && (
          <li className="nav-item">
            <Link className="nav-link" to={FACILITATOR_INDIGENOUS_WELCOME_LETTERS_PATH}>
              Indigenous Welcome Letters
              {assignments.length > 0 && <Badge pill bg="primary" className="ms-1">{assignments.length}</Badge>}
            </Link>
          </li>
        )}

        <li className="nav-item">
          <Link className="nav-link" to={FACILITATOR_CURRICULUM_PATH}>Courses</Link>
        </li>
      </ul>
    </div>
  );
}

export default FacilitatorNav;
