import { REDUX_STATUS } from 'lib/constants';
import { COMPLETE_BULK_IMPORT, START_BULK_IMPORT, UPDATE_BULK_IMPORT } from './actions';

/* Group object
{
  status: REDUX_STATUS.IDLE,
  progress: 0,
  error: null,
}
*/

const initialState = {
  groups: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_BULK_IMPORT.SYNC:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.id]: {
            status: REDUX_STATUS.PENDING,
            progress: 0,
          },
        },
      };

    case UPDATE_BULK_IMPORT.SYNC:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.id]: {
            ...state.groups[action.payload.id],
            progress: action.payload.progress,
          },
        },
      };

    case COMPLETE_BULK_IMPORT.SYNC:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.id]: {
            ...state.groups[action.payload.id],
            status: action.payload.error ? REDUX_STATUS.ERROR : REDUX_STATUS.SUCCESS,
            error: action.payload.error,
          },
        },
      };

    default:
      return state;
  }
};
