import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { GET_EMAIL_CONFIRMATIONS } from 'store/emailTransmissionReport/actions';
import { Button, Spinner } from 'react-bootstrap';
import InputField from 'components/shared/FormFields/InputField';
import DatePickerField from 'components/shared/FormFields/DatePickerField';
import SelectField from 'components/shared/FormFields/SelectField';

function SearchForm() {
  const searching = useSelector((state) => state.emailTransmissionReport.searching);
  const dispatch = useDispatch();
  const initialValues = {
    withSubject: '',
    withFromDate: '',
    withToDate: '',
    withEmail: '',
    withStatus: '',
  };

  const handleSubmit = (values) => {
    dispatch(GET_EMAIL_CONFIRMATIONS.request(values));
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {({ resetForm }) => (
        <Form>
          <div className="row">
            <div className="col-md-6">
              <InputField name="withSubject" label="Subject" placeholder="A word or a part of a word contained in the subject" />
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <DatePickerField name="withFromDate" label="Sent After" placeholder="Emails sent after this date" />
                </div>

                <div className="col-md-6">
                  <DatePickerField name="withToDate" label="Sent Before" placeholder="Emails sent before this date" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <InputField name="withEmail" label="Recipient Email Address" placeholder="Full or partial email address (e.g. @phsa)" />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <SelectField
                name="withStatus"
                label="Status"
                includeBlank="- Any Status -"
                options={[
                  { value: 'success', label: 'Success: (Sent or Opened)' },
                  { value: 'bounced', label: 'Bounced' },
                ]}
              />
            </div>
          </div>

          <div className="my-3">
            <Button variant="primary" type="submit" disabled={searching}>
              Search Email Transmissions
              {searching && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>

            <Button variant={null} className="btn-plain" disabled={searching} onClick={resetForm}>
              Reset
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SearchForm;
