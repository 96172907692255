/* eslint-disable */
// TODO Ready for Registrar-specific Sagas
import { takeLatest, put } from 'redux-saga/effects';
import { fetchGet, handleSimpleFetchError } from 'lib/apiHelpers';

export const effects = [];

export default function* sagas() {
  // No Registrar-specific Sagas
}
