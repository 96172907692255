import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ComponentPropType, StylePropType } from 'lib/propTypes';
import { isRoute } from 'lib/routerHelpers';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

/**
 * Displays a Bootstrap Breadcrumbs component
 * See: https://react-bootstrap.github.io/components/breadcrumb/
 * And: https://v5.getbootstrap.com/docs/5.1/components/breadcrumb/
 *
 * Example:
 *   <Breadcrumbs
 *    navItems={
 *      [
 *        {label: "Home", href: "/"},
 *        {label: "Foo", href: "/foo"}
 *      ]
 *    }
 *    />
 *
 */
function Breadcrumbs({ navItems, style, className, ignoreLast }) {
  return (
    <Breadcrumb className={className} style={style}>
      {
        navItems.map(({ label, href }, index) => {
          const isLast = (index === navItems.length - 1);
          if (!href || (isLast && !ignoreLast)) {
            return <Breadcrumb.Item key={label} active>{label}</Breadcrumb.Item>;
          }
          if (isRoute(href)) {
            return <Breadcrumb.Item key={label + href} linkAs={Link} linkProps={{ to: href }}>{label}</Breadcrumb.Item>;
          }
          return <Breadcrumb.Item key={label + href} href={href}>{label}</Breadcrumb.Item>;
        })
      }
    </Breadcrumb>
  );
}

Breadcrumbs.defaultProps = {
  style: null,
  className: null,
  ignoreLast: false,
};

Breadcrumbs.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, ComponentPropType]).isRequired,
    href: PropTypes.string,
  })).isRequired,
  style: StylePropType,
  className: PropTypes.string,
  ignoreLast: PropTypes.bool,
};

export default Breadcrumbs;
