import React from 'react';
import PropTypes from 'prop-types';
import { StylePropType } from 'lib/propTypes';
import './styles.scss';

function Skeleton({ classes, style }) {
  const classNames = `skeleton ${classes} animate-pulse`;

  return <div className={classNames} style={style} />;
}

Skeleton.defaultProps = {
  style: null,
};

Skeleton.propTypes = {
  classes: PropTypes.string.isRequired,
  style: StylePropType,
};

export default Skeleton;
