import React from 'react';

function Lock() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.25 12H6.75C6.35218 12 5.97064 12.158 5.68934 12.4393C5.40804 12.7206 5.25 13.1022 5.25 13.5V21C5.25 21.3978 5.40804 21.7794 5.68934 22.0607C5.97064 22.342 6.35218 22.5 6.75 22.5H17.25C17.6478 22.5 18.0294 22.342 18.3107 22.0607C18.592 21.7794 18.75 21.3978 18.75 21V13.5C18.75 13.1022 18.592 12.7206 18.3107 12.4393C18.0294 12.158 17.6478 12 17.25 12ZM6.75 10.5C5.95435 10.5 5.19129 10.8161 4.62868 11.3787C4.06607 11.9413 3.75 12.7044 3.75 13.5V21C3.75 21.7956 4.06607 22.5587 4.62868 23.1213C5.19129 23.6839 5.95435 24 6.75 24H17.25C18.0456 24 18.8087 23.6839 19.3713 23.1213C19.9339 22.5587 20.25 21.7956 20.25 21V13.5C20.25 12.7044 19.9339 11.9413 19.3713 11.3787C18.8087 10.8161 18.0456 10.5 17.25 10.5H6.75ZM6.75 6C6.75 4.60761 7.30312 3.27226 8.28769 2.28769C9.27226 1.30312 10.6076 0.75 12 0.75C13.3924 0.75 14.7277 1.30312 15.7123 2.28769C16.6969 3.27226 17.25 4.60761 17.25 6V10.5H15.75V6C15.75 5.00544 15.3549 4.05161 14.6517 3.34835C13.9484 2.64509 12.9946 2.25 12 2.25C11.0054 2.25 10.0516 2.64509 9.34835 3.34835C8.64509 4.05161 8.25 5.00544 8.25 6V10.5H6.75V6Z" fill="currentColor" />
    </svg>
  );
}

export default Lock;
