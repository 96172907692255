import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form, useFormikContext } from 'formik';
import { StandardOptionsPropType } from 'lib/propTypes';
import _intersection from 'lodash/intersection';
import { Button, Col, ListGroup, Row, Spinner, Tab } from 'react-bootstrap';
import ErrorMessage from 'components/shared/ErrorMessage';
import SanyasIcon from 'components/shared/SanyasIcon';
import InputField from 'components/shared/FormFields/InputField';
import RadioCheckField from 'components/shared/FormFields/RadioCheckField';
import SelectField from 'components/shared/FormFields/SelectField';
import SwitchField from 'components/shared/FormFields/SwitchField';
import DollarInputField from 'components/shared/FormFields/DollarInputField';
import TextareaField from 'components/shared/FormFields/TextareaField';

function CourseForm(props) {
  const { courseTypeOptions, mode } = props;
  const { values, handleSubmit, touched, errors, dirty, isSubmitting } = useFormikContext();
  const editMode = mode === 'edit';
  const currentUser = useSelector((state) => state.currentUser);
  const { courseType, formData } = useSelector((state) => state.courseTypes);
  const { themes } = formData;

  return (
    <>
      {courseType.error && (
        <ErrorMessage error={courseType.error} className="mb-2" />
      )}

      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#basics">
        {/* height of Timelines - tallest tab - 533px */}
        <Form style={{ minHeight: 533 }}>
          <Row>
            <Col sm={4}>
              <ListGroup>
                <ListGroup.Item href="#basics" action disabled={editMode && dirty} variant={_intersection(['name', 'isCore', 'price', 'prerequisites'], Object.keys(errors), Object.keys(touched)).length && 'danger'}>
                  <SanyasIcon name="list" className="mx-1" size="lg" />
                  Basics
                </ListGroup.Item>
                <ListGroup.Item href="#language" action disabled={editMode && dirty} variant={_intersection(['isAvailableInFrench', 'nameFr'], Object.keys(errors), Object.keys(touched)).length && 'danger'}>
                  <SanyasIcon name="chat" className="mx-1" size="lg" />
                  Language availability
                </ListGroup.Item>
                <ListGroup.Item href="#timelines" action disabled={editMode && dirty} variant={_intersection(['durationInWeeks', 'sittingTimeInHours', 'expireReservationsXDaysBeforeBegin', 'sendParticipantListsXDaysBeforeBegin', 'closeRegistrationXDaysBeforeBegin', 'sendWelcomePacksXDaysBeforeBegin'], Object.keys(errors), Object.keys(touched)).length && 'danger'}>
                  <SanyasIcon name="clock" className="mx-1" size="lg" />
                  Timelines
                </ListGroup.Item>
                <ListGroup.Item href="#themes" action disabled={editMode && dirty}>
                  <SanyasIcon name="image" className="mx-1" size="lg" />
                  Themes
                </ListGroup.Item>
                <ListGroup.Item href="#tools" action disabled={editMode && dirty}>
                  <SanyasIcon name="toggles" className="mx-1" size="lg" />
                  Tools
                </ListGroup.Item>
                {currentUser.isRegistrar && (
                  <ListGroup.Item href="#advanced" action disabled={editMode && dirty}>
                    <SanyasIcon name="shieldLock" className="mx-1" size="lg" />
                    Advanced
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Col>

            <Col sm={8} className="mt-4 mt-sm-0 position-relative">
              {editMode && (
                <div className="position-absolute end-0 me-2">
                  <Button value="primary" onClick={handleSubmit} type="submit" disabled={!dirty || isSubmitting}>
                    Save
                    {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
                  </Button>
                </div>
              )}
              <Tab.Content>
                <Tab.Pane eventKey="#basics">
                  <h4>Basics</h4>
                  <InputField
                    name="name"
                    placeholder="Course title"
                    label="Course title"
                    required
                    initialValidation="Required"
                  />
                  <RadioCheckField hideLabel type="radio" name="isCore" options={[{ value: true, label: 'Core' }, { value: false, label: 'Post training' }]} />
                  <DollarInputField
                    name="price"
                    label="Cost"
                    className="w-50"
                  />
                  <SelectField
                    name="prerequisites"
                    multiple
                    options={courseTypeOptions}
                  />
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="#language">
                  <h4>Language availability</h4>

                  <RadioCheckField label="" type="checkbox" hideLabel name="isAvailableInFrench" options={[{ value: true, label: 'Available in french' }]} />
                  <InputField
                    name="nameFr"
                    label="French course title"
                    placeholder="titre de cours"
                  />
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="#timelines">
                  <h4>Timelines</h4>
                  <div className="row">
                    <div className="col-xl-9">
                      <InputField
                        name="durationInWeeks"
                        label="Duration"
                        addOnEnd="Weeks"
                        type="number"
                      />
                      <InputField
                        name="sittingTimeInHours"
                        label="Sitting time"
                        addOnEnd="Hours"
                        type="number"
                      />

                      <InputField
                        name="expireReservationsXDaysBeforeBegin"
                        label="Expire reservations"
                        addOnEnd="Days before at 7:30am"
                        type="number"
                      />
                      <InputField
                        name="closeRegistrationXDaysBeforeBegin"
                        label="Close registration"
                        addOnEnd="Days before at 4:30pm"
                        type="number"
                      />
                      <InputField
                        name="sendParticipantListsXDaysBeforeBegin"
                        label="Send participant lists"
                        addOnEnd="Days before at 12:00pm"
                        type="number"
                      />
                      <InputField
                        name="sendWelcomePacksXDaysBeforeBegin"
                        label="Send welcome packs"
                        addOnEnd="Days before at 12:00pm"
                        type="number"
                      />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="#themes">
                  <h4 className="mb-4">
                    Theme
                  </h4>
                  <SelectField
                    name="theme"
                    options={themes}
                    placeholder="Choose a theme"
                    hideLabel
                    required
                  />
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="#tools">
                  <h4>
                    Tools
                  </h4>
                  <p className="text-small">
                    Enable or disable tabs for participants
                  </p>

                  <div className="row">
                    <div className="col-8">
                      <SwitchField
                        name="showParticipantReflectionsTab"
                        label="Personal reflections"
                      />
                      <SwitchField
                        name="showMessagesTab"
                        label="Messages"
                      />
                      <SwitchField
                        name="showDiscussionsTab"
                        label="Discussions"
                      />
                      <SwitchField
                        name="showJournalsTab"
                        label="Journals"
                      />
                      <SwitchField
                        name="showGlossaryTab"
                        label="Glossary"
                      />
                      <SwitchField
                        name="showSelfCareTab"
                        label="Self-care"
                      />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="#advanced">
                  <h4>Advanced</h4>

                  <InputField
                    name="nameForCertificate"
                    label="Name for Certificate"
                    helpText={`Name shown on the certificate. Also applies to past participants.
                      ${(values.name && !values.nameForCertificate)
                      ? `By default, “${values.name}” will be used.`
                      : ''}`}
                  />
                  <TextareaField
                    name="registrationNote"
                  />

                  <div className="row mt-3">
                    <div className="col-8">
                      <SwitchField
                        name="hideFromCurriculumTab"
                        label="Hide from Curriculum tab"
                      />
                      <SwitchField
                        name="hideFromReports"
                        label="Hide from reports"
                      />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Form>
      </Tab.Container>
    </>
  );
}

CourseForm.propTypes = {
  courseTypeOptions: StandardOptionsPropType,
  mode: PropTypes.string.isRequired,
};

CourseForm.defaultProps = {
  courseTypeOptions: [],
};

export default CourseForm;
