import React, { useRef, useCallback, useState, useEffect, createRef } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_QUESTION, QUESTION_TYPES, REDUX_STATUS, TASK_TYPES } from 'lib/constants';
import { LocalePropType } from 'lib/propTypes';
import { getLocaleSuffix } from 'lib/utils';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import TextareaField from 'components/shared/FormFields/TextareaField';
import Button from 'react-bootstrap/Button';
import ErrorMessage from 'components/shared/ErrorMessage';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import QuestionSkeleton from '../JournalTask/QuestionSkeleton';
import EssayQuestion from '../Questions/EssayQuestion';
import MultipleChoiceQuestion from '../Questions/MultipleChoiceQuestion';
import Heading from '../Questions/Heading';
import './styles.scss';

const questionComponents = {
  [QUESTION_TYPES.MULTIPLE_CHOICE]: MultipleChoiceQuestion,
  [QUESTION_TYPES.HEADING]: Heading,
  [QUESTION_TYPES.ESSAY]: EssayQuestion,
};

function fadeOutHighlight(el) {
  setTimeout(() => {
    el.classList.remove('question-enter-done');
  }, 800);
}

/* eslint-disable react/jsx-props-no-spreading */

function FormBuilder({ locale }) {
  const { currentTask } = useCourseShowContext();
  const { values } = useFormikContext();
  const { status, error } = useSelector((state) => state.courses.questions);
  const [newQuestionId, setNewQuestionId] = useState(null);
  const [newQuestionRef, setNewQuestionRef] = useState(null);
  const helperRef = useRef();
  const { id: taskId, type: taskType, questions } = values;

  useEffect(() => {
    if (newQuestionRef) {
      setTimeout(() => newQuestionRef.scrollIntoView(), 400);
    }
  }, [newQuestionRef]);

  const handleAddQuestion = (type) => {
    const questionId = uuidv4();
    const newQuestion = { ...DEFAULT_QUESTION,
      id: questionId,
      taskId,
      type,
      options: type === QUESTION_TYPES.MULTIPLE_CHOICE ? [
        {
          body: '',
          bodyFr: '',
          questionId,
          id: uuidv4(),
          correct: taskType === TASK_TYPES.MULTIPLE_CHOICE_QUIZ,
        },
      ] : undefined,
      answerRequired: true,
      position: questions.length + 1,
    };

    helperRef.current.push(newQuestion);
  };

  const handleDragEnd = useCallback(({ destination, source, draggableId }) => {
    const element = document.getElementById(draggableId);
    element.classList.remove('is-dragging');

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    helperRef.current.move(source.index, destination.index);
  }, []);

  const handleBeforeCapture = useCallback((event) => {
    const element = document.getElementById(event.draggableId);
    element.classList.add('is-dragging');
    element.style.height = 'auto';
  }, []);

  return (
    <div className="form-builder mt-4">
      <h3>Form Builder</h3>

      {taskType === TASK_TYPES.MULTIPLE_CHOICE_QUIZ && (
        <TextareaField
          name={`closing${getLocaleSuffix(locale)}`}
          label="Closing text"
          placeholder="Closing text"
        />
      )}

      <DragDropContext onDragEnd={handleDragEnd} onBeforeCapture={handleBeforeCapture}>
        <Droppable droppableId={`${taskId}`} type="question">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <FieldArray name="questions">
                {(arrayHelpers) => {
                  helperRef.current = arrayHelpers;
                  return (
                    <>
                      {status === REDUX_STATUS.PENDING && <QuestionSkeleton />}

                      {status === REDUX_STATUS.SUCCESS && (
                        <TransitionGroup key={taskId} component={null}>
                          {questions.map((question, index) => {
                            const QuestionComponent = questionComponents[question.type];
                            const nodeRef = createRef(null);
                            if (!QuestionComponent) return null;
                            return (
                              <CSSTransition
                                key={question.id}
                                nodeRef={nodeRef}
                                timeout={300}
                                classNames="question"
                                onEntered={() => fadeOutHighlight(nodeRef.current)}
                              >
                                <QuestionComponent
                                  innerRef={nodeRef}
                                  isNew={newQuestionId === question.id}
                                  setNewQuestionRef={setNewQuestionRef}
                                  key={question.id}
                                  index={index}
                                  question={question}
                                  locale={locale}
                                  arrayHelpers={helperRef.current}
                                  setNewQuestionId={setNewQuestionId}
                                />
                              </CSSTransition>
                            );
                          })}
                        </TransitionGroup>
                      )}

                      {status === REDUX_STATUS.ERROR && <ErrorMessage error={error} />}
                    </>
                  );
                }}
              </FieldArray>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {!currentTask?.isLocked && (
        <div className="mt-2 mb-4 border-top pt-2">
          {taskType === TASK_TYPES.MULTIPLE_CHOICE_QUIZ && (
            <Button variant="primary" size="sm" className="btn-plain" title="Add multiple choice question" onClick={() => handleAddQuestion(QUESTION_TYPES.MULTIPLE_CHOICE)}>
              + Add multiple choice question
            </Button>
          )}

          {taskType === TASK_TYPES.SURVEY && (
            <>
              <Button variant="primary" size="sm" className="btn-plain" title="Add survey question" onClick={() => handleAddQuestion(QUESTION_TYPES.MULTIPLE_CHOICE)}>
                + Add survey question
              </Button>
              <Button variant="primary" size="sm" className="btn-plain" title="Add essay question" onClick={() => handleAddQuestion(QUESTION_TYPES.ESSAY)}>
                + Add essay question
              </Button>
              <Button variant="primary" size="sm" className="btn-plain" title="Add question heading" onClick={() => handleAddQuestion(QUESTION_TYPES.HEADING)}>
                + Add question heading
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
}

FormBuilder.defaultProps = {
  locale: 'en',
};

FormBuilder.propTypes = {
  locale: LocalePropType,
};

export default FormBuilder;
