import React from 'react';

function Discussion() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 5.75C4 5.33579 4.33579 5 4.75 5H14.25C14.6642 5 15 5.33579 15 5.75V5.75C15 6.16421 14.6642 6.5 14.25 6.5H4.75C4.33579 6.5 4 6.16421 4 5.75V5.75Z" fill="currentColor" />
      <path d="M4 9.75C4 9.33579 4.33579 9 4.75 9H14.25C14.6642 9 15 9.33579 15 9.75V9.75C15 10.1642 14.6642 10.5 14.25 10.5H4.75C4.33579 10.5 4 10.1642 4 9.75V9.75Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3 2.5H16C16.8284 2.5 17.5 3.17157 17.5 4V12C17.5 12.8277 16.8281 13.5 15.9989 13.5H8.38403C7.76688 13.5 7.17073 13.7283 6.71132 14.1418L4.5 16.132V15.3529L4.5 15.3472C4.50006 15.2243 4.50026 14.82 4.29115 14.4151C4.16548 14.1717 3.95876 13.9152 3.64398 13.7318C3.33591 13.5522 3.0228 13.5 2.77059 13.5C2.04743 13.5 1.5 12.8995 1.5 12.3529V4C1.5 3.17157 2.17157 2.5 3 2.5ZM7 15.9L4.5 18.15L4.47923 18.1687L4.24742 18.3773L3.83448 18.749C3.51272 19.0386 3 18.8102 3 18.3773V17.8218V17.5099V17.482V15.3529C3 15.158 2.96552 15 2.77059 15C1.30866 15 0 13.8149 0 12.3529V4C0 2.34315 1.34315 1 3 1H16C17.6569 1 19 2.34315 19 4V7H21C22.6569 7 24 8.34314 24 10V17.4964C24 18.8045 22.8291 19.8649 21.521 19.8649C21.3466 19.8649 21.3158 20.0062 21.3158 20.1807V22.857C21.3158 23.293 20.7966 23.5201 20.4764 23.2241L17.1295 20.1305C16.9447 19.9597 16.7018 19.8649 16.4501 19.8649H10.0009C8.34402 19.8649 7 18.5217 7 16.8649V15.9ZM8.5 15V16.8649C8.5 17.6927 9.17188 18.3649 10.0009 18.3649H16.4501C17.0783 18.3649 17.685 18.6014 18.1477 19.029L19.8158 20.5709L19.8158 20.1741C19.8157 20.0595 19.8154 19.6658 20.0203 19.2691C20.1442 19.0291 20.3485 18.7753 20.6601 18.5937C20.9649 18.416 21.274 18.3649 21.521 18.3649C22.0903 18.3649 22.5 17.8891 22.5 17.4964V10C22.5 9.17157 21.8284 8.5 21 8.5H19V12C19 13.6569 17.6558 15 15.9989 15H8.5Z" fill="currentColor" />
    </svg>

  );
}

export default Discussion;
