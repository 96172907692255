import { REDUX_STATUS } from 'lib/constants';
import { LOAD_HEALTH_AUTHORITIES } from './actions';

const initialState = {
  status: REDUX_STATUS.IDLE,
  allIds: [],
  byId: {},
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_HEALTH_AUTHORITIES.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
        error: undefined,
      };
    case LOAD_HEALTH_AUTHORITIES.SUCCESS:
      return {
        ...state,
        allIds: action.payload.healthAuthorityIds,
        byId: action.payload.healthAuthorities,
        status: REDUX_STATUS.SUCCESS,
      };
    case LOAD_HEALTH_AUTHORITIES.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };
    default:
      return state;
  }
};
