import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType, StylePropType } from 'lib/propTypes';
import CurriculumNav from 'components/curriculum/CurriculumNav';
import BaseTemplate from '../BaseTemplate';

function CurriculumTemplate({ children, id, className, style }) {
  return (
    <BaseTemplate
      id={id}
      className={`bg-light ${className || ''}`}
      style={style}
      navComponent={<CurriculumNav />}
    >
      {children}
    </BaseTemplate>
  );
}

CurriculumTemplate.defaultProps = {
  id: null,
  className: null,
  style: null,
};

CurriculumTemplate.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: ComponentPropType.isRequired,
  style: StylePropType,
};

export default CurriculumTemplate;
