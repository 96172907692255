import React from 'react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { UserTaskPropType } from 'lib/propTypes';
import { formatSeconds } from 'lib/utils';
import { DT_LOG_TIME } from 'lib/dateFormats';

function TaskLogItem({ task }) {
  const completedAtDate = task.completedAt && parseISO(task.completedAt);
  const lastViewedAtDate = task.lastViewedAt && parseISO(task.lastViewedAt);

  return (
    <tr>
      <td style={{ whiteSpace: 'nowrap ' }}>
        {completedAtDate ? format(completedAtDate, DT_LOG_TIME) : 'Not available'}
      </td>
      <td>{task.section.position}</td>
      <td>{task.name}</td>
      <td>{formatSeconds(task.activeTimeBeforeCompletion + task.activeTimeAfterCompletion)}</td>
      <td style={{ whiteSpace: 'nowrap' }}>
        {lastViewedAtDate ? format(lastViewedAtDate, DT_LOG_TIME) : 'Not available'}
      </td>
    </tr>
  );
}

TaskLogItem.propTypes = {
  task: UserTaskPropType.isRequired,
};

export default TaskLogItem;
