import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType } from 'lib/propTypes';

// Roughly mimics a Formik/form field, but for displaying data only
function DisplayField({ label, value, className }) {
  return (
    <p className={`mb-2 ${className ?? ''}`}>
      <span className="d-block pb-1 text-accent-grey" role="presentation">{label}</span>
      {value}
    </p>
  );
}

DisplayField.defaultProps = {
  value: null,
  className: null,
};

DisplayField.propTypes = {
  label: PropTypes.string.isRequired,
  value: ComponentPropType,
  className: PropTypes.string,
};

export default DisplayField;
