import { CREATE_FACILITATOR_REPORT, FETCH_ALL_FACILITATOR_REPORTS, GET_FACILITATOR_RPT_FORM_DATA } from 'store/actions';

const initialState = {
  formLoading: true,
  loading: true,
  reports: {
    allIds: [],
    byId: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FACILITATOR_RPT_FORM_DATA.REQUEST:
      return {
        ...state,
        submitting: true,
        formLoading: true,
      };
    case GET_FACILITATOR_RPT_FORM_DATA.SUCCESS:
      return {
        ...state,
        formData: action.payload,
        submitting: false,
        formLoading: false,
      };
    case GET_FACILITATOR_RPT_FORM_DATA.FAILURE:
      return {
        ...state,
        submitting: false,
        formLoading: false,
      };
    case CREATE_FACILITATOR_REPORT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_FACILITATOR_REPORT.SUCCESS:
      return {
        ...state,
        loading: false,
        reports: {
          allIds: [...action.payload.reportIds, ...state.reports.allIds],
          byId: { ...state.reports.byId, ...action.payload.reports },
        },
      };
    case CREATE_FACILITATOR_REPORT.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ALL_FACILITATOR_REPORTS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_FACILITATOR_REPORTS.SUCCESS:
      return {
        ...state,
        loading: false,
        reports: {
          allIds: [...action.payload.reportIds],
          byId: { ...action.payload.reports },
        },
      };
    case FETCH_ALL_FACILITATOR_REPORTS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
