import React from 'react';
import { useSelector } from 'react-redux';
import _sortBy from 'lodash/sortBy';
import _reduce from 'lodash/reduce';
import { selectOpsSubGroups } from 'store/facilitatorDashboard/selectors';
import NotificationBubble from 'components/facilitator/NotificationBubble';

function OPSTotals() {
  const opsSubGroups = useSelector((state) => selectOpsSubGroups(state));
  const totals = totalsByPriority(opsSubGroups);

  if (totals.length === 0) return null;

  return (
    <div className="row mt-4">
      <div className="col-md-8">
        <p>Totals for OPS courses</p>
        <table className="table table-bordered ops-totals">
          <thead>
            <tr>
              { totals.map(({ taskName }) => {
                if (taskName.indexOf('J') === 0) {
                  return (
                    <th key={taskName}>
                      {taskName}
                      {' '}
                      (primary)
                    </th>
                  );
                }
                return <th key={taskName}>{taskName}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {
                totals.map(({ highPriorityTotal, mediumPriorityTotal, lowPriorityTotal, sortingKey }) => (
                  <td key={sortingKey}>
                    <div title="Totals for all OPS courses">
                      <NotificationBubble priority="high" count={highPriorityTotal} />
                      <NotificationBubble priority="medium" count={mediumPriorityTotal} />
                      <NotificationBubble priority="low" count={lowPriorityTotal} />
                    </div>
                  </td>
                ))
              }
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function totalsByPriority(pods) {
  const initTotals = {
    highPriorityTotal: 0,
    mediumPriorityTotal: 0,
    lowPriorityTotal: 0,
  };
  /* eslint-disable no-param-reassign */
  const totalsAsObj = pods?.reduce((totals, pod) => {
    const orderedDBNotifications = _sortBy(Object.values(pod.discussionBoardNotifications || {}), 'position');
    orderedDBNotifications.forEach((db, index) => {
      const dbName = `DB${index + 1}`;
      if (!totals[dbName]) totals[dbName] = { ...initTotals };

      totals[dbName].highPriorityTotal += db.highPriorityTotal;
      totals[dbName].mediumPriorityTotal += db.mediumPriorityTotal;
      totals[dbName].lowPriorityTotal += db.lowPriorityTotal;
      // the sortingKey is used to sort journals before dbs in table
      totals[dbName].sortingKey = `bDB${index + 1}`;
    });

    const orderedJournalNotifications = _sortBy(Object.values(pod.journalNotifications || {}), 'position');
    orderedJournalNotifications.forEach((j, index) => {
      const journalTaskName = `J${index + 1}`;
      if (!totals[journalTaskName]) totals[journalTaskName] = { ...initTotals };

      totals[journalTaskName].highPriorityTotal += j.primary.highPriorityTotal;
      totals[journalTaskName].mediumPriorityTotal += j.primary.mediumPriorityTotal;
      totals[journalTaskName].lowPriorityTotal += j.primary.lowPriorityTotal;
      totals[journalTaskName].sortingKey = `aJ${index + 1}`;
    });

    return totals;
  }, {});
  /* eslint-enable no-param-reassign */

  // convert the totalsObj to array for sorting
  const totalsAsArray = _reduce(totalsAsObj, (result, obj, taskName) => {
    result.push({ ...obj, taskName });
    return result;
  }, []);
  return _sortBy(totalsAsArray, 'sortingKey');
}

export default OPSTotals;
