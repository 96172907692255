import React from 'react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { LEGACY_APP_DOMAIN } from 'config';
import { DT_CONVERSATION } from 'lib/dateFormats';
import { ConversationPropType } from 'lib/propTypes';
import MultilineText from 'components/facilitator/MultilineText';

function GroupConversation({ conversation }) {
  const firstMessage = conversation.messages[0];
  const recipients = conversation
    .messages
    .map((m) => `${m.toUser.firstName} ${m.toUser.lastName}`)
    .sort((a, b) => { if (a > b) { return 1; } return -1; })
    .join(', ');
  const { groupName } = firstMessage.toUser;
  const { groupId } = firstMessage.toUser;

  return (
    <div className="row-space-top">
      <p className="well">
        This message was sent to your pod in
        {' '}
        <a href={`${LEGACY_APP_DOMAIN}/groups/${groupId}`} target="_blank" rel="noreferrer">{groupName}</a>
        .
        <br />
        <br />
        <small>{recipients}</small>
      </p>
      <div>
        <p style={{ fontWeight: 'bold' }}>
          On
          {' '}
          {format(parseISO(firstMessage.createdAt), DT_CONVERSATION)}
          {', '}
          {firstMessage.fromUser.firstName}
          {' '}
          {firstMessage.fromUser.lastName}
          {' '}
          wrote:
        </p>
        <MultilineText text={firstMessage.body} />
      </div>
    </div>
  );
}

GroupConversation.propTypes = {
  conversation: ConversationPropType.isRequired,
};

export default GroupConversation;
