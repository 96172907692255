import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { CourseSectionPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_MODULE_PATH, MODULE_EDIT_PATH } from 'lib/routerPaths';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import { RESET_SECTION } from 'store/courses/actions';
import Skeleton from 'components/shared/Skeleton';

const MODULE_PATHS = {
  facilitator: FACILITATOR_MODULE_PATH,
  curriculum: MODULE_EDIT_PATH,
};

function ModuleName({ section, index, disabled }) {
  const { course, currentSection } = useCourseShowContext();
  const { id: workspaceId } = useWorkspace();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (currentSection?.id !== section?.id) {
      dispatch(RESET_SECTION.action());
    }
    history.push(buildRoutePath(MODULE_PATHS[workspaceId], { courseId: course?.id, sectionId: section?.id }));
  }, [currentSection?.id, section?.id, course?.id, workspaceId, dispatch, history]);

  return (
    <button
      type="button"
      className="accordion-title"
      onClick={handleClick}
      disabled={disabled}
    >
      <span className="accordion-position">{`${index + 1}. ` || ''}</span>
      {section?.name || <Skeleton classes="text strong" />}
    </button>
  );
}

ModuleName.defaultProps = {
  section: null,
  disabled: false,
};

ModuleName.propTypes = {
  section: CourseSectionPropType,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export default ModuleName;
