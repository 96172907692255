import React from 'react';
import BaseSkeleton from 'components/shared/Skeleton';
import Accordion from 'react-bootstrap/Accordion';

function Skeleton() {
  return (
    <Accordion className="outline" flush>
      <Accordion.Item>
        <BaseSkeleton classes="title width-66p my-2 ms-4" />
      </Accordion.Item>
      <Accordion.Item>
        <BaseSkeleton classes="title width-66p my-2 ms-4" />
      </Accordion.Item>
    </Accordion>
  );
}

export default Skeleton;
