import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from './FieldWrapper';
import InputGroup from './InputGroup';
import Input from './Input';

/* Renders an input group with $ start and CAD end
 * and an input field for positive numeric values, up to 11 characters (10 digits + decimal point).
 * The input value is validated at the form level.
 * ```
 * Yup.number().required('Cost is required').test(
 *   'valid-decimal',
 *   'Cost is not valid',
 *   // up to 8 digits before the optional decimal point, up to 2 digits after
 *   (value) => `${value}`.match(/^\d{0,8}.?\d{0,2}$/),
 * )
 * ```
*/
function DollarInputField(props) {
  const { id, name, label, className, disabled, helpText, infoText, required, initialValidation, onFieldChange, onFieldBlur } = props;

  return (
    <FieldWrapper
      id={id}
      name={name}
      label={label}
      isFloat
      helpText={helpText}
      infoText={infoText}
      required={required}
      initialValidation={initialValidation}
      onFieldChange={onFieldChange}
      onFieldBlur={onFieldBlur}
    >
      <InputGroup
        className={className}
        addOnStart="$"
        addOnEnd="CAD"
      >
        <Input
          name={name}
          type="text"
          disabled={disabled}
          required={required}
          maxLength={11}
          isPositive
        />
      </InputGroup>
    </FieldWrapper>
  );
}

DollarInputField.defaultProps = {
  id: null,
  label: null,
  className: null,
  disabled: false,
  helpText: null,
  infoText: null,
  required: false,
  initialValidation: null,
  onFieldChange: null,
  onFieldBlur: null,
};

DollarInputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  infoText: PropTypes.string,
  required: PropTypes.bool,
  initialValidation: PropTypes.string,
  onFieldChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
};

export default DollarInputField;
