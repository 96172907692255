import { createSelector } from 'reselect';
import { selectOrganizations } from 'store/organizations/selectors';

export const selectHealthAuthorities = (state) => state.healthAuthorities.byId;

export const selectHealthAuthorityById = createSelector(
  selectHealthAuthorities,
  (_, id) => id,
  (healthAuthorities, id) => healthAuthorities[id],
);

export const selectGroupedHealthAuthorities = createSelector(
  selectOrganizations,
  selectHealthAuthorities,
  (organizations, healthAuthorities) => {
    if (!organizations || !healthAuthorities) return [];

    return Object.values(organizations).reduce((group, org) => {
      group.push({ label: org.name,
        options: org.healthAuthorityIds.map((haId) => {
          const ha = healthAuthorities[haId] || {};
          return ({ value: ha.id, label: ha.name, organization: org.shortName });
        }),
      });
      return group;
    }, []);
  },
);
