import React from 'react';
import PropTypes from 'prop-types';
import StateBadge from 'components/shared/Badge/StateBadge';

const reportStates = {
  requested: {
    text: 'Requested',
    className: 'primary',
    icon: 'fa fa-question-circle',
  },
  in_progress: {
    text: 'In Progress',
    className: 'warning',
    icon: 'fa fa-circle',
  },
  completed: {
    text: 'Completed',
    className: 'success',
    icon: 'fa fa-check-circle',
  },
  failed: {
    text: 'Failed',
    className: 'danger',
    icon: 'fa fa-times-circle',
  },
};

function ReportStateBadge({ reportState }) {
  if (Object.keys(reportStates).includes(reportState)) {
    const { text, className, icon } = reportStates[reportState];

    return (
      <StateBadge
        text={text}
        backgroundClassName={className}
        iconClassName={icon}
      />
    );
  }

  return (
    <StateBadge
      text="Unknown"
      backgroundClassName="secondary"
      iconClassName="far fa-circle"
    />
  );
}

ReportStateBadge.propTypes = {
  reportState: PropTypes.string.isRequired,
};

export default ReportStateBadge;
