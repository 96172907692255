import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import { OrganizationPropType } from 'lib/propTypes';
import { CREATE_EMAIL_TEMPLATE, DELETE_EMAIL_TEMPLATE, UPDATE_EMAIL_TEMPLATE } from 'store/emailTemplates/actions';
import { selectEmailTemplateTypeById, selectEmailTemplateWithTypeId } from 'store/emailTemplates/selectors';
import { Button, Spinner } from 'react-bootstrap';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import ErrorMessage from 'components/shared/ErrorMessage';
import FormHeader from './FormHeader';
import MessageBody from './MessageBody';
import SanyasIcon from '../SanyasIcon';

/* eslint-disable no-template-curly-in-string */
const FormSchema = Yup.object().shape({
  body: Yup.string().required().label(' Message') // Hack: hard-space for alignment
    .test('empty', '${label} cannot be empty', (value) => value?.trim()),
  bodyFr: Yup.string().label(' Message (French)') // Hack: hard-space for alignment
    .test('empty', '${label} cannot be empty', (value) => !value || value?.trim()),
});
/* eslint-enable no-template-curly-in-string */

function EmailTemplateForm({ template, context, organization }) {
  const { courseId, id } = useParams();
  const emailTemplate = useSelector((state) => selectEmailTemplateWithTypeId(state, template));
  const emailTemplateType = useSelector((state) => selectEmailTemplateTypeById(state, template));
  const activeTemplate = useSelector((state) => state.emailTemplates.template);
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const determineEmailTemplateContextId = () => {
    switch (context) {
      case 'courses':
        return Number(courseId);
      case 'groups':
      case 'organizations':
        return Number(id);
      default:
        return null;
    }
  };

  const initialValues = {
    id: emailTemplate?.id || null,
    body: emailTemplate?.body || '',
    bodyFr: emailTemplate?.bodyFr || '',
    emailTemplateTypeId: emailTemplate?.emailTemplateTypeId || template,
    emailTemplateContextId: emailTemplate?.emailTemplateContextId || determineEmailTemplateContextId(),
  };

  useEffect(() => {
    if (activeTemplate?.status === REDUX_STATUS.SUCCESS && activeTemplate.success === REDUX_SUCCESS.DELETED) {
      setShowDeleteModal(false);
    }
  }, [activeTemplate?.status, activeTemplate?.success]);

  const handleSave = useCallback((values, actions) => {
    if (emailTemplate?.id) {
      dispatch(UPDATE_EMAIL_TEMPLATE.request({ ...values, context }, { formikActions: actions }));
    } else {
      dispatch(CREATE_EMAIL_TEMPLATE.request({ ...values, context }, { formikActions: actions }));
    }
  }, [emailTemplate?.id, context, dispatch]);

  const handleDelete = useCallback(() => {
    if (emailTemplate?.id) {
      dispatch(DELETE_EMAIL_TEMPLATE.request({ entityId: courseId, id: emailTemplate.id, context, typeId: template }));
    }
  }, [courseId, emailTemplate?.id, template, context, dispatch]);

  return (
    <div className="sticky-top">
      <Formik
        onSubmit={handleSave}
        validationSchema={FormSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <FormHeader entry={values} setShowDeleteModal={setShowDeleteModal} />

            <ErrorMessage error={activeTemplate?.error} className="mt-2 mx-4" />

            {(emailTemplate && !emailTemplate.body) && (
              <p className="mb-0 py-2 px-4">
                <SanyasIcon name="exclamationTriangle" className="me-1 text-warning" />
                {' '}
                This template is empty and will not send.
              </p>
            )}

            {(organization && !organization.sendManagerNotifications && emailTemplateType.managerNotification) && (
              <p className="mb-0 py-2 px-4">
                <SanyasIcon name="exclamationTriangle" className="me-1 text-warning" />
                {' '}
                This organization does not send manager notifications.
              </p>
            )}

            <div className="py-3 px-4">
              <MessageBody
                label="Message"
                placeholder="Start typing your email here..."
                placeholderFr="Commencez à taper votre e-mail ici..."
              />
            </div>
          </Form>
        )}
      </Formik>

      <DefaultModal
        key={emailTemplate?.id}
        size="md"
        isOpen={showDeleteModal}
        header="Delete template?"
        onClose={() => setShowDeleteModal(false)}
        footerComponent={(
          <Button
            className="d-flex align-items-center"
            disabled={activeTemplate?.status === REDUX_STATUS.PENDING}
            variant="danger"
            onClick={handleDelete}
          >
            {activeTemplate?.status === REDUX_STATUS.PENDING && (
              <Spinner
                size="sm"
                className="ms-1"
                animation="border"
                role="status"
              />
            )}
            Delete
          </Button>
      )}
      >
        <p className="d-flex justify-content-between">
          <strong>Confirm</strong>
          <span className="ps-2">Please confirm that you want to delete this template.</span>
        </p>
      </DefaultModal>
    </div>
  );
}

EmailTemplateForm.defaultProps = {
  organization: null,
};

EmailTemplateForm.propTypes = {
  template: PropTypes.number.isRequired,
  context: PropTypes.oneOf(['courses', 'groups', 'organizations']).isRequired,
  organization: OrganizationPropType,
};

export default EmailTemplateForm;
