import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import { LOAD_COURSE_TYPES, LOAD_FACILITATOR_COURSE_TYPES, CREATE_COURSE_TYPE, UPDATE_COURSE_TYPE, GET_COURSE_TYPE_FORM_DATA, MERGE_COURSE_TYPE, RESET_COURSE_TYPE } from './actions';

const initialState = {
  status: REDUX_STATUS.IDLE,
  allIds: [],
  byId: {},
  error: undefined,
  courseType: {
    id: undefined,
    data: {},
    status: REDUX_STATUS.IDLE,
    error: undefined,
    success: undefined,
  },
  formData: {
    themes: [],
    attributes: { // Reasonable defaults
      closeRegistrationXDaysBeforeBegin: 5,
      durationInWeeks: 8,
      expireReservationsXDaysBeforeBegin: 7,
      isAvailableInFrench: false,
      isCore: false,
      hideFromCurriculumTab: false,
      hideFromReports: false,
      prerequisites: [],
      sendParticipantListsXDaysBeforeBegin: 4,
      sendWelcomePacksXDaysBeforeBegin: 3,
      showDiscussionsTab: true,
      showGlossaryTab: true,
      showJournalsTab: true,
      showMessagesTab: true,
      showParticipantReflectionsTab: false,
      showSelfCareTab: false,
      sittingTimeInHours: 10,
      theme: 'phsa',
      price: '0',
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COURSE_TYPES.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
      };
    case LOAD_COURSE_TYPES.SUCCESS: {
      if (action.meta.cached) return { ...state, status: REDUX_STATUS.SUCCESS, error: undefined };

      return {
        ...state,
        allIds: action.payload.courseTypeIds,
        byId: action.payload.courseTypes,
        status: REDUX_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case LOAD_COURSE_TYPES.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case LOAD_FACILITATOR_COURSE_TYPES:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
      };
    case LOAD_FACILITATOR_COURSE_TYPES.SUCCESS: {
      if (action.meta.cached) return { ...state, status: REDUX_STATUS.SUCCESS, error: undefined };

      return {
        ...state,
        allIds: action.payload.courseTypeIds,
        byId: action.payload.courseTypes,
        status: REDUX_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case LOAD_FACILITATOR_COURSE_TYPES.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case CREATE_COURSE_TYPE.REQUEST:
      return {
        ...state,
        courseType: {
          ...state.courseType,
          status: REDUX_STATUS.PENDING,
        },
      };
    case CREATE_COURSE_TYPE.SUCCESS:
      return {
        ...state,
        courseType: {
          ...state.courseType,
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    case CREATE_COURSE_TYPE.ERROR:
      return {
        ...state,
        courseType: {
          ...state.courseType,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case MERGE_COURSE_TYPE.SYNC: {
      const { courseType } = state;
      return {
        ...state,
        allIds: [...state.allIds, courseType.id],
        byId: { ...state.byId, [`${courseType.id}`]: { ...courseType.data } },
        courseType: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };
    }

    case UPDATE_COURSE_TYPE.REQUEST:
      return {
        ...state,
        courseType: {
          ...state.courseType,
          id: action.payload.id,
          status: REDUX_STATUS.PENDING,
        },
      };
    case UPDATE_COURSE_TYPE.SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [`${action.payload.id}`]: action.payload },
        courseType: {
          ...state.courseType,
          id: action.payload.id,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.SAVED,
        },
      };
    case UPDATE_COURSE_TYPE.ERROR:
      return {
        ...state,
        courseType: {
          ...state.courseType,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_COURSE_TYPE.SYNC:
      return {
        ...state,
        courseType: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };

    // TODO: This is not currently used, but might be in the future
    // case GET_COURSE_TYPE_FORM_DATA.REQUEST:
    //   return {
    //     ...state,
    //     status: REDUX_STATUS.PENDING,
    //   };
    case GET_COURSE_TYPE_FORM_DATA.SUCCESS:
      return {
        ...state,
        formData: action.payload,
      };
    default:
      return state;
  }
};
