import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import parseISO from 'date-fns/parseISO';
import { REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH } from 'lib/routerPaths';
import { LOAD_PLATFORM_CLOSURES } from 'store/actions';
import { selectPlatformEventById } from 'store/platformClosure/selectors';
import Button from 'react-bootstrap/Button';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import Spinner from 'components/shared/Spinner';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import PlatformClosureDetails from 'components/registrar/PlatformClosureDetails';
import PlatformClosureWarning from 'components/registrar/PlatformClosureWarning';

function PlatformClosureShowPage() {
  const { id } = useParams();
  const { id: eventId, message, closePlatformFrom, closePlatformUntil } = useSelector((state) => selectPlatformEventById(state, id)) || {};
  const dispatch = useDispatch();

  const pastEvent = parseISO(closePlatformUntil) < new Date();

  useEffect(() => {
    dispatch(LOAD_PLATFORM_CLOSURES.request());
  }, [dispatch]);

  return (
    <RegistrarTemplate>
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Settings', href: '/registrar/settings' },
                { label: 'Platform Closures', href: REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH },
                { label: 'Scheduled Closure' },
              ]}
            />

            <h1 className="mb-0">Scheduled Platform Closure</h1>
          </div>
        </div>
      </div>

      <div className="flush px-4 my-4">
        <PlatformClosureWarning />

        {!eventId && <Spinner />}

        {eventId && (
          <div className="row">
            <div className="col-md-9">
              <PlatformClosureDetails closePlatformFrom={closePlatformFrom} closePlatformUntil={closePlatformUntil} message={message} />

              <p>
                <Link to={`${REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH}/${pastEvent ? '#past' : ''}`}>
                  <Button variant="link">
                    <i className="fas fa-chevron-left me-1" />
                    Back
                  </Button>
                </Link>

                {!pastEvent && (
                <Link to={`${REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH}/${id}/edit`}>
                  <Button variant="outline-secondary">
                    <i className="fas fa-edit me-1" />
                    Edit this closure
                  </Button>
                </Link>
                )}
              </p>
            </div>
          </div>
        )}
      </div>
    </RegistrarTemplate>
  );
}

export default PlatformClosureShowPage;
