import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { OrganizationPropType, StatusPropType } from 'lib/propTypes';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import GroupFormPanel from 'components/registrar/GroupFormPanel';

const EMPTY_GROUP = {
  beginSessionAt: '',
  maxParticipants: '',
  courseTypeId: '',
  review: false,
  languagePreference: '',
  internalNotes: '',
  name: '',
};

const FormSchema = Yup.object().shape({
  groups: Yup.array()
    .of(Yup.object().shape({
      beginSessionAt: Yup.date()
        .required('Required')
        .min(new Date(), 'Start date must be in the future'),
      maxParticipants: Yup.number()
        .required('Required')
        .min(0, 'Max. participants must be greater than or equal to 0'),
      courseTypeId: Yup.string()
        .required('Required'),
      name: Yup.string()
        .required('Required'),
    })),
});

function CreateForm({ creating, organizations, onSubmit }) {
  const [organizationId, setOrganizationId] = useState();

  const handleSubmit = useCallback((values, { resetForm }) => {
    onSubmit({
      ...values,
      organizationId,
    });
    resetForm();
  }, [onSubmit, organizationId]);

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{ groups: [] }}
    >
      {({ errors, values, setFieldValue }) => (
        <Form>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-7">
                <select htmlFor="organizationId" name="organizationId" className="form-select" value={organizationId || ''} onChange={(e) => setOrganizationId(Number(e.target.value))}>
                  <option>Choose an Organization</option>
                  {
                      organizations.allIds.map((orgId) => (
                        <option key={orgId} value={orgId}>
                          {organizations.byId[orgId].shortName}
                          {` (${organizations.byId[orgId].name})`}
                        </option>
                      ))
                    }
                </select>
              </div>
            </div>
          </div>

          {values.groups.length > 0 ? <h6 className="mt-4 pb-2 border-bottom">Add Groups</h6> : undefined}
          <div className="from-group">
            <FieldArray
              name="groups"
              render={(arrayHelpers) => (
                <div>
                  {
                      values.groups.map((group, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className="row" key={index}>
                          <div className="col-md-12">
                            <GroupFormPanel
                              index={index}
                              errors={errors}
                              value={values.groups[index].beginSessionAt}
                              onRemove={() => arrayHelpers.remove(index)}
                              onChange={setFieldValue}
                              group={group}
                              organizationId={organizationId}
                            />
                          </div>
                        </div>
                      ))
                    }
                  <div><Button variant="link" className="btn-plain add-group-link" onClick={() => arrayHelpers.push({ ...EMPTY_GROUP })}>+ Add Group</Button></div>
                </div>
              )}
            />
          </div>

          <div className="my-3">
            <Button variant="primary" type="submit" disabled={creating || values.groups.length === 0}>
              Create Groups
              {creating && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
          </div>

        </Form>
      )}
    </Formik>
  );
}
CreateForm.defaultProps = {
  organizations: {},
  creating: false,
};

CreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  creating: PropTypes.bool,
  organizations: PropTypes.shape({
    status: StatusPropType,
    allIds: PropTypes.arrayOf(PropTypes.number),
    byId: PropTypes.objectOf(OrganizationPropType),
  }),
};

export default CreateForm;
