import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { formatInTimeZone, toDate } from 'date-fns-tz';
import { COMPACT_DATE, DT_ISO, COMPACT_TIME, TZ_PACIFIC, TZ_UTC } from 'lib/dateFormats';
import Flatpickr from 'react-flatpickr';
import { Form as BootstrapForm } from 'react-bootstrap';

function DateTimePicker({
  field: { name, value, onChange }, // also onBlur
  form: { errors }, // also touched, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  min,
  allowInput,
  disabled,
  // ...props
}) {
  const dateFieldRef = useRef();
  const timeFieldRef = useRef();

  const fullFieldDate = value ? parseISO(value) : '';
  const minDate = min ? format(parseISO(min), COMPACT_DATE) : null;

  const updateDate = useCallback(() => {
    const formattedDate = format(dateFieldRef.current?.flatpickr.selectedDates[0], COMPACT_DATE);
    const dateTime = toDate(`${formattedDate} ${timeFieldRef.current?.value}`, { timeZone: TZ_PACIFIC });

    onChange({
      target: {
        value: formatInTimeZone(dateTime, TZ_UTC, DT_ISO),
        name,
      },
    });
  }, [name, onChange]);

  return (
    <div style={{ maxWidth: '30rem' }}>
      <div className="d-flex flex-row align-items-center">
        <input type="hidden" name={name} value={value ?? ''} />
        <Flatpickr
          ref={dateFieldRef}
          data-enable-time
          className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
          value={fullFieldDate ? formatInTimeZone(fullFieldDate, TZ_PACIFIC, COMPACT_DATE) : ''}
          onChange={updateDate}
          options={{ minDate, allowInput, enableTime: false }}
          disabled={disabled}
        />
        <i className="fas fa-at mx-2" />
        <BootstrapForm.Select
          ref={timeFieldRef}
          className={errors[name] ? 'is-invalid' : ''}
          value={fullFieldDate ? formatInTimeZone(fullFieldDate, TZ_PACIFIC, COMPACT_TIME) : ''}
          onChange={updateDate}
          disabled={disabled}
        >
          <option label="5:00am PT" value="05:00" />
          <option label="7:30am PT" value="07:30" />
          <option label="12:30pm PT" value="12:30" />
          <option label="4:30pm PT" value="16:30" />
        </BootstrapForm.Select>
      </div>

      {errors[name] && (
      <div className="invalid-feedback d-block">
        {errors[name]}
      </div>
      )}
    </div>
  );
}

DateTimePicker.defaultProps = {
  field: {
    value: '',
  },
  form: {
    touched: {},
    errors: {},
    values: {},
  },
  min: null,
  allowInput: false,
  disabled: false,
  props: {
  },
};

DateTimePicker.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    onChange: PropTypes.func.isRequired,
  }),
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    values: PropTypes.shape({}),
  }),
  min: PropTypes.string,
  allowInput: PropTypes.bool,
  disabled: PropTypes.bool,
  props: PropTypes.shape({
  }),
};

export default DateTimePicker;
