import { fetchGet, fetchPost, handleSimpleFetchError } from 'lib/apiHelpers';
import { put, takeLatest } from 'redux-saga/effects';

import { GET_HARM_PREVENTION_REPORTS, REQUEST_HARM_PREVENTION_REPORT } from 'store/actions';

export default function* sagas() {
  yield takeLatest(GET_HARM_PREVENTION_REPORTS.REQUEST, function* getPreviousPtqReports() {
    try {
      const response = yield fetchGet('/api/reporting/reports/harm_prevention.json');
      yield put(GET_HARM_PREVENTION_REPORTS.success(response.data, { ...response.meta, cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_HARM_PREVENTION_REPORTS);
    }
  });

  yield takeLatest(REQUEST_HARM_PREVENTION_REPORT.REQUEST, function* requestPlatformPtq(action) {
    try {
      const response = yield fetchPost(
        '/api/reporting/reports/harm_prevention.json',
        {
          backgroundReport: {
            reportType: 'harm_prevention',
            filters: {
              startDate: action.payload.startDate,
              endDate: action.payload.endDate,
            },
          },
        },
      );
      yield put(REQUEST_HARM_PREVENTION_REPORT.success(response.data, { ...response.meta, cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, REQUEST_HARM_PREVENTION_REPORT);
    }
  });
}
