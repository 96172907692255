import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'bootstrap/js/dist/tooltip'; // same as 'app/javascript/index.js' to avoid conflicts
import _kebabCase from 'lodash/kebabCase';
import { useField } from 'formik';
import { SIZES } from 'lib/constants';
import { ComponentPropType } from 'lib/propTypes';
import { sentenceCase } from 'lib/utils';
import FieldProvider from 'lib/hooks/useFieldContext';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import InfoTextToolTip from './InfoTextToolTip';
import './styles.scss';

function FieldWrapper(props) {
  const { id, name, label, type, className, helpText, infoText, size, children, hideLabel, inline, required, initialValidation, showTooltip, switchPlacement, onFieldChange, onFieldBlur, innerRef } = props;
  const [field, meta, helpers] = useField(name);
  const { value, onChange, onBlur } = field;
  const { touched, error } = meta;

  const tooltipRef = useRef(null);
  const fieldRef = useRef(null);

  const derivedId = id || _kebabCase(name);
  const derivedLabel = label || sentenceCase(name);

  const handleFocus = () => {
    innerRef?.current.focus();
  };

  const handleChange = useCallback((e) => {
    if (typeof onFieldChange === 'function') {
      onFieldChange(e);
    }

    if (!e?.target) { // receives a value directly
      helpers.setValue(e);
    } else {
      onChange(e);
    }
  }, [helpers, onFieldChange, onChange]);

  const handleBlur = (e) => {
    if (typeof onFieldBlur === 'function') {
      onFieldBlur(e);
    }

    if (!e?.target) { // receives a value directly
      helpers.setTouched(true);
    } else {
      onBlur(e);
    }
  };

  useEffect(() => {
    if (touched && error && showTooltip && fieldRef?.current) {
      tooltipRef.current = new Tooltip(fieldRef.current, {
        title: error,
        customClass: 'bs-tooltip-danger',
      });
    }

    return () => {
      if (typeof tooltipRef.current?.disable === 'function') {
        tooltipRef.current.disable();
      }
    };
  }, [fieldRef, touched, error, showTooltip]);

  return (
    <FieldProvider
      value={{
        id: derivedId,
        value,
        touched,
        error,
        handleChange,
        handleBlur,
      }}
    >
      <ErrorBoundary>
        <div className={`${inline ? '' : 'mb-2'} ${className || ''}`}>
          {type !== 'switch' && (
            <>
              <label
                role="presentation"
                className={`form-label ${hideLabel ? 'sr-only' : ''} ${size === SIZES.sm ? 'text-small mb-1' : ''}`}
                htmlFor={derivedId}
                onClick={handleFocus}
              >
                {derivedLabel}
                {!hideLabel && <InfoTextToolTip text={infoText} />}
              </label>

              {showTooltip ? (<span ref={fieldRef}>{ children }</span>) : children}
            </>
          )}

          {type === 'switch' && (
            <div className="d-flex align-items-center">
              {switchPlacement === 'start' && children}
              <label className="switch-label" htmlFor={derivedId}>
                {derivedLabel}
                <InfoTextToolTip text={infoText} />
              </label>
              {switchPlacement === 'end' && children}
            </div>
          )}

          {(!!helpText || (!!infoText && hideLabel)) && (
            <div className="form-text">
              {(!!helpText && !!infoText && hideLabel) && <InfoTextToolTip text={infoText} className="me-1" />}
              {(!helpText && !!infoText && hideLabel) && infoText}
              {helpText}
            </div>
          )}

          {(touched && error && !showTooltip) && (
            <div className="form-text text-danger">
              {error}
            </div>
          )}

          {((required && initialValidation) && !(touched && error)) && (
            <div className="form-text">
              {initialValidation || 'Required'}
            </div>
          )}
        </div>
      </ErrorBoundary>
    </FieldProvider>
  );
}

FieldWrapper.defaultProps = {
  id: null,
  label: null,
  type: 'text',
  className: null,
  helpText: null,
  infoText: null,
  size: SIZES.md,
  hideLabel: false,
  inline: false,
  required: false,
  initialValidation: null,
  showTooltip: false,
  switchPlacement: 'end',
  onFieldChange: null,
  onFieldBlur: null,
  innerRef: null,
};

FieldWrapper.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'tel', 'url', 'date', 'time', 'textarea', 'select', 'checkbox', 'radio', 'switch']),
  className: PropTypes.string,
  helpText: PropTypes.string,
  infoText: PropTypes.oneOfType([
    PropTypes.string,
    ComponentPropType,
  ]),
  size: PropTypes.oneOf(Object.values(SIZES)),
  hideLabel: PropTypes.bool,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  initialValidation: PropTypes.string,
  showTooltip: PropTypes.bool,
  switchPlacement: PropTypes.oneOf(['start', 'end']),
  onFieldChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
  children: ComponentPropType.isRequired,
  innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
};

export default FieldWrapper;
