import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Details({ voucher }) {
  const setStatusClass = (n) => {
    switch (n) {
      case 'Unused':
        return 'badge bg-secondary';
      case 'Used':
        return 'badge bg-success';
      case 'Voided':
        return 'badge bg-danger';
      default:
        return 'badge bg-light';
    }
  };

  return (
    <dl className="row">
      <dt className="col-sm-4 fw-semibold">Status:</dt>
      <dd className="col-sm-8">
        <div className={`${setStatusClass(voucher.state)}`} style={{ fontSize: '1em' }}>{voucher.state}</div>
      </dd>

      {voucher.state === 'Used'
        && (
          <>
            <dt className="col-sm-4 fw-semibold">Redeemed by:</dt>
            <dd className="col-sm-8">
              {voucher.hasGroupMembership
                ? (
                  <>
                    {voucher.groupMembership.fullName}
                    {' in '}
                    <Link to={`/registrar/people/${voucher.groupMembership.userId}/group_memberships/${voucher.groupMembership.id}`} style={{ fontSize: '1em' }}>
                      {voucher.groupMembership.groupDisplayName}
                    </Link>
                  </>
                )
                : 'unknown participant'}
            </dd>
          </>
        )}
      {voucher.state === 'Unused' && (
        <>
          <dt className="col-sm-4 fw-semibold">Unredeemed:</dt>
          <dd className="col-sm-8">
            <div style={{ fontSize: '1em' }}>-</div>
          </dd>
        </>
      )}

      <dt className="col-sm-4 fw-semibold">Date Issued:</dt>
      <dd className="col-sm-8">{voucher.createdAt}</dd>

      { voucher.creator && (
        <>
          <dt className="col-sm-4 fw-semibold">Issued by:</dt>
          <dd className="col-sm-8">{voucher.creator}</dd>
        </>
      )}

      <dt className="col-sm-4 fw-semibold">Payment Marked as:</dt>
      <dd className="col-sm-8">{voucher.markTrainingPaymentAs}</dd>

      <dt className="col-sm-4 fw-semibold">Health Authority:</dt>
      <dd className="col-sm-8">{voucher.healthAuthorityName}</dd>

      <dt className="col-sm-4 fw-semibold">Course Type:</dt>
      <dd className="col-sm-8">{voucher.courseTypeNameBasic}</dd>
    </dl>
  );
}

Details.defaultProps = {
  voucher: {
    state: '',
    createdAt: '',
    creator: '',
    markTrainingPaymentAs: '',
    healthAuthorityName: '',
    courseTypeNameBasic: '',
    hasGroupMembership: false,
    groupMembership: {
      fullName: null,
      groupDisplayName: null,
      id: null,
      userId: null,
    },
  },
};

Details.propTypes = {
  voucher: PropTypes.shape({
    state: PropTypes.string,
    createdAt: PropTypes.string,
    creator: PropTypes.string,
    markTrainingPaymentAs: PropTypes.string,
    healthAuthorityName: PropTypes.string,
    courseTypeNameBasic: PropTypes.string,
    hasGroupMembership: PropTypes.bool,
    groupMembership: PropTypes.shape({
      fullName: PropTypes.string,
      groupDisplayName: PropTypes.string,
      id: PropTypes.number,
      userId: PropTypes.number,
    }),
  }),
};

export default Details;
