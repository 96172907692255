import React from 'react';
import { useSelector } from 'react-redux';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import ReportsListItem from 'components/reporting/ResponsesReport/ReportsListItem';

function ReportsList() {
  const reportIds = useSelector((state) => state.responsesReport.reports.allIds);

  if (!reportIds.length) return null;

  return (
    <div className="row mt-5">
      <div className="col">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Filters</th>
                <th>Requested</th>
                <th className="text-nowrap">
                  Duration
                  <SimpleToolTip
                    placement="top"
                    text="How long this report took to generate"
                  >
                    <span className="fas fa-info-circle ps-1" />
                  </SimpleToolTip>
                </th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>

            <tbody>
              {reportIds.map((reportId) => (
                <ReportsListItem key={reportId} itemId={reportId} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ReportsList;
