import React from 'react';
import PropTypes from 'prop-types';
import { ACTIVATION } from 'lib/constants';
import { sentenceCase } from 'lib/utils';
import Badge from 'components/shared/Badge/Badge';

function ParticipantBadge({ state, completion, activation }) {
  // deactivated => deactivated; nil => completion || nil; activated => completion || activated
  const localState = activation === ACTIVATION.DEACTIVATED ? activation : (state || activation);
  const title = activation === ACTIVATION.DEACTIVATED ? sentenceCase(activation) : (completion || sentenceCase(activation));

  switch (localState) {
    case 'ready_to_go':
    case 'registered':
      return (
        <Badge title={title} variant="blue" />
      );
    case 'completed_with_insufficient_participation':
    case 'deactivated':
      return (
        <Badge title={title} variant="red" reverse />
      );
    case 'completed':
      return (
        <Badge title={title} variant="green" reverse />
      );
    case 'tmp_group_closed':
    case 'tmp_group_extension_expired':
    case 'tmp_new_record':
    case 'payment_pending':
      return (
        <Badge title={title} variant="blue" reverse />
      );
    case 'removed':
    case 'dropout':
    case 'cancelled':
    case 'non_starter':
    case 'payment_abandoned':
      return (
        <Badge title={title} variant="red" />
      );
    case 'in_session_logged_in':
    case 'in_extension':
      return (
        <Badge title={title} variant="green" />
      );
    case 'facilitator':
    case 'curriculum_reviewer':
      return (
        <Badge title={title} variant="grey" reverse />
      );
    default:
      return (
        <Badge title={title || 'Inactive'} variant="grey" />
      );
  }
}

ParticipantBadge.defaultProps = {
  state: null,
  completion: null,
  activation: null,
};

ParticipantBadge.propTypes = {
  state: PropTypes.string,
  completion: PropTypes.string,
  activation: PropTypes.string,
};

export default ParticipantBadge;
