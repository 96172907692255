import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { REQUEST_HARM_PREVENTION_REPORT } from 'store/actions';
import Button from 'react-bootstrap/Button';
import DatePickerField from 'components/shared/FormFields/DatePickerField';

const FormSchema = Yup.object().shape({
  startDate: Yup.date().required('Required').typeError('Valid date required'),
  endDate: Yup.date().required('Required').typeError('Valid date required'),
});

function RequestForm() {
  const dispatch = useDispatch();

  const handleSubmit = (values, { resetForm }) => {
    dispatch(REQUEST_HARM_PREVENTION_REPORT.request(values));
    resetForm();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        startDate: '',
        endDate: '',
      }}
    >
      {({ values, resetForm, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4">
                  <DatePickerField
                    name="startDate"
                    label="Responses between:"
                    placeholder="Pick start date"
                    required
                    dateFormat="m/d/Y"
                    onFieldChange={(value) => !value && setFieldValue('endDate', '')}
                  />
                </div>

                <div className="col-md-4">
                  <DatePickerField
                    name="endDate"
                    label="and:"
                    disabled={!values.startDate}
                    placeholder="Pick end date"
                    required
                    dateFormat="m/d/Y"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="my-3">
            <Button variant="primary" type="submit" className="me-2">
              Request this report
            </Button>
            <Button variant={null} className="btn-plain" onClick={resetForm}>
              Reset
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default RequestForm;
