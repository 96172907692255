import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CREATE_REPORT } from 'store/actions';
import CreatableSelect from 'react-select/creatable';
import Button from 'react-bootstrap/Button';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';

const customCreatableSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? 'rgba(242, 247, 247, 0.55)' : 'var(--bs-sn-clr-border-grey)',
    borderRadius: 'var(--bs-border-radius-lg)',
    fontSize: '1.25rem',
    padding: '0 0.75rem',
    minHeight: 'calc(1.5em + 1rem + 2px)',
    letterSpacing: 'normal',
    lineHeight: '1.5',
    boxSizing: 'borderBox',
    '&:hover': {
      borderColor: state.isFocused ? 'rgba(242, 247, 247, 0.55)' : 'var(--bs-sn-clr-border-grey)',
      cursor: 'text',
    },
    boxShadow: state.isFocused && '0 0 0 0.25rem rgba(0, 86, 97, 0.25)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    borderCollapse: 'collapse',
  }),
  multiValue: (provided) => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
};

const createOption = (label) => ({
  label,
  value: label,
});

const FormSchema = Yup.object().shape({
  keywords: Yup.array(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
    .required('Add at least one keyword or phrase by typing and pressing Enter or Tab after each')
    .min(1, 'Add at least one keyword or phrase by typing and pressing Enter or Tab after each'),
  limit: Yup.number('Must be a number').integer('Must be an integer').min(1, 'Must be greater than 1').max(1000, 'Must be less than 1000')
    .required('Required'),
  kind: Yup.string().required('Required'),
});

function SearchForm() {
  const keywordSelectRef = useRef(null);
  const dispatch = useDispatch();
  const [currentKeyword, setCurrentKeyword] = useState('');

  const handleSubmit = (values, { resetForm }) => {
    const { keywords, limit, kind } = values;
    dispatch(CREATE_REPORT.request({ limit, kind, keywords: keywords.map(({ value }) => value) }));
    resetForm();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
      initialValues={{
        keywords: [],
        limit: '200',
        kind: 'participants',
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, setFieldValue, errors, handleReset }) => (
        <Form>
          <div className="row mb-3">
            <div className="col-md-8">
              <label className="form-label" role="presentation" htmlFor="keywords" onClick={() => keywordSelectRef.current?.focus()}>Keywords or Phrases</label>
              <CreatableSelect
                ref={keywordSelectRef}
                components={{ DropdownIndicator: null }}
                isMulti
                isClearable
                menuIsOpen={false}
                styles={customCreatableSelectStyles}
                onChange={(value) => {
                  if (!value) {
                    setFieldValue('keywords', []);
                    return;
                  }

                  setFieldValue('keywords', value);
                }}
                onBlur={() => {
                  if (currentKeyword.length === 0) return;
                  const found = values.keywords.find((item) => item.value === currentKeyword);

                  if (!found) {
                    setFieldValue('keywords', [...values.keywords, createOption(currentKeyword)]);
                  }

                  setCurrentKeyword('');
                }}
                onInputChange={(value) => setCurrentKeyword(value)}
                onKeyDown={(event) => {
                  if (!currentKeyword) return;
                  switch (event.key) {
                    case 'Enter':
                    case 'Tab': {
                      const found = values.keywords.find((item) => item.value === currentKeyword);

                      if (!found) {
                        setFieldValue('keywords', [...values.keywords, createOption(currentKeyword)]);
                      }

                      setCurrentKeyword('');
                      event.preventDefault();
                      break;
                    }
                    default: {
                      break;
                    }
                  }
                }}
                className=""
                placeholder="Type and press enter after each keyword or phrase..."
                inputValue={currentKeyword}
                value={values.keywords}
              />
              {errors.keywords && (
              <div id="keywordsFeedback" className="invalid-feedback d-block">
                {errors.keywords}
              </div>
              )}
            </div>

            <div className="col-md-2">
              <label className="form-label" htmlFor="limit">
                Limit
                <SimpleToolTip
                  id="limit-help"
                  placement="top"
                  width="20rem"
                  variant="info"
                  text={(
                    <>
                      <p className="mb-0 text-start">
                        The results are fetched using two separate queries:
                      </p>
                      <ol className="m-0 my-2 text-start">
                        <li>Journal Answers</li>
                        <li>Journal Comments, Discussion Board Comments, Questionnaire Responses</li>
                      </ol>

                      <p className="mb-0 text-start">
                        This limit will be applied to each query separately so you may get more results than the limit.
                        The limit cannot exceed 1000 for performance reasons.
                      </p>
                    </>
                    )}
                >
                  <span className="fas fa-info-circle ps-1" />
                </SimpleToolTip>
              </label>
              <Field
                id="limit"
                name="limit"
                type="number"
                step="10"
                className="form-control form-control-lg"
                value={values.limit}
              />
              {errors.limit && (
                <div id="limitFeedback" className="invalid-feedback d-block">
                  {errors.limit}
                </div>
              )}
            </div>

            <div className="col-md-2">
              <label className="form-label">Results for</label>

              <div className="form-check">
                <Field
                  id="kind-participants"
                  name="kind"
                  type="radio"
                  value="participants"
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="kind-participants">Participants</label>
              </div>

              <div className="form-check">
                <Field
                  id="kind-facilitators"
                  name="kind"
                  type="radio"
                  value="facilitators"
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="kind-facilitators">Facilitators</label>
              </div>

              {errors.kind && (
                <div id="kindFeedback" className="invalid-feedback d-block">
                  {errors.kind}
                </div>
              )}
            </div>
          </div>

          <div className="mb-3">
            <Button variant="primary" type="submit" className="me-2">Request this report</Button>
            <Button variant={null} onClick={handleReset} className="btn-plain">Reset</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SearchForm;
