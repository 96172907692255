import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { UPDATE_COMPLETION_THRESHOLD, RESET_TASK } from 'store/courses/actions';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import InputField from 'components/shared/FormFields/InputField';
import ErrorMessage from 'components/shared/ErrorMessage';

const FormSchema = Yup.object().shape({
  completionThreshold: Yup.string().required().label('Completion threshold'),
});

function AddCompletionThresholdForm({ setUpdatingThreshold }) {
  const { taskId } = useParams();
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.courses.task);
  const inputRef = useRef(null);
  const { completionThresholdInSeconds } = useSelector((state) => state.courses.tasks[taskId]);
  const averageTimeBeforeCompletion = useSelector((state) => state.courses.tasks[taskId].averageTimeBeforeCompletion);
  const valueInMinutes = averageTimeBeforeCompletion < 60 ? `${averageTimeBeforeCompletion} seconds` : `${(averageTimeBeforeCompletion / 60).toFixed(2)} minutes (${averageTimeBeforeCompletion} seconds)`;

  const handleSubmit = useCallback((values, actions) => {
    dispatch(UPDATE_COMPLETION_THRESHOLD.request(values, { formikActions: actions }));
  }, [dispatch]);

  useEffect(() => {
    inputRef.current.focus({ preventScroll: true });
  }, []);

  useEffect(() => {
    if (status === 'success') {
      dispatch(RESET_TASK.action());
      setUpdatingThreshold(false);
    }
  }, [status, setUpdatingThreshold, dispatch]);

  return (
    <>
      <ErrorMessage error={error} />

      <Formik
        onSubmit={handleSubmit}
        initialValues={{ completionThreshold: completionThresholdInSeconds, taskId: Number(taskId) }}
        validationSchema={FormSchema}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <Form style={{ width: '20rem' }}>
            <div className="row align-items-center">
              <InputField addOnEnd="seconds" className="col-6" name="completionThreshold" label="Completion threshold" size="sm" inputRef={inputRef} autoComplete="nope" />
            </div>
            <p className="mb-0">Average time before completion:</p>
            {averageTimeBeforeCompletion !== undefined ? <div className="mb-1">{valueInMinutes}</div> : <div><Spinner size="sm" className="ms-1" animation="border" role="status" /></div>}
            <Button variant="primary" size="sm" type="submit" disabled={isSubmitting}>
              Add
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
          </Form>

        )}
      </Formik>
    </>
  );
}

AddCompletionThresholdForm.defaultProps = {
  setUpdatingThreshold: () => {},
};

AddCompletionThresholdForm.propTypes = {
  setUpdatingThreshold: PropTypes.func,
};

export default AddCompletionThresholdForm;
