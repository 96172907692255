import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { REORDER_COURSE_ARTICLE, RESET_COURSE_ARTICLE } from 'store/courses/actions';
import { ARTICLE_NEW_PATH } from 'lib/routerPaths';
import { buildRoutePath } from 'lib/routerHelpers';
import Accordion from 'react-bootstrap/Accordion';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import ArticleNavItem from './ArticleNavItem';
import Skeleton from './ArticleNavSkeleton';

/* eslint-disable react/jsx-props-no-spreading */

function ArticleNav() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { course, setCurrentArticle, activeKey, setActiveKey } = useCourseShowContext();
  const dispatch = useDispatch();
  const buttonRef = useRef();

  // 57px s/be the height of the button, but we'll calculate it when the button is rendered
  const defaultButtonHeight = course?.isLocked ? 0 : 57;

  const articleNewPath = buildRoutePath(ARTICLE_NEW_PATH, { courseId: course?.id });
  const newArticle = (pathname === articleNewPath || !course?.articleIds?.length) ? (course?.articleIds?.length || 0) + 1 : 0;

  const handleClick = useCallback(() => {
    setCurrentArticle(null);
    setActiveKey(newArticle);
    dispatch(RESET_COURSE_ARTICLE.action());
    history.push(articleNewPath);
  }, [articleNewPath, newArticle, setCurrentArticle, setActiveKey, dispatch, history]);

  const handleSelect = useCallback((key) => {
    setActiveKey(key);
  }, [setActiveKey]);

  const handleDragEnd = useCallback(({ destination, source, draggableId }) => {
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    dispatch(REORDER_COURSE_ARTICLE.action({ courseId: destination.droppableId, articleId: draggableId, fromIndex: source.index, toIndex: destination.index }));
  }, [dispatch]);

  if (!course?.id) {
    return (
      <Skeleton />
    );
  }

  return (
    <>
      {course?.id && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={`${course?.id}`} type="article">
            {(provided) => (
              <Accordion
                className="outline"
                style={{
                  height: `calc(100% - ${buttonRef.current?.offsetHeight || defaultButtonHeight}px)`,
                  overflowY: 'scroll',
                }}
                flush
                onSelect={handleSelect}
                activeKey={activeKey}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {course?.articleIds?.map((id, index) => <ArticleNavItem key={`${id}`} articleId={id} index={index} />)}

                {/* New module */}
                {newArticle > 0 && (
                  <li className="list-group-item d-flex align-items-center border-0 ps-1 list-group-item-active">
                    <span className="w-100 border-0 text-start font-weight-semibold" style={{ paddingLeft: '24px' }}>
                      <span className="accordion-position">{`${newArticle}. `}</span>
                      New article
                    </span>
                  </li>
                )}

                {provided.placeholder}
              </Accordion>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {!course?.isLocked && (
        <button
          ref={buttonRef}
          className={`btn btn-primary ${course.articleIds?.length ? 'btn-plain' : 'rounded-0'} w-100 text-start text-nowrap border-top py-3`}
          type="button"
          onClick={handleClick}
        >
          + Add article
        </button>
      )}
    </>
  );
}

export default ArticleNav;
