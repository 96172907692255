import { useEffect } from 'react';
import { UPDATE_GROUP_SHOW_DATA } from 'store/groupShow/actions';
import { UPDATE_BULK_IMPORT, COMPLETE_BULK_IMPORT } from 'store/bulkImport/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import consumer from '../../../app/javascript/channels/consumer';

const BulkImportActions = { UPDATE_BULK_IMPORT, COMPLETE_BULK_IMPORT, UPDATE_GROUP_SHOW_DATA };

function useGroupsChannel() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const subscriptions = consumer.subscriptions.subscriptions.map((s) => s.identifier);
    if (!subscriptions.includes('{"channel":"GroupsChannel"}')) {
      consumer.subscriptions.create(
        'GroupsChannel',
        {
          received(payload) {
            if (payload?.type && Object.keys(BulkImportActions).includes(payload.type)) {
              dispatch(BulkImportActions[payload.type].action(payload));
            }
          },
        },
      );
    }
  }, [id, dispatch]);
}

export default useGroupsChannel;
