import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import { getSearchParams } from 'lib/routerHelpers';
import MessagesListPage from 'pages/facilitator/MessagesListPage';
import MessagesNewPage from 'pages/facilitator/MessagesNewPage';
import MessagesShowPage from 'pages/facilitator/MessagesShowPage';

function MessagesPage() {
  return (
    <Switch>
      {/* A <Switch> looks through its children <Route>s and
        renders the first one that matches the current URL. */}
      <Route exact path="/facilitator/messages" render={(router) => <MessagesListPage page={getSearchParams(router.location.search, 'page', { numeric: true })} />} />
      <Route exact path="/facilitator/messages/new" render={(router) => <MessagesNewPage userId={getSearchParams(router.location.search, 'user_id', { numeric: true })} />} />
      <Route exact path="/facilitator/messages/:conversationId" render={(router) => <MessagesShowPage params={router.match.params} />} />
    </Switch>
  );
}

export default MessagesPage;
