import React from 'react';
import PropTypes from 'prop-types';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { GroupMembershipPropType } from 'lib/propTypes';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_GROUP_SHOW_PATH, REGISTRAR_GROUP_SHOW_PATH } from 'lib/routerPaths';
import SanyasIcon from 'components/shared/SanyasIcon';

function GroupDropDown({ gm, groupMemberships }) {
  const { id: workspaceId, path: workspacePath } = useWorkspace();

  if (!gm || !groupMemberships) return null;

  return (
    <Dropdown as={ButtonGroup}>
      <Button
        as={Link}
        variant={null}
        className="bg-white border"
        to={buildRoutePath(workspaceId === 'facilitator' ? FACILITATOR_GROUP_SHOW_PATH : REGISTRAR_GROUP_SHOW_PATH, { id: gm?.group?.id })}
        title={`View ${gm?.group?.name} (${gm?.group?.decoratedState})`}
      >
        {gm?.group?.name || '...'}
      </Button>
      <Dropdown.Toggle
        split
        variant={null}
        bsPrefix="no-arrow"
        className="bg-white border"
        id="group-switcher"
        title="Click to select other group memberships"
        aria-label="Click to select other group memberships"
      >
        <SanyasIcon name="chevronDown" size="xs" />
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        {groupMemberships.data?.map((ddGm) => (
          <Dropdown.Item key={ddGm.id} as={Link} to={`${workspacePath}/people/${ddGm.userId}/group_memberships/${ddGm.id}`} eventKey={ddGm.id} disabled={ddGm.id === gm.id}>
            {ddGm.group.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>

  );
}

GroupDropDown.defaultProps = {
  gm: null,
  groupMemberships: null,
};

GroupDropDown.propTypes = {
  gm: GroupMembershipPropType,
  groupMemberships: PropTypes.shape({
    data: PropTypes.arrayOf(GroupMembershipPropType),
  }),
};

export default GroupDropDown;
