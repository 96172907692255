import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SIZES } from 'lib/constants';
import { useFieldContext } from 'lib/hooks/useFieldContext';
import ReactAsyncSelect from 'react-select/async';
import { ReactSelectStyles } from './ReactSelectStyles';

function AsyncSelect({ name, size, placeholder, includeBlank, multiple, required, disabled, loading, loadOptions, cacheOptions, noOptionsMessage, innerRef }) {
  const { id, value, touched, error, handleChange, handleBlur } = useFieldContext();

  const handleSelectChange = useCallback((selected) => {
    handleChange(selected || '');
  }, [handleChange]);

  return (
    <ReactAsyncSelect
      ref={innerRef}
      key={id}
      inputId={name}
      name={name}
      menuPosition="fixed"
      className={`${(touched && error) ? 'is-invalid' : ''}`}
      aria-invalid={!!(touched && error)}
      styles={ReactSelectStyles(size)}
      value={value}
      placeholder={placeholder || includeBlank || 'Please select'}
      isDisabled={disabled || loading}
      isLoading={loading}
      cacheOptions={cacheOptions}
      loadOptions={loadOptions}
      noOptionsMessage={noOptionsMessage}
      onChange={handleSelectChange}
      onBlur={handleBlur}
      isMulti={multiple}
      isClearable={!required}
      isSearchable
    />
  );
}

AsyncSelect.defaultProps = {
  size: SIZES.md,
  placeholder: null,
  includeBlank: null,
  multiple: false,
  required: false,
  disabled: false,
  loading: false,
  cacheOptions: false,
  noOptionsMessage: () => 'No options found',
  innerRef: null,
};

AsyncSelect.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  placeholder: PropTypes.string,
  includeBlank: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  loadOptions: PropTypes.func.isRequired,
  cacheOptions: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
};

export default AsyncSelect;
