import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'bootstrap/js/dist/tooltip'; // same as 'app/javascript/index.js' to avoid conflicts
import { kebabCase as _kebabCase, upperFirst as _upperFirst, lowerCase as _lowerCase } from 'lodash';
import { StandardOptionsPropType } from 'lib/propTypes';
import ReactSelect from 'react-select';
import { ReactSelectStyles } from './ReactSelectStyles';

const SIZES = {
  sm: '6em',
  md: '10em',
  lg: '12em',
  xl: '16em',
  xxl: '20em',
};

const setDefaultSelectValue = (value) => {
  if (value) {
    return { label: value, value };
  }
  return null;
};

function Select({ name, index, value, error, options, size, required, onBlur, disabled }) {
  const selectRef = useRef(null);
  const tooltipRef = useRef({ destroy: false });

  useEffect(() => {
    if (error) {
      tooltipRef.current = new Tooltip(selectRef.current, {
        title: error,
        customClass: 'bs-tooltip-danger',
      });
    }

    return () => {
      if (typeof tooltipRef.current?.hide === 'function') {
        tooltipRef.current.hide();
      }
    };
  }, [error]);

  const handleBlur = (e) => {
    if (value !== e?.value) {
      onBlur(e, index, name);
    }
  };

  const selectName = `user.${index}.${name}`;
  const selectId = _kebabCase(selectName);
  const selectLabel = _upperFirst(_lowerCase(selectName));

  return (
    <div key={selectId} ref={selectRef} className="position-relative" style={{ width: SIZES[size] }}>
      <label className="visually-hidden" htmlFor={selectId}>{selectLabel}</label>
      <ReactSelect
        key={selectId}
        inputId={selectName}
        name={selectName}
        menuPosition="fixed"
        aria-invalid={error}
        className={`${error ? 'is-invalid' : ''}`}
        styles={ReactSelectStyles}
        defaultValue={setDefaultSelectValue(value)}
        options={options}
        onChange={handleBlur}
        isDisabled={disabled}
        required={required}
        isClearable={!required}
      />
    </div>
  );
}

Select.defaultProps = {
  value: '',
  error: null,
  size: 'md',
  required: false,
  onBlur: () => {},
  disabled: false,
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  options: StandardOptionsPropType.isRequired,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(Select);
