import React from 'react';
import { useSelector } from 'react-redux';
import { QUESTION_TYPES, REDUX_STATUS, TASK_TYPES } from 'lib/constants';
import { LocalePropType } from 'lib/propTypes';
import { getLocaleSuffix } from 'lib/utils';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import ErrorMessage from 'components/shared/ErrorMessage';
import QuestionSkeleton from '../../../curriculum/JournalTask/QuestionSkeleton';
import EssayQuestion from './EssayQuestion';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import Heading from './Heading';

const questionComponents = {
  [QUESTION_TYPES.MULTIPLE_CHOICE]: MultipleChoiceQuestion,
  [QUESTION_TYPES.HEADING]: Heading,
  [QUESTION_TYPES.ESSAY]: EssayQuestion,
};

function Questions({ locale }) {
  const { currentTask } = useCourseShowContext();
  const { data: questions, status, error } = useSelector((state) => state.courses.questions);
  const currentTaskClosing = currentTask?.[`closing${getLocaleSuffix(locale)}`];

  return (
    <div className="form-builder mt-4">
      <h3>Questions</h3>

      {(currentTask?.type === TASK_TYPES.MULTIPLE_CHOICE_QUIZ && currentTaskClosing) && (
        <>
          <span className="d-inline-block form-label">Closing text</span>
          <div className="border rounded bg-light p-3" style={{ whiteSpace: 'pre-wrap' }}>
            {currentTaskClosing}
          </div>
        </>
      )}

      {status === REDUX_STATUS.PENDING && <QuestionSkeleton />}

      {status === REDUX_STATUS.SUCCESS && questions.map((question, index) => {
        const QuestionComponent = questionComponents[question.type];
        if (!QuestionComponent) return null;
        return (
          <QuestionComponent
            key={question.id}
            index={index}
            question={question}
            locale={locale}
          />
        );
      })}

      {status === REDUX_STATUS.ERROR && <ErrorMessage error={error} />}
    </div>
  );
}

Questions.defaultProps = {
  locale: 'en',
};

Questions.propTypes = {
  locale: LocalePropType,
};

export default Questions;
