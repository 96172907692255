import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field } from 'formik';
import format from 'date-fns/format';
import { D_GROUP_NAME } from 'lib/dateFormats';
import { CourseTypePropType, LimitedGroupPropType } from 'lib/propTypes';
import { selectCourseTypesByOrganizationId } from 'store/courseTypes/selectors';
import Flatpickr from 'react-flatpickr';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';

function panelGroupName(courseType, beginSessionAt) {
  const { name, nameForCertificate } = courseType ?? {};
  if (courseType && beginSessionAt) {
    return `${name || nameForCertificate} - ${format(beginSessionAt, D_GROUP_NAME)}`;
  }
  return '...';
}

function GroupFormPanel(props) {
  const { value, group, index, onChange, organizationId, errors, onRemove } = props;
  const allCourseTypes = useSelector((state) => selectCourseTypesByOrganizationId(state, organizationId));
  const courseTypes = allCourseTypes.filter((ct) => !ct.hideFromCurriculumTab);

  const [isAvailableInFrench, setIsAvailableInFrench] = useState(false);

  const handleCourseTypeChange = useCallback((e) => {
    const { name, value: newCourseTypeId } = e.currentTarget;
    const newCourseType = courseTypes.find((ct) => ct.id === Number(newCourseTypeId));
    setIsAvailableInFrench(newCourseType?.isAvailableInFrench);
    onChange(name, newCourseTypeId);
    onChange(`groups.${index}.name`, panelGroupName(newCourseType, group.beginSessionAt));
  }, [courseTypes, onChange, index, group]);

  const handleLanguagePreferenceChange = useCallback((e) => {
    const { name, value: newLanguagePreference } = e.currentTarget;
    onChange(name, newLanguagePreference);
  }, [onChange]);

  const generateGroupName = useCallback((date) => {
    const courseType = courseTypes.find((ct) => ct.id === Number(group.courseTypeId));
    onChange(`groups.${index}.beginSessionAt`, date[0]);
    onChange(`groups.${index}.name`, panelGroupName(courseType, date[0]));
  }, [courseTypes, group, index, onChange]);

  const showError = useCallback((errorKey) => {
    if (errors && errors.groups && errors.groups[index] && errors.groups[index][errorKey]) {
      return <small className="form-text text-danger">{errors.groups[index][errorKey]}</small>;
    }
    return undefined;
  }, [errors, index]);

  return (
    <div className="mb-3">
      <div className="row mb-3 mt-3">
        <div className="col-md-4">
          <label htmlFor={`groups.${index}.courseTypeId`} className="form-label">Course</label>
          <br />
          <Field name={`groups.${index}.courseTypeId`} as="select" className="form-select" onChange={handleCourseTypeChange}>
            <option>Choose a Course</option>
            {courseTypes?.map((ct) => (
              <option key={ct.id} value={ct.id}>{ct.name}</option>
            ))}
          </Field>
          {showError('courseTypeId')}
        </div>
        <div className="col-md-4 me-3">
          <label htmlFor={`groups.${index}.beginSessionAt`} className="form-label">Start date</label>
          <br />
          <Flatpickr
            value={value}
            name={`groups.${index}.beginSessionAt`}
            options={{ dateFormat: 'm/d/Y' }}
            placeholder="Pick Date"
            aria-label="Pick Date"
            className="form-control"
            onChange={(date) => generateGroupName(date)}
            style={{ backgroundColor: 'white' }}
          />
          {showError('beginSessionAt')}
        </div>
        <div className="col-1">
          <button type="button" className="btn ms-auto" onClick={onRemove} title="Remove group">
            <i className="far fa-trash-alt" />
            <span className="visually-hidden">Remove group</span>
          </button>
        </div>
      </div>

      <div className="mb-3">
        <div className="col-md-8">
          <label htmlFor={`groups.${index}.name`} className="form-label">Group name</label>
          <br />
          <div className="input">
            <Field placeholder="Group - start date" type="text" name={`groups.${index}.name`} className="form-control" />
          </div>
        </div>
        {showError('name')}
      </div>
      <div className="row d-flex align-items-center pb-5 border-bottom">
        <div className="col-md-2">
          <label htmlFor={`groups.${index}.maxParticipants`} className="form-label">Max. participants</label>
          <br />
          <div className="input-group">
            <span className="input-group-text">#</span>
            <Field name={`groups.${index}.maxParticipants`} className="form-control" type="number" min="0" />
          </div>
          {showError('maxParticipants')}
        </div>
        <div className="col-2 mt-3">
          <div className="form-check">
            <Field name={`groups.${index}.review`}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {({ field }) => <input {...field} id={`groups.${index}.review`} className="form-check-input" type="checkbox" />}
            </Field>

            <label htmlFor={`groups.${index}.review`} className="form-check-label">
              Review group
            </label>
          </div>
        </div>
        <div className="col-2 mt-3">
          <div className="form-check">
            <SimpleToolTip
              id={`groups.${index}.languagePreference-tooltip`}
              placement="top"
              variant="info"
              text={`This course type is ${isAvailableInFrench ? '' : 'not '}available in French`}
            >
              <span className="d-inline-block">
                <Field name={`groups.${index}.languagePreference`}>
                  {({ field }) => (
                    <input
                      {...field} // eslint-disable-line react/jsx-props-no-spreading
                      id={`groups.${index}.languagePreference`}
                      className="form-check-input"
                      type="checkbox"
                      value="fr"
                      disabled={!isAvailableInFrench}
                      onChange={handleLanguagePreferenceChange}
                    />
                  )}
                </Field>
                <label htmlFor={`groups.${index}.languagePreference`} className="form-check-label">
                  French group
                </label>
              </span>
            </SimpleToolTip>
          </div>
        </div>
      </div>
    </div>
  );
}

GroupFormPanel.defaultProps = {
  index: 0,
  value: '',
  courseTypes: null,
  group: null,
  organizationId: null,
  onRemove: () => {},
  onChange: () => {},
};

GroupFormPanel.propTypes = {
  index: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  courseTypes: PropTypes.arrayOf(CourseTypePropType),
  errors: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  group: LimitedGroupPropType,
  organizationId: PropTypes.number,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

export default GroupFormPanel;
