import { ComponentPropType } from 'lib/propTypes';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // INFO: The component will probably throw an error anyway, so we probably don't need to output it.
  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   console.warn(error, errorInfo); // eslint-disable-line no-console
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <p className="py-2 fw-semibold text-danger">
          An error occurred when trying to display this component.
        </p>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: ComponentPropType.isRequired,
};

export default ErrorBoundary;
