import React from 'react';
import BaseSkeleton from 'components/shared/Skeleton';

function Skeleton() {
  return (
    <div className="col-xxl-4 col-md-6 mb-2">
      <div className="card h-100 shadow-sm bg-white rounded">
        <div className="px-3 py-2">
          <BaseSkeleton classes="title width-50 mb-0" />

          <div className="d-flex align-items-center mt-3">
            <BaseSkeleton classes="button" />
            <BaseSkeleton classes="text width-100 ms-auto" />
          </div>
        </div>

        <div className="d-flex justify-content-between border-top px-3 py-3 text-small">
          <BaseSkeleton classes="text width-quarter" />
          <BaseSkeleton classes="text width-quarter ms-2" />
          <BaseSkeleton classes="text width-quarter ms-2" />
          <BaseSkeleton classes="text width-quarter ms-2" />
        </div>
      </div>
    </div>
  );
}

export default Skeleton;
