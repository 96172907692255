import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useBreakpoint, { breakpoints } from 'lib/hooks/useBreakpoint';
import Button from 'react-bootstrap/Button';
import { selectArticleById } from 'store/courses/selectors';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import AddArticleKeyPopover from './AddArticleKeyPopover';

function ArticleKey() {
  const { articleId } = useParams();
  const article = useSelector((state) => selectArticleById(state, articleId));
  const breakpoint = useBreakpoint();
  const [updatingKey, setUpdatingKey] = useState(false);

  const toggleArticleKey = useCallback(() => {
    setUpdatingKey((prev) => !prev);
  }, []);

  if (!article) return null;

  return (
    <div className="border rounded mt-2 py-3 px-3">
      <div className="d-flex align-items-center justify-content-between">
        <h6 className="mb-0">Key</h6>
        <OverlayTrigger
          trigger="click"
          placement={breakpoint === breakpoints.XXL ? 'left' : 'top'}
          overlay={AddArticleKeyPopover}
          rootClose
          show={updatingKey}
          onToggle={toggleArticleKey}
          popperConfig={{ articleId: article.id, setUpdatingKey }}
        >
          <Button className="btn-plain d-inline-flex" variant="primary" disabled={updatingKey} size="sm">
            {article.key}
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  );
}

export default ArticleKey;
