import React from 'react';
import { useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import FacilitatorReportsListItem from 'components/reporting/FacilitatorReports/FacilitatorReportsListItem';

function FacilitatorReportsList() {
  const { allIds } = useSelector((state) => state.facilitatorReport.reports);

  if (!allIds.length) return null;

  return (
    <Table bordered hover responsive className="mt-4 sn-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Status</th>
          <th>Filters</th>
          <th>Requested</th>
          <th className="text-nowrap">
            Duration
            <SimpleToolTip
              placement="top"
              text="How long this report took to generate"
            >
              <span className="fas fa-info-circle ps-1" />
            </SimpleToolTip>
          </th>
          <th className="text-end">Actions</th>
        </tr>
      </thead>

      <tbody>
        {allIds.map((id) => (
          <FacilitatorReportsListItem key={id} itemId={id} />
        ))}
      </tbody>
    </Table>
  );
}

export default FacilitatorReportsList;
