import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import { REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH } from 'lib/routerPaths';
import { LOAD_PLATFORM_CLOSURES } from 'store/actions';
import { selectPlatformEventById } from 'store/platformClosure/selectors';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import PlatformClosureForm from 'components/registrar/PlatformClosureForm';
import PlatformClosureDetails from 'components/registrar/PlatformClosureDetails';
import PlatformClosureWarning from 'components/registrar/PlatformClosureWarning';

function PlatformClosureEditPage() {
  const { id } = useParams();
  const { id: eventId, message, closePlatformFrom, closePlatformUntil } = useSelector((state) => selectPlatformEventById(state, id)) || {};
  const dispatch = useDispatch();

  const pastEvent = parseISO(closePlatformUntil) < new Date();

  useEffect(() => {
    dispatch(LOAD_PLATFORM_CLOSURES.request());
  }, [dispatch]);

  return (
    <RegistrarTemplate>
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Settings', href: '/registrar/settings' },
                { label: 'Platform Closures', href: REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH },
                { label: 'Edit Scheduled Closure' },
              ]}
            />

            <h1 className="mb-0">Edit Scheduled Platform Closure</h1>
          </div>
        </div>
      </div>

      <div className="flush px-4 my-4">
        <PlatformClosureWarning />

        {pastEvent && (
          <div className="row">
            <div className="col-md-9">
              <Alert variant="danger">A past closure cannot be edited.</Alert>
              <PlatformClosureDetails closePlatformFrom={closePlatformFrom} closePlatformUntil={closePlatformUntil} message={message} />

              <Link to={`${REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH}/#past`}>
                <Button variant="link">
                  <i className="fas fa-chevron-left me-1" />
                  Back
                </Button>
              </Link>
            </div>
          </div>
        )}

        {(eventId && !pastEvent) && (
          <PlatformClosureForm
            initialValues={{
              id: eventId,
              message,
              closePlatformFrom: startOfDay(parseISO(closePlatformFrom)),
              closePlatformUntil: startOfDay(parseISO(closePlatformUntil)),
            }}
          />
        )}
      </div>
    </RegistrarTemplate>
  );
}

export default PlatformClosureEditPage;
