import React from 'react';
import { Link } from 'react-router-dom';
import { CourseTypePropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_COURSE_TYPE_PATH } from 'lib/routerPaths';
import SanyasIcon from 'components/shared/SanyasIcon';

function CourseTypeCard(props) {
  const { courseType } = props;

  return (
    <div className="col-xl-4 col-md-6 mb-2">
      <Link className="text-decoration-none" to={buildRoutePath(FACILITATOR_COURSE_TYPE_PATH, { id: courseType.id })}>

        <div className="card h-100 shadow-sm p-3 bg-white rounded d-flex justify-content-between">
          <div>
            <h4 className="text-primary">
              {courseType.name}
            </h4>

            {(courseType.nameForCertificate && courseType.nameForCertificate !== courseType.name) && (
              <p className="text-small">
                {courseType.nameForCertificate}
              </p>
            )}

            {courseType.isAvailableInFrench && (
              <p className="text-small">
                <SanyasIcon name="checkmark" size="xl" className="me-1" />
                Available in french
              </p>
            )}
          </div>

          <div>
            <p className={`badge badge-${courseType.isCore ? 'green' : 'red'}`}>
              {courseType.isCore ? 'Core' : 'Post-training'}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}

CourseTypeCard.defaultProps = {
};

CourseTypeCard.propTypes = {
  courseType: CourseTypePropType.isRequired,
};

export default CourseTypeCard;
