import React from 'react';

function CaretDownFill() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8705 16.71L3.67652 8.487C2.82752 7.5195 3.51752 6 4.80602 6H19.194C19.4824 5.99975 19.7647 6.08263 20.0072 6.23871C20.2497 6.39479 20.442 6.61746 20.5612 6.88004C20.6804 7.14262 20.7213 7.434 20.6792 7.71927C20.637 8.00454 20.5136 8.27161 20.3235 8.4885L13.1295 16.7085C12.9887 16.8696 12.8151 16.9987 12.6203 17.0872C12.4255 17.1757 12.214 17.2215 12 17.2215C11.7861 17.2215 11.5746 17.1757 11.3798 17.0872C11.1849 16.9987 11.0113 16.8696 10.8705 16.7085V16.71Z" fill="currentColor" />
    </svg>
  );
}

export default CaretDownFill;
