import React from 'react';
import { Link } from 'react-router-dom';

function SuperFacilitatorNav() {
  return (
    <div className="side-nav bg-light border-end p-2">
      <ul className="nav nav-pills flex-column">
        {/* TODO Re-enable when Dashboard has content
        <li className="nav-item">
          <Link className="nav-link" to="/lead_facilitator">Dashboard</Link>
        </li> */}
        <li className="nav-item">
          <Link className="nav-link" to="/lead_facilitator/reports">Reports</Link>
        </li>
      </ul>
    </div>
  );
}

export default SuperFacilitatorNav;
