import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { ComponentPropType } from 'lib/propTypes';
import { RESET_LOGIN } from 'store/user/actions';
import { Button, Spinner } from 'react-bootstrap';
import { REDUX_STATUS } from 'lib/constants';
import Confirmation from './Modal/Confirmation';

// Roughly mimics a Formik/form field, but for displaying data only
function LoginField({ label, value, loginId, className }) {
  const { id: workspace } = useWorkspace();
  const { status } = useSelector((state) => state.user.resetLogin);
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleLoginReset = useCallback(() => {
    dispatch(RESET_LOGIN.request({ workspace, id: loginId }));
  }, [loginId, workspace, dispatch]);

  return (
    <>
      <div className={`mb-2 ${className ?? ''}`}>
        <span className="d-block pb-1 text-accent-grey" role="presentation">{label}</span>
        <Button variant={null} className="btn-text" disabled={!loginId || status === REDUX_STATUS.PENDING} onClick={() => setConfirmOpen(true)}>
          {value}
        </Button>
        {status === REDUX_STATUS.PENDING && (<Spinner animation="border" size="sm" className="ms-2" />)}
      </div>

      <Confirmation
        open={confirmOpen}
        close={() => setConfirmOpen(false)}
        text="Do you want to reset this participant’s Public Display name? It will also notify the participant."
        confirmText="Yes, reset"
        confirmAction={handleLoginReset}
        cancelText="No"
      />
    </>
  );
}

LoginField.defaultProps = {
  value: null,
  loginId: null,
  className: null,
};

LoginField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, ComponentPropType]),
  loginId: PropTypes.number,
  className: PropTypes.string,
};

export default LoginField;
