import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATUS } from 'lib/constants';
import usePrevious from 'lib/hooks/usePrevious';
import { LOAD_COURSE_GROUPS } from 'store/courses/actions';
import { selectPublishedCourses } from 'store/courses/selectors';
import Table from 'react-bootstrap/Table';
import { CourseTableSkeleton } from 'components/curriculum/CourseTable';
import CourseGroupsModal from 'components/curriculum/CourseTable/CourseGroupsModal';
import CourseRow from './CourseRow';

function CourseTable() {
  const status = useSelector((state) => state.courses.status);
  const courses = useSelector((state) => selectPublishedCourses(state));
  const dispatch = useDispatch();
  const [showGroupsCourseId, setShowGroupsCourseId] = useState();
  const [showGroups, setShowGroups] = useState(false);
  const prevShowGroupsCourseId = usePrevious(showGroupsCourseId);
  const loading = status === REDUX_STATUS.PENDING;

  useEffect(() => {
    if (showGroupsCourseId && showGroupsCourseId !== prevShowGroupsCourseId) {
      dispatch(LOAD_COURSE_GROUPS.request(showGroupsCourseId));
      setShowGroups(true);
    }
  }, [showGroupsCourseId, prevShowGroupsCourseId, dispatch]);

  useEffect(() => {
    if (!showGroups) {
      setShowGroupsCourseId(null);
    }
  }, [showGroups]);

  return (
    <>
      {loading && <CourseTableSkeleton />}

      {(!loading && courses.length > 0) && (
        <Table bordered hover responsive className="sn-table bg-white">
          <thead>
            <tr>
              <th style={{ width: '35%' }}>Course Name</th>
              <th>Date created</th>
              <th>Number of groups</th>
              <th>Status</th>
              <th>Last updated</th>
              <th>{}</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course) => <CourseRow key={course?.id} course={course} setShowGroupsCourseId={setShowGroupsCourseId} />)}
          </tbody>
        </Table>
      )}

      <CourseGroupsModal courseId={showGroupsCourseId} showGroups={showGroups} setShowGroups={setShowGroups} />
    </>
  );
}

export default CourseTable;
