import React from 'react';
import BaseSkeleton from 'components/shared/Skeleton';

function Skeleton() {
  return (
    <div className="row">
      <div className="col-lg-10 col-xl-9 ps-4">
        <BaseSkeleton classes="title w-25 mb-2" />
        <BaseSkeleton classes="text strong w-50" />

        <div className="border rounded bg-light p-3">
          <BaseSkeleton classes="title w-25" />
          <div>
            <BaseSkeleton classes="text w-100" />
            <br />
            <BaseSkeleton classes="text w-100" />
            <br />
            <BaseSkeleton classes="text w-50" />
          </div>
          <div>
            <BaseSkeleton classes="text w-100" />
            <br />
            <BaseSkeleton classes="text w-100" />
            <br />
            <BaseSkeleton classes="text w-50" />
          </div>
        </div>
      </div>

      <div className="col-lg-2 col-xl-3 ps-4">
        <div className="text-end">
          <BaseSkeleton classes="button" />
        </div>

        <div className="border rounded p-2">
          <BaseSkeleton classes="text strong w-100" />
        </div>

        <div className="border rounded p-2 mt-2">
          <BaseSkeleton classes="text strong w-100" />
        </div>
      </div>
    </div>
  );
}

export default Skeleton;
