import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { SHORT_DATE } from 'lib/dateFormats';
import { CoursePropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_COURSE_GLOSSARY_PATH, FACILITATOR_COURSE_PHRASEBOOK_PATH, FACILITATOR_COURSE_SEARCH_PATH, FACILITATOR_COURSE_PATH, FACILITATOR_COURSE_SELF_CARE_PATH } from 'lib/routerPaths';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import SanyasIcon from 'components/shared/SanyasIcon';

function CourseDraftCard({ course }) {
  const courseType = useSelector((state) => selectCourseTypeById(state, course?.courseTypeId));

  if (!course) return null;

  return (
    <div className="col-xxl-4 col-md-6 mb-2">
      <div className="card h-100 d-flex flex-column shadow-sm bg-white rounded">
        <div className="d-flex flex-column h-100 px-3 py-2">
          <div className="d-flex align-items-center">
            <h4 className="text-truncate">
              <Link
                className="link-dark text-decoration-none"
                to={buildRoutePath(FACILITATOR_COURSE_PATH, { courseId: course.id })}
              >
                <SanyasIcon name="eye" className="me-1" />
                {course.description}
              </Link>
            </h4>
          </div>

          <div className="mt-auto text-end">
            <p className="mb-0 pt-1 text-small">
              Created
              {' '}
              {!!course?.createdAt && format(parseISO(course.createdAt), SHORT_DATE)}
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-around mt-auto border-top px-3 py-2 text-small">
          <Link
            className="link-secondary link-offset-2-hover link-underline-opacity-0 link-underline-opacity-50-hover"
            to={buildRoutePath(FACILITATOR_COURSE_SEARCH_PATH, { courseId: course?.id })}
          >
            Search
          </Link>

          <Link
            className="link-secondary link-offset-2-hover link-underline-opacity-0 link-underline-opacity-50-hover"
            to={buildRoutePath(FACILITATOR_COURSE_GLOSSARY_PATH, { courseId: course?.id })}
          >
            Glossary
          </Link>
          <Link
            className="link-secondary link-offset-2-hover link-underline-opacity-0 link-underline-opacity-50-hover"
            to={buildRoutePath(FACILITATOR_COURSE_PHRASEBOOK_PATH, { courseId: course?.id })}
          >
            Phrasebook
          </Link>
          {courseType?.showSelfCareTab && (
            <Link
              className="link-secondary link-offset-2-hover link-underline-opacity-0 link-underline-opacity-50-hover"
              to={buildRoutePath(FACILITATOR_COURSE_SELF_CARE_PATH, { courseId: course?.id })}
            >
              Self Care
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

CourseDraftCard.propTypes = {
  course: CoursePropType.isRequired,
};

export default CourseDraftCard;
