import React from 'react';

function Upload() {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.06506 6.8723C4.1057 6.91304 4.15397 6.94537 4.20713 6.96742C4.26028 6.98948 4.31726 7.00083 4.37481 7.00083C4.43235 7.00083 4.48933 6.98948 4.54248 6.96742C4.59564 6.94537 4.64392 6.91304 4.68456 6.8723L6.99981 4.55617L9.31506 6.8723C9.35573 6.91298 9.40402 6.94524 9.45717 6.96726C9.51032 6.98927 9.56728 7.0006 9.62481 7.0006C9.68233 7.0006 9.73929 6.98927 9.79244 6.96726C9.84559 6.94524 9.89388 6.91298 9.93456 6.8723C9.97523 6.83162 10.0075 6.78333 10.0295 6.73018C10.0515 6.67704 10.0629 6.62008 10.0629 6.56255C10.0629 6.50502 10.0515 6.44806 10.0295 6.39491C10.0075 6.34177 9.97523 6.29348 9.93456 6.2528L7.30956 3.6278C7.26892 3.58706 7.22064 3.55473 7.16748 3.53268C7.11433 3.51062 7.05735 3.49927 6.99981 3.49927C6.94226 3.49927 6.88528 3.51062 6.83213 3.53268C6.77897 3.55473 6.7307 3.58706 6.69006 3.6278L4.06506 6.2528C4.02431 6.29344 3.99199 6.34172 3.96993 6.39487C3.94788 6.44802 3.93652 6.505 3.93652 6.56255C3.93652 6.6201 3.94788 6.67708 3.96993 6.73023C3.99199 6.78338 4.02431 6.83166 4.06506 6.8723V6.8723Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7 13.125C7.11603 13.125 7.22731 13.0789 7.30936 12.9969C7.39141 12.9148 7.4375 12.8035 7.4375 12.6875V4.8125C7.4375 4.69647 7.39141 4.58519 7.30936 4.50314C7.22731 4.42109 7.11603 4.375 7 4.375C6.88397 4.375 6.77269 4.42109 6.69064 4.50314C6.60859 4.58519 6.5625 4.69647 6.5625 4.8125V12.6875C6.5625 12.8035 6.60859 12.9148 6.69064 12.9969C6.77269 13.0789 6.88397 13.125 7 13.125Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.3125 2.1875C1.3125 1.8394 1.45078 1.50556 1.69692 1.25942C1.94306 1.01328 2.2769 0.875 2.625 0.875H11.375C11.7231 0.875 12.0569 1.01328 12.3031 1.25942C12.5492 1.50556 12.6875 1.8394 12.6875 2.1875V9.1875C12.6875 9.5356 12.5492 9.86944 12.3031 10.1156C12.0569 10.3617 11.7231 10.5 11.375 10.5H10.0625C9.94647 10.5 9.83519 10.4539 9.75314 10.3719C9.67109 10.2898 9.625 10.1785 9.625 10.0625C9.625 9.94647 9.67109 9.83519 9.75314 9.75314C9.83519 9.67109 9.94647 9.625 10.0625 9.625H11.375C11.491 9.625 11.6023 9.57891 11.6844 9.49686C11.7664 9.41481 11.8125 9.30353 11.8125 9.1875V2.1875C11.8125 2.07147 11.7664 1.96019 11.6844 1.87814C11.6023 1.79609 11.491 1.75 11.375 1.75H2.625C2.50897 1.75 2.39769 1.79609 2.31564 1.87814C2.23359 1.96019 2.1875 2.07147 2.1875 2.1875V9.1875C2.1875 9.30353 2.23359 9.41481 2.31564 9.49686C2.39769 9.57891 2.50897 9.625 2.625 9.625H3.9375C4.05353 9.625 4.16481 9.67109 4.24686 9.75314C4.32891 9.83519 4.375 9.94647 4.375 10.0625C4.375 10.1785 4.32891 10.2898 4.24686 10.3719C4.16481 10.4539 4.05353 10.5 3.9375 10.5H2.625C2.2769 10.5 1.94306 10.3617 1.69692 10.1156C1.45078 9.86944 1.3125 9.5356 1.3125 9.1875V2.1875Z" fill="currentColor" />
    </svg>
  );
}

export default Upload;
