import React from 'react';
import PropTypes from 'prop-types';
import PlatformClosureListItem from '../PlatformClosureListItem';

function PlatformClosureList({ platformEvents, editEvents }) {
  if (platformEvents.length === 0) {
    return (
      <div className="alert alert-info">
        There are no platform closures scheduled.
      </div>
    );
  }

  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>Closing From</th>
          <th>Reopening On</th>
          <th>Requested By</th>
          <th>Requested On</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {platformEvents.map((event) => (
          <PlatformClosureListItem key={event.id} event={event} editEvents={editEvents} />
        ))}
      </tbody>
    </table>
  );
}

PlatformClosureList.defaultProps = {
  platformEvents: [],
  editEvents: false,
};

PlatformClosureList.propTypes = {
  platformEvents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    closePlatformFrom: PropTypes.string,
    closePlatformUntil: PropTypes.string,
    message: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    registrar: PropTypes.string,
  })),
  editEvents: PropTypes.bool,
};

export default PlatformClosureList;
