import React from 'react';
import { LocalePropType, QuestionPropType } from 'lib/propTypes';
import { getLocaleSuffix } from 'lib/utils';

function Heading({ question, locale }) {
  return (
    <div id={`${question.id}`} className="question">
      <div className="d-flex align-items-center mb-1">
        <h4 className="mb-0">{question[`body${getLocaleSuffix(locale)}`]}</h4>

        <span className="ms-auto font-weight-semibold" style={{ fontSize: '0.875em' }}>
          Question heading
        </span>
      </div>

      <p style={{ whiteSpace: 'pre-wrap' }}>{question[`explanation${getLocaleSuffix(locale)}`]}</p>
    </div>
  );
}

Heading.defaultProps = {
  locale: 'en',
};

Heading.propTypes = {
  question: QuestionPropType.isRequired,
  locale: LocalePropType,
};

export default Heading;
