import React from 'react';
import PropTypes from 'prop-types';
import { LEGACY_APP_DOMAIN } from 'config';
import { UserPropType } from 'lib/propTypes';

function ParticipantInfo({ fromUser, toUser }) {
  const participant = fromUser.isFacilitator ? toUser : fromUser;
  return (
    <div>
      <div className="row row-space-top">
        <div className="col-md-12">
          { !participant.groupMembershipId && <p className="well">There is either no participant in this conversation, or the participant is not registered in a group</p> }
          {
            participant.groupMembershipId && (
            <p className="well">
              Conversation with
              {' '}
              <a href={`${LEGACY_APP_DOMAIN}/group_memberships/${participant.groupMembershipId}`} target="_blank" rel="noreferrer">
                { participant.firstName }
                {' '}
                {participant.lastName}
              </a>
              {' '}
              in group
              {' '}
              <a href={`${LEGACY_APP_DOMAIN}/groups/${participant.groupId}`} target="_blank" rel="noreferrer">{participant.groupName}</a>
              .
            </p>
            )
          }
        </div>
      </div>
    </div>
  );
}

ParticipantInfo.propTypes = PropTypes.shape({
  fromUser: UserPropType,
  toUser: UserPropType,
}).isRequired;

export default ParticipantInfo;
