import React from 'react';

function Envelope() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_246_3937)">
        <path fillRule="evenodd" clipRule="evenodd" d="M21 4.5H3C2.60218 4.5 2.22064 4.65804 1.93934 4.93934C1.65804 5.22064 1.5 5.60218 1.5 6V18C1.5 18.3978 1.65804 18.7794 1.93934 19.0607C2.22064 19.342 2.60218 19.5 3 19.5H21C21.3978 19.5 21.7794 19.342 22.0607 19.0607C22.342 18.7794 22.5 18.3978 22.5 18V6C22.5 5.60218 22.342 5.22064 22.0607 4.93934C21.7794 4.65804 21.3978 4.5 21 4.5ZM3 3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.316071 4.44129 0 5.20435 0 6L0 18C0 18.7956 0.316071 19.5587 0.87868 20.1213C1.44129 20.6839 2.20435 21 3 21H21C21.7956 21 22.5587 20.6839 23.1213 20.1213C23.6839 19.5587 24 18.7956 24 18V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H3Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.106441 6.3645C0.157053 6.27998 0.22382 6.20626 0.302929 6.14755C0.382037 6.08884 0.471938 6.04629 0.567494 6.02234C0.66305 5.99838 0.76239 5.99348 0.85984 6.00792C0.95729 6.02236 1.05094 6.05586 1.13544 6.1065L11.9999 12.6255L22.8644 6.1065C22.9489 6.05588 23.0426 6.02239 23.14 6.00796C23.2375 5.99353 23.3368 5.99843 23.4324 6.02239C23.5279 6.04635 23.6178 6.08889 23.6969 6.14759C23.776 6.20629 23.8428 6.28 23.8934 6.3645C23.9441 6.44901 23.9775 6.54266 23.992 6.6401C24.0064 6.73755 24.0015 6.83688 23.9776 6.93244C23.9536 7.02799 23.9111 7.11788 23.8524 7.19699C23.7937 7.2761 23.7199 7.34288 23.6354 7.3935L11.9999 14.3745L0.364441 7.395C0.279922 7.34439 0.206202 7.27762 0.147492 7.19851C0.0887822 7.11941 0.0462327 7.02951 0.0222739 6.93395C-0.00168479 6.83839 -0.00658326 6.73905 0.00785836 6.6416C0.0223 6.54415 0.0557987 6.4505 0.106441 6.366V6.3645Z" fill="currentColor" />
        <path d="M10.1279 13.398L10.7759 13.02L10.0199 11.724L9.37195 12.102L10.1279 13.398ZM1.12795 18.648L10.1279 13.398L9.37195 12.102L0.371948 17.352L1.12795 18.648ZM13.8719 13.398L13.2239 13.02L13.9799 11.724L14.6279 12.102L13.8719 13.398ZM22.872 18.648L13.8719 13.398L14.6279 12.102L23.6279 17.352L22.872 18.648Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_3937">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Envelope;
