import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GET_ASSIGNMENTS, SEND_LETTERS, CANCEL_LETTER } from 'store/actions';
import { Button, Alert } from 'react-bootstrap';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import AssignmentList from 'components/facilitator/IndigenousWelcomeLetters/AssignmentList';

function IndigenousWelcomeLettersPage() {
  const { id: userId } = useSelector((state) => state.currentUser || {});
  const { allIds: assignmentIds } = useSelector((state) => state.indigenousWelcomeLetters.assignments);
  const showSuccessMessage = useSelector((state) => state.indigenousWelcomeLetters.showSuccessMessage);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState({ participantName: '', assignmentId: '' });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GET_ASSIGNMENTS.request({ userId }));
  }, [userId, dispatch]);

  const handleSendLetters = () => {
    dispatch(SEND_LETTERS.request({ assignmentIds }));
  };

  const hideCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleCancelLetter = () => {
    hideCancelModal();
    dispatch(CANCEL_LETTER.request({ assignmentId: selectedParticipant.assignmentId }));
  };

  return (
    <FacilitatorTemplate className="indigenous-welcome-letter-outbox-page">
      <div className="header flush">
        <h1 className="mb-0">Indigenous Welcome Letter Outbox</h1>
      </div>

      <div className="flush my-4 px-4">
        <div className="row mb-3">
          <div className="col-xl-10">
            <p>Below is a list of all Indigenous participants who could be sent a letter from the platform on your behalf.</p>
            <p>
              Please review the list and identify any participants you know personally by clicking
              {' '}
              <strong className="fw-semibold">Conflict</strong>
              {'. '}
              The platform will send these participants into the Outbox of another eligible Indigenous facilitator on the platform.
            </p>
            <p>
              Once you are sure you do not personally know any of the participants in the list click
              {' '}
              <strong className="fw-semibold">Send Welcome Email to All Participants in the List</strong>
              .
            </p>
          </div>
        </div>

        { showSuccessMessage && <Alert>The welcome letters have been queued and they will be sent out next morning.</Alert> }

        <Button
          variant="outline-primary"
          onClick={handleSendLetters}
        >
          <i className="fas fa-paper-plane me-1" />
          Send Welcome Email To All Participants in the List
        </Button>

        <AssignmentList setShowCancelModal={setShowCancelModal} setSelectedParticipant={setSelectedParticipant} />

        <DefaultModal
          isOpen={showCancelModal}
          onClose={hideCancelModal}
          header="Cancel Indigenous Welcome Letter"
          footerComponent={(
            <>
              <Button variant="outline-secondary" onClick={hideCancelModal}>No</Button>
              <Button
                variant="outline-danger"
                onClick={handleCancelLetter}
              >
                Yes
              </Button>
            </>
        )}
        >
          <p>
            {`You are about to cancel the IWL for ${selectedParticipant.participantName}.`}
          </p>
          <p>
            They will not be re-assigned to another facilitator, and will not be able to receive an IWL for this group
            membership. In addition, the IWL facilitator leads will be notified of the cancellation.
          </p>
          <p>
            {`If needed, you can send a modified IWL message for ${selectedParticipant.participantName} from your message tab.`}
          </p>
          <p>Are you sure you wish to proceed?</p>
        </DefaultModal>
      </div>
    </FacilitatorTemplate>
  );
}

export default IndigenousWelcomeLettersPage;
