import React from 'react';

function BoxArrowUpRight() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21.6927 2.71255C21.6549 2.62143 21.5995 2.53867 21.5297 2.469C21.46 2.39915 21.3772 2.34374 21.2861 2.30593C21.195 2.26812 21.0973 2.24866 20.9987 2.24866C20.9837 2.24866 20.9687 2.24911 20.9538 2.25H13.4987C13.2997 2.25 13.109 2.32902 12.9683 2.46967C12.8277 2.61032 12.7487 2.80109 12.7487 3C12.7487 3.19891 12.8277 3.38968 12.9683 3.53033C13.109 3.67098 13.2997 3.75 13.4987 3.75H19.1867L8.46766 14.469C8.32683 14.6098 8.24771 14.8008 8.24771 15C8.24771 15.1992 8.32683 15.3902 8.46766 15.531C8.60849 15.6718 8.7995 15.7509 8.99866 15.7509C9.19782 15.7509 9.38883 15.6718 9.52966 15.531L20.2487 4.812V10.5C20.2487 10.6989 20.3277 10.8897 20.4683 11.0303C20.609 11.171 20.7997 11.25 20.9987 11.25C21.1976 11.25 21.3883 11.171 21.529 11.0303C21.6696 10.8897 21.7487 10.6989 21.7487 10.5V3.04486C21.7496 3.02994 21.75 3.01498 21.75 3C21.75 2.90135 21.7305 2.80367 21.6927 2.71255ZM2.90767 21.091C2.48571 20.669 2.24866 20.0967 2.24866 19.5V7.5C2.24866 6.90326 2.48571 6.33097 2.90767 5.90901C3.32962 5.48705 3.90192 5.25 4.49866 5.25H10.4987C10.6976 5.25 10.8883 5.32902 11.029 5.46967C11.1696 5.61032 11.2487 5.80109 11.2487 6C11.2487 6.19891 11.1696 6.38968 11.029 6.53033C10.8883 6.67098 10.6976 6.75 10.4987 6.75H4.49866C4.29975 6.75 4.10898 6.82902 3.96833 6.96967C3.82767 7.11032 3.74866 7.30109 3.74866 7.5V19.5C3.74866 19.6989 3.82767 19.8897 3.96833 20.0303C4.10898 20.171 4.29975 20.25 4.49866 20.25H16.4987C16.6976 20.25 16.8883 20.171 17.029 20.0303C17.1696 19.8897 17.2487 19.6989 17.2487 19.5V13.5C17.2487 13.3011 17.3277 13.1103 17.4683 12.9697C17.609 12.829 17.7997 12.75 17.9987 12.75C18.1976 12.75 18.3883 12.829 18.529 12.9697C18.6696 13.1103 18.7487 13.3011 18.7487 13.5V19.5C18.7487 20.0967 18.5116 20.669 18.0896 21.091C17.6677 21.5129 17.0954 21.75 16.4987 21.75H4.49866C3.90192 21.75 3.32962 21.5129 2.90767 21.091Z" fill="currentColor" />
    </svg>
  );
}

export default BoxArrowUpRight;
