import React from 'react';

function List() {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.8125 12.9375C2.8125 12.7883 2.87176 12.6452 2.97725 12.5398C3.08274 12.4343 3.22582 12.375 3.375 12.375H14.625C14.7742 12.375 14.9173 12.4343 15.0227 12.5398C15.1282 12.6452 15.1875 12.7883 15.1875 12.9375C15.1875 13.0867 15.1282 13.2298 15.0227 13.3352C14.9173 13.4407 14.7742 13.5 14.625 13.5H3.375C3.22582 13.5 3.08274 13.4407 2.97725 13.3352C2.87176 13.2298 2.8125 13.0867 2.8125 12.9375ZM2.8125 8.4375C2.8125 8.28832 2.87176 8.14524 2.97725 8.03975C3.08274 7.93426 3.22582 7.875 3.375 7.875H14.625C14.7742 7.875 14.9173 7.93426 15.0227 8.03975C15.1282 8.14524 15.1875 8.28832 15.1875 8.4375C15.1875 8.58668 15.1282 8.72976 15.0227 8.83525C14.9173 8.94074 14.7742 9 14.625 9H3.375C3.22582 9 3.08274 8.94074 2.97725 8.83525C2.87176 8.72976 2.8125 8.58668 2.8125 8.4375ZM2.8125 3.9375C2.8125 3.78832 2.87176 3.64524 2.97725 3.53975C3.08274 3.43426 3.22582 3.375 3.375 3.375H14.625C14.7742 3.375 14.9173 3.43426 15.0227 3.53975C15.1282 3.64524 15.1875 3.78832 15.1875 3.9375C15.1875 4.08668 15.1282 4.22976 15.0227 4.33525C14.9173 4.44074 14.7742 4.5 14.625 4.5H3.375C3.22582 4.5 3.08274 4.44074 2.97725 4.33525C2.87176 4.22976 2.8125 4.08668 2.8125 3.9375Z" fill="currentColor" />
    </svg>
  );
}

export default List;
