import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buildRoutePath } from 'lib/routerHelpers';
import { ARTICLE_EDIT_PATH, FACILITATOR_ARTICLE_PATH } from 'lib/routerPaths';
import { RESET_COURSE_ARTICLE } from 'store/courses/actions';
import { Draggable } from '@hello-pangea/dnd';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import SanyasIcon from 'components/shared/SanyasIcon';
import { useWorkspace } from 'lib/hooks/useWorkspace';

const ARTICLE_PATHS = {
  facilitator: FACILITATOR_ARTICLE_PATH,
  curriculum: ARTICLE_EDIT_PATH,
};

/* eslint-disable react/jsx-props-no-spreading */

function ArticleNavItem({ articleId, index }) {
  const dispatch = useDispatch();
  const { course, currentArticle } = useCourseShowContext();
  const article = useSelector((state) => state.courses.articles?.byId[articleId]);
  const { id: workspaceId } = useWorkspace();

  const handleClick = useCallback(() => {
    dispatch(RESET_COURSE_ARTICLE.action());
  }, [dispatch]);

  if (!article?.id) return null;

  return (
    <Draggable draggableId={`${article.id}`} index={index} isDragDisabled={course?.isLocked}>
      {(provided, snapshot) => (
        <li
          className={`list-group-item d-flex align-items-center border-0 ps-1 ${article.id === currentArticle?.id || snapshot.isDragging ? 'list-group-item-active' : ''}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {!course?.isLocked && (
            <span
              className={`cursor-grab p-1 outline-visible ${snapshot.isDragging ? 'visible' : ''}`}
              {...provided.dragHandleProps}
            >
              <SanyasIcon name="gripVertical" style={{ width: '10px' }} />
              <span className="sr-only">Drag to reorder</span>
            </span>
          )}

          <Link
            to={buildRoutePath(ARTICLE_PATHS[workspaceId], { courseId: course?.id, articleId })}
            className="article w-100 border-0 ps-2 text-start font-weight-semibold text-decoration-none"
            style={{ backgroundColor: 'transparent' }}
            onClick={handleClick}
          >
            <span className="accordion-position">{`${index + 1}. `}</span>
            {article.nameEn}
            <br />
            <small className="text-muted fw-normal">{article.summaryEn}</small>
          </Link>
        </li>
      )}
    </Draggable>
  );
}

ArticleNavItem.propTypes = {
  articleId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default ArticleNavItem;
