import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Tab, Tabs } from 'react-bootstrap';
import Shortcodes from './Shortcodes';
import TextareaField from '../FormFields/TextareaField';
import TagAutocomplete from '../TagAutocomplete';

function MessageBody({ label, placeholder, placeholderFr }) {
  const { handleBlur, setFieldValue } = useFormikContext();
  const validTags = useSelector((state) => state.emailTemplates.tags.data);
  const bodyRef = useRef();
  const bodyFrRef = useRef();
  const [key, setKey] = useState('en');
  const [activeRef, setActiveRef] = useState(bodyRef);
  const [selection, setSelection] = useState([0, 0]);

  const handleTabSelect = useCallback((k) => {
    setKey(k);
    setActiveRef(k === 'en' ? bodyRef : bodyFrRef);
  }, []);

  const saveSelection = useCallback(() => {
    setSelection([activeRef.current.selectionStart, activeRef.current.selectionEnd]);
  }, [activeRef]);

  const addTemplateTag = useCallback((tag) => {
    activeRef.current.setRangeText(`{{\u00A0${tag}\u00A0}} `, selection[0], selection[1], 'end');
    activeRef.current.focus();

    setFieldValue(activeRef.current.name, activeRef.current.value);
    saveSelection();
  }, [activeRef, selection, saveSelection, setFieldValue]);

  const handleMessageBodyBlur = useCallback((e) => {
    saveSelection();
    setFieldValue(activeRef.current.name, activeRef.current.value);
    handleBlur(e);
  }, [activeRef, saveSelection, setFieldValue, handleBlur]);
  return (
    <>
      <Shortcodes addTemplateTag={addTemplateTag} />

      <label className="form-label">{label}</label>
      <div className="position-relative">
        <div className="border rounded">
          <Tabs
            variant="underline-thin"
            className="nav-tabs nav-uppercase"
            activeKey={key}
            onSelect={handleTabSelect}
          >
            <Tab eventKey="en" title="English">
              <TextareaField
                name="body"
                placeholder={placeholder}
                className="mt-1"
                style={{ border: 'none' }}
                inputRef={bodyRef}
                hideLabel
                inline
                onFieldBlur={handleMessageBodyBlur}
              />
            </Tab>
            <Tab eventKey="fr" title="French">
              <TextareaField
                name="bodyFr"
                placeholder={placeholderFr || placeholder}
                className="mt-1"
                style={{ border: 'none' }}
                inputRef={bodyFrRef}
                hideLabel
                inline
                onFieldBlur={handleMessageBodyBlur}
              />
            </Tab>
          </Tabs>
        </div>

        <TagAutocomplete
          tags={validTags}
          innerRef={activeRef}
          placeholder={/\{\{[ \u00A0](\w*)[ \u00A0]?[}]?[}]?/g}
          replacement={['{{\u00A0', '\u00A0}} ']}
          tagUsage="email template tag"
        />
      </div>
    </>
  );
}

MessageBody.defaultProps = {
  placeholderFr: null,
};

MessageBody.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  placeholderFr: PropTypes.string,
};

export default MessageBody;
