import { takeLatest, put, select } from 'redux-saga/effects';
import { fetchGet, fetchPut, handleSimpleFetchError } from 'lib/apiHelpers';
import { GET_ASSIGNMENTS, MARK_CONFLICT, SEND_LETTERS, CANCEL_LETTER } from 'store/indigenousWelcomeLetters/actions';

function* getAssignments({ payload }) {
  const { indigenousWelcomeLetters } = yield select();
  if (indigenousWelcomeLetters.assignments.facilitatorId !== payload.userId) {
    try {
      const { data } = yield fetchGet('/api/facilitator/indigenous_welcome_letter_assignments', {});
      yield put(GET_ASSIGNMENTS.success({ ...data, userId: payload.userId }));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_ASSIGNMENTS);
    }
  } else {
    yield put(GET_ASSIGNMENTS.success({}, { cached: true }));
  }
}

function* markConflict({ payload: { assignmentId } }) {
  try {
    yield fetchPut(`/api/facilitator/indigenous_welcome_letter_assignments/${assignmentId}/mark_conflict`);
    yield put(MARK_CONFLICT.success({ assignmentId }));
  } catch (err) {
    console.warn(err); // eslint-disable-line no-console
    yield handleSimpleFetchError(err, MARK_CONFLICT);
  }
}

function* cancelIndigenousWelcomeLetter({ payload: { assignmentId } }) {
  try {
    yield fetchPut(`/api/facilitator/indigenous_welcome_letter_assignments/${assignmentId}/cancel_letter`);
    yield put(CANCEL_LETTER.success({ assignmentId }));
  } catch (err) {
    console.warn(err); // eslint-disable-line no-console
    yield handleSimpleFetchError(err, CANCEL_LETTER);
  }
}

function* sendLetters({ payload: { assignmentIds } }) {
  try {
    const { data } = yield fetchPut(
      '/api/facilitator/indigenous_welcome_letter_assignments/send_indigenous_welcome_letters',
      { assignmentIds },
    );
    yield put(SEND_LETTERS.success(data));
  } catch (err) {
    console.warn(err); // eslint-disable-line no-console
    yield handleSimpleFetchError(err, SEND_LETTERS);
  }
}

export default function* sagas() {
  yield takeLatest(GET_ASSIGNMENTS.REQUEST, getAssignments);
  yield takeLatest(MARK_CONFLICT.REQUEST, markConflict);
  yield takeLatest(CANCEL_LETTER.REQUEST, cancelIndigenousWelcomeLetter);
  yield takeLatest(SEND_LETTERS.REQUEST, sendLetters);
}
