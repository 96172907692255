import React from 'react';
import PropTypes from 'prop-types';

function Badge({ title, variant, pill, reverse, className }) {
  return (
    <span
      className={`badge badge-${variant} ${reverse ? 'badge-reverse' : ''} ${pill ? 'rounded-pill' : ''} ${className || ''}`}
    >
      { title }
    </span>
  );
}

Badge.defaultProps = {
  variant: 'green',
  pill: false,
  reverse: false,
  className: null,
};

Badge.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  variant: PropTypes.oneOf(['green', 'red', 'blue', 'grey', 'orange']),
  pill: PropTypes.bool,
  reverse: PropTypes.bool,
  className: PropTypes.string,
};

export default Badge;
