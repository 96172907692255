import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import SanyasIcon from 'components/shared/SanyasIcon';
import { MediaResourcePropType, RefPropType } from 'lib/propTypes';

function MediaResource({ resource, setShowDeleteModal, setSelectedResource, innerRef }) {
  const handleClick = useCallback(() => {
    setShowDeleteModal(true);
    setSelectedResource(resource);
  }, [resource, setShowDeleteModal, setSelectedResource]);

  return (
    <div ref={innerRef} className="resource px-1">
      <div className="d-flex p-1">
        <SanyasIcon name={resource.type} className="flex-shrink-0 me-2" />
        <a className="text-decoration-none text-break" href={resource.path} target={resource.type === 'link' ? '_blank' : null} rel="noreferrer">{resource.name}</a>
        <button className="btn btn-plain align-self-start ms-auto p-0" onClick={handleClick} type="button">
          <SanyasIcon name="trashCan" />
        </button>
      </div>
    </div>
  );
}

MediaResource.defaultProps = {
  innerRef: null,
};

MediaResource.propTypes = {
  resource: MediaResourcePropType.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
  setSelectedResource: PropTypes.func.isRequired,
  innerRef: RefPropType,
};

export default MediaResource;
