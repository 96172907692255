import { createSelector } from 'reselect';
import _sortBy from 'lodash/sortBy';

export const selectGroup = (state) => state.groupShow.group;
export const selectSubgroups = (state) => state.groupShow.subGroups;
export const selectGroupMemberships = (state) => state.groupShow.groupMemberships;
export const selectDiscussionsSections = (state) => state.groupShow.discussionsSections;

export const selectGroupSubgroups = createSelector(
  selectGroup,
  selectSubgroups,
  (group, subGroups) => subGroups.filter((sg) => sg.parentGroupId === group.id),
);

export const selectAssignedGroupMemberships = createSelector(
  selectGroupMemberships,
  (gms) => _sortBy(
    Object.values(gms).filter((gm) => !gm.isFacilitator && !!gm.subGroupId),
    ['firstName', 'lastName'],
  ),
);

export const selectUnassignedGroupMemberships = createSelector(
  selectGroupMemberships,
  (gms) => _sortBy(
    Object.values(gms).filter((gm) => !gm.isFacilitator && !gm.subGroupId),
    ['firstName', 'lastName'],
  ),
);

export const selectUnassignedActiveGroupMemberships = createSelector(
  selectGroupMemberships,
  (gms) => _sortBy(
    Object.values(gms).filter((gm) => !gm.isFacilitator && !gm.subGroupId && gm.completion !== 'removed'),
    ['firstName', 'lastName'],
  ),
);

export const selectDiscussionsSectionsValues = createSelector(
  selectDiscussionsSections,
  (sections) => Object.values(sections),
);

export const selectSubgroupsValues = createSelector(
  selectSubgroups,
  (subGroups) => Object.values(subGroups),
);
