import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CREATE_FACILITATOR_REPORT } from 'store/actions';
import Button from 'react-bootstrap/Button';
import SelectField from 'components/shared/FormFields/SelectField';
import DatePickerField from 'components/shared/FormFields/DatePickerField';
import Skeleton from './FormSkeleton';

const FormSchema = Yup.object().shape({
  reportInterval: Yup.string().required('Required'),
  reportType: Yup.string().required('Required'),
  startDate: Yup.date().required('Required'),
  endDate: Yup.date().required('Required'),
});

function RequestForm() {
  const { formLoading, formData } = useSelector((state) => state.facilitatorReport);
  const dispatch = useDispatch();

  const handleSubmit = (values, { resetForm }) => {
    dispatch(CREATE_FACILITATOR_REPORT.request(values));
    resetForm();
  };

  if (formLoading) return <Skeleton />;

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
      initialValues={{
        reportInterval: 'daily',
        reportType: 'stats',
        facilitatorIds: [],
        courseTypes: [],
        journalEntries: [],
        startDate: '',
        endDate: '',
      }}
    >
      {({ resetForm }) => (
        <Form>
          <div className="row">
            <div className="col-md-8 col-xl-6">
              <SelectField
                name="reportType"
                label="Report Type"
                options={[
                  { value: 'stats', label: 'Statistics' },
                  { value: 'breakdown', label: 'Breakdown' },
                  { value: 'participant', label: 'Participant Stats' },
                ]}
                required
              />

              <SelectField
                name="reportInterval"
                label="Report Interval"
                options={[
                  { value: 'daily', label: 'Daily' },
                  { value: 'weekly', label: 'Weekly' },
                  { value: 'monthly', label: 'Monthly' },
                ]}
                required
              />

              <div className="row">
                <div className="col-md-6">
                  <DatePickerField
                    name="startDate"
                    label="Report Timeframe:"
                    placeholder="Pick start date"
                    infoText={(
                      <p className="mb-0 text-start">
                        Results for before
                        {' '}
                        <strong>March 15, 2021</strong>
                        {' '}
                        do not take into account facilitators providing coverage for each other.
                        Responses before this date will be credited to the facilitator being covered.
                      </p>
                    )}
                    dateFormat="m/d/Y"
                    required
                  />
                </div>

                <div className="col-md-6">
                  <DatePickerField
                    name="endDate"
                    label="to:"
                    placeholder="Pick end date"
                    dateFormat="m/d/Y"
                    required
                  />
                </div>
              </div>

              <SelectField
                name="facilitatorIds"
                label="Facilitators"
                placeholder="Filter by facilitator (optional)"
                options={formData.facilitators}
                multiple
              />

              <SelectField
                name="courseTypes"
                label="Courses"
                placeholder="Filter by course (optional)"
                options={formData.courseTypes}
                multiple
              />

              <SelectField
                name="journalEntries"
                label="Journal Entries"
                placeholder="Filter by journal (optional)"
                options={formData.journalOptions}
                multiple
              />
            </div>
          </div>

          <div className="my-3">
            <Button variant="primary" type="submit" className="me-2">
              Request this report
            </Button>

            <Button variant={null} className="btn-plain" onClick={resetForm}>
              Reset
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default RequestForm;
