import React from 'react';

function Toggles() {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.9375 1.125H5.0625C4.31658 1.125 3.60121 1.42132 3.07376 1.94876C2.54632 2.47621 2.25 3.19158 2.25 3.9375C2.25 4.68342 2.54632 5.39879 3.07376 5.92624C3.60121 6.45368 4.31658 6.75 5.0625 6.75H12.9375C13.6834 6.75 14.3988 6.45368 14.9262 5.92624C15.4537 5.39879 15.75 4.68342 15.75 3.9375C15.75 3.19158 15.4537 2.47621 14.9262 1.94876C14.3988 1.42132 13.6834 1.125 12.9375 1.125V1.125ZM5.0625 0C4.01821 0 3.01669 0.414843 2.27827 1.15327C1.53984 1.89169 1.125 2.89321 1.125 3.9375C1.125 4.98179 1.53984 5.98331 2.27827 6.72173C3.01669 7.46016 4.01821 7.875 5.0625 7.875H12.9375C13.9818 7.875 14.9833 7.46016 15.7217 6.72173C16.4602 5.98331 16.875 4.98179 16.875 3.9375C16.875 2.89321 16.4602 1.89169 15.7217 1.15327C14.9833 0.414843 13.9818 0 12.9375 0H5.0625ZM5.0625 10.125C4.01821 10.125 3.01669 10.5398 2.27827 11.2783C1.53984 12.0167 1.125 13.0182 1.125 14.0625C1.125 15.1068 1.53984 16.1083 2.27827 16.8467C3.01669 17.5852 4.01821 18 5.0625 18H12.9375C13.9818 18 14.9833 17.5852 15.7217 16.8467C16.4602 16.1083 16.875 15.1068 16.875 14.0625C16.875 13.0182 16.4602 12.0167 15.7217 11.2783C14.9833 10.5398 13.9818 10.125 12.9375 10.125H5.0625ZM12.9375 16.875C13.6834 16.875 14.3988 16.5787 14.9262 16.0512C15.4537 15.5238 15.75 14.8084 15.75 14.0625C15.75 13.3166 15.4537 12.6012 14.9262 12.0738C14.3988 11.5463 13.6834 11.25 12.9375 11.25C12.1916 11.25 11.4762 11.5463 10.9488 12.0738C10.4213 12.6012 10.125 13.3166 10.125 14.0625C10.125 14.8084 10.4213 15.5238 10.9488 16.0512C11.4762 16.5787 12.1916 16.875 12.9375 16.875Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 3.9375C9 4.98179 8.58516 5.98331 7.84673 6.72173C7.10831 7.46016 6.10679 7.875 5.0625 7.875C4.01821 7.875 3.01669 7.46016 2.27827 6.72173C1.53984 5.98331 1.125 4.98179 1.125 3.9375C1.125 2.89321 1.53984 1.89169 2.27827 1.15327C3.01669 0.414843 4.01821 0 5.0625 0C6.10679 0 7.10831 0.414843 7.84673 1.15327C8.58516 1.89169 9 2.89321 9 3.9375V3.9375ZM5.0625 6.75C5.80842 6.75 6.52379 6.45368 7.05124 5.92624C7.57868 5.39879 7.875 4.68342 7.875 3.9375C7.875 3.19158 7.57868 2.47621 7.05124 1.94876C6.52379 1.42132 5.80842 1.125 5.0625 1.125C4.31658 1.125 3.60121 1.42132 3.07376 1.94876C2.54632 2.47621 2.25 3.19158 2.25 3.9375C2.25 4.68342 2.54632 5.39879 3.07376 5.92624C3.60121 6.45368 4.31658 6.75 5.0625 6.75V6.75Z" fill="currentColor" />
    </svg>
  );
}

export default Toggles;
