import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { REDUX_STATUS } from 'lib/constants';
import { buildRoutePath } from 'lib/routerHelpers';
import { TASK_EDIT_PATH } from 'lib/routerPaths';
import { COPY_TASK_FORM_DATA, COPY_TASK_UPDATE_STATUS, COPY_TASK } from 'store/courses/actions';
import { Button, Spinner } from 'react-bootstrap';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import SelectField from 'components/shared/FormFields/SelectField';

const formatCourseLabel = ({ label, orgShortName, courseType }, { context }) => {
  const shouldAddCourseType = !label.toLowerCase().includes(courseType.toLowerCase());

  if (context === 'value') {
    const displayCourseType = shouldAddCourseType ? ` ${courseType}` : '';
    return (
      <>
        <span>{label}</span>
        <span className="ms-2 text-small">{`${orgShortName}${displayCourseType}`}</span>
      </>
    );
  }

  if (context === 'menu') {
    return (
      <>
        <span>{label}</span>
        {shouldAddCourseType && <span className="ms-2 text-small">{courseType}</span>}
      </>
    );
  }
  return label;
};

const FormSchema = Yup.object().shape({
  course_id: Yup.number().required('Required'),
  module_id: Yup.number().required('Required'),
});

function CopyTaskForm({ currentTaskId, showCopyModal, header, setShowCopyModal }) {
  const { copyCoursesFormData, copyModulesFormData } = useSelector((state) => state.courses);
  const { data: copiedTask, status } = useSelector((state) => state.courses.task);
  const dispatch = useDispatch();
  const [selectedCourseId, setSelectedCourse] = useState(copyCoursesFormData && copyCoursesFormData[0].value);
  const moduleFormData = useMemo(() => selectedCourseId && copyModulesFormData[selectedCourseId], [copyModulesFormData, selectedCourseId]);

  const initialValues = {
    course_id: undefined,
    module_id: undefined,
    source_task_id: currentTaskId,
  };

  useEffect(() => {
    dispatch(COPY_TASK_FORM_DATA.request());
  }, [dispatch]);

  const handleSave = useCallback((values) => {
    dispatch(COPY_TASK.request(values));
  }, [dispatch]);

  const handleClose = useCallback((resetForm) => {
    setShowCopyModal(false);
    if (typeof resetForm === 'function') resetForm();
    dispatch(COPY_TASK_UPDATE_STATUS.action(REDUX_STATUS.IDLE));
  }, [setShowCopyModal, dispatch]);

  const courseFieldChange = (val, setFieldValue) => {
    setFieldValue('module_id', '');
    setSelectedCourse(val);
  };

  const isLoading = showCopyModal && status === REDUX_STATUS.PENDING;
  const successMessage = (
    <div>
      <div>
        The task was copied successfully
      </div>
      <div className="mt-3">
        Go to
        {' '}
        <Link
          onClick={handleClose}
          to={buildRoutePath(TASK_EDIT_PATH, { courseId: copiedTask?.courseId, taskId: copiedTask?.id })}
        >
          {`${copiedTask?.courseName}/ ${copiedTask?.sectionName}/ ${copiedTask?.name}`}
        </Link>
      </div>
    </div>
  );

  return (
    <Formik
      onSubmit={handleSave}
      validationSchema={FormSchema}
      enableReinitialize
      initialValues={initialValues}
    >
      {({ resetForm, handleSubmit, setFieldValue }) => {
        const handleModalClose = () => handleClose(resetForm);
        const handleModalConfirm = status === REDUX_STATUS.SUCCESS ? handleModalClose : handleSubmit;
        return (
          <DefaultModal
            size="md"
            isOpen={showCopyModal}
            header={header}
            onClose={() => handleClose(resetForm)}
            footerComponent={(
              <Button className="d-flex align-items-center" disabled={isLoading} value="primary" onClick={handleModalConfirm} type="submit">
                {status === REDUX_STATUS.SUCCESS ? 'Close' : 'Copy'}
                {isLoading ? <Spinner size="sm" className="ms-1" animation="border" role="status" /> : undefined}
              </Button>
            )}
          >
            <Form key={currentTaskId} className="form-horizontal col-12">
              {status === REDUX_STATUS.SUCCESS ? successMessage
                : (
                  <>
                    <p>Which course would you like to copy this task to?</p>
                    <SelectField
                      name="course_id"
                      label="Course"
                      options={copyCoursesFormData}
                      includeBlank="Choose a course"
                      disabled={isLoading}
                      required
                      onFieldChange={(val) => courseFieldChange(val, setFieldValue)}
                      formatOptionLabel={formatCourseLabel}
                      filterOptions={{
                        stringify: (option) => `${option.label} ${option.value} ${option.data?.courseType}`,
                      }}
                    />
                    <SelectField
                      name="module_id"
                      label="Module"
                      options={moduleFormData}
                      includeBlank="Choose a module"
                      disabled={isLoading}
                      required
                    />
                  </>
                )}
            </Form>
          </DefaultModal>
        );
      } }
    </Formik>
  );
}

CopyTaskForm.defaultProps = {
  header: '',
};

CopyTaskForm.propTypes = {
  currentTaskId: PropTypes.number.isRequired,
  showCopyModal: PropTypes.bool.isRequired,
  header: PropTypes.string,
  setShowCopyModal: PropTypes.func.isRequired,
};

export default CopyTaskForm;
