import React from 'react';
import { useSelector } from 'react-redux';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import CsvDownloadButton from 'components/reporting/EmailTransmission/CsvDownloadButton';
import Results from 'components/reporting/EmailTransmission/Results';
import SearchForm from 'components/reporting/EmailTransmission/SearchForm';

function EmailTransmissionReport() {
  const totalResults = useSelector((state) => state.emailTransmissionReport.totalResults);

  return (
    <RegistrarTemplate id="email-transmission-reports-page">
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Reports', href: '/registrar/reports' },
                { label: 'Email Transmission' },
              ]}
            />

            <h1 className="mb-0">Email Transmission Report</h1>
          </div>
        </div>
      </div>

      <div className="flush px-4 my-4">

        <p className="lead">
          Get a report on the transmission status of emails that were sent by the platform.
          This data comes from Postmark.
        </p>

        <div className="mt-3">
          <SearchForm />
        </div>

        {totalResults > 0 && (
          <div className="my-3 text-center">
            <CsvDownloadButton />
          </div>
        )}

        <div className="mt-3">
          <Results />
        </div>
      </div>
    </RegistrarTemplate>
  );
}

export default EmailTransmissionReport;
