import React from 'react';
import { Prompt } from 'react-router-dom';

function ConfirmLeave() {
  return (
    <Prompt
      message={JSON.stringify({
        text: 'The bulk import is not complete. Are you sure you want to leave this page?',
        title: 'Confirm',
        confirm: 'Yes, leave',
        cancel: 'No, stay',
      })}
    />
  );
}

export default ConfirmLeave;
