import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { add, formatDistanceToNow, parseISO } from 'date-fns';
import ReportStateBadge from '../ReportStateBadge';

function ReportsListItem({ itemId }) {
  const { reportId, state: reportState, filters, createdAt, requestedByUserName, durationInSeconds } = useSelector((state) => state.responsesReport.reports.byId[itemId]);
  const downloadDropdownId = `download-dropdown-report-${itemId}`;
  const reportReady = reportState === 'completed';

  return (
    <tr>
      <td className="text-secondary font-monospace text-nowrap">
        {reportId}
      </td>

      <td>
        <ReportStateBadge reportState={reportState} />
      </td>

      <td>
        <ul className="list-inline">
          <li>
            <small className="text-secondary pe-2">Keywords:</small>
            <span className="font-monospace">{filters.keywords.join(', ')}</span>
          </li>

          <li>
            <small className="text-secondary">Kind:</small>
            <span className="font-monospace ps-2">{filters.kind}</span>
          </li>

          <li>
            <small className="text-secondary">Limit:</small>
            <span className="font-monospace ps-2">{filters.limit}</span>
          </li>
        </ul>
      </td>

      <td>
        <small className="text-secondary">
          {formatDistanceToNow(parseISO(createdAt), { addSuffix: true })}
          <br />
          {` by ${requestedByUserName}`}
        </small>
      </td>

      <td>
        {reportReady && (
          <small className="text-secondary">{formatDistanceToNow(add(new Date(), { seconds: durationInSeconds }))}</small>
        )}

        {!reportReady && <span className="text-muted">&hellip;</span>}
      </td>

      <td className="text-end">
        {reportReady ? (
          <a className="btn btn-sm btn-success text-nowrap" href={`/api/registrar/reports/responses/${itemId}`} download>
            <i className="fa fa-file-csv me-2" />
            Download as CSV
          </a>
        ) : (
          <button disabled className="btn btn-sm btn-light text-nowrap" type="button" id={downloadDropdownId}>
            <i className="fa fa-file-csv me-2" />
            Download as CSV
          </button>
        )}
      </td>
    </tr>
  );
}

ReportsListItem.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default ReportsListItem;
