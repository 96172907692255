import { takeLatest, put, select, call, delay } from 'redux-saga/effects';
import { fetchDelete, fetchGet, fetchPost, fetchPut, handleSimpleFetchError } from 'lib/apiHelpers';
import { LOAD_EMAIL_TEMPLATES, LOAD_EMAIL_TEMPLATE_TYPES, LOAD_EMAIL_TEMPLATE_TAGS, DELETE_EMAIL_TEMPLATE, CREATE_EMAIL_TEMPLATE, UPDATE_EMAIL_TEMPLATE, RESET_EMAIL_TEMPLATE, REMOVE_EMAIL_TEMPLATE } from 'store/emailTemplates/actions';
import { SET_NOTIFICATION } from 'store/flashNotifications/actions';
import { selectEmailTemplateTypeById } from 'store/emailTemplates/selectors';

const getTemplateName = (emailTemplateType) => (emailTemplateType?.displayName ? `${emailTemplateType?.displayName} template` : 'Template');

export default function* sagas() {
  yield takeLatest(LOAD_EMAIL_TEMPLATES.REQUEST, function* loadEmailTemplates({ payload: { entityId, context, contextType } }) {
    const { emailTemplates } = yield select();
    if (!emailTemplates.templates.data?.length || emailTemplates.data?.[0]?.emailTemplateContextId !== entityId || emailTemplates.data?.[0]?.emailTemplateContextType !== contextType) {
      try {
        const { data } = yield fetchGet(`/api/registrar/${context}/${entityId}/email_templates`, {});
        yield put(LOAD_EMAIL_TEMPLATES.success(data, { cached: false }));

        yield put(LOAD_EMAIL_TEMPLATE_TYPES.request());
        yield put(LOAD_EMAIL_TEMPLATE_TAGS.request());
      } catch (err) {
        yield handleSimpleFetchError(err, LOAD_EMAIL_TEMPLATES);
      }
    } else {
      yield put(LOAD_EMAIL_TEMPLATES.success({}, { cached: true }));
    }
  });

  yield takeLatest(LOAD_EMAIL_TEMPLATE_TYPES.REQUEST, function* loadEmailTemplateTypes() {
    const state = yield select();
    if (!state.emailTemplates.types.data?.length) {
      try {
        const { data } = yield fetchGet('/api/shared/email_templates/types', {});
        yield put(LOAD_EMAIL_TEMPLATE_TYPES.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, LOAD_EMAIL_TEMPLATE_TYPES);
      }
    } else {
      yield put(LOAD_EMAIL_TEMPLATE_TYPES.success({}, { cached: true }));
    }
  });

  yield takeLatest(LOAD_EMAIL_TEMPLATE_TAGS.REQUEST, function* loadEmailTemplateTags() {
    const state = yield select();
    if (!state.emailTemplates.tags.data?.length) {
      try {
        const { data } = yield fetchGet('/api/shared/email_templates/tags', {});
        yield put(LOAD_EMAIL_TEMPLATE_TAGS.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, LOAD_EMAIL_TEMPLATE_TAGS);
      }
    } else {
      yield put(LOAD_EMAIL_TEMPLATE_TAGS.success({}, { cached: true }));
    }
  });

  yield takeLatest(CREATE_EMAIL_TEMPLATE.REQUEST, function* createEmailTemplate({ payload, meta }) {
    const emailTemplateType = yield select((state) => selectEmailTemplateTypeById(state, payload.emailTemplateTypeId));
    const templateName = getTemplateName(emailTemplateType);
    const { id, context, ...serverPayload } = payload;
    try {
      const { data } = yield fetchPost(`/api/registrar/${context}/${payload.emailTemplateContextId}/email_templates`, serverPayload);
      yield delay(800);
      yield put(CREATE_EMAIL_TEMPLATE.success(data));
      yield put(SET_NOTIFICATION.action({
        message: `${templateName} created successfully.`,
        type: 'success',
      }));
      yield put(RESET_EMAIL_TEMPLATE.action());
    } catch (err) {
      yield handleSimpleFetchError(err, CREATE_EMAIL_TEMPLATE);
    } finally {
      if (meta.formikActions) {
        yield call(meta.formikActions.setSubmitting, false);
      }
    }
  });

  yield takeLatest(UPDATE_EMAIL_TEMPLATE.REQUEST, function* updateEmailTemplate({ payload, meta }) {
    const emailTemplateType = yield select((state) => selectEmailTemplateTypeById(state, payload.emailTemplateTypeId));
    const templateName = getTemplateName(emailTemplateType);
    const { context, ...serverPayload } = payload;
    try {
      const { data } = yield fetchPut(`/api/registrar/${context}/${payload.emailTemplateContextId}/email_templates/${payload.id}`, serverPayload);
      yield delay(800);
      yield put(UPDATE_EMAIL_TEMPLATE.success(data));
      yield put(SET_NOTIFICATION.action({
        message: `${templateName} updated successfully.`,
        type: 'success',
      }));
      yield put(RESET_EMAIL_TEMPLATE.action());
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_EMAIL_TEMPLATE);
    } finally {
      if (meta.formikActions) {
        yield call(meta.formikActions.setSubmitting, false);
      }
    }
  });

  yield takeLatest(DELETE_EMAIL_TEMPLATE.REQUEST, function* deleteEmailTemplate({ payload: { entityId, id, context, typeId } }) {
    const emailTemplateType = yield select((state) => selectEmailTemplateTypeById(state, typeId));
    const templateName = getTemplateName(emailTemplateType);
    try {
      yield fetchDelete(`/api/registrar/${context}/${entityId}/email_templates/${id}`);
      yield delay(400);
      yield put(DELETE_EMAIL_TEMPLATE.success(id));
      yield put(SET_NOTIFICATION.action({
        message: `${templateName} deleted successfully.`,
        type: 'success',
      }));
      yield put(REMOVE_EMAIL_TEMPLATE.action(id));
    } catch (err) {
      yield handleSimpleFetchError(err, DELETE_EMAIL_TEMPLATE, { id });
    }
  });
}
