import React from 'react';
import PropTypes from 'prop-types';
import { encodeGetURLParamsForRails } from 'lib/utils';

function CsvDownloadButton({ filters }) {
  const csvDownloadUrl = `/api/registrar/payment_transactions.csv?${encodeGetURLParamsForRails(filters)}`;

  return (
    <a
      className="btn btn-success"
      href={csvDownloadUrl}
      download
    >
      <i className="fa fa-file-csv me-2" />
      Download as CSV
    </a>
  );
}

CsvDownloadButton.defaultProps = {
  filters: {
    page: 1,
    withFromDate: null,
    withPayableType: null,
    withProvider: null,
    withStatus: null,
    withState: null,
    withToDate: null,
    withTransactionId: null,
  },
};

CsvDownloadButton.propTypes = {
  filters: PropTypes.shape({
    page: PropTypes.number,
    withFromDate: PropTypes.string,
    withPayableType: PropTypes.string,
    withProvider: PropTypes.string,
    withStatus: PropTypes.string,
    withState: PropTypes.string,
    withToDate: PropTypes.string,
    withTransactionId: PropTypes.string,
  }),
};

export default CsvDownloadButton;
