import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATUS } from 'lib/constants';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_GROUP_LIVE_FEED_PATH, FACILITATOR_GROUP_REPORTS_PATH, FACILITATOR_GROUP_SHOW_PATH, FACILITATOR_GROUP_DISCUSSIONS_PATH, FACILITATOR_GROUP_JOURNALS_PATH, FACILITATOR_GROUP_SETTINGS_PATH } from 'lib/routerPaths';
import { GET_GROUP } from 'store/actions';
import Spinner from 'components/shared/Spinner';
import Header from 'components/groups/Header/Header';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import TabbedNav from 'components/shared/Navigation/TabbedNav';
import GroupDiscussionsTabPage from 'pages/groups/GroupDiscussionsTabPage';
import GroupJournalsTabPage from 'pages/groups/GroupJournalsTabPage';
import GroupLiveFeedTabPage from 'pages/groups/GroupLiveFeedTabPage';
import GroupParticipantsTabPage from 'pages/facilitator/groups/GroupParticipantsTabPage';
import GroupReportsTabPage from 'pages/groups/GroupReportsTabPage';
import GroupSettingsTabPage from 'pages/facilitator/groups/GroupSettingsTabPage';

function GroupShowPage() {
  const { id } = useParams();
  const { group, status } = useSelector((state) => state.groupShow);
  const dispatch = useDispatch();
  const groupShowPath = buildRoutePath(FACILITATOR_GROUP_SHOW_PATH, { id });

  useEffect(() => {
    if (!group || group.id !== Number(id)) {
      dispatch(GET_GROUP.request({ id, workspace: 'facilitator' }));
    }
  }, [group, id, dispatch]);

  return (
    <FacilitatorTemplate>
      <div className="header flush pb-0">
        <Header />

        <TabbedNav
          variant="underline"
          navItems={[
            {
              title: 'Participants',
              href: groupShowPath,
              match: {
                path: FACILITATOR_GROUP_SHOW_PATH,
                exact: true,
              },
            },
            {
              title: 'Reports',
              href: `${groupShowPath}/reports`,
              match: { path: FACILITATOR_GROUP_REPORTS_PATH },
            },
            {
              title: 'Live feed',
              href: `${groupShowPath}/live_feed`,
              match: { path: FACILITATOR_GROUP_LIVE_FEED_PATH },
            },
            {
              title: 'Discussions',
              href: `${groupShowPath}/discussions`,
              match: { path: FACILITATOR_GROUP_DISCUSSIONS_PATH },
            },
            {
              title: 'Journals',
              href: `${groupShowPath}/journals`,
              match: { path: FACILITATOR_GROUP_JOURNALS_PATH },
            },
            {
              title: 'Settings',
              href: `${groupShowPath}/settings`,
              match: { path: FACILITATOR_GROUP_SETTINGS_PATH },
            },
          ]}
        />
      </div>

      <div className="flush my-4 px-4">
        {(!group || status === REDUX_STATUS.PENDING) && (
          <Spinner message="Loading group..." />
        )}

        {(group && [REDUX_STATUS.IDLE, REDUX_STATUS.SUCCESS].includes(status)) && (
          <Switch>
            <Route path={FACILITATOR_GROUP_REPORTS_PATH}>
              <GroupReportsTabPage group={group} />
            </Route>
            <Route path={FACILITATOR_GROUP_LIVE_FEED_PATH}>
              <GroupLiveFeedTabPage group={group} />
            </Route>
            <Route path={FACILITATOR_GROUP_DISCUSSIONS_PATH}>
              <GroupDiscussionsTabPage group={group} />
            </Route>
            <Route path={FACILITATOR_GROUP_JOURNALS_PATH}>
              <GroupJournalsTabPage group={group} />
            </Route>
            <Route path={FACILITATOR_GROUP_SETTINGS_PATH}>
              <GroupSettingsTabPage group={group} />
            </Route>

            <Route path="*">
              <GroupParticipantsTabPage group={group} />
            </Route>
          </Switch>
        )}
      </div>
    </FacilitatorTemplate>
  );
}

export default GroupShowPage;
