import React from 'react';
import PropTypes from 'prop-types';

function ActiveUserItem({ user }) {
  let message = ` (${user.email}) is already `;
  if (user.errorCode === 'active_in_other') {
    message += 'an active member of '; // Group link will be added below
  } else if (user.errorCode === 'already_in_group') {
    message += 'registered in this group.';
  }

  return (
    <li key={user.key}>
      <a
        href={`/registrar/people/${user.userId}/group_memberships/${user.groupMembershipId}`}
        className="fw-semibold text-decoration-none"
        target="_blank"
        rel="noreferrer"
      >
        {`${user.firstName} ${user.lastName}`}
      </a>
      {message}
      {user.groupName && (
        <a
          href={`/registrar/groups/${user.groupId}`}
          className="fw-semibold text-decoration-none"
          target="_blank"
          rel="noreferrer"
        >
          {user.groupName}
        </a>
      )}
    </li>
  );
}

ActiveUserItem.propTypes = {
  user: PropTypes.shape({
    key: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    groupMembershipId: PropTypes.number,
    groupId: PropTypes.number,
    groupName: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    errorCode: PropTypes.string,
  }).isRequired,
};

export default ActiveUserItem;
