import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { QUESTION_TASK_TYPES, TASK_TYPES } from 'lib/constants';
import { buildRoutePath } from 'lib/routerHelpers';
import { TASK_EDIT_PATH, CURRICULUM_COURSE_SHOW_PATH, TASK_NEW_PATH } from 'lib/routerPaths';
import { LOAD_TASK_QUESTIONS, RESET_TASK_QUESTIONS, LOAD_TASK_PROACTIVE, RESET_TASK_PROACTIVE } from 'store/courses/actions';
import NewTaskForm from 'components/curriculum/CurriculumEditNav/NewTaskForm';
import EditTaskForm from 'components/curriculum/CurriculumEditNav/EditTaskForm';
import { useCourseShowContext } from '../CourseShowPage/context';

function TaskEditPage() {
  const { taskId } = useParams();
  const { course, currentSection, setCurrentSection, currentTask, setCurrentTask, setActiveKey } = useCourseShowContext();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { data: courses, sections, tasks } = useSelector((state) => state.courses);
  const task = tasks?.[taskId];
  const firstSectionId = courses[course.id]?.sectionIds[0];
  const firstTaskId = sections?.[firstSectionId]?.taskIds[0];
  const section = sections?.[task?.sectionId] || currentSection || sections?.[firstSectionId];
  const { data: questions } = useSelector((state) => state.courses.questions);
  const { data: proactiveTemplate } = useSelector((state) => state.courses.proactiveTemplate);

  useEffect(() => {
    if (section && section?.id !== currentSection?.id) {
      setCurrentSection(section);
      setActiveKey(section?.id);
    }
  }, [sections, section, currentSection?.id, firstSectionId, setCurrentSection, setActiveKey]);

  useEffect(() => {
    if (task && task?.id !== currentTask?.id) {
      setCurrentTask(task);
      if (QUESTION_TASK_TYPES.includes(task?.type)) {
        dispatch(LOAD_TASK_QUESTIONS.request({ id: task.id, workspace: 'curriculum' }));
      } else if (task?.type && questions?.length) {
        dispatch(RESET_TASK_QUESTIONS.action());
      }

      if (task?.type === TASK_TYPES.POD_DISCUSSION) {
        dispatch(LOAD_TASK_PROACTIVE.request({ id: task.id, workspace: 'curriculum' }));
      } else if (task?.type && !_isEmpty(proactiveTemplate)) {
        dispatch(RESET_TASK_PROACTIVE.action());
      }
    }
  }, [task, currentTask, setCurrentTask, questions?.length, proactiveTemplate, dispatch]);

  useEffect(() => {
    // Course Show or New Task w/o Section
    if ((path === CURRICULUM_COURSE_SHOW_PATH || (!taskId && !currentSection?.id)) && course?.id && firstTaskId) {
      history.replace(buildRoutePath(TASK_EDIT_PATH, { courseId: course?.id, taskId: firstTaskId }));
    }
  }, [taskId, currentSection?.id, course?.id, firstTaskId, history, path]);

  return (
    <>
      {(!taskId && path === TASK_NEW_PATH) && (
        <NewTaskForm />
      )}

      {taskId && (
        <EditTaskForm />
      )}
    </>
  );
}

export default TaskEditPage;
