import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-bootstrap';
import AddFileForm from './AddFileForm';

// This Popover wraps the AddFileForm because the Popover needs
// consistent Hooks and the AddFileForm adds its own Hooks.

function AddFilePopover(props) {
  const { setAddingFile } = props.popper.state?.options || {};

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Popover id="popover-basic" {...props}>
      <Popover.Header className="d-flex align-items-center justify-content-between">
        <h4 className="m-0">Add file</h4>
        <button
          type="button"
          className="btn-primary btn-plain btn-sm"
          aria-label="Close"
          onClick={() => setAddingFile(false)}
        >
          Cancel
        </button>
      </Popover.Header>
      <Popover.Body>
        <AddFileForm setAddingFile={setAddingFile} />
      </Popover.Body>
    </Popover>
  );
}

AddFilePopover.defaultProps = {
  popper: {},
};

AddFilePopover.propTypes = {
  popper: PropTypes.shape({
    state: PropTypes.shape({
      options: PropTypes.shape({
        setAddingFile: PropTypes.func,
      }),
    }),
  }),
};

export default AddFilePopover;
