import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { add, format, parse } from 'date-fns';
import { COMPACT_DATE } from 'lib/dateFormats';
import { Button, Spinner } from 'react-bootstrap';
import InputField from 'components/shared/FormFields/InputField';
import SelectField from 'components/shared/FormFields/SelectField';
import DatePickerField from 'components/shared/FormFields/DatePickerField';

const FormSchema = Yup.object().shape({
  withFromDate: Yup.date().label('Received After'),
  withToDate: Yup.date().label('Received Before')
    // s/be handled by `minDate` on the field, but this is a backup
    .test('is-after', 'Must be after the "Received After" date', function isAfter(value) {
      const { withFromDate } = this.parent;
      return !withFromDate || !value || withFromDate < value;
    }),
});

function SearchForm({ searching, onSubmit }) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        withTransactionId: '',
        withFromDate: '',
        withToDate: '',
        withState: '',
        withStatus: '',
        withPayableType: '',
        withProvider: '',
      }}
      validationSchema={FormSchema}
    >
      {({ values, resetForm }) => (
        <Form>
          <div className="row">
            <div className="col-lg-10 col-xxl-8">

              <div className="row">
                <div className="col-md-4">
                  <InputField name="withTransactionId" label="Transaction ID" placeholder="Full or partial transaction identifier" />
                </div>

                <div className="col-md-4">
                  <DatePickerField name="withFromDate" label="Received After" placeholder="Received after this date" />
                </div>

                <div className="col-md-4">
                  <DatePickerField
                    name="withToDate"
                    label="Received Before"
                    placeholder="Received before this date"
                    minDate={values.withFromDate ? format(add(parse(values.withFromDate, COMPACT_DATE, new Date()), { days: 1 }), COMPACT_DATE) : ''}
                  />
                </div>

                <div className="col-md-4">
                  <SelectField
                    name="withState"
                    label="State"
                    options={[
                      { label: 'Successful', value: 'successful' },
                      { label: 'Refunded', value: 'refunded' },
                      { label: 'Pending', value: 'pending' },
                      { label: 'Failed', value: 'failed' },
                    ]}
                    includeBlank="- Any State -"
                  />
                </div>

                {/* TODO This data is very similar to withState, and might be confusing to users
                  <div className="col-md-4">
                    <SelectField
                      name="withStatus"
                      label="Status"
                      options={[
                        { label: 'Completed', value: 'Completed' },
                        { label: 'Refunded', value: 'Refunded' },
                        { label: 'Canceled/Reversal', value: 'Canceled_Reversal' },
                        { label: 'Declined', value: 'Declined' },
                      ]}
                      includeBlank="- Any Status -"
                    />
                  </div> */}

                <div className="col-md-4">
                  <SelectField
                    name="withPayableType"
                    label="Type"
                    options={[
                      { label: 'Training Payment', value: 'TrainingPayment' },
                      { label: 'Voucher Purchase', value: 'Voucher::Purchase' },
                    ]}
                    includeBlank="- Any Type -"
                  />
                </div>

                <div className="col-md-4">
                  <SelectField
                    name="withProvider"
                    label="Provider"
                    options={[
                      { label: 'Paypal', value: 'paypal' },
                      { label: 'Moneris', value: 'moneris' },
                      { label: 'Moneris Checkout', value: 'Moneris Checkout' },
                      { label: 'Global Payments', value: 'Global Payments' },
                    ]}
                    includeBlank="- Any Provider -"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <Button variant="primary" disabled={searching} type="submit">
                    Search Payment Transactions
                    {searching && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
                  </Button>

                  <Button variant="link" className="ms-2 btn-plain" disabled={searching} onClick={resetForm}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

SearchForm.defaultProps = {
  searching: false,
  onSubmit: () => {},
};

SearchForm.propTypes = {
  searching: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default SearchForm;
