import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FETCH_ALL_COVERAGE_REPORTS } from 'store/actions';
import Alert from 'react-bootstrap/Alert';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import SuperFacilitatorTemplate from 'components/templates/SuperFacilitatorTemplate';
import RequestForm from 'components/reporting/DiscussionBoardReports/RequestForm';
import DiscussionBoardReportsList from 'components/reporting/DiscussionBoardReports/DiscussionBoardReportsList';

function SuperFacilitatorDiscussionBoardReport() {
  const { isSuperFacilitator, isRegistrar, isSuperAdmin } = useSelector((state) => state.currentUser) || {};
  const dispatch = useDispatch();
  const hasAccess = isSuperFacilitator || isRegistrar || isSuperAdmin;

  useEffect(() => {
    dispatch(FETCH_ALL_COVERAGE_REPORTS.request());

    const id = setInterval(() => {
      dispatch(FETCH_ALL_COVERAGE_REPORTS.request());
    }, 15 * 1000);

    return () => clearInterval(id);
  }, [dispatch]);

  return (
    <SuperFacilitatorTemplate className="dashboard-page">
      <div className="header flush">
        <Breadcrumbs
          navItems={
          [
            { label: 'Reports', href: '/lead_facilitator/reports' },
            { label: 'Facilitator Discussion Board' },
          ]
        }
        />

        <h1 className="mb-0">Facilitator Discussion Board Report</h1>
      </div>

      <div className="flush px-4 my-4">
        <p className="lead">This report provides different analysis about board facilitation.</p>
        <p>
          Reports are generated in the background. Once a report is
          requested, this page can be closed and the report will continue
          generating. This page is auto-updating and will display reports as
          they are in-progress and eventually completed. Completed reports
          can be downloaded in comma-separated value (CSV) format.
        </p>

        {!hasAccess && (
          <Alert variant="danger" className="mb-3">
            <span className="fa fa-exclamation-triangle me-1" />
            You do not have access to this report
          </Alert>
        )}

        {hasAccess && (
          <>
            <RequestForm />
            <DiscussionBoardReportsList />
          </>
        )}
      </div>
    </SuperFacilitatorTemplate>
  );
}

export default SuperFacilitatorDiscussionBoardReport;
