import React from 'react';
import PropTypes from 'prop-types';

const PRIORITY_CLASSES = {
  high: 'priority3',
  medium: 'priority2',
  low: 'priority1',
};

function priorityClass(priority) {
  return `badge ${PRIORITY_CLASSES[priority]}`;
}

function NotificationBubble({ priority, href, count }) {
  return (
    <span className={priorityClass(priority)}>
      <a style={{ color: 'white' }} href={href} target="_blank" rel="noreferrer">{count}</a>
    </span>
  );
}

NotificationBubble.defaultProps = {
  priority: 'priority1',
  href: null,
  count: 0,
};

NotificationBubble.propTypes = {
  priority: PropTypes.oneOf(Object.keys(PRIORITY_CLASSES)),
  href: PropTypes.string,
  count: PropTypes.number,
};

export default NotificationBubble;
