import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

// NOTE: Used in <GroupParticipantsBulkImport>

function Dropzone({ children, name }) {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();
  const fieldName = name || 'file';

  const hasError = useMemo(() => errors[fieldName] && touched[fieldName], [errors, touched, fieldName]);

  const defaultBorder = '2px dashed var(--bs-secondary)';
  const successBorder = '2px dashed var(--bs-success)';
  const initialStyles = useMemo(() => ({
    backgroundColor: 'transparent',
    border: defaultBorder,
    borderRadius: '0.25rem',
    padding: '3px',
    pointerEvents: 'none',
    zIndex: 100,
  }), []);

  const [styles, setStyles] = useState(initialStyles);
  const [isDragging, setDragging] = useState(false);

  useEffect(() => {
    if (!isDragging) {
      if (values[fieldName] && !hasError && styles.border !== successBorder) {
        setStyles((s) => ({
          ...s,
          border: successBorder,
        }));
      }

      if ((!touched[fieldName] || hasError) && styles.border !== defaultBorder) {
        setStyles(initialStyles);
      }
    }
  }, [isDragging, values, fieldName, hasError, styles, touched, initialStyles]);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();

    if (!values[fieldName]) {
      setDragging(false);
      setStyles(initialStyles);
    }
  }, [values, fieldName, initialStyles]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();

    if (!values[fieldName]) {
      setDragging(true);
      setFieldTouched(fieldName, true, true);
      setStyles((s) => ({
        ...s,
        backgroundColor: 'rgba(255, 255, 255, 75%)',
        border: '5px dashed var(--bs-primary)',
        padding: 0,
      }));
    }
  }, [values, fieldName, setFieldTouched]);

  const handleDrop = useCallback((e) => {
    e.preventDefault();

    if (!values[fieldName]) {
      setDragging(false);
      const [file] = e.dataTransfer.files;

      setFieldValue(fieldName, file);
      setStyles(initialStyles);
    }
  }, [values, fieldName, setFieldValue, initialStyles]);

  return (
    <div
      className="position-relative d-flex justify-content-center mb-4 p-4 text-secondary"
      style={{ minHeight: '180px', zIndex: 20 }}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div style={styles} className="position-absolute top-0 start-0 bottom-0 end-0">
        <div className="position-absolute top-50 start-50 translate-middle text-center" style={{ zIndex: 10 }}>
          {isDragging && (
            <>
              <div className="mb-2"><i className="fas fa-upload fa-3x" /></div>
              Drop file here to upload
            </>
          )}

          {values[fieldName] && (
            <>
              <div className="mb-2"><i className="fas fa-upload fa-3x text-secondary" /></div>
              {values[fieldName].name}
              {hasError ? (
                <div className="invalid-feedback d-block">{errors[fieldName]}</div>
              ) : null}
            </>
          )}
        </div>
      </div>

      {!values[fieldName] && children}
    </div>
  );
}

Dropzone.defaultProps = {
  name: 'file',
};

Dropzone.propTypes = {
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

export default Dropzone;
