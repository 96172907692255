import React, { useCallback, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { TASK_TYPES } from 'lib/constants';
import { LocalePropType, QuestionPropType, RefPropType } from 'lib/propTypes';
import { getLocaleSuffix } from 'lib/utils';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import { v4 as uuidv4 } from 'uuid';
import { useFormikContext } from 'formik';
import { Draggable } from '@hello-pangea/dnd';
import Button from 'react-bootstrap/Button';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import SanyasIcon from 'components/shared/SanyasIcon';
import TextareaField from 'components/shared/FormFields/TextareaField';
import RichTextField from 'components/shared/FormFields/RichTextField';
import OptionsTable from './OptionsTable';

/* eslint-disable react/jsx-props-no-spreading */

function MultipleChoiceQuestion({ locale, index, question, arrayHelpers, isNew, setNewQuestionRef, setNewQuestionId, innerRef }) {
  const { currentTask } = useCourseShowContext();
  const { values, errors, setFieldValue } = useFormikContext();
  const { type: taskType } = values;
  const nodeRef = createRef(null);

  useEffect(() => {
    if (isNew) {
      setNewQuestionRef(nodeRef.current);
    }
  }, [nodeRef, setNewQuestionRef, isNew]);

  const handleAddOption = () => {
    const correct = taskType === TASK_TYPES.MULTIPLE_CHOICE_QUIZ && values.questions[index].options.length === 0;
    const newOption = { body: '', questionId: question.id, id: uuidv4(), correct };
    const newQuestion = values.questions[index];
    newQuestion.options.push(newOption);
    arrayHelpers.replace(index, newQuestion);
  };

  const handleDuplicate = useCallback(() => {
    const newId = uuidv4();
    const duplicateOptions = question.options?.map((option) => ({ ...option, id: uuidv4() }));
    arrayHelpers.insert(index + 1, { ...question, id: newId, options: duplicateOptions });
    setNewQuestionId(newId);
  }, [arrayHelpers, index, question, setNewQuestionId]);

  const handleRemove = useCallback(() => {
    setFieldValue(`questions[${index}]`, { ...values.questions[index], _destroy: true });
  }, [index, values.questions, setFieldValue]);

  if (question._destroy) return null; // eslint-disable-line no-underscore-dangle

  return (
    <Draggable key={question.id} draggableId={`${question.id}`} index={index} isDragDisabled={false}>
      {(provided, snapshot) => (
        <div id={`${question.id}`} ref={provided.innerRef} {...provided.draggableProps} className="question">
          <div ref={innerRef}>
            <div
              className={`cursor-grab p-1 outline-visible text-center ${snapshot.isDragging ? 'visible' : ''}`}
              {...provided.dragHandleProps}
            >
              {!currentTask?.isLocked && (
                <>
                  <SanyasIcon name="gripHorizontal" />
                  <span className="sr-only">Drag to reorder</span>
                </>
              )}
            </div>

            <div className="position-relative">
              <div className="position-absolute top-0 end-0 d-flex align-items-center" style={{ marginTop: '-2px' }}>
                <span className="font-weight-semibold" style={{ fontSize: '0.875em' }}>
                  {`${taskType === TASK_TYPES.SURVEY ? 'Survey' : 'Multiple choice'} question`}
                </span>

                {!currentTask?.isLocked && (
                  <>
                    <SimpleToolTip text="Duplicate" placement="top">
                      <Button variant="secondary" size="sm" className="btn-plain icon-background" onClick={handleDuplicate}>
                        <SanyasIcon className="thing" name="copy" />
                      </Button>
                    </SimpleToolTip>
                    <SimpleToolTip text="Delete" placement="top">
                      <Button variant="secondary" size="sm" className="btn-plain icon-background" onClick={handleRemove}>
                        <SanyasIcon name="trashCan" />
                      </Button>
                    </SimpleToolTip>
                  </>
                )}
              </div>
              <div ref={nodeRef}>
                <TextareaField
                  className="question-number"
                  placeholder="Question"
                  name={`questions[${index}].body${getLocaleSuffix(locale)}`}
                  label="Question"
                />
              </div>
            </div>

            <div className="drag-hide">
              <p className="mt-2 mb-0">Options</p>

              <OptionsTable hasError={!_isEmpty(errors?.questions?.[index])} index={index} locale={locale} options={values.questions[index]?.options} />

              {!currentTask?.isLocked && (
                <Button
                  variant="primary"
                  size="sm"
                  className="btn-plain"
                  title="Add question"
                  disabled={false}
                  onClick={handleAddOption}
                >
                  + Add Option
                </Button>
              )}

              {taskType === TASK_TYPES.MULTIPLE_CHOICE_QUIZ && (
                <RichTextField
                  id={String(question.id)}
                  name={`questions[${index}].explanation${getLocaleSuffix(locale)}`}
                  label="Correct answer explanation"
                  className="mt-4"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

MultipleChoiceQuestion.defaultProps = {
  arrayHelpers: {},
  locale: 'en',
};

MultipleChoiceQuestion.propTypes = {
  arrayHelpers: PropTypes.shape({
    insert: PropTypes.func,
    remove: PropTypes.func,
    replace: PropTypes.func,
  }),
  question: QuestionPropType.isRequired,
  locale: LocalePropType,
  index: PropTypes.number.isRequired,
  setNewQuestionId: PropTypes.func.isRequired,
  setNewQuestionRef: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  innerRef: RefPropType.isRequired,
};

export default MultipleChoiceQuestion;
