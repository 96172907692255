import React, { useCallback, useRef, useState } from 'react';
import TextareaField from 'components/shared/FormFields/TextareaField';
import TagAutocomplete from 'components/shared/TagAutocomplete';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useFormikContext } from 'formik';

function ProactiveTemplate() {
  const { setFieldValue } = useFormikContext();
  const tags = useSelector((state) => state.courses.proactiveTemplate.data.tags);
  const inputRef = useRef();
  const [selection, setSelection] = useState([0, 0]);
  const fieldName = 'proactiveTemplate.body';

  const saveSelection = useCallback(() => {
    setSelection([inputRef.current.selectionStart, inputRef.current.selectionEnd]);
  }, []);

  const addTemplateTag = useCallback((tag) => {
    inputRef.current.setRangeText(`{{\u00A0${tag}\u00A0}} `, selection[0], selection[1], 'end');
    inputRef.current.focus();

    setFieldValue(fieldName, inputRef.current.value);
    saveSelection();
  }, [selection, setFieldValue, saveSelection]);

  const handleTagClick = useCallback((e) => {
    addTemplateTag(e.currentTarget.dataset.code);
  }, [addTemplateTag]);

  const handleBodyBlur = useCallback(() => {
    setFieldValue(fieldName, inputRef.current.value);
    saveSelection();
  }, [setFieldValue, saveSelection]);

  return (
    <div className="mt-4">
      <h3 id="proactive_template">Proactive Template</h3>

      <p>
        Proactives are messages that the platform posts on behalf of facilitators before the group starts.
        A proactive message is the first message the participant will see when they get to this discussion board.
        Please edit the proactive template below. The particulars for each facilitator will be filled in with the
        template tags indicated by the curly braces.
      </p>

      <p>
        <span className="font-weight-semibold">Available tags</span>
        <br />

        {tags?.map((tag) => (
          <Button
            key={tag.code}
            variant="secondary"
            className="btn-plain me-2 px-0 py-1"
            data-code={tag.code}
            onClick={handleTagClick}
          >
            {`{{ ${tag.code} }}`}
          </Button>
        ))}
      </p>

      <div className="position-relative">
        <TextareaField name={fieldName} label="Body" inputRef={inputRef} onFieldBlur={handleBodyBlur} />

        <TagAutocomplete
          tags={tags}
          innerRef={inputRef}
          placeholder={/\{\{[ \u00A0](\w*)[ \u00A0]?[}]?[}]?/g}
          replacement={['{{\u00A0', '\u00A0}} ']}
          tagType="available tag"
          tagUsage={false}
        />
      </div>
    </div>
  );
}

export default ProactiveTemplate;
