import React from 'react';
import PropTypes from 'prop-types';
import { SIZES } from 'lib/constants';
import { ComponentPropType } from 'lib/propTypes';
import FieldWrapper from './FieldWrapper';
import DatePicker from './DatePicker';

// Additional props (inputProps)
// - dateFormat: PropTypes.string,
//   - 'Y-m-d' or 'm/d/Y', https://flatpickr.js.org/formatting/
//   - default: 'Y-m-d'
//   - maps to COMPACT_DATE or D_FLATPICKR from client/lib/dateFormats.js
//   - additional formats can be added to DATE_FORMATS in client/components/shared/FormFields/DatePicker.jsx
//     - ensure the format key uses Flatpickr formatting tokens and the value uses dateFns formatting tokens,
//       or a format from client/lib/dateFormats.js
// - minDate: PropTypes.string, // date must be in the same format specified by dateFormat
// - maxDate: PropTypes.string, // date must be in the same format specified by dateFormat

function DatePickerField(props) {
  const { id, name, label, helpText, infoText, size, hideLabel, required, initialValidation, showTooltip, className, onFieldChange, onFieldBlur, ...inputProps } = props;
  return (
    <FieldWrapper
      id={id}
      name={name}
      label={label}
      helpText={helpText}
      infoText={infoText}
      size={size}
      hideLabel={hideLabel}
      showTooltip={showTooltip}
      required={required}
      initialValidation={initialValidation}
      onFieldChange={onFieldChange}
      onFieldBlur={onFieldBlur}
      className={className}
    >
      <DatePicker
        name={name}
        size={size}
        required={required}
        {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
      />
    </FieldWrapper>
  );
}

DatePickerField.defaultProps = {
  id: null,
  label: null,
  helpText: null,
  infoText: null,
  size: SIZES.md,
  hideLabel: false,
  required: false,
  initialValidation: null,
  showTooltip: false,
  className: null,
  onFieldChange: null,
  onFieldBlur: null,
};

DatePickerField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  infoText: PropTypes.oneOfType([
    PropTypes.string,
    ComponentPropType,
  ]),
  size: PropTypes.oneOf(Object.values(SIZES)),
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
  initialValidation: PropTypes.string,
  showTooltip: PropTypes.bool,
  onFieldChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
  className: PropTypes.string,
};

export default DatePickerField;
