import React from 'react';
import Table from 'react-bootstrap/Table';
import BaseSkeleton from 'components/shared/Skeleton';

function TableSkeleton() {
  return (
    <Table bordered hover className="sn-table bg-white">
      <thead>
        <tr>
          <th style={{ width: '35%' }}>
            <BaseSkeleton classes="title width-100" />
          </th>
          <th>
            <BaseSkeleton classes="title width-100" />
          </th>
          <th style={{ width: '5rem' }}>{}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><BaseSkeleton classes="text width-100" /></td>
          <td><BaseSkeleton classes="text width-100" /></td>
          <td><BaseSkeleton classes="text width-100" /></td>
        </tr>
        <tr>
          <td><BaseSkeleton classes="text width-100" /></td>
          <td><BaseSkeleton classes="text width-100" /></td>
          <td><BaseSkeleton classes="text width-100" /></td>
        </tr>
      </tbody>
    </Table>
  );
}

export default TableSkeleton;
