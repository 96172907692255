import { GET_PARTICIPANT, GET_EVENT_LOGS } from 'store/actions';

const initialState = {
  loading: true,
  reSendingIndigenousWelcomeLetter: false,
  logEntries: null,
  emailConfirmations: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTICIPANT.SUCCESS:
      return {
        group: action.payload.group,
        groupParticipation: action.payload.groupMembership,
        loading: false,
      };
    case GET_PARTICIPANT.ERROR:
      return { ...state, loading: false };
    case GET_EVENT_LOGS.SUCCESS:
      return {
        ...state,
        logEntries: action.payload.logEntries,
        emailConfirmations: action.payload.emailConfirmations,
      };
    default:
      return state;
  }
};
