import React from 'react';
import PropTypes from 'prop-types';

function StateBadge({ text, backgroundClassName, iconClassName }) {
  return (
    <span className={`badge rounded-pill bg-${backgroundClassName}`}>
      {iconClassName && (<i className={`${iconClassName} me-1`} />)}
      {text}
    </span>
  );
}

StateBadge.defaultProps = {
  iconClassName: null,
};

StateBadge.propTypes = {
  text: PropTypes.string.isRequired,
  backgroundClassName: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
};

export default StateBadge;
