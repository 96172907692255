import _omit from 'lodash/omit';
import _difference from 'lodash/difference';

/**
 * Adds a single object to a state that has the structure:
 * {
 *   byId: {...},
 *   allIds: [],
 *   fetchedAt: ...,
 * }
 * @param {Object} state - the reducer state
 * @param {Object} obj - a data object
 * Returns a copy of the given state with the item added.
 */
export function addSingleObjectToState(state, obj) {
  return {
    byId: { ...state.byId, [obj.id]: obj },
    allIds: [...state.allIds, obj.id],
    fetchedAt: state.fetchedAt,
  };
}

export function addObjectsToState(state, objectsById, ids) {
  return {
    byId: { ...state.byId, ...objectsById },
    allIds: [...state.allIds, ...ids],
    fetchedAt: state.fetchedAt,
  };
}

export function removeSingleObjectFromState(state, objectId) {
  const idIndex = state.allIds.indexOf(objectId);
  const newById = _omit(state.byId, [objectId]);
  return {
    allIds: [
      ...state.allIds.slice(0, idIndex),
      ...state.allIds.slice(idIndex + 1),
    ],
    byId: newById,
    fetchedAt: state.fetchedAt,
  };
}

/*
 * Removes a several objects from a state that has the shape:
 * {
 *   byId: {...},
 *   allIds: [],
 *   fetchedAt: ...,
 * }
 * @param state [Object] - the state object for the current state
 * @param objectIds [Array<Number>] - the ids to be removed
 * @return a new copy of the state with all the objectIds removed
 */
export function removeObjectsFromState(state, objectIds) {
  const idsToKeep = _difference(state.allIds, objectIds);
  const newState = {
    allIds: idsToKeep,
    byId: {},
  };

  // rebuild the state object
  idsToKeep.forEach((id) => {
    newState.byId[id] = state.byId[id];
  });

  return newState;
}

/**
 *  Returns an array with the specified entry replaced
 *  @param {Array} array - An array of data objects.
 *    Each object must have an id property.
 *  @param {Number} objectId - The object ID to find and update.
 *  @param {Object} replace - New object.
 */
export function replaceSingleObject(array, objectId, replace) {
  return array.map((c) => {
    if (c.id === objectId) {
      return ({ ...replace });
    }
    return c;
  });
}

/**
 *  Returns an array with the specified entry updated
 *  @param {Array} array - An array of data objects.
 *    Each object must have an id property.
 *  @param {Number} objectId - The object ID to find and update.
 *  @param {Object} update - An object with updated properties.
 */
export function updateSingleObject(array, objectId, update) {
  return array.map((c) => {
    if (c.id === objectId) {
      return ({ ...c, ...update });
    }
    return c;
  });
}

export function deleteSingleObject(objectToBeAltered, objectId) {
  const newObject = { ...objectToBeAltered };
  delete newObject[objectId];
  return newObject;
}

/**
 *  Returns an object with the normalized state shape according to:
 *    http://redux.js.org/docs/recipes/reducers/NormalizingStateShape.html
 *  @param {Array} arrayOfObjects - An array of data objects.
 *    Each object must have an id property.
 */
export function normalizeArray(arrayOfObjects) {
  return {
    byId: indexableObject(arrayOfObjects),
    allIds: extractIds(arrayOfObjects),
  };
}

/*
 * Converts an array of objects into an object with id as the key.
 * Ex: [{id: 1, name: "Bob"}, id: 2, name: "Lucy"}]
 *     becomes
 *     { "1" => {id: 1, name: "Bob"}, "2" => {id: 2, name: "Lucy"} }
 */
export function indexableObject(arrayOfObjects = []) {
  const ids = extractIds(arrayOfObjects);

  if (arrayOfObjects.length !== ids.length) {
    window.console.warn('Warning: Reducer received an array of objects that does not have unique ids. Duplicates have been discarded. First element in array: ', arrayOfObjects[0]);
  }

  return arrayOfObjects.reduce((resultObj, obj) => ({ ...resultObj, [obj.id]: obj }), {});
}

// TODO: Consider memoizing this function for better performance
// _.memoize

/**
 * Returns an Array with the ids of each data object.
 * @param {Array} [arrayOfObjects = []] - The array of objects
 * @returns {Array} Array of ids
 */
export function extractIds(arrayOfObjects = []) {
  return arrayOfObjects.reduce((ids, obj) => {
    if (!obj.hasOwnProperty('id')) {
      throw Error(`Reducer received an array of objects without an id property. Object with missing property: ${JSON.stringify(obj)}`);
    }
    return [...ids, obj.id];
  }, []);
}

export function getCachingTimestamp(state, action, DateObject = window.Date) {
  if (!action.meta || typeof action.meta.cached === 'undefined') {
    throw new Error(`Invalid action. Reducer is expecting the action to contain a { meta: { cached: <boolean> }} property. Action details: ${JSON.stringify(action)}`);
  }
  if (action.meta.cached) {
    // The payload is cached. FetchedAt should be the original timestamp.
    return state.fetchedAt;
  }
  const now = new DateObject().toISOString();
  if (!state.fetchedAt) {
    // This is the first time this data was fetched.
    return now;
  }
  if (!action.meta.cached) {
    // This is a re-fetching of the data. Generate a new timestamp
    return now;
  }

  return null;
}
