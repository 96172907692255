import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SAVE_COURSE_ARTICLE, RESET_COURSE_ARTICLE } from 'store/courses/actions';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import InputField from 'components/shared/FormFields/InputField';
import ErrorMessage from 'components/shared/ErrorMessage';
import { selectArticleById } from 'store/courses/selectors';
import { REDUX_STATUS } from 'lib/constants';

const formatKey = (key) => {
  if (!key) return '';
  return key
    // https://www.30secondsofcode.org/js/s/remove-accents/
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .replace(/[^A-Za-z0-9-_ ]/g, '')
    .replace(/ /g, '-');
};

const FormSchema = Yup.object().shape({
  key: Yup.string().required().label('Key').trim()
    .matches(/^[a-zA-Z0-9\-_ ]+$/, 'Incorrect format'),
});

function AddArticleKeyForm({ setUpdatingKey }) {
  const { articleId } = useParams();
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.courses.article);
  const inputRef = useRef(null);
  const article = useSelector((state) => selectArticleById(state, articleId));

  const handleSubmit = useCallback((values, actions) => {
    dispatch(SAVE_COURSE_ARTICLE.request(values, { formikActions: actions }));
  }, [dispatch]);

  useEffect(() => {
    inputRef.current.focus({ preventScroll: true });
  }, []);

  useEffect(() => {
    if (status === REDUX_STATUS.SUCCESS) {
      dispatch(RESET_COURSE_ARTICLE.action());
      setUpdatingKey(false);
    }
  }, [status, setUpdatingKey, dispatch]);

  const generateKey = useCallback(() => {
    inputRef.current.value = formatKey(article.nameEn);
  }, [article.nameEn]);

  return (
    <>
      <ErrorMessage error={error} />

      <Formik
        onSubmit={handleSubmit}
        initialValues={{ ...article }}
        validationSchema={FormSchema}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <Form style={{ width: '20rem' }}>
            <div className="row align-items-center">
              <InputField
                inputRef={inputRef}
                label="Key"
                hideLabel
                name="key"
                helpText="May contain characters, numbers, “-”, “_” and “ ” only."
                disabled={article?.isLocked}
                addOnEnd={!article?.isLocked && (
                  <Button name="regenerate" variant="primary" size="sm" className="btn-plain p-0 text-nowrap" onClick={generateKey}>
                    Regenerate
                  </Button>
                )}
                autoComplete="off"
              />
            </div>
            <Button variant="primary" size="sm" type="submit" disabled={isSubmitting}>
              Save
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
          </Form>

        )}
      </Formik>
    </>
  );
}

AddArticleKeyForm.defaultProps = {
  setUpdatingKey: () => {},
};

AddArticleKeyForm.propTypes = {
  setUpdatingKey: PropTypes.func,
};

export default AddArticleKeyForm;
