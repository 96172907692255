import React from 'react';

function ChatSquareQuote() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_246_3838)">
        <path fillRule="evenodd" clipRule="evenodd" d="M21 1.5H3C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H6.75C7.21574 16.5 7.67507 16.6084 8.09164 16.8167C8.50821 17.025 8.87056 17.3274 9.15 17.7L12 21.4995L14.85 17.7C15.1294 17.3274 15.4918 17.025 15.9084 16.8167C16.3249 16.6084 16.7843 16.5 17.25 16.5H21C21.3978 16.5 21.7794 16.342 22.0607 16.0607C22.342 15.7794 22.5 15.3978 22.5 15V3C22.5 2.60218 22.342 2.22064 22.0607 1.93934C21.7794 1.65804 21.3978 1.5 21 1.5ZM3 0C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3L0 15C0 15.7956 0.316071 16.5587 0.87868 17.1213C1.44129 17.6839 2.20435 18 3 18H6.75C6.98287 18 7.21254 18.0542 7.42082 18.1584C7.6291 18.2625 7.81028 18.4137 7.95 18.6L10.8 22.3995C10.9397 22.5858 11.1209 22.737 11.3292 22.8411C11.5375 22.9453 11.7671 22.9995 12 22.9995C12.2329 22.9995 12.4625 22.9453 12.6708 22.8411C12.8791 22.737 13.0603 22.5858 13.2 22.3995L16.05 18.6C16.1897 18.4137 16.3709 18.2625 16.5792 18.1584C16.7875 18.0542 17.0171 18 17.25 18H21C21.7956 18 22.5587 17.6839 23.1213 17.1213C23.6839 16.5587 24 15.7956 24 15V3C24 2.20435 23.6839 1.44129 23.1213 0.87868C22.5587 0.316071 21.7956 0 21 0L3 0Z" fill="currentColor" />
        <path d="M11.202 8.5005C11.202 9.8805 10.038 10.9995 8.601 10.9995C7.164 10.9995 6 9.8805 6 8.5005C6 7.1205 7.164 6 8.601 6C10.038 6 11.202 7.119 11.202 8.5005Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M9.2355 7.40391C9.21079 7.48895 9.16914 7.56811 9.11307 7.63664C9.05699 7.70517 8.98764 7.76166 8.90918 7.80271C8.83072 7.84376 8.74477 7.86853 8.65649 7.87552C8.56822 7.88252 8.47944 7.8716 8.3955 7.84341C8.3158 7.81973 8.24169 7.78025 8.17758 7.72733C8.11346 7.6744 8.06066 7.60911 8.02231 7.53535C7.98395 7.46159 7.96084 7.38086 7.95435 7.29798C7.94785 7.21509 7.95811 7.13175 7.9845 7.05291C8.1045 6.70791 8.3295 6.39291 8.7 6.23391C8.86694 6.16675 9.04573 6.13399 9.22564 6.1376C9.40555 6.14121 9.58288 6.18111 9.747 6.25491C10.3275 6.49491 10.827 7.07241 11.1315 7.75041C11.7735 9.17241 11.721 11.3159 9.7185 13.3094C9.59627 13.4285 9.43313 13.4965 9.26248 13.4992C9.09182 13.502 8.92655 13.4395 8.8005 13.3244C8.73904 13.2687 8.68962 13.2011 8.6553 13.1256C8.62098 13.0501 8.60248 12.9684 8.60093 12.8855C8.59939 12.8026 8.61483 12.7202 8.64632 12.6435C8.6778 12.5668 8.72465 12.4973 8.784 12.4394C10.4235 10.8089 10.371 9.20241 9.939 8.24841C9.711 7.74441 9.408 7.47741 9.234 7.40391H9.2355Z" fill="currentColor" />
        <path d="M17.7045 8.5005C17.7045 9.8805 16.5405 10.9995 15.1035 10.9995C13.668 10.9995 12.5025 9.8805 12.5025 8.5005C12.5025 7.1205 13.668 6 15.1035 6C16.5405 6 17.7045 7.119 17.7045 8.5005Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.738 7.40391C15.7133 7.48895 15.6716 7.56811 15.6156 7.63664C15.5595 7.70517 15.4901 7.76166 15.4117 7.80271C15.3332 7.84376 15.2473 7.86853 15.159 7.87552C15.0707 7.88252 14.9819 7.8716 14.898 7.84341C14.8183 7.81973 14.7442 7.78025 14.6801 7.72733C14.616 7.6744 14.5632 7.60911 14.5248 7.53535C14.4865 7.46159 14.4633 7.38086 14.4568 7.29798C14.4504 7.21509 14.4606 7.13175 14.487 7.05291C14.607 6.70791 14.832 6.39291 15.2025 6.23391C15.3694 6.16675 15.5482 6.13399 15.7281 6.1376C15.9081 6.14121 16.0854 6.18111 16.2495 6.25491C16.83 6.49491 17.3295 7.07241 17.6355 7.75041C18.2775 9.17241 18.2235 11.3159 16.2225 13.3094C16.1002 13.429 15.9367 13.4972 15.7657 13.5C15.5947 13.5027 15.4291 13.4399 15.303 13.3244C15.2415 13.2687 15.1921 13.2011 15.1578 13.1256C15.1235 13.0501 15.105 12.9684 15.1034 12.8855C15.1019 12.8026 15.1173 12.7202 15.1488 12.6435C15.1803 12.5668 15.2272 12.4973 15.2865 12.4394C16.926 10.8089 16.8735 9.20241 16.4415 8.24841C16.2135 7.74441 15.9105 7.47741 15.738 7.40391Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_3838">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ChatSquareQuote;
