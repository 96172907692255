import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useFieldContext } from 'lib/hooks/useFieldContext';
import { RefPropType } from 'lib/propTypes';

/* eslint-disable react/jsx-props-no-spreading */

function Textarea({ name, inputRef, ...props }) {
  const { id, value, touched, error, handleChange, handleBlur } = useFieldContext();
  const ref = useRef(null);
  const textAreaRef = inputRef || ref;
  const [isVisible, setIsVisible] = useState(false);

  const autoResize = useCallback(() => {
    textAreaRef.current.style.height = 'auto';
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight + 2}px`; // +2px to account for border
  }, [textAreaRef]);

  useEffect(() => {
    autoResize();
  }, [textAreaRef, value, isVisible, autoResize]);

  useEffect(() => {
    const textarea = textAreaRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (textarea) observer.observe(textarea);

    return () => {
      if (textarea) observer.unobserve(textarea);
    };
  }, [textAreaRef]);

  return (
    <textarea
      ref={textAreaRef}
      id={id}
      name={name}
      className={`form-control ${(touched && error) ? 'is-invalid' : ''}`}
      value={value || ''}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
}

Textarea.defaultProps = {
  inputRef: null,
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  inputRef: RefPropType,
};

export default Textarea;
