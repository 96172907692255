import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType } from 'lib/propTypes';

function InputGroup({ className, children, addOnStart, addOnEnd }) {
  return (
    <div className={`d-flex align-items-center ${className || ''}`}>
      {addOnStart && <span className="pe-2 text-small">{addOnStart}</span>}
      {children}
      {addOnEnd && <span className="ps-2 text-small">{addOnEnd}</span>}
    </div>
  );
}

InputGroup.defaultProps = {
  className: null,
  addOnStart: null,
  addOnEnd: null,
};

InputGroup.propTypes = {
  className: PropTypes.string,
  children: ComponentPropType.isRequired,
  addOnStart: ComponentPropType,
  addOnEnd: ComponentPropType,
};

export default InputGroup;
