import { fetchGet, fetchPost, handleSimpleFetchError } from 'lib/apiHelpers';
import { put, takeLatest } from 'redux-saga/effects';

import { CREATE_COVERAGE_REPORT, FETCH_ALL_COVERAGE_REPORTS, GET_COVERAGE_FORM_DATA } from 'store/superFacilitatorReport/actions';

function* createReport(action) {
  try {
    const response = yield fetchPost('/api/super_facilitator/reports/discussion_board.json', {
      ...action.payload,
    });
    yield put(CREATE_COVERAGE_REPORT.success(response.data, { ...response.meta, cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, CREATE_COVERAGE_REPORT);
  }
}

function* fetchAllReports() {
  try {
    const response = yield fetchGet('/api/super_facilitator/reports/discussion_board.json');
    yield put(FETCH_ALL_COVERAGE_REPORTS.success(response.data, { ...response.meta, cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, FETCH_ALL_COVERAGE_REPORTS);
  }
}

function* getFacilitatorsFormData() {
  try {
    const response = yield fetchGet('/api/super_facilitator/reports/discussion_board/form_data.json');
    yield put(GET_COVERAGE_FORM_DATA.success(response.data, { ...response.meta, cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, GET_COVERAGE_FORM_DATA);
  }
}

export default function* sagas() {
  yield takeLatest(CREATE_COVERAGE_REPORT.REQUEST, createReport);
  yield takeLatest(FETCH_ALL_COVERAGE_REPORTS.REQUEST, fetchAllReports);
  yield takeLatest(GET_COVERAGE_FORM_DATA.REQUEST, getFacilitatorsFormData);
}
