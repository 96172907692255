import React, { useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import useBreakpoint, { breakpoints } from 'lib/hooks/useBreakpoint';
import AddUrlPopover from './AddUrlPopover';
import AddFilePopover from './AddFilePopover';

function AddResources() {
  const breakpoint = useBreakpoint();
  const [addingUrl, setAddingUrl] = useState(false);
  const [addingFile, setAddingFile] = useState(false);

  const toggleAddingUrl = useCallback(() => {
    setAddingUrl((prev) => !prev);
  }, []);

  const toggleAddingFile = useCallback(() => {
    setAddingFile((prev) => !prev);
  }, []);

  return (
    <div className="mt-2 px-2">
      <OverlayTrigger
        trigger="click"
        placement={breakpoint === breakpoints.XXL ? 'left' : 'top'}
        overlay={AddUrlPopover}
        rootClose
        show={addingUrl}
        onToggle={toggleAddingUrl}
        popperConfig={{ setAddingUrl }}
      >
        <Button className="btn-plain" variant="primary" size="sm" disabled={addingUrl || addingFile}>+ Add URL</Button>
      </OverlayTrigger>

      <OverlayTrigger
        trigger="click"
        placement={breakpoint === breakpoints.XXL ? 'left' : 'top'}
        overlay={AddFilePopover}
        rootClose
        show={addingFile}
        onToggle={toggleAddingFile}
        popperConfig={{ setAddingFile }}
      >
        <Button variant="primary" size="sm" className="ms-1 btn-plain" disabled={addingUrl || addingFile}>+ Add file</Button>
      </OverlayTrigger>
    </div>
  );
}

export default AddResources;
