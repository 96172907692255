import React from 'react';
import { useSelector } from 'react-redux';
import { GroupPropType } from 'lib/propTypes';
import { selectDiscussionsSectionsValues } from 'store/groupShow/selectors';
import ModuleDetails from './DiscussionModuleDetails';

function GroupDiscussionsTabPage({ group }) {
  const discussionsSectionsValues = useSelector((state) => selectDiscussionsSectionsValues(state));

  if (!group) return { loading: true };

  return (
    <>
      {(discussionsSectionsValues.length === 0) && (
        <div className="col-8">
          <div className="alert alert-info">There are no discussions for this group yet.</div>
        </div>
      )}

      {(discussionsSectionsValues.length > 0) && (
        discussionsSectionsValues.map((section) => (
          <div key={section.id} className="col-md-8 mb-2">
            <ModuleDetails section={section} group={group} />
          </div>
        ))
      )}
    </>
  );
}

GroupDiscussionsTabPage.defaultProps = {
  group: null,
};

GroupDiscussionsTabPage.propTypes = {
  group: GroupPropType,
};

export default GroupDiscussionsTabPage;
