import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'bootstrap/js/dist/tooltip'; // same as 'app/javascript/index.js' to avoid conflicts
import { kebabCase as _kebabCase, upperFirst as _upperFirst, lowerCase as _lowerCase } from 'lodash';
import { Form } from 'react-bootstrap';

const SIZES = {
  sm: '6em',
  md: '10em',
  lg: '12em',
  xl: '16em',
  xxl: '20em',
};

function Input({ name, index, type, value, error, size, onBlur, disabled }) {
  const inputRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (error) {
      tooltipRef.current = new Tooltip(inputRef.current, {
        title: error,
        customClass: 'bs-tooltip-danger',
      });
    }

    return () => {
      if (typeof tooltipRef.current?.hide === 'function') {
        tooltipRef.current.hide();
      }
    };
  }, [error]);

  const handleBlur = (e) => {
    if (value !== e.target.value) {
      onBlur(e.target, index, name);
    }
  };

  const inputName = `user.${index}.${name}`;
  const inputId = _kebabCase(inputName);
  const inputLabel = _upperFirst(_lowerCase(inputName));

  return (
    <Form.Group key={inputId} controlId={inputId} className="position-relative">
      <Form.Label className="visually-hidden">{inputLabel}</Form.Label>
      <Form.Control
        ref={inputRef}
        type={type}
        isInvalid={!!error}
        style={{ fontSize: '0.875rem', padding: '2px 6px', width: SIZES[size] }}
        name={`user.${index}.${name}`}
        defaultValue={value}
        onBlur={handleBlur}
        disabled={disabled}
        autoComplete="false"
        data-1p-ignore // ignore 1Password
        data-lpignore // ignore LastPass
      />
    </Form.Group>
  );
}

Input.defaultProps = {
  type: 'text',
  value: '',
  error: null,
  size: 'md',
  onBlur: () => {},
  disabled: false,
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email']),
  index: PropTypes.number.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(Input);
