import React from 'react';
import PropTypes from 'prop-types';

function Pagination({ page, totalPages, window, outerWindow, first, previous, next, last, onPageClick }) {
  const hasNewerPage = page > 1;
  const hasOlderPage = page < totalPages;

  const outerWindowPagesStart = Array.from(Array(outerWindow), (_, i) => i + 1).filter((i) => i < page);
  const outerWindowPagesEnd = Array.from(Array(outerWindow), (_, i) => totalPages - i).filter((i) => i > page).reverse();
  const windowPagesStart = Array.from(Array(window), (_, i) => page - i - 1).filter((i) => i > 0 && !outerWindowPagesStart.includes(i)).reverse();
  const windowPagesEnd = Array.from(Array(window), (_, i) => page + i + 1).filter((i) => i < totalPages && !outerWindowPagesEnd.includes(i));

  const hasGapStart = outerWindowPagesStart.slice(-1)[0] < (windowPagesStart[0] - 1);
  const hasGapEnd = windowPagesEnd.slice(-1)[0] < (outerWindowPagesEnd[0] - 1);

  const handlePageClick = (p) => (e) => {
    e.preventDefault();
    if (p) onPageClick(p);
  };

  return (
    <nav aria-label="navigate between pages">
      <ul className="pagination justify-content-center py-2">
        { hasNewerPage
          && (
            <>
              <li className="page-item">
                <a className="page-link" tabIndex="0" role="button" aria-disabled="false" aria-label="First page" href="#first" onClick={handlePageClick(1)}>
                  <span aria-hidden="true">&laquo;</span>
                  {' '}
                  {first}
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" tabIndex="0" role="button" aria-disabled="false" aria-label="Previous page" href="#previous" onClick={handlePageClick(page - 1)}>
                  <span aria-hidden="true">&lsaquo;</span>
                  {' '}
                  {previous}
                </a>
              </li>
            </>
          )}

        {outerWindowPagesStart.length > 0 && (
          outerWindowPagesStart.map((p) => (
            <li key={p} className="page-item">
              <a className="page-link" tabIndex="0" role="button" aria-disabled="false" aria-label={`Page ${p}`} href={`#page-${p}`} onClick={handlePageClick(p)}>
                {p}
              </a>
            </li>
          ))
        )}

        {hasGapStart && (
          <li className="page-item disabled"><a href="#gap" className="page-link" tabIndex="-1" onClick={handlePageClick}>&hellip;</a></li>
        )}

        {windowPagesStart.length > 0 && (
          windowPagesStart.map((p) => (
            <li key={p} className="page-item">
              <a className="page-link" tabIndex="0" role="button" aria-disabled="false" aria-label={`Page ${p}`} href={`#page-${p}`} onClick={handlePageClick(p)}>
                {p}
              </a>
            </li>
          ))
        )}

        <li className="page-item active"><a href="#gap" className="page-link" tabIndex="-1" onClick={handlePageClick(page)}>{page}</a></li>

        {windowPagesEnd.length > 0 && (
          windowPagesEnd.map((p) => (
            <li key={p} className="page-item">
              <a className="page-link" tabIndex="0" role="button" aria-disabled="false" aria-label={`Page ${p}`} href={`#page-${p}`} onClick={handlePageClick(p)}>
                {p}
              </a>
            </li>
          ))
        )}

        {hasGapEnd && (
          <li className="page-item disabled"><a href="#gap" className="page-link" tabIndex="-1" onClick={handlePageClick}>&hellip;</a></li>
        )}

        {outerWindowPagesEnd.length > 0 && (
          outerWindowPagesEnd.map((p) => (
            <li key={p} className="page-item">
              <a className="page-link" tabIndex="0" role="button" aria-disabled="false" aria-label={`Page ${p}`} href={`#page-${p}`} onClick={handlePageClick(p)}>
                {p}
              </a>
            </li>
          ))
        )}

        { hasOlderPage
          && (
            <>
              <li className="page-item">
                <a className="page-link" tabIndex="0" role="button" aria-disabled="false" aria-label="Next page" rel="next" href="#next" onClick={handlePageClick(page + 1)}>
                  {next}
                  {' '}
                  <span aria-hidden="true">&rsaquo;</span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" tabIndex="0" role="button" aria-disabled="false" aria-label="Last page" href="#last" onClick={handlePageClick(totalPages)}>
                  {last}
                  {' '}
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </>
          )}
      </ul>
    </nav>
  );
}

Pagination.defaultProps = {
  page: 1,
  totalPages: 1,
  window: 4,
  outerWindow: 0,
  first: 'First',
  previous: 'Prev',
  next: 'Next',
  last: 'Last',

};

Pagination.propTypes = {
  page: PropTypes.number,
  totalPages: PropTypes.number,
  window: PropTypes.number,
  outerWindow: PropTypes.number,
  first: PropTypes.string,
  previous: PropTypes.string,
  next: PropTypes.string,
  last: PropTypes.string,
  onPageClick: PropTypes.func.isRequired,
};

export default Pagination;
