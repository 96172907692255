import { createSelector } from 'reselect';

export const selectEmailTemplatesData = (state) => state.emailTemplates.templates.data;
export const selectEmailTemplateTypesData = (state) => state.emailTemplates.types.data;
export const selectEmailTemplateTagsData = (state) => state.emailTemplates.tags.data;

export const selectEmailTemplateById = createSelector(
  [selectEmailTemplatesData,
    (_, id) => id],
  (emailTemplates, id) => emailTemplates?.find((et) => et.id === id),
);

export const selectEmailTemplateWithTypeId = createSelector(
  [selectEmailTemplatesData,
    (_, id) => id],
  (emailTemplates, id) => emailTemplates?.find((et) => et.emailTemplateTypeId === id),
);

export const selectEmailTemplateTypeById = createSelector(
  [selectEmailTemplateTypesData,
    (_, id) => id],
  (types, id) => types.find((t) => t.id === id),
);

export const selectEmailTemplateTypeByContext = createSelector(
  [selectEmailTemplateTypesData,
    (_, context) => context],
  (types, context) => types.filter((t) => t.context === context),
);

export const selectGroupedTags = createSelector(
  selectEmailTemplateTagsData,
  (tags) => tags.reduce((acc, curr) => {
    let category = 'Other';
    if (curr.code.startsWith('course')) category = 'Course';
    if (curr.code.startsWith('group')) category = 'Group';
    if (curr.code.startsWith('iwl')) category = 'IWL';
    if (curr.code.startsWith('participant')) category = 'Participant';
    if (curr.code.endsWith('url')) category = 'Links';

    return {
      ...acc,
      [category]: [...acc[category], curr],
    };
  }, {
    Course: [],
    Participant: [],
    Other: [],
    Group: [],
    Links: [],
    IWL: [],
  }),
);
