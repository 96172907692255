import React, { useMemo } from 'react';
import { GroupPropType } from 'lib/propTypes';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import add from 'date-fns/add';
import formatISO from 'date-fns/formatISO';
import { COMPACT_DATE } from 'lib/dateFormats';
import GroupSettingsForm from 'components/groups/GroupSettings/GroupSettingsForm';
import GroupSettingsActionsMenu from 'components/groups/GroupSettings/GroupSettingsActionsMenu';

function GroupSettingsTabPage({ group }) {
  // No Longer in the design however, will keep this for now as we will need it shortly. (will request where to put it)
  // I believe this is a calculated date set when participants are extended and should not be editable.
  // eslint-disable-next-line no-unused-vars
  const groupExtendedUntilDate = useMemo(() => {
    if (!group.extendedUntil) {
      return null;
    }

    /*
      The extendedUntil attribute only stores a date, and not a time.
      We copy the time of the closeSessionAt attribute to extendedUntil
      to display the correct date and time. This is a temporary fix
      until we update the extendedUntil date stamp to include a time.
    */
    const extendedUntilDate = parse(group.extendedUntil, COMPACT_DATE, new Date());
    const closeSessionAtDate = parseISO(group.closeSessionAt);
    const hours = getHours(closeSessionAtDate);
    const minutes = getMinutes(closeSessionAtDate);

    return formatISO(add(extendedUntilDate, { hours, minutes }));
  }, [group.closeSessionAt, group.extendedUntil]);

  return (
    <div className="row">
      <div className="col-md-8">
        <GroupSettingsForm group={group} />
      </div>

      <div className="col-md-4 col-xl-3 offset-xl-1">
        <dl className="my-3 mt-md-0 border rounded bg-light px-3 py-2">
          <dt>HA Reservations</dt>
          <dd>{group.reservationsState === 'res_active' ? 'Active' : 'Expired'}</dd>
          <dt>Total Reserved Seats</dt>
          <dd>{group.reservedSeatsCount}</dd>
        </dl>
        <div className="mt-3 mt-md-0 mb-3 border rounded px-3 py-2">
          <h4 className="py-2">Actions</h4>
          <GroupSettingsActionsMenu group={group} />
        </div>
      </div>
    </div>
  );
}

GroupSettingsTabPage.propTypes = {
  group: GroupPropType.isRequired,
};

export default GroupSettingsTabPage;
