import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REDUX_STATUS } from 'lib/constants';
import usePrevious from 'lib/hooks/usePrevious';
import { buildRoutePath } from 'lib/routerHelpers';
import { REGISTRAR_SETTINGS_ORGANIZATION_EMAIL_TEMPLATES_PATH } from 'lib/routerPaths';
import { LOAD_EMAIL_TEMPLATES } from 'store/emailTemplates/actions';
import { selectEmailTemplateTypeByContext } from 'store/emailTemplates/selectors';
import { selectOrganizationById } from 'store/organizations/selectors';
import Button from 'react-bootstrap/Button';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import TabbedNav from 'components/shared/Navigation/TabbedNav';
import SanyasIcon from 'components/shared/SanyasIcon';
import Spinner from 'components/shared/Spinner';
import EmailTemplatesListItem from 'components/shared/EmailTemplates/ListItem';
import EmailTemplateForm from 'components/shared/EmailTemplates/Form';

function OrganizationEmailTemplatesPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const organization = useSelector((state) => selectOrganizationById(state, id));
  const emailTemplates = useSelector((state) => state.emailTemplates.templates);
  const emailTemplateTypes = useSelector((state) => selectEmailTemplateTypeByContext(state, 'Organization'));
  const emailTemplateTypesStatus = useSelector((state) => state.emailTemplates.types.status);
  const prevEmailTemplatesStatus = usePrevious(emailTemplates.status);
  const prevEmailTemplateTypesStatus = usePrevious(emailTemplateTypesStatus);
  const [selectedTemplate, setSelectedTemplate] = useState(-1);

  const emailTemplatesLoaded = [REDUX_STATUS.PENDING, REDUX_STATUS.SUCCESS].includes(prevEmailTemplatesStatus)
    && emailTemplates.status === REDUX_STATUS.SUCCESS;
  const emailTemplateTypesLoaded = [REDUX_STATUS.PENDING, REDUX_STATUS.SUCCESS].includes(prevEmailTemplateTypesStatus)
    && emailTemplateTypesStatus === REDUX_STATUS.SUCCESS;

  const activeEmailTemplateTypeIds = emailTemplates.data.map((template) => template.emailTemplateTypeId);
  const activeTemplateTypes = emailTemplateTypes?.filter((type) => activeEmailTemplateTypeIds.includes(type.id));
  const moreTemplateTypes = emailTemplateTypes?.filter((type) => !activeEmailTemplateTypeIds.includes(type.id));

  useEffect(() => {
    dispatch(LOAD_EMAIL_TEMPLATES.request({ entityId: Number(id), context: 'organizations', contextType: 'Organization' }));
  }, [id, dispatch]);

  useEffect(() => {
    if (emailTemplatesLoaded && emailTemplateTypesLoaded && selectedTemplate === -1) {
      setSelectedTemplate(activeTemplateTypes?.[0]?.id || moreTemplateTypes?.[0]?.id);
    }
  }, [emailTemplatesLoaded, emailTemplateTypesLoaded, selectedTemplate, activeTemplateTypes, moreTemplateTypes]);

  return (
    <RegistrarTemplate className="bg-white">
      <div className="header flush pb-0">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <div>
            <Breadcrumbs
              className="course-show-breadcrumbs my-0"
              navItems={[
                { label: 'Settings', href: '/registrar/settings' },
                { label: 'Organizations', href: '/registrar/settings/organizations' },
                { label: organization?.shortName || '...' },
              ]}
            />

            <h1 className="mb-0">{organization?.name}</h1>
          </div>

          <div className="ms-auto">
            <Button
              variant="outline-secondary"
              size="sm"
              className="me-1"
              href={`/registrar/health_authorities/#${organization?.permalink}`}
              title="View health authorities belonging to this organization"
            >
              {organization?.healthAuthorityLabelPlural || '...'}
            </Button>
            <Button
              variant="outline-secondary"
              size="sm"
              href={`/registrar/groups?filterrific%5Bwith_organization_id%5D=${organization?.id}`}
              title="View this organization's groups"
            >
              Groups
            </Button>
          </div>
        </div>

        <TabbedNav
          variant="underline"
          navItems={[
            {
              title: 'Settings',
              href: `/registrar/settings/organizations/${id}/edit`,
            },
            {
              title: 'Email templates',
              href: buildRoutePath(REGISTRAR_SETTINGS_ORGANIZATION_EMAIL_TEMPLATES_PATH, { id }),
              match: {
                path: REGISTRAR_SETTINGS_ORGANIZATION_EMAIL_TEMPLATES_PATH,
              },
            },
          ]}
        />
      </div>

      <div className="flush bg-white">
        <div className="row mx-0">
          <div className="col-6 border-end px-0" style={{ maxWidth: '40rem' }}>
            {/* Min-height to match next column header w/ button */}
            <div className="d-flex align-items-center py-3 px-4 border-bottom" style={{ minHeight: '4rem' }}>
              <h4 className="m-0">
                <SanyasIcon name="envelope" className="me-2" />
                Email Templates
              </h4>
            </div>

            {emailTemplates.status === REDUX_STATUS.PENDING && <Spinner />}

            {activeTemplateTypes?.length > 0 && (
              <ul className="list-group list-group-flush">
                {activeTemplateTypes.map((template) => (
                  <EmailTemplatesListItem key={template.id} template={template} setSelectedTemplate={setSelectedTemplate} organization={organization} active />
                ))}
              </ul>
            )}

            {moreTemplateTypes?.length > 0 && (
              <>
                {activeTemplateTypes?.length > 0 && (
                  <div className="d-flex align-items-center py-3 px-4 border-bottom">
                    <h4 className="m-0">
                      More email templates available
                    </h4>
                  </div>
                )}

                <ul className="list-group list-group-flush">
                  {moreTemplateTypes.map((template) => (
                    <EmailTemplatesListItem key={template.id} template={template} setSelectedTemplate={setSelectedTemplate} organization={organization} />
                  ))}
                </ul>
              </>
            )}
          </div>

          <div className="col px-0">
            {selectedTemplate > -1 && (
              <EmailTemplateForm template={selectedTemplate} context="organizations" setSelectedTemplate={setSelectedTemplate} organization={organization} />
            )}
          </div>
        </div>
      </div>
    </RegistrarTemplate>
  );
}

export default OrganizationEmailTemplatesPage;
