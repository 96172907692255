import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { RefPropType } from 'lib/propTypes';
import ClearIndicator from '../ClearIndicator/ClearIndicator';
import './styles.scss';

function ClearableInput({ id, name, className, placeholder, disabled, onChange, ref }) {
  const uniqueId = useRef(uuidv4());
  const thisRef = useRef(null);
  const inputRef = ref || thisRef;

  const thisId = id || uniqueId.current;
  const thisName = name || thisId;

  const handleChange = useCallback((e) => {
    onChange(e);
  }, [onChange]);

  const handleClear = useCallback(() => {
    inputRef.current.value = '';
    onChange({ target: inputRef.current });
  }, [inputRef, onChange]);

  const handleEscape = useCallback((e) => {
    if (document.activeElement === inputRef.current && e.key === 'Escape') {
      handleClear(e);
    }
  }, [inputRef, handleClear]);

  useEffect(() => {
    document.addEventListener('keydown', handleEscape, false);

    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape]);

  return (
    <div className={`clearable-input d-flex align-items-center ${className}`} style={{ paddingBlock: '5px' }} disabled={disabled}>
      <input id={thisId} name={thisName} ref={inputRef} type="text" className="w-100" placeholder={placeholder} disabled={disabled} onChange={handleChange} />
      <ClearIndicator onClick={handleClear} />
    </div>
  );
}

ClearableInput.defaultProps = {
  id: null,
  name: null,
  className: '',
  placeholder: '',
  disabled: false,
  ref: null,
};

ClearableInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  ref: RefPropType,
};

export default ClearableInput;
