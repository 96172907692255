import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_COURSE_PATH, FACILITATOR_COURSE_TYPE_PATH, FACILITATOR_CURRICULUM_PATH } from 'lib/routerPaths';
import { LOAD_COURSE, FIND_AND_REPLACE_SEARCH, FIND_AND_REPLACE_RESET } from 'store/courses/actions';
import { selectCourseById } from 'store/courses/selectors';
import { LOAD_FACILITATOR_COURSE_TYPES } from 'store/courseTypes/actions';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import { Button, Spinner } from 'react-bootstrap';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import InputField from 'components/shared/FormFields/InputField';
import Skeleton from 'components/shared/Skeleton';
import SearchResults from 'components/facilitator/Courses/SearchResults';

function CourseSearchPage() {
  const { courseId } = useParams();
  const course = useSelector((state) => selectCourseById(state, courseId));
  const courseType = useSelector((state) => selectCourseTypeById(state, course?.courseTypeId));
  const searchCourseId = useSelector((state) => state.courses.findAndReplace.courseId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LOAD_FACILITATOR_COURSE_TYPES.request());
  }, [dispatch]);

  useEffect(() => {
    if (!course?.id) {
      dispatch(LOAD_COURSE.request(courseId));
    }
  }, [course?.id, courseId, dispatch]);

  useEffect(() => {
    if (courseId !== searchCourseId) {
      dispatch(FIND_AND_REPLACE_RESET.action());
    }
  }, [courseId, searchCourseId, dispatch]);

  const handleSearch = useCallback((values, actions) => {
    dispatch(FIND_AND_REPLACE_SEARCH.request({ ...values, workspace: 'facilitator' }, { formikActions: actions }));
  }, [dispatch]);

  const handleReset = useCallback(() => {
    dispatch(FIND_AND_REPLACE_RESET.action());
  }, [dispatch]);

  return (
    <div className="container-fluid">
      {/* # HEADER */}
      <div className="row pt-4 header">
        <h3 className="col-md-2 mb-0 pe-0">Search</h3>
        <div className="d-flex col-md-8 ps-0">
          <Breadcrumbs
            className="course-show-breadcrumbs my-0"
            navItems={[
              { label: 'Courses', href: FACILITATOR_CURRICULUM_PATH },
              {
                label: courseType?.name || <Skeleton classes="text" />,
                href: buildRoutePath(FACILITATOR_COURSE_TYPE_PATH, { id: courseType?.id }),
              },
              {
                label: course?.description || <Skeleton classes="text" />,
                href: buildRoutePath(FACILITATOR_COURSE_PATH, { courseId: course?.id }),
              },
            ]}
            ignoreLast
          />
        </div>
        <div className="d-flex justify-content-end col-md-2">
          <Link
            className="btn-close"
            to={buildRoutePath(FACILITATOR_COURSE_TYPE_PATH, { id: courseType?.id })}
            onClick={handleReset}
            aria-label="Cancel"
          />
        </div>
      </div>

      {/* # INTRO / SEARCH */}
      <div className="row">
        <div className="col-md-8 my-4 mx-auto">
          <h3>Search Course content</h3>
          <p className="vw-75">
            Quickly locate content in a course.
            You will be presented with a list allowing you to individually review each task.
          </p>

          <Formik
            initialValues={{
              findTerm: '',
              courseId,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.findTerm) {
                errors.findTerm = 'Required';
              }
              return errors;
            }}
            onSubmit={handleSearch}
            enableReinitialize
          >
            {({ isSubmitting }) => (
              <Form className="row g-3">
                <div className="col-md-3">
                  <InputField
                    label="Search"
                    name="findTerm"
                    size="md"
                  />
                </div>
                <div className="col-md-2 d-flex align-items-end pb-2">
                  <Button type="submit" value="primary" disabled={isSubmitting}>
                    Search
                    {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {/* # RESULTS */}
      <SearchResults />
    </div>
  );
}

export default CourseSearchPage;
