import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_HARM_PREVENTION_REPORTS } from 'store/actions';
import Alert from 'react-bootstrap/Alert';
import ReportingTemplate from 'components/templates/ReportingTemplate';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import ReportsList from 'components/reporting/HarmPrevention/ReportsList';
import RequestForm from 'components/reporting/HarmPrevention/RequestForm';

function HarmReport() {
  const { isHarmTeamUser, isRegistrar, isSuperAdmin } = useSelector((state) => state.currentUser || {});
  const dispatch = useDispatch();
  const hasAccess = isHarmTeamUser || isRegistrar || isSuperAdmin;

  useEffect(() => {
    dispatch(GET_HARM_PREVENTION_REPORTS.request());

    const id = setInterval(() => {
      dispatch(GET_HARM_PREVENTION_REPORTS.request());
    }, 15 * 1000);

    return () => clearInterval(id);
  }, [dispatch]);

  return (
    <ReportingTemplate>
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Reports', href: '/reporting' },
                { label: 'Harm Prevention' },
              ]}
            />

            <h1 className="mb-0">Harm Prevention Report</h1>
          </div>
        </div>
      </div>

      <div className="flush px-4 my-4">
        <p className="lead">
          Request a new report, view reports that are in-progress, and download completed reports.
        </p>

        {!hasAccess && (
          <Alert variant="info">You do not have access to this report</Alert>
        )}

        {hasAccess && (
          <>
            <p>
              This report will export all of the responses for the stereotyping discussion boards for a given timeframe.
            </p>

            <p>
              Reports are generated in the background.
              Once a report is requested, this page can be closed and the report will continue generating.
              This page is auto-updating and will display reports as they are in-progress and eventually completed.
              Completed reports can be downloaded in comma-separated value (CSV) format.
            </p>
          </>
        )}

        {hasAccess && (
          <>
            <RequestForm />

            <ReportsList />
          </>
        )}
      </div>
    </ReportingTemplate>
  );
}

HarmReport.defaultProps = {
  reports: {
    allIds: [],
    byId: {},
  },
  filters: {},
  formData: {
    courseTypeIds: [],
    courseTypes: {},
  },
};

export default HarmReport;
