import React from 'react';
import { useSelector } from 'react-redux';
import { LEGACY_APP_DOMAIN } from 'config';
import { GroupPropType, TaskPropType } from 'lib/propTypes';
import { selectSubgroupsValues } from 'store/groupShow/selectors';

function DiscussionTaskDetails({ task, group }) {
  const subGroups = useSelector((state) => selectSubgroupsValues(state));

  return (
    <>
      <strong>{task.name}</strong>

      { subGroups.map((subGroup) => (
        <div
          key={subGroup.id}
          className="mt-2"
        >
          <a
            href={`${LEGACY_APP_DOMAIN}/groups/${group.id}/sub_groups/${subGroup.id}/discussion_tasks/${task.id}`}
            className="position-relative"
            target="_blank"
            rel="noreferrer"
          >
            {subGroup.name}
            <span className="top-0 start-100 translate-middle badge rounded-pill bg-primary ms-3">{task.subGroups[subGroup.id].count}</span>
          </a>
        </div>
      ))}
    </>
  );
}

DiscussionTaskDetails.propTypes = {
  task: TaskPropType.isRequired,
  group: GroupPropType.isRequired,
};

export default DiscussionTaskDetails;
