import { takeLatest, put, select } from 'redux-saga/effects';
import { fetchGet, handleSimpleFetchError } from 'lib/apiHelpers';
import { LOAD_ORGANIZATIONS } from 'store/organizations/actions';

export default function* sagas() {
  yield takeLatest(LOAD_ORGANIZATIONS.REQUEST, function* getOrganizations() {
    const state = yield select();
    if (!state.organizations.allIds.length) {
      try {
        const { data } = yield fetchGet('/api/shared/organizations');
        yield put(LOAD_ORGANIZATIONS.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, LOAD_ORGANIZATIONS);
      }
    } else {
      yield put(LOAD_ORGANIZATIONS.success({}, { cached: true }));
    }
  });
}
