import React from 'react';

function Audio() {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.80662 3.99313C9.90193 4.03904 9.98235 4.11089 10.0387 4.20044C10.095 4.28998 10.1249 4.3936 10.125 4.49938V13.4994C10.1248 13.6052 10.0948 13.7089 10.0384 13.7984C9.98199 13.888 9.90144 13.9598 9.80603 14.0056C9.71062 14.0515 9.60422 14.0694 9.49905 14.0575C9.39388 14.0455 9.29421 14.0042 9.2115 13.9381L6.55312 11.8119H3.9375C3.78832 11.8119 3.64524 11.7526 3.53975 11.6471C3.43426 11.5416 3.375 11.3986 3.375 11.2494V6.74938C3.375 6.60019 3.43426 6.45712 3.53975 6.35163C3.64524 6.24614 3.78832 6.18688 3.9375 6.18688H6.55312L9.2115 4.06063C9.29427 3.99446 9.39404 3.95303 9.49933 3.94108C9.60462 3.92914 9.71114 3.94718 9.80662 3.99313ZM9 5.66938L7.101 7.18813C7.00143 7.26801 6.87765 7.31165 6.75 7.31188H4.5V10.6869H6.75C6.87765 10.6871 7.00143 10.7307 7.101 10.8106L9 12.3294V5.66938Z" fill="currentColor" />
      <path d="M12.0454 12.5794C12.5162 12.1098 12.8895 11.5518 13.144 10.9375C13.3984 10.3232 13.529 9.66462 13.5281 8.99967C13.529 8.33473 13.3984 7.67616 13.144 7.06183C12.8895 6.4475 12.5162 5.88951 12.0454 5.41992L11.25 6.2153C11.6162 6.58055 11.9065 7.01457 12.1044 7.4924C12.3023 7.97024 12.4038 8.48248 12.4031 8.99967C12.4031 10.0864 11.9621 11.0708 11.25 11.784L12.0454 12.5794Z" fill="currentColor" />
    </svg>
  );
}

export default Audio;
