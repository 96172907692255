import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { nullToString } from 'lib/utils';
import CourseForm from 'components/curriculum/CourseForm';
import CourseFormSchema from 'components/curriculum/CourseForm/validation';
import Spinner from 'components/shared/Spinner';
import { Formik } from 'formik';
import { UPDATE_COURSE_TYPE, GET_COURSE_TYPE_FORM_DATA } from 'store/courseTypes/actions';

function CourseTypeSettingsTabPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const courseTypesArr = useSelector((state) => Object.values(state.courseTypes.byId).filter((ct) => !ct.hideFromCurriculumTab));
  const courseType = useSelector((state) => state.courseTypes.byId[id]);
  const organization = useSelector((state) => state.organizations.byId[courseType?.organizationId]);
  const courseTypeOptions = useMemo(() => courseTypesArr.filter((ct) => ct.organizationId === organization?.id).map((ct) => ({ label: ct.name, value: ct.id })), [courseTypesArr, organization?.id]);

  const handleSave = useCallback(
    (values, actions) => {
      dispatch(UPDATE_COURSE_TYPE.request({ id, values }, { formikActions: actions }));
    },
    [dispatch, id],
  );

  useEffect(() => {
    dispatch(GET_COURSE_TYPE_FORM_DATA.request({ organizationId: courseType?.organizationId }));
  }, [courseType?.organizationId, dispatch]);

  return (
    <div className="bg-white px-4 flex-grow-1">
      {courseType ? (
        <Formik
          onSubmit={handleSave}
          initialValues={nullToString(courseType)}
          validationSchema={CourseFormSchema}
          enableReinitialize
        >
          <div className="row">
            <div className="col-xxl-8 col-lg-10 mb-4 mt-4">
              <CourseForm courseTypeOptions={courseTypeOptions} mode="edit" />
            </div>
          </div>
        </Formik>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default CourseTypeSettingsTabPage;
