import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_PHRASEBOOK_NEW_PATH, CURRICULUM_COURSE_PHRASEBOOK_PATH, CURRICULUM_COURSE_PHRASEBOOK_SHOW_PATH, CURRICULUM_COURSE_SHOW_PATH, CURRICULUM_COURSE_TYPE_SHOW_PATH, CURRICULUM_PATH } from 'lib/routerPaths';
import { LOAD_COURSE, LOAD_COURSE_PHRASEBOOK_ENTRIES } from 'store/courses/actions';
import { selectCourseById } from 'store/courses/selectors';
import { LOAD_COURSE_TYPES } from 'store/courseTypes/actions';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import CurriculumTemplate from 'components/templates/CurriculumTemplate';
import SanyasIcon from 'components/shared/SanyasIcon';
import PhrasebookEntryForm from 'components/curriculum/Phrasebook/PhrasebookEntryForm';
import HowToUsePhrases from 'components/curriculum/Phrasebook/HowToUsePhrases';
import PhrasebookEntry from 'components/curriculum/Phrasebook/PhrasebookEntry';
import ClearableInput from 'components/shared/ClearableInput/ClearableInput';
import Skeleton from 'components/shared/Skeleton';

function CoursePhrasebookPage() {
  const { courseId } = useParams();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const course = useSelector((state) => selectCourseById(state, courseId));
  const courseType = useSelector((state) => selectCourseTypeById(state, course?.courseTypeId));
  const { allIds, byId } = useSelector((state) => state.courses.phrasebookEntries);
  const [filteredIds, setFilteredIds] = useState([]);

  useEffect(() => {
    dispatch(LOAD_COURSE_TYPES.request());
  }, [dispatch]);

  useEffect(() => {
    if (!course.id) {
      dispatch(LOAD_COURSE.request(courseId));
    }
  }, [course?.id, courseId, dispatch]);

  useEffect(() => {
    dispatch(LOAD_COURSE_PHRASEBOOK_ENTRIES.request({ courseId, workspace: 'curriculum' }));
  }, [courseId, dispatch]);

  useEffect(() => {
    setFilteredIds(allIds);
  }, [allIds]);

  const handleChangeFilter = useCallback((e) => {
    if (!allIds?.length) return; // Nothing to filter

    const { value } = e.target;

    if (value?.length > 1) {
      const newFilteredIds = allIds.filter((id) => {
        const entry = byId[id];
        return entry.title.toLowerCase().includes(value.toLowerCase());
      });

      setFilteredIds(newFilteredIds);
    } else {
      setFilteredIds(allIds);
    }
  }, [allIds, byId]);

  return (
    <CurriculumTemplate className="bg-white">
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <SanyasIcon name="book" size="3x" />
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Courses', href: CURRICULUM_PATH },
                { label: courseType?.name || <Skeleton classes="text" />, href: buildRoutePath(CURRICULUM_COURSE_TYPE_SHOW_PATH, { id: courseType?.id }) },
                { label: course?.description || <Skeleton classes="text" />, href: buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course?.id }) },
              ]}
              ignoreLast
            />

            <h1>{courseType?.name}</h1>
          </div>
        </div>
      </div>

      <div className="flush bg-white">
        <div className="row mx-0 border-bottom">
          <div className="col-6 border-end px-0">
            <div className="d-flex align-items-center py-3 px-4 border-bottom">
              <h4 className="m-0">Phrasebook entries</h4>
              <Link
                className="btn btn-primary btn-sm ms-auto"
                to={buildRoutePath(CURRICULUM_COURSE_PHRASEBOOK_NEW_PATH, { courseId })}
              >
                New entry
              </Link>
            </div>

            <ul className="list-group list-group-flush">
              <li className="list-group-item ps-4">
                <Link
                  className="btn-plain d-inline-block w-100 py-3 px-0 text-start text-black"
                  to={buildRoutePath(CURRICULUM_COURSE_PHRASEBOOK_PATH, { courseId })}
                >
                  <h4 className="m-0">
                    <SanyasIcon name="infoCircleFill" size="lg" className="align-top pe-1 text-info" />
                    How to use Phrases
                  </h4>
                </Link>
              </li>
              <li className="list-group-item ps-3">
                <ClearableInput placeholder="Filter by title" onChange={handleChangeFilter} />
              </li>

              {(allIds?.length > 0 && !filteredIds?.length) && (
                <li className="list-group-item ps-4">No entries found</li>
              )}
              {filteredIds?.map((entry) => (
                <PhrasebookEntry key={`entry-${entry}`} entry={byId?.[entry]} />
              ))}
            </ul>
          </div>

          <div className="col-6 px-0">
            <Switch>
              <Route path={[CURRICULUM_COURSE_PHRASEBOOK_NEW_PATH, CURRICULUM_COURSE_PHRASEBOOK_SHOW_PATH]}>
                <PhrasebookEntryForm />
              </Route>
              <Route path={path}>
                <>
                  {/* Form header is 64px */}
                  <div className="d-flex align-items-center py-3 px-4 border-bottom" style={{ minHeight: 64 }}>
                    <h4 className="m-0">How to use Phrases</h4>
                  </div>

                  <div className="py-3 px-4">
                    <HowToUsePhrases />
                  </div>
                </>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </CurriculumTemplate>
  );
}

export default CoursePhrasebookPage;
