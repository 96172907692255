import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { FACILITATOR_ARTICLE_PATH } from 'lib/routerPaths';
import { LOAD_COURSE, LOAD_COURSE_ARTICLES } from 'store/courses/actions';
import { selectCourseById } from 'store/courses/selectors';
import { LOAD_FACILITATOR_COURSE_TYPES } from 'store/courseTypes/actions';
import Alert from 'react-bootstrap/Alert';
import CourseShowProvider from 'pages/curriculum/CourseShowPage/context';
import CurriculumCourseTemplate from 'components/templates/CurriculumCourseTemplate';
import ArticleNav from 'components/curriculum/SelfCare/ArticleNav';
import Introduction from 'components/curriculum/SelfCare/Introduction';
import Article from 'components/curriculum/SelfCare/Article';

function CourseSelfCarePage() {
  const { courseId } = useParams();
  const { path } = useRouteMatch();
  const course = useSelector((state) => selectCourseById(state, courseId));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LOAD_FACILITATOR_COURSE_TYPES.request());
  }, [dispatch]);

  useEffect(() => {
    if (!course?.id) {
      dispatch(LOAD_COURSE.request(courseId));
    }
  }, [course?.id, courseId, dispatch]);

  useEffect(() => {
    dispatch(LOAD_COURSE_ARTICLES.request({ courseId, workspace: 'facilitator' }));
  }, [courseId, dispatch]);

  return (
    <CourseShowProvider>
      <CurriculumCourseTemplate
        Navigation={ArticleNav}
      >
        <Switch>
          <Route path={FACILITATOR_ARTICLE_PATH}>
            <Article />
          </Route>
          <Route path={path}>
            <Alert variant="light" className="d-flex">
              <Introduction />
            </Alert>
          </Route>
        </Switch>
      </CurriculumCourseTemplate>
    </CourseShowProvider>
  );
}

export default CourseSelfCarePage;
