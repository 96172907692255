import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectVoucherById } from 'store/vouchers/selectors';

function VouchersListItem({ voucherId }) {
  const { id, email, region, notes, healthAuthorityName, markTrainingPaymentAs, organizationType, createdAt, state: voucherState, courseTypeName, code } = useSelector((state) => selectVoucherById(state, voucherId));
  const [showMore, toggleMore] = useState(false);

  const setStateClass = useCallback((n) => {
    switch (n) {
      case 'Unused':
      case 'Waived':
        return 'bg-light text-dark';
      case 'Voided':
      case 'Void':
        return 'bg-warning text-dark';
      case 'Used':
      case 'Paid':
        return 'bg-success';
      default:
        return 'bg-success';
    }
  }, []);

  if (!voucherId) return null;

  return (
    <tr>
      <td>
        <div>
          <strong>{email}</strong>
        </div>

        <div>
          <small className="text-muted">
            Created on
            {' '}
            {createdAt}
          </small>
        </div>

        <button type="button" onClick={() => toggleMore(!showMore)} className="btn btn-sm btn-link m-0 p-0 text-info">
          {showMore ? 'Close' : 'More'}
          {' '}
          {showMore ? (<i className="fa fa-caret-up" />) : (<i className="fa fa-caret-down" />)}
        </button>

        {showMore && (
          <div className="card mt-2">
            <div className="card-body">
              {id && <div>{`id: ${id}`}</div>}
              {region && <div>{`Region: ${region}`}</div>}
              {healthAuthorityName && <div>{`Health Authority: ${healthAuthorityName}`}</div>}
              {organizationType && <div>{`Organization Type: ${organizationType}`}</div>}

              {code && <div className="text-muted"><small>{code}</small></div>}
              {notes && <div>{notes}</div>}
            </div>
          </div>
        )}
      </td>
      <td><div className={`badge ${setStateClass(voucherState)}`}>{voucherState}</div></td>
      <td><div className={`badge ${setStateClass(markTrainingPaymentAs)}`}>{markTrainingPaymentAs}</div></td>
      <td>{courseTypeName}</td>
      <td>
        <div className="d-flex justify-content-between">
          <Link to={`/registrar/vouchers/${id}`} className="btn btn-dark">
            View
          </Link>
        </div>
      </td>
    </tr>
  );
}

VouchersListItem.propTypes = {
  voucherId: PropTypes.number.isRequired,
};

export default VouchersListItem;
