import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _throttle from 'lodash/throttle';
import { ComponentPropType } from 'lib/propTypes';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import CurriculumEditNav from 'components/curriculum/CurriculumEditNav';
import Breadcrumbs from 'pages/curriculum/CourseShowPage/Breadcrumbs';
import Skeleton from 'components/shared/Skeleton';

function CurriculumCourseTemplate({ Navigation, children }) {
  const { pathname } = useLocation();
  const { course } = useCourseShowContext();
  const courseType = useSelector((state) => selectCourseTypeById(state, course?.courseTypeId));
  const pageHeaderRef = useRef(null);
  const sidebarRef = useRef(null);
  const envBannerHeight = document.getElementById('env-banner')?.offsetHeight || 0;
  const navbarHeight = document.querySelector('nav.navbar')?.offsetHeight || 0;
  const maxHeight = window.innerHeight - 1; // magically adjust
  const initBaseHeight = maxHeight - envBannerHeight - navbarHeight;
  const [baseHeight, setBaseHeight] = useState(initBaseHeight);

  const throttledScroll = useMemo(() => _throttle(() => {
    if (!sidebarRef.current) return;

    const newHeight = `${Math.min(maxHeight, baseHeight + window.scrollY)}px`;
    if (sidebarRef.current.style.height !== newHeight) {
      sidebarRef.current.style.height = newHeight;
    }
  }, 150), [baseHeight, maxHeight]);

  const handleScroll = useCallback(throttledScroll, [throttledScroll]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    setTimeout(() => {
      setBaseHeight(initBaseHeight - (pageHeaderRef.current?.offsetHeight || 0));
    }, 0);
  }, [pathname, initBaseHeight, pageHeaderRef]);

  return (
    <div className="container-fluid">
      <div className="row border-bottom">
        <div className="col p-0">
          <div className="container-xxxl">
            <div ref={pageHeaderRef} className="row align-items-center">
              <div className="col-md-3">
                <h1 className="h3 m-0 text-truncate">{courseType?.name || <Skeleton classes="title mt-3" />}</h1>
              </div>

              <div className="col-md-9 border-start">
                <Breadcrumbs selfCare={pathname.includes('self_care')} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col p-0">
          <div className="container-xxxl">
            <div className="row">
              <div
                ref={sidebarRef}
                className="col-md-3 m-0 p-0 d-block sticky-top overflow-hidden"
                style={{ height: `${baseHeight}px`, transition: 'height 0.2s ease-in' }}
              >
                <Navigation />
              </div>

              <div className="col-md-9 py-3 px-4 border-start">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CurriculumCourseTemplate.defaultProps = {
  Navigation: CurriculumEditNav,
};

CurriculumCourseTemplate.propTypes = {
  Navigation: PropTypes.func,
  children: ComponentPropType.isRequired,
};

export default CurriculumCourseTemplate;
