/* Action creator helpers that generate FSA-compatible action creators */

/**
 * Creates an FSA-compatible action creators for the three stages of an async action.
 *
 * Request:
 *   Dispatch this action when the async action is initiated.
 *   You can attach parameters to the action.
 *   Usage example:
 *       dispatch(GET_USERS.request({organizationId: 1}))
 *
 * Success:
 *   Dispatch this action when the async action has succeeded.
 *   You can attach parameters to the action.
 *   Usage example:
 *       dispatch(GET_USERS.success({ users: [...]}))
 *
 * Error:
 *   Use the params to attach details about why this action failed, for
 *   example validation errors.
 *   Usage example:
 *       yield put(CREATE_FACILITATOR.error({email: "has already been taken"}))
 */
export function asyncAction(actionName) {
  const actionTypes = {
    REQUEST: `${actionName}_REQUEST`,
    SUCCESS: `${actionName}_SUCCESS`,
    ERROR: `${actionName}_ERROR`,
  };

  return {
    ...actionTypes,
    request: simpleActionFn(actionTypes.REQUEST),
    success: simpleActionFn(actionTypes.SUCCESS),
    error: simpleActionErrorFn(actionTypes.ERROR),
  };
}

export function syncAction(actionName) {
  return {
    SYNC: `${actionName}_SYNC`,
    action: simpleActionFn(`${actionName}_SYNC`),
  };
}

function simpleActionFn(actionType) {
  return (payload = {}, meta = {}) => ({ type: actionType, payload, meta });
}

function simpleActionErrorFn(actionType) {
  return (payload = {}, meta = {}) => ({ type: actionType, error: true, payload, meta });
}
