import React from 'react';
import { useSelector } from 'react-redux';
import { REDUX_STATUS } from 'lib/constants';
import { LocalePropType } from 'lib/propTypes';
import ErrorMessage from 'components/shared/ErrorMessage';
import QuestionSkeleton from 'components/curriculum/JournalTask/QuestionSkeleton';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import SanyasIcon from 'components/shared/SanyasIcon';
import JournalQuestion from './JournalQuestion';

function JournalTask({ locale }) {
  const { currentTask } = useCourseShowContext();
  const { data: questions, status, error } = useSelector((state) => state.courses.questions);

  return (
    <div className="mt-4">
      <h3>Journal</h3>

      {status === REDUX_STATUS.PENDING && <QuestionSkeleton />}
      {status === REDUX_STATUS.SUCCESS && (
        <>
          {questions.map((question, index) => (
            <JournalQuestion key={question.id} index={index} question={question} locale={locale} />
          ))}
        </>
      )}
      {status === REDUX_STATUS.ERROR && <ErrorMessage error={error} />}

      <div>
        <SanyasIcon
          name={currentTask?.silenceJournalNotification ? 'checkBox' : 'square'}
          className={'me-1 {currentTask?.silenceJournalNotification ? \'text-primary\' : \'text-secondary\'}'}
        />
        Don’t trigger journal notification
        <br />
        <span className="text-small text-secondary">
          If this option is checked, the facilitators will not receive a ‘red dot’ notification when the participant
          responds.
        </span>
      </div>
    </div>
  );
}

JournalTask.defaultProps = {
  locale: 'en',
};

JournalTask.propTypes = {
  locale: LocalePropType,
};

export default JournalTask;
