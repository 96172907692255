import React from 'react';
import PropTypes from 'prop-types';
import { PhrasebookEntryPropType } from 'lib/propTypes';
import Button from 'react-bootstrap/Button';

function PhrasebookEntry({ entry, setSelectedEntry }) {
  if (!entry) return null;

  return (
    <li className="list-group-item ps-4 position-relative">
      <Button variant={null} className="w-100 m-0 py-2 ps-0 text-start h4 text-black" onClick={() => setSelectedEntry(entry.id)}>
        {entry.title}
      </Button>
    </li>
  );
}

PhrasebookEntry.defaultProps = {
  entry: null,
};

PhrasebookEntry.propTypes = {
  entry: PhrasebookEntryPropType,
  setSelectedEntry: PropTypes.func.isRequired,
};

export default PhrasebookEntry;
