import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Draggable } from '@hello-pangea/dnd';
import { REDUX_STATUS } from 'lib/constants';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import Accordion from 'react-bootstrap/Accordion';
import AccordionButton from 'react-bootstrap/AccordionButton';
import SanyasIcon from 'components/shared/SanyasIcon';
import Spinner from 'react-bootstrap/Spinner';
import ModuleName from './ModuleName';
import ModuleTasks from './ModuleTasks';

/* eslint-disable react/jsx-props-no-spreading */

function Module({ sectionId, index }) {
  const { pathname } = useLocation();
  const { course, currentSection, currentTask } = useCourseShowContext();
  const sections = useSelector((state) => state.courses.sections) || {};
  const { id: activeId, status: activeStatus } = useSelector((state) => state.courses.section) || {};
  const section = sections[sectionId];
  const pending = sectionId === activeId && activeStatus === REDUX_STATUS.PENDING;
  const newTask = pathname.endsWith('tasks/new');

  return (
    <Draggable draggableId={`${sectionId}`} index={index} isDragDisabled={course?.isLocked || pending}>
      {(provided, snapshot) => (
        <Accordion.Item eventKey={sectionId} ref={provided.innerRef} {...provided.draggableProps}>
          <h3
            className={
              `accordion-header d-flex align-items-center
              ${(!newTask && !currentTask && sectionId === currentSection?.id) || snapshot.isDragging ? 'accordion-header-active' : ''}`
            }
          >
            {(!course?.isLocked && !pending) && (
              <span
                className={`cursor-grab p-1 outline-visible ${snapshot.isDragging ? 'visible' : ''}`}
                {...provided.dragHandleProps}
              >
                <SanyasIcon name="gripVertical" style={{ width: '10px' }} />
                <span className="sr-only">Drag to reorder</span>
              </span>
            )}

            <ModuleName section={section} index={index} eventKey={sectionId} disabled={pending} />

            { pending && (
              <div>
                <Spinner variant="danger" size="sm" animation="border" className="me-1" role="status" />
                <span className="sr-only">Deleting</span>
              </div>
            )}

            { sectionId !== activeId && (
              <AccordionButton className="w-auto" style={{ backgroundColor: 'transparent' }}>
                <span className="sr-only">Toggle</span>
              </AccordionButton>
            )}
          </h3>

          <ModuleTasks section={section} />

        </Accordion.Item>
      )}
    </Draggable>
  );
}

Module.propTypes = {
  sectionId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default Module;
