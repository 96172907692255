import { asyncAction, syncAction } from 'lib/actionCreatorCreators';

// list
export const GET_MESSAGES = asyncAction('GET_MESSAGES');

// show
export const GET_CONVERSATION = asyncAction('GET_CONVERSATION');
export const MARK_CONVERSATION_AS_READ = asyncAction('MARK_CONVERSATION_AS_READ');
export const START_COMPOSING_REPLY = syncAction('START_COMPOSING_REPLY');
export const CANCEL_COMPOSING_REPLY = syncAction('CANCEL_COMPOSING_REPLY');
export const CLEAR_SHOW_PAGE = syncAction('CLEAR_SHOW_PAGE');
export const SHOW_EDIT_SUBJECT = syncAction('SHOW_EDIT_SUBJECT');
export const HIDE_EDIT_SUBJECT = syncAction('HIDE_EDIT_SUBJECT');
export const CHANGE_SUBJECT = syncAction('CHANGE_SUBJECT');
export const UPDATE_SUBJECT = asyncAction('UPDATE_SUBJECT');

// compose
export const GET_SUB_GROUPS = asyncAction('GET_SUB_GROUPS');
export const UPDATE_SELECTED_RECIPIENTS = syncAction('UPDATE_SELECTED_RECIPIENTS');
export const SET_PRESELECTED_USER_ID = syncAction('SET_PRESELECTED_USER_ID');
export const SEND_MESSAGES = asyncAction('SEND_MESSAGES');
export const CREATE_REPLY = asyncAction('CREATE_REPLY');
