/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LEGACY_APP_DOMAIN } from 'config';
import format from 'date-fns/format';
import { SHORT_DATE } from 'lib/dateFormats';
import Badge from 'components/shared/Badge/Badge';
import parseISO from 'date-fns/parseISO';
import Table from 'react-bootstrap/Table';
import { sortedSubGroups } from 'store/facilitatorDashboard/selectors';
import { LOAD_FACILITATOR_DISCUSSION_BOARDS, LOAD_INITIAL_STATE } from 'store/facilitatorDashboard/actions';

function DiscussionBoardsTable() {
  const currentUser = useSelector((state) => state.currentUser);
  const { loadingSubGroups } = useSelector((state) => state.facilitatorDashboard);
  const subGroups = useSelector((state) => sortedSubGroups(state));
  const loadSubGroups = subGroups === null;
  const dispatch = useDispatch();

  useEffect(() => {
    if (loadSubGroups && currentUser?.id && !loadingSubGroups) {
      dispatch(LOAD_INITIAL_STATE.request());
      dispatch(LOAD_FACILITATOR_DISCUSSION_BOARDS.request({ id: currentUser?.id }));
    }
  }, [currentUser?.id, dispatch, loadSubGroups, loadingSubGroups]);

  const table = useMemo(() => (
    !loadSubGroups && subGroups.map((subGroup) => Object.keys(subGroup.discussionBoardNotifications).map((key) => (
      <tr key={key}>
        <td className="text-start">
          <Badge
            variant="blue"
            title={subGroup.discussionBoardNotifications[key].internalName}
          />
        </td>
        <td className="fw-semibold text-start">{subGroup.groupName}</td>
        <td className="text-start">{subGroup.subGroupName}</td>
        <td className="text-nowrap text-start">
          {format(parseISO(subGroup.groupBeginSessionAt), SHORT_DATE)}
        </td>
        <td className="text-nowrap text-start">
          {format(parseISO(subGroup.groupCloseSessionAt), SHORT_DATE)}
        </td>
        <td className="text-nowrap text-start">
          <Badge
            variant="red"
            title={subGroup.discussionBoardNotifications[key].highPriorityTotal}
            className="me-1"
          />
          <Badge
            variant="orange"
            title={subGroup.discussionBoardNotifications[key].mediumPriorityTotal}
            className="me-1"
          />
          <Badge
            variant="green"
            title={subGroup.discussionBoardNotifications[key].lowPriorityTotal}
            className="me-1"
          />
        </td>
        <td>
          <a className="text-decoration-none test-start" href={`${LEGACY_APP_DOMAIN}/groups/${subGroup.groupId}/sub_groups/${subGroup.subGroupId}/discussion_tasks/${subGroup.discussionBoardNotifications[key].discussionBoardId}`} target="_blank" rel="noreferrer">View</a>
        </td>
      </tr>
    )))
  ), [loadSubGroups, subGroups]);

  return (
    <Table bordered hover className="bg-white mb-4 mt-4 sn-table">
      <thead>
        <tr>
          <th className="text-start">Task</th>
          <th className="text-start">Group</th>
          <th className="text-start">Pod</th>
          <th className="text-start">Starting</th>
          <th className="text-start">Closing</th>
          <th className="text-start">Unread posts</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {table}
      </tbody>
    </Table>
  );
}

export default DiscussionBoardsTable;
