import React, { Fragment, useCallback, useState } from 'react';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { GroupMembershipPropType } from 'lib/propTypes';
import { UPDATE_INSUFFICIENT_PARTICIPATION } from 'store/groupMemberships/actions';
import SanyasIcon from 'components/shared/SanyasIcon';
import { Button } from 'react-bootstrap';
import Confirmation from 'components/shared/Modal/Confirmation';
import { useDispatch } from 'react-redux';

const IP_DATA = [
  {
    label: 'Mark as Insufficient Participation',
    description: 'This will mark the participant as insufficient participation and send an email to their manager.',
    text: 'Are you sure you want to mark this participant as having insufficient participation?',
    textSecondary: 'This will send an email to their manager, mark their seat as burnt and make the participant ineligible for a certificate.',
    confirmText: 'Submit',
  },
  {
    label: 'Remove Insufficient Participation',
    description: 'This will remove the insufficient participation status for this participant.',
    text: 'Are you sure you want to remove the insufficient participation status for this participant?',
    confirmText: 'Remove',
  },
];

function InsufficientParticipation({ gm }) {
  const workspace = useWorkspace();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const dispatch = useDispatch();
  const ipData = IP_DATA[gm?.isMarkedAsInsufficientParticipation ? 1 : 0];

  const handleConfirmModalClose = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  const handleUpdateInsufficientParticipation = () => dispatch(UPDATE_INSUFFICIENT_PARTICIPATION.request({
    id: gm.id,
    userId: gm.userId,
    isMarkedAsInsufficientParticipation: !gm.isMarkedAsInsufficientParticipation,
  }));

  if (!gm) return null;

  if (workspace.id === 'registrar') {
    if (gm.isMarkedAsInsufficientParticipation) {
      return (
        <>
          {/* TODO Might need to rethink this border/divider if there is more content */}
          <span className="d-inline-block w-100 mb-3 border-top" style={{ height: 1 }} />
          <li className="d-flex align-items-baseline mb-3 px-3 text-primary">
            <SanyasIcon name="infoCircle" className="me-2 text-danger" />
            Marked as Insufficient Participation
          </li>
        </>
      );
    }
    return null;
  }

  return (
    <Fragment key={ipData.label}>
      <li className="d-flex align-items-baseline mb-3 px-3 text-primary">
        <SanyasIcon name="infoCircle" className="me-2" />
        <span>
          <Button variant="link" className="d-block btn-plain p-0 text-start" onClick={() => setConfirmationModalOpen(true)}>
            {ipData.label}
          </Button>
          <span className="d-block text-small text-secondary">
            {ipData.description}
          </span>
        </span>
      </li>

      <Confirmation
        title={ipData.label}
        open={confirmationModalOpen}
        close={handleConfirmModalClose}
        text={ipData.text}
        textSecondary={ipData.textSecondary}
        confirmAction={handleUpdateInsufficientParticipation}
        confirmText={ipData.confirmText}
        cancelText={false}
        delay={400}
      />
    </Fragment>
  );
}

InsufficientParticipation.defaultProps = {
  gm: null,
};

InsufficientParticipation.propTypes = {
  gm: GroupMembershipPropType,
};

export default InsufficientParticipation;
