import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import { CREATE_GLOSSARY_ENTRY, DELETE_GLOSSARY_ENTRY, SAVE_GLOSSARY_ENTRY } from 'store/courses/actions';
import { Button, Spinner } from 'react-bootstrap';
import InputField from 'components/shared/FormFields/InputField';
import TextareaField from 'components/shared/FormFields/TextareaField';
import SwitchField from 'components/shared/FormFields/SwitchField';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import ErrorMessage from 'components/shared/ErrorMessage';
import { selectGlossaryEntryById, selectGlossaryEntryTerms } from 'store/courses/selectors';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_GLOSSARY_PATH, CURRICULUM_COURSE_GLOSSARY_SHOW_PATH } from 'lib/routerPaths';
import GlossaryEntryFormHeader from './GlossaryEntryFormHeader';

const FormSchema = Yup.object().shape({
  term: Yup.string()
    .required('Required')
    .matches(/^[A-zÀ-ú0-9-_ ]+$/, 'Incorrect format')
    .test(
      'unique',
      'Term must be unique. This term already exists in this Course.',
      (value, context) => {
        const { isFrench, terms } = context.parent;
        return !terms.includes(`${value}${isFrench ? 'fr' : 'en'}`);
      },
    ),
  definition: Yup.string()
    .required('Required'),
  isFrench: Yup.bool(),
});

function GlossaryEntryForm() {
  const { courseId, glossaryId } = useParams();
  const activeEntry = useSelector((state) => state.courses.glossaryEntry);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const glossaryEntry = useSelector((state) => selectGlossaryEntryById(state, glossaryId));
  const glossaryEntryTerms = useSelector((state) => selectGlossaryEntryTerms(state, glossaryId));

  const initialValues = {
    id: glossaryEntry?.id ?? null,
    courseId: Number(courseId),
    term: glossaryEntry?.term ?? '',
    definition: glossaryEntry?.definition ?? '',
    isFrench: glossaryEntry?.isFrench ?? false,
    terms: glossaryEntryTerms ?? [],
  };

  useEffect(() => {
    if (activeEntry?.status === REDUX_STATUS.SUCCESS) {
      if (activeEntry?.success === REDUX_SUCCESS.CREATED) {
        history.push(buildRoutePath(CURRICULUM_COURSE_GLOSSARY_SHOW_PATH, { courseId, glossaryId: activeEntry?.id }));
      }
      if (activeEntry?.success === REDUX_SUCCESS.DELETED) {
        history.push(buildRoutePath(CURRICULUM_COURSE_GLOSSARY_PATH, { courseId }));
      }
    }
  }, [activeEntry, courseId, history]);

  const handleDelete = useCallback(() => {
    if (glossaryId) {
      dispatch(DELETE_GLOSSARY_ENTRY.request(glossaryId));
    }
  }, [glossaryId, dispatch]);

  const handleSave = useCallback((values, actions) => {
    const { id, keys, ...sendValues } = values;
    if (glossaryId > 0) {
      dispatch(SAVE_GLOSSARY_ENTRY.request({ ...sendValues, id }, { formikActions: actions }));
    } else {
      dispatch(CREATE_GLOSSARY_ENTRY.request(sendValues, { formikActions: actions }));
    }
  }, [glossaryId, dispatch]);

  return (
    <div className="sticky-top">

      <Formik
        onSubmit={handleSave}
        validationSchema={FormSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ values }) => (
          <Form key={`form-${glossaryId}`}>
            <GlossaryEntryFormHeader entry={values} setShowDeleteModal={setShowDeleteModal} />

            <ErrorMessage error={activeEntry?.error} className="mt-2 mx-4" />

            <div className="py-3 px-4">
              <SwitchField name="isFrench" label="This is a French entry" />
              <InputField name="term" placeholder="Term title" />
              <TextareaField name="definition" placeholder="Enter the term definition" />
            </div>
          </Form>
        )}
      </Formik>

      <DefaultModal
        key={`delete-${glossaryId}`}
        size="md"
        isOpen={showDeleteModal}
        header="Delete entry?"
        onClose={() => setShowDeleteModal(false)}
        footerComponent={(
          <Button
            className="d-flex align-items-center"
            disabled={activeEntry?.status === REDUX_STATUS.PENDING}
            variant="danger"
            onClick={handleDelete}
          >
            Delete
            {activeEntry?.status === REDUX_STATUS.PENDING && (
              <Spinner
                size="sm"
                className="ms-1"
                animation="border"
                role="status"
              />
            )}
          </Button>
      )}
      >
        <p
          className="bg-sn-sand flush top py-1"
        >
          Deleting this Glossary entry will delete
          {' '}
          <strong>all content</strong>
          {' '}
          within the entry as well.
          <br />
          This action cannot be undone.
        </p>

        <p className="d-flex justify-content-between">
          <strong>Confirm</strong>
          <span className="ps-2">Please confirm that you want to delete this entry.</span>
        </p>
      </DefaultModal>
    </div>
  );
}

export default GlossaryEntryForm;
