import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType } from 'lib/propTypes';
import SimpleToolTip from '../SimpleToolTip/SimpleToolTip';
import SanyasIcon from '../SanyasIcon';

function InfoTextToolTip({ text, className }) {
  if (!text) return null;

  return (
    <SimpleToolTip
      placement="top"
      text={text}
    >
      <span className="text-info">
        <SanyasIcon name="infoCircle" className={className ?? 'ms-2'} />
      </span>
    </SimpleToolTip>
  );
}

InfoTextToolTip.defaultProps = {
  text: null,
  className: null,
};

InfoTextToolTip.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    ComponentPropType,
  ]),
  className: PropTypes.string,
};

export default InfoTextToolTip;
