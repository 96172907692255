import {
  CANCEL_COMPOSING_REPLY, CHANGE_SUBJECT, CLEAR_SHOW_PAGE, CREATE_REPLY, GET_CONVERSATION, GET_MESSAGES, GET_SUB_GROUPS,
  HIDE_EDIT_SUBJECT, MARK_CONVERSATION_AS_READ, SET_PRESELECTED_USER_ID, SHOW_EDIT_SUBJECT, START_COMPOSING_REPLY,
  UPDATE_SELECTED_RECIPIENTS, UPDATE_SUBJECT,
} from 'store/actions';

const initialState = {
  list: {
    conversations: null,
    conversationIds: null,
    users: null,
    page: null,
    perPage: null,
    totalPages: null,
  },
  show: {
    conversation: null,
    users: null,
    composingReply: false,
    submittingReply: false,
    submitReplyError: null,
    editingSubject: false,
    newSubject: null,
  },
  compose: {
    availableRecipients: null,
    availableSubGroups: null,
    selectedRecipients: null,
    subGroup: null,
    preSelectedUserId: null,
    preSelectedRecipient: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGES.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload.data,
          ...action.payload.meta,
        },
      };
    case GET_CONVERSATION.SUCCESS:
      return {
        ...state,
        show: {
          ...state.show,
          conversation: action.payload.conversation,
          users: action.payload.users,
        },
      };
    case CLEAR_SHOW_PAGE.SYNC:
      return {
        ...state,
        show: {
          ...initialState.show,
        },
      };
    case SHOW_EDIT_SUBJECT.SYNC:
      return {
        ...state,
        show: {
          ...state.show,
          editingSubject: true,
        },
      };
    case HIDE_EDIT_SUBJECT.SYNC:
      return {
        ...state,
        show: {
          ...state.show,
          editingSubject: false,
        },
      };
    case CHANGE_SUBJECT.SYNC:
      return {
        ...state,
        show: {
          ...state.show,
          newSubject: action.payload,
        },
      };
    case UPDATE_SUBJECT.SUCCESS:
      return {
        ...state,
        show: {
          ...state.show,
          conversation: {
            ...state.show.conversation,
            subject: state.show.newSubject,
          },
          editingSubject: false,
          newSubject: null,
        },
      };
    case MARK_CONVERSATION_AS_READ.SUCCESS:
      return {
        ...state,
        show: {
          ...state.show,
          conversation: {
            ...state.show.conversation,
            messages: [
              ...state.show.conversation.messages.map((m) => ({ ...m, readAt: new Date() })),
            ],
          },
        },
      };
    case START_COMPOSING_REPLY.SYNC:
      return {
        ...state,
        show: {
          ...state.show,
          composingReply: true,
          submitReplyError: null,
        },
      };
    case CANCEL_COMPOSING_REPLY.SYNC:
      return {
        ...state,
        show: {
          ...state.show,
          composingReply: false,
          submittingReply: false,
          submitReplyError: null,
        },
      };
    case CREATE_REPLY.REQUEST:
      return {
        ...state,
        show: {
          ...state.show,
          composingReply: false,
          submittingReply: true,
        },
      };
    case CREATE_REPLY.SUCCESS:
      return {
        ...state,
        show: {
          ...state.show,
          conversation: {
            ...state.show.conversation,
            messages: [...state.show.conversation.messages, action.payload.message],
          },
          submittingReply: false,
          composingReply: false,
          submitReplyError: null,
        },
      };
    case CREATE_REPLY.ERROR:
      return {
        ...state,
        show: {
          ...state.show,
          submittingReply: false,
          composingReply: true,
          submitReplyError: 'Your message could not be sent.',
        },
      };
    case GET_SUB_GROUPS.SUCCESS: {
      const preSelectedRecipient = (
        state.compose.preSelectedUserId
          && action.payload.participants.find((p) => p.userId === state.compose.preSelectedUserId)
      );

      return {
        ...state,
        compose: {
          ...state.compose,
          availableSubGroups: action.payload.subGroups,
          availableRecipients: action.payload.participants,
          preSelectedRecipient,
        },
      };
    }
    case UPDATE_SELECTED_RECIPIENTS.SYNC: {
      const somePodsSelected = action.payload.length > 0;
      const onlyPodsSelected = somePodsSelected && !!action.payload[0].subGroupId;
      const selectedRecipients = onlyPodsSelected
        ? { subGroupIds: action.payload.map((v) => v.subGroupId) }
        : { groupMembershipIds: action.payload.map((v) => v.groupMembership.id) };

      return {
        ...state,
        compose: {
          ...state.compose,
          selectedRecipients,
        },
      };
    }
    case SET_PRESELECTED_USER_ID.SYNC:
      return {
        ...state,
        compose: {
          ...state.compose,
          preSelectedUserId: action.payload.userId,
        },
      };
    default:
      return state;
  }
};
