import React from 'react';
import PropTypes from 'prop-types';
import { LEGACY_APP_DOMAIN } from 'config';
import { ExtendedParticipantPropType } from 'lib/propTypes';
import Spinner from 'components/shared/Spinner';

function ExtendedParticipantsList({ participants }) {
  if (!participants) {
    return <div className="col-md-4 text-center"><Spinner /></div>;
  }
  if (participants.length === 0) {
    return <div className="col-md-4" />;
  }
  participants.sort((a, b) => {
    if (a.fullName > b.fullName) return 1;
    if (a.fullName < b.fullName) return -1;
    return 0;
  });
  return (
    <div className="col-md-4 text-start">
      <h5 style={{ marginTop: 0 }}>Extended Participants</h5>
      {
        participants.map((participant, index) => (
          <span key={participant.groupMembershipId}>
            <a href={`${LEGACY_APP_DOMAIN}/group_memberships/${participant.groupMembershipId}`} target="_blank" rel="noreferrer">
              {' '}
              {participant.fullName}
              {' '}
            </a>
            { index !== participants.length - 1 && '●'}
          </span>
        ))
      }
    </div>
  );
}

ExtendedParticipantsList.defaultProps = {
  participants: [],
};

ExtendedParticipantsList.propTypes = {
  participants: PropTypes.arrayOf(ExtendedParticipantPropType),
};

export default ExtendedParticipantsList;
