import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFieldContext } from 'lib/hooks/useFieldContext';
import { StandardOptionsPropType } from 'lib/propTypes';
import './styles.scss';

function RadioCheckInput({ name, type, disabled, options, block }) {
  const { value, touched, error, handleChange, handleBlur } = useFieldContext();

  const handleInputChange = useCallback((event) => {
    // Boolean values come in as string values from the event
    if (['true', 'false'].includes(event.target.value) && event.target.checked) {
      handleChange(event.target.value === 'true' || false);
    } else {
      handleChange(event);
    }
  }, [handleChange]);

  return (
    <div className={`d-flex ${block ? 'flex-column align-items-start' : ' flex-wrap'} gap-1`}>
      {options.map((option) => {
        const isSelected = (Array.isArray(value) && value.includes(option.value)) || value === option.value;

        return (
          <label key={option.value} className={`check-input rounded ${(touched && error) ? 'is-invalid border-danger' : ''} ${isSelected ? 'selected' : ''}`}>
            <input
              id={option.label}
              name={name}
              type={type}
              className={`form-check-input me-2 ${(touched && error) ? 'is-invalid' : ''}`}
              value={option.value}
              checked={isSelected}
              disabled={disabled}
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
            {option.label}
          </label>
        );
      })}
    </div>
  );
}

RadioCheckInput.defaultProps = {
  disabled: false,
  options: [],
  block: false,
};

RadioCheckInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  disabled: PropTypes.bool,
  options: StandardOptionsPropType,
  block: PropTypes.bool,
};

export default RadioCheckInput;
