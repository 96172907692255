import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectCourseById } from 'store/courses/selectors';
import Table from 'react-bootstrap/Table';
import CompletedGroupsList from 'components/shared/CompletionReport/CompletedGroupsList';

function CompletionReport() {
  const { courseId } = useParams();
  const course = useSelector((state) => selectCourseById(state, courseId));
  const { dropoutReports, totals } = useSelector((state) => state.courses.completionReport);

  const reportData = dropoutReports?.map((report) => ({
    name: `M${report.modulePosition}`, moduleName: report.moduleName, dropouts: report.moduleDropoutCount, moduleDropoutPercentage: report.moduleDropoutPercentage,
  }));

  return (

    <div>
      <div className="mb-5">
        <h2>Completion Report</h2>
        <p className="pb-2">
          For this version of the curriculum, below are some statistics
          of the general completion rate as well as a breakdown of which modules people didn&apos;t complete. This report looks at completion data for all &quot;completed&quot; groups have taken this course.
        </p>

        <h4>
          {totals?.totalMembershipCount}
          {' '}
          total participants
        </h4>
        <p className="pb-2">
          As of June 29, 2012 there have been
          {' '}
          {totals?.totalMembershipCount}
          {' '}
          participants across all groups who have taken this course. Below is a breakdown showing which of those participants completed or dropped out.
        </p>

        <h4>
          {totals?.totalCompleterPercentage}
          %
          {' '}
          completed the course
        </h4>
        <p className="pb-2">
          {totals?.totalCompleterCount}
          {' '}
          (or
          {' '}
          {totals?.totalCompleterPercentage}
          %) of the participants who have taken this version of the curriculum have completed this course and earned a certificate.
        </p>

        <h4>
          {totals?.totalDropoutPercentage}
          %
          {' '}
          did not complete the course
        </h4>
        <p className="pb-2">
          {totals?.totalDropoutCount}
          {' '}
          (or
          {' '}
          {totals?.totalDropoutPercentage}
          %) of the participants who have taken this version did not complete it. Of those dropouts, below shows which module they stopped at.
        </p>
      </div>
      <h4 className="mt-3 mb-3">Modules at which participants stopped at</h4>
      <Table style={{ width: '100%' }} bordered hover className="bg-white mb-4">
        <thead>
          <tr>
            <th style={{ width: '25%' }}>Module</th>
            <th>Description</th>
            <th>Number of participants</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody key={course.id}>
          {reportData && reportData.map((report) => (
            <tr key={report.moduleName}>
              <td>{report.name}</td>
              <td>{report.moduleName}</td>
              <td>{report.dropouts}</td>
              <td>
                {report.moduleDropoutPercentage}
                %
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="mt-5">
        <CompletedGroupsList />
      </div>
    </div>
  );
}

export default CompletionReport;
