import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchPut } from 'lib/apiHelpers';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH, REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH } from 'lib/routerPaths';
import { GroupParticipationPropType } from 'lib/propTypes';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ParticipantBadge from 'components/shared/Badge/ParticipantBadge';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import ParticipantActions from './ParticipantActions';

export const PutRestoreParticipant = (groupId, values) => fetchPut(`/api/registrar/groups/${groupId}/restore_participant.json`, values);

function SubGroupMembershipRow({ groupMembership }) {
  const group = useSelector((state) => state.groupShow.group);
  const organizations = useSelector((state) => state.organizations);
  const healthAuthorities = useSelector((state) => state.healthAuthorities);
  const workspace = useWorkspace();

  const restoreParticipant = useCallback(async (participantId) => {
    const response = await PutRestoreParticipant(group.id, { participantId });
    if (response.status === 'ok') {
      window.location.reload();
    }
  }, [group.id]);

  return (
    <tr key={groupMembership.id} className="align-middle">
      <td className="col-3">
        <Link
          to={buildRoutePath(
            workspace.id === 'facilitator' ? FACILITATOR_PARTICIPANTS_SHOW_PATH : REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH,
            {
              id: groupMembership.userId,
              groupMembershipId: groupMembership.id,
            },
          )}
          className="btn-plain fs-5 fw-semibold"
        >
          { `${groupMembership.fullName}`}
        </Link>
      </td>

      <td className="col-3">
        <SimpleToolTip
          placement="top"
          text={(
            <span>
              <span className="fw-semibold">{organizations?.byId[groupMembership.organizationId]?.name}</span>
              <br />
              <span className="text-small">{healthAuthorities?.byId[groupMembership.healthAuthorityId]?.name}</span>
            </span>
                )}
        >
          <span className="fs-5 ">{organizations?.byId[groupMembership.organizationId]?.shortName}</span>
        </SimpleToolTip>
      </td>

      <td className="col-3">
        {groupMembership.percentageDoneCourse > 0 && (
          <SimpleToolTip
            placement="top"
            text={`${groupMembership.percentageDoneCourse}%`}
            variant="success"
          >
            <ProgressBar
              now={groupMembership.percentageDoneCourse}
              label={`${groupMembership.percentageDoneCourse}%`}
              visuallyHidden
              style={{ height: '0.75rem', borderRadius: '0.375rem' }}
            />
          </SimpleToolTip>
        )}
        {groupMembership.percentageDoneCourse === 0 && (
          <ProgressBar
            now={groupMembership.percentageDoneCourse}
            label={`${groupMembership.percentageDoneCourse}%`}
            visuallyHidden
            style={{ height: '0.75rem', borderRadius: '0.375rem' }}
          />
        )}
      </td>

      <td className="col-2">
        <ParticipantBadge state={groupMembership.completion} completion={groupMembership.decoratedCompletion} />
      </td>

      <td className="col-1 text-end">
        {workspace.id === 'registrar' && (
          <>
            {(groupMembership.completion === 'removed' && groupMembership.canRestore) && (
            <button
              data-confirm="Are you sure you want to restore this participant's group membership?"
              onClick={() => restoreParticipant(groupMembership.id)}
              className="btn btn-outline-warning btn-sm"
              type="button"
            >
              Restore
            </button>
            )}

            {(groupMembership.completion === 'removed' && !groupMembership.canRestore) && (
            <div className="text-nowrap">
              <SimpleToolTip
                placement="left"
                text={(
                  <p className="mb-0 text-start">
                    Group memberships can only be restored if the group is in session and the participant has
                    recorded progress on training tasks.
                  </p>
                )}
              >
                <span className="fas fa-info-circle pe-1" />
              </SimpleToolTip>

              <button disabled className="btn btn-outline-warning btn-sm" type="button">
                Restore
              </button>
            </div>
            )}
          </>
        )}

        {groupMembership.completion !== 'removed' && (
          <ParticipantActions participant={groupMembership} />
        )}
      </td>
    </tr>
  );
}

SubGroupMembershipRow.propTypes = {
  groupMembership: GroupParticipationPropType.isRequired,
};

export default SubGroupMembershipRow;
