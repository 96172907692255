/* eslint-disable */
// TODO make this work
import React, { Component } from 'react';
import _sortBy from 'lodash/sortBy';
import Select from 'react-select';

export default class RecipientSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRecipients: [],
    };
  }

  getMultiSelectGroups() {
    return _sortBy(
      this.props.availableSubGroups.map((sg) => ({
        groupId: sg.id,
        title: `${sg.groupName} - ${sg.name.substr(0, 5)}`,
        beginSessionAt: sg.beginSessionAt,
      })),
      (asg) => asg.beginSessionAt,
    );
  }

  getMultiSelectOptions() {
    return _sortBy(
      this.props.availableRecipients.map((recipient) => ({
        groupId: recipient.subGroupId, // the group id of the selector
        label: `${recipient.fullName}`,
        id: `gm:${recipient.id}`,
        type: 'groupMembership',
        groupMembership: { id: recipient.id, subGroupId: recipient.subGroupId },
      })).concat(
        this.props.availableSubGroups.map((sg) => {
          const podName = sg.name.substr(0, 5);
          return {
            groupId: sg.id,
            label: `> Entire ${sg.groupName} - ${podName}`,
            id: `sg:${sg.id}`,
            type: 'subGroup',
            subGroupId: sg.id,
            beginSessionAt: sg.beginSessionAt,
          };
        }),
      ),
      (element) => element.label.toLowerCase(),
    );
  }

  // Called whenever the options in the dropdown are re-rendered.
  // This happens every time the the user modifies the search terms
  // See: http://furqanzafar.github.io/react-selectize/#/?category=multi&example=disable-selected
  // @param options (Array): Available options
  // @param values (Array): Currently selected values
  // @param search (String): Optionally, the string typed by the facilitator in the search box
  // @return Array: The new set of options to be returned
  filterMultiSelectOptions(options, values, search) {
    if (search.length > 0) {
      const caseInsensitiveSearch = search.toLowerCase();
      options = options
        .filter((o) => o.label.toLowerCase().indexOf(caseInsensitiveSearch) >= 0); // only show results that match the search term
    }

    if (values.length > 0) {
      options = options
        .filter((o) => !values.find((selectedValue) => selectedValue.id === o.id)); // only options that are not already selected
    }

    const isSubGroupSelected = (values.length > 0 && values[0].type === 'subGroup');
    const isParticipantSelected = (values.length > 0 && values[0].type === 'groupMembership');

    if (isSubGroupSelected) {
      return options
        .filter((o) => o.type === 'subGroup'); // sub group options only
    } if (isParticipantSelected) {
      return options
        .filter((o) => o.type === 'groupMembership'); // only participants
    }
    // participants and groups
    return options;
  }

  updateRecipients(values) {
    const isSubGroupSelected = (values.length >= 1 && values[0].type === 'subGroup');
    if (isSubGroupSelected) {
      // Remove individual recipients if the facilitator selects an entire pod
      const validValues = values.filter((v) => v.type === 'subGroup');
      this.setState({ selectedRecipients: validValues });
      this.props.onChange(validValues);
    } else {
      this.setState({ selectedRecipients: values });
      this.props.onChange(values);
    }
  }

  render() {
    // TODO: @koda
    return <div>todo</div>;
    // return <Select />
  }
}
