import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { LEGACY_APP_DOMAIN } from 'config';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { getElementBottomHeight } from 'lib/utils';

function ActionsMenu({ itemId, type, courseId, initialValuesId, dirty, isSubmitting, toggleCopyModal, toggleDeleteModal, isLocked }) {
  const scrollRef = useRef();
  const { id: workspaceId } = useWorkspace();
  const [isVisible, setIsVisible] = useState(false);
  const previewLink = type === 'task' ? `${LEGACY_APP_DOMAIN}/tasks/${itemId}` : `${LEGACY_APP_DOMAIN}/courses/${courseId}/articles/${itemId}`;

  const listenToScroll = () => {
    const heightToShowFrom = getElementBottomHeight(scrollRef.current);
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToShowFrom) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  });

  return (
    <>
      <div className={`${isVisible ? 'position-fixed z-3 top-0 end-0 mt-2 me-3 border rounded p-2' : 'ms-auto'}`} style={{ backdropFilter: 'blur(2px)' }}>
        {!!itemId && (
          <Button target="_blank" size="sm" href={previewLink} variant="secondary">
            Preview
          </Button>
        )}

        {workspaceId === 'curriculum' && (
          <>
            <Button
              size="sm"
              variant={(initialValuesId && !dirty) ? 'secondary' : 'primary'}
              className="ms-2"
              type="submit"
              disabled={isSubmitting}
            >
              Save
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>

            <Dropdown align="end" className="d-inline-block ms-2 py-0">
              <Dropdown.Toggle variant="secondary" className="btn-sm">
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={toggleCopyModal} disabled={!itemId}>
                  Copy
                  {' '}
                  {type}
                  {' '}
                  to&hellip;
                </Dropdown.Item>
                <Dropdown.Item onClick={toggleDeleteModal} disabled={isLocked || !itemId}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </div>
      <span ref={scrollRef} />
    </>
  );
}

ActionsMenu.defaultProps = {
  itemId: null,
  courseId: null,
  initialValuesId: null,
  dirty: false,
  isSubmitting: false,
  toggleCopyModal: () => {},
  toggleDeleteModal: () => {},
  isLocked: false,
};

ActionsMenu.propTypes = {
  itemId: PropTypes.number,
  type: PropTypes.oneOf(['task', 'article']).isRequired,
  courseId: PropTypes.number,
  initialValuesId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  toggleCopyModal: PropTypes.func,
  toggleDeleteModal: PropTypes.func,
  isLocked: PropTypes.bool,
};

export default ActionsMenu;
