import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LOAD_COURSE_TYPE_ARCHIVED_COURSES } from 'store/courses/actions';
import { REDUX_STATUS } from 'lib/constants';
import Alert from 'react-bootstrap/Alert';
import ErrorMessage from 'components/shared/ErrorMessage';
import CourseTable from 'components/curriculum/CourseTable';

function CourseTypeArchiveTabPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const courseTypes = useSelector((state) => state.courseTypes);
  const courses = useSelector((state) => state.courses);

  const courseTypesDone = [REDUX_STATUS.IDLE, REDUX_STATUS.SUCCESS].includes(courseTypes.status);
  const coursesDone = [REDUX_STATUS.IDLE, REDUX_STATUS.SUCCESS].includes(courses.status);

  useEffect(() => {
    dispatch(LOAD_COURSE_TYPE_ARCHIVED_COURSES.request(id));
  }, [dispatch, id]);

  const archived = useMemo(() => {
    if (!courses?.data) return [];
    const archivedCourses = Object.values(courses.data).filter((course) => course.archived);
    return archivedCourses.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  }, [courses?.data]);

  return (
    <div className="px-4">
      <ErrorMessage error={courses?.error} />

      {(courseTypesDone && coursesDone && archived.length === 0) && (
        <Alert variant="info mt-4">
          There are no archived courses for this course type.
        </Alert>
      )}

      <CourseTable courses={archived} loading={courses.status === REDUX_STATUS.PENDING} className="mt-4" />
    </div>
  );
}

export default CourseTypeArchiveTabPage;
