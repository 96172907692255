import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType, StylePropType } from 'lib/propTypes';
import RegistrarNav from 'components/registrar/RegistrarNav';
import BaseTemplate from '../BaseTemplate';

function RegistrarTemplate({ children, id, className, style }) {
  return (
    <BaseTemplate
      id={id}
      className={className}
      style={style}
      navComponent={<RegistrarNav />}
    >
      {children}
    </BaseTemplate>
  );
}

RegistrarTemplate.defaultProps = {
  id: null,
  className: null,
  style: null,
};

RegistrarTemplate.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: ComponentPropType.isRequired,
  style: StylePropType,
};

export default RegistrarTemplate;
