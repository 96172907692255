import React from 'react';
import SuperFacilitatorTemplate from 'components/templates/SuperFacilitatorTemplate';

function SuperFacilitatorDashboard() {
  return (
    <SuperFacilitatorTemplate className="dashboard-page">
      <p className="mt-4">placeholder</p>
    </SuperFacilitatorTemplate>
  );
}

export default SuperFacilitatorDashboard;
