import React from 'react';
import PropTypes from 'prop-types';
import { InsufficientParticipationPropType } from 'lib/propTypes';
import Spinner from 'components/shared/Spinner';

import { LEGACY_APP_DOMAIN } from 'config';

function InsufficientParticipationList({ ips }) {
  if (!ips) {
    return <div className="col-md-4 text-center"><Spinner /></div>;
  }
  if (ips.length === 0) {
    return <div className="col-md-4" />;
  }
  ips.sort((a, b) => {
    if (a.fullName > b.fullName) return 1;
    if (a.fullName < b.fullName) return -1;
    return 0;
  });
  return (
    <div className="col-md-4 text-start">
      <h5 style={{ marginTop: 0 }}>Insufficient Participations</h5>
      {
        ips.map((participant, index) => (
          <span key={participant.groupMembershipId}>
            <a
              href={`${LEGACY_APP_DOMAIN}/group_memberships/${participant.groupMembershipId}`}
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              {participant.fullName}
              {' '}
            </a>
            { index !== ips.length - 1 && '●'}
          </span>
        ))
      }
    </div>
  );
}

InsufficientParticipationList.defaultProps = {
  ips: [],
};

InsufficientParticipationList.propTypes = {
  ips: PropTypes.arrayOf(InsufficientParticipationPropType),
};

export default InsufficientParticipationList;
