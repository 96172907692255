import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { COMPACT_DATE_TIME, SHORT_DATE } from 'lib/dateFormats';
import Spinner from 'components/shared/Spinner';
import Pagination from 'components/shared/Pagination';
import Badge from 'components/shared/Badge/Badge';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';

function transactionLink(transaction) {
  if (!transaction.payableUrl) return null;

  if (transaction.payableType === 'Voucher/Purchase') {
    return <Link to={transaction.payableUrl}>{transaction.transactionId}</Link>;
  }

  return <a href={transaction.payableUrl} target="_blank" rel="noreferrer">{transaction.transactionId}</a>;
}

function transactionCustomer(transaction) {
  if (!transaction.name && !transaction.email) return null;

  if (transaction.name && transaction.email) {
    return (
      <>
        {transaction.name}
        <br />
        {transaction.email}
      </>
    );
  }

  return transaction.email;
}

function transactionState(state, comment) {
  if (['Refunded', 'Failed'].includes(state)) {
    const badgeVariant = state === 'refunded' ? 'blue' : 'red';
    if (comment) {
      return (
        <SimpleToolTip text={comment}>
          <span>
            <Badge title={state} variant={badgeVariant} />
          </span>
        </SimpleToolTip>
      );
    }
    return <Badge title={state} variant={badgeVariant} />;
  }

  if (state === 'Successful') return <Badge title={state} variant="green" />;
  return <Badge title={state} variant="grey" />;
}

function Results({ setPage }) {
  const { paymentTransactions, page, totalResults, searching } = useSelector((state) => state.paymentReport);
  const totalPages = Math.ceil(totalResults / 50);

  if (!paymentTransactions) return null;

  const numRecords = Object.keys(paymentTransactions).length;
  if (numRecords === 0) return <div className="alert alert-warning">No results.</div>;

  return (
    <>
      <p className="text-center text-secondary">
        {`Showing ${((page - 1) * numRecords) + 1} - ${page * numRecords} out of ${totalResults} payment transactions.`}
      </p>

      <Pagination
        page={page}
        totalPages={totalPages}
        onPageClick={setPage}
        window={1}
      />

      <div className="position-relative">
        {searching && (
          <div className="position-absolute top-0 start-50 translate-middle-x bg-light bg-opacity-10">
            <Spinner />
          </div>
        )}

        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Date</th>
                <th>Transaction ID</th>
                <th>Customer</th>
                <th>Training</th>
                <th>Start Date</th>
                <th>Type</th>
                <th>State</th>
                <th>Provider</th>
              </tr>
            </thead>

            <tbody>
              {paymentTransactions?.map((transaction) => (
                <tr key={transaction.id}>
                  <td className="text-small text-muted text-nowrap">
                    {format(parseISO(transaction.receivedAt), COMPACT_DATE_TIME)}
                  </td>
                  <td className="text-nowrap">{transactionLink(transaction)}</td>
                  <td className="text-truncate" style={{ maxWidth: '16em' }}>{transactionCustomer(transaction)}</td>
                  <td>{transaction.itemName}</td>
                  <td className="text-nowrap">{transaction.groupStartDate && format(parseISO(transaction.groupStartDate), SHORT_DATE)}</td>
                  <td>{transaction.payableType}</td>
                  <td>{transactionState(transaction.state, transaction.comment)}</td>
                  <td className="text-small text-muted">{transaction.provider}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Pagination
        page={page}
        totalPages={totalPages}
        onPageClick={setPage}
        window={1}
      />
    </>
  );
}

Results.defaultProps = {
  setPage: () => {},
};

Results.propTypes = {
  setPage: PropTypes.func,
};

export default Results;
