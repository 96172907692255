import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { REDUX_STATUS } from 'lib/constants';
import { Alert, Table } from 'react-bootstrap';
import AssignmentListItem from './AssignmentListItem';
import Skeleton from './ListSkeleton';

function AssignmentList({ setShowCancelModal, setSelectedParticipant }) {
  const { allIds: assignmentIds, status } = useSelector((state) => state.indigenousWelcomeLetters.assignments);

  const selectParticipant = useCallback(({ participantName, assignmentId }) => {
    setShowCancelModal(true);
    setSelectedParticipant({ participantName, assignmentId });
  }, [setShowCancelModal, setSelectedParticipant]);

  if (status === REDUX_STATUS.PENDING) return <Skeleton />;

  if (!assignmentIds.length) {
    return <Alert variant="info" className="mt-4">There are no Indigenous Welcome Letter assignments at this time.</Alert>;
  }

  return (
    <Table striped bordered className="sn-table my-4">
      <thead>
        <tr>
          <th>Name</th>
          <th>Group & Pod</th>
          <th>Percent Complete</th>
          <th>Job Category</th>
          <th>Health Authority</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {assignmentIds.map((assignmentId) => (
          <AssignmentListItem key={assignmentId} itemId={assignmentId} selectParticipant={selectParticipant} />
        ))}
      </tbody>
    </Table>
  );
}

AssignmentList.defaultProps = {
  setShowCancelModal: () => {},
  setSelectedParticipant: () => {},
};

AssignmentList.propTypes = {
  setShowCancelModal: PropTypes.func,
  setSelectedParticipant: PropTypes.func,
};

export default AssignmentList;
