import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { GET_MESSAGES } from 'store/actions';
import Spinner from 'components/shared/Spinner';
import Pagination from 'components/shared/Pagination';
import Messages from 'components/facilitator/Messages';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';

function MessagesListPage(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => !state.messages.list.conversationIds || !state.currentUser);
  const page = useSelector((state) => state.messages.list.page || props.page);
  const [showPage, setShowPage] = useState(page);
  const conversations = useSelector((state) => state.messages.list.conversationIds && state.messages.list.conversationIds.map((cId) => state.messages.list.conversations[cId]));
  const totalPages = useSelector((state) => state.messages.list.totalPages);
  const users = useSelector((state) => state.messages.list.users);
  const currentUserId = useSelector((state) => !loading && state.currentUser.ids);

  const handlePageClick = useCallback((p) => {
    setShowPage(p);
    history.push(`/facilitator/messages?page=${p}`);
  }, [history]);

  useEffect(() => {
    dispatch(GET_MESSAGES.request({ page: showPage }));
  }, [dispatch, showPage]);

  if (loading) {
    return <FacilitatorTemplate className="messages-page mt-3"><Spinner message="Loading..." /></FacilitatorTemplate>;
  }

  return (
    <FacilitatorTemplate className="messages-page">
      <div className="row mt-4">
        <div className="col-md-3">
          <h1>Messages</h1>
        </div>
        <div className="col-md-4 offset-md-5 text-end">
          <Link className="btn btn-light" to="/facilitator/messages/new">New Message</Link>
        </div>
      </div>

      <Pagination page={Number(page)} totalPages={totalPages} onPageClick={handlePageClick} window={1} previous="Newer" next="Older" />

      <Messages
        conversations={conversations}
        users={users}
        currentUserId={currentUserId}
      />

      <Pagination page={Number(page)} totalPages={totalPages} onPageClick={handlePageClick} window={1} previous="Newer" next="Older" />
    </FacilitatorTemplate>
  );
}

MessagesListPage.defaultProps = {
  page: 1,
};

MessagesListPage.propTypes = {
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MessagesListPage;
