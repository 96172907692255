import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import useBreakpoint, { breakpoints } from 'lib/hooks/useBreakpoint';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { AVERAGE_TIME_BEFORE_COMPLETION } from 'store/courses/actions';
import AddCompletionThresholdPopover from './AddCompletionThresholdPopover';

function CompletionThreshold({ seconds }) {
  const { taskId } = useParams();
  const breakpoint = useBreakpoint();
  const [updatingThreshold, setUpdatingThreshold] = useState(false);
  const dispatch = useDispatch();

  const toggleCompletionThreshold = useCallback(() => {
    if (!updatingThreshold) {
      dispatch(AVERAGE_TIME_BEFORE_COMPLETION.request(taskId));
    }
    setUpdatingThreshold((prev) => !prev);
  }, [dispatch, taskId, updatingThreshold]);

  if (!taskId) return null;

  return (
    <div className="border rounded mt-2 py-3 px-3">
      <div className="row align-items-center">
        <h6 className="col-9 mb-0">Completion Threshold</h6>
        <OverlayTrigger
          trigger="click"
          placement={breakpoint === breakpoints.XXL ? 'left' : 'top'}
          overlay={AddCompletionThresholdPopover}
          rootClose
          show={updatingThreshold}
          onToggle={toggleCompletionThreshold}
          popperConfig={{ setUpdatingThreshold }}
        >
          <Button className="btn-plain col-3 ps-0" variant="primary" disabled={updatingThreshold} size="sm">
            {seconds === 0 ? '+ Add' : `${seconds} sec`}
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  );
}

CompletionThreshold.propTypes = {
  seconds: PropTypes.number.isRequired,
};

export default CompletionThreshold;
