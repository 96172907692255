import React from 'react';

function CheckCircle() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M23.031 3.96906C23.1008 4.03873 23.1562 4.12149 23.194 4.21261C23.2318 4.30373 23.2513 4.40141 23.2513 4.50006C23.2513 4.59871 23.2318 4.69639 23.194 4.78751C23.1562 4.87863 23.1008 4.96139 23.031 5.03106L12.531 15.5311C12.4613 15.6009 12.3785 15.6563 12.2874 15.6941C12.1963 15.7319 12.0986 15.7514 12 15.7514C11.9013 15.7514 11.8036 15.7319 11.7125 15.6941C11.6214 15.6563 11.5386 15.6009 11.469 15.5311L6.96897 11.0311C6.89924 10.9613 6.84392 10.8785 6.80619 10.7874C6.76845 10.6963 6.74902 10.5987 6.74902 10.5001C6.74902 10.4014 6.76845 10.3038 6.80619 10.2127C6.84392 10.1216 6.89924 10.0388 6.96897 9.96906C7.0387 9.89933 7.12149 9.84401 7.2126 9.80627C7.3037 9.76853 7.40136 9.74911 7.49997 9.74911C7.59859 9.74911 7.69624 9.76853 7.78735 9.80627C7.87846 9.84401 7.96124 9.89933 8.03097 9.96906L12 13.9396L21.969 3.96906C22.0386 3.89921 22.1214 3.8438 22.2125 3.80599C22.3036 3.76818 22.4013 3.74872 22.5 3.74872C22.5986 3.74872 22.6963 3.76818 22.7874 3.80599C22.8785 3.8438 22.9613 3.89921 23.031 3.96906Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 3.74998C10.3683 3.74998 8.77326 4.23384 7.41656 5.14036C6.05985 6.04688 5.00243 7.33536 4.37801 8.84285C3.75358 10.3503 3.59021 12.0091 3.90854 13.6095C4.22686 15.2098 5.0126 16.6798 6.16638 17.8336C7.32017 18.9874 8.79018 19.7731 10.3905 20.0915C11.9909 20.4098 13.6497 20.2464 15.1572 19.622C16.6646 18.9976 17.9531 17.9401 18.8596 16.5834C19.7662 15.2267 20.25 13.6317 20.25 12C20.25 11.8011 20.329 11.6103 20.4697 11.4697C20.6103 11.329 20.8011 11.25 21 11.25C21.1989 11.25 21.3897 11.329 21.5303 11.4697C21.671 11.6103 21.75 11.8011 21.75 12C21.7497 14.1462 21.0413 16.2324 19.7345 17.935C18.4278 19.6376 16.5958 20.8615 14.5226 21.4168C12.4494 21.972 10.2509 21.8278 8.26813 21.0063C6.28531 20.1848 4.62896 18.732 3.55595 16.8732C2.48294 15.0144 2.05324 12.8535 2.33349 10.7256C2.61373 8.59776 3.58826 6.6218 5.10594 5.10422C6.62362 3.58664 8.59964 2.61223 10.7275 2.33212C12.8554 2.05202 15.0163 2.48186 16.875 3.55498C16.9646 3.60187 17.0439 3.6664 17.1079 3.7447C17.1719 3.82299 17.2195 3.91344 17.2477 4.01058C17.2759 4.10773 17.2841 4.20957 17.2719 4.30998C17.2597 4.4104 17.2273 4.50731 17.1767 4.59488C17.1261 4.68245 17.0583 4.75888 16.9773 4.81957C16.8964 4.88026 16.8041 4.92395 16.7058 4.94801C16.6076 4.97208 16.5055 4.97602 16.4056 4.9596C16.3058 4.94319 16.2104 4.90675 16.125 4.85248C14.8714 4.12752 13.4482 3.74715 12 3.74998Z" fill="currentColor" />
    </svg>
  );
}

export default CheckCircle;
