import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { COURSE_STATUS, REDUX_STATUS } from 'lib/constants';
import { UPDATE_COURSE_DATA, REMOVE_COURSE } from 'store/courses/actions';
import Alert from 'react-bootstrap/Alert';
import CourseDrafts from 'components/curriculum/CourseDrafts';
import CourseTable from 'components/curriculum/CourseTable';
import ErrorMessage from 'components/shared/ErrorMessage';
import consumer from '../../../../app/javascript/channels/consumer';

const CourseActions = { UPDATE_COURSE_DATA, REMOVE_COURSE };

function CourseTypeCourseTabPage({ setShowCreateDraftModal }) {
  const courseIds = useSelector((state) => state.courses.courseIds);
  const { data: courses, status, error } = useSelector((state) => state.courses);
  const dispatch = useDispatch();

  let masterCourseIds = [];
  let otherCourseIds = [];
  if (courseIds) {
    masterCourseIds = courseIds.filter((courseId) => [COURSE_STATUS.DRAFT, COURSE_STATUS.DUPLICATING].includes(courses[courseId]?.status));
    otherCourseIds = courseIds.filter((courseId) => ![COURSE_STATUS.ARCHIVED, COURSE_STATUS.DRAFT, COURSE_STATUS.DUPLICATING].includes(courses[courseId]?.status));
  }

  const masterCourses = masterCourseIds.map((masterCourseId) => courses[masterCourseId]);
  const otherCourses = otherCourseIds.map((otherCourseId) => courses[otherCourseId]);

  useEffect(() => {
    const subscriptions = consumer.subscriptions.subscriptions.map((s) => s.identifier);
    if (!subscriptions.includes('{"channel":"CoursesChannel"}')) {
      consumer.subscriptions.create(
        'CoursesChannel',
        {
          received(payload) {
            if (Object.keys(CourseActions).includes(payload?.type)) { dispatch(CourseActions[payload.type].action(payload)); }
          },
        },
      );
    }
  }, [dispatch]);

  return (
    <div className="px-4">
      <ErrorMessage error={error} />

      <CourseDrafts courses={masterCourses} loading={status === REDUX_STATUS.PENDING} setShowCreateDraftModal={setShowCreateDraftModal} className="mt-4" />

      <div className="mt-4">
        <h4>Published</h4>
        <p>The course content that is visible to participants</p>

        { (!REDUX_STATUS.PENDING && !otherCourses.length) && (
          <Alert variant="secondary" className="p-4">
            Publish a draft to make course content available to the next group of participants
          </Alert>
        )}

        <CourseTable courses={otherCourses} loading={status === REDUX_STATUS.PENDING} className="mt-4" />
      </div>
    </div>
  );
}

CourseTypeCourseTabPage.defaultProps = {
  setShowCreateDraftModal: () => {},
};

CourseTypeCourseTabPage.propTypes = {
  setShowCreateDraftModal: PropTypes.func,
};

export default CourseTypeCourseTabPage;
