import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { CloseButton } from 'react-bootstrap';
import './styles.scss';

const STEP_LABELS = ['Upload a file', 'Review', 'Import'];

const STEPS = {
  UPLOAD: 0,
  REVIEW: 1,
  IMPORT: 2,
  COMPLETE: 3,
};

const calculateStepClass = (active, index) => {
  const step = typeof active === 'string' ? STEPS[active] : active;
  if (step === index) return 'active';
  if (step > index) return 'completed';
  return '';
};

function Header({ activeIndex, onClose, returnTo }) {
  const history = useHistory();

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
    if (returnTo) {
      history.push(returnTo);
    }
  };

  return (
    <div className="header-grid border-bottom">
      <h4 className="title">Bulk Import</h4>

      <ol className="steps">
        {STEP_LABELS.map((step, index) => (
          <li key={step} className={calculateStepClass(activeIndex, index)}>
            {step}
          </li>
        ))}
      </ol>

      <div className="close">
        <CloseButton onClick={handleClose} />
      </div>
    </div>
  );
}

Header.defaultProps = {
  onClose: null,
  returnTo: null,
};

Header.propTypes = {
  activeIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(Object.keys(STEPS)),
  ]).isRequired,
  onClose: PropTypes.func,
  returnTo: PropTypes.string,
};

export default Header;
