import React from 'react';
import PropTypes from 'prop-types';
import { TASK_TYPES } from 'lib/constants';
import { LocalePropType, QuestionPropType } from 'lib/propTypes';
import { getLocaleSuffix } from 'lib/utils';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import OptionsTable from './OptionsTable';

function MultipleChoiceQuestion({ locale, index, question }) {
  const { currentTask } = useCourseShowContext();
  const correctAnswerExplanation = question[`explanation${getLocaleSuffix(locale)}`];

  return (
    <div id={`${question.id}`} className="question">
      <div className="d-flex align-items-center mb-1">
        <div className="question-number">
          <label className="form-label mb-0">Question</label>
        </div>

        <span className="ms-auto font-weight-semibold" style={{ fontSize: '0.875em' }}>
          {`${currentTask?.type === TASK_TYPES.SURVEY ? 'Survey' : 'Multiple choice'} question`}
        </span>
      </div>

      <div style={{ whiteSpace: 'pre-wrap' }}>
        {question[`body${getLocaleSuffix(locale)}`]}
      </div>

      <p className="mt-2 mb-0 form-label">Options</p>

      <OptionsTable index={index} locale={locale} options={question.options} />

      {(currentTask?.type === TASK_TYPES.MULTIPLE_CHOICE_QUIZ && correctAnswerExplanation) && (
      <>
        <span className="d-inline-block form-label mt-3">Correct answer explanation</span>
        {/* eslint-disable react/no-danger */}
        <div
          className="border rounded bg-light p-3"
          dangerouslySetInnerHTML={{ __html: correctAnswerExplanation }}
        />
        {/* eslint-enable react/no-danger */}
      </>
      )}
    </div>
  );
}

MultipleChoiceQuestion.defaultProps = {
  arrayHelpers: {},
  locale: 'en',
};

MultipleChoiceQuestion.propTypes = {
  arrayHelpers: PropTypes.shape({
    insert: PropTypes.func,
    remove: PropTypes.func,
    replace: PropTypes.func,
  }),
  question: QuestionPropType.isRequired,
  locale: LocalePropType,
  index: PropTypes.number.isRequired,
};

export default MultipleChoiceQuestion;
