import { CREATE_GROUPS } from './actions';

const initialState = {
  loading: true,
  formState: 'display',
  groupsCreated: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_GROUPS.REQUEST:
      return { ...state, formState: 'creating' };
    case CREATE_GROUPS.SUCCESS:
      return {
        ...state,
        formState: 'created',
        groupsCreated: action.payload.groups,
      };
    case CREATE_GROUPS.ERROR:
      return { ...state, formState: 'error' };
    default:
      return state;
  }
};
