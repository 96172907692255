import { fetchGet, fetchPost, handleSimpleFetchError } from 'lib/apiHelpers';
import _isEmpty from 'lodash/isEmpty';
import { put, select, takeLatest } from 'redux-saga/effects';

import { GET_PREVIOUS_PTQ_REPORTS, GET_PTQ_FORM_DATA, REQUEST_PLATFORM_PTQ } from 'store/actions';

export default function* sagas() {
  yield takeLatest(GET_PREVIOUS_PTQ_REPORTS.REQUEST, function* getPreviousPtqReports() {
    try {
      const response = yield fetchGet('/api/registrar/reports/ptq.json');
      yield put(GET_PREVIOUS_PTQ_REPORTS.success(response.data, { ...response.meta, cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_PREVIOUS_PTQ_REPORTS);
    }
  });

  yield takeLatest(GET_PTQ_FORM_DATA.REQUEST, function* getPtqFromData() {
    const state = yield select();

    if (_isEmpty(state.ptqReport.formData)) {
      try {
        const { data } = yield fetchGet('/api/shared/course_types', {});
        yield put(GET_PTQ_FORM_DATA.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, GET_PTQ_FORM_DATA);
      }
    } else {
      yield put(GET_PTQ_FORM_DATA.success({}, { cached: true }));
    }
  });

  yield takeLatest(REQUEST_PLATFORM_PTQ.REQUEST, function* requestPlatformPtq(action) {
    try {
      const response = yield fetchPost(
        '/api/registrar/reports/ptq.json',
        {
          backgroundReport: {
            reportType: 'ptq',
            filters: {
              courseTypeIds: action.payload.courseTypeId,
              dateRange: action.payload.dateRange,
              startDate: action.payload.startDate,
              endDate: action.payload.endDate,
              physicianCreditType: action.payload.physicianCreditType,
            },
          },
        },
      );
      yield put(REQUEST_PLATFORM_PTQ.success(response.data, { ...response.meta, cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, REQUEST_PLATFORM_PTQ);
    }
  });
}
