import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { fetchGet } from 'lib/apiHelpers';
import { COUNTRIES, PROVINCES, REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import { ErrorPropType } from 'lib/propTypes';
import { CREATE_PARTICIPANT } from 'store/groupShow/actions';
import { Form, Formik } from 'formik';
import { Button, Spinner } from 'react-bootstrap';
import ErrorMessage from 'components/shared/ErrorMessage';
import InputField from 'components/shared/FormFields/InputField';
import SelectField from 'components/shared/FormFields/SelectField';
import SwitchField from 'components/shared/FormFields/SwitchField';
import { FormSchema } from './validation';

const GetFormData = (groupId) => fetchGet(`/api/registrar/groups/${groupId}/participant_form_data.json`);

const formatHAOptionLabel = (option, { context }) => {
  if (context === 'menu') return option.label;

  return (
    <span>
      {option.label}
      <span className="ps-2 text-secondary text-small">
        {option.organizationName}
      </span>
    </span>
  );
};

const INITIAL_FORM_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  login: '',
  address1: '',
  address2: '',
  city: '',
  country: '',
  province: '',
  postalCode: '',
  phone: '',
  languagePreference: 'en',
  healthAuthorityId: '',
  isCurriculumReviewer: false,
};

function CreateParticipantForm({ group, user }) {
  const userId = user ? user.id : null;
  const dispatch = useDispatch();
  const history = useHistory();
  const organizations = useSelector((state) => Object.values(state.organizations.byId));
  const [formData, setFormData] = useState({
    healthAuthorities: [],
    jobCategories: [],
  });

  const groupedHealthAuthorities = useMemo(() => {
    if (!organizations || !formData.healthAuthorities.length) return [];

    return organizations.reduce((acc, org) => {
      acc.push({
        label: org.name,
        options: org.healthAuthorityIds.map((haId) => formData.healthAuthorities.find((ha) => ha.value === haId) || {}),
      });
      return acc;
    }, []);
  }, [organizations, formData.healthAuthorities]);

  useEffect(() => {
    if (!formData.healthAuthorities.length || !formData.jobCategories.length) {
      GetFormData(group.id).then((resp) => {
        if (resp) {
          setFormData(resp);
        }
      });
    }
  }, [formData, setFormData, group.id]);

  useEffect(() => {
    if (group.status === REDUX_STATUS.SUCCESS && group.success === REDUX_SUCCESS.CREATED) {
      history.push(`/registrar/groups/${group.id}`);
    }
  }, [group, history]);

  const handleSubmit = useCallback((values, actions) => {
    dispatch(CREATE_PARTICIPANT.request({ groupId: group.id, participant: { ...values, userId, groupId: group.id } }, { formikActions: actions }));
  }, [dispatch, group.id, userId]);

  const initialValues = {
    ...INITIAL_FORM_VALUES,
    ...(user || {}),
  };

  const jobCategoryOptions = formData.jobCategories.map((j) => ({ value: j.value, label: j.label }));

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={FormSchema}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form className="mt-3">
          {user.id ? (
            <div className="alert alert-success" role="alert">
              This email address
              {' '}
              <strong>already exists</strong>
              {' '}
              in the database.
              Please verify the fields in the form below.
            </div>
          ) : (
            <div className="alert alert-info" role="alert">
              This email address
              {' '}
              <strong>does not exist</strong>
              {' '}
              in the database.
              Please fill in the fields in the form below.
            </div>
          )}

          <ErrorMessage error={group.error} />

          <fieldset>
            <legend>General information</legend>
            <div className="mb-3">
              <InputField disabled label="Email Address" name="email" />
            </div>
            <div className="mb-3">
              <InputField label="First Name" name="firstName" />
            </div>
            <div className="mb-3">
              <InputField label="Last Name" name="lastName" />
            </div>
            <div className="mb-3">
              <InputField label="Phone Number" name="phoneNumber" />
            </div>
            <div className="mb-3">
              <SelectField
                label="Language Preference"
                name="languagePreference"
                includeBlank="Please select language"
                options={[{ label: 'English', value: 'en' }, { label: 'French', value: 'fr' }]}
              />
            </div>
          </fieldset>
          <div className="mb-3">
            <InputField label="Public display name" name="login" />
          </div>
          <fieldset>
            <legend>Address</legend>
            <div className="mb-3">
              <InputField label="Address Line 1" name="address1" />
            </div>
            <div className="mb-3">
              <InputField label="Address Line 2" name="address2" />
            </div>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <InputField label="City" name="city" />
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <SelectField
                    label="Province"
                    name="province"
                    includeBlank="Please select"
                    options={PROVINCES}
                  />
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <InputField label="Postal Code" name="postalCode" />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="mb-3">
                <SelectField
                  label="Country"
                  name="country"
                  includeBlank="Please select"
                  options={COUNTRIES}
                />
              </div>
            </div>
            <legend>Health authority information</legend>
            <div className="col">
              <div className="mb-3">
                <SelectField
                  label="Health Authority Name"
                  name="healthAuthorityId"
                  includeBlank="Please select"
                  options={groupedHealthAuthorities}
                  required
                  formatOptionLabel={formatHAOptionLabel}
                />
              </div>
            </div>
            <div className="col">
              <div className="mb-3">
                <SelectField
                  label="Current Job Position"
                  name="jobCategoryId"
                  includeBlank="Please select"
                  options={jobCategoryOptions}
                />
              </div>
            </div>
            <div className="mb-3">
              <InputField label="Department" name="department" />
            </div>
            <legend>Employment Information</legend>
            <div className="mb-3">
              <InputField label="Service Organization" name="serviceOrganization" />
            </div>
            <div className="mb-3">
              <InputField label="Manager Name" name="authorizerName" />
            </div>
            <div className="mb-3">
              <InputField label="Manager Email" name="authorizerEmail" />
            </div>
            <div className="mb-3">
              <InputField label="Manager Phone" name="authorizerPhone" />
            </div>
          </fieldset>

          <fieldset>
            <legend>Curriculum Reviewer</legend>
            <div className="mb-3">
              <SwitchField type="checkbox" label="Curriculum reviewer" name="isCurriculumReviewer" switchPlacement="start" />
            </div>
          </fieldset>

          <div className="mt-3 mb-3">
            <Button
              type="submit"
              className="btn btn-primary me-2"
              disabled={isSubmitting}
            >
              Add a new participant to this group
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
            <Link to={`/registrar/groups/${group.id}`} className="btn btn-plain">Cancel</Link>
          </div>
        </Form>
      )}
    </Formik>
  );
}

CreateParticipantForm.defaultProps = {
  user: null,
};

CreateParticipantForm.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.number,
    groupParticipations: PropTypes.arrayOf(PropTypes.shape({})),
    error: ErrorPropType,
    success: PropTypes.oneOf(Object.values(REDUX_SUCCESS)),
    status: PropTypes.oneOf(Object.values(REDUX_STATUS)),
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default CreateParticipantForm;
