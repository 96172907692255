import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { takeLatest, put, retry, select, delay, call } from 'redux-saga/effects';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, handleSimpleFetchError } from 'lib/apiHelpers';
import { TASK_TYPES } from 'lib/constants';
import {
  LOAD_COURSES,
  LOAD_COURSE,
  LOAD_COURSE_TYPE_COURSES,
  LOAD_COURSE_TYPE_ARCHIVED_COURSES,
  ARCHIVE_COURSE,
  DUPLICATE_COURSE,
  DUPLICATE_COURSE_UPDATE_STATUS,
  SAVE_TASK,
  SAVE_SECTION,
  CREATE_TASK,
  CREATE_SECTION,
  LOAD_FORM_DATA,
  REORDER_COURSE_SECTION,
  REORDER_COURSE_SECTION_TASK,
  REORDER_QUESTIONS,
  DELETE_SECTION,
  REMOVE_SECTION,
  DELETE_TASK,
  REMOVE_TASK,
  LOAD_COURSE_SECTIONS,
  CREATE_COURSE,
  UPDATE_COURSE,
  MERGE_COURSE,
  PUBLISH_COURSE,
  COPY_TASK_FORM_DATA,
  COPY_TASK,
  DESTROY_RESOURCE,
  CREATE_URL_TASK_RESOURCE,
  CREATE_FILE_TASK_RESOURCE,
  UPDATE_COMPLETION_THRESHOLD,
  LOAD_TASK_QUESTIONS,
  LOAD_TASK_PROACTIVE,
  LOAD_COURSE_PROACTIVES,
  LOAD_COURSE_GROUPS,
  RESET_COURSE_STATUS,
  RESET_SECTION,
  RESET_TASK,
  LOAD_COURSE_PHRASEBOOK_ENTRIES,
  CREATE_PHRASEBOOK_ENTRY,
  SAVE_PHRASEBOOK_ENTRY,
  DELETE_PHRASEBOOK_ENTRY,
  RESET_PHRASEBOOK_ENTRY,
  LOAD_COURSE_GLOSSARY_ENTRIES,
  LOAD_COMPLETION_REPORT,
  CREATE_GLOSSARY_ENTRY,
  SAVE_GLOSSARY_ENTRY,
  DELETE_GLOSSARY_ENTRY,
  RESET_GLOSSARY_ENTRY,
  FIND_AND_REPLACE_SEARCH,
  FIND_AND_REPLACE_UPDATE,
  FIND_AND_REPLACE_UPDATE_ALL,
  AVERAGE_TIME_BEFORE_COMPLETION,
  LOAD_COURSE_ARTICLES,
  CREATE_COURSE_ARTICLE,
  SAVE_COURSE_ARTICLE,
  DELETE_COURSE_ARTICLE,
  REMOVE_COURSE_ARTICLE,
  RESET_COURSE_ARTICLE,
  REORDER_COURSE_ARTICLE,
  COPY_ARTICLE_FORM_DATA,
  COPY_ARTICLE,
} from 'store/courses/actions';
import { SET_NOTIFICATION } from 'store/flashNotifications/actions';

function* loadCourses() {
  const state = yield select();
  if (!state.courses.data.length) {
    try {
      const { data } = yield fetchGet('/api/shared/courses', {});
      yield put(LOAD_COURSES.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COURSES);
    }
  } else {
    yield put(LOAD_COURSES.success({}, { cached: true }));
  }
}

function* loadCourse({ payload }) {
  const state = yield select();
  if (!state.courses.courseIds.length || !state.courses.courseIds.some((course) => course.id === payload)) {
    try {
      const data = yield fetchGet(`/api/shared/courses/${payload}`, {});
      yield put(LOAD_COURSE.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COURSE);
    }
  } else {
    yield put(LOAD_COURSE.success({}, { cached: true }));
  }
}

function* createCourse({ payload, meta }) {
  try {
    const { data } = yield fetchPost('/api/shared/courses', payload);
    yield delay(800);
    yield put(CREATE_COURSE.success(data));
    yield put(SET_NOTIFICATION.action({
      message: `${data.displayName} created successfully.`,
      type: 'success',
    }));
    yield put(MERGE_COURSE.action());
  } catch (err) {
    yield delay(400);
    yield handleSimpleFetchError(err, CREATE_COURSE);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* updateCourse({ payload, meta }) {
  try {
    const { data } = yield fetchPatch(`/api/shared/courses/${payload.id}`, payload);
    yield delay(800);
    yield put(UPDATE_COURSE.success(data));
    yield put(SET_NOTIFICATION.action({
      message: `${data.displayName} updated successfully.`,
      type: 'success',
    }));
    yield put(RESET_COURSE_STATUS.action());
  } catch (err) {
    yield delay(400);
    yield handleSimpleFetchError(err, UPDATE_COURSE);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* publishCourse({ payload }) {
  try {
    const { data } = yield fetchPost(`/api/shared/courses/${payload}/publish`, {});
    yield put(PUBLISH_COURSE.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, PUBLISH_COURSE);
  }
}

function* archiveCourse({ payload }) {
  try {
    const { data } = yield fetchPatch(`/api/shared/courses/${payload}/archive`, {});
    yield delay(800);
    yield put(ARCHIVE_COURSE.success({ data }));
    yield put(SET_NOTIFICATION.action({
      message: `${data.displayName} archived successfully.`,
      type: 'success',
    }));
  } catch (err) {
    yield handleSimpleFetchError(err, ARCHIVE_COURSE);
  }
}

function* duplicateCourse({ payload, meta }) {
  yield delay(800);
  try {
    const { data } = yield fetchPost(`/api/shared/courses/${payload.courseId}/duplicate`, payload);
    yield put(DUPLICATE_COURSE.success({ data }));

    if (!payload.copyToCourseTypeId) {
      yield put(DUPLICATE_COURSE_UPDATE_STATUS.action({ data }));
    }
  } catch (err) {
    yield handleSimpleFetchError(err, DUPLICATE_COURSE);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* loadCourseTypeCourses({ payload }) {
  const { courses, courseTypes } = yield select();
  const courseType = courseTypes.byId[payload.id];
  if (!courses?.courseIds.length || courses?.courseIds.every((id) => courseType?.courseIds.includes(id))) {
    try {
      const data = yield fetchGet(`/api/${payload.workspace}/course_types/${payload.id}/courses`, {});
      yield put(LOAD_COURSE_TYPE_COURSES.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COURSE_TYPE_COURSES);
    }
  } else {
    yield put(LOAD_COURSE_TYPE_COURSES.success({}, { cached: true }));
  }
}

function* loadCourseTypeArchivedCourses({ payload: courseTypeId }) {
  const { courses, courseTypes } = yield select();
  const courseType = courseTypes.byId[courseTypeId];
  if (!courses?.courseIds.length || courses?.courseIds.length !== courseType?.courseIds.length) {
    try {
      const data = yield fetchGet(`/api/shared/course_types/${courseTypeId}/archived_courses`, {});
      yield put(LOAD_COURSE_TYPE_ARCHIVED_COURSES.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COURSE_TYPE_ARCHIVED_COURSES);
    }
  } else {
    yield put(LOAD_COURSE_TYPE_ARCHIVED_COURSES.success({}, { cached: true }));
  }
}

function* loadCourseGroups({ payload: courseId }) {
  const { courses } = yield select();
  const { groupIds, groups } = courses.data[courseId] || {};

  if (groupIds && (!groups || groupIds.length !== groups.length)) {
    try {
      const data = yield fetchGet(`/api/shared/courses/${courseId}/groups`);
      yield put(LOAD_COURSE_GROUPS.success({ courseId, ...data }, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COURSE_GROUPS);
    }
  } else {
    yield put(LOAD_COURSE_GROUPS.success({}, { cached: true }));
  }
}

function* loadCourseSections({ payload: courseId }) {
  const { courses } = yield select();
  const courseSectionIds = courses.data[courseId]?.sectionIds;
  const sectionIds = Object.keys(courses.sections || {}).map((id) => Number(id));

  if (courseSectionIds && !_isEqual(courseSectionIds, sectionIds)) {
    try {
      const data = yield fetchGet(`/api/shared/courses/${courseId}/sections`);
      yield put(LOAD_COURSE_SECTIONS.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COURSE_SECTIONS);
    }
  } else {
    yield put(LOAD_COURSE_SECTIONS.success({}, { cached: true }));
  }
}

function* createTask({ payload, meta }) {
  try {
    const data = yield fetchPost('/api/curriculum/tasks', payload);
    yield delay(800);
    yield call(meta.formikActions.resetForm); // allow navigation to new task, w/o confirmation
    yield put(CREATE_TASK.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Task created successfully.',
      type: 'success',
    }));
    yield put(RESET_TASK.action());
  } catch (err) {
    yield handleSimpleFetchError(err, CREATE_TASK);
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* saveTask({ payload, meta }) {
  try {
    const task = yield fetchPatch(`/api/curriculum/tasks/${payload.id}`, payload, { preserveKeys: ['_destroy'] });
    yield delay(800);
    yield put(SAVE_TASK.success(task));
    yield put(SET_NOTIFICATION.action({
      message: 'Task saved successfully.',
      type: 'success',
    }));
    yield put(RESET_TASK.action());

    if (task.type === TASK_TYPES.POD_DISCUSSION) {
      yield put(LOAD_TASK_PROACTIVE.request(task.id));
    }
  } catch (err) {
    yield handleSimpleFetchError(err, SAVE_TASK);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* createSection({ payload, meta }) {
  try {
    const data = yield fetchPost('/api/curriculum/sections', payload);
    yield delay(800);
    yield call(meta.formikActions.resetForm);
    yield put(CREATE_SECTION.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Module created successfully.',
      type: 'success',
    }));
    yield put(RESET_SECTION.action());
  } catch (err) {
    yield handleSimpleFetchError(err, CREATE_SECTION);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* saveSection({ payload, meta }) {
  try {
    const data = yield fetchPatch(`/api/curriculum/sections/${payload.id}`, payload);
    yield delay(800);
    yield put(SAVE_SECTION.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Module saved successfully.',
      type: 'success',
    }));
    yield put(RESET_SECTION.action());
  } catch (err) {
    yield handleSimpleFetchError(err, SAVE_SECTION);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* deleteSection({ payload }) {
  try {
    yield fetchDelete(`/api/shared/sections/${payload}`);
    yield delay(800);
    yield put(DELETE_SECTION.success());
    yield put(REMOVE_SECTION.action(payload));
  } catch (err) {
    yield handleSimpleFetchError(err, DELETE_SECTION);
  }
}

function* deleteTask({ payload }) {
  try {
    yield fetchDelete(`/api/curriculum/tasks/${payload}`);
    yield put(DELETE_TASK.success());
    yield put(REMOVE_TASK.action(payload));
  } catch (err) {
    yield handleSimpleFetchError(err, DELETE_TASK);
  }
}

function* loadFormData() {
  const { courses } = yield select();
  if (_isEmpty(courses.formData)) {
    try {
      const { data } = yield fetchGet('/api/shared/courses/form_data', {});
      yield put(LOAD_FORM_DATA.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_FORM_DATA);
    }
  } else {
    yield put(LOAD_FORM_DATA.success({}, { cached: true }));
  }
}

function* reorderCourseSection({ payload }) {
  const { courses } = yield select();
  if (courses) {
    try {
      yield retry(3, 2000, fetchPatch, `/api/shared/courses/${payload.courseId}/reorder_sections`, { sections: courses.data[payload.courseId]?.sectionIds });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  }
}

function* reorderCourseSectionTask({ payload }) {
  const { courses } = yield select();
  if (courses) {
    try {
      yield retry(3, 2000, fetchPatch, `/api/shared/sections/${payload.sectionId}/reorder_tasks`, { tasks: courses.sections[payload.sectionId]?.taskIds });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  }
}

function* reorderQuestions({ payload }) {
  const { courses } = yield select();
  if (courses) {
    try {
      yield retry(3, 2000, fetchPatch, `/api/curriculum/tasks/${payload.taskId}/reorder_questions`, { questions: courses.questions.data });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  }
}

function* copyTaskFormData() {
  const state = yield select();
  if (state) {
    try {
      const data = yield fetchGet('/api/curriculum/tasks/copy_task_form_data');
      yield put(COPY_TASK_FORM_DATA.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, COPY_TASK_FORM_DATA);
    }
  } else {
    yield put(COPY_TASK_FORM_DATA.success({}, { cached: true }));
  }
}

function* copyTask({ payload }) {
  try {
    const { data } = yield fetchPost('/api/curriculum_developer/task_clonings', payload);
    yield put(COPY_TASK.success(data, { cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, COPY_TASK);
  }
}

function* createUrlTaskResource({ payload, meta }) {
  try {
    const data = yield fetchPost('/api/curriculum/url_task_resources', payload);
    yield delay(400);
    yield put(CREATE_URL_TASK_RESOURCE.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, CREATE_URL_TASK_RESOURCE);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* createFileTaskResource({ payload, meta }) {
  try {
    const data = yield fetchPost('/api/curriculum/file_task_resources', payload, { formData: true });
    yield put(CREATE_FILE_TASK_RESOURCE.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, CREATE_FILE_TASK_RESOURCE);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}
// fetchPatch, `/api/curriculum/tasks/${payload.taskId}/reorder_questions`
function* createCompletionThreshold({ payload, meta }) {
  try {
    const data = yield fetchPatch(`/api/curriculum/tasks/${payload.taskId}/update_completion_threshold`, payload, { formData: true });
    yield put(UPDATE_COMPLETION_THRESHOLD.success(data));
    yield put(RESET_TASK.action());
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_COMPLETION_THRESHOLD);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* averageTimeBeforeCompletion({ payload }) {
  const { courses } = yield select();
  if (!courses.tasks[payload]?.averageTimeBeforeCompletion) {
    try {
      const data = yield fetchGet(`/api/curriculum/tasks/${payload}/average_time_before_completion`);
      yield put(AVERAGE_TIME_BEFORE_COMPLETION.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, AVERAGE_TIME_BEFORE_COMPLETION);
    }
  } else {
    yield put(AVERAGE_TIME_BEFORE_COMPLETION.success({}, { cached: true }));
  }
}

function* destroyResource({ payload }) {
  try {
    const data = yield fetchDelete(`/api/curriculum/resources/${payload.id}`, payload);
    yield put(DESTROY_RESOURCE.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, DESTROY_RESOURCE);
  }
}

function* loadTaskQuestions({ payload }) {
  const { courses } = yield select();
  if (!courses.questions.data.length || !courses.questions.data.every((question) => question.taskId === payload.id)) {
    try {
      const { data } = yield fetchGet(`/api/${payload.workspace}/tasks/${payload.id}/questions`);
      yield put(LOAD_TASK_QUESTIONS.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_TASK_QUESTIONS);
    }
  } else {
    yield put(LOAD_TASK_QUESTIONS.success({}, { cached: true }));
  }
}

function* loadTaskProactive({ payload }) {
  const { courses } = yield select();
  if (_isEmpty(courses.proactiveTemplate.data) || courses.proactiveTemplate.data.taskId !== payload.id) {
    try {
      const { data } = yield fetchGet(`/api/${payload.workspace}/tasks/${payload.id}/proactive_template`);
      yield put(LOAD_TASK_PROACTIVE.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_TASK_PROACTIVE);
    }
  } else {
    yield put(LOAD_TASK_PROACTIVE.success({}, { cached: true }));
  }
}

function* loadCourseProactives({ payload }) {
  const { courses } = yield select();
  if (_isEmpty(courses.proactiveTemplates.data) || Object.values(courses.proactiveTemplates.data)?.[0]?.courseId !== payload) {
    try {
      const { data } = yield fetchGet(`/api/curriculum/courses/${payload}/proactive_templates`);
      yield put(LOAD_COURSE_PROACTIVES.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COURSE_PROACTIVES);
    }
  } else {
    yield put(LOAD_COURSE_PROACTIVES.success({}, { cached: true }));
  }
}

function* loadCoursePhrasebookEntries({ payload }) {
  const { courses } = yield select();
  if (!courses.phrasebookEntries.allIds?.length || Object.values(courses.phrasebookEntries.byId)?.[0]?.courseId !== payload.courseId) {
    try {
      const { data } = yield fetchGet(`/api/${payload.workspace}/courses/${payload.courseId}/phrasebook_entries`);
      yield put(LOAD_COURSE_PHRASEBOOK_ENTRIES.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COURSE_PHRASEBOOK_ENTRIES);
    }
  } else {
    yield put(LOAD_COURSE_PHRASEBOOK_ENTRIES.success({}, { cached: true }));
  }
}

function* createPhrasebookEntry({ payload, meta }) {
  try {
    const data = yield fetchPost('/api/curriculum/phrasebook_entries', payload);
    yield delay(800);
    yield put(CREATE_PHRASEBOOK_ENTRY.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Entry created successfully.',
      type: 'success',
    }));
    yield put(RESET_PHRASEBOOK_ENTRY.action());
  } catch (err) {
    yield handleSimpleFetchError(err, CREATE_PHRASEBOOK_ENTRY);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* savePhrasebookEntry({ payload, meta }) {
  try {
    const data = yield fetchPatch(`/api/curriculum/phrasebook_entries/${payload.id}`, payload);
    yield delay(800);
    yield put(SAVE_PHRASEBOOK_ENTRY.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Entry saved successfully.',
      type: 'success',
    }));
  } catch (err) {
    yield handleSimpleFetchError(err, SAVE_PHRASEBOOK_ENTRY);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* deletePhrasebookEntry({ payload }) {
  try {
    const data = yield fetchDelete(`/api/curriculum/phrasebook_entries/${payload}`);
    yield delay(400);
    yield put(DELETE_PHRASEBOOK_ENTRY.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Entry deleted successfully.',
      type: 'success',
    }));
    yield put(RESET_PHRASEBOOK_ENTRY.action());
  } catch (err) {
    yield handleSimpleFetchError(err, DELETE_PHRASEBOOK_ENTRY);
  }
}

function* loadCourseGlossaryEntries({ payload }) {
  const { courses } = yield select();
  if (!courses.glossaryEntries.allIds?.length || Object.values(courses.glossaryEntries.byId)?.[0]?.courseId !== payload.courseId) {
    try {
      const { data } = yield fetchGet(`/api/${payload.workspace}/courses/${payload.courseId}/glossary_entries`);
      yield put(LOAD_COURSE_GLOSSARY_ENTRIES.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COURSE_GLOSSARY_ENTRIES);
    }
  } else {
    yield put(LOAD_COURSE_GLOSSARY_ENTRIES.success({}, { cached: true }));
  }
}

function* loadCompletionReport({ payload }) {
  const { courses } = yield select();
  if (courses.completionReport.courseId !== Number(payload)) {
    try {
      const { data } = yield fetchGet(`/api/${payload.workspace}/courses/${payload.courseId}/completion_report`);
      yield put(LOAD_COMPLETION_REPORT.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COMPLETION_REPORT);
    }
  } else {
    yield put(LOAD_COMPLETION_REPORT.success({}, { cached: true }));
  }
}

function* createGlossaryEntry({ payload, meta }) {
  try {
    const data = yield fetchPost('/api/curriculum/glossary_entries', payload);
    yield delay(800);
    yield put(CREATE_GLOSSARY_ENTRY.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Entry created successfully.',
      type: 'success',
    }));
    yield put(RESET_GLOSSARY_ENTRY.action());
  } catch (err) {
    yield handleSimpleFetchError(err, CREATE_GLOSSARY_ENTRY);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* saveGlossaryEntry({ payload, meta }) {
  try {
    const data = yield fetchPatch(`/api/curriculum/glossary_entries/${payload.id}`, payload);
    yield delay(800);
    yield put(SAVE_GLOSSARY_ENTRY.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Entry saved successfully.',
      type: 'success',
    }));
  } catch (err) {
    yield handleSimpleFetchError(err, SAVE_GLOSSARY_ENTRY);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* deleteGlossaryEntry({ payload }) {
  try {
    const data = yield fetchDelete(`/api/curriculum/glossary_entries/${payload}`);
    yield delay(400);
    yield put(DELETE_GLOSSARY_ENTRY.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Entry deleted successfully.',
      type: 'success',
    }));
    yield put(RESET_GLOSSARY_ENTRY.action());
  } catch (err) {
    yield handleSimpleFetchError(err, DELETE_GLOSSARY_ENTRY);
  }
}

function* findAndReplaceSearch({ payload, meta }) {
  let url = '/api/curriculum/find_and_replace/search';
  if (payload.workspace === 'facilitator') {
    url = `/api/facilitator/courses/${payload.courseId}/search`;
  }

  try {
    const { data } = yield fetchGet(url, payload);
    yield put(FIND_AND_REPLACE_SEARCH.success(data, { cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, FIND_AND_REPLACE_SEARCH);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* findAndReplaceUpdate({ payload }) {
  try {
    const data = yield fetchPatch('/api/curriculum/find_and_replace/update', payload);
    yield delay(400);
    yield put(FIND_AND_REPLACE_UPDATE.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, FIND_AND_REPLACE_UPDATE);
  }
}

function* findAndReplaceUpdateAll({ payload }) {
  try {
    const data = yield fetchPatch('/api/curriculum/find_and_replace/update_all', payload);
    yield delay(400);
    yield put(FIND_AND_REPLACE_UPDATE_ALL.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, FIND_AND_REPLACE_UPDATE_ALL);
  }
}

function* loadCourseArticles({ payload }) {
  const { courses } = yield select();
  if (!courses.articles.allIds?.length || Object.values(courses.articles.byId)?.[0]?.courseId !== Number(payload.courseId)) {
    try {
      const { data } = yield fetchGet(`/api/${payload.workspace}/courses/${payload.courseId}/articles`);
      yield put(LOAD_COURSE_ARTICLES.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COURSE_ARTICLES);
    }
  } else {
    yield put(LOAD_COURSE_ARTICLES.success({}, { cached: true }));
  }
}

function* createCourseArticle({ payload, meta }) {
  try {
    const data = yield fetchPost('/api/curriculum/articles', payload);
    yield delay(800);
    yield call(meta.formikActions.resetForm);
    yield put(CREATE_COURSE_ARTICLE.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Article created successfully.',
      type: 'success',
    }));
    yield put(RESET_COURSE_ARTICLE.action());
  } catch (err) {
    yield handleSimpleFetchError(err, CREATE_COURSE_ARTICLE);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* saveCourseArticle({ payload, meta }) {
  try {
    const data = yield fetchPatch(`/api/curriculum/articles/${payload.id}`, payload);
    yield delay(800);
    yield put(SAVE_COURSE_ARTICLE.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Article saved successfully.',
      type: 'success',
    }));
  } catch (err) {
    yield handleSimpleFetchError(err, SAVE_COURSE_ARTICLE);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* deleteCourseArticle({ payload }) {
  try {
    const data = yield fetchDelete(`/api/curriculum/articles/${payload}`);
    yield delay(400);
    yield put(DELETE_COURSE_ARTICLE.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Article deleted successfully.',
      type: 'success',
    }));
    yield put(REMOVE_COURSE_ARTICLE.action(payload));
    yield put(RESET_COURSE_ARTICLE.action());
  } catch (err) {
    yield handleSimpleFetchError(err, DELETE_COURSE_ARTICLE);
  }
}

function* reorderCourseArticle({ payload }) {
  const { courses } = yield select();
  if (courses.articles.allIds.length) {
    try {
      yield retry(3, 2000, fetchPatch, `/api/curriculum/courses/${payload.courseId}/reorder_articles`, { articles: courses.articles.allIds });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  }
}

function* copyArticle({ payload }) {
  try {
    const data = yield fetchPost('/api/curriculum/articles/copy', payload);
    yield put(COPY_ARTICLE.success(data, { cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, COPY_ARTICLE);
  }
}

function* copyArticleFormData() {
  const { courses } = yield select();
  if (!courses.formData.copyArticleFormData) {
    try {
      const data = yield fetchGet('/api/curriculum/articles/copy_article_form_data');
      yield put(COPY_ARTICLE_FORM_DATA.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, COPY_ARTICLE_FORM_DATA);
    }
  } else {
    yield put(COPY_ARTICLE_FORM_DATA.success({}, { cached: true }));
  }
}

export default function* sagas() {
  yield takeLatest(LOAD_COURSES.REQUEST, loadCourses);
  yield takeLatest(LOAD_COURSE.REQUEST, loadCourse);
  yield takeLatest(LOAD_COURSE_GROUPS.REQUEST, loadCourseGroups);
  yield takeLatest(LOAD_COURSE_SECTIONS.REQUEST, loadCourseSections);
  yield takeLatest(CREATE_COURSE.REQUEST, createCourse);
  yield takeLatest(UPDATE_COURSE.REQUEST, updateCourse);
  yield takeLatest(PUBLISH_COURSE.REQUEST, publishCourse);
  yield takeLatest(ARCHIVE_COURSE.REQUEST, archiveCourse);
  yield takeLatest(DUPLICATE_COURSE.REQUEST, duplicateCourse);
  yield takeLatest(LOAD_COURSE_TYPE_COURSES.REQUEST, loadCourseTypeCourses);
  yield takeLatest(LOAD_COURSE_TYPE_ARCHIVED_COURSES.REQUEST, loadCourseTypeArchivedCourses);
  yield takeLatest(CREATE_TASK.REQUEST, createTask);
  yield takeLatest(SAVE_TASK.REQUEST, saveTask);
  yield takeLatest(CREATE_SECTION.REQUEST, createSection);
  yield takeLatest(SAVE_SECTION.REQUEST, saveSection);
  yield takeLatest(DELETE_SECTION.REQUEST, deleteSection);
  yield takeLatest(DELETE_TASK.REQUEST, deleteTask);
  yield takeLatest(LOAD_FORM_DATA.REQUEST, loadFormData);
  yield takeLatest(COPY_TASK_FORM_DATA.REQUEST, copyTaskFormData);
  yield takeLatest(REORDER_COURSE_SECTION.SYNC, reorderCourseSection);
  yield takeLatest(REORDER_COURSE_SECTION_TASK.SYNC, reorderCourseSectionTask);
  yield takeLatest(REORDER_QUESTIONS.SYNC, reorderQuestions);
  yield takeLatest(COPY_TASK.REQUEST, copyTask);
  yield takeLatest(CREATE_FILE_TASK_RESOURCE.REQUEST, createFileTaskResource);
  yield takeLatest(CREATE_URL_TASK_RESOURCE.REQUEST, createUrlTaskResource);
  yield takeLatest(UPDATE_COMPLETION_THRESHOLD.REQUEST, createCompletionThreshold);
  yield takeLatest(DESTROY_RESOURCE.REQUEST, destroyResource);
  yield takeLatest(LOAD_TASK_QUESTIONS.REQUEST, loadTaskQuestions);
  yield takeLatest(LOAD_TASK_PROACTIVE.REQUEST, loadTaskProactive);
  yield takeLatest(LOAD_COURSE_PROACTIVES.REQUEST, loadCourseProactives);
  yield takeLatest(LOAD_COURSE_PHRASEBOOK_ENTRIES.REQUEST, loadCoursePhrasebookEntries);
  yield takeLatest(CREATE_PHRASEBOOK_ENTRY.REQUEST, createPhrasebookEntry);
  yield takeLatest(SAVE_PHRASEBOOK_ENTRY.REQUEST, savePhrasebookEntry);
  yield takeLatest(DELETE_PHRASEBOOK_ENTRY.REQUEST, deletePhrasebookEntry);
  yield takeLatest(LOAD_COURSE_GLOSSARY_ENTRIES.REQUEST, loadCourseGlossaryEntries);
  yield takeLatest(LOAD_COMPLETION_REPORT.REQUEST, loadCompletionReport);
  yield takeLatest(CREATE_GLOSSARY_ENTRY.REQUEST, createGlossaryEntry);
  yield takeLatest(SAVE_GLOSSARY_ENTRY.REQUEST, saveGlossaryEntry);
  yield takeLatest(DELETE_GLOSSARY_ENTRY.REQUEST, deleteGlossaryEntry);
  yield takeLatest(FIND_AND_REPLACE_SEARCH.REQUEST, findAndReplaceSearch);
  yield takeLatest(FIND_AND_REPLACE_UPDATE.REQUEST, findAndReplaceUpdate);
  yield takeLatest(FIND_AND_REPLACE_UPDATE_ALL.REQUEST, findAndReplaceUpdateAll);
  yield takeLatest(LOAD_COURSE_ARTICLES.REQUEST, loadCourseArticles);
  yield takeLatest(CREATE_COURSE_ARTICLE.REQUEST, createCourseArticle);
  yield takeLatest(SAVE_COURSE_ARTICLE.REQUEST, saveCourseArticle);
  yield takeLatest(DELETE_COURSE_ARTICLE.REQUEST, deleteCourseArticle);
  yield takeLatest(REORDER_COURSE_ARTICLE.SYNC, reorderCourseArticle);
  yield takeLatest(COPY_ARTICLE_FORM_DATA.REQUEST, copyArticleFormData);
  yield takeLatest(COPY_ARTICLE.REQUEST, copyArticle);
  yield takeLatest(AVERAGE_TIME_BEFORE_COMPLETION.REQUEST, averageTimeBeforeCompletion);
}
