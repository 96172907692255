import React from 'react';

function FileText() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 1.5H18C18.7956 1.5 19.5587 1.81607 20.1213 2.37868C20.6839 2.94129 21 3.70435 21 4.5V19.5C21 20.2956 20.6839 21.0587 20.1213 21.6213C19.5587 22.1839 18.7956 22.5 18 22.5H6C5.20435 22.5 4.44129 22.1839 3.87868 21.6213C3.31607 21.0587 3 20.2956 3 19.5V4.5C3 3.70435 3.31607 2.94129 3.87868 2.37868C4.44129 1.81607 5.20435 1.5 6 1.5ZM6 3C5.60218 3 5.22064 3.15804 4.93934 3.43934C4.65804 3.72064 4.5 4.10218 4.5 4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V4.5C19.5 4.10218 19.342 3.72064 19.0607 3.43934C18.7794 3.15804 18.3978 3 18 3H6Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.75 15.75C6.75 15.5511 6.82902 15.3603 6.96967 15.2197C7.11032 15.079 7.30109 15 7.5 15H12C12.1989 15 12.3897 15.079 12.5303 15.2197C12.671 15.3603 12.75 15.5511 12.75 15.75C12.75 15.9489 12.671 16.1397 12.5303 16.2803C12.3897 16.421 12.1989 16.5 12 16.5H7.5C7.30109 16.5 7.11032 16.421 6.96967 16.2803C6.82902 16.1397 6.75 15.9489 6.75 15.75ZM6.75 12.75C6.75 12.5511 6.82902 12.3603 6.96967 12.2197C7.11032 12.079 7.30109 12 7.5 12H16.5C16.6989 12 16.8897 12.079 17.0303 12.2197C17.171 12.3603 17.25 12.5511 17.25 12.75C17.25 12.9489 17.171 13.1397 17.0303 13.2803C16.8897 13.421 16.6989 13.5 16.5 13.5H7.5C7.30109 13.5 7.11032 13.421 6.96967 13.2803C6.82902 13.1397 6.75 12.9489 6.75 12.75ZM6.75 9.75C6.75 9.55109 6.82902 9.36032 6.96967 9.21967C7.11032 9.07902 7.30109 9 7.5 9H16.5C16.6989 9 16.8897 9.07902 17.0303 9.21967C17.171 9.36032 17.25 9.55109 17.25 9.75C17.25 9.94891 17.171 10.1397 17.0303 10.2803C16.8897 10.421 16.6989 10.5 16.5 10.5H7.5C7.30109 10.5 7.11032 10.421 6.96967 10.2803C6.82902 10.1397 6.75 9.94891 6.75 9.75ZM6.75 6.75C6.75 6.55109 6.82902 6.36032 6.96967 6.21967C7.11032 6.07902 7.30109 6 7.5 6H16.5C16.6989 6 16.8897 6.07902 17.0303 6.21967C17.171 6.36032 17.25 6.55109 17.25 6.75C17.25 6.94891 17.171 7.13968 17.0303 7.28033C16.8897 7.42098 16.6989 7.5 16.5 7.5H7.5C7.30109 7.5 7.11032 7.42098 6.96967 7.28033C6.82902 7.13968 6.75 6.94891 6.75 6.75Z" fill="currentColor" />
    </svg>
  );
}

export default FileText;
