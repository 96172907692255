import { GET_PAYMENT_TRANSACTIONS } from './actions';

export const initialState = {
  searching: false,
  filters: {},
  paymentTransactions: null,
  totalResults: 0,
  page: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_TRANSACTIONS.REQUEST:
      return {
        ...state,
        searching: true,
        filters: action.payload,
      };
    case GET_PAYMENT_TRANSACTIONS.SUCCESS:
      return {
        ...state,
        searching: false,
        paymentTransactions: action.payload.data.paymentTransactions,
        totalResults: action.payload.meta.totalResults,
        page: action.payload.meta.page,
      };
    case GET_PAYMENT_TRANSACTIONS.ERROR:
      return {
        ...state,
        searching: false,
      };
    default:
      return state;
  }
};
