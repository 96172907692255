import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import XLSX from 'xlsx';
import { parse } from 'papaparse';
import { fetchPut } from 'lib/apiHelpers';
import { GroupPropType, OrganizationPropType, BulkImportFormDataPropType } from 'lib/propTypes';
import { normalizeEmail, randomHash, sentenceCase } from 'lib/utils';
import { USER_PROP_MAP, REQUIRED_USER_PROPS, SPREADSHEET_FILES } from 'pages/groups/GroupParticipantsBulkImport/constants';
import { Button, Spinner } from 'react-bootstrap';

const BulkImportReviewAll = (groupId, props) => fetchPut(`/api/registrar/groups/${groupId}/bulk_imports/review_all.json`, props);

async function onReview({ groupId, organizationId, participants }) {
  const response = await BulkImportReviewAll(groupId, {
    participants,
    default_organization_id: organizationId,
    group_id: groupId,
  });
  return R.indexBy(R.prop('key'), response.data);
}

const VALID_LANGUAGE_PREFERENCES = {
  English: ['en', 'english'],
  French: ['fr', 'french', 'francais', 'français'],
};

const updateOutput = (mappedOutput, referenceData) => mappedOutput.map((o) => {
  const updates = {};

  if (o.email) {
    updates.email = normalizeEmail(o.email);
  }
  if (o.authorizerEmail) {
    updates.authorizerEmail = normalizeEmail(o.authorizerEmail);
  }
  if (o.ageGroup) {
    updates.ageGroup = o.ageGroup.replace('–', '-');
  }

  if (o.languagePreference) {
    updates.languagePreference = Object.entries(VALID_LANGUAGE_PREFERENCES)
      .find(([, v]) => v.includes(o.languagePreference.toLowerCase()))?.[0] || undefined;
  }

  if (o.jobCategory && !referenceData.jobCategory.find((jc) => jc.value === o.jobCategory)) {
    updates.jobCategory = 'Other';
    updates.jobCategoryOther = o.jobCategory;
  }

  return {
    ...o,
    ...updates,
  };
});

function Verify({ group, organizations, formData, setUploadedUsers }) {
  const { values, dirty, errors, isSubmitting } = useFormikContext();

  const setParticipants = useCallback(async (results) => {
    if (results.length > 0) {
      const validations = await onReview({ groupId: group?.id, organizationId: values.organizationId, participants: results });
      const users = results.map((u) => {
        const { key, userId, groupMembershipId, groupName, groupId, errorCode, message, ...errorKeys } = validations[u.key];
        const fieldErrors = Object.keys(errorKeys).reduce((cur, k) => ({
          ...cur,
          [k]: `${sentenceCase(k)} ${errorKeys[k].join(', ')}`,
        }), {});

        return { ...u, userId, groupMembershipId, groupName, groupId, errorCode, message, errors: fieldErrors };
      });
      setUploadedUsers({ users, organizationId: values.organizationId });
    }
  }, [setUploadedUsers, group?.id, values.organizationId]);

  const parseFile = useCallback(async (file) => {
    const organization = values.organization || organizations.find((org) => org.id === group?.organizationId)?.shortName;

    let output = null;
    if (SPREADSHEET_FILES.includes(file.type)) {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      output = XLSX.utils.sheet_to_json(firstSheet);
    }

    if (file.type === 'text/csv') {
      const text = await file.text();
      const parsed = parse(text, { header: true });
      output = parsed.data;
    }

    const userPropMapArray = R.toPairs(USER_PROP_MAP);
    const mappedOutput = output.map((o) => {
      const oKeys = R.toPairs(o).reduce((cur, [key, value]) => {
        const mappedPropObject = userPropMapArray.reduce((c, [k, v]) => {
          if (v.includes(key.trim().toLowerCase())) {
            return {
              [k]: value.toString(),
            };
          }
          return c;
        }, {});
        return {
          ...cur,
          ...mappedPropObject,
        };
      }, REQUIRED_USER_PROPS);
      return {
        ...REQUIRED_USER_PROPS,
        organization, // set default Organization
        ...R.reject(R.isEmpty, oKeys),
        key: randomHash(),
      };
    });

    const updatedOutput = updateOutput(mappedOutput, formData);
    setParticipants(updatedOutput);
  }, [setParticipants, organizations, formData, values.organization, group?.organizationId]);

  const validateFile = useCallback(() => {
    const { file } = values;

    if (file) {
      parseFile(file);
    }

    return () => true;
  }, [values, parseFile]);

  if (!group) return null;

  return (
    <Button variant="primary" disabled={errors.file || !dirty || isSubmitting} type="submit" onClick={validateFile}>
      Continue
      {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
    </Button>
  );
}

Verify.defaultProps = {
  group: null,
  organizations: [],
  formData: {},
  setUploadedUsers: () => {},
};

Verify.propTypes = {
  group: GroupPropType,
  organizations: PropTypes.arrayOf(OrganizationPropType),
  formData: BulkImportFormDataPropType,
  setUploadedUsers: PropTypes.func,
};

export default Verify;
