import React from 'react';
import PropTypes from 'prop-types';
import add from 'date-fns/add';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import parseISO from 'date-fns/parseISO';
import { COMPACT_DATE, TZ_UTC } from 'lib/dateFormats';
import ReportStateBadge from 'components/reporting/ReportStateBadge';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

function ReportsListItem({ itemId }) {
  const {
    id,
    filters,
    state: reportState,
    requestedByUserName,
    createdAt,
    reportId,
    durationInSeconds,
  } = useSelector((state) => state.harmPreventionReport.reports.byId[itemId]);
  const reportReady = reportState === 'completed';

  return (
    <tr>
      <td>
        <span className="text-secondary font-monospace text-nowrap">
          {reportId}
        </span>
      </td>

      <td><ReportStateBadge reportState={reportState} /></td>

      <td>
        <ul className="list-inline">
          <li>
            <small className="text-secondary">Dates:</small>
            <small className="ps-1">
              {formatInTimeZone(parseISO(filters.startDate), TZ_UTC, COMPACT_DATE)}
              <span className="text-secondary"> to </span>
              {formatInTimeZone(parseISO(filters.endDate), TZ_UTC, COMPACT_DATE)}
            </small>
          </li>
        </ul>
      </td>

      <td>
        <small className="text-secondary">
          {formatDistanceToNow(parseISO(createdAt), { addSuffix: true })}
          <br />
          {`by ${requestedByUserName}`}
        </small>
      </td>

      <td>
        {reportReady && (
          <small className="text-secondary">
            {formatDistanceToNow(add(new Date(), { seconds: durationInSeconds }))}
          </small>
        )}

        {!reportReady && (
          <span className="text-secondary">&hellip;</span>
        )}
      </td>

      <td className="text-end">
        <Button
          variant={reportReady ? 'success' : 'light'}
          size="sm"
          disabled={!reportReady}
          href={reportReady ? `/api/reporting/reports/harm_prevention/${id}` : undefined}
          download={reportReady}
        >
          <i className="fa fa-file-csv me-2" />
          Download as CSV
        </Button>
      </td>
    </tr>
  );
}

ReportsListItem.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default ReportsListItem;
