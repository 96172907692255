import { takeLatest, put, select, call, delay } from 'redux-saga/effects';
import { fetchGet, fetchPatch, fetchPost, handleSimpleFetchError } from 'lib/apiHelpers';
import { LOAD_USER, UPDATE_USER, LOAD_EMAIL_CONFIRMATIONS, RESET_LOGIN, RESET_USER_STATUS, RESEND_EMAIL_CONFIRMATION, UPDATE_USER_ACTIVATION } from 'store/user/actions';
import { SET_NOTIFICATION } from 'store/flashNotifications/actions';

function* loadUser({ payload }) {
  try {
    const { data } = yield fetchGet(`/api/users/${payload}/profile`);
    yield put(LOAD_USER.success(data, { cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, LOAD_USER);
  }
}

function* updateUser({ payload, meta }) {
  try {
    const { data } = yield fetchPatch(`/api/users/${payload.id}/profile`, payload, { preserveKeys: ['address1', 'address2'] });
    yield delay(800);
    yield put(UPDATE_USER.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'User profile saved successfully.',
      type: 'success',
    }));
    yield put(RESET_USER_STATUS.action());
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_USER);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* loadEmailConfirmations({ payload }) {
  const state = yield select();
  if (!state.user.emailConfirmations.data) {
    try {
      const { data } = yield fetchGet(`/api/users/${payload.id}/email_confirmations`, {});
      yield put(LOAD_EMAIL_CONFIRMATIONS.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_EMAIL_CONFIRMATIONS);
    }
  } else {
    yield put(LOAD_EMAIL_CONFIRMATIONS.success({}, { cached: true }));
  }
}

function* resetLogin({ payload }) {
  try {
    const { data } = yield fetchPatch(`/api/${payload.workspace || 'registrar'}/users/${payload.id || payload}/reset_login`);
    yield delay(800); // Show Pending for extra time
    yield put(RESET_LOGIN.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Public Display name reset successfully.',
      type: 'success',
    }));
    yield put(RESET_USER_STATUS.action());
  } catch (err) {
    yield handleSimpleFetchError(err, RESET_LOGIN);
  }
}

function* resendEmailConfirmation({ payload }) {
  try {
    yield fetchPost(`/api/users/${payload.id}/resend_email_confirmation`);
    yield delay(800); // Show Pending for extra time
    yield put(SET_NOTIFICATION.action({
      message: 'E-mail confirmation sent to registrant.',
      type: 'success',
    }));
  } catch (err) {
    yield handleSimpleFetchError(err, RESET_LOGIN);
  }
}

function* updateUserActivation({ payload }) {
  try {
    const { data } = yield fetchPatch(`/api/registrar/users/${payload.id}/${payload.action}`);
    yield delay(800); // Show Pending for extra time
    yield put(UPDATE_USER_ACTIVATION.success({ data }));
    yield put(SET_NOTIFICATION.action({
      message: `User ${data.activation} successfully.`,
      type: 'success',
    }));
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_USER_ACTIVATION);
  }
}

export default function* sagas() {
  yield takeLatest(LOAD_USER.REQUEST, loadUser);
  yield takeLatest(UPDATE_USER.REQUEST, updateUser);
  yield takeLatest(LOAD_EMAIL_CONFIRMATIONS.REQUEST, loadEmailConfirmations);
  yield takeLatest(RESET_LOGIN.REQUEST, resetLogin);
  yield takeLatest(RESEND_EMAIL_CONFIRMATION.SYNC, resendEmailConfirmation);
  yield takeLatest(UPDATE_USER_ACTIVATION.REQUEST, updateUserActivation);
}
