import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFieldContext } from 'lib/hooks/useFieldContext';
import { RefPropType } from 'lib/propTypes';
import { SIZES } from 'lib/constants';

/* eslint-disable react/jsx-props-no-spreading */

function Input({ name, type, isNumeric, isPositive, size, inputRef, ...props }) {
  const { id, value, touched, error, handleChange, handleBlur } = useFieldContext();

  const handleInputChange = useCallback((event) => {
    if (isNumeric || isPositive) {
      const re = new RegExp(`[^0-9.${isPositive ? '' : '-'}]`, 'g'); // disallow non-numeric characters
      const newValue = event.target.value.replace(re, '');
      handleChange(newValue);
    } else if (type === 'file') {
      handleChange(event.target.files[0]);
    } else {
      handleChange(event);
    }
  }, [isNumeric, isPositive, type, handleChange]);

  return (
    <input
      ref={inputRef}
      id={id}
      name={name}
      type={type}
      className={`form-control ${(touched && error) ? 'is-invalid' : ''} ${size !== SIZES.md ? `form-control-${size}` : ''}`}
      value={type === 'file' ? undefined : value}
      onChange={handleInputChange}
      onBlur={handleBlur}
      {...props}
    />
  );
}

Input.defaultProps = {
  disabled: false,
  maxLength: null,
  isNumeric: false,
  isPositive: false,
  placeholder: undefined,
  size: SIZES.md,
  inputRef: null,
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'text', 'email', 'number', 'url', 'tel', 'file',
  ]).isRequired,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  isNumeric: PropTypes.bool,
  isPositive: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  inputRef: RefPropType,
};

export default Input;
