import React, { useState } from 'react';
import { encodeGetURLParamsForRails } from 'lib/utils';
import { Button, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function CsvDownloadButton() {
  const filters = useSelector((state) => state.emailTransmissionReport.filters);
  const [downloading, setDownloading] = useState(false);
  const csvDownloadUrl = `/api/registrar/email_confirmations.csv?${encodeGetURLParamsForRails(filters)}`;

  const handleClick = () => {
    setDownloading(true);

    setTimeout(() => {
      setDownloading(false);
    }, 1200);
  };

  return (
    <Button
      className="btn btn-success"
      href={csvDownloadUrl}
      onClick={handleClick}
      disabled={downloading}
      download
    >
      <i className="fa fa-file-csv me-2" />
      Download as CSV
      {downloading && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
    </Button>
  );
}

export default CsvDownloadButton;
