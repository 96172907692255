import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectCourseById } from 'store/courses/selectors';
import { FindAndReplaceDataPropType } from 'lib/propTypes';
import { LEGACY_APP_DOMAIN } from 'config';
import { FIND_AND_REPLACE_UPDATE_ALL } from 'store/courses/actions';
import SanyasIcon from 'components/shared/SanyasIcon';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import ReviewChange from './ReviewChange';
import './styles.scss';

function SearchResults({ taskIds, data, findTerm, replaceTerm }) {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const course = useSelector((state) => selectCourseById(state, courseId));
  const [showUpdateAllModal, setShowUpdateAllModal] = useState(false);
  const [updateAllAvailable, setUpdateAllAvailable] = useState(true);

  const handleUpdateAll = useCallback(
    () => {
      dispatch(FIND_AND_REPLACE_UPDATE_ALL.request({ findTerm, replaceTerm, courseId }));
      setShowUpdateAllModal(false);
      setUpdateAllAvailable(false);
    },
    [courseId, dispatch, findTerm, replaceTerm],
  );

  return (
    <div className="row">
      {(taskIds?.length === 0) && (
        <div className="col-md-8 my-4 mx-auto">
          <p className="mb-0">0 tasks found</p>
          <hr className="mt-1" />
        </div>
      )}

      {(taskIds?.length > 0) && (
        <div className="col-md-8 my-4 mx-auto">
          <div className="row mb-0">
            <div className="col-md-6 d-flex align-bottom">
              <p className="align-items-end mb-0">
                {`${taskIds.length} tasks found`}
              </p>
            </div>
            {(!(replaceTerm === '')) && (updateAllAvailable) && (
              <div className="col-md-6 d-flex">
                <Button size="sm" className="ms-auto" onClick={() => setShowUpdateAllModal(true)}>
                  Update all
                </Button>
              </div>
            )}
          </div>
          <hr className="mt-2" />
          {taskIds?.map((taskId, index) => (
            <div key={taskId} className="row mt-3">
              {(data?.[taskId].updated) && (
                <h3>
                  <a
                    href={`${LEGACY_APP_DOMAIN}/tasks/${taskId}`}
                    target="_blank"
                    className="task-link"
                    rel="noreferrer"
                  >
                    {`${index + 1}. ${data?.[taskId].name}`}
                  </a>
                  <span className="updated-text">
                    <SanyasIcon name="checkmark" size="md" className="me-1" />
                    Updated!
                  </span>
                </h3>
              )}

              {(!data?.[taskId].updated) && (
                <ReviewChange data={data?.[taskId]} taskId={taskId} findTerm={findTerm} replaceTerm={replaceTerm} index={index} course={course} />
              )}
            </div>
          ))}
        </div>
      )}

      <DefaultModal
        size="md"
        isOpen={showUpdateAllModal}
        header="Update all?"
        onClose={() => setShowUpdateAllModal(false)}
        footerComponent={(
          <Button
            className="d-flex align-items-center"
            onClick={handleUpdateAll}
          >
            Update all
          </Button>
        )}
      >
        <p>
          {`Are you sure you want to update all ${taskIds.length} tasks?`}
        </p>
      </DefaultModal>
    </div>
  );
}

SearchResults.propTypes = {
  taskIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  data: PropTypes.shape({
    FindAndReplaceDataPropType,
  }).isRequired,
  findTerm: PropTypes.string.isRequired,
  replaceTerm: PropTypes.string.isRequired,
};

export default SearchResults;
