import * as Yup from 'yup';

const CourseFormSchema = Yup.object().shape({
  name: Yup.string().required('Course title is required'),
  isCore: Yup.bool().required(''),
  price: Yup.number().required('Cost is required').test(
    'valid-decimal',
    'Cost is not valid',
    // up to 8 digits before the optional decimal point, up to 2 digits after
    (value) => `${value}`.match(/^\d{0,8}.?\d{0,2}$/),
  ),
  nameFr: Yup.string()
    .test(
      'name_fr',
      'French name is required',
      (value, context) => {
        if (context.parent.isAvailableInFrench) return !!value?.trim().length;
        return true;
      },
    ),
  durationInWeeks: Yup.number()
    .integer()
    .min(1, 'Duration in weeks must be greater than 0')
    .required('Duration in weeks is required'),
  sittingTimeInHours: Yup.number()
    .integer()
    .min(1, 'Sitting in time hours must be greater than 0')
    .required('Sitting in time hours is required'),
  expireReservationsXDaysBeforeBegin: Yup.number()
    .integer()
    .min(1, 'Expire reservations must be greater than 0')
    .moreThan(Yup.ref('closeRegistrationXDaysBeforeBegin'), 'Expire reservations must be greater than Close registration')
    .required('Expire reservations is required'),
  closeRegistrationXDaysBeforeBegin: Yup.number()
    .integer()
    .min(1, 'Close registration must be greater than 0')
    .moreThan(Yup.ref('sendParticipantListsXDaysBeforeBegin'), 'Close registration must be greater than Send participant lists')
    .required('Close registration is required'),
  sendParticipantListsXDaysBeforeBegin: Yup.number()
    .integer()
    .min(1, 'Send participant lists must be greater than 0')
    .moreThan(Yup.ref('sendWelcomePacksXDaysBeforeBegin'), 'Send participant lists must be greater than Send welcome packs')
    .required('Send participant lists is required'),
  sendWelcomePacksXDaysBeforeBegin: Yup.number()
    .integer()
    .min(1, 'Send welcome packs must be greater than 0')
    .required('Send welcome packs is required'),
  theme: Yup.string().required(),
});

export default CourseFormSchema;
