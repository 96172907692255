import { asyncAction, syncAction } from 'lib/actionCreatorCreators';

export const LOAD_EMAIL_TEMPLATES = asyncAction('LOAD_EMAIL_TEMPLATES');
export const CREATE_EMAIL_TEMPLATE = asyncAction('CREATE_EMAIL_TEMPLATE');
export const UPDATE_EMAIL_TEMPLATE = asyncAction('UPDATE_EMAIL_TEMPLATE');
export const DELETE_EMAIL_TEMPLATE = asyncAction('DELETE_EMAIL_TEMPLATE');
export const REMOVE_EMAIL_TEMPLATE = syncAction('REMOVE_EMAIL_TEMPLATE');
export const RESET_EMAIL_TEMPLATE = syncAction('RESET_EMAIL_TEMPLATE');
export const RESET_EMAIL_TEMPLATES = syncAction('RESET_EMAIL_TEMPLATES');

export const LOAD_EMAIL_TEMPLATE_TYPES = asyncAction('LOAD_EMAIL_TEMPLATE_TYPES');
export const RESET_EMAIL_TEMPLATE_TYPES = syncAction('RESET_EMAIL_TEMPLATE_TYPES');

export const LOAD_EMAIL_TEMPLATE_TAGS = asyncAction('LOAD_EMAIL_TEMPLATE_TAGS');
export const RESET_EMAIL_TEMPLATE_TAGS = syncAction('RESET_EMAIL_TEMPLATE_TAGS');
