import { createSelector } from 'reselect';
import { REDUX_STATUS } from 'lib/constants';

export const selectOrganizationsStatus = (state) => state.organizations.status;
export const selectOrganizations = (state) => state.organizations.byId;
export const selectOrganizationIds = (state) => state.organizations.allIds;

export const selectOrganizationsLoading = createSelector(
  selectOrganizationsStatus,
  (status) => status === REDUX_STATUS.PENDING,
);

export const selectOrganizationById = createSelector(
  selectOrganizations,
  (_, id) => id,
  (organizations, id) => organizations[id],
);

export const selectOrganizationData = createSelector(
  selectOrganizations,
  (organizations) => Object.values(organizations),
);

export const selectSortedOrganizationData = createSelector(
  selectOrganizations,
  selectOrganizationIds,
  (organizations, ids) => ids.map((id) => organizations[id]),
);
