import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PAYMENT_TRANSACTIONS } from 'store/paymentReport/actions';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import SearchForm from 'components/reporting/PaymentReport/SearchForm';
import Results from 'components/reporting/PaymentReport/Results';
import CsvDownloadButton from 'components/reporting/PaymentReport/CsvDownloadButton';

function PaymentReport() {
  const { totalResults, searching, filters } = useSelector((state) => state.paymentReport);
  const dispatch = useDispatch();

  const getPaymentTransactions = useCallback((values) => {
    dispatch(GET_PAYMENT_TRANSACTIONS.request(values));
  }, [dispatch]);

  return (
    <RegistrarTemplate id="payment-reports-page">
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Reports', href: '/registrar/reports' },
                { label: 'Payments' },
              ]}
            />

            <h1 className="mb-0">Payments Report</h1>
          </div>
        </div>
      </div>

      <div className="flush px-4 my-4">

        <p className="lead">
          Get a report on payments that were received through the platform.
        </p>

        <div className="row mt-3">
          <div className="col">
            <SearchForm
              searching={searching}
              onSubmit={(values) => getPaymentTransactions(values)}
            />
          </div>
        </div>

        {totalResults > 0 && (
          <div className="row">
            <div className="col my-3 text-center">
              <CsvDownloadButton
                filters={filters}
              />
            </div>
          </div>
        )}

        <div className="row mt-3">
          <div className="col">
            <Results
              setPage={(p) => getPaymentTransactions({ ...filters, page: p })}
            />
          </div>
        </div>
      </div>
    </RegistrarTemplate>
  );
}

export default PaymentReport;
