export const USER_PROP_MAP = {
  firstName: ['first name', 'firstName'],
  lastName: ['last name', 'lastName'],
  email: ['email', 'e-mail'],
  phone: ['phone', 'phone number'],
  address1: ['address 1', 'address line 1', 'address', 'address1'],
  address2: ['address 2', 'address line 2', 'address2'],
  city: ['city', 'city/town', 'town'],
  province: ['province'],
  postalCode: ['postal code', 'postalCode'],
  languagePreference: ['language', 'language preference', 'languagePreference'],
  serviceOrganization: ['service', 'service organization', 'serviceOrganization'],
  department: ['department'],
  organization: ['organization'],
  healthAuthority: ['health authority', 'healthAuthority', 'health authority (bc)'],
  ancestry: ['ancestry'],
  jobCategory: ['job category', 'jobCategory', 'job', 'job role', 'jobRole'],
  educationLevel: ['education level', 'educationLevel'],
  ageGroup: ['age group', 'ageGroup'],
  gender: ['gender'],
  authorizerName: ['authorizingManager', 'authorizing manager', 'authorizerName', 'authorizer name', 'authorizer', 'manager', 'manager name'],
  authorizerEmail: ['managerEmail', 'manager email', 'manager e-mail', 'authorizerEmail', 'authorizer email', 'authorizer e-mail'],
  authorizerPhone: ['managerPhone', 'manager phone', 'authorizerPhone', 'authorizer phone'],
  employeeNumber: ['employeeNumber', 'employee number'],
};

export const LANGUAGE_PREFERENCE_TRANSLATIONS = {
  en: 'English',
  english: 'English',
  English: 'English',
  fr: 'French',
  french: 'French',
  French: 'French',
};

export const DEFAULT_USER_PROPS = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  province: '',
  postalCode: '',
  languagePreference: '',
  serviceOrganization: '',
  department: '',
  organization: '',
  healthAuthority: '',
  ancestry: '',
  jobCategory: '',
  jobCategoryOther: '',
  educationLevel: '',
  ageGroup: '',
  gender: '',
  authorizerName: '',
  authorizerEmail: '',
  authorizerPhone: '',
  employeeNumber: '',
};

export const REQUIRED_USER_PROPS = {
  firstName: '',
  lastName: '',
  email: '',
  organization: '',
  healthAuthority: '',
};

export const USER_PROPS = Object.keys(DEFAULT_USER_PROPS);

export const SPREADSHEET_FILES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
];

export const SUPPORTED_FILES = [
  'text/csv',
  ...SPREADSHEET_FILES,
];

// 1MB - To support larger excel files
export const MAX_FILE_SIZE = 1000000;
