import React from 'react';
import { useSelector } from 'react-redux';
import { LEGACY_APP_DOMAIN } from 'config';
import { GROUP_MEMBERSHIP_ACTION_OPTIONS, LANGUAGE_PREFERENCES, PROVINCE_VALUES } from 'lib/constants';
import { GroupMembershipPropType, UserPropType } from 'lib/propTypes';
import ErrorMessage from 'components/shared/ErrorMessage';
import LoginField from 'components/shared/LoginField';
import SanyasIcon from 'components/shared/SanyasIcon';
import CourseProgress from 'components/group_memberships/CourseProgress';
import JournalAndDiscussionBoards from 'components/users/JournalAndDbs/JournalAndDiscussionBoards';
import GroupMembershipActionsMenu from 'components/users/GroupMembershipActionsMenu';
import ParticipantStatus from 'components/group_memberships/ParticipantStatus';
import GroupLink from 'components/group_memberships/GroupLink';
import DisplayField from 'components/shared/DisplayField';

function ProfileTabPage({ user, gm }) {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <>
      <div className="bg-light px-4 py-2">
        <GroupLink group={gm.group} />
      </div>

      <div className="px-4">
        <ParticipantStatus gm={gm} />

        <ErrorMessage error={user?.error || user?.resetLogin?.error} className="mt-2" />

        <div className="row">
          <div className="col-md-8 col-xl-9">

            <div className="mb-4">
              <h2 className="mb-3">Overview</h2>

              <div className="row">
                <DisplayField className="col-sm-6" label="First name" value={user.data?.firstName} />

                <DisplayField className="col-sm-6" label="Email" value={user.data?.email} />
              </div>

              <div className="row">
                <DisplayField className="col-sm-6" label="Last name" value={user.data?.lastName} />

                <DisplayField className="col-sm-6" label="Phone number" value={user.data?.phone} />
              </div>

              <div className="row">
                <LoginField
                  className="col-sm-6"
                  label="Display name"
                  value={user.data?.login}
                  loginId={user.data?.id}
                />

                <DisplayField className="col-sm-6" label="City" value={user.data?.city} />
              </div>

              <div className="row">
                <DisplayField className="col-sm-6" label="Preferred language" value={user.data?.languagePreference ? LANGUAGE_PREFERENCES[user.data?.languagePreference] : ''} />

                <DisplayField className="col-sm-6" label="Province or territory" value={PROVINCE_VALUES[user.data?.province]} />
              </div>

              <div className="row">
                <DisplayField className="col-sm-6 offset-sm-6" label="Country" value={user.data?.country} />
              </div>
            </div>

            <div className="mb-4">
              <h2 className="mb-3">Demographic info</h2>

              <div className="row">
                <DisplayField className="col-sm-6" label="Gender" value={gm.gender} />

                <DisplayField className="col-sm-6" label="Service Organization" value={gm.serviceOrganization} />
              </div>

              <div className="row">
                <DisplayField className="col-sm-6" label="Ancestry" value={gm.ancestry} />

                <DisplayField className="col-sm-6" label="Current position" value={gm.jobCategoryOther ?? gm.jobCategory} />
              </div>

              <div className="row">
                <DisplayField className="col-sm-6" label="Age group" value={gm.ageGroup} />

                <DisplayField className="col-sm-6" label="Department" value={gm.department} />
              </div>

              <div className="row">
                <DisplayField className="col-sm-6" label="Education level" value={gm.educationLevel} />

                <DisplayField className="col-sm-6" label="Employee number" value={gm.employee_number} />
              </div>

              <div className="row">
                <DisplayField
                  className="col-sm-6 offset-sm-6"
                  label={gm.healthAuthorityLabel}
                  value={(
                    <>
                      {gm.healthAuthority}
                      <br />
                      <span className="text-secondary text-small">{gm.organization}</span>
                    </>
                  )}
                />
              </div>
            </div>

            <CourseProgress gm={gm} />
          </div>

          {currentUser?.hasFacilitatorAccess && (
          <div className="col">

            <div className="mt-3 mt-md-0 mb-3 border rounded py-2">
              <h4 className="py-2 px-3">Journals & DBs</h4>
              <JournalAndDiscussionBoards
                groupMembershipId={gm.id}
                subGroupId={gm.subGroupId}
                groupId={gm.groupId}
                userId={gm.userId}
              />
            </div>

            <div className="mt-3 mt-md-0 mb-3 border rounded py-2">
              <h4 className="py-2 px-3">Actions</h4>

              <ul className="list-unstyled">
                <li className="d-flex align-items-baseline mb-3 px-3 text-primary">
                  <SanyasIcon name="boxArrowLeft" className="me-2" />
                  <a
                    className="btn-plain"
                    href={`${LEGACY_APP_DOMAIN}/user_impersonations/${user.data?.id}/create`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Impersonate
                  </a>
                </li>

                <GroupMembershipActionsMenu
                  type="list"
                  groupMembership={gm}
                  group={gm.group}
                  options={[
                    // GROUP_MEMBERSHIP_ACTION_OPTIONS.WELCOME,
                    GROUP_MEMBERSHIP_ACTION_OPTIONS.IWL,
                    // GROUP_MEMBERSHIP_ACTION_OPTIONS.CONFIRMATION,
                    // GROUP_MEMBERSHIP_ACTION_OPTIONS.CERTIFICATE,
                    // GROUP_MEMBERSHIP_ACTION_OPTIONS.PARE,
                    // GROUP_MEMBERSHIP_ACTION_OPTIONS.CURRICULUM_REVIEWER,
                    GROUP_MEMBERSHIP_ACTION_OPTIONS.INSUFFICIENT_PARTICIPATION,
                  ]}
                />
              </ul>
            </div>
          </div>
          )}
        </div>
      </div>
    </>
  );
}

ProfileTabPage.propTypes = {
  user: UserPropType.isRequired,
  gm: GroupMembershipPropType.isRequired,
};

export default ProfileTabPage;
