import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { COURSE_STATUS, REDUX_STATUS } from 'lib/constants';
import { stringToNull } from 'lib/utils';
import { selectCourseById } from 'store/courses/selectors';
import { LOAD_COURSE_SECTIONS, UPDATE_COURSE } from 'store/courses/actions';
import { Button, Spinner } from 'react-bootstrap';
import ErrorMessage from 'components/shared/ErrorMessage';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import InputField from 'components/shared/FormFields/InputField';
import SelectField from 'components/shared/FormFields/SelectField';

const FormSchema = Yup.object().shape({
  description: Yup.string().required('Required'),
  completionMilestoneSectionId: Yup.number(),
});

function SettingsDialog({ courseId, settingsOpen, setSettingsOpen }) {
  const { description, name, completionMilestoneSectionId, sectionIds, status } = useSelector((state) => selectCourseById(state, courseId));
  const { error: courseError, status: courseStatus } = useSelector((state) => state.courses.course);
  const coursesStatus = useSelector((state) => state.courses.status);
  const sections = useSelector((state) => state.courses.sections);
  const dispatch = useDispatch();
  const isDraft = status === COURSE_STATUS.DRAFT;
  const completionMilestoneSectionOptions = sections && Object.values(sections).filter((section) => sectionIds?.includes(section.id))?.map((section) => ({ value: section.id, label: `${section.position}. ${section.name}` }));

  const initialValues = {
    id: courseId,
    name,
    description: description || name,
    completionMilestoneSectionId: completionMilestoneSectionId || '',
  };

  useEffect(() => {
    if (courseStatus === REDUX_STATUS.SUCCESS) {
      setSettingsOpen(false);
    }
  }, [courseStatus, setSettingsOpen]);

  useEffect(() => {
    if (settingsOpen && isDraft) {
      dispatch(LOAD_COURSE_SECTIONS.request(courseId));
    }
  }, [isDraft, courseId, dispatch, settingsOpen]);

  const handleSave = (values, actions) => {
    dispatch(UPDATE_COURSE.request(stringToNull(values), { formikActions: actions }));
  };

  const handleClose = (resetForm) => {
    setSettingsOpen(false);
    if (typeof resetForm === 'function') resetForm();
  };

  return (
    <Formik
      onSubmit={handleSave}
      validationSchema={FormSchema}
      enableReinitialize
      initialValues={initialValues}
    >
      {({ isSubmitting, handleSubmit, resetForm }) => (
        <DefaultModal
          size="md"
          isOpen={settingsOpen}
          header="Course settings"
          onClose={() => handleClose(resetForm)}
          footerComponent={(
            <Button disabled={isSubmitting} value="primary" onClick={handleSubmit}>
              Save
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
            )}
        >
          {courseError && <ErrorMessage error={courseError} />}
          <Form key={courseId}>
            <InputField name="description" label="Draft description" />
            {isDraft && (
              <SelectField
                name="completionMilestoneSectionId"
                label="Completion milestone"
                options={completionMilestoneSectionOptions}
                includeBlank="Choose module"
                loading={coursesStatus === REDUX_STATUS.PENDING}
              />
            )}
          </Form>
        </DefaultModal>
      )}
    </Formik>
  );
}

SettingsDialog.propTypes = {
  courseId: PropTypes.number.isRequired,
  settingsOpen: PropTypes.bool.isRequired,
  setSettingsOpen: PropTypes.func.isRequired,
};

export default SettingsDialog;
