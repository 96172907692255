import React from 'react';
import PropTypes from 'prop-types';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import { Button } from 'react-bootstrap';
import Spinner from 'components/shared/Spinner';
import { useSelector } from 'react-redux';
import { buildRoutePath } from 'lib/routerHelpers';
import { REGISTRAR_GROUP_SHOW_PATH } from 'lib/routerPaths';
import { sentenceCase } from 'lib/utils';
import { selectCourseById } from 'store/courses/selectors';

function CourseGroupsModal({ courseId, showGroups, setShowGroups }) {
  // TODO link to Facilitator Group Show page - see below
  // const { id: userId, isFacilitator, isRegistrar } = useSelector((state) => state.currentUser) || {};
  const { isRegistrar } = useSelector((state) => state.currentUser) || {};
  const course = useSelector((state) => selectCourseById(state, courseId));

  const handleCloseModal = () => {
    setShowGroups(false);
  };

  return (
    <DefaultModal
      isOpen={showGroups}
      header="Groups"
      onClose={handleCloseModal}
      scrollable
      footerComponent={
        <Button value="primary" onClick={handleCloseModal}>Close</Button>
      }
    >
      {!course?.groups?.length && <Spinner />}
      <ul className="mt-2">
        {course?.groups?.map((group) => (
          <li key={group.id}>
            {/* TODO link to Facilitator Group Show page - (isFacilitator && group.facilitatorIds.includes(userId)) */}
            {isRegistrar ? (
              <a href={buildRoutePath(REGISTRAR_GROUP_SHOW_PATH, { id: group.id })}>{group.name}</a>
            )
              : group.name}
            <br />
            <em>{`${group.participants} participants — ${sentenceCase(group.state)}`}</em>
          </li>
        ))}
      </ul>
    </DefaultModal>
  );
}

CourseGroupsModal.defaultProps = {
  courseId: null,
};

CourseGroupsModal.propTypes = {
  courseId: PropTypes.number,
  showGroups: PropTypes.bool.isRequired,
  setShowGroups: PropTypes.func.isRequired,
};

export default CourseGroupsModal;
