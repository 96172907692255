import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_PHRASEBOOK_PATH, CURRICULUM_COURSE_PHRASEBOOK_SHOW_PATH } from 'lib/routerPaths';
import { CREATE_PHRASEBOOK_ENTRY, DELETE_PHRASEBOOK_ENTRY, SAVE_PHRASEBOOK_ENTRY } from 'store/courses/actions';
import { selectPhrasebookEntryById, selectPhrasebookEntryKeys } from 'store/courses/selectors';
import { Button, Spinner } from 'react-bootstrap';
import InputField from 'components/shared/FormFields/InputField';
import RichTextField from 'components/shared/FormFields/RichTextField';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import SwitchField from 'components/shared/FormFields/SwitchField';
import ErrorMessage from 'components/shared/ErrorMessage';
import PhrasebookEntryFormHeader from './PhrasebookEntryFormHeader';

const formatKey = (key, isFrench) => {
  if (!key) return '';
  const newKey = key
    // https://www.30secondsofcode.org/js/s/remove-accents/
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .replace(/[^A-Za-z0-9-_ ]/g, '')
    .replace(/ /g, '-');
  return (isFrench) ? `fr-${newKey}` : newKey.replace(/^fr-/, '');
};

const generateKey = (values, setFieldValue) => (e) => {
  const { name, value, checked } = e.currentTarget;
  let { key } = values;
  if (name === 'regenerate') {
    key = formatKey(values.title, values.isFrench);
  } else if (!values.key || !values.id) {
    if (name === 'title') {
      key = formatKey(value, values.isFrench);
    }
    if (name === 'isFrench') {
      key = formatKey(key, checked);
    }
  }
  if (values.key !== key) setTimeout(() => setFieldValue('key', key), 0);
};

const FormSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required'),
  key: Yup.string()
    .required('Required')
    .matches(/^[A-z0-9-_ ]+$/, 'Incorrect format')
    .test(
      'unique',
      'Key must be unique. This key already exists in this Course.',
      (value, context) => {
        const { keys } = context.parent;
        return !keys.includes(value);
      },
    ),
  description: Yup.string()
    .required('Required'),
  isFrench: Yup.bool(),
});

function PhrasebookEntryForm() {
  const { courseId, phrasebookId } = useParams();
  const activeEntry = useSelector((state) => state.courses.phrasebookEntry);
  const dispatch = useDispatch();
  const history = useHistory();
  const phrasebookEntry = useSelector((state) => selectPhrasebookEntryById(state, phrasebookId));
  const phrasebookEntryKeys = useSelector((state) => selectPhrasebookEntryKeys(state, phrasebookId));
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (activeEntry?.status === REDUX_STATUS.SUCCESS) {
      if (activeEntry?.success === REDUX_SUCCESS.CREATED) {
        history.push(buildRoutePath(CURRICULUM_COURSE_PHRASEBOOK_SHOW_PATH, { courseId, phrasebookId: activeEntry?.id }));
      }
      if (activeEntry?.success === REDUX_SUCCESS.DELETED) {
        history.push(buildRoutePath(CURRICULUM_COURSE_PHRASEBOOK_PATH, { courseId }));
      }
    }
  }, [activeEntry, courseId, history]);

  const initialValues = {
    id: phrasebookEntry?.id ?? null,
    courseId: Number(courseId),
    title: phrasebookEntry?.title ?? '',
    key: phrasebookEntry?.key ?? '',
    description: phrasebookEntry?.description ?? '',
    isFrench: phrasebookEntry?.isFrench ?? false,
    keys: phrasebookEntryKeys ?? [],
  };

  const handleSave = useCallback((values, actions) => {
    const { id, keys, ...sendValues } = values;
    if (phrasebookId > 0) {
      dispatch(SAVE_PHRASEBOOK_ENTRY.request({ ...sendValues, id }, { formikActions: actions }));
    } else {
      dispatch(CREATE_PHRASEBOOK_ENTRY.request(sendValues, { formikActions: actions }));
    }
  }, [phrasebookId, dispatch]);

  const handleDelete = useCallback(() => {
    if (phrasebookId) {
      dispatch(DELETE_PHRASEBOOK_ENTRY.request(phrasebookId));
    }
  }, [phrasebookId, dispatch]);

  return (
    <div className="sticky-top">

      <Formik
        onSubmit={handleSave}
        validationSchema={FormSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          const formGenerateKey = generateKey(values, setFieldValue);
          return (
            <Form key={`form-${phrasebookId}`}>
              <PhrasebookEntryFormHeader entry={values} setShowDeleteModal={setShowDeleteModal} />

              <ErrorMessage error={activeEntry?.error} className="mt-2 mx-4" />

              <div className="py-3 px-4">
                <SwitchField name="isFrench" label="This is a French entry" onFieldChange={formGenerateKey} />
                <InputField name="title" placeholder="Term title" onFieldChange={formGenerateKey} />
                <InputField
                  name="key"
                  placeholder="Autogenerate editable key"
                  helpText={`Use a different key for English and French entries.
                    May contain characters, numbers, “-”, “_” and “ ” only.`}
                  addOnEnd={(
                    <Button name="regenerate" variant="link" size="sm" className="p-0 text-decoration-none text-nowrap" onClick={formGenerateKey}>
                      Regenerate key
                    </Button>
                  )}
                />
                <RichTextField variant="simple" name="description" placeholder="Enter the term description" />
              </div>
            </Form>
          );
        }}
      </Formik>

      <DefaultModal
        key={`delete-${phrasebookId}`}
        size="md"
        isOpen={showDeleteModal}
        header="Delete entry?"
        onClose={() => setShowDeleteModal(false)}
        footerComponent={(
          <Button
            className="d-flex align-items-center"
            disabled={activeEntry?.status === REDUX_STATUS.PENDING}
            variant="danger"
            onClick={handleDelete}
          >
            {activeEntry?.status === REDUX_STATUS.PENDING && (
              <Spinner
                size="sm"
                className="ms-1"
                animation="border"
                role="status"
              />
            )}
            Delete
          </Button>
      )}
      >
        <p
          className="bg-sn-sand flush top py-1"
        >
          Deleting this Phrasebook entry will delete
          {' '}
          <strong>all content</strong>
          {' '}
          within the entry as well.
          <br />
          This action cannot be undone.
        </p>

        <p className="d-flex justify-content-between">
          <strong>Confirm</strong>
          <span className="ps-2">Please confirm that you want to delete this entry.</span>
        </p>
      </DefaultModal>
    </div>
  );
}

export default PhrasebookEntryForm;
