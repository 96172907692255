import React from 'react';
import PropTypes from 'prop-types';
import parseISO from 'date-fns/parseISO';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { DT_PLATFORM_CLOSURE_DETAILS, TZ_ATLANTIC, TZ_PACIFIC } from 'lib/dateFormats';
import Card from 'react-bootstrap/Card';

function PlatformClosureDetails({ closePlatformFrom, closePlatformUntil, message }) {
  return (
    <>
      <p>
        <span className="text-uppercase fw-lighter" style={{ fontSize: '0.75rem' }}>From</span>
        {' '}
        <span className="fw-bolder">{formatInTimeZone(parseISO(closePlatformFrom), TZ_PACIFIC, DT_PLATFORM_CLOSURE_DETAILS)}</span>
        {' '}
        <span className="text-uppercase fw-lighter" style={{ fontSize: '0.75rem' }}>until</span>
        {' '}
        <span className="fw-bolder">{formatInTimeZone(parseISO(closePlatformUntil), TZ_ATLANTIC, DT_PLATFORM_CLOSURE_DETAILS)}</span>
      </p>

      <Card border="info" className="mb-3">
        <Card.Body>
          <Card.Text style={{ whiteSpace: 'pre-wrap' }}>{message}</Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

PlatformClosureDetails.propTypes = {
  closePlatformFrom: PropTypes.string.isRequired,
  closePlatformUntil: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default PlatformClosureDetails;
