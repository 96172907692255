/**
 * Helper functions for react-router-dom v5.
 */
import { matchPath } from 'react-router-dom';
import ROUTE_PATHS from 'lib/routerPaths';

/**
 *
 * @param searchParams (String) - The search params. Ex: "?page=1&perPage=20"
 * @param name (String) - The name of the search param to retrieve. Ex: page or perPage
 * @param options (Object) - set numeric: true if the param is expected to be a number. Otherwise params are returned as strings.
 *
 * @return (String|Number|null) - return the parameter value or null if the parameter does not exist
 */
export function getSearchParams(searchParams, name, { numeric = false }) {
  const param = new URLSearchParams(searchParams).get(name);
  if (param) {
    return numeric ? Number(param) : param;
  }
  return null;
}

/**
 *
 * @param path (String) - The route path. e.g., "/registrar/groups/:id", REGISTRAR_GROUP_SHOW_PATH
 * @param params (Object) - The path params to replace. e.g., { id: 1, name: 'foo' }
 *
 * @return (String) - return the path with params replaced. e.g., "/registrar/groups/1"
 */
export function buildRoutePath(path, params) {
  return Object.keys(params).reduce((acc, param) => acc.replace(`:${param}`, params[param]), path);
}

/**
 *
 * @param href (String) - The route href. e.g., "/registrar/groups/:id", REGISTRAR_GROUP_SHOW_PATH
 *
 * @return (String) - return true if the path is a defined route path
 */
export function isRoute(href) {
  if (!href) return false;
  return ROUTE_PATHS.some((path) => {
    const match = matchPath(href, {
      path,
      exact: true,
    });
    return match ? match.isExact : false;
  });
}

/**
 *
 * @param path (String) - The route path. e.g., "/registrar/groups/:id", REGISTRAR_GROUP_SHOW_PATH
 *
 * @return (Boolean) - return true if the path matches the current route path
 */
export function isActivePath(path) {
  return matchPath(window.location.pathname, path) !== null;
}
