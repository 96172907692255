import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import { OrganizationPropType } from 'lib/propTypes';

function DefaultOrganizationSelect({ field, form, ...props }) {
  const { organizations, label } = props;

  const handleChange = useCallback((e) => {
    const organization = organizations.find((org) => org.shortName === e.target.value);
    form.setFieldValue('organizationId', organization?.id);
    field.onChange(e);
  }, [organizations, field, form]);

  return (
    <div className="form-group form-row">
      <label htmlFor="organization" className="form-label">
        {label}
        <SimpleToolTip
          id="organization-instruction"
          placement="top"
          width="20em"
          variant="info"
          text={(
            <div className="text-start">
              <p className="m-0 p-0">
                For accurate seat counts with participants under the &ldquo;Other (Fee Paying)&rdquo; health authority,
                select the organization towards which their seats should be counted.
              </p>
              <p className="m-0 p-0">
                If no participants are listed as &ldquo;Other (Fee Paying)&rdquo;, this option will have no effect.
              </p>
            </div>
        )}
        >
          <span className="fas fa-info-circle ps-1" />
        </SimpleToolTip>
      </label>
      <div className="col-md-4 col-lg-3">
        <select name={field.name} className="form-select" value={field.value} onChange={handleChange}>
          <option value="">Organization</option>
          {organizations.map((o) => <option key={o.id} value={o.shortName}>{o.shortName}</option>)}
        </select>
      </div>
    </div>
  );
}

DefaultOrganizationSelect.defaultProps = {
  organizations: [],
};

DefaultOrganizationSelect.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }).isRequired,
  organizations: PropTypes.arrayOf(OrganizationPropType),
  label: PropTypes.string.isRequired,
};

export default DefaultOrganizationSelect;
