import React from 'react';
import { useSelector } from 'react-redux';
import { selectGroupSubgroups, selectAssignedGroupMemberships, selectUnassignedActiveGroupMemberships } from 'store/groupShow/selectors';
import { Alert } from 'react-bootstrap';
import SubGroupDetails from 'components/groups/Journals/SubGroupDetails';

function GroupJournalsTabPage() {
  const subGroups = useSelector((state) => selectGroupSubgroups(state));
  const assignedGroupMemberships = useSelector((state) => selectAssignedGroupMemberships(state));
  const unassignedActiveGroupMemberships = useSelector((state) => selectUnassignedActiveGroupMemberships(state));

  return (
    <>
      <h2>Journals</h2>

      {(subGroups.length === 0 && unassignedActiveGroupMemberships.length === 0) && (
        <Alert variant="info" className="w-75">There are no participants currently registered to this group.</Alert>
      )}

      <div className="row mt-4">
        { subGroups.map((subGroup) => {
          const assignedForThisSubGroup = assignedGroupMemberships.filter((gm) => gm.subGroupId === subGroup.id);

          return (
            <div key={subGroup.id} className="col-sm-6 col-lg-4 col-xxl-3 mb-4">
              <SubGroupDetails subGroup={subGroup} groupMemberships={assignedForThisSubGroup} />
            </div>
          );
        })}

        { unassignedActiveGroupMemberships.length > 0 && (
          <div className="col-sm-6 col-lg-4 col-xxl-3 mb-4">
            <SubGroupDetails subGroup={null} groupMemberships={unassignedActiveGroupMemberships} />
          </div>
        )}
      </div>
    </>
  );
}

export default GroupJournalsTabPage;
