import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { fetchGet, fetchPost } from 'lib/apiHelpers';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import Button from 'react-bootstrap/Button';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import Spinner from 'components/shared/Spinner';
import VoucherActions from 'components/registrar/VoucherActions';
import VoucherDetails from 'components/registrar/VoucherDetails';
import VoucherLogs from 'components/registrar/VoucherLogs';

function VouchersShowPage() {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(null);
  const [voucher, setVoucher] = useState({});
  const { id } = useParams();

  const theFetch = useCallback((url, options) => {
    fetchPost(url, options)
      .then(({ data }) => {
        setVoucher((v) => ({
          ...(v || {}),
          ...data,
        }));
      })
      .catch((err) => {
        setError(err);
        // eslint-disable-next-line no-console
        console.log(err.data, err.message);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetchGet(`/api/registrar/vouchers/${id}`)
      .then(({ data }) => {
        setVoucher(data);
      })
      .catch((err) => {
        setError(err);
        // eslint-disable-next-line no-console
        console.warn(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const redeemAction = useCallback((e) => {
    e.preventDefault();
    // eslint-disable-next-line no-alert
    const c = window.confirm('Are you sure you want to redeem this voucher?');
    if (c) {
      theFetch('/api/registrar/vouchers/redeem', { voucher: { id: parseInt(id, 10) } });
    }
  }, [theFetch, id]);

  const restoreAction = useCallback((e) => {
    e.preventDefault();
    // eslint-disable-next-line no-alert
    const c = window.confirm('Are you sure you want to restore this voucher?');
    if (c) {
      theFetch('/api/registrar/vouchers/restore', { voucher: { id: parseInt(id, 10) } });
    }
  }, [theFetch, id]);

  const voidAction = useCallback((e) => {
    e.preventDefault();
    // eslint-disable-next-line no-alert
    const c = window.confirm('Are you sure you want to void?');
    if (c) {
      theFetch('/api/registrar/vouchers/void', { voucher: { id: parseInt(id, 10) } });
    }
  }, [theFetch, id]);

  const toggleMarkTrainingPaymentAs = useCallback((e) => {
    const paymentAs = voucher.markTrainingPaymentAs;
    e.preventDefault();
    // eslint-disable-next-line no-alert
    const c = window.confirm(`Are you sure you want to mark payment as ${paymentAs === 'Waived' ? 'Paid' : 'Waived'}?`);
    if (c) {
      theFetch('/api/registrar/vouchers/mark_payment_as', {
        voucher: {
          id: parseInt(id, 10), markTrainingPaymentAs: `${paymentAs === 'Waived' ? 'paid' : 'waived'}`,
        },
      });
    }
  }, [theFetch, id, voucher.markTrainingPaymentAs]);

  const resendAction = useCallback((e) => {
    e.preventDefault();
    // eslint-disable-next-line no-alert
    const c = window.confirm('Are you sure you want to resend email invites?');
    if (c) {
      theFetch('/api/registrar/vouchers/resend_emails', { voucher: { id: parseInt(id, 10) } });
    }
  }, [theFetch, id]);

  const url = useMemo(() => `${voucher.host}/register/${voucher.orgPermalink}?voucher_code=${voucher.code}`, [voucher]);

  const copyText = useCallback((textValue) => {
    const el = document.createElement('textarea');
    el.value = textValue;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }, []);

  if (isError) {
    return (
      <RegistrarTemplate>
        <div className="alert alert-danger" role="alert">{ isError.message }</div>
      </RegistrarTemplate>
    );
  }

  return (
    <RegistrarTemplate>
      { isLoading && <Spinner /> }
      { !isLoading && (
        <>
          <div className="header flush">
            <Breadcrumbs
              navItems={[
                { label: 'Vouchers', href: '/registrar/vouchers' },
                { label: `Voucher for ${voucher.email}` },
              ]}
            />

            <h1 className="mb-0">{`Voucher for ${voucher.email}`}</h1>
          </div>

          <div className="flush px-4 my-4">
            <p className="lead">
              Review information about and perform actions to this voucher.
            </p>

            <div className="row">
              <div className="col-md-6">
                <VoucherDetails voucher={voucher} />

                <Button variant="dark" size="sm" onClick={resendAction}>Resend voucher invite</Button>
              </div>

              <div className="col-md-6">
                <VoucherActions>
                  <VoucherActions.ActionItem
                    title={`Mark payment as ${voucher.markTrainingPaymentAs === 'Waived' ? 'Paid' : 'Waived'}`}
                    text={`Set this voucher invite code as ${voucher.markTrainingPaymentAs === 'Waived' ? 'Paid' : 'Waived'}.`}
                    action={<a href="#redeem" onClick={toggleMarkTrainingPaymentAs} className="btn btn-outline-success">{voucher.markTrainingPaymentAs === 'Waived' ? 'Paid' : 'Waived'}</a>}
                  />

                  {voucher.actions.canRedeem && (
                    <VoucherActions.ActionItem
                      title="Mark as Redeemed"
                      text="Set this voucher invite code as used."
                      action={<a href="#redeem" onClick={redeemAction} className="btn btn-outline-primary">Redeem</a>}
                    />
                  )}

                  {voucher.actions.canVoid && (
                    <VoucherActions.ActionItem
                      title="Mark as Voided"
                      text="Revoke this voucher invite code."
                      action={<a href="#void" onClick={voidAction} className="btn btn-outline-danger">Void</a>}
                    />
                  )}

                  {voucher.actions.canRestore && (
                    <VoucherActions.ActionItem
                      title="Restore"
                      text="Set this voucher invite code as unused."
                      action={<a href="#restore" onClick={restoreAction} className="btn btn-outline-warning">Restore</a>}
                    />
                  )}

                  {voucher.actions.canLink && (
                    <VoucherActions.ActionItem
                      title="Copy Voucher Invite Link"
                      text="Copy a link to redeem this voucher in a browser."
                      action={<button type="button" onClick={() => copyText(url)} className="btn btn-outline-secondary text-nowrap">Copy URL</button>}
                    />
                  )}
                </VoucherActions>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <VoucherLogs items={voucher.logs} />
              </div>
            </div>
          </div>
        </>
      )}
    </RegistrarTemplate>
  );
}

export default VouchersShowPage;
