import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

function AlertDismissible({ variant, className, isShown, onDismiss, heading, children }) {
  const [show, setShow] = useState(isShown);
  const onClose = useCallback(() => {
    setShow(false);
    onDismiss();
  }, [onDismiss]);

  if (show) {
    return (
      <Alert variant={variant} onClose={onClose} dismissible className={className}>
        {heading && <Alert.Heading>Oh snap! You got an error!</Alert.Heading>}
        {children}
      </Alert>
    );
  }
  return null;
}

AlertDismissible.defaultProps = {
  variant: 'success',
  className: '',
  heading: null,
  isShown: true,
  onDismiss: () => {},
};

AlertDismissible.propTypes = {
  onDismiss: PropTypes.func,
  isShown: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  className: PropTypes.string,
  heading: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

export default AlertDismissible;
