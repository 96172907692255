import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { CoursePropType } from 'lib/propTypes';
import Button from 'react-bootstrap/Button';
import { PUBLISH_COURSE } from 'store/courses/actions';
import Confirmation from 'components/shared/Modal/Confirmation';
import CourseDraftCard, { CourseDraftCardSkeleton } from '../CourseDraftCard';

function CourseDrafts({ courses, loading, className, setShowCreateDraftModal }) {
  const [confirmation, setConfirmation] = useState({ open: false, close: () => {} });
  const dispatch = useDispatch();

  const handlePublishRequest = useCallback((courseId) => {
    dispatch(PUBLISH_COURSE.request(courseId));
  }, [dispatch]);

  const handleConfirmModalClose = useCallback(() => {
    setConfirmation((prev) => ({ ...prev, open: false }));
  }, []);

  const handlePublish = useCallback((course) => {
    setConfirmation({
      open: true,
      title: `Publish ${course.displayName}?`,
      close: handleConfirmModalClose,
      text: 'Published courses are open to registration to the next group. Your draft will remain editable. Publishing may take a few minutes.',
      confirmText: 'Publish',
      confirmAction: () => handlePublishRequest(course.id),
      cancelText: false,
    });
  }, [handleConfirmModalClose, handlePublishRequest]);

  return (
    <div className={`position-relative ${className || ''}`}>
      <h4>Drafts</h4>

      <p>Drafts are only visible to the San’yas team, this is where all the edits happen.</p>

      { (!loading && !courses.length) && (
        <Button variant="primary" onClick={() => setShowCreateDraftModal(true)}>New draft</Button>
      )}

      { (loading || courses.length > 0) && (
        <div className="row gy-2">
          {courses.map((course) => (<CourseDraftCard key={course.id} course={course} handlePublish={handlePublish} />))}
          {loading && <CourseDraftCardSkeleton />}
        </div>
      )}

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Confirmation {...confirmation} />
    </div>
  );
}

CourseDrafts.defaultProps = {
  courses: [],
  loading: false,
  className: null,
};

CourseDrafts.propTypes = {
  courses: PropTypes.arrayOf(CoursePropType),
  loading: PropTypes.bool,
  className: PropTypes.string,
  setShowCreateDraftModal: PropTypes.func.isRequired,
};

export default CourseDrafts;
