import React from 'react';

function Video() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.667 3.5C2.022 3.5 1.5 4.022 1.5 4.667V11.333C1.5 11.978 2.022 12.5 2.667 12.5H9.333C9.978 12.5 10.5 11.978 10.5 11.333V4.667C10.5 4.022 9.978 3.5 9.333 3.5H2.667ZM0.5 4.667C0.5 3.47 1.47 2.5 2.667 2.5H9.333C10.53 2.5 11.5 3.47 11.5 4.667V11.333C11.5 12.53 10.53 13.5 9.333 13.5H2.667C2.09228 13.5 1.54109 13.2717 1.1347 12.8653C0.728308 12.4589 0.5 11.9077 0.5 11.333V4.667Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.25 5.64915L14.018 4.04415C14.0656 4.01548 14.1198 3.99977 14.1753 3.99858C14.2309 3.99739 14.2857 4.01076 14.3345 4.03736C14.3832 4.06396 14.4242 4.10286 14.4532 4.15019C14.4822 4.19753 14.4984 4.25164 14.5 4.30715V11.6911C14.5 11.9191 14.24 12.0841 14.018 11.9551L11.251 10.3501L10.749 11.2151L13.516 12.8201C14.375 13.3181 15.5 12.7251 15.5 11.6911V4.30715C15.5 3.27415 14.375 2.68115 13.516 3.17915L10.75 4.78415L11.252 5.64915H11.25Z" fill="currentColor" />
    </svg>
  );
}

export default Video;
