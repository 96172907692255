import React from 'react';

function InfoCircle() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_246_4350)">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="currentColor" />
        <path d="M13.3951 9.88202L9.96005 10.3125L9.83705 10.8825L10.5121 11.007C10.9531 11.112 11.0401 11.271 10.9441 11.7105L9.83705 16.9125C9.54605 18.258 9.99455 18.891 11.0491 18.891C11.8666 18.891 12.8161 18.513 13.2466 17.994L13.3786 17.37C13.0785 17.634 12.6406 17.739 12.3496 17.739C11.9371 17.739 11.7871 17.4495 11.8936 16.9395L13.3951 9.88202Z" fill="currentColor" />
        <path d="M12 8.25C12.8284 8.25 13.5 7.57843 13.5 6.75C13.5 5.92157 12.8284 5.25 12 5.25C11.1716 5.25 10.5 5.92157 10.5 6.75C10.5 7.57843 11.1716 8.25 12 8.25Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_4350">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InfoCircle;
