import { takeLatest, put, select, call, delay } from 'redux-saga/effects';
import { fetchGet, fetchPost, fetchPatch, handleSimpleFetchError } from 'lib/apiHelpers';

import { CREATE_PLATFORM_CLOSURE, UPDATE_PLATFORM_CLOSURE, GET_PLATFORM_CLOSURE_STATUS, LOAD_PLATFORM_CLOSURES } from 'store/actions';
import { LOAD_VALIDATION_GROUPS } from 'store/platformClosure/actions';

export default function* sagas() {
  yield takeLatest(CREATE_PLATFORM_CLOSURE.REQUEST, function* createClosure({ payload: { closePlatformFrom, closePlatformUntil, message }, meta }) {
    try {
      const platformClosure = yield fetchPost('/api/registrar/platform_events', { close_platform_from: closePlatformFrom, close_platform_until: closePlatformUntil, message });
      yield delay(800);
      yield put(CREATE_PLATFORM_CLOSURE.success(platformClosure.data));
    } catch (err) {
      yield handleSimpleFetchError(err, CREATE_PLATFORM_CLOSURE);
    } finally {
      yield call(meta.formikActions.setSubmitting, false);
    }
  });

  yield takeLatest(UPDATE_PLATFORM_CLOSURE.REQUEST, function* updateClosure({ payload: { id, closePlatformFrom, closePlatformUntil, message }, meta }) {
    try {
      const platformClosure = yield fetchPatch(`/api/registrar/platform_events/${id}`, { close_platform_from: closePlatformFrom, close_platform_until: closePlatformUntil, message });
      yield delay(800);
      yield put(UPDATE_PLATFORM_CLOSURE.success(platformClosure.data));
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_PLATFORM_CLOSURE);
    } finally {
      yield call(meta.formikActions.setSubmitting, false);
    }
  });

  yield takeLatest(GET_PLATFORM_CLOSURE_STATUS.REQUEST, function* getStatus() {
    try {
      const platformStatus = yield fetchGet('/api/platform/status');
      yield put(GET_PLATFORM_CLOSURE_STATUS.success(platformStatus));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_PLATFORM_CLOSURE_STATUS);
    }
  });

  yield takeLatest(LOAD_PLATFORM_CLOSURES.REQUEST, function* getEvents() {
    const state = yield select();
    if (!state.platformClosure.events.allIds.length) {
      try {
        const platformEvents = yield fetchGet('/api/registrar/platform_events');
        yield put(LOAD_PLATFORM_CLOSURES.success(platformEvents));
      } catch (err) {
        yield handleSimpleFetchError(err, LOAD_PLATFORM_CLOSURES);
      }
    } else {
      yield put(LOAD_PLATFORM_CLOSURES.success({}, { cached: true }));
    }
  });

  yield takeLatest(LOAD_VALIDATION_GROUPS.REQUEST, function* getGroups({ payload }) {
    try {
      const data = yield fetchGet('/api/registrar/platform_events/groups', payload);
      yield put(LOAD_VALIDATION_GROUPS.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_VALIDATION_GROUPS);
    }
  });
}
