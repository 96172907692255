import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectPhrasebookEntryById } from 'store/courses/selectors';

function PhrasebookEntryDetail({ entry }) {
  const phrasebookEntry = useSelector((state) => selectPhrasebookEntryById(state, entry)) || {};

  return (
    <div className="sticky-top">
      <div className="d-flex align-items-center py-3 px-4 border-bottom">
        <h4 className="m-0 text-truncate">{phrasebookEntry.title}</h4>
      </div>

      <div className="py-3 px-4">
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: phrasebookEntry.description }} />

        <p>
          <code>
            {phrasebookEntry.key}
          </code>
        </p>
        {phrasebookEntry.isFrench && <p className="text-small text-secondary">This is a French entry</p>}
      </div>
    </div>
  );
}

PhrasebookEntryDetail.propTypes = {
  entry: PropTypes.number.isRequired,
};

export default PhrasebookEntryDetail;
