import React from 'react';
import PropTypes from 'prop-types';
import Switch from './Switch';
import FieldWrapper from './FieldWrapper';

function SwitchField(props) {
  const { id, name, label, className, disabled, inline, helpText, infoText, switchPlacement, onFieldChange, onFieldBlur } = props;

  return (
    <FieldWrapper
      id={id}
      name={name}
      label={label}
      className={className}
      type="switch"
      helpText={helpText}
      infoText={infoText}
      inline={inline}
      switchPlacement={switchPlacement}
      onFieldChange={onFieldChange}
      onFieldBlur={onFieldBlur}
    >
      <Switch
        name={name}
        disabled={disabled}
      />
    </FieldWrapper>
  );
}

SwitchField.defaultProps = {
  id: null,
  label: null,
  className: null,
  disabled: false,
  inline: false,
  helpText: null,
  infoText: null,
  switchPlacement: 'end',
  onFieldChange: null,
  onFieldBlur: null,
};

SwitchField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  helpText: PropTypes.string,
  infoText: PropTypes.string,
  switchPlacement: PropTypes.oneOf(['start', 'end']),
  onFieldChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
};

export default SwitchField;
