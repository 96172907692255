import React from 'react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { SHORT_DATE } from 'lib/dateFormats';
import { CoursePropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_GLOSSARY_PATH, CURRICULUM_COURSE_PHRASEBOOK_PATH, CURRICULUM_COURSE_SHOW_PATH } from 'lib/routerPaths';
import SanyasIcon from 'components/shared/SanyasIcon';
import PropTypes from 'prop-types';
import CourseDropDown from '../CourseDropDown';

function CourseDraftCard(props) {
  const { course, handlePublish } = props;
  const { isDuplicating, isPublishing } = course;

  let publishButtonText = 'Publish';
  if (isDuplicating) {
    publishButtonText = 'Duplicating...';
  } else if (isPublishing) {
    publishButtonText = 'Publishing...';
  }

  const handlePublishClick = () => {
    handlePublish(course);
  };

  return (
    <div className="col-xxl-4 col-md-6 mb-2">
      <div className="card h-100 shadow-sm bg-white rounded">
        <div className="px-3 py-2">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 me-1 text-truncate">
              <Link
                className="link-dark text-decoration-none"
                to={buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course.id })}
                disabled={isDuplicating || isPublishing}
              >
                <SanyasIcon name="pencil" className="me-1" />
                {course.description}
              </Link>
            </h4>

            <CourseDropDown course={course} alignToggle="end" draftCard />
          </div>

          <div className="d-flex align-items-center mt-2">
            <button
              className="btn btn-plain btn-primary ps-0"
              type="button"
              onClick={handlePublishClick}
              disabled={isDuplicating || isPublishing}
            >
              <SanyasIcon name="upload" className="me-1" />
              {publishButtonText}
            </button>

            <p className="m-0 ms-auto text-small">
              Created
              {' '}
              {!!course?.createdAt && format(parseISO(course.createdAt), SHORT_DATE)}
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-between border-top px-3 py-2 text-small">
          <Link
            className="link-secondary link-offset-2-hover link-underline-opacity-0 link-underline-opacity-50-hover"
            to={`${buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course?.id })}/proactive_templates`}
          >
            Proactive Templates
          </Link>
          <Link
            className="link-secondary link-offset-2-hover link-underline-opacity-0 link-underline-opacity-50-hover"
            to={`${buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course?.id })}/email_templates`}
          >
            Email Templates
          </Link>
          <Link
            className="link-secondary link-offset-2-hover link-underline-opacity-0 link-underline-opacity-50-hover"
            to={buildRoutePath(CURRICULUM_COURSE_GLOSSARY_PATH, { courseId: course?.id })}
          >
            Glossary
          </Link>
          <Link
            className="link-secondary link-offset-2-hover link-underline-opacity-0 link-underline-opacity-50-hover"
            to={buildRoutePath(CURRICULUM_COURSE_PHRASEBOOK_PATH, { courseId: course?.id })}
          >
            Phrasebook
          </Link>
        </div>
      </div>
    </div>
  );
}

CourseDraftCard.propTypes = {
  course: CoursePropType.isRequired,
  handlePublish: PropTypes.func.isRequired,
};

export default CourseDraftCard;
