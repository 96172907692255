import React from 'react';
import { Link } from 'react-router-dom';
import FilterForm from 'components/registrar/Vouchers/FilterForm';
import VouchersList from 'components/registrar/Vouchers/VouchersList';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';

function VouchersPage() {
  return (
    <RegistrarTemplate>
      <div className="header flush d-flex align-items-center">
        <h1 className="mb-0">Vouchers</h1>

        <Link to="/registrar/vouchers/new" className="btn btn-primary btn-sm ms-auto">
          New vouchers
        </Link>
      </div>

      <div className="flush px-4 my-4">
        <p className="lead">
          Search and view the status of voucher invite codes across the platform.
        </p>

        <FilterForm />
        <VouchersList />
      </div>
    </RegistrarTemplate>
  );
}

export default VouchersPage;
