import React from 'react';
import { GroupPropType } from 'lib/propTypes';
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_GROUP_SHOW_PATH, REGISTRAR_GROUP_SHOW_PATH } from 'lib/routerPaths';
import { useWorkspace } from 'lib/hooks/useWorkspace';

GroupLink.defaultProps = {
  group: null,
};

GroupLink.propTypes = {
  group: GroupPropType,
};

function GroupLink({ group }) {
  const { id: workspaceId } = useWorkspace();

  if (!group) return null;

  return (
    <Button
      as={Link}
      variant={null}
      className="bg-white border"
      to={buildRoutePath(workspaceId === 'facilitator' ? FACILITATOR_GROUP_SHOW_PATH : REGISTRAR_GROUP_SHOW_PATH, { id: group.id })}
      title={`View ${group.name} (${group.decoratedState})`}
    >
      {group.name || <Spinner animation="border" size="sm" />}
    </Button>
  );
}

export default GroupLink;
