import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import _orderBy from 'lodash/orderBy';
import { REDUX_STATUS } from 'lib/constants';
import { DT_CLOSE_TIME } from 'lib/dateFormats';
import { REGISTRAR_SETTINGS_PLATFORM_CLOSURES_NEW_PATH } from 'lib/routerPaths';
import { LOAD_PLATFORM_CLOSURES, RESET_EVENT_STATUS } from 'store/actions';
import { RESET_VALIDATION_GROUPS } from 'store/platformClosure/actions';
import { Alert, Badge, Tab, Tabs } from 'react-bootstrap';
import Spinner from 'components/shared/Spinner';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import PlatformClosureList from 'components/registrar/PlatformClosureList';

function PlatformClosurePage() {
  const platformStatus = useSelector((state) => state.platformClosure.status);
  const { status, allIds: eventIds, byId: events, error, message } = useSelector((state) => state.platformClosure.events);
  const { message: eventMessage } = useSelector((state) => state.platformClosure.event);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(location.hash.slice(1) || 'upcoming');

  let statusMessage = 'open';
  if (platformStatus.data?.platformClosed) {
    statusMessage = `closed until ${format(
      parseISO(platformStatus.data.closePlatformUntil),
      DT_CLOSE_TIME,
    )}`;
  }

  useEffect(() => {
    dispatch(RESET_VALIDATION_GROUPS.action());
    dispatch(LOAD_PLATFORM_CLOSURES.request());
  }, [dispatch]);

  useEffect(() => {
    let clearMessageTimeout;
    if (eventMessage) {
      clearMessageTimeout = setTimeout(() => {
        dispatch(RESET_EVENT_STATUS.action());
      }, 5000);
    }

    return () => {
      clearTimeout(clearMessageTimeout);
    };
  }, [eventMessage, dispatch]);

  useEffect(() => {
    const now = new Date();
    setUpcomingEvents(Object.values(events).filter((event) => parseISO(event.closePlatformUntil) >= now));
    setPastEvents(_orderBy(Object.values(events).filter((event) => parseISO(event.closePlatformUntil) < now), ['closePlatformFrom'], ['desc']));
  }, [events]);

  const handleTabSelect = useCallback((hash) => {
    history.replace({ hash });
    setActiveTabKey(hash);
  }, [history]);

  const handleAlertClose = useCallback(() => {
    dispatch(RESET_EVENT_STATUS.action());
  }, [dispatch]);

  return (
    <RegistrarTemplate>
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Settings', href: '/registrar/settings' },
                { label: 'Platform Closures' },
              ]}
            />

            <h1 className="mb-0">Platform Closures</h1>
          </div>
          <Link className="btn btn-primary btn-sm ms-auto" to={REGISTRAR_SETTINGS_PLATFORM_CLOSURES_NEW_PATH}>
            Schedule Closure
          </Link>
        </div>
      </div>

      <div className="flush px-4 my-4">
        {(status === REDUX_STATUS.SUCCESS && message) && <Alert variant="success" onClose={handleAlertClose} dismissible>{message}</Alert>}
        {eventMessage && <Alert variant="success">{eventMessage}</Alert>}
        {status === REDUX_STATUS.ERROR && <Alert variant="danger" onClose={handleAlertClose} dismissible>{`${error.message} ${error.details.message}`}</Alert>}

        {platformStatus.status === REDUX_STATUS.PENDING && <Spinner />}

        {(status !== REDUX_STATUS.PENDING && eventIds.length) && (
          <>
            <p className="lead">
              {`The platform is now ${statusMessage}.`}
            </p>

            <div className="hstack gap-3 mb-3">
              <div>
                <a href="/registrar/groups?filterrific%5Bwith_state%5D=in_session" className="text-decoration-none">
                  <Badge pill bg="info" className="fs-6 me-1">{platformStatus.data?.inSessionGroups}</Badge>
                  groups in session
                </a>
              </div>
              <div>
                <Badge pill bg="info" className="fs-6 me-1">{platformStatus.data?.temporarilyClosedGroups}</Badge>
                groups temporarily closed
              </div>
            </div>
          </>
        )}

        {eventIds.length === 0 && <Spinner />}
        {eventIds.length > 0 && (
          <Tabs activeKey={activeTabKey} defaultActiveKey="upcoming" className="mb-3" onSelect={handleTabSelect}>
            <Tab eventKey="upcoming" title="Upcoming">
              <PlatformClosureList platformEvents={upcomingEvents} editEvents />
            </Tab>
            <Tab eventKey="past" title="Past">
              <PlatformClosureList platformEvents={pastEvents} />
            </Tab>
          </Tabs>
        )}

      </div>
    </RegistrarTemplate>
  );
}

export default PlatformClosurePage;
