import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType, StylePropType } from 'lib/propTypes';
import ReportingNav from 'components/reporting/ReportingNav';
import BaseTemplate from './BaseTemplate';

function ReportingTemplate({ children, id, className, style }) {
  return (
    <BaseTemplate
      id={id}
      className={className}
      style={style}
      navComponent={<ReportingNav />}
    >
      {children}
    </BaseTemplate>
  );
}

ReportingTemplate.defaultProps = {
  id: null,
  className: null,
  style: null,
};

ReportingTemplate.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: ComponentPropType.isRequired,
  style: StylePropType,
};

export default ReportingTemplate;
