import React from 'react';
import BaseSkeleton from 'components/shared/Skeleton';

function Skeleton() {
  return (
    <div className="row">
      <div className="col-md-8 col-xl-6">
        <BaseSkeleton classes="title w-25 mb-2" />
        <BaseSkeleton classes="input border w-100 mb-2" />
        <BaseSkeleton classes="title w-25 mb-2" />
        <BaseSkeleton classes="input border w-100 mb-2" />

        <div className="d-flex mb-3">
          <BaseSkeleton classes="button" />
          <BaseSkeleton classes="title w-25 ms-2" />
        </div>
      </div>
    </div>
  );
}

export default Skeleton;
