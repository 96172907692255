import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { REDUX_STATUS } from 'lib/constants';
import { buildRoutePath } from 'lib/routerHelpers';
import { ARTICLE_EDIT_PATH } from 'lib/routerPaths';
import { COPY_ARTICLE_FORM_DATA, COPY_ARTICLE, RESET_COURSE_ARTICLE } from 'store/courses/actions';
import { Button, Spinner } from 'react-bootstrap';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import SelectField from 'components/shared/FormFields/SelectField';

const formatCourseLabel = ({ label, orgShortName, courseType }, { context }) => {
  const shouldAddCourseType = !label.toLowerCase().includes(courseType.toLowerCase());

  if (context === 'value') {
    const displayCourseType = shouldAddCourseType ? ` ${courseType}` : '';
    return (
      <>
        <span>{label}</span>
        <span className="ms-2 text-small">{`${orgShortName}${displayCourseType}`}</span>
      </>
    );
  }

  if (context === 'menu') {
    return (
      <>
        <span>{label}</span>
        {shouldAddCourseType && <span className="ms-2 text-small">{courseType}</span>}
      </>
    );
  }
  return label;
};

const FormSchema = Yup.object().shape({
  courseId: Yup.number().required('Required'),
});

function CopyArticleForm({ currentArticleId, showCopyModal, header, setShowCopyModal }) {
  const { copyArticleFormData } = useSelector((state) => state.courses.formData);
  const { data: copiedArticle, status } = useSelector((state) => state.courses.article);
  const dispatch = useDispatch();

  const initialValues = {
    courseId: undefined,
    sourceArticleId: currentArticleId,
  };

  useEffect(() => {
    dispatch(COPY_ARTICLE_FORM_DATA.request());
  }, [dispatch]);

  const handleSave = useCallback((values) => {
    dispatch(COPY_ARTICLE.request(values));
  }, [dispatch]);

  const handleClose = useCallback((resetForm) => {
    setShowCopyModal(false);
    if (typeof resetForm === 'function') resetForm();
    dispatch(RESET_COURSE_ARTICLE.action());
  }, [setShowCopyModal, dispatch]);

  const isLoading = showCopyModal && status === REDUX_STATUS.PENDING;
  const successMessage = (
    <div>
      <div>
        The article was copied successfully
      </div>
      <div className="mt-3">
        Go to
        {' '}
        <Link
          onClick={handleClose}
          to={buildRoutePath(ARTICLE_EDIT_PATH, { courseId: copiedArticle?.courseId, articleId: copiedArticle?.id })}
        >
          {`${copiedArticle?.courseName} / ${copiedArticle?.nameEn}`}
        </Link>
      </div>
    </div>
  );

  return (
    <Formik
      onSubmit={handleSave}
      validationSchema={FormSchema}
      enableReinitialize
      initialValues={initialValues}
    >
      {({ resetForm, handleSubmit }) => {
        const handleModalClose = () => handleClose(resetForm);
        const handleModalConfirm = status === REDUX_STATUS.SUCCESS ? handleModalClose : handleSubmit;
        return (
          <DefaultModal
            size="md"
            isOpen={showCopyModal}
            header={header}
            onClose={() => handleClose(resetForm)}
            footerComponent={(
              <Button className="d-flex align-items-center" disabled={isLoading} value="primary" onClick={handleModalConfirm} type="submit">
                {status === REDUX_STATUS.SUCCESS ? 'Close' : 'Copy'}
                {isLoading ? <Spinner size="sm" className="ms-1" animation="border" role="status" /> : undefined}
              </Button>
            )}
          >
            <Form key={currentArticleId} className="form-horizontal col-12">
              {status === REDUX_STATUS.SUCCESS ? successMessage
                : (
                  <>
                    <p>Which course would you like to copy this article to?</p>
                    <SelectField
                      name="courseId"
                      label="Course"
                      options={copyArticleFormData?.courses}
                      includeBlank="Choose a course"
                      disabled={isLoading || !copyArticleFormData?.courses.length}
                      required
                      formatOptionLabel={formatCourseLabel}
                      filterOptions={{
                        stringify: (option) => `${option.label} ${option.value} ${option.data?.courseType}`,
                      }}
                    />
                  </>
                )}
            </Form>
          </DefaultModal>
        );
      } }
    </Formik>
  );
}

CopyArticleForm.defaultProps = {
  header: '',
};

CopyArticleForm.propTypes = {
  currentArticleId: PropTypes.number.isRequired,
  showCopyModal: PropTypes.bool.isRequired,
  header: PropTypes.string,
  setShowCopyModal: PropTypes.func.isRequired,
};

export default CopyArticleForm;
