import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PREVIOUS_PTQ_REPORTS } from 'store/ptqReport/actions';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import Spinner from 'components/shared/Spinner';
import PtqReportsListItem from 'components/reporting/PtqReports/PtqReportsListItem';

const ReportsList = () => {
  const loading = useSelector((state) => state.ptqReport.loading);
  const reportIds = useSelector((state) => state.ptqReport.reports.allIds);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GET_PREVIOUS_PTQ_REPORTS.request());

    const id = setInterval(() => {
      dispatch(GET_PREVIOUS_PTQ_REPORTS.request());
    }, 20 * 1000);

    return () => clearInterval(id);
  }, [dispatch]);

  return (
    <>
      {loading && <Spinner />}

      {(!loading && reportIds.length > 0) && (
        <div className="row mt-5">
          <div className="col">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Status</th>
                    <th>Filters</th>
                    <th>Requested</th>
                    <th className="text-nowrap">
                      Duration
                      <SimpleToolTip
                        placement="top"
                        text="How long this report took to generate"
                      >
                        <span className="fas fa-info-circle ps-1" />
                      </SimpleToolTip>
                    </th>
                    <th className="text-end">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {reportIds.map((reportId) => <PtqReportsListItem key={reportId} itemId={reportId} />)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportsList;
