import React, { memo, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BulkImportFormDataPropType, UserPropType } from 'lib/propTypes';
import SanyasIcon from 'components/shared/SanyasIcon';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import Input from './Input';
import Select from './Select';

function SummaryRow({ u, index, handleFieldBlur, handleSelectRow, formData, formDataOrganizations, formDataHealthAuthorities }) {
  const initialHaOptions = formDataHealthAuthorities.filter((ha) => ha.org === u.organization);
  const [haOptions, setHaOptions] = useState(initialHaOptions);
  const rowRef = useRef();
  const errorCount = useMemo(() => Object.keys(u.errors).length, [u.errors]);

  const handleOrganizationBlur = useCallback((e) => {
    handleFieldBlur(e, index, 'organization');
    handleFieldBlur(null, index, 'healthAuthority');
    setHaOptions(formDataHealthAuthorities.filter((ha) => ha.org === e.value));
  }, [index, formDataHealthAuthorities, handleFieldBlur]);

  useLayoutEffect(() => {
    let newWidth = -24; // 24px is the initial position of the sticky column
    rowRef.current.querySelectorAll('.bi-sticky-col').forEach((el, i, arr) => {
      if (i > 0) {
        newWidth += arr[i - 1].offsetWidth;
        // eslint-disable-next-line no-param-reassign
        el.style.left = `${newWidth}px`;
      }
    });
  }, []);

  return (
    <tr ref={rowRef} key={u.key}>
      <td className="bi-sticky-col align-top" style={{ minWidth: '2rem' }}>
        <input type="hidden" name={`user.${index}.key`} value={u.key} />
        <input type="hidden" name={`user.${index}.userId`} value={u.userId || undefined} />
        {errorCount > 0 && (
          <SimpleToolTip
            placement="right"
            text={(
              <p className="mb-0 text-start" style={{ whiteSpace: 'pre-line' }}>
                {Object.values(u.errors).join('\n')}
              </p>
            )}
            width="16rem"
          >
            <span className="badge rounded-pill text-bg-danger">
              {errorCount}
            </span>
          </SimpleToolTip>
        )}
        {(!errorCount && u.userId) && (
          <a href={`/registrar/people/${u.userId}/group_memberships`} className="ps-1" target="_blank" rel="noreferrer">
            <SimpleToolTip
              placement="right"
              text={(
                <p className="mb-0 text-start">
                  {u.message ? `${u.message} (click to view details)` : 'Click to view existing user details'}
                </p>
              )}
              width="16rem"
            >
              <SanyasIcon name="boxArrowUpRight" />
            </SimpleToolTip>
          </a>
        )}
      </td>
      <td className="bi-sticky-col align-middle" style={{ minWidth: '2rem' }}>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value={u.key}
            defaultChecked={!u.userId && !errorCount}
            disabled={errorCount}
            name={`import${u.userId ? 'Existing' : 'New'}`}
            id={`include-in-import-${index}`}
            onClick={handleSelectRow}
          />
          <label className="visually-hidden" htmlFor={`include-in-import-${index}`}>
            Include in import
          </label>
        </div>
      </td>
      <td className="bi-sticky-col"><Input name="firstName" index={index} value={u.firstName} error={u.errors.firstName} onBlur={handleFieldBlur} disabled={!!u.userId} /></td>
      <td className="bi-sticky-col"><Input name="lastName" index={index} value={u.lastName} error={u.errors.lastName} onBlur={handleFieldBlur} disabled={!!u.userId} /></td>
      <td className="bi-sticky-col overlap"><Input name="email" type="email" size="xl" index={index} value={u.email} error={u.errors.email} onBlur={handleFieldBlur} /></td>
      <td><Input name="phone" index={index} value={u.phone} error={u.errors.phone} onBlur={handleFieldBlur} disabled={!!u.userId} /></td>
      <td><Input name="address1" size="lg" index={index} value={u.address1} error={u.errors.address1} onBlur={handleFieldBlur} disabled={!!u.userId} /></td>
      <td><Input name="address2" size="lg" index={index} value={u.address2} error={u.errors.address2} onBlur={handleFieldBlur} disabled={!!u.userId} /></td>
      <td><Input name="city" index={index} value={u.city} error={u.errors.city} onBlur={handleFieldBlur} disabled={!!u.userId} /></td>
      <td><Input name="province" index={index} value={u.province} error={u.errors.province} onBlur={handleFieldBlur} disabled={!!u.userId} /></td>
      <td><Input name="postalCode" size="sm" index={index} value={u.postalCode} error={u.errors.postalCode} onBlur={handleFieldBlur} disabled={!!u.userId} /></td>

      <td><Select name="languagePreference" index={index} value={u.languagePreference} error={u.errors.languagePreference} options={formData.languagePreference} onBlur={handleFieldBlur} disabled={!!u.userId} /></td>

      <td><Input name="serviceOrganization" size="lg" index={index} value={u.serviceOrganization} error={u.errors.serviceOrganization} onBlur={handleFieldBlur} /></td>
      <td><Input name="department" size="lg" index={index} value={u.department} error={u.errors.department} onBlur={handleFieldBlur} /></td>

      <td><Select name="organization" index={index} value={u.organization} error={u.errors.organization} options={formDataOrganizations} size="lg" required onBlur={handleOrganizationBlur} /></td>
      <td><Select name="healthAuthority" index={index} value={u.healthAuthority} error={u.errors.healthAuthority} options={haOptions} size="xl" required onBlur={handleFieldBlur} /></td>

      <td><Select name="ancestry" index={index} value={u.ancestry} error={u.errors.ancestry} options={formData.ancestry} size="xl" onBlur={handleFieldBlur} /></td>
      <td><Select name="jobCategory" index={index} value={u.jobCategory} error={u.errors.jobCategory} options={formData.jobCategory} size="xxl" onBlur={handleFieldBlur} /></td>
      <td><Input name="jobCategoryOther" size="lg" index={index} value={u.jobCategoryOther} error={u.errors.jobCategoryOther} onBlur={handleFieldBlur} /></td>
      <td><Select name="educationLevel" index={index} value={u.educationLevel} error={u.errors.educationLevel} options={formData.educationLevel} size="xxl" onBlur={handleFieldBlur} /></td>
      <td><Select name="ageGroup" index={index} value={u.ageGroup} error={u.errors.ageGroup} options={formData.ageGroup} onBlur={handleFieldBlur} /></td>
      <td><Select name="gender" index={index} value={u.gender} error={u.errors.gender} options={formData.gender} onBlur={handleFieldBlur} /></td>

      <td><Input name="authorizerName" size="lg" index={index} value={u.authorizerName} error={u.errors.authorizerName} onBlur={handleFieldBlur} /></td>
      <td><Input name="authorizerEmail" size="xl" index={index} value={u.authorizerEmail} error={u.errors.authorizerEmail} onBlur={handleFieldBlur} /></td>
      <td><Input name="authorizerPhone" index={index} value={u.authorizerPhone} error={u.errors.authorizerPhone} onBlur={handleFieldBlur} /></td>
      <td><Input name="employeeNumber" index={index} value={u.employeeNumber} error={u.errors.employeeNumber} onBlur={handleFieldBlur} /></td>
    </tr>
  );
}

SummaryRow.defaultProps = {
  handleFieldBlur: () => {},
  handleSelectRow: () => {},
};

SummaryRow.propTypes = {
  u: UserPropType.isRequired,
  index: PropTypes.number.isRequired,
  formData: BulkImportFormDataPropType.isRequired,
  formDataOrganizations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formDataHealthAuthorities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleFieldBlur: PropTypes.func,
  handleSelectRow: PropTypes.func,
};

export default memo(SummaryRow);
