import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getCaretCoordinates from 'textarea-caret';
import { RefPropType } from 'lib/propTypes';
import { TYPE_ICONS } from 'lib/constants';
import './styles.scss';

const formatAutoCompleteOption = ({ code, description, type }, tagIndex, selectedIndex) => {
  const icon = TYPE_ICONS[type] || 'question-circle';

  return (
    <li
      className="list-group-item d-flex align-items-baseline p-1 text-small"
      key={code}
      role="option"
      aria-selected={selectedIndex ? tagIndex === selectedIndex : tagIndex === 0}
      style={{ lineHeight: 1.2 }}
    >
      <span className={`icon fas fa-${icon} fa-fw flex-shrink-0 text-opacity-50 me-1`} />
      <div className="d-flex flex-column flex-start" style={{ minWidth: 0 }}>
        <span className="text-truncate">{description}</span>
        <small className="text-opacity-50 text-start text-truncate">{code}</small>
      </div>
    </li>
  );
};

function getMatch(element, placeholder) {
  const { value, selectionStart, selectionEnd } = element;

  for (let match; (match = placeholder.exec(value)) !== null;) {
    if (match.index < selectionStart && placeholder.lastIndex >= selectionEnd) {
      return { code: match[1], replacement: [match.index, placeholder.lastIndex] };
    }
  }

  return null;
}

function TagAutocomplete(props) {
  const { tags, innerRef, hint, placeholder, replacement: [replacementStart, replacementEnd], tagType, tagUsage, showHelp } = props;

  const [showAutoComplete, setShowAutoComplete] = useState(null);
  const lastTagIndex = showAutoComplete?.tags?.findLastIndex(() => true);

  const addTemplateTag = useCallback((tag, [start, end]) => {
    innerRef.current.setRangeText(`${replacementStart}${tag}${replacementEnd}`, start, end, 'end');
    innerRef.current.focus();
  }, [innerRef, replacementStart, replacementEnd]);

  const handleInput = useCallback((e) => {
    const { target } = e;
    if (target === innerRef.current) {
      const match = getMatch(target, placeholder);
      if (!match) {
        setShowAutoComplete(null);
      } else {
        const { top, left, height } = showAutoComplete || getCaretCoordinates(target, match.replacement[0]);
        const acTags = tags.filter((tag) => tag.code.includes(match.code));
        setShowAutoComplete({ code: match.code, replacement: match.replacement, index: 0, tags: acTags, top, left, height });
      }
    }
  }, [tags, innerRef, placeholder, showAutoComplete]);

  const handleBlur = useCallback(() => {
    setShowAutoComplete(null);
  }, []);

  const handleAutoCompleteSelect = useCallback((code) => {
    addTemplateTag(code, showAutoComplete.replacement);
    setShowAutoComplete(null);
  }, [showAutoComplete, addTemplateTag]);

  useEffect(() => {
    const currentInnerRef = innerRef.current;
    window.addEventListener('input', handleInput);
    currentInnerRef.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('input', handleInput);
      currentInnerRef.removeEventListener('blur', handleBlur);
    };
  }, [innerRef, handleInput, handleBlur]);

  const handleUserKeyDown = useCallback((e) => {
    const { key } = e;

    if (showAutoComplete) {
      const alpha = Array.from(Array(26)).map((_, i) => String.fromCharCode(i + 97)); // 97 = char code a
      const validKeys = [...alpha, 'Backspace', 'Tab', 'Enter', 'ArrowUp', 'ArrowDown'];

      if (!validKeys.includes(key)) {
        setShowAutoComplete(null);
      }

      if (key === 'Tab' || key === 'Enter') {
        e.preventDefault();
        handleAutoCompleteSelect(showAutoComplete.tags[showAutoComplete.index].code);
      }

      if (key === 'ArrowUp') {
        e.preventDefault();
        const index = showAutoComplete.index === 0 ? lastTagIndex : showAutoComplete.index - 1;
        setShowAutoComplete((prev) => ({ ...prev, index }));
      }

      if (key === 'ArrowDown') {
        e.preventDefault();
        const index = showAutoComplete.index === lastTagIndex ? 0 : showAutoComplete.index + 1;
        setShowAutoComplete((prev) => ({ ...prev, index }));
      }
    }
  }, [showAutoComplete, lastTagIndex, handleAutoCompleteSelect]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyDown);
    return () => {
      window.removeEventListener('keydown', handleUserKeyDown);
    };
  }, [handleUserKeyDown]);

  const handleEscape = useCallback(({ key }) => {
    if (key === 'Escape') {
      setShowAutoComplete(null);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keyup', handleEscape);
    return () => {
      window.removeEventListener('keyup', handleEscape);
    };
  }, [handleEscape]);

  return (
    <>
      {showHelp && (
        <div className="form-text">
          Keyboard access: Type &lsquo;
          <span className="font-monospace">{hint}</span>
          &rsquo; to search for the
          {' '}
          {tagType}
          {!!tagUsage && ` (e.g., ${tagUsage})`}
          . Press Enter or Tab to select and add.
        </div>
      )}

      {showAutoComplete?.tags?.length > 0 && (
        <ul
          className="auto-complete list-group position-absolute border rounded bg-white overflow-auto"
          role="listbox"
          style={{
            top: showAutoComplete.top + (2.6 * showAutoComplete.height),
            left: showAutoComplete.left,
            zIndex: 1,
            maxHeight: 480,
            minWidth: 180,
            maxWidth: 240,
          }}
        >
          {showAutoComplete.tags.map((tag, tagIndex) => formatAutoCompleteOption(tag, tagIndex, showAutoComplete.index, handleAutoCompleteSelect))}
        </ul>
      )}
    </>
  );
}

TagAutocomplete.defaultProps = {
  tags: [],
  hint: '{{ ',
  tagType: 'shortcode',
  tagUsage: 'template tag',
  showHelp: true,
};

TagAutocomplete.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
  })),
  innerRef: RefPropType.isRequired,
  hint: PropTypes.string,
  placeholder: PropTypes.instanceOf(RegExp).isRequired,
  replacement: PropTypes.arrayOf(PropTypes.string).isRequired,
  tagType: PropTypes.string,
  tagUsage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showHelp: PropTypes.bool,
};

export default TagAutocomplete;
