import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const UserConfirmation = (message, callback) => {
  let text = 'Are you sure you want to leave this page?';
  let title = 'Warning';
  let confirmButtonText = 'Confirm';
  let cancelButtonText = 'Cancel';

  try {
    const messageObj = JSON.parse(message);
    if (messageObj.text) {
      text = messageObj.text;
    }
    if (messageObj.title) {
      title = messageObj.title;
    }
    if (messageObj.confirm) {
      confirmButtonText = messageObj.confirm;
    }
    if (messageObj.cancel) {
      cancelButtonText = messageObj.cancel;
    }
  } catch (e) {
    if (message.trim()) {
      text = message;
    }
  }

  const container = document.createElement('div');
  document.body.appendChild(container);

  const closeModal = (callbackState) => {
    unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(callbackState);
  };

  render(
    <Modal
      centered
      show
      onHide={() => closeModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {text}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => closeModal(false)}>
          {cancelButtonText}
        </Button>

        <Button
          variant="outline-danger"
          onClick={() => closeModal(true)}
        >
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>,
    container,
  );
};

export default UserConfirmation;
