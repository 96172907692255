import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_SHOW_PATH, CURRICULUM_COURSE_TYPE_SHOW_PATH, CURRICULUM_PATH } from 'lib/routerPaths';
import { selectCourseById } from 'store/courses/selectors';
import { LOAD_COURSE_TYPES } from 'store/courseTypes/actions';
import { LOAD_COURSE, LOAD_COMPLETION_REPORT } from 'store/courses/actions';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import CurriculumTemplate from 'components/templates/CurriculumTemplate';
import SanyasIcon from 'components/shared/SanyasIcon';
import Skeleton from 'components/shared/Skeleton';
import CompletionReport from 'components/shared/CompletionReport/CompletionReport';

function CourseCompletionReportPage() {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const course = useSelector((state) => selectCourseById(state, courseId));
  const courseType = useSelector((state) => selectCourseTypeById(state, course?.courseTypeId));

  useEffect(() => {
    dispatch(LOAD_COURSE_TYPES.request());
  }, [dispatch]);

  useEffect(() => {
    if (!course.id) {
      dispatch(LOAD_COURSE.request(courseId));
    }
  }, [course.id, courseId, dispatch]);

  useEffect(() => {
    dispatch(LOAD_COMPLETION_REPORT.request({ courseId, workspace: 'curriculum' }));
  }, [courseId, dispatch]);

  return (
    <CurriculumTemplate className="bg-white">
      <div className="header flush mb-4">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <SanyasIcon name="book" size="3x" />
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Courses', href: CURRICULUM_PATH },
                { label: courseType?.name || <Skeleton classes="text" />, href: buildRoutePath(CURRICULUM_COURSE_TYPE_SHOW_PATH, { id: courseType?.id }) },
                { label: course?.description || <Skeleton classes="text" />, href: buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course?.id }) },
              ]}
              ignoreLast
            />

            <h1>{courseType?.name}</h1>
          </div>
        </div>
      </div>
      <div className="ms-2">
        <CompletionReport />
      </div>
    </CurriculumTemplate>
  );
}

export default CourseCompletionReportPage;
