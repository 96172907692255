import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { REDUX_STATUS, REDUX_SUCCESS, TASK_TYPES, TASK_TYPE_ICONS } from 'lib/constants';
import { sentenceCase } from 'lib/utils';
import usePrevious from 'lib/hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { buildRoutePath } from 'lib/routerHelpers';
import { TASK_EDIT_PATH } from 'lib/routerPaths';
import { CREATE_TASK } from 'store/courses/actions';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import { Button, Spinner } from 'react-bootstrap';
import Skeleton from 'components/curriculum/Module/Skeleton';
import SanyasIcon from 'components/shared/SanyasIcon';
import InputField from 'components/shared/FormFields/InputField';
import SelectField from 'components/shared/FormFields/SelectField';
import ErrorMessage from 'components/shared/ErrorMessage';
import Confirm from './Confirm';

const taskTypesWithIcon = Object.entries(TASK_TYPES).map(([label, value]) => ({
  label: sentenceCase(label),
  value,
  icon: TASK_TYPE_ICONS[value],
})).filter((v) => v.icon); // Remove task types without icons

const formatOptionLabel = ({ label, icon }) => {
  if (!icon) return label;
  return (
    <>
      <SanyasIcon name={icon} size="lg" className="me-2" />
      {label}
    </>
  );
};

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
});

function NewTaskForm() {
  const { course, currentSection } = useCourseShowContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const { error: courseError, status: courseStatus, task: activeTask } = useSelector((state) => state.courses);
  const prevTaskSuccess = usePrevious(activeTask.success);

  useEffect(() => {
    if (!prevTaskSuccess && activeTask.success === REDUX_SUCCESS.CREATED) {
      history.push(buildRoutePath(TASK_EDIT_PATH, { courseId: course?.id, taskId: activeTask?.id }));
    }
  }, [prevTaskSuccess, activeTask.success, activeTask?.id, course?.id, history]);

  const initialValues = {
    sectionId: currentSection?.id || '',
    name: '',
    type: 'PageTask',
    summary: '',
  };

  const handleSubmit = ((values, actions) => {
    dispatch(CREATE_TASK.request(values, { formikActions: actions }));
  });

  if (!course?.id || courseStatus === REDUX_STATUS.PENDING) {
    return <Skeleton />;
  }

  return (
    <>
      <ErrorMessage error={courseError || activeTask.error} className="mt-2" />

      <Formik
        onSubmit={handleSubmit}
        validationSchema={FormSchema}
        initialValues={initialValues}
      >
        {({ dirty, isSubmitting }) => (
          <Form className="row">
            {dirty && <Confirm />}

            <div className="col-lg-10 col-xl-9 ps-4">
              <div className="d-flex justify-content-between align-content-center mb-2">
                <h2 className="m-0">
                  New task
                </h2>
                <div>
                  <Button
                    size="sm"
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                    {isSubmitting && <Spinner size="sm" variant={initialValues.id ? 'primary' : null} className="ms-1" animation="border" role="status" />}
                  </Button>
                </div>
              </div>

              <div className="border rounded p-3">
                <div className="row">
                  <div className="col-7">
                    <InputField label="Task name" name="name" />
                  </div>

                  <div className="col-5">
                    <SelectField options={taskTypesWithIcon} label="Task type" name="type" required formatOptionLabel={formatOptionLabel} />
                  </div>
                  <div className="col-12">
                    <InputField label="Task description" name="summary" />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default NewTaskForm;
