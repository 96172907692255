import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_SHOW_PATH } from 'lib/routerPaths';
import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import usePrevious from 'lib/hooks/usePrevious';
import { RESET_COURSE_STATUS } from 'store/courses/actions';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import InputField from 'components/shared/FormFields/InputField';
import ErrorMessage from 'components/shared/ErrorMessage';

const FormSchema = Yup.object().shape({
  description: Yup.string().required('Description is required'),
});

function CourseDraftCreateDialog(props) {
  const { show, onClose, courseTypeId, isDuplicating, onSubmit, courseId } = props;
  const courseType = useSelector((state) => selectCourseTypeById(state, courseTypeId));
  const course = useSelector((state) => state.courses.course);
  const currentUserId = useSelector((state) => state.currentUser?.id);
  const prevCourseStatus = usePrevious(course?.status);
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValues = {
    name: courseType?.name,
    description: '',
    courseTypeId,
    master: true,
    editable: true,
    courseId,
    userId: currentUserId,
  };

  useEffect(() => {
    if (prevCourseStatus === REDUX_STATUS.PENDING && course?.status === REDUX_STATUS.SUCCESS && course?.success === REDUX_SUCCESS.CREATED) {
      history.push(buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course?.id }));
    }
  }, [prevCourseStatus, course?.status, course?.success, course?.id, history]);

  const closeModal = useCallback((resetForm) => {
    onClose();
    dispatch(RESET_COURSE_STATUS.action());
    resetForm();
  }, [onClose, dispatch]);

  let buttonText = '';
  if (isDuplicating && course.status === REDUX_STATUS.SUCCESS) {
    buttonText = 'Close';
  } else if (isDuplicating) {
    buttonText = 'Duplicate';
  } else {
    buttonText = 'Create';
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={FormSchema}
      validateOnBlur={false}
      enableReinitialize
    >
      {({ resetForm, handleSubmit, isSubmitting }) => (
        <DefaultModal
          isOpen={show}
          header={isDuplicating ? 'Duplicate a draft' : 'Create a draft'}
          onClose={() => closeModal(resetForm)}
          footerComponent={(
            <Button value="primary" onClick={isDuplicating && course.status === REDUX_STATUS.SUCCESS ? () => closeModal(resetForm) : handleSubmit} type="submit" disabled={isSubmitting}>
              {buttonText}
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
            )}
        >
          <Form>
            {course?.error && (
              <ErrorMessage error={course.error} />
            )}

            {isDuplicating && course.status === REDUX_STATUS.SUCCESS ? 'The course duplication process has been started. You will receive an notification when the process is complete.'
              : (
                <>
                  <p>Add a description to your draft. For example &ldquo;Working copy&rdquo; or &ldquo;New curriculum&rdquo;. Descriptions are only visible to the San&rsquo;yas team.</p>
                  <InputField name="description" label="Description" autoFocus />
                </>
              )}
          </Form>
        </DefaultModal>
      )}
    </Formik>
  );
}

CourseDraftCreateDialog.defaultProps = {
  isDuplicating: false,
  courseId: '',
};

CourseDraftCreateDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  courseTypeId: PropTypes.number.isRequired,
  isDuplicating: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  courseId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default CourseDraftCreateDialog;
