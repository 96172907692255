import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CREATE_FILE_TASK_RESOURCE, RESET_RESOURCE } from 'store/courses/actions';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import InputField from 'components/shared/FormFields/InputField';
import ErrorMessage from 'components/shared/ErrorMessage';

const FormSchema = Yup.object().shape({
  title: Yup.string().required().label('Name'),
  file: Yup.mixed().required().label('File'),
});

function AddFileForm({ setAddingFile }) {
  const { taskId } = useParams();
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.courses.resource);
  const inputRef = useRef(null);

  const handleSubmit = useCallback((values, actions) => {
    dispatch(CREATE_FILE_TASK_RESOURCE.request(values, { formikActions: actions }));
  }, [dispatch]);

  useEffect(() => {
    inputRef.current.focus({ preventScroll: true });
  }, []);

  useEffect(() => {
    if (status === 'success') {
      dispatch(RESET_RESOURCE.action());
      setAddingFile(false);
    }
  }, [status, setAddingFile, dispatch]);

  return (
    <>
      <ErrorMessage error={error} />

      <Formik
        onSubmit={handleSubmit}
        initialValues={{ title: '', file: '', taskId: Number(taskId) }}
        validationSchema={FormSchema}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <Form style={{ width: '20rem' }}>
            <InputField name="title" label="Name" size="sm" inputRef={inputRef} autoComplete="nope" />
            <InputField name="file" label="File" size="sm" type="file" />

            <Button variant="primary" size="sm" type="submit" disabled={isSubmitting}>
              Add
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
          </Form>

        )}
      </Formik>
    </>
  );
}

AddFileForm.defaultProps = {
  setAddingFile: () => {},
};

AddFileForm.propTypes = {
  setAddingFile: PropTypes.func,
};

export default AddFileForm;
