import { asyncAction, syncAction } from 'lib/actionCreatorCreators';

export const ADD_QUESTION = syncAction('ADD_QUESTION');
export const LOAD_COURSES = asyncAction('LOAD_COURSES');
export const LOAD_COURSE = asyncAction('LOAD_COURSE');
export const LOAD_COURSE_GROUPS = asyncAction('LOAD_COURSE_GROUPS');
export const LOAD_COURSE_SECTIONS = asyncAction('LOAD_COURSE_SECTIONS');
export const CREATE_COURSE = asyncAction('CREATE_COURSE');
export const UPDATE_COURSE = asyncAction('UPDATE_COURSE');
export const PUBLISH_COURSE = asyncAction('PUBLISH_COURSE');
export const UPDATE_COURSE_DATA = syncAction('UPDATE_COURSE_DATA');
export const REMOVE_COURSE = syncAction('REMOVE_COURSE');
export const MERGE_COURSE = syncAction('MERGE_COURSE');
export const ARCHIVE_COURSE = asyncAction('ARCHIVE_COURSE');
export const DUPLICATE_COURSE = asyncAction('DUPLICATE_COURSE');
export const DUPLICATE_COURSE_UPDATE_STATUS = syncAction('DUPLICATE_COURSE_UPDATE_STATUS');
export const RESET_COURSE_STATUS = syncAction('RESET_COURSE_STATUS');
export const LOAD_COURSE_TYPE_COURSES = asyncAction('LOAD_COURSE_TYPE_COURSES');
export const LOAD_COURSE_TYPE_ARCHIVED_COURSES = asyncAction('LOAD_COURSE_TYPE_ARCHIVED_COURSES');
export const RESET_COURSES = syncAction('RESET_COURSES');
export const SAVE_TASK = asyncAction('SAVE_TASK');
export const CREATE_TASK = asyncAction('CREATE_TASK');
export const RESET_TASK = syncAction('RESET_TASK');
export const SAVE_SECTION = asyncAction('SAVE_SECTION');
export const CREATE_SECTION = asyncAction('CREATE_SECTION');
export const RESET_SECTION = syncAction('RESET_SECTION');
export const DELETE_SECTION = asyncAction('DELETE_SECTION');
export const REMOVE_SECTION = syncAction('REMOVE_SECTION');
export const DELETE_TASK = asyncAction('DELETE_TASK');
export const REMOVE_TASK = syncAction('REMOVE_TASK');
export const LOAD_FORM_DATA = asyncAction('LOAD_FORM_DATA');
export const REORDER_COURSE_SECTION = syncAction('REORDER_COURSE_SECTION');
export const REORDER_COURSE_SECTION_TASK = syncAction('REORDER_COURSE_SECTION_TASK');
export const REORDER_QUESTIONS = syncAction('REORDER_QUESTIONS');
export const COPY_TASK_FORM_DATA = asyncAction('COPY_TASK_FORM_DATA');
export const COPY_TASK = asyncAction('COPY_TASK');
export const COPY_TASK_UPDATE_STATUS = syncAction('COPY_TASK_UPDATE');
export const CREATE_FILE_TASK_RESOURCE = asyncAction('CREATE_FILE_TASK_RESOURCE');
export const CREATE_URL_TASK_RESOURCE = asyncAction('CREATE_URL_TASK_RESOURCE');
export const DESTROY_RESOURCE = asyncAction('DESTROY_RESOURCE');
export const RESET_RESOURCE = syncAction('RESET_RESOURCE');
export const LOAD_TASK_QUESTIONS = asyncAction('LOAD_TASK_QUESTIONS');
export const RESET_TASK_QUESTIONS = syncAction('RESET_TASK_QUESTIONS');
export const LOAD_TASK_PROACTIVE = asyncAction('LOAD_TASK_PROACTIVE');
export const RESET_TASK_PROACTIVE = syncAction('RESET_TASK_PROACTIVE');
export const LOAD_COURSE_PROACTIVES = asyncAction('LOAD_COURSE_PROACTIVES');
export const LOAD_COURSE_PHRASEBOOK_ENTRIES = asyncAction('LOAD_COURSE_PHRASEBOOK_ENTRIES');
export const CREATE_PHRASEBOOK_ENTRY = asyncAction('CREATE_PHRASEBOOK_ENTRY');
export const SAVE_PHRASEBOOK_ENTRY = asyncAction('SAVE_PHRASEBOOK_ENTRY');
export const DELETE_PHRASEBOOK_ENTRY = asyncAction('DELETE_PHRASEBOOK_ENTRY');
export const RESET_PHRASEBOOK_ENTRY = syncAction('RESET_PHRASEBOOK_ENTRY');
export const LOAD_COURSE_GLOSSARY_ENTRIES = asyncAction('LOAD_COURSE_GLOSSARY_ENTRIES');
export const CREATE_GLOSSARY_ENTRY = asyncAction('CREATE_GLOSSARY_ENTRY');
export const SAVE_GLOSSARY_ENTRY = asyncAction('SAVE_GLOSSARY_ENTRY');
export const DELETE_GLOSSARY_ENTRY = asyncAction('DELETE_GLOSSARY_ENTRY');
export const RESET_GLOSSARY_ENTRY = syncAction('RESET_GLOSSARY_ENTRY');
export const FIND_AND_REPLACE_SEARCH = asyncAction('FIND_AND_REPLACE_SEARCH');
export const FIND_AND_REPLACE_UPDATE = asyncAction('FIND_AND_REPLACE_UPDATE');
export const FIND_AND_REPLACE_UPDATE_ALL = asyncAction('FIND_AND_REPLACE_UPDATE_ALL');
export const FIND_AND_REPLACE_RESET = syncAction('FIND_AND_REPLACE_RESET');
export const LOAD_COURSE_ARTICLES = asyncAction('LOAD_COURSE_ARTICLES');
export const CREATE_COURSE_ARTICLE = asyncAction('CREATE_COURSE_ARTICLE');
export const REMOVE_COURSE_ARTICLE = syncAction('REMOVE_COURSE_ARTICLE');
export const SAVE_COURSE_ARTICLE = asyncAction('SAVE_COURSE_ARTICLE');
export const DELETE_COURSE_ARTICLE = asyncAction('DELETE_COURSE_ARTICLE');
export const REORDER_COURSE_ARTICLE = syncAction('REORDER_COURSE_ARTICLE');
export const RESET_COURSE_ARTICLE = syncAction('RESET_COURSE_ARTICLE');
export const COPY_ARTICLE_FORM_DATA = asyncAction('COPY_ARTICLE_FORM_DATA');
export const COPY_ARTICLE = asyncAction('COPY_ARTICLE');
export const UPDATE_COMPLETION_THRESHOLD = asyncAction('COMPLETION_THRESHOLD');
export const AVERAGE_TIME_BEFORE_COMPLETION = asyncAction('AVERAGE_TIME_BEFORE_COMPLETION');
export const LOAD_COMPLETION_REPORT = asyncAction('LOAD_COMPLETION_REPORT');
