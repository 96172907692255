import React from 'react';

function CameraVideo() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.0005 5.25C3.033 5.25 2.25 6.033 2.25 7.0005V16.9995C2.25 17.967 3.033 18.75 4.0005 18.75H13.9995C14.967 18.75 15.75 17.967 15.75 16.9995V7.0005C15.75 6.033 14.967 5.25 13.9995 5.25H4.0005ZM0.75 7.0005C0.75 5.205 2.205 3.75 4.0005 3.75H13.9995C15.795 3.75 17.25 5.205 17.25 7.0005V16.9995C17.25 18.795 15.795 20.25 13.9995 20.25H4.0005C3.13841 20.25 2.31164 19.9075 1.70205 19.298C1.09246 18.6884 0.75 17.8616 0.75 16.9995V7.0005Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.875 8.475L21.027 6.0675C21.0983 6.02451 21.1797 6.00094 21.263 5.99916C21.3462 5.99737 21.4285 6.01743 21.5017 6.05733C21.5748 6.09723 21.6362 6.15558 21.6797 6.22657C21.7233 6.29757 21.7475 6.37874 21.75 6.462V17.538C21.75 17.88 21.36 18.1275 21.027 17.934L16.8765 15.5265L16.1235 16.824L20.274 19.2315C21.5625 19.9785 23.25 19.089 23.25 17.538V6.462C23.25 4.9125 21.5625 4.023 20.274 4.77L16.125 7.1775L16.878 8.475H16.875Z" fill="currentColor" />
    </svg>
  );
}

export default CameraVideo;
