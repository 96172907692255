import React from 'react';
import { SectionPropType, GroupPropType } from 'lib/propTypes';
import DiscussionTaskDetails from './DiscussionTaskDetails';

function DiscussionModuleDetails({ section, group }) {
  const tasksValues = Object.values(section.tasks);

  return (
    <div className="card border">
      <h5 className="card-header">
        <span className="text-secondary">
          {`M${section.position}: `}
        </span>
        {section.name}
      </h5>

      <ul className="list-group list-group-flush">
        { tasksValues.map((task) => (
          <li className="list-group-item" key={task.id}>
            <DiscussionTaskDetails task={task} group={group} />
          </li>
        ))}
      </ul>
    </div>
  );
}

DiscussionModuleDetails.propTypes = {
  section: SectionPropType.isRequired,
  group: GroupPropType.isRequired,
};

export default DiscussionModuleDetails;
