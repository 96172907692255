import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType, StylePropType } from 'lib/propTypes';
import FacilitatorNav from 'components/facilitator/FacilitatorNav';
import BaseTemplate from './BaseTemplate';

function FacilitatorTemplate({ children, id, className, style }) {
  return (
    <BaseTemplate
      id={id}
      className={className}
      style={style}
      navComponent={<FacilitatorNav />}
    >
      {children}
    </BaseTemplate>
  );
}

FacilitatorTemplate.defaultProps = {
  id: null,
  className: null,
  style: null,
};

FacilitatorTemplate.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: ComponentPropType.isRequired,
  style: StylePropType,
};

export default FacilitatorTemplate;
