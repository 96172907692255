import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

function ReportLinkCard({ title, description, link, linkText }) {
  return (
    <div className="card border h-100">
      <h5 className="card-header">{title}</h5>
      <div className="card-body report-card">
        <p className="card-text">{description}</p>
        <div className="text-end report-button">
          <Button variant="primary" size="sm" href={link} target="_blank" rel="noreferrer" className="stretched-link">{linkText}</Button>
        </div>
      </div>
    </div>
  );
}

ReportLinkCard.defaultProps = {
  description: null,
  linkText: 'View Report',
};

ReportLinkCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string,
};

export default ReportLinkCard;
