import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { ErrorPropType, StylePropType } from 'lib/propTypes';
import { sentenceCase } from 'lib/utils';

/* Example simple error object
{
  "name": "InvalidRequestError",
  "details": {
      "code": "errors.interaction.validation_failed",
      "message": "Could not update.",
      "errors": {
          "types": [
              "is missing"
          ],
          "answerRequired": [
              "is missing"
          ]
      }
  }
}
 * Nested object
{
    "name": "InvalidRequestError",
    "details": {
        "code": "errors.interaction.validation_failed",
        "message": "Could not create.",
        "errors": {
            "task": {
                "sectionId": [
                    "must be filled"
                ]
            }
        }
    }
} */

function formatErrors(errors) {
  return Object.entries(errors).map(([key, value]) => {
    if (Array.isArray(value)) {
      return (
        <li key={key}>
          {sentenceCase(key)}
          {' '}
          {value.join(', ')}
        </li>
      );
    }
    return formatErrors(value);
  });
}

function ErrorMessage({ error, className, style }) {
  const { name, message, details } = error;

  if (name || message || details) {
    return (
      <Alert variant="danger" className={className} style={style}>
        {details?.message || message || 'An error occurred.'}

        {details?.errors && (
          <ul className="mb-0">
            {formatErrors(details?.errors)}
          </ul>
        )}
      </Alert>
    );
  }

  return null;
}

ErrorMessage.defaultProps = {
  error: {},
  className: null,
  style: null,
};

ErrorMessage.propTypes = {
  error: ErrorPropType,
  className: PropTypes.string,
  style: StylePropType,
};

export default ErrorMessage;
