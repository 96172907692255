import React from 'react';

function Link() {
  return (
    <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.60884 5.5H4.1604C3.33283 5.5 2.53915 5.81607 1.95397 6.37868C1.36879 6.94129 1.04004 7.70435 1.04004 8.5C1.04004 9.29565 1.36879 10.0587 1.95397 10.6213C2.53915 11.1839 3.33283 11.5 4.1604 11.5H7.28076C7.7801 11.5003 8.2722 11.3853 8.71574 11.1648C9.15928 10.9442 9.54129 10.6246 9.82969 10.2327C10.1181 9.84079 10.3044 9.38806 10.3731 8.91255C10.4418 8.43703 10.3907 7.95262 10.2243 7.5H9.361C9.27155 7.5 9.18418 7.51 9.10097 7.531C9.27639 7.83551 9.3661 8.17876 9.36125 8.52681C9.35639 8.87487 9.25714 9.21569 9.07329 9.51556C8.88945 9.81544 8.62738 10.064 8.313 10.2366C7.99862 10.4093 7.64282 10.5001 7.28076 10.5H4.1604C3.60869 10.5 3.07957 10.2893 2.68945 9.91421C2.29933 9.53914 2.08016 9.03043 2.08016 8.5C2.08016 7.96957 2.29933 7.46086 2.68945 7.08579C3.07957 6.71071 3.60869 6.5 4.1604 6.5H5.75698C5.98373 6.124 6.27184 5.786 6.60988 5.5H6.60884Z" fill="currentColor" />
      <path d="M7.03516 6.49992H7.28062C7.65923 6.49992 8.01495 6.59692 8.32075 6.76792C8.63679 6.59193 8.99564 6.49946 9.36087 6.49992H9.60633C9.25703 6.12392 8.81493 5.83849 8.32075 5.66992C7.82648 5.83834 7.38434 6.12379 7.03516 6.49992Z" fill="currentColor" />
      <path d="M9.36126 5.5C8.86192 5.49975 8.36981 5.61471 7.92628 5.83524C7.48274 6.05576 7.10073 6.3754 6.81233 6.76731C6.52393 7.15921 6.33757 7.61194 6.2689 8.08745C6.20024 8.56297 6.25127 9.04738 6.41772 9.5H7.55977C7.37719 9.19597 7.28107 8.85108 7.28107 8.50001C7.28107 8.14894 7.37718 7.80406 7.55975 7.50002C7.74233 7.19599 8.00492 6.94351 8.32115 6.76797C8.63738 6.59243 8.9961 6.50001 9.36126 6.5H12.4816C13.0333 6.5 13.5624 6.71071 13.9526 7.08579C14.3427 7.46086 14.5619 7.96957 14.5619 8.5C14.5619 9.03043 14.3427 9.53914 13.9526 9.91421C13.5624 10.2893 13.0333 10.5 12.4816 10.5H10.885C10.6587 10.8754 10.3707 11.2131 10.0321 11.5H12.4816C12.8914 11.5 13.2971 11.4224 13.6757 11.2716C14.0543 11.1209 14.3983 10.8999 14.688 10.6213C14.9778 10.3427 15.2076 10.012 15.3645 9.64805C15.5213 9.28407 15.602 8.89397 15.602 8.5C15.602 8.10603 15.5213 7.71593 15.3645 7.35195C15.2076 6.98797 14.9778 6.65726 14.688 6.37868C14.3983 6.1001 14.0543 5.87913 13.6757 5.72836C13.2971 5.5776 12.8914 5.5 12.4816 5.5H9.36126Z" fill="currentColor" />
      <path d="M8.32075 11.3304C8.81481 11.1616 9.25686 10.8762 9.60633 10.5004H9.36087C8.99564 10.5009 8.63679 10.4084 8.32075 10.2324C8.0047 10.4084 7.64585 10.5009 7.28062 10.5004H7.03516C7.38048 10.8714 7.82149 11.1604 8.32075 11.3304Z" fill="currentColor" />
    </svg>
  );
}

export default Link;
