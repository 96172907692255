import React from 'react';
import { LocalePropType, QuestionPropType } from 'lib/propTypes';
import { getLocaleSuffix } from 'lib/utils';

function EssayQuestion({ question, locale }) {
  return (
    <div id={`${question.id}`} className="question">
      <div className="d-flex align-items-center mb-1">
        <div className="question-number">
          <label className="form-label mb-0">Question</label>
        </div>

        <span className="ms-auto font-weight-semibold" style={{ fontSize: '0.875em' }}>
          Essay question
        </span>
      </div>

      <div style={{ whiteSpace: 'pre-wrap' }}>
        {question[`body${getLocaleSuffix(locale)}`]}
      </div>

      <div className="d-flex align-items-center justify-content-end">
        <span className="text-small">
          {question.answerRequired ? 'Required' : 'Optional'}
        </span>
      </div>
    </div>
  );
}

EssayQuestion.defaultProps = {
  locale: 'en',
};

EssayQuestion.propTypes = {
  question: QuestionPropType.isRequired,
  locale: LocalePropType,
};

export default EssayQuestion;
