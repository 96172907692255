import React, { useCallback, useEffect, useMemo } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, format, subDays } from 'date-fns';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { DATE_RANGE, PHYSICIAN_AFFILIATION_OPTIONS } from 'lib/constants';
import { COMPACT_DATE } from 'lib/dateFormats';
import { GET_PTQ_FORM_DATA, REQUEST_PLATFORM_PTQ } from 'store/ptqReport/actions';
import Button from 'react-bootstrap/Button';
import Spinner from 'components/shared/Spinner';
import SelectField from 'components/shared/FormFields/SelectField';
import RadioCheckField from 'components/shared/FormFields/RadioCheckField';
import DatePickerField from 'components/shared/FormFields/DatePickerField';

const formatCourseTypeLabel = ({ label, orgShortName }, { context }) => {
  if (context === 'value') {
    return `${label} (${orgShortName})`;
  }
  return label;
};

const FormSchema = Yup.object().shape({
  courseTypeId: Yup.array().min(1, 'Please select at least one (1) Course').of(Yup.number()).label('Course'),
  startDate: Yup.date().required('Required').typeError('Please select a valid date'),
  endDate: Yup.date().required('Required').typeError('Please select a valid date')
    .test(
      'end_date_test',
      'End date must be after start date.',
      (value, context) => {
        const { startDate } = context.parent;
        return value > startDate;
      },
    ),
});

function PtqReportsForm() {
  const { formLoading, formData } = useSelector((state) => state.ptqReport);
  const { byId: organizations } = useSelector((state) => state.organizations);
  const dispatch = useDispatch();

  const initialValues = {
    courseTypeId: [],
    dateRange: 'closeSessionAt',
    startDate: format(subDays(new Date(), 1), COMPACT_DATE),
    endDate: format(addDays(new Date(), 1), COMPACT_DATE),
    physicianCreditType: null,
  };

  const courseTypeOptions = useMemo(() => Object.values(organizations).map((org) => ({
    label: org.name,
    options: formData.courseTypes && Object.values(formData.courseTypes).map((courseType) => {
      if (courseType.organizationId !== org.id) return null;
      return ({
        label: courseType.name,
        value: courseType.id,
        orgShortName: org.shortName,
      });
    }).filter((courseType) => courseType),
  })), [organizations, formData.courseTypes]);

  useEffect(() => {
    dispatch(GET_PTQ_FORM_DATA.request());
  }, [dispatch]);

  const handleSubmit = useCallback((values) => {
    dispatch(REQUEST_PLATFORM_PTQ.request(values));
  }, [dispatch]);

  return (
    <>
      {formLoading && <Spinner />}

      {(!formLoading && !_isEmpty(formData)) && (
        <>
          <h2 className="fs-4">Filter by</h2>

          <Formik
            onSubmit={handleSubmit}
            validationSchema={FormSchema}
            initialValues={initialValues}
          >
            {({ values, resetForm }) => (
              <Form>
                <div className="row">
                  <div className="col-md-8">
                    <SelectField
                      name="courseTypeId"
                      label="Courses"
                      options={courseTypeOptions}
                      includeBlank="Not Selected"
                      multiple
                      formatOptionLabel={formatCourseTypeLabel}
                    />

                    <RadioCheckField name="dateRange" type="radio" options={[{ value: 'beginSessionAt', label: DATE_RANGE.beginSessionAt }, { value: 'closeSessionAt', label: DATE_RANGE.closeSessionAt }]} />

                    <div className="row">
                      <div className="col-md-6 col-xxl-4">
                        <DatePickerField
                          name="startDate"
                          label={`${DATE_RANGE[values.dateRange]} between:`}
                          placeholder="Pick start date"
                          required
                        />
                      </div>

                      <div className="col-md-6 col-xxl-4">
                        <DatePickerField name="endDate" label="and:" placeholder="Pick end date" required />
                      </div>
                    </div>

                    <SelectField
                      name="physicianCreditType"
                      label="Physician affiliation"
                      options={PHYSICIAN_AFFILIATION_OPTIONS}
                      includeBlank="Not Selected"
                    />
                  </div>
                </div>

                <div className="my-3">
                  <Button variant="primary" type="submit" className="me-2">
                    Request this report
                  </Button>
                  <Button variant={null} type="button" className="btn-plain" onClick={resetForm}>
                    Reset
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
}

export default PtqReportsForm;
