import React from 'react';
import PropTypes from 'prop-types';
import { useFieldContext } from 'lib/hooks/useFieldContext';
import { RefPropType } from 'lib/propTypes';
import { SIZES } from 'lib/constants';
import SharedClearableInput from '../ClearableInput/ClearableInput';

/* eslint-disable react/jsx-props-no-spreading */

function ClearableInput({ name, size, inputRef, ...props }) {
  const { id, touched, error, handleChange } = useFieldContext();

  return (
    <SharedClearableInput
      ref={inputRef}
      id={id}
      name={name}
      className={`form-control ${(touched && error) ? 'is-invalid' : ''} ${size !== SIZES.md ? `form-control-${size}` : ''}`}
      onChange={handleChange}
      {...props}
    />
  );
}

ClearableInput.defaultProps = {
  size: SIZES.md,
  inputRef: null,
};

ClearableInput.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  inputRef: RefPropType,
};

export default ClearableInput;
