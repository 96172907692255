import React from 'react';

function ShieldLock() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.16457 2.9865C6.79279 3.35528 5.42991 3.75639 4.07707 4.1895C3.95464 4.22729 3.84536 4.29886 3.76178 4.39597C3.67819 4.49308 3.6237 4.61181 3.60457 4.7385C2.80507 10.584 4.65007 14.85 6.85057 17.6595C7.95457 19.0695 9.15007 20.1135 10.1461 20.799C10.6456 21.141 11.0851 21.39 11.4316 21.549C11.6056 21.6285 11.7466 21.6825 11.8546 21.714C11.9022 21.7293 11.9508 21.7413 12.0001 21.75C12.0106 21.7485 12.0571 21.7425 12.1456 21.7155C12.2536 21.6825 12.3946 21.6285 12.5686 21.549C12.9136 21.39 13.3561 21.141 13.8541 20.799C15.103 19.9227 16.2138 18.8645 17.1496 17.6595C19.3501 14.85 21.1951 10.584 20.3956 4.7385C20.3764 4.61181 20.3219 4.49308 20.2384 4.39597C20.1548 4.29886 20.0455 4.22729 19.9231 4.1895C18.9841 3.8895 17.4001 3.4005 15.8356 2.9865C14.2366 2.565 12.7651 2.25 12.0001 2.25C11.2351 2.25 9.76507 2.565 8.16457 2.9865ZM7.78057 1.5375C9.34507 1.1235 11.0056 0.75 12.0001 0.75C12.9931 0.75 14.6551 1.1235 16.2196 1.5375C17.615 1.91216 19.0014 2.31978 20.3776 2.76C21.1696 3.012 21.7666 3.6945 21.8821 4.536C22.7416 10.8315 20.7481 15.4965 18.3316 18.5835C17.3018 19.9083 16.0792 21.0712 14.7046 22.0335C14.2266 22.3683 13.7213 22.6623 13.1941 22.9125C12.7891 23.0985 12.3571 23.25 12.0001 23.25C11.6431 23.25 11.2111 23.0985 10.8061 22.9125C10.2787 22.6626 9.77342 22.3685 9.29557 22.0335C7.92148 21.0711 6.69941 19.9082 5.67007 18.5835C3.25057 15.4965 1.25857 10.8315 2.11807 4.536C2.17554 4.12811 2.34734 3.74483 2.6136 3.43052C2.87985 3.11622 3.22967 2.88374 3.62257 2.76C4.99874 2.31979 6.38513 1.91217 7.78057 1.5375Z" fill="currentColor" />
      <path d="M14.25 9.75C14.25 10.3467 14.0129 10.919 13.591 11.341C13.169 11.7629 12.5967 12 12 12C11.4033 12 10.831 11.7629 10.409 11.341C9.98705 10.919 9.75 10.3467 9.75 9.75C9.75 9.15326 9.98705 8.58097 10.409 8.15901C10.831 7.73705 11.4033 7.5 12 7.5C12.5967 7.5 13.169 7.73705 13.591 8.15901C14.0129 8.58097 14.25 9.15326 14.25 9.75Z" fill="currentColor" />
      <path d="M11.1166 12.051C11.146 11.8762 11.2365 11.7174 11.3718 11.6029C11.5072 11.4884 11.6788 11.4255 11.8561 11.4255H12.0901C12.2665 11.4257 12.4372 11.4881 12.5722 11.6016C12.7071 11.7152 12.7977 11.8727 12.8281 12.0465L13.3486 15.0465C13.3672 15.1542 13.362 15.2647 13.3334 15.3702C13.3048 15.4757 13.2535 15.5737 13.183 15.6573C13.1126 15.7409 13.0247 15.8081 12.9256 15.8542C12.8264 15.9002 12.7184 15.9241 12.6091 15.924H11.3566C11.2477 15.924 11.1402 15.9002 11.0414 15.8544C10.9426 15.8086 10.855 15.7418 10.7847 15.6588C10.7143 15.5757 10.6628 15.4783 10.6339 15.3734C10.605 15.2684 10.5992 15.1584 10.6171 15.051L11.1166 12.051Z" fill="currentColor" />
    </svg>
  );
}

export default ShieldLock;
