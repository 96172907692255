import React from 'react';

function BoxArrowLeft() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.781 17.031C11.8508 16.9613 11.9063 16.8786 11.9441 16.7875C11.9819 16.6963 12.0013 16.5987 12.0013 16.5C12.0013 16.4014 11.9819 16.3037 11.9441 16.2126C11.9063 16.1214 11.8508 16.0387 11.781 15.969L7.8105 12L11.781 8.031C11.9218 7.89017 12.0009 7.69917 12.0009 7.5C12.0009 7.30084 11.9218 7.10983 11.781 6.969C11.6402 6.82817 11.4492 6.74905 11.25 6.74905C11.0508 6.74905 10.8598 6.82817 10.719 6.969L6.219 11.469C6.14915 11.5387 6.09374 11.6214 6.05593 11.7126C6.01812 11.8037 5.99866 11.9014 5.99866 12C5.99866 12.0987 6.01812 12.1963 6.05593 12.2875C6.09374 12.3786 6.14915 12.4613 6.219 12.531L10.719 17.031C10.7887 17.1008 10.8714 17.1563 10.9625 17.1941C11.0537 17.2319 11.1513 17.2513 11.25 17.2513C11.3486 17.2513 11.4463 17.2319 11.5374 17.1941C11.6286 17.1563 11.7113 17.1008 11.781 17.031Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5 12C22.5 11.8011 22.421 11.6103 22.2803 11.4697C22.1397 11.329 21.9489 11.25 21.75 11.25H8.25C8.05109 11.25 7.86032 11.329 7.71967 11.4697C7.57902 11.6103 7.5 11.8011 7.5 12C7.5 12.1989 7.57902 12.3897 7.71967 12.5303C7.86032 12.671 8.05109 12.75 8.25 12.75H21.75C21.9489 12.75 22.1397 12.671 22.2803 12.5303C22.421 12.3897 22.5 12.1989 22.5 12Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.75 21.75C3.15326 21.75 2.58097 21.5129 2.15901 21.091C1.73705 20.669 1.5 20.0967 1.5 19.5V4.5C1.5 3.90326 1.73705 3.33097 2.15901 2.90901C2.58097 2.48705 3.15326 2.25 3.75 2.25H15.75C16.3467 2.25 16.919 2.48705 17.341 2.90901C17.7629 3.33097 18 3.90326 18 4.5V6.75C18 6.94891 17.921 7.13968 17.7803 7.28033C17.6397 7.42098 17.4489 7.5 17.25 7.5C17.0511 7.5 16.8603 7.42098 16.7197 7.28033C16.579 7.13968 16.5 6.94891 16.5 6.75V4.5C16.5 4.30109 16.421 4.11032 16.2803 3.96967C16.1397 3.82902 15.9489 3.75 15.75 3.75H3.75C3.55109 3.75 3.36032 3.82902 3.21967 3.96967C3.07902 4.11032 3 4.30109 3 4.5V19.5C3 19.6989 3.07902 19.8897 3.21967 20.0303C3.36032 20.171 3.55109 20.25 3.75 20.25H15.75C15.9489 20.25 16.1397 20.171 16.2803 20.0303C16.421 19.8897 16.5 19.6989 16.5 19.5V17.25C16.5 17.0511 16.579 16.8603 16.7197 16.7197C16.8603 16.579 17.0511 16.5 17.25 16.5C17.4489 16.5 17.6397 16.579 17.7803 16.7197C17.921 16.8603 18 17.0511 18 17.25V19.5C18 20.0967 17.7629 20.669 17.341 21.091C16.919 21.5129 16.3467 21.75 15.75 21.75H3.75Z" fill="currentColor" />
    </svg>
  );
}

export default BoxArrowLeft;
