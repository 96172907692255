import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { add, format, formatDistanceToNow, parseISO } from 'date-fns';
import { COMPACT_DATE } from 'lib/dateFormats';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import { Button } from 'react-bootstrap';
import ReportStateBadge from '../ReportStateBadge';

function DiscussionBoardReportsListItem({ itemId }) {
  const { id, filters, facilitatorNames, courseTypes, state: reportState, createdAt, requestedByUserId, requestedByUserName, reportId, durationInSeconds } = useSelector((state) => state.superFacilitatorReport.reports.byId[itemId]);
  const reportReady = reportState === 'completed';
  const discussionBoards = filters.discussionBoards?.length > 0 ? filters.discussionBoards.join(', ') : 'all';

  return (
    <tr>
      <td>
        <span className="text-secondary font-monospace text-nowrap">
          {reportId}
        </span>
      </td>

      <td>
        <ReportStateBadge reportState={reportState} />
      </td>

      <td className="text-start">
        <ul className="list-inline">
          <li>
            <small className="text-secondary">Report Type: </small>
            { filters.reportSubType === 'coverage' && (
              <span className="font-monospace ps-2">Coverage</span>
            )}
            { filters.reportSubType === 'facilitation' && (
              <span className="font-monospace ps-2">Facilitation</span>
            )}
            { filters.reportSubType === 'engagement' && (
              <span className="font-monospace ps-2">Engagement</span>
            )}
          </li>

          <li>
            <small className="text-secondary">Discussion Boards:</small>
            <span className="font-monospace ps-2">
              <ul className="list-inline d-inline-block">
                {discussionBoards}
              </ul>
            </span>
          </li>

          <li>
            <small className="text-secondary">Start Date:</small>
            <span className="font-monospace ps-2">{!!filters.startDate && format(parseISO(filters.startDate), COMPACT_DATE)}</span>
          </li>

          <li>
            <small className="text-secondary">End Date:</small>
            <span className="font-monospace ps-2">{!!filters.endDate && format(parseISO(filters.endDate), COMPACT_DATE)}</span>
          </li>

          <li>
            <small className="text-secondary">Facilitators:</small>
            <span className="font-monospace ps-2">{filters.facilitatorIds ? null : 'all'}</span>
            { facilitatorNames && (
              <SimpleToolTip
                id={`facilitators-${reportId}`}
                variant="info"
                placement="top"
                text={(
                  <p className="mb-0 text-start">
                    { facilitatorNames && facilitatorNames.join(', ') }
                  </p>
                )}
              >
                <span className="fas fa-info-circle ps-1" />
              </SimpleToolTip>
            )}
          </li>

          <li>
            <small className="text-secondary">Courses:</small>
            <span className="font-monospace ps-2">{filters.courseTypeIds ? null : 'all'}</span>
            { courseTypes && (
              <SimpleToolTip
                id={`courseTypes-${reportId}`}
                placement="top"
                variant="info"
                text={(<p className="mb-0 text-start">{ courseTypes.join(', ') }</p>)}
              >
                <span className="fas fa-info-circle" />
              </SimpleToolTip>
            )}
          </li>

          <li>
            <small className="text-secondary">Unfacilitated Posts:</small>
            <span className="font-monospace ps-2">{filters.totalUnfacilitatedPosts ?? 'all'}</span>
          </li>
        </ul>
      </td>

      <td className="text-start">
        <small className="text-secondary">
          {formatDistanceToNow(parseISO(createdAt), { addSuffix: true })}
          <br />
          {' by '}
          <a className="link-dark" href={`#${requestedByUserId}`}>
            {requestedByUserName}
          </a>
        </small>
      </td>

      <td className="text-start">
        {reportReady && (
          <small className="text-secondary">
            {formatDistanceToNow(add(new Date(), { seconds: durationInSeconds }))}
          </small>
        )}

        {!reportReady && <span className="text-muted">&hellip;</span>}
      </td>

      <td className="text-end">
        <Button
          variant={reportReady ? 'success' : 'light'}
          size="sm"
          className="text-nowrap"
          disabled={!reportReady}
          href={reportReady ? `/api/super_facilitator/reports/facilitator/${id}` : undefined}
          download={reportReady}
        >
          <i className="fa fa-file-csv me-2" />
          Download as CSV
        </Button>
      </td>
    </tr>
  );
}

DiscussionBoardReportsListItem.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default DiscussionBoardReportsListItem;
