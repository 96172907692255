import React, { Fragment } from 'react';
import RBDropdown from 'react-bootstrap/Dropdown';
import DropdownItem from 'react-bootstrap/DropdownItem';
import DropdownHeader from 'react-bootstrap/DropdownHeader';
import DropdownDivider from 'react-bootstrap/DropdownDivider';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ELEMENTS = {
  divider: DropdownDivider,
  header: DropdownHeader,
};

const getElementProps = (item, eventKey) => {
  if (item.to) {
    return {
      as: Link,
      to: item.to,
      eventKey,
    };
  }

  if (item.href) {
    return {
      href: item.href,
      target: '_blank',
      rel: 'noreferrer',
      eventKey,
    };
  }

  if (item.onClick) {
    return {
      as: 'button',
      onClick: item.onClick,
      eventKey,
    };
  }

  return null;
};

function Dropdown({ items }) {
  return (
    <RBDropdown.Menu>
      {items.map((item, index) => {
        const Element = item.type ? ELEMENTS[item.type] : DropdownItem;
        const elementProps = getElementProps(item, index);

        return (
          <Fragment key={item.label || `divider-${index}`}>
            {item.type === 'divider' && <Element />}
            {item.type !== 'divider' && (
              <>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Element {...elementProps}>
                  {item.label}
                  {item.href && <span className="fas fa-external-link-alt fa-sm text-muted ms-1" />}
                </Element>
              </>
            )}

          </Fragment>
        );
      })}
    </RBDropdown.Menu>
  );
}

Dropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    to: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.oneOf(['header', 'divider']),
  })).isRequired,
};

export default Dropdown;
