export const init = {
  promotion: false,
  skin: false,
  content_css: false,
  images_upload_url: '/api/curriculum/assets',
  images_upload_credentials: true,
  automatic_uploads: true,
  relative_urls: false,
  remove_script_host: false,
  convert_urls: false,
  browser_spellcheck: true,
  contextmenu: 'link useBrowserSpellcheck',
  setup(editor) {
    editor.ui.registry.addMenuItem('useBrowserSpellcheck', {
      text: 'Use `Ctrl+Click` to access spellchecker',
      icon: 'spell-check',
      onAction() {
        editor.notificationManager.open({
          text:
    'To access the spellchecker, hold the Control (Ctrl) key and click on the misspelled word.',
          type: 'info',
          timeout: 5000,
          closeButton: true,
        });
      },
    });
    editor.ui.registry.addContextMenu('useBrowserSpellcheck', {
      update() {
        return editor.selection.isCollapsed() ? ['useBrowserSpellcheck'] : [];
      },
    });
  },
};

export const templates = [
  {
    title: 'Wizard #1',
    content: "<div class='Wizard1'>Place your content here.</div>",
    description: 'Lightbulb with light green background colour.',
  },
  {
    title: 'Wizard #2',
    content: "<div class='Wizard2'>Place your content here.</div>",
    description: 'Horn with light red background colour.',
  },
  {
    title: 'Wizard #3',
    content: "<div class='Wizard3'>Place your content here.</div>",
    description: 'Paperclip with light blue background colour.',
  },
  {
    title: 'Wizard #5',
    content: "<div class='Wizard5'>Place your content here.</div>",
    description: 'Lightbulb with light red background colour.',
  },
  {
    title: 'Text Box #1',
    content: "<div class='TextBox1'>Place your content here.</div>",
    description: 'Light yellow background colour with a black border.',
  },
  {
    title: 'Text Box #2',
    content: "<div class='TextBox2'>Place your content here.</div>",
    description: 'Thick black border with a white background colour.',
  },
  {
    title: 'Text Box #3',
    content: "<div class='TextBox3'>Place your content here.</div>",
    description: 'Light red background colour.',
  },
  {
    title: 'Narratives #1',
    content: "<div class='narratives1'>Place your content here.</div>",
    description: 'Maple leaf with a smile and light green background colour.',
  },
  {
    title: 'Assets: Audio',
    content: '<div align="center">[[ "type": "audio", "src": "https://assets.culturalcompetency.ca/", "transcript": "https://assets.culturalcompetency.ca/", "transcript_link_title": "View a transcript of this audio file" ]]</div>',
    description: 'Embed an audio clip hosted on our assets server.',
  },
  {
    title: 'Assets: Video',
    content: '<div align="center">[[ "type": "video", "src": "https://assets.culturalcompetency.ca/", "transcript": "https://assets.culturalcompetency.ca/", "transcript_link_title": "View a transcript of this video" ]]</div>',
    description: 'Embed a video hosted on our assets server. Must be either .flv or .mp4 and .webm format. Subtitles must be .vtt and only show for .mp4 and .webm. videos.',
  },
  {
    title: 'Assets: Flash Activity',
    content: '<div align="center">[[ "type": "flash", "src": "https://assets.culturalcompetency.ca/", "width": "", "height": "", "transcript": "https://assets.culturalcompetency.ca/", "transcript_link_title": "View a transcript of this activity" ]]</div>',
    description: 'Embed a flash activity hosted on our assets server.',
  },
  {
    title: 'Assets: Image',
    content: '<div align="center">[[ "type": "image", "src": "https://assets.culturalcompetency.ca/", "transcript": "https://assets.culturalcompetency.ca/", "transcript_link_title": "View a text version of this" ]]</div>',
    description: 'Embed an image hosted on our assets server.',
  },
  {
    title: 'Assets: Frame',
    content: '<div align="center">[[ "type": "frame", "src": "https://assets.culturalcompetency.ca/", "transcript": "https://assets.culturalcompetency.ca/", "transcript_link_title": "View a text version of this", "width": "", "height": "" ]]</div>',
    description: 'Embed another page (using an HTML frame) inside this page.',
  },
  {
    title: 'Glossary Term',
    content: '{{glossary entry}}',
    description: 'Matches an entry in the glossary. Can have spaces, e.g.) {{Non-Status Indian}}',
  },
  {
    title: 'Glossary Term (Different Display Text)',
    content: '{{text to display:glossary entry}}',
    description: 'Matches an entry in the glossary but use different display text. e.g.) {{Métis:Metis}}',
  },
  {
    title: 'Image Pop-up',
    content: '[[ "type": "PopupImage", "src": "https://assets.culturalcompetency.ca/", "title": "Image Pop-up" ]]',
    description: 'Displays an image in a pop-up when the user hovers over the text.',
  },
  {
    title: 'Article Link',
    content: '[[ "type": "ArticleLink", "text": "Here is the link text", "key": "the_article_key" ]]',
    description: 'Displays the link for the article.',
  },
];
