import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { SIZES } from 'lib/constants';
import FieldWrapper from './FieldWrapper';
import Select from './Select';
import AsyncSelect from './AsyncSelect';

function SelectField(props) {
  const { name, id, label, className, size, helpText, infoText, hideLabel, required, initialValidation, showTooltip, loadOptions, onFieldChange, onFieldBlur, ...inputProps } = props;
  const selectRef = useRef(null);
  const SelectComponent = loadOptions ? AsyncSelect : Select; // loadOptions is only available for AsyncSelect

  return (
    <FieldWrapper
      id={id}
      name={name}
      label={label}
      className={className}
      size={size}
      required={required}
      hideLabel={hideLabel}
      helpText={helpText}
      infoText={infoText}
      showTooltip={showTooltip}
      initialValidation={initialValidation}
      onFieldChange={onFieldChange}
      onFieldBlur={onFieldBlur}
      innerRef={selectRef}
    >
      <SelectComponent
        innerRef={selectRef}
        name={name}
        required={required}
        size={size}
        loadOptions={loadOptions}
        {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
      />
    </FieldWrapper>
  );
}

SelectField.defaultProps = {
  id: null,
  label: null,
  className: null,
  size: SIZES.md,
  helpText: null,
  infoText: null,
  hideLabel: false,
  required: false,
  initialValidation: null,
  showTooltip: false,
  loadOptions: null,
  onFieldChange: null,
  onFieldBlur: null,
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  helpText: PropTypes.string,
  infoText: PropTypes.string,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
  initialValidation: PropTypes.string,
  showTooltip: PropTypes.bool,
  loadOptions: PropTypes.func,
  onFieldChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
};

export default SelectField;
