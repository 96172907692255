import React from 'react';

function ChevronDown() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.469 6.96906C2.53867 6.89921 2.62143 6.8438 2.71255 6.80599C2.80367 6.76818 2.90135 6.74872 3 6.74872C3.09865 6.74872 3.19633 6.76818 3.28745 6.80599C3.37857 6.8438 3.46133 6.89921 3.531 6.96906L12 15.4396L20.469 6.96906C20.5387 6.89933 20.6215 6.84401 20.7126 6.80627C20.8037 6.76853 20.9014 6.74911 21 6.74911C21.0986 6.74911 21.1963 6.76853 21.2874 6.80627C21.3785 6.84401 21.4613 6.89933 21.531 6.96906C21.6007 7.03879 21.656 7.12157 21.6938 7.21268C21.7315 7.30379 21.7509 7.40144 21.7509 7.50006C21.7509 7.59867 21.7315 7.69633 21.6938 7.78743C21.656 7.87854 21.6007 7.96133 21.531 8.03106L12.531 17.0311C12.4613 17.1009 12.3786 17.1563 12.2874 17.1941C12.1963 17.2319 12.0986 17.2514 12 17.2514C11.9013 17.2514 11.8037 17.2319 11.7125 17.1941C11.6214 17.1563 11.5387 17.1009 11.469 17.0311L2.469 8.03106C2.39915 7.96139 2.34374 7.87863 2.30593 7.78751C2.26812 7.69639 2.24866 7.59871 2.24866 7.50006C2.24866 7.40141 2.26812 7.30373 2.30593 7.21261C2.34374 7.12149 2.39915 7.03873 2.469 6.96906Z" fill="currentColor" />
    </svg>
  );
}

export default ChevronDown;
