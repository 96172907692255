import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useFormikContext } from 'formik';
import { LocalePropType, QuestionPropType } from 'lib/propTypes';
import { getLocaleSuffix } from 'lib/utils';
import Button from 'react-bootstrap/Button';
import SwitchField from 'components/shared/FormFields/SwitchField';
import TextareaField from 'components/shared/FormFields/TextareaField';
import SanyasIcon from 'components/shared/SanyasIcon';

/* eslint-disable react/jsx-props-no-spreading */

function JournalQuestion({ locale, index, question, arrayHelpers }) {
  const { values, setFieldValue } = useFormikContext();

  const handleDuplicate = useCallback(() => {
    arrayHelpers.insert(index + 1, { ...question, id: uuidv4() });
  }, [arrayHelpers, index, question]);

  const handleRemove = useCallback(() => {
    setFieldValue(`questions[${index}]`, { ...values.questions[index], _destroy: true });
  }, [index, values.questions, setFieldValue]);

  if (question._destroy) return null; // eslint-disable-line no-underscore-dangle

  return (
    <div className="journal-question mb-2 border-bottom pb-2">
      <TextareaField name={`questions[${index}].body${getLocaleSuffix(locale)}`} label={`${index + 1}. Question`} />

      <div className="d-flex align-items-center justify-content-end">
        <Button variant="secondary" size="sm" className="btn-plain" title="Duplicate question" onClick={handleDuplicate}>
          <SanyasIcon name="copy" />
        </Button>
        <Button variant="secondary" size="sm" className="btn-plain" title="Delete question" onClick={handleRemove}>
          <SanyasIcon name="trashCan" />
        </Button>
        <span className="text-muted mx-2">|</span>
        <span className="text-small" style={{ width: '8em' }}>
          <SwitchField name={`questions[${index}].answerRequired`} label="Required" inline />
        </span>
      </div>
    </div>
  );
}

JournalQuestion.defaultProps = {
  locale: 'en',
  arrayHelpers: {},
};

JournalQuestion.propTypes = {
  locale: LocalePropType,
  index: PropTypes.number.isRequired,
  question: QuestionPropType.isRequired,
  arrayHelpers: PropTypes.shape({
    insert: PropTypes.func,
    remove: PropTypes.func,
  }),
};

export default JournalQuestion;
