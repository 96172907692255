import React from 'react';
import PropTypes from 'prop-types';
import { FacilitationSubgroupPropType, GroupMembershipPropType } from 'lib/propTypes';
import SanyasIcon from 'components/shared/SanyasIcon';
import JournalDetails from './JournalDetails';

function SubGroupDetails({ subGroup, groupMemberships }) {
  const [pod, facilitators] = (subGroup && subGroup.name.split(':')) || [];

  return (
    <div className="border rounded shadow-sm h-100">
      {/* Ensure consistent header height 76px */}
      <div className="d-flex border-bottom p-3" style={{ minHeight: 76 }}>
        <SanyasIcon name="fileText" className="me-2 text-primary" style={{ width: '2.5em' }} />
        <div className="d-flex flex-column">
          <span className="text-small fw-semibold" style={{ marginTop: -2 }}>
            {pod || 'Unassigned'}
          </span>
          <span>{facilitators}</span>
        </div>
      </div>

      <ul className="list-unstyled">
        {groupMemberships.map((gm) => <JournalDetails key={gm.id} gm={gm} />)}
      </ul>
    </div>
  );
}

SubGroupDetails.defaultProps = {
  groupMemberships: [],
  subGroup: {},
};

SubGroupDetails.propTypes = {
  groupMemberships: PropTypes.arrayOf(GroupMembershipPropType),
  subGroup: FacilitationSubgroupPropType,
};

export default SubGroupDetails;
