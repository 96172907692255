import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import _debounce from 'lodash/debounce';
import useQuery from 'lib/hooks/useQuery';

function Filters() {
  const organizations = useSelector((state) => Object.values(state.organizations.byId));
  const types = [{ id: 'core', name: 'Core' }, { id: 'post-training', name: 'Post-training' }];
  const history = useHistory();
  const searchParams = useQuery();
  const filters = useMemo(() => ({
    query: '',
    organization: '',
    type: '',
    ...searchParams,
  }), [searchParams]);

  const formRef = useRef();

  const handleChange = useCallback(() => {
    formRef.current.requestSubmit();
  }, []);

  const handleDebouncedChange = useMemo(() => _debounce(handleChange, 300), [handleChange]);

  const handleReset = useCallback(() => {
    formRef.current.reset();
    history.push({ search: null });
  }, [history]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    history.push({ search: `?${new URLSearchParams(formProps)}` });
  }, [history]);

  return (
    <form className="row" ref={formRef} onSubmit={handleSubmit}>
      <div className="form-group col-xxl-6">
        <div className="row">
          <div className="form-group col-md-5 mb-1">
            <div className="input-group">
              <span className="input-group-text"><span className="fas fa-search fa-fw" /></span>
              <input name="query" defaultValue={filters.query} type="text" className="form-control" placeholder="Search course by name" aria-label="Search course by name" onChange={handleDebouncedChange} />
            </div>
          </div>
          <div className="form-group col-md-4 mb-1">
            <select name="organization" value={filters.organization} className="form-select" aria-label="Organization" onChange={handleChange}>
              <option value="">Any organization</option>
              {organizations.map((org) => <option key={org.id} value={org.id}>{org.name}</option>)}
            </select>
          </div>
          <div className="form-group col-md-3 mb-1">
            <select name="type" value={filters.type} className="form-select" aria-label="Type" onChange={handleChange}>
              <option value="">Any type</option>
              {types.map((type) => <option key={type.id} value={type.id}>{type.name}</option>)}
            </select>
          </div>
        </div>
      </div>
      <div className="form-group col-12">
        <Button variant="primary" className="btn-plain px-0" onClick={handleReset}>Reset filters</Button>
      </div>
    </form>
  );
}

export default Filters;
