import React from 'react';

function PersonCheck() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_246_4629)">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.5 21C16.5 21 18 21 18 19.5C18 18 16.5 13.5 9 13.5C1.5 13.5 0 18 0 19.5C0 21 1.5 21 1.5 21H16.5ZM1.5075 19.584V19.581V19.584ZM1.533 19.5H16.467C16.474 19.4992 16.481 19.4982 16.488 19.497L16.5 19.494C16.4985 19.125 16.269 18.015 15.252 16.998C14.274 16.02 12.4335 15 9 15C5.565 15 3.726 16.02 2.748 16.998C1.731 18.015 1.503 19.125 1.5 19.494C1.51098 19.4961 1.52198 19.4981 1.533 19.5ZM16.494 19.584V19.581V19.584ZM9 10.5C9.79565 10.5 10.5587 10.1839 11.1213 9.62132C11.6839 9.05871 12 8.29565 12 7.5C12 6.70435 11.6839 5.94129 11.1213 5.37868C10.5587 4.81607 9.79565 4.5 9 4.5C8.20435 4.5 7.44129 4.81607 6.87868 5.37868C6.31607 5.94129 6 6.70435 6 7.5C6 8.29565 6.31607 9.05871 6.87868 9.62132C7.44129 10.1839 8.20435 10.5 9 10.5ZM13.5 7.5C13.5 8.69347 13.0259 9.83807 12.182 10.682C11.3381 11.5259 10.1935 12 9 12C7.80653 12 6.66193 11.5259 5.81802 10.682C4.97411 9.83807 4.5 8.69347 4.5 7.5C4.5 6.30653 4.97411 5.16193 5.81802 4.31802C6.66193 3.47411 7.80653 3 9 3C10.1935 3 11.3381 3.47411 12.182 4.31802C13.0259 5.16193 13.5 6.30653 13.5 7.5ZM23.781 7.719C23.8508 7.78867 23.9063 7.87143 23.9441 7.96255C23.9819 8.05367 24.0013 8.15135 24.0013 8.25C24.0013 8.34865 23.9819 8.44633 23.9441 8.53745C23.9063 8.62857 23.8508 8.71133 23.781 8.781L19.281 13.281C19.2113 13.3508 19.1286 13.4063 19.0374 13.4441C18.9463 13.4819 18.8487 13.5013 18.75 13.5013C18.6513 13.5013 18.5537 13.4819 18.4626 13.4441C18.3714 13.4063 18.2887 13.3508 18.219 13.281L15.969 11.031C15.8282 10.8902 15.7491 10.6992 15.7491 10.5C15.7491 10.3008 15.8282 10.1098 15.969 9.969C16.1098 9.82817 16.3008 9.74905 16.5 9.74905C16.6992 9.74905 16.8902 9.82817 17.031 9.969L18.75 11.6895L22.719 7.719C22.7887 7.64916 22.8714 7.59374 22.9626 7.55593C23.0537 7.51812 23.1513 7.49866 23.25 7.49866C23.3487 7.49866 23.4463 7.51812 23.5374 7.55593C23.6286 7.59374 23.7113 7.64916 23.781 7.719Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_4629">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PersonCheck;
