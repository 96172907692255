import React, { useRef } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_QUESTION, QUESTION_TYPES, REDUX_STATUS } from 'lib/constants';
import { LocalePropType } from 'lib/propTypes';
import Button from 'react-bootstrap/Button';
import RadioCheckField from 'components/shared/FormFields/RadioCheckField';
import ErrorMessage from 'components/shared/ErrorMessage';
import { useSelector } from 'react-redux';
import JournalQuestion from './JournalQuestion';
import QuestionSkeleton from './QuestionSkeleton';

function JournalTask({ locale }) {
  const { values } = useFormikContext();
  const { status, error } = useSelector((state) => state.courses.questions);
  const helperRef = useRef();
  const { id: taskId, questions } = values;

  const handleAddQuestion = () => {
    const newQuestion = { ...DEFAULT_QUESTION, id: uuidv4(), taskId, type: QUESTION_TYPES.JOURNAL };
    helperRef.current.push(newQuestion);
  };

  return (
    <div className="mt-4">
      <h3>Journal Builder</h3>
      <FieldArray name="questions">
        {(arrayHelpers) => {
          helperRef.current = arrayHelpers;
          return (
            <>
              {status === REDUX_STATUS.PENDING && <QuestionSkeleton />}
              {status === REDUX_STATUS.SUCCESS && (
                <>
                  {questions.map((question, index) => (
                    <JournalQuestion key={question.id} index={index} question={question} locale={locale} arrayHelpers={arrayHelpers} />
                  ))}
                </>
              )}
              {status === REDUX_STATUS.ERROR && <ErrorMessage error={error} />}

              <div className="mb-4">
                <Button variant="primary" size="sm" className={`${questions.length > 0 ? 'btn-plain' : ''}`} title="Add question" disabled={status !== REDUX_STATUS.SUCCESS} onClick={handleAddQuestion}>
                  + Add journal question
                </Button>
              </div>
            </>
          );
        }}
      </FieldArray>

      <RadioCheckField
        type="checkbox"
        hideLabel
        name="silenceJournalNotification"
        options={[{ value: true, label: 'Don’t trigger journal notification' }]}
        helpText="If this option is checked, the facilitators will not receive a ‘red dot’ notification when the participant responds."
        disabled={status !== REDUX_STATUS.SUCCESS}
      />
    </div>
  );
}

JournalTask.defaultProps = {
  locale: 'en',
};

JournalTask.propTypes = {
  locale: LocalePropType,
};

export default JournalTask;
