import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import add from 'date-fns/add';
import format from 'date-fns/format';
import startOfToday from 'date-fns/startOfToday';
import { REDUX_STATUS } from 'lib/constants';
import { COMPACT_DATE, D_FLATPICKR, DT_CLOSE_TIME } from 'lib/dateFormats';
import usePrevious from 'lib/hooks/usePrevious';
import { REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH } from 'lib/routerPaths';
import { titleCase } from 'lib/utils';
import { CREATE_PLATFORM_CLOSURE, LOAD_VALIDATION_GROUPS, RESET_EVENT_STATUS, UPDATE_PLATFORM_CLOSURE } from 'store/platformClosure/actions';
import Flatpickr from 'react-flatpickr';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const FormSchema = Yup.object().shape({
  message: Yup.string()
    .required('Required'),
  closePlatformFrom: Yup.date()
    .required('Required'),
  closePlatformUntil: Yup.date()
    .required('Required')
    .min(new Date(), 'Date must be later than today.')
    .test(
      'overlap_test',
      'Reopening Date must be after Closing Date',
      (value, context) => {
        const { closePlatformFrom } = context.parent;
        return value > closePlatformFrom;
      },
    ),
});

function PlatformClosureForm({ initialValues }) {
  const { error, status } = useSelector((state) => state.platformClosure.events);
  const { error: eventError, status: eventStatus } = useSelector((state) => state.platformClosure.event);
  const { groups, groupIds, status: validationStatus, error: validationError } = useSelector((state) => state.platformClosure.validation);
  const dispatch = useDispatch();
  const history = useHistory();
  const prevEventStatus = usePrevious(eventStatus);

  const errorGroupIds = useMemo(() => groupIds.filter((id) => groups[id].errors?.length), [groupIds, groups]);
  const warningGroupIds = useMemo(() => groupIds.filter((id) => groups[id].warnings?.length && !groups[id].errors?.length), [groupIds, groups]);
  const warnings = useMemo(() => new Set(warningGroupIds.map((id) => groups[id].warnings).flat()), [warningGroupIds, groups]);

  const isValidatingGroups = validationStatus === REDUX_STATUS.PENDING;
  const hasErrorGroups = validationStatus === REDUX_STATUS.SUCCESS && errorGroupIds.length;
  const isCurrentEvent = initialValues?.closePlatformFrom && initialValues?.closePlatformFrom < startOfToday();

  const handleSubmit = useCallback((values, actions) => {
    if (initialValues?.id) {
      dispatch(UPDATE_PLATFORM_CLOSURE.request(values, { formikActions: actions }));
    } else {
      dispatch(CREATE_PLATFORM_CLOSURE.request(values, { formikActions: actions }));
    }
  }, [initialValues?.id, dispatch]);

  const handleAlertClose = useCallback(() => {
    dispatch(RESET_EVENT_STATUS.action());
  }, [dispatch]);

  const validateClosureDates = useCallback((closePlatformFrom, closePlatformUntil) => {
    if (closePlatformFrom && closePlatformUntil) {
      dispatch(LOAD_VALIDATION_GROUPS.request({
        closePlatformFrom: format(closePlatformFrom, COMPACT_DATE),
        closePlatformUntil: format(closePlatformUntil, COMPACT_DATE),
      }));
    }
    return null;
  }, [dispatch]);

  useEffect(() => {
    if (prevEventStatus === REDUX_STATUS.PENDING && eventStatus === REDUX_STATUS.SUCCESS) {
      history.push(REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH);
    }
  }, [prevEventStatus, eventStatus, history]);

  useEffect(() => {
    if (initialValues?.id) {
      validateClosureDates(initialValues.closePlatformFrom, initialValues.closePlatformUntil);
    }
  }, [initialValues?.id, initialValues?.closePlatformFrom, initialValues?.closePlatformUntil, validateClosureDates]);

  return (
    <div className="row mb-4">
      <div className="col-md-9">
        {status === REDUX_STATUS.ERROR && <Alert variant="danger" onClose={handleAlertClose} dismissible>{`${error.message} ${error.details?.message}`}</Alert>}
        {eventStatus === REDUX_STATUS.ERROR && <Alert variant="danger" onClose={handleAlertClose} dismissible>{`${eventError.message} ${eventError.details?.message}`}</Alert>}

        <Formik
          onSubmit={handleSubmit}
          validationSchema={FormSchema}
          initialValues={initialValues}
        >
          {({ values, setFieldValue, isSubmitting }) => {
            const handleClosePlatformFromChange = (data) => {
              setFieldValue('closePlatformFrom', data[0]);
              validateClosureDates(data[0], values.closePlatformUntil);
            };

            const handleClosePlatformUntilChange = (data) => {
              setFieldValue('closePlatformUntil', data[0]);
              validateClosureDates(values.closePlatformFrom, data[0]);
            };

            return (
              <Form>
                {errorGroupIds.length > 0 && (
                  <Alert variant="danger">
                    <p><strong>The following groups have opening or closing dates within the closure period:</strong></p>
                    <ul>
                      {errorGroupIds.map((id) => (
                        <li key={id}>
                          <Alert.Link as={Link} to={`/registrar/groups/${id}/settings`} target="_blank" rel="noopener noreferrer">{groups[id].name}</Alert.Link>
                          {` (${groups[id].errors.map((e) => titleCase(e)).join(', ')})`}
                        </li>
                      ))}
                    </ul>
                  </Alert>
                )}

                {warningGroupIds.length > 0 && (
                  <Alert variant="warning">
                    <p><strong>The following groups have significant dates within the closure period:</strong></p>
                    <ul>
                      {warningGroupIds.map((id) => (
                        <li key={id}>
                          <Alert.Link as={Link} to={`/registrar/groups/${id}/settings`} target="_blank" rel="noopener noreferrer">{groups[id].name}</Alert.Link>
                          {` (${groups[id].warnings.map((e) => titleCase(e)).join(', ')})`}
                        </li>
                      ))}
                    </ul>

                    <p>This doesn&rsquo;t affect the closure, but might affect the groups.</p>

                    {warnings.has('send_welcome_packs_at') && (
                      <p>
                        {`Welcome packs will be sent ${format(add(values?.closePlatformFrom, { hours: 14, minutes: 30 }), DT_CLOSE_TIME)}.`}
                        <br />
                        The course might not be assigned and email templates might not have been copied to the group when Welcome packs are sent.
                      </p>
                    )}
                  </Alert>
                )}

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="closePlatformFrom" className="form-label">Closing Date</label>
                      <Flatpickr
                        value={values.closePlatformFrom}
                        name="closePlatformFrom"
                        placeholder="Pick start date"
                        className="form-control"
                        id="closePlatformFrom"
                        options={{ dateFormat: 'm/d/Y', minDate: isCurrentEvent ? format(initialValues?.closePlatformFrom, D_FLATPICKR) : 'today' }}
                        onChange={handleClosePlatformFromChange}
                        style={isCurrentEvent ? { cursor: 'not-allowed' } : { backgroundColor: 'white' }}
                        disabled={isCurrentEvent}
                      />
                      <ErrorMessage name="closePlatformFrom" component="div" className="invalid-feedback d-block" />
                      <span className="form-text">The platform will close at 11&nbsp;pm Pacific Time on the date above.</span>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="closePlatformUntil" className="form-label">Reopening Date</label>
                      <Flatpickr
                        value={values.closePlatformUntil}
                        name="closePlatformUntil"
                        placeholder="Pick end date"
                        className="form-control"
                        id="closePlatformUntil"
                        options={{ dateFormat: 'm/d/Y', minDate: 'today' }}
                        onChange={handleClosePlatformUntilChange}
                        style={{ backgroundColor: 'white' }}
                      />
                      <ErrorMessage name="closePlatformUntil" component="div" className="invalid-feedback d-block" />
                      <span className="form-text">The platform will reopen at 7&nbsp;am Atlantic Time on the date above.</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label htmlFor="message" className="form-label">Message</label>

                    <Field name="message" as="textarea" className="form-control" />
                    <ErrorMessage name="message" component="div" className="invalid-feedback d-block" />
                    <span className="form-text">Set a message that the participants will see if they try to access the platform.</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col mt-3">
                    <button disabled={isSubmitting || isValidatingGroups || hasErrorGroups} type="submit" className="btn btn-outline-primary">
                      {isSubmitting && <Spinner animation="border" size="sm" className="me-1" />}
                      {isSubmitting ? 'Submitting' : 'Schedule Closure'}
                    </button>
                    <Button variant="link" as={Link} to={REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH} className="btn-plain">Cancel</Button>
                  </div>
                </div>

                {validationError && (
                  <ErrorMessage error={validationError} className="mt-1" />
                )}
              </Form>
            );
          } }

        </Formik>
      </div>
    </div>
  );
}

PlatformClosureForm.defaultProps = {
  initialValues: {
    id: 0,
    message: '',
    closePlatformFrom: null,
    closePlatformUntil: null,
  },
};

PlatformClosureForm.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    message: PropTypes.string,
    closePlatformFrom: PropTypes.instanceOf(Date),
    closePlatformUntil: PropTypes.instanceOf(Date),
  }),
};

export default PlatformClosureForm;
