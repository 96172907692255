import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType, StylePropType } from 'lib/propTypes';

const navSizes = {
  sm: 2,
  md: 3,
  lg: 4,
};

function BaseTemplate({ children, id, className, style, navComponent, navSize }) {
  return (
    <div className={`container-fluid ${className ?? ''}`} id={id} style={style}>
      <div className="row">
        <div className={`col-lg-${navSizes[navSize]} m-0 p-0 d-none d-lg-block`}>
          {navComponent}
        </div>

        {navComponent && (
          <div className={`col-lg-${12 - navSizes[navSize]}`}>
            {children}
          </div>
        )}

        {!navComponent && children}
      </div>
    </div>
  );
}

BaseTemplate.defaultProps = {
  id: null,
  className: null,
  style: null,
  navSize: 'sm',
};

BaseTemplate.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: ComponentPropType.isRequired,
  navComponent: ComponentPropType.isRequired,
  style: StylePropType,
  navSize: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default BaseTemplate;
