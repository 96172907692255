import React, { useCallback, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useFormikContext } from 'formik';
import { LocalePropType, QuestionPropType, RefPropType } from 'lib/propTypes';
import { getLocaleSuffix } from 'lib/utils';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import { Draggable } from '@hello-pangea/dnd';
import Button from 'react-bootstrap/Button';
import SanyasIcon from 'components/shared/SanyasIcon';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import TextareaField from 'components/shared/FormFields/TextareaField';
import SwitchField from 'components/shared/FormFields/SwitchField';

/* eslint-disable react/jsx-props-no-spreading */

function EssayQuestion({ index, arrayHelpers, question, locale, setNewQuestionRef, setNewQuestionId, isNew, innerRef }) {
  const { currentTask } = useCourseShowContext();
  const { values, setFieldValue } = useFormikContext();
  const nodeRef = createRef(null);

  useEffect(() => {
    if (isNew) {
      setNewQuestionRef(nodeRef.current);
    }
  }, [isNew, nodeRef, setNewQuestionRef]);

  const handleDuplicate = useCallback(() => {
    const newId = uuidv4();
    arrayHelpers.insert(index + 1, { ...question, id: newId });
    setNewQuestionId(newId);
  }, [arrayHelpers, index, question, setNewQuestionId]);

  const handleRemove = useCallback(() => {
    setFieldValue(`questions[${index}]`, { ...values.questions[index], _destroy: true });
  }, [index, values.questions, setFieldValue]);

  if (question._destroy) return null; // eslint-disable-line no-underscore-dangle

  return (
    <Draggable key={question.id} draggableId={`${question.id}`} index={index} isDragDisabled={currentTask?.isLocked}>
      {(provided, snapshot) => (
        <div id={`${question.id}`} ref={provided.innerRef} {...provided.draggableProps} className="question">
          <div ref={innerRef}>
            <div
              ref={nodeRef}
              className={`cursor-grab p-1 outline-visible text-center ${snapshot.isDragging ? 'visible' : ''}`}
              {...provided.dragHandleProps}
            >
              {!currentTask?.isLocked && (
                <>
                  <SanyasIcon name="gripHorizontal" />
                  <span className="sr-only">Drag to reorder</span>
                </>
              )}
            </div>
            <div className="position-relative">
              <div className="position-absolute top-0 end-0 d-flex align-items-center" style={{ marginTop: '-2px' }}>
                <span className="font-weight-semibold" style={{ fontSize: '0.875em' }}>
                  Essay question
                </span>

                {!currentTask?.isLocked && (
                  <>
                    <SimpleToolTip text="Duplicate" placement="top">
                      <Button variant="secondary" size="sm" className="icon-background btn-plain" onClick={handleDuplicate}>
                        <SanyasIcon name="copy" />
                      </Button>
                    </SimpleToolTip>

                    <SimpleToolTip text="Delete" placement="top">
                      <Button variant="secondary" size="sm" className="icon-background btn-plain" onClick={handleRemove}>
                        <SanyasIcon name="trashCan" />
                      </Button>
                    </SimpleToolTip>
                  </>
                )}
              </div>

              <TextareaField
                className="question-number"
                name={`questions[${index}].body${getLocaleSuffix(locale)}`}
                label="Question"
                placeholder="Question"
              />
              <div className="drag-hide">
                <div className="d-flex align-items-center justify-content-end">
                  <span className="text-small" style={{ width: '8em' }}>
                    <SwitchField name={`questions[${index}].answerRequired`} label="Required" inline />
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

EssayQuestion.defaultProps = {
  arrayHelpers: {},
  locale: 'en',
};

EssayQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  arrayHelpers: PropTypes.shape({
    insert: PropTypes.func,
    remove: PropTypes.func,
    replace: PropTypes.func,
  }),
  question: QuestionPropType.isRequired,
  locale: LocalePropType,
  setNewQuestionId: PropTypes.func.isRequired,
  setNewQuestionRef: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  innerRef: RefPropType.isRequired,
};

export default EssayQuestion;
