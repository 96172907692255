import React from 'react';
import { LEGACY_APP_DOMAIN } from 'config';
import { useSelector } from 'react-redux';
import '../../curriculum/FindAndReplace/styles.scss';

function SearchResults() {
  const { allIds, byId, searchPerformed } = useSelector((state) => state.courses.findAndReplace);

  if (!searchPerformed) return null;

  return (
    <div className="row">
      <div className="col-md-8 my-4 mx-auto">
        {(allIds?.length === 0) && (
          <>
            <p className="mb-0">0 tasks found</p>
            <hr className="mt-1" />
          </>
        )}

        {(allIds?.length > 0) && (
          <>
            <div className="d-flex align-bottom col-md-6">
              <p className="align-items-end mb-0">
                {`${allIds.length} tasks found`}
              </p>
            </div>
            <hr className="mt-2" />
            {allIds.map((taskId, index) => (
              <div key={taskId} className="row mt-3">
                <h3>
                  <a
                    href={`${LEGACY_APP_DOMAIN}/tasks/${taskId}`}
                    className="task-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`${index + 1}. ${byId[taskId].name}`}
                  </a>
                </h3>
                <div className="col-12">
                  {/* eslint-disable-next-line react/no-danger */}
                  <div className="highlighted mt-1" style={{ maxHeight: '20em' }} dangerouslySetInnerHTML={{ __html: byId[taskId].highlighted }} />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default SearchResults;
