import React from 'react';
import PropTypes from 'prop-types';
import { StandardOptionsPropType } from 'lib/propTypes';
import FieldWrapper from './FieldWrapper';
import RadioCheckInput from './RadioCheckInput';

function RadioCheckField(props) {
  const { id, name, label, type, disabled, helpText, infoText, options, hideLabel, block, onFieldChange, className, onFieldBlur } = props;

  return (
    <FieldWrapper
      id={id}
      name={name}
      label={label}
      type={type}
      helpText={helpText}
      infoText={infoText}
      hideLabel={hideLabel}
      onFieldChange={onFieldChange}
      onFieldBlur={onFieldBlur}
      className={className}
    >
      <RadioCheckInput
        name={name}
        type={type}
        disabled={disabled}
        options={options}
        block={block}
      />
    </FieldWrapper>
  );
}

RadioCheckField.defaultProps = {
  id: null,
  label: null,
  disabled: false,
  helpText: null,
  infoText: null,
  options: [],
  hideLabel: false,
  block: false,
  className: null,
  onFieldChange: null,
  onFieldBlur: null,
};

RadioCheckField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf([
    'checkbox', 'radio',
  ]).isRequired,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  infoText: PropTypes.string,
  options: StandardOptionsPropType,
  hideLabel: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  onFieldChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
};

export default RadioCheckField;
