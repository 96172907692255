import React from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import FlashNotification from './FlashNotification';

function Notifications() {
  const { list } = useSelector((state) => state.flashNotifications);

  return createPortal(
    <>
      {list.map((notification) => (
        <FlashNotification key={notification.id} notification={notification} />
      ))}
    </>,
    document.getElementById('toasts'),
  );
}

export default Notifications;
