import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { fetchGet } from 'lib/apiHelpers';
import { REDUX_STATUS } from 'lib/constants';
import useGroupsChannel from 'lib/hooks/useGroupsChannel';
import useSessionStorage from 'lib/hooks/useSessionStorage';
import useUnload from 'lib/hooks/useUnload';
import { buildRoutePath } from 'lib/routerHelpers';
import { REGISTRAR_GROUP_SHOW_PATH } from 'lib/routerPaths';
import { START_BULK_IMPORT } from 'store/bulkImport/actions';
import { GET_GROUP, UPDATE_GROUP_SHOW_DATA } from 'store/groupShow/actions';
import Button from 'react-bootstrap/Button';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import FileUpload from 'components/shared/FileUpload';
import Dropzone from 'components/shared/Dropzone';
import Spinner from 'components/shared/Spinner';
import Header from 'components/groups/BulkImport/Header';
import DefaultOrganizationSelect from 'components/groups/ParticipantsBulkImportDefaultOrganization';
import Verify from 'components/groups/ParticipantsBulkImportVerify';
import Summary from 'components/groups/ParticipantsBulkImportReview/Summary';
import ConfirmLeave from 'components/groups/ParticipantsBulkImportReview/ConfirmLeave';
import Processing from 'components/groups/ParticipantsBulkImportReview/Processing';
import { SUPPORTED_FILES, MAX_FILE_SIZE } from './constants';
import './styles.scss';

export const BulkImportGetFormData = (groupId) => fetchGet(`/api/registrar/groups/${groupId}/bulk_imports/demographics.json`);

const FormSchema = Yup.object({
  organization: Yup.string(),
  file: Yup.mixed().required('File is required')
    .test('fileType', 'Unsupported File Format', (value) => value && SUPPORTED_FILES.includes(value.type))
    .test('fileSize', 'File Size is too large', (value) => value && value.size <= MAX_FILE_SIZE),
});

function GroupParticipantsBulkImport() {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [showResetModal, setShowResetModal] = useState(false);
  const [uploadedUsers, setUploadedUsers] = useState({ users: [] });
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const { group, status: groupStatus } = useSelector((state) => state.groupShow);
  const [isProcessing, setProcessing] = useState(group?.isImporting);
  const organizationsStatus = useSelector((state) => state.organizations.status);
  const organizations = Object.values(useSelector((state) => state.organizations.byId));
  const healthAuthorities = Object.values(useSelector((state) => state.healthAuthorities.byId));
  const [importData, setImportData] = useSessionStorage(`bulk-import-${id}`, null);
  const pageLoading = organizationsStatus === REDUX_STATUS.PENDING || groupStatus === REDUX_STATUS.PENDING;

  useGroupsChannel();

  useUnload((e) => {
    e.preventDefault();
    e.returnValue = 'Are you sure you want to leave this page?';
  });

  useEffect(() => {
    if (!isProcessing && group?.isImporting) {
      setImportData({ status: 'active' });
      setProcessing(group?.isImporting);
    }
  }, [isProcessing, group?.isImporting, setImportData]);

  useEffect(() => {
    if (!group || group.id !== Number(id)) {
      dispatch(GET_GROUP.request(id));
    }
  }, [group, id, dispatch]);

  useEffect(() => {
    (async () => {
      const resp = await BulkImportGetFormData(id);
      setFormData(resp?.data);
    })();
  }, [id]);

  const handleResetModalClose = useCallback(() => setShowResetModal(false), []);
  const handleResetModalShow = useCallback(() => setShowResetModal(true), []);
  const handleReset = useCallback(() => {
    setProcessing(false);
    setUploadedUsers({ users: [] });
    setActiveStep(0);
  }, []);

  const handleProcessing = () => {
    dispatch(UPDATE_GROUP_SHOW_DATA.action({ id: Number(id), group: { isImporting: true } }));
    dispatch(START_BULK_IMPORT.action({ id }));
    setImportData({ status: 'active' });
    setProcessing(true);
    setActiveStep('IMPORT');
  };

  const handleClose = useCallback(() => {
    if (importData?.status === 'complete') {
      setImportData(null);
    }
  }, [importData, setImportData]);

  return (
    <ErrorBoundary>
      <Header
        activeIndex={activeStep}
        onClose={handleClose}
        returnTo={buildRoutePath(REGISTRAR_GROUP_SHOW_PATH, { id })}
      />

      {pageLoading && <Spinner message="Loading group..." /> }

      {(isProcessing || importData) && (
        <Processing
          onReset={handleReset}
          importData={importData}
          setImportData={setImportData}
          setActiveStep={setActiveStep}
        />
      )}

      {(!isProcessing && !pageLoading && !importData) && (
      <>
        {uploadedUsers.users.length === 0 && (
        <Formik
          initialValues={{
            file: null,
            organization: '',
          }}
          validationSchema={FormSchema}
          onReset={handleReset}
          onSubmit={() => {
            // Form submission is handled in the Verify component
            setActiveStep('REVIEW');
          }}
        >
          {({ dirty, resetForm }) => (
            <Form>
              {dirty && <ConfirmLeave />}

              <div className="col-md-7 my-4 mx-4 mx-md-auto">
                <p className="lead">{`Bulk import participants for ${group.name}`}</p>

                <p>
                  You can upload a CSV or Excel file to add a list of participants to your group. You can use our
                  <a href="/files/bulk-import-template.xls" target="_blank" className="text-decoration-none px-1">template here.</a>
                  Only the columns named in the template file will be used for data import; please don’t modify the column names.
                </p>

                <Field name="organization" component={DefaultOrganizationSelect} label="1. Select an organization" organizations={organizations} />

                <div className="my-4">
                  <label htmlFor="file" className="form-label">
                    2. Upload a CSV or Excel file *
                  </label>
                  <Dropzone name="file">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <i className="fa fa-lg fa-upload pb-3" />
                      <div>
                        {'Drag and drop or '}
                        <FileUpload name="file" showField={false} type="link" />
                        {' to upload.'}
                      </div>
                      <div>CSV or Excel</div>
                    </div>
                  </Dropzone>
                </div>

                <div className="text-end">
                  <Button variant="primary" className="btn-plain me-2" onClick={handleResetModalShow} disabled={!dirty}>Reset</Button>

                  <Verify group={group} organizations={organizations} formData={formData} setActiveStep={setActiveStep} setUploadedUsers={setUploadedUsers} />
                </div>
              </div>

              <DefaultModal
                isOpen={showResetModal}
                onClose={handleResetModalClose}
                header="You have unsaved changes"
                footerComponent={(
                  <>
                    <Button variant="outline-secondary" onClick={handleResetModalClose}>No</Button>
                    <Button
                      variant="outline-danger"
                      onClick={() => {
                        resetForm();
                        handleResetModalClose();
                      }}
                    >
                      Yes
                    </Button>
                  </>
                      )}
              >
                Are you sure you want to reset the import form?
              </DefaultModal>
            </Form>
          )}
        </Formik>
        )}

        {uploadedUsers.users.length > 0 && (
        <ErrorBoundary>
          <Summary
            groupId={group.id}
            formData={formData}
            organizations={organizations}
            healthAuthorities={healthAuthorities}
            onReset={handleReset}
            users={uploadedUsers}
            handleProcessing={handleProcessing}
          />
        </ErrorBoundary>
        )}
      </>
      )}
    </ErrorBoundary>
  );
}

export default GroupParticipantsBulkImport;
