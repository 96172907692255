/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { ComponentPropType } from 'lib/propTypes';

const FieldContext = React.createContext();

export default function FieldProvider({ children, ...props }) {
  return <FieldContext.Provider {...props}>{children}</FieldContext.Provider>;
}

FieldProvider.propTypes = {
  children: ComponentPropType.isRequired,
};

export const useFieldContext = () => {
  const field = useContext(FieldContext);
  return field;
};
