import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function CompletedGroupsList() {
  const { closedGroups } = useSelector((state) => state.courses.completionReport);

  const completedGroups = useMemo(() => (closedGroups ? closedGroups.map((group) => (
    <li key={group.id} className="list-group-item pb-2">
      <Link to={`/registrar/groups/${group.id}`}>{group.name}</Link>
      {' '}
      -
      {' '}
      {group.totalParticipants}
      {' '}
      participants
    </li>
  )) : []), [closedGroups]);

  return (
    <div>
      <h4>
        {closedGroups && closedGroups.length}
        {' '}
        Groups completed this course
      </h4>
      { completedGroups }
    </div>

  );
}

export default CompletedGroupsList;
