import _omit from 'lodash/omit';
import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import { deleteSingleObject } from 'lib/reducerHelpers';
import {
  ADD_QUESTION,
  LOAD_COURSES,
  LOAD_COURSE,
  LOAD_COURSE_TYPE_COURSES,
  LOAD_COURSE_TYPE_ARCHIVED_COURSES,
  LOAD_COURSE_GROUPS,
  RESET_COURSES,
  ARCHIVE_COURSE,
  DUPLICATE_COURSE,
  DUPLICATE_COURSE_UPDATE_STATUS,
  RESET_COURSE_STATUS,
  CREATE_TASK,
  SAVE_TASK,
  RESET_TASK,
  CREATE_SECTION,
  SAVE_SECTION,
  DELETE_TASK,
  LOAD_FORM_DATA,
  DELETE_SECTION,
  REMOVE_SECTION,
  RESET_SECTION,
  REORDER_COURSE_SECTION,
  REORDER_COURSE_SECTION_TASK,
  REORDER_QUESTIONS,
  REMOVE_TASK,
  LOAD_COURSE_SECTIONS,
  CREATE_COURSE,
  UPDATE_COURSE,
  MERGE_COURSE,
  PUBLISH_COURSE,
  UPDATE_COURSE_DATA,
  REMOVE_COURSE,
  COPY_TASK_FORM_DATA,
  COPY_TASK,
  COPY_TASK_UPDATE_STATUS,
  DESTROY_RESOURCE,
  RESET_RESOURCE,
  CREATE_URL_TASK_RESOURCE,
  CREATE_FILE_TASK_RESOURCE,
  UPDATE_COMPLETION_THRESHOLD,
  LOAD_TASK_QUESTIONS,
  RESET_TASK_QUESTIONS,
  LOAD_TASK_PROACTIVE,
  RESET_TASK_PROACTIVE,
  LOAD_COURSE_PROACTIVES,
  LOAD_COURSE_PHRASEBOOK_ENTRIES,
  CREATE_PHRASEBOOK_ENTRY,
  SAVE_PHRASEBOOK_ENTRY,
  DELETE_PHRASEBOOK_ENTRY,
  RESET_PHRASEBOOK_ENTRY,
  LOAD_COURSE_GLOSSARY_ENTRIES,
  LOAD_COMPLETION_REPORT,
  CREATE_GLOSSARY_ENTRY,
  SAVE_GLOSSARY_ENTRY,
  DELETE_GLOSSARY_ENTRY,
  RESET_GLOSSARY_ENTRY,
  FIND_AND_REPLACE_SEARCH,
  FIND_AND_REPLACE_UPDATE,
  FIND_AND_REPLACE_UPDATE_ALL,
  LOAD_COURSE_ARTICLES,
  AVERAGE_TIME_BEFORE_COMPLETION,
  FIND_AND_REPLACE_RESET,
  RESET_COURSE_ARTICLE,
  CREATE_COURSE_ARTICLE,
  SAVE_COURSE_ARTICLE,
  DELETE_COURSE_ARTICLE,
  REMOVE_COURSE_ARTICLE,
  REORDER_COURSE_ARTICLE,
  COPY_ARTICLE_FORM_DATA,
  COPY_ARTICLE,
} from './actions';

const initialState = {
  status: REDUX_STATUS.IDLE,
  data: {},
  error: undefined,
  success: undefined,
  courseIds: [],
  course: {
    id: undefined,
    data: {},
    status: REDUX_STATUS.IDLE,
    error: undefined,
    success: undefined,
  },
  resource: {
    id: undefined,
    data: {},
    status: REDUX_STATUS.IDLE,
    error: undefined,
    success: undefined,
  },
  sections: {},
  section: {
    id: undefined,
    data: {},
    status: REDUX_STATUS.IDLE,
    error: undefined,
    success: undefined,
  },
  tasks: {},
  task: {
    id: undefined,
    data: {},
    status: REDUX_STATUS.IDLE,
    error: undefined,
    success: undefined,
  },
  formData: {},
  questions: {
    data: [],
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
  proactiveTemplates: {
    data: [],
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
  proactiveTemplate: {
    id: undefined,
    data: {},
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
  phrasebookEntries: {
    allIds: [],
    byId: {},
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
  phrasebookEntry: {
    id: undefined,
    data: {},
    status: REDUX_STATUS.IDLE,
    error: undefined,
    success: undefined,
  },
  glossaryEntries: {
    allIds: [],
    byId: {},
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
  glossaryEntry: {
    id: undefined,
    data: {},
    status: REDUX_STATUS.IDLE,
    error: undefined,
    success: undefined,
  },
  findAndReplace: {
    courseId: undefined,
    allIds: [],
    byId: {},
    searchPerformed: false,
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
  articles: {
    allIds: [],
    byId: {},
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
  article: {
    id: undefined,
    data: {},
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
  completionReport: {
    dropoutReports: [],
    totals: {},
    closedGroups: [],
    courseId: undefined,
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COURSES.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
      };
    case LOAD_COURSES.SUCCESS: {
      if (action.meta.cached) return { ...state, status: REDUX_STATUS.SUCCESS, error: undefined };

      return {
        ...state,
        data: action.payload,
        status: REDUX_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case LOAD_COURSES.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case LOAD_COURSE.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
      };
    case LOAD_COURSE.SUCCESS: {
      if (action.meta.cached) return { ...state, status: REDUX_STATUS.SUCCESS, error: undefined };
      return {
        ...state,
        data: action.payload.data,
        sections: action.payload.sections,
        tasks: action.payload.tasks,
        status: REDUX_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case LOAD_COURSE.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    // TODO not currently updating state
    // case LOAD_COURSE_GROUPS.REQUEST:
    //   return {
    //     ...state,
    //   };
    case LOAD_COURSE_GROUPS.SUCCESS: {
      if (action.meta.cached) return state; // { ...state, status: REDUX_STATUS.SUCCESS, error: undefined }

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.courseId]: {
            ...state.data[action.payload.courseId],
            groups: action.payload.groups,
          },
        },
        status: REDUX_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case LOAD_COURSE_GROUPS.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case LOAD_COURSE_SECTIONS.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
        error: undefined,
      };
    case LOAD_COURSE_SECTIONS.SUCCESS: {
      if (action.meta.cached) return { ...state, status: REDUX_STATUS.SUCCESS };

      return {
        ...state,
        sections: action.payload.sections,
        tasks: action.payload.tasks,
        status: REDUX_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case LOAD_COURSE_SECTIONS.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case CREATE_COURSE.REQUEST:
      return {
        ...state,
        course: {
          ...state.course,
          status: REDUX_STATUS.PENDING,
        },
      };
    case CREATE_COURSE.SUCCESS:
      return {
        ...state,
        course: {
          ...state.course,
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.CREATED,
        },
      };
    case CREATE_COURSE.ERROR:
      return {
        ...state,
        course: {
          ...state.course,
          error: action.payload,
          status: REDUX_STATUS.ERROR,
        },
      };

    case UPDATE_COURSE.REQUEST:
      return {
        ...state,
        course: {
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case UPDATE_COURSE.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
        course: {
          ...state.course,
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.SAVED,
        },
      };
    case UPDATE_COURSE.ERROR:
      return {
        ...state,
        course: {
          ...state.course,
          error: action.payload,
          status: REDUX_STATUS.ERROR,
        },
      };

    case PUBLISH_COURSE.REQUEST:
      return {
        ...state,
      };
    case PUBLISH_COURSE.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
        courseIds: [action.payload.id, ...state.courseIds],
        error: undefined,
        status: REDUX_STATUS.SUCCESS,
      };
    case PUBLISH_COURSE.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case UPDATE_COURSE_DATA.SYNC:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: { ...state.data[action.payload.id], ...action.payload.data },
        },
      };

    case REMOVE_COURSE.SYNC:
      return {
        ...state,
        courseIds: state.courseIds.filter((e) => e !== action.payload.id),
      };

    case MERGE_COURSE.SYNC:
      return {
        ...state,
        data: {
          ...state.data,
          [state.course.id]: { ...state.course.data },
        },
        courseIds: [...state.courseIds, state.course.id],
        course: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };

    case ARCHIVE_COURSE.REQUEST:
      return {
        ...state,
        course: {
          ...state.course,
          id: action.payload,
          data: {},
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case ARCHIVE_COURSE.SUCCESS: {
      return {
        ...state,
        course: {
          ...state.course,
          id: undefined,
          status: REDUX_STATUS.IDLE,
        },
        data: {
          ...state.data,
          [action.payload.data.id]: { ...state.data[action.payload.data.id], ...action.payload.data },
        },
      };
    }
    case ARCHIVE_COURSE.ERROR:
      return {
        ...state,
        course: {
          ...state.course,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case DUPLICATE_COURSE.REQUEST:
      return {
        ...state,
        course: {
          ...state.course,
          status: REDUX_STATUS.PENDING,
        },
      };
    case DUPLICATE_COURSE.SUCCESS:
      return {
        ...state,
        course: {
          ...state.course,
          data: action.payload.data,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.DUPLICATED,
        },
      };

    case DUPLICATE_COURSE.ERROR:
      return {
        ...state,
        course: {
          ...state.course,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case DUPLICATE_COURSE_UPDATE_STATUS.SYNC:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.data.id]: action.payload.data,
        },
        courseIds: [action.payload.data.id, ...state.courseIds],
      };

    case RESET_COURSE_STATUS.SYNC:
      return {
        ...state,
        status: REDUX_STATUS.IDLE,
        error: undefined,
        success: undefined,
        course: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };

    case LOAD_COURSE_TYPE_COURSES.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
      };
    case LOAD_COURSE_TYPE_COURSES.SUCCESS: {
      if (action.meta.cached) return { ...state, status: REDUX_STATUS.SUCCESS, error: undefined };
      return {
        ...state,
        data: action.payload.data,
        courseIds: action.payload.courseIds,
        sections: action.payload.sections,
        tasks: action.payload.tasks,
        status: REDUX_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case LOAD_COURSE_TYPE_COURSES.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case LOAD_COURSE_TYPE_ARCHIVED_COURSES.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
      };
    case LOAD_COURSE_TYPE_ARCHIVED_COURSES.SUCCESS: {
      if (action.meta.cached) return { ...state, status: REDUX_STATUS.SUCCESS, error: undefined };
      return {
        ...state,
        data: action.payload.data,
        courseIds: action.payload.courseIds,
        sections: action.payload.sections,
        tasks: action.payload.tasks,
        status: REDUX_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case LOAD_COURSE_TYPE_ARCHIVED_COURSES.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case RESET_COURSES.SYNC:
      return initialState;

    case CREATE_TASK.REQUEST:
      return {
        ...state,
        task: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case CREATE_TASK.SUCCESS:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.sectionId]: {
            ...state.sections[action.payload.sectionId],
            taskIds: [...state.sections[action.payload.sectionId].taskIds, action.payload.id],
          },
        },
        tasks: {
          ...state.tasks,
          [action.payload.id]: action.payload,
        },
        task: {
          ...state.task,
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.CREATED,
        },
      };
    case CREATE_TASK.ERROR:
      return {
        ...state,
        task: {
          ...state.task,
          error: action.payload,
          status: REDUX_STATUS.ERROR,
        },
      };

    case SAVE_TASK.REQUEST:
      return {
        ...state,
        task: {
          data: {},
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case SAVE_TASK.SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.id]: action.payload,
        },
        task: {
          ...state.task,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.SAVED,
        },
        questions: {
          ...state.questions,
          data: action.payload.questions,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    case SAVE_TASK.ERROR:
      return {
        ...state,
        task: {
          ...state.task,
          error: action.payload,
          status: REDUX_STATUS.ERROR,
        },
      };

    case RESET_TASK.SYNC: {
      if (state.task.status === REDUX_STATUS.PENDING) return state;

      return {
        ...state,
        task: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };
    }

    case CREATE_SECTION.REQUEST:
      return {
        ...state,
        section: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case CREATE_SECTION.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.courseId]: {
            ...state.data[action.payload.courseId],
            sectionIds: [...state.data[action.payload.courseId].sectionIds, action.payload.id],
          },
        },
        sections: {
          ...state.sections,
          [action.payload.id]: action.payload,
        },
        section: {
          ...state.section,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.CREATED,
        },
      };
    case CREATE_SECTION.ERROR:
      return {
        ...state,
        section: {
          ...state.section,
          error: action.payload,
          status: REDUX_STATUS.ERROR,
        },
      };

    case SAVE_SECTION.REQUEST:
      return {
        ...state,
        section: {
          data: {},
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case SAVE_SECTION.SUCCESS:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.id]: action.payload,
        },
        section: {
          ...state.section,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.SAVED,
        },
      };
    case SAVE_SECTION.ERROR:
      return {
        ...state,
        section: {
          ...state.section,
          error: action.payload,
          status: REDUX_STATUS.ERROR,
        },
      };

    case DELETE_SECTION.REQUEST:
      return {
        ...state,
        section: {
          id: action.payload,
          data: {},
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case DELETE_SECTION.SUCCESS:
      return {
        ...state,
        section: {
          ...state.section,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.DELETED,
        },
      };
    case DELETE_SECTION.ERROR:
      return {
        ...state,
        section: {
          ...state.section,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case REMOVE_SECTION.SYNC: {
      const sectionId = action.payload;
      const section = state.sections[sectionId];
      const newSections = _omit(state.sections, [sectionId]);
      const newSectionIds = Object.values(newSections).map((s) => s.id);

      return {
        ...state,
        data: {
          ...state.data,
          [section.courseId]: {
            ...state.data[section.courseId],
            sectionIds: newSectionIds,
          },
        },
        sections: newSections,
        section: {
          id: undefined,
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };
    }

    case RESET_SECTION.SYNC: {
      if (!state.section.status === REDUX_STATUS.IDLE) return state;

      return {
        ...state,
        section: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };
    }

    case DELETE_TASK.REQUEST:
      return {
        ...state,
        task: {
          ...state.task,
          id: action.payload,
          data: {},
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case DELETE_TASK.SUCCESS:
      return {
        ...state,
        task: {
          ...state.task,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.DELETED,
        },
      };
    case DELETE_TASK.ERROR:
      return {
        ...state,
        task: {
          ...state.task,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case REMOVE_TASK.SYNC: {
      const { sectionId } = state.tasks[action.payload];
      const index = state.sections[sectionId].taskIds.indexOf(parseInt(action.payload, 10));
      const taskIds = [...state.sections[sectionId].taskIds];
      taskIds.splice(index, 1);

      return {
        ...state,
        sections: {
          ...state.sections,
          [sectionId]: {
            ...state.sections[sectionId],
            taskIds,
          },
        },
        tasks: deleteSingleObject(state.tasks, action.payload),
        task: {
          id: undefined,
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };
    }

    case LOAD_FORM_DATA.REQUEST:
      return {
        ...state,
      };
    case LOAD_FORM_DATA.SUCCESS:
      return {
        ...state,
        formData: action.payload,
      };
    case LOAD_FORM_DATA.ERROR:
      return {
        ...state,
      };

    case REORDER_COURSE_SECTION.SYNC: {
      const { courseId, sectionId, fromIndex, toIndex } = action.payload;
      const sectionIds = [...state.data[courseId].sectionIds];
      sectionIds.splice(fromIndex, 1);
      sectionIds.splice(toIndex, 0, Number(sectionId));

      return {
        ...state,
        data: {
          ...state.data,
          [courseId]: {
            ...state.data[courseId],
            sectionIds,
          },
        },
      };
    }

    case REORDER_COURSE_SECTION_TASK.SYNC: {
      const { sectionId, taskId, fromIndex, toIndex } = action.payload;
      const taskIds = [...state.sections[sectionId].taskIds];
      taskIds.splice(fromIndex, 1);
      taskIds.splice(toIndex, 0, Number(taskId));

      return {
        ...state,
        sections: {
          ...state.sections,
          [sectionId]: {
            ...state.sections[sectionId],
            taskIds,
          },
        },
      };
    }

    case REORDER_QUESTIONS.SYNC: {
      const { fromIndex, toIndex } = action.payload;
      const questions = [...state.questions.data];
      const oldQuestion = questions.splice(fromIndex, 1);
      questions.splice(toIndex, 0, oldQuestion[0]);

      return {
        ...state,
        questions: {
          ...state.questions,
          data: questions,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }

    case ADD_QUESTION.SYNC: {
      const { question } = action.payload;

      return {
        ...state,
        questions: {
          ...state.questions,
          data: [...state.questions.data, question],
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }

    case COPY_TASK_FORM_DATA.REQUEST:
      return {
        ...state,
      };
    case COPY_TASK_FORM_DATA.SUCCESS:
      return {
        ...state,
        copyCoursesFormData: action.payload.data.courses,
        copyModulesFormData: action.payload.data.sectionsByCourseId,
      };
    case COPY_TASK_FORM_DATA.ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case COPY_TASK.REQUEST:
      return {
        ...state,
        task: {
          ...state.task,
          status: REDUX_STATUS.PENDING,
        },
      };
    case COPY_TASK.SUCCESS: {
      const sections = { ...state.sections };
      const tasks = { ...state.tasks };

      // If the section exists in state, add the task to the section.
      if (Object.keys(sections).includes(String(action.payload.sectionId))) {
        sections[action.payload.sectionId] = {
          ...state.sections[action.payload.sectionId],
          taskIds: [...state.sections[action.payload.sectionId].taskIds, action.payload.id],
        };

        tasks[action.payload.id] = action.payload;
      }

      return {
        ...state,
        sections,
        tasks,
        task: {
          ...state.task,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.COPIED,
          data: action.payload,
        },
      };
    }
    case COPY_TASK.ERROR:
      return {
        ...state,
        task: {
          ...state.task,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case COPY_TASK_UPDATE_STATUS.SYNC:
      return {
        ...state,
        task: {
          ...state.task,
          status: action.payload,
          success: undefined,
          error: undefined,
        },
      };

    case CREATE_URL_TASK_RESOURCE.REQUEST:
      return {
        ...state,
        resource: {
          ...state.resource,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case CREATE_URL_TASK_RESOURCE.SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.taskId]: {
            ...state.tasks[action.payload.taskId],
            mediaResources: [
              ...state.tasks[action.payload.taskId].mediaResources,
              action.payload.data,
            ],
          },
        },
        resource: {
          ...state.resource,
          id: action.payload.data.id,
          data: action.payload.data,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.CREATED,
        },
      };
    case CREATE_URL_TASK_RESOURCE.ERROR:
      return {
        ...state,
        resource: {
          ...state.resource,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case CREATE_FILE_TASK_RESOURCE.REQUEST:
      return {
        ...state,
        resource: {
          ...state.resource,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case CREATE_FILE_TASK_RESOURCE.SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.taskId]: {
            ...state.tasks[action.payload.taskId],
            mediaResources: [
              ...state.tasks[action.payload.taskId].mediaResources,
              action.payload.data,
            ],
          },
        },
        resource: {
          ...state.resource,
          id: action.payload.data.id,
          data: action.payload.data,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.CREATED,
        },
      };
    case CREATE_FILE_TASK_RESOURCE.ERROR:
      return {
        ...state,
        resource: {
          ...state.resource,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case UPDATE_COMPLETION_THRESHOLD.REQUEST:
      return {
        ...state,
        task: {
          data: {},
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };

    case UPDATE_COMPLETION_THRESHOLD.SUCCESS:
      return {
        ...state,
        task: {
          data: {},
          status: REDUX_STATUS.SUCCESS,
          error: undefined,
          success: undefined,
        },
        tasks: {
          ...state.tasks,
          [action.payload.id]: {
            ...state.tasks[action.payload.id],
            completionThresholdInSeconds: action.payload.completionThresholdInSeconds,
          },
        },
      };
    case UPDATE_COMPLETION_THRESHOLD.ERROR:
      return {
        task: {
          ...state.task,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case AVERAGE_TIME_BEFORE_COMPLETION.SUCCESS:
      if (action.meta.cached) return state;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.id]: {
            ...state.tasks[action.payload.id],
            averageTimeBeforeCompletion: action.payload.averageTimeBeforeCompletion,
          },
        },
      };

    case DESTROY_RESOURCE.REQUEST:
      return {
        ...state,
        resource: {
          ...state.resource,
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case DESTROY_RESOURCE.SUCCESS: {
      const mediaResources = [...state.tasks[action.payload.taskId].mediaResources];
      const index = mediaResources.findIndex((res) => res.id === Number(action.payload.id));
      if (index < 0) return state; // resource not found in state

      mediaResources.splice(index, 1);

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.taskId]: {
            ...state.tasks[action.payload.taskId],
            mediaResources,
          },
        },
        resource: {
          ...state.resource,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.DELETED,
        },
      };
    }
    case DESTROY_RESOURCE.ERROR:
      return {
        ...state,
        resource: {
          ...state.resource,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_RESOURCE.SYNC:
      return {
        ...state,
        resource: {
          ...state.resource,
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };

    case LOAD_TASK_QUESTIONS.REQUEST:
      return {
        ...state,
        questions: {
          data: [],
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case LOAD_TASK_QUESTIONS.SUCCESS:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    case LOAD_TASK_QUESTIONS.ERROR:
      return {
        ...state,
        questions: {
          ...state.questions,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_TASK_QUESTIONS.SYNC:
      return {
        ...state,
        questions: {
          data: [],
          status: REDUX_STATUS.IDLE,
          error: undefined,
        },
      };

    case LOAD_TASK_PROACTIVE.REQUEST:
      return {
        ...state,
        proactiveTemplate: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case LOAD_TASK_PROACTIVE.SUCCESS:
      return {
        ...state,
        proactiveTemplate: {
          ...state.proactiveTemplate,
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    case LOAD_TASK_PROACTIVE.ERROR:
      return {
        ...state,
        proactiveTemplate: {
          ...state.proactiveTemplate,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_TASK_PROACTIVE.SYNC:
      return {
        ...state,
        proactiveTemplate: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
        },
      };

    case LOAD_COURSE_PROACTIVES.REQUEST:
      return {
        ...state,
        proactiveTemplates: {
          data: [],
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case LOAD_COURSE_PROACTIVES.SUCCESS:
      return {
        ...state,
        proactiveTemplates: {
          ...state.proactiveTemplate,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    case LOAD_COURSE_PROACTIVES.ERROR:
      return {
        ...state,
        proactiveTemplates: {
          ...state.proactiveTemplate,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case LOAD_COURSE_PHRASEBOOK_ENTRIES.REQUEST:
      return {
        ...state,
        phrasebookEntries: {
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case LOAD_COURSE_PHRASEBOOK_ENTRIES.SUCCESS: {
      if (action.meta.cached) {
        return {
          ...state,
          phrasebookEntries: {
            ...state.phrasebookEntries,
            status: REDUX_STATUS.SUCCESS,
          },
        };
      }

      return {
        ...state,
        phrasebookEntries: {
          ...state.phrasebookEntries,
          allIds: action.payload.phrasebookEntryIds,
          byId: action.payload.phrasebookEntries,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case LOAD_COURSE_PHRASEBOOK_ENTRIES.ERROR:
      return {
        ...state,
        phrasebookEntries: {
          ...state.phrasebookEntries,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case CREATE_PHRASEBOOK_ENTRY.REQUEST:
      return {
        ...state,
        phrasebookEntry: {
          id: undefined,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case CREATE_PHRASEBOOK_ENTRY.SUCCESS:
      return {
        ...state,
        phrasebookEntries: {
          ...state.phrasebookEntries,
          allIds: [action.payload.phrasebookEntry.id, ...state.phrasebookEntries.allIds],
          byId: {
            ...state.phrasebookEntries.byId,
            [action.payload.phrasebookEntry.id]: action.payload.phrasebookEntry,
          },
        },
        phrasebookEntry: {
          id: action.payload.phrasebookEntry.id,
          data: action.payload.phrasebookEntry,
          status: REDUX_STATUS.SUCCESS,
          error: undefined,
          success: REDUX_SUCCESS.CREATED,
        },
      };
    case CREATE_PHRASEBOOK_ENTRY.ERROR:
      return {
        ...state,
        phrasebookEntry: {
          ...state.phrasebookEntry,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case SAVE_PHRASEBOOK_ENTRY.REQUEST:
      return {
        ...state,
        phrasebookEntry: {
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case SAVE_PHRASEBOOK_ENTRY.SUCCESS:
      return {
        ...state,
        phrasebookEntries: {
          ...state.phrasebookEntries,
          byId: {
            ...state.phrasebookEntries.byId,
            [action.payload.phrasebookEntry.id]: action.payload.phrasebookEntry,
          },
        },
        phrasebookEntry: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };
    case SAVE_PHRASEBOOK_ENTRY.ERROR:
      return {
        ...state,
        phrasebookEntry: {
          ...state.phrasebookEntry,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case DELETE_PHRASEBOOK_ENTRY.REQUEST:
      return {
        ...state,
        phrasebookEntry: {
          ...state.phrasebookEntry,
          id: action.payload,
          data: {},
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case DELETE_PHRASEBOOK_ENTRY.SUCCESS:
      return {
        ...state,
        phrasebookEntries: {
          ...state.phrasebookEntries,
          allIds: state.phrasebookEntries.allIds.filter((e) => e !== Number(action.payload.id)),
          byId: _omit(state.phrasebookEntries.byId, [action.payload.id]),
        },
        phrasebookEntry: {
          ...state.phrasebookEntry,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.DELETED,
        },
      };
    case DELETE_PHRASEBOOK_ENTRY.ERROR:
      return {
        ...state,
        phrasebookEntry: {
          ...state.phrasebookEntry,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_PHRASEBOOK_ENTRY.SYNC:
      return {
        ...state,
        phrasebookEntry: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };

    case LOAD_COURSE_GLOSSARY_ENTRIES.REQUEST:
      return {
        ...state,
        glossaryEntries: {
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case LOAD_COURSE_GLOSSARY_ENTRIES.SUCCESS: {
      if (action.meta.cached) {
        return {
          ...state,
          glossaryEntries: {
            ...state.glossaryEntries,
            status: REDUX_STATUS.SUCCESS,
          },
        };
      }

      return {
        ...state,
        glossaryEntries: {
          ...state.glossaryEntries,
          allIds: action.payload.glossaryEntryIds,
          byId: action.payload.glossaryEntries,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case LOAD_COURSE_GLOSSARY_ENTRIES.ERROR:
      return {
        ...state,
        glossaryEntries: {
          ...state.glossaryEntries,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case LOAD_COMPLETION_REPORT.REQUEST:
      return {
        ...state,
        completionReport: {
          ...state.completionReport,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case LOAD_COMPLETION_REPORT.SUCCESS: {
      if (action.meta.cached) {
        return {
          ...state,
          completionReport: {
            ...state.completionReport,
            status: REDUX_STATUS.SUCCESS,
          },
        };
      }

      return {
        ...state,
        completionReport: {
          ...state.completionReport,
          courseId: action.payload.courseId,
          dropoutReports: action.payload.dropoutReports,
          totals: action.payload.totals,
          closedGroups: action.payload.closedGroups,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case LOAD_COMPLETION_REPORT.ERROR:
      return {
        ...state,
        completionReport: {
          ...state.completionReport,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case CREATE_GLOSSARY_ENTRY.REQUEST:
      return {
        ...state,
        glossaryEntry: {
          id: undefined,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case CREATE_GLOSSARY_ENTRY.SUCCESS:
      return {
        ...state,
        glossaryEntries: {
          ...state.glossaryEntries,
          allIds: [action.payload.glossaryEntry.id, ...state.glossaryEntries.allIds],
          byId: {
            ...state.glossaryEntries.byId,
            [action.payload.glossaryEntry.id]: action.payload.glossaryEntry,
          },
        },
        glossaryEntry: {
          id: action.payload.glossaryEntry.id,
          data: action.payload.glossaryEntry,
          status: REDUX_STATUS.SUCCESS,
          error: undefined,
          success: REDUX_SUCCESS.CREATED,
        },
      };
    case CREATE_GLOSSARY_ENTRY.ERROR:
      return {
        ...state,
        glossaryEntry: {
          ...state.glossaryEntry,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case SAVE_GLOSSARY_ENTRY.REQUEST:
      return {
        ...state,
        glossaryEntry: {
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case SAVE_GLOSSARY_ENTRY.SUCCESS:
      return {
        ...state,
        glossaryEntries: {
          ...state.glossaryEntries,
          byId: {
            ...state.glossaryEntries.byId,
            [action.payload.glossaryEntry.id]: action.payload.glossaryEntry,
          },
        },
        glossaryEntry: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };
    case SAVE_GLOSSARY_ENTRY.ERROR:
      return {
        ...state,
        glossaryEntry: {
          ...state.glossaryEntry,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case DELETE_GLOSSARY_ENTRY.REQUEST:
      return {
        ...state,
        glossaryEntry: {
          ...state.glossaryEntry,
          id: action.payload,
          data: {},
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case DELETE_GLOSSARY_ENTRY.SUCCESS:
      return {
        ...state,
        glossaryEntries: {
          ...state.glossaryEntries,
          allIds: state.glossaryEntries.allIds.filter((e) => e !== Number(action.payload.id)),
          byId: _omit(state.glossaryEntries.byId, [action.payload.id]),
        },
        glossaryEntry: {
          ...state.glossaryEntry,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.DELETED,
        },
      };
    case DELETE_GLOSSARY_ENTRY.ERROR:
      return {
        ...state,
        glossaryEntry: {
          ...state.glossaryEntry,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_GLOSSARY_ENTRY.SYNC:
      return {
        ...state,
        glossaryEntry: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };

    case FIND_AND_REPLACE_SEARCH.REQUEST:
      return {
        ...state,
        findAndReplace: {
          courseId: action.payload.courseId,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case FIND_AND_REPLACE_SEARCH.SUCCESS: {
      return {
        ...state,
        findAndReplace: {
          ...state.findAndReplace,
          allIds: action.payload.taskIds,
          byId: action.payload.results,
          status: REDUX_STATUS.SUCCESS,
          searchPerformed: true,
        },
      };
    }
    case FIND_AND_REPLACE_SEARCH.ERROR:
      return {
        ...state,
        findAndReplace: {
          ...state.findAndReplace,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case FIND_AND_REPLACE_UPDATE.REQUEST:
      return {
        ...state,
        findAndReplace: {
          ...state.findAndReplace,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case FIND_AND_REPLACE_UPDATE.SUCCESS: {
      const updatedTasks = { ...state.findAndReplace.byId };
      action.payload.data.taskIds.forEach((taskId) => { updatedTasks[taskId].updated = true; });

      return {
        ...state,
        findAndReplace: {
          ...state.findAndReplace,
          byId: updatedTasks,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case FIND_AND_REPLACE_UPDATE.ERROR:
      return {
        ...state,
        findAndReplace: {
          ...state.findAndReplace,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };
    case FIND_AND_REPLACE_UPDATE_ALL.REQUEST:
      return {
        ...state,
        findAndReplace: {
          ...state.findAndReplace,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case FIND_AND_REPLACE_UPDATE_ALL.SUCCESS: {
      const updatedTasks = { ...state.findAndReplace.byId };
      action.payload.data.taskIds.forEach((taskId) => { updatedTasks[taskId].updated = true; });
      return {
        ...state,
        findAndReplace: {
          ...state.findAndReplace,
          byId: updatedTasks,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case FIND_AND_REPLACE_UPDATE_ALL.ERROR:
      return {
        ...state,
        findAndReplace: {
          ...state.findAndReplace,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };
    case FIND_AND_REPLACE_RESET.SYNC:
      return {
        ...state,
        findAndReplace: {
          courseId: undefined,
          allIds: [],
          byId: {},
          searchPerformed: false,
          status: REDUX_STATUS.IDLE,
          error: undefined,
        },
      };

    case LOAD_COURSE_ARTICLES.REQUEST:
      return {
        ...state,
        articles: {
          ...state.articles,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case LOAD_COURSE_ARTICLES.SUCCESS: {
      if (action.meta.cached) {
        return {
          ...state,
          articles: {
            ...state.articles,
            status: REDUX_STATUS.SUCCESS,
          },
        };
      }

      return {
        ...state,
        articles: {
          ...state.articles,
          allIds: action.payload.articleIds,
          byId: action.payload.articles,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case LOAD_COURSE_ARTICLES.ERROR:
      return {
        ...state,
        articles: {
          ...state.articles,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case CREATE_COURSE_ARTICLE.REQUEST:
      return {
        ...state,
        article: {
          id: undefined,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case CREATE_COURSE_ARTICLE.SUCCESS: {
      const allIds = [...state.articles.allIds];
      allIds.splice(action.payload.position - 1, 0, action.payload.id);

      return {
        ...state,
        articles: {
          ...state.articles,
          allIds,
          byId: {
            ...state.articles.byId,
            [action.payload.id]: action.payload,
          },
        },
        article: {
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
          error: undefined,
          success: REDUX_SUCCESS.CREATED,
        },
      };
    }
    case CREATE_COURSE_ARTICLE.ERROR:
      return {
        ...state,
        article: {
          ...state.article,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case SAVE_COURSE_ARTICLE.REQUEST:
      return {
        ...state,
        article: {
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case SAVE_COURSE_ARTICLE.SUCCESS:
      return {
        ...state,
        articles: {
          ...state.articles,
          byId: {
            ...state.articles.byId,
            [action.payload.id]: action.payload,
          },
        },
        article: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };
    case SAVE_COURSE_ARTICLE.ERROR:
      return {
        ...state,
        article: {
          ...state.article,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case DELETE_COURSE_ARTICLE.REQUEST:
      return {
        ...state,
        article: {
          ...state.article,
          id: action.payload,
          data: {},
          status: REDUX_STATUS.PENDING,
          error: undefined,
          success: undefined,
        },
      };
    case DELETE_COURSE_ARTICLE.SUCCESS:
      return {
        ...state,
        article: {
          ...state.article,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.DELETED,
        },
      };
    case DELETE_COURSE_ARTICLE.ERROR:
      return {
        ...state,
        article: {
          ...state.article,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case REMOVE_COURSE_ARTICLE.SYNC:
      return {
        ...state,
        articles: {
          ...state.articles,
          allIds: state.articles.allIds.filter((e) => e !== Number(action.payload)),
          byId: _omit(state.articles.byId, [action.payload]),
        },
      };

    case REORDER_COURSE_ARTICLE.SYNC: {
      const { articleId, fromIndex, toIndex } = action.payload;
      const articleIds = [...state.articles.allIds];
      articleIds.splice(fromIndex, 1);
      articleIds.splice(toIndex, 0, Number(articleId));

      return {
        ...state,
        articles: {
          ...state.articles,
          allIds: articleIds,
        },
      };
    }

    case RESET_COURSE_ARTICLE.SYNC: {
      if (!state.article.status === REDUX_STATUS.IDLE) return state;

      return {
        ...state,
        article: {
          id: undefined,
          data: {},
          status: REDUX_STATUS.IDLE,
          error: undefined,
          success: undefined,
        },
      };
    }

    case COPY_ARTICLE.REQUEST:
      return {
        ...state,
        article: {
          ...state.article,
          status: REDUX_STATUS.PENDING,
        },
      };
    case COPY_ARTICLE.SUCCESS:
      return {
        ...state,
        articles: {
          ...state.articles,
          allIds: [...state.articles.allIds, action.payload.id],
          byId: {
            ...state.articles.byId,
            [action.payload.id]: action.payload,
          },
        },
        article: {
          ...state.article,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.COPIED,
          id: action.payload.id,
          data: action.payload,
        },
      };
    case COPY_ARTICLE.ERROR:
      return {
        ...state,
        article: {
          ...state.article,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    // case COPY_ARTICLE_FORM_DATA.REQUEST:
    //   return state;
    case COPY_ARTICLE_FORM_DATA.SUCCESS: {
      if (action.meta.cached) return state;

      return {
        ...state,
        formData: {
          ...state.formData,
          copyArticleFormData: action.payload.data,
        },
      };
    }
    case COPY_ARTICLE_FORM_DATA.ERROR:
      return {
        ...state,
        formData: {
          ...state.formData,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
