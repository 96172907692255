import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { ComponentPropType } from 'lib/propTypes';
import { selectCourseById } from 'store/courses/selectors';
import { useWorkspace } from 'lib/hooks/useWorkspace';

const CourseShowContext = React.createContext();

function CourseShowProvider({ children }) {
  const { courseId } = useParams();
  const { id: workspaceId } = useWorkspace();
  const { id, name, courseTypeId, displayName, master, isLocked, sectionIds } = useSelector((state) => selectCourseById(state, courseId)) || {};
  const { allIds: articleIds } = useSelector((state) => state.courses.articles) || {};
  const [currentSection, setCurrentSection] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);
  const [currentArticle, setCurrentArticle] = useState(null);
  const [activeKey, setActiveKey] = useState(null);

  const isLockedForWorkspace = workspaceId === 'curriculum' ? isLocked : true;

  const value = useMemo(() => ({
    course: { id, name, displayName, courseTypeId, master, isLocked: isLockedForWorkspace, sectionIds, articleIds },
    currentSection,
    currentTask,
    currentArticle,
    activeKey,
    setCurrentSection,
    setCurrentTask,
    setCurrentArticle,
    setActiveKey,
  }), [id, name, displayName, courseTypeId, master, isLockedForWorkspace, sectionIds, articleIds, currentSection, currentTask, currentArticle, activeKey]);

  return (
    <CourseShowContext.Provider value={value}>
      {children}
    </CourseShowContext.Provider>
  );
}

CourseShowProvider.propTypes = {
  children: ComponentPropType.isRequired,
};

export default CourseShowProvider;

export const useCourseShowContext = () => {
  const { course, currentSection, currentTask, currentArticle, activeKey, setCurrentSection, setCurrentTask, setCurrentArticle, setActiveKey } = useContext(CourseShowContext);
  return { course, currentSection, currentTask, currentArticle, activeKey, setCurrentSection, setCurrentTask, setCurrentArticle, setActiveKey };
};
