import React from 'react';
import PropTypes from 'prop-types';

function VoucherLogItem({ name, invokedBy, createdAt, args }) {
  return (
    <li className="list-group-item d-flex justify-content-between align-items-start">
      <div className="ms-2 me-auto">
        <div className="fw-semibold">
          {name}
          {' '}
          {args.markTrainingPaymentAs}
        </div>
        {invokedBy ? `by ${invokedBy}` : ''}
      </div>
      <span>{createdAt}</span>
    </li>
  );
}

VoucherLogItem.defaultProps = {
  name: null,
  createdAt: null,
  invokedBy: null,
  args: {},
};

VoucherLogItem.propTypes = {
  args: PropTypes.shape({
    markTrainingPaymentAs: PropTypes.string,
  }),
  name: PropTypes.string,
  invokedBy: PropTypes.string,
  createdAt: PropTypes.string,
};

export default VoucherLogItem;
