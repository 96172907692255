import React from 'react';

function ExclamationTriangle() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_246_3970)">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.473 2.34902C13.3246 2.09037 13.1105 1.87547 12.8524 1.72603C12.5942 1.57659 12.3013 1.49789 12.003 1.49789C11.7048 1.49789 11.4118 1.57659 11.1537 1.72603C10.8956 1.87547 10.6815 2.09037 10.533 2.34902L0.247541 19.8495C-0.437959 21.0165 0.384041 22.5 1.71754 22.5H22.287C23.6205 22.5 24.444 21.015 23.757 19.8495L13.473 2.34902ZM12 7.50002C11.8105 7.50013 11.623 7.53995 11.4497 7.61691C11.2765 7.69388 11.1212 7.80628 10.9941 7.94689C10.8669 8.08749 10.7706 8.25318 10.7113 8.43327C10.6521 8.61336 10.6312 8.80387 10.65 8.99252L11.175 14.253C11.1927 14.4597 11.2872 14.6522 11.44 14.7925C11.5928 14.9328 11.7926 15.0106 12 15.0106C12.2074 15.0106 12.4073 14.9328 12.5601 14.7925C12.7128 14.6522 12.8074 14.4597 12.825 14.253L13.35 8.99252C13.3689 8.80387 13.348 8.61336 13.2888 8.43327C13.2295 8.25318 13.1332 8.08749 13.006 7.94689C12.8788 7.80628 12.7236 7.69388 12.5504 7.61691C12.3771 7.53995 12.1896 7.50013 12 7.50002ZM12.003 16.5C11.6052 16.5 11.2237 16.6581 10.9424 16.9394C10.6611 17.2207 10.503 17.6022 10.503 18C10.503 18.3978 10.6611 18.7794 10.9424 19.0607C11.2237 19.342 11.6052 19.5 12.003 19.5C12.4009 19.5 12.7824 19.342 13.0637 19.0607C13.345 18.7794 13.503 18.3978 13.503 18C13.503 17.6022 13.345 17.2207 13.0637 16.9394C12.7824 16.6581 12.4009 16.5 12.003 16.5Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_3970">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ExclamationTriangle;
