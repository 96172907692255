import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _flatten from 'lodash/flatten';
import _uniq from 'lodash/uniq';
import _sortBy from 'lodash/sortBy';
import _last from 'lodash/last';
import parseISO from 'date-fns/parseISO';
import isSameDay from 'date-fns/isSameDay';
import isSameYear from 'date-fns/isSameYear';
import format from 'date-fns/format';
import { DT_MESSAGE, DT_MESSAGE_YEAR, SHORT_TIME } from 'lib/dateFormats';
import { trim } from 'lib/utils';
import { ConversationPropType } from 'lib/propTypes';

/**
 * The conversations are sorted by latest message first
 */
function sortConversations(conversations) {
  return _sortBy(conversations, ((convo) => _last(_sortBy(convo.messages, ['updatedBy'])).updatedAt
  )).reverse();
}

function Messages(props) {
  return (
    <table className="table table-striped">
      <colgroup>
        <col span="1" style={{ width: '5%' }} />
        <col span="1" style={{ width: '22%' }} />
        <col span="1" style={{ width: '53%' }} />
        <col span="1" style={{ width: '20%' }} />
      </colgroup>
      <tbody>
        {
        sortConversations(props.conversations).map((convo) => {
          if (!convo.isAnnouncement) {
            return (
              <OneToOneConversation
                key={convo.id}
                conversation={convo}
                users={props.users}
                currentUserId={props.currentUserId}
              />
            );
          }
          return (
            <GroupConversation
              key={convo.id}
              conversation={convo}
              users={props.users}
              currentUserId={props.currentUserId}
            />
          );
        })
      }
      </tbody>
    </table>
  );
}

function OneToOneConversation(props) {
  const lastMessage = props.conversation.messages[props.conversation.messages.length - 1];
  // Unique user ids in the conversation
  const userNames = _uniq(_flatten(props.conversation.messages.map((m) => [m.fromUserId, m.toUserId]))) // Array of unique userIds involved in convo
    .map((uId) => props.users[uId]) // Array of full user objects
    .map((u) => {
      if (props.currentUserId === u.id) {
        return 'me';
      }
      return `${u.firstName} ${u.lastName}`;
    }) // extract names as Array of strings
    .join(', ');
  const unreadCount = getUnreadCount(props.conversation.messages, props.currentUserId);
  return (
    <tr>
      { unreadCount > 0 && <td><span className="badge" style={{ backgroundColor: '#cb002c' }}>{unreadCount}</span></td> }
      { unreadCount <= 0 && <td /> }
      <td>
        {userNames}
        {' '}
        <small>
          (
          {props.conversation.messages.length}
          )
        </small>
      </td>
      <td>
        <Link to={`/facilitator/messages/${props.conversation.messages[0].conversationId}`}>{trim(props.conversation.subject, 60)}</Link>
      </td>
      <td>
        {formatDate(lastMessage.createdAt)}
      </td>
    </tr>
  );
}

function GroupConversation(props) {
  const lastMessage = props.conversation.messages[props.conversation.messages.length - 1];
  const userNames = _uniq(_flatten(props.conversation.messages.map((m) => [m.fromUserId, m.toUserId]))) // Array of unique userIds involved in convo
    .map((uId) => props.users[uId]) // Array of full user objects
    .map((u) => {
      if (props.currentUserId === u.id) {
        return 'me';
      }
      return `${u.firstName} ${u.lastName}`;
    }) // extract names as Array of strings
    .filter((name) => name !== 'me');
  const subsetOfUserNames = userNames
    .slice(0, Math.min(2, userNames.length - 1))
    .join(', ');

  return (
    <tr>
      { /* Facilitators will never have unread conversations in group conversations because new conversations are created when participants reply */ }
      <td>
        <span className="fas fa-user" aria-hidden="true" />
        <span className="fas fa-user" aria-hidden="true" />
      </td>
      <td>
        {subsetOfUserNames}
        , ...
        {' '}
        <small>
          (
          {props.conversation.messages.length}
          )
        </small>
      </td>
      <td>
        <Link to={`/facilitator/messages/${props.conversation.messages[0].conversationId}`}>{props.conversation.subject}</Link>
      </td>
      <td>
        {formatDate(lastMessage.createdAt)}
      </td>
    </tr>
  );
}

/**
 * @return Number - the number of unread messages in this conversation
 */
function getUnreadCount(messages, currentUserId) {
  return messages.filter((m) => m.toUserId === currentUserId && m.readAt == null).length;
}

function formatDate(date) {
  const now = Date.now();
  const messageDate = parseISO(date);
  if (isSameDay(now, messageDate)) {
    return format(messageDate, SHORT_TIME);
  } if (isSameYear(now, messageDate)) {
    return format(messageDate, DT_MESSAGE);
  }
  return format(messageDate, DT_MESSAGE_YEAR);
}

const UserPropType = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isFacilitator: PropTypes.bool,
  groupMembershipId: PropTypes.number,
  groupId: PropTypes.number,
  groupName: PropTypes.string,
});

Messages.defaultProps = {
  conversations: [],
  users: {},
  currentUserId: null,
};

Messages.propTypes = {
  conversations: PropTypes.arrayOf(ConversationPropType),
  users: PropTypes.objectOf(UserPropType),
  currentUserId: PropTypes.number,
};

OneToOneConversation.defaultProps = {
  conversation: null,
  users: {},
  currentUserId: null,
};

OneToOneConversation.propTypes = {
  conversation: ConversationPropType,
  users: PropTypes.objectOf(UserPropType),
  currentUserId: PropTypes.number,
};

GroupConversation.defaultProps = {
  conversation: null,
  users: {},
  currentUserId: null,
};

GroupConversation.propTypes = {
  conversation: ConversationPropType,
  users: PropTypes.objectOf(UserPropType),
  currentUserId: PropTypes.number,
};

export default Messages;
