import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { add, format, formatDistanceToNow, parseISO } from 'date-fns';
import { DATE_RANGE, PHYSICIAN_AFFILIATION } from 'lib/constants';
import { COMPACT_DATE } from 'lib/dateFormats';
import Button from 'react-bootstrap/Button';
import ReportStateBadge from 'components/reporting/ReportStateBadge';

const ReportsListItem = ({ itemId }) => {
  const { id,
    filters,
    state: reportState,
    requestedByUserName,
    createdAt,
    reportId,
    durationInSeconds,
    courseTypeName,
  } = useSelector((state) => state.ptqReport.reports.byId[itemId]);
  const reportReady = reportState === 'completed';

  return (
    <tr>
      <td>
        <span className="text-secondary font-monospace text-nowrap">
          {reportId}
        </span>
      </td>

      <td><ReportStateBadge reportState={reportState} /></td>

      <td>
        <ul className="list-inline">
          <li>
            <small className="text-secondary">Course:</small>
            <span className="font-monospace ps-2">
              <small>{courseTypeName}</small>
            </span>
          </li>
          <li>
            <small className="text-secondary">
              {`${filters.dateRange ? DATE_RANGE[filters.dateRange] : 'Groups ended'} between`}
            </small>
            <small className="ps-1">
              {format(parseISO(filters.startDate), COMPACT_DATE)}
              <span className="text-secondary"> and </span>
              {format(parseISO(filters.endDate), COMPACT_DATE)}
            </small>
          </li>
          {filters.physicianCreditType && (
          <li>
            <small className="text-secondary">Physician affiliation:</small>
            <span className="font-monospace ps-2">
              <small>{PHYSICIAN_AFFILIATION[filters.physicianCreditType].code}</small>
            </span>
          </li>
          )}
        </ul>
      </td>

      <td>
        <small className="text-secondary">
          {formatDistanceToNow(parseISO(createdAt), { addSuffix: true })}
          <br />
          {`by ${requestedByUserName}`}
        </small>
      </td>

      <td>
        {reportReady && (
          <small className="text-secondary">
            {formatDistanceToNow(add(new Date(), { seconds: durationInSeconds }))}
          </small>
        )}

        {!reportReady && (
          <span className="text-muted">&hellip;</span>
        )}
      </td>

      <td className="text-end">

        <Button
          variant={reportReady ? 'success' : 'light'}
          size="sm"
          className="text-nowrap"
          disabled={!reportReady}
          href={reportReady ? `/api/registrar/reports/ptq/${id}` : null}
          download={reportReady}
        >
          <i className="fa fa-file-csv me-2" />
          Download as CSV
        </Button>
      </td>
    </tr>
  );
};

ReportsListItem.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default ReportsListItem;
