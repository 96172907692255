import React from 'react';

function Bookmarks() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_246_3333)">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.5 19.5L18 24V6C18 5.20435 17.6839 4.44129 17.1213 3.87868C16.5587 3.31607 15.7956 3 15 3H6C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6V24L10.5 19.5ZM4.5 21.351L10.5 17.751L16.5 21.351V6C16.5 5.60218 16.342 5.22064 16.0607 4.93934C15.7794 4.65804 15.3978 4.5 15 4.5H6C5.60218 4.5 5.22064 4.65804 4.93934 4.93934C4.65804 5.22064 4.5 5.60218 4.5 6V21.351Z" fill="currentColor" />
        <path d="M21 21L19.5 20.1V3C19.5 2.60218 19.342 2.22064 19.0607 1.93934C18.7794 1.65804 18.3978 1.5 18 1.5H6.40201C6.66531 1.04395 7.04401 0.665247 7.50005 0.401943C7.95609 0.138639 8.47341 1.33777e-05 9.00001 0L18 0C18.7957 0 19.5587 0.316071 20.1213 0.87868C20.6839 1.44129 21 2.20435 21 3V21Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_3333">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Bookmarks;
