import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { CURRICULUM_PATH, CURRICULUM_COURSE_TYPE_SHOW_PATH, CURRICULUM_COURSE_TYPE_SETTINGS_PATH, CURRICULUM_COURSE_TYPE_ARCHIVE_PATH } from 'lib/routerPaths';
import { buildRoutePath } from 'lib/routerHelpers';
import { LOAD_COURSE_TYPE_COURSES, CREATE_COURSE } from 'store/courses/actions';
import { LOAD_COURSE_TYPES } from 'store/courseTypes/actions';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import CurriculumTemplate from 'components/templates/CurriculumTemplate';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import CourseDraftCreateDialog from 'components/curriculum/CourseDraftCreateDialog';
import SanyasIcon from 'components/shared/SanyasIcon';
import Badge from 'components/shared/Badge/Badge';
import Spinner from 'components/shared/Spinner';
import TabbedNav from 'components/shared/Navigation/TabbedNav';
import CourseTypeSettingsTabPage from '../CourseTypeSettingsTabPage';
import CourseTypeArchiveTabPage from '../CourseTypeArchiveTabPage';
import CourseTypeCourseTabPage from '../CourseTypeCourseTabPage';

function CourseTypeShowPage() {
  const { id } = useParams();
  const courseType = useSelector((state) => selectCourseTypeById(state, id));
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const courseTypePath = buildRoutePath(CURRICULUM_COURSE_TYPE_SHOW_PATH, { id });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(LOAD_COURSE_TYPES.request());
    dispatch(LOAD_COURSE_TYPE_COURSES.request({ workspace: 'shared', id }));
  }, [dispatch, id]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onSubmit = useCallback((values, actions) => {
    dispatch(CREATE_COURSE.request(values, { formikActions: actions }));
  }, [dispatch]);

  return (
    <CurriculumTemplate>
      <div className="h-100 d-flex flex-column flush">
        <div className="header pb-0">
          <div className="d-flex align-items-center flex-col-gap-sm">
            <SanyasIcon name="book" size="3x" />
            <div>
              <Breadcrumbs
                navItems={[
                  { label: 'Courses', href: CURRICULUM_PATH },
                  { label: courseType?.name || '...' },
                ]}
              />

              <h1 className="d-flex align-items-center">
                {courseType?.name}
                {courseType && (
                  <Badge
                    variant={courseType.isCore ? 'green' : 'red'}
                    title={courseType.isCore ? 'Core' : 'Post-training'}
                    className="badge-xs ms-2"
                  />
                )}
              </h1>
            </div>
          </div>

          <div className="position-relative">
            <TabbedNav
              variant="underline"
              navItems={[
                {
                  title: 'Course',
                  href: courseTypePath,
                  match: {
                    path: CURRICULUM_COURSE_TYPE_SHOW_PATH,
                    exact: true,
                  },
                },
                {
                  title: 'Settings',
                  href: `${courseTypePath}/settings`,
                  match: { path: CURRICULUM_COURSE_TYPE_SETTINGS_PATH },
                },
                {
                  title: 'Archive',
                  href: `${courseTypePath}/archive`,
                  match: { path: CURRICULUM_COURSE_TYPE_ARCHIVE_PATH },
                }]}
            />

            <button className="btn btn-secondary btn-sm position-absolute top-0 end-0" type="button" onClick={() => setShowModal(true)}>New draft</button>
          </div>
        </div>

        <Switch>
          <Route path={CURRICULUM_COURSE_TYPE_SETTINGS_PATH}>
            <CourseTypeSettingsTabPage />
          </Route>

          <Route path={CURRICULUM_COURSE_TYPE_ARCHIVE_PATH}>
            <CourseTypeArchiveTabPage />
          </Route>

          <Route path={match.path}>
            {!courseType ? <Spinner /> : <CourseTypeCourseTabPage courseType={courseType} setShowCreateDraftModal={setShowModal} />}
          </Route>
        </Switch>
      </div>

      <CourseDraftCreateDialog
        courseTypeId={Number(id)}
        show={showModal}
        onClose={handleCloseModal}
        onSubmit={onSubmit}
      />
    </CurriculumTemplate>
  );
}

export default CourseTypeShowPage;
