import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { format, parse } from 'date-fns';
import { SIZES } from 'lib/constants';
import { COMPACT_DATE, D_FLATPICKR } from 'lib/dateFormats';
import { useFieldContext } from 'lib/hooks/useFieldContext';
import { RefPropType } from 'lib/propTypes';
import Flatpickr from 'react-flatpickr';
import ClearIndicator from '../ClearIndicator/ClearIndicator';

const DATE_FORMATS = {
  'Y-m-d': COMPACT_DATE,
  'm/d/Y': D_FLATPICKR,
  // Add additional formats here
};

const formatValue = (value, dateFormat) => (value ? format(parse(value, COMPACT_DATE, new Date()), DATE_FORMATS[dateFormat]) : '');
const formatRangeValue = (value, dateFormat) => {
  if (value.startDate && value.endDate) {
    return [formatValue(value.startDate, dateFormat), formatValue(value.endDate, dateFormat)];
  }
  return ['', ''];
};

function DatePicker({ name, size, required, disabled, mode, dateFormat, minDate, maxDate, placeholder, inputRef, ...props }) {
  const { id, value, touched, error, handleChange, handleBlur } = useFieldContext();
  const datePickerRef = useRef(null);
  const formattedValue = mode === 'range' ? formatRangeValue(value, dateFormat) : formatValue(value, dateFormat);
  const clearable = !required && (Boolean(mode === 'range' && value.startDate && value.endDate) || Boolean(mode === 'single' && value));

  const handleDateChange = (date) => {
    // only handleBlur when handleChange is called
    if (mode === 'range') {
      if (date[0] && date[1]) {
        handleChange({
          startDate: date[0] ? format(date[0], COMPACT_DATE) : '',
          endDate: date[1] ? format(date[1], COMPACT_DATE) : '',
        });
        handleBlur();
      } else if (!date.length) {
        handleChange({ startDate: '', endDate: '' });
        handleBlur();
      }
    } else {
      handleChange(date[0] ? format(date[0], COMPACT_DATE) : '');
      handleBlur();
    }
  };

  const handleClear = () => {
    datePickerRef.current.flatpickr.clear();
  };

  return (
    <div
      ref={inputRef}
      className={`sn-datepicker form-control${(touched && error) ? ' is-invalid' : ''}${size !== SIZES.md ? ` form-control-${size}` : ''}${!required ? ' is-clearable' : ''}${disabled ? ' is-disabled' : ''}`}
    >
      <Flatpickr
        ref={datePickerRef}
        id={id}
        name={name}
        value={formattedValue}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleDateChange}
        options={{ mode, dateFormat, minDate, maxDate }}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
      />
      {clearable && (
        <ClearIndicator onClick={handleClear} />
      )}
    </div>
  );
}

DatePicker.defaultProps = {
  size: SIZES.md,
  required: false,
  disabled: false,
  mode: 'single',
  dateFormat: 'Y-m-d', // Flatpickr default
  minDate: undefined,
  maxDate: undefined,
  placeholder: null,
  inputRef: null,
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  mode: PropTypes.oneOf(['single', 'range']),
  dateFormat: PropTypes.string,
  minDate: PropTypes.string, // format must match dateFormat
  maxDate: PropTypes.string, // format must match dateFormat
  placeholder: PropTypes.string,
  inputRef: RefPropType,
};

export default DatePicker;
