import React from 'react';
import { hot } from 'react-hot-loader/root';
import {
  Route, Switch,
} from 'react-router-dom';

import routes from '../routes';
import ScrollToTop from './shared/ScrollToTop/ScrollToTop';
import Notifications from './shared/FlashNotifications/Notifications';

function App() {
  return (
    <>
      <ScrollToTop />
      <Switch>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
        // eslint-disable-next-line react/jsx-props-no-spreading
            render={(props) => <route.main {...props} />}
          />
        ))}
      </Switch>
      <Notifications />
    </>
  );
}

export default hot(App);
