import { fetchGet, handleSimpleFetchError } from 'lib/apiHelpers';
import { put, takeLatest } from 'redux-saga/effects';
import { GET_PAYMENT_TRANSACTIONS } from 'store/paymentReport/actions';

export default function* sagas() {
  yield takeLatest(GET_PAYMENT_TRANSACTIONS.REQUEST, function* getPaymentTransactions({ payload: filters }) {
    try {
      const paymentTransactions = yield fetchGet('/api/registrar/payment_transactions', filters);
      yield put(GET_PAYMENT_TRANSACTIONS.success(paymentTransactions));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_PAYMENT_TRANSACTIONS);
    }
  });
}
