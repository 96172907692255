import React from 'react';
import PropTypes from 'prop-types';
import { StylePropType } from 'lib/propTypes';

function Spinner({ message, style, size, overlay }) {
  const parentClassName = overlay ? 'position-absolute w-100 p-0 bg-white bg-opacity-50' : 'position-relative p-5';
  const parentStyle = overlay ? { zIndex: 1 } : {}; // z-index needs to be slightly above content

  return (
    <div className={`h-100 ${parentClassName}`} style={parentStyle}>
      <div className="position-absolute top-50 start-50 translate-middle text-center">
        <span className={`fas fa-spinner fa-pulse fa-${size} fa-fw`} style={style} />
        <div className="mt-2">{message}</div>
      </div>
    </div>
  );
}

Spinner.defaultProps = {
  style: null,
  message: '',
  size: '3x',
  overlay: false,
};

Spinner.propTypes = {
  style: StylePropType,
  message: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'lg', '2x', '3x', '5x', '7x', '10x']), // https://fontawesome.com/v5/docs/web/style/size#relative-sizing
  overlay: PropTypes.bool,
};

export default Spinner;
