import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { REDUX_STATUS } from 'lib/constants';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_SHOW_PATH, CURRICULUM_COURSE_TYPE_SHOW_PATH, CURRICULUM_PATH } from 'lib/routerPaths';
import { LOAD_COURSE, LOAD_COURSE_PROACTIVES, LOAD_COURSE_SECTIONS } from 'store/courses/actions';
import { selectCourseById } from 'store/courses/selectors';
import { LOAD_COURSE_TYPES } from 'store/courseTypes/actions';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import CurriculumTemplate from 'components/templates/CurriculumTemplate';
import SanyasIcon from 'components/shared/SanyasIcon';
import ProactiveTemplateRow from 'components/curriculum/ProactiveTemplate/ProactiveTemplateRow';
import TableSkeleton from 'components/curriculum/ProactiveTemplate/TableSkeleton';

function CourseProactiveTemplatesPage() {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const course = useSelector((state) => selectCourseById(state, courseId));
  const courseType = useSelector((state) => state.courseTypes.byId[course?.courseTypeId]);
  const proactiveTemplates = useSelector((state) => state.courses.proactiveTemplates);

  useEffect(() => {
    dispatch(LOAD_COURSE_TYPES.request());
  }, [dispatch]);

  useEffect(() => {
    if (!course?.id) {
      dispatch(LOAD_COURSE.request(courseId));
    }
  }, [course?.id, courseId, dispatch]);

  useEffect(() => {
    dispatch(LOAD_COURSE_PROACTIVES.request(courseId));
    dispatch(LOAD_COURSE_SECTIONS.request(courseId));
  }, [courseId, dispatch]);

  return (
    <CurriculumTemplate>
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <SanyasIcon name="book" size="3x" />
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Courses', href: CURRICULUM_PATH },
                { label: courseType?.name || '...', href: buildRoutePath(CURRICULUM_COURSE_TYPE_SHOW_PATH, { id: courseType?.id }) },
                { label: course?.name || '...', href: buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course?.id }) },
              ]}
              ignoreLast
            />

            <h1>
              {courseType?.name}
            </h1>
          </div>
        </div>
      </div>

      <div className="mt-4 px-2">
        <h2>Proactive Templates</h2>

        <p className="w-75">
          Proactive templates are used for facilitator’s proactive posts on discussion boards. They are automatically
          posted around 1:00 pm PT on the Friday before a group opens.
        </p>

        {proactiveTemplates.status === REDUX_STATUS.PENDING && (<TableSkeleton />)}

        {(proactiveTemplates.status === REDUX_STATUS.SUCCESS && !proactiveTemplates.data.length) && (
          <Alert variant="info">
            No proactive templates found.
          </Alert>
        )}

        {(proactiveTemplates.status === REDUX_STATUS.SUCCESS && proactiveTemplates.data.length > 0) && (
          <Table striped bordered hover className="sn-table bg-white">
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>{}</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(proactiveTemplates.data)?.map((template) => (
                <ProactiveTemplateRow key={template.id} template={template} />
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </CurriculumTemplate>
  );
}

export default CourseProactiveTemplatesPage;
