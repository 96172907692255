import { useEffect, useRef } from 'react';

const useUnload = (handler) => {
  const eventListenerRef = useRef();

  useEffect(() => {
    eventListenerRef.current = (event) => {
      const returnValue = typeof handler === 'function' ? handler(event) : null;

      // Handle legacy `event.returnValue` property
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      if (typeof returnValue === 'string') {
        event.returnValue = returnValue; // eslint-disable-line no-param-reassign
      }
      // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
      // instead it requires `event.returnValue` to be set
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
      if (event.defaultPrevented) {
        event.returnValue = ''; // eslint-disable-line no-param-reassign
      }

      return event.returnValue;
    };
  }, [handler]);

  useEffect(() => {
    const eventListener = (event) => {
      eventListenerRef.current(event);
    };
    window.addEventListener('beforeunload', eventListener);
    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  }, []);
};

export default useUnload;
