import React from 'react';

function CheckBox() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M23.031 3.96906C23.1008 4.03873 23.1562 4.12149 23.194 4.21261C23.2319 4.30373 23.2513 4.40141 23.2513 4.50006C23.2513 4.59871 23.2319 4.69639 23.194 4.78751C23.1562 4.87863 23.1008 4.96139 23.031 5.03106L12.531 15.5311C12.4613 15.6009 12.3785 15.6563 12.2874 15.6941C12.1963 15.7319 12.0986 15.7514 12 15.7514C11.9013 15.7514 11.8036 15.7319 11.7125 15.6941C11.6214 15.6563 11.5386 15.6009 11.469 15.5311L6.96897 11.0311C6.89924 10.9613 6.84392 10.8785 6.80619 10.7874C6.76845 10.6963 6.74902 10.5987 6.74902 10.5001C6.74902 10.4014 6.76845 10.3038 6.80619 10.2127C6.84392 10.1216 6.89924 10.0388 6.96897 9.96906C7.0387 9.89933 7.12149 9.84401 7.2126 9.80627C7.3037 9.76853 7.40136 9.74911 7.49997 9.74911C7.59859 9.74911 7.69624 9.76853 7.78735 9.80627C7.87846 9.84401 7.96124 9.89933 8.03097 9.96906L12 13.9396L21.969 3.96906C22.0386 3.89921 22.1214 3.8438 22.2125 3.80599C22.3036 3.76818 22.4013 3.74872 22.5 3.74872C22.5986 3.74872 22.6963 3.76818 22.7874 3.80599C22.8785 3.8438 22.9613 3.89921 23.031 3.96906Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2.25 19.5C2.25 20.0967 2.48705 20.669 2.90901 21.091C3.33097 21.5129 3.90326 21.75 4.5 21.75H19.5C20.0967 21.75 20.669 21.5129 21.091 21.091C21.5129 20.669 21.75 20.0967 21.75 19.5V12C21.75 11.8011 21.671 11.6103 21.5303 11.4697C21.3897 11.329 21.1989 11.25 21 11.25C20.8011 11.25 20.6103 11.329 20.4697 11.4697C20.329 11.6103 20.25 11.8011 20.25 12V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H16.5C16.6989 3.75 16.8897 3.67098 17.0303 3.53033C17.171 3.38968 17.25 3.19891 17.25 3C17.25 2.80109 17.171 2.61032 17.0303 2.46967C16.8897 2.32902 16.6989 2.25 16.5 2.25H4.5C3.90326 2.25 3.33097 2.48705 2.90901 2.90901C2.48705 3.33097 2.25 3.90326 2.25 4.5V19.5Z" fill="currentColor" />
    </svg>
  );
}

export default CheckBox;
