import React from 'react';
import PropTypes from 'prop-types';
import { LEGACY_APP_DOMAIN } from 'config';
import { FlaggedJournalPropType } from 'lib/propTypes';
import Spinner from 'components/shared/Spinner';

function FlaggedJournalList({ journals }) {
  if (!journals) {
    return <div className="col-md-4 text-center"><Spinner /></div>;
  }
  if (journals.length === 0) {
    return <div className="col-md-4" />;
  }
  journals.sort((a, b) => {
    if (a.fullName > b.fullName) return 1;
    if (a.fullName < b.fullName) return -1;
    return 0;
  });
  return (
    <div className="col-md-4">
      <h5 style={{ marginTop: 0 }}>Flagged Journals</h5>
      <ul>
        {
          journals.map((journal) => (
            <li key={journal.groupMembershipId} style={{ marginTop: 5, marginBottom: 5 }}>
              <span className="label priority3">
                <a
                  href={`${LEGACY_APP_DOMAIN}/group_memberships/${journal.groupMembershipId}/journal_tasks/${journal.taskId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  {journal.fullName}
                  {' '}
                </a>
              </span>
            </li>
          ))
        }
      </ul>
    </div>

  );
}

FlaggedJournalList.defaultProps = {
  journals: [],
};

FlaggedJournalList.propTypes = {
  journals: PropTypes.arrayOf(FlaggedJournalPropType),
};

export default FlaggedJournalList;
