import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { REDUX_STATUS } from 'lib/constants';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_TYPE_SHOW_PATH } from 'lib/routerPaths';
import { DUPLICATE_COURSE, RESET_COURSE_STATUS } from 'store/courses/actions';
import { selectCourseTypeById, selectCourseTypeOptions } from 'store/courseTypes/selectors';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import SelectField from 'components/shared/FormFields/SelectField';

const formatCourseTypeLabel = ({ label, orgShortName }, { context }) => {
  if (context === 'value') {
    return (
      <>
        <span>{label}</span>
        <span className="ms-2 text-small text-muted">{orgShortName}</span>
      </>
    );
  }
  return label;
};

const FormSchema = Yup.object().shape({
  copyToCourseTypeId: Yup.number().required('Required'),
});

function CopyCourseForm({ currentCourseId, showCopyModal, setShowCopyModal }) {
  const { id } = useParams();
  const courseTypeOptions = useSelector((state) => selectCourseTypeOptions(state, id));
  const currentUserId = useSelector((state) => state.currentUser?.id);
  const { status, data } = useSelector((state) => state.courses.course);
  const copyToCourseType = useSelector((state) => selectCourseTypeById(state, data.courseTypeId));
  const dispatch = useDispatch();

  const initialValues = {
    copyToCourseTypeId: '',
    courseId: currentCourseId,
    userId: currentUserId,
  };

  const handleSave = useCallback((values, actions) => {
    dispatch(DUPLICATE_COURSE.request(values, { formikActions: actions }));
  }, [dispatch]);

  const handleClose = useCallback((resetForm) => {
    setShowCopyModal(false);
    if (typeof resetForm === 'function') resetForm();
    dispatch(RESET_COURSE_STATUS.action());
  }, [setShowCopyModal, dispatch]);

  const isLoading = showCopyModal && status === REDUX_STATUS.PENDING;
  const successMessage = (
    <>
      <p>
        The course copy process has been started. You will receive an notification when the process is complete.
      </p>
      <p className="mt-3">
        Go to
        {' '}
        <Link
          onClick={handleClose}
          to={buildRoutePath(CURRICULUM_COURSE_TYPE_SHOW_PATH, { id: data.courseTypeId })}
        >
          {`${copyToCourseType?.name}`}
        </Link>
      </p>
    </>
  );

  return (
    <Formik
      onSubmit={handleSave}
      validationSchema={FormSchema}
      enableReinitialize
      initialValues={initialValues}
    >
      {({ resetForm, handleSubmit }) => {
        const handleModalClose = () => handleClose(resetForm);
        const handleModalConfirm = status === REDUX_STATUS.SUCCESS ? handleModalClose : handleSubmit;
        return (
          <DefaultModal
            size="md"
            isOpen={showCopyModal}
            header="Copy course"
            onClose={() => handleClose(resetForm)}
            footerComponent={(
              <Button className="d-flex align-items-center" disabled={isLoading} value="primary" onClick={handleModalConfirm} type="submit">
                {status === REDUX_STATUS.SUCCESS ? 'Close' : 'Copy'}
                {isLoading ? <Spinner size="sm" className="ms-1" animation="border" role="status" /> : undefined}
              </Button>
            )}
          >
            <Form key={currentCourseId} className="form-horizontal col-12">
              {status === REDUX_STATUS.SUCCESS ? successMessage
                : (
                  <>
                    <p>Which course would you like to copy to?</p>
                    <SelectField
                      name="copyToCourseTypeId"
                      label="Course"
                      options={courseTypeOptions}
                      includeBlank="Choose a course"
                      disabled={isLoading}
                      required
                      formatOptionLabel={formatCourseTypeLabel}
                    />
                  </>
                )}
            </Form>
          </DefaultModal>
        );
      } }
    </Formik>
  );
}

CopyCourseForm.defaultProps = {
};

CopyCourseForm.propTypes = {
  currentCourseId: PropTypes.number.isRequired,
  showCopyModal: PropTypes.bool.isRequired,
  setShowCopyModal: PropTypes.func.isRequired,
};

export default CopyCourseForm;
