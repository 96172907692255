/* TODO Combine this file with client/components/shared/FormFields/ReactSelectStyles.js */
export const ReactSelectStyles = {
  clearIndicator: (base) => ({
    ...base,
    padding: '2px',
  }),
  control: (base, state) => {
    const isInvalid = state.selectProps['aria-invalid'];
    return ({
      ...base,
      // match Bootstrap form-select
      backgroundColor: state.isDisabled ? 'var(--bs-secondary-bg)' : 'var(--bs-white)',
      // eslint-disable-next-line no-nested-ternary
      borderColor: state.isFocused && !isInvalid ? 'rgba(242, 247, 247, 0.55)' : isInvalid ? '#C24447' : 'var(--bs-sn-clr-border-grey)',
      '&:hover': { // Override react-select :hover style
      // eslint-disable-next-line no-nested-ternary
        borderColor: isInvalid ? '#C24447' : state.isFocused ? 'rgba(242, 247, 247, 0.55)' : 'var(--bs-sn-clr-border-grey)',
        cursor: 'pointer',
      },
      borderRadius: 'var(--bs-border-radius)',
      boxShadow: state.isFocused && '0 0 0 0.25rem rgba(0, 86, 97, 0.25)',
      fontSize: '0.875rem',
      minHeight: 0,
      paddingBlock: 0,
    });
  },
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled ? 'var(--bs-secondary-color)' : 'var(--bs-gray-800)', // match Bootstrap form-select
    '&:hover': { // Override react-select :hover style
      color: 'var(--bs-gray-800)',
    },
    padding: '2px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (base) => ({ ...base, paddingBlock: 0 }),
  menu: (base) => ({ ...base, fontSize: '0.875rem', zIndex: 10 }),
  placeholder: (base) => ({ ...base, color: 'var(--bs-gray-500)' }),
  singleValue: (base) => ({
    ...base,
    color: 'var(--bs-body-color)', // match Bootstrap form-select
  }),
  valueContainer: (base) => ({ ...base, paddingBlock: 0, paddingInline: 6 }),
};
