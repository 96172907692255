import React from 'react';
import { useSelector } from 'react-redux';
import { REDUX_STATUS } from 'lib/constants';
import { selectDraftCourses } from 'store/courses/selectors';
import { CourseDraftCardSkeleton } from 'components/curriculum/CourseDraftCard';
import CourseDraftCard from './CourseDraftCard';

function CourseDrafts() {
  const status = useSelector((state) => state.courses.status);
  const courses = useSelector((state) => selectDraftCourses(state));
  const loading = status === REDUX_STATUS.PENDING;

  return (
    <>
      <h4>Drafts</h4>

      <p>Drafts are only visible to the San’yas team, this is where all the edits happen.</p>

      { (loading || courses.length > 0) && (
        <div className="row gy-2">
          {courses.map((course) => (<CourseDraftCard key={course.id} course={course} />))}
          {loading && <CourseDraftCardSkeleton />}
        </div>
      )}
    </>
  );
}

export default CourseDrafts;
