import React from 'react';

function Image() {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4126_34205)">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.752 2.25H2.25195C1.95358 2.25 1.66744 2.36853 1.45646 2.5795C1.24548 2.79048 1.12695 3.07663 1.12695 3.375V14.625C1.12695 14.9234 1.24548 15.2095 1.45646 15.4205C1.66744 15.6315 1.95358 15.75 2.25195 15.75H15.752C16.0503 15.75 16.3365 15.6315 16.5474 15.4205C16.7584 15.2095 16.877 14.9234 16.877 14.625V3.375C16.877 3.07663 16.7584 2.79048 16.5474 2.5795C16.3365 2.36853 16.0503 2.25 15.752 2.25V2.25ZM2.25195 1.125C1.65522 1.125 1.08292 1.36205 0.660963 1.78401C0.239006 2.20597 0.00195313 2.77826 0.00195312 3.375V14.625C0.00195313 15.2217 0.239006 15.794 0.660963 16.216C1.08292 16.6379 1.65522 16.875 2.25195 16.875H15.752C16.0474 16.875 16.34 16.8168 16.613 16.7037C16.886 16.5907 17.134 16.4249 17.3429 16.216C17.5519 16.0071 17.7176 15.759 17.8307 15.486C17.9438 15.2131 18.002 14.9205 18.002 14.625V3.375C18.002 2.77826 17.7649 2.20597 17.3429 1.78401C16.921 1.36205 16.3487 1.125 15.752 1.125H2.25195V1.125Z" fill="currentColor" />
        <path d="M11.9787 8.6017C12.0623 8.51838 12.1699 8.46344 12.2864 8.44467C12.4029 8.42589 12.5223 8.44422 12.6278 8.49707L16.877 10.6874V15.7499H1.12695V13.4999L4.1037 10.8517C4.19565 10.7601 4.31646 10.7031 4.44563 10.6904C4.57481 10.6777 4.70441 10.71 4.81245 10.7819L7.80495 12.7766L11.9787 8.60282V8.6017Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.06445 7.875C5.28606 7.875 5.50549 7.83135 5.71023 7.74655C5.91497 7.66174 6.101 7.53744 6.2577 7.38074C6.41439 7.22404 6.5387 7.03802 6.6235 6.83328C6.7083 6.62854 6.75195 6.40911 6.75195 6.1875C6.75195 5.96589 6.7083 5.74646 6.6235 5.54172C6.5387 5.33698 6.41439 5.15096 6.2577 4.99426C6.101 4.83756 5.91497 4.71326 5.71023 4.62845C5.50549 4.54365 5.28606 4.5 5.06445 4.5C4.6169 4.5 4.18768 4.67779 3.87121 4.99426C3.55474 5.31072 3.37695 5.73995 3.37695 6.1875C3.37695 6.63505 3.55474 7.06428 3.87121 7.38074C4.18768 7.69721 4.6169 7.875 5.06445 7.875V7.875Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_4126_34205">
          <rect width="100%" height="100%" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Image;
