import React from 'react';

function ChatQuote() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.017 17.841C4.1727 17.9973 4.292 18.186 4.36641 18.3937C4.44082 18.6014 4.46851 18.8229 4.4475 19.0425C4.34208 20.0589 4.14221 21.0632 3.8505 22.0425C5.943 21.558 7.221 20.997 7.8015 20.703C8.13076 20.5362 8.50993 20.4967 8.8665 20.592C9.88865 20.8645 10.9421 21.0017 12 21C17.994 21 22.5 16.7895 22.5 12C22.5 7.212 17.994 3 12 3C6.006 3 1.5 7.212 1.5 12C1.5 14.202 2.4255 16.245 4.017 17.841ZM3.2775 23.6985C2.9221 23.769 2.56555 23.8335 2.208 23.892C1.908 23.94 1.68 23.628 1.7985 23.349C1.93169 23.0349 2.05376 22.7162 2.1645 22.3935L2.169 22.3785C2.541 21.2985 2.844 20.0565 2.955 18.9C1.1145 17.055 0 14.64 0 12C0 6.201 5.373 1.5 12 1.5C18.627 1.5 24 6.201 24 12C24 17.799 18.627 22.5 12 22.5C10.8115 22.5016 9.62788 22.3473 8.4795 22.041C7.6995 22.4355 6.021 23.154 3.2775 23.6985Z" fill="currentColor" />
      <path d="M11.202 11.5005C11.202 12.8805 10.038 13.9995 8.601 13.9995C7.164 13.9995 6 12.8805 6 11.5005C6 10.1205 7.164 9 8.601 9C10.038 9 11.202 10.119 11.202 11.5005Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.2355 10.4039C9.21079 10.4889 9.16914 10.5681 9.11307 10.6366C9.05699 10.7052 8.98764 10.7617 8.90918 10.8027C8.83072 10.8438 8.74477 10.8685 8.65649 10.8755C8.56822 10.8825 8.47944 10.8716 8.3955 10.8434C8.3158 10.8197 8.24169 10.7803 8.17758 10.7273C8.11346 10.6744 8.06066 10.6091 8.02231 10.5354C7.98395 10.4616 7.96084 10.3809 7.95435 10.298C7.94785 10.2151 7.95811 10.1318 7.9845 10.0529C8.1045 9.70791 8.3295 9.39291 8.7 9.23391C8.86694 9.16675 9.04573 9.13399 9.22564 9.1376C9.40555 9.14121 9.58288 9.18111 9.747 9.25491C10.3275 9.49491 10.827 10.0724 11.1315 10.7504C11.7735 12.1724 11.721 14.3159 9.7185 16.3094C9.59627 16.4285 9.43313 16.4965 9.26248 16.4992C9.09182 16.502 8.92655 16.4395 8.8005 16.3244C8.73904 16.2687 8.68962 16.2011 8.6553 16.1256C8.62098 16.0501 8.60248 15.9684 8.60093 15.8855C8.59939 15.8026 8.61483 15.7202 8.64632 15.6435C8.6778 15.5668 8.72465 15.4973 8.784 15.4394C10.4235 13.8089 10.371 12.2024 9.939 11.2484C9.711 10.7444 9.408 10.4774 9.234 10.4039H9.2355ZM9.183 10.3859H9.1815H9.183Z" fill="currentColor" />
      <path d="M17.7045 11.5005C17.7045 12.8805 16.5405 13.9995 15.1035 13.9995C13.668 13.9995 12.5025 12.8805 12.5025 11.5005C12.5025 10.1205 13.668 9 15.1035 9C16.5405 9 17.7045 10.119 17.7045 11.5005Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.738 10.4039C15.7133 10.4889 15.6716 10.5681 15.6156 10.6366C15.5595 10.7052 15.4901 10.7617 15.4117 10.8027C15.3332 10.8438 15.2473 10.8685 15.159 10.8755C15.0707 10.8825 14.9819 10.8716 14.898 10.8434C14.8183 10.8197 14.7442 10.7803 14.6801 10.7273C14.616 10.6744 14.5632 10.6091 14.5248 10.5354C14.4865 10.4616 14.4633 10.3809 14.4568 10.298C14.4504 10.2151 14.4606 10.1318 14.487 10.0529C14.607 9.70791 14.832 9.39291 15.2025 9.23391C15.3694 9.16675 15.5482 9.13399 15.7281 9.1376C15.9081 9.14121 16.0854 9.18111 16.2495 9.25491C16.83 9.49491 17.3295 10.0724 17.6355 10.7504C18.2775 12.1724 18.2235 14.3159 16.2225 16.3094C16.1002 16.429 15.9367 16.4972 15.7657 16.5C15.5947 16.5027 15.4291 16.4399 15.303 16.3244C15.2415 16.2687 15.1921 16.2011 15.1578 16.1256C15.1235 16.0501 15.105 15.9684 15.1034 15.8855C15.1019 15.8026 15.1173 15.7202 15.1488 15.6435C15.1803 15.5668 15.2272 15.4973 15.2865 15.4394C16.926 13.8089 16.8735 12.2024 16.4415 11.2484C16.2135 10.7444 15.9105 10.4774 15.738 10.4039ZM15.687 10.3859H15.684H15.687Z" fill="currentColor" />
    </svg>
  );
}

export default ChatQuote;
