import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CHANGE_CURRENT_FACILITATOR, CHANGE_DASHBOARD_SORT_ORDER, LOAD_FACILITATOR_DISCUSSION_BOARDS } from 'store/facilitatorDashboard/actions';
import { SORT_ORDER_TYPES } from 'store/facilitatorDashboard/reducer';
import { activatedFacilitators } from 'store/facilitatorDashboard/selectors';

function FacilitatorHeader() {
  const facilitators = useSelector((state) => activatedFacilitators(state));
  const { currentFacilitator, sortOrder } = useSelector((state) => state.facilitatorDashboard);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleCurrentFacilitatorChange = useCallback((e) => {
    if (location.pathname.endsWith('discussion_boards')) {
      dispatch(LOAD_FACILITATOR_DISCUSSION_BOARDS.request({ id: parseInt(e.target.value, 10) }));
    } else {
      dispatch(CHANGE_CURRENT_FACILITATOR.action({ id: parseInt(e.target.value, 10) }));
    }
  }, [dispatch, location.pathname]);

  const handleSortOrderChange = useCallback((e) => {
    dispatch(CHANGE_DASHBOARD_SORT_ORDER.action({ sortLabel: e.target.value }));
  }, [dispatch]);

  return (
    <div className="row mt-4">
      <div className="col-md-3">
        <label className="sr-only" htmlFor="dashboard-facilitator-select">Facilitator: </label>
        <select
          id="dashboard-facilitator-select"
          onChange={handleCurrentFacilitatorChange}
          value={currentFacilitator?.id}
          className="form-select switch-facilitator"
        >
          <option disabled="disabled" value="">Choose Facilitator</option>
          {facilitators.length > 0 && facilitators.map((facilitator) => (
            <option key={facilitator.id} value={facilitator.id}>
              {facilitator.firstName}
              {' '}
              {facilitator.lastName}
            </option>
          ))}
        </select>
      </div>

      <div className="col-md-4">
        <label className="sr-only" htmlFor="dashboard-sort-order ">Sort By: </label>
        <select
          id="dashboard-sort-order"
          onChange={handleSortOrderChange}
          className="form-select switch-start-date inline-block"
          value={sortOrder}
        >
          <option value={SORT_ORDER_TYPES.START_DATE_OLDEST_FIRST}>Start Date (Oldest First)</option>
          <option value={SORT_ORDER_TYPES.START_DATE_NEWEST_FIRST}>Start Date (Newest First)</option>
          <option value={SORT_ORDER_TYPES.CLOSING_DATE_OLDEST_FIRST}>Closing Date (Oldest First)</option>
          <option value={SORT_ORDER_TYPES.CLOSING_DATE_NEWEST_FIRST}>Closing Date (Newest First)</option>
        </select>
      </div>
    </div>
  );
}

export default FacilitatorHeader;
