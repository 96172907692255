import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useFormikContext } from 'formik';

function AutoSave({ debounceMs }) {
  const [lastSaved, setLastSaved] = useState(null);
  const formik = useFormikContext();

  // NOTE: warning, maybe change to useDebounce https://usehooks.com/useDebounce/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    _debounce(() => {
      if (formik.isValid) {
        formik.submitForm().then(() => setLastSaved(new Date()));
      }
    }, debounceMs),
    [debounceMs, formik.submitForm, formik.isValid],
  );

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return (
    <small className="text-muted">
      {formik.isSubmitting
        ? 'Updating...'
        : lastSaved !== null && `Updated ${formatDistanceToNow(lastSaved, { addSuffix: true })}`}
    </small>
  );
}

AutoSave.propTypes = {
  debounceMs: PropTypes.number.isRequired,
};

export default AutoSave;
