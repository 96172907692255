import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { isRoute } from 'lib/routerHelpers';

function NavItem({ navItem, className }) {
  return (
    <li className={`nav-item ${className || ''}`}>
      {isRoute(navItem.href)
        ? (
          <NavLink
            exact={navItem.match.exact}
            to={navItem.href}
            aria-current="page"
            role="tab"
            className="nav-link"
            isActive={navItem.isActive}
          >
            {navItem.title}
          </NavLink>
        )
        : (
          <a href={navItem.href} role="tab" className="nav-link">{navItem.title}</a>
        )}
    </li>
  );
}

NavItem.defaultProps = {
  className: null,
};

NavItem.propTypes = {
  navItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    match: PropTypes.shape({
      path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]).isRequired,
      exact: PropTypes.bool,
    }),
    isActive: PropTypes.func,
  }).isRequired,
  className: PropTypes.string,
};

export default NavItem;
