import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { COMPACT_DATE_TIME } from 'lib/dateFormats';
import { GET_EMAIL_CONFIRMATIONS } from 'store/emailTransmissionReport/actions';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'components/shared/Spinner';
import Pagination from 'components/shared/Pagination';
import { Table } from 'react-bootstrap';

function Results() {
  const { emailConfirmations, filters, totalResults, page, searching } = useSelector((state) => state.emailTransmissionReport);
  const dispatch = useDispatch();
  const numRecords = emailConfirmations && Object.keys(emailConfirmations).length;
  const totalPages = Math.ceil(totalResults / 50);

  const setPage = useCallback((p) => {
    dispatch(GET_EMAIL_CONFIRMATIONS.request({ ...filters, page: p }));
  }, [filters, dispatch]);

  if (!emailConfirmations) return null;
  if (numRecords === 0) return <Alert variant="warning">No results.</Alert>;

  return (
    <>
      <p className="text-center text-secondary">
        Showing
        {' '}
        {((page - 1) * numRecords) + 1}
        {' - '}
        {page * numRecords}
        {' '}
        out of
        {' '}
        {totalResults}
        {' '}
        email confirmation log
        entries.
      </p>

      <Pagination
        page={page}
        totalPages={totalPages}
        onPageClick={setPage}
        window={1}
      />

      <div className="position-relative">
        {searching && <Spinner overlay />}

        <Table bordered striped className="sn-table mb-0">
          <thead>
            <tr>
              <th>Date</th>
              <th>Recipient</th>
              <th>Subject</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {emailConfirmations?.map((confirmation) => (
              <tr key={confirmation.id}>
                <td>
                  <small className="text-muted">
                    {format(parseISO(confirmation.createdAt), COMPACT_DATE_TIME)}
                  </small>
                </td>

                <td>
                  {!confirmation.userId && confirmation.to}
                  {confirmation.userId && (
                    <>
                      <Link
                        to={`/registrar/people/${confirmation.userId}/emails`}
                      >
                        {confirmation.firstName}
                        {' '}
                        {confirmation.lastName}
                      </Link>

                      <br />
                      <small>
                        {confirmation.to}
                      </small>
                    </>
                  )}
                </td>

                <td>{confirmation.subject}</td>
                <td>{confirmation.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Pagination
        page={page}
        totalPages={totalPages}
        onPageClick={setPage}
        window={1}
      />
    </>
  );
}

export default Results;
