import React from 'react';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_PHRASEBOOK_PATH, FACILITATOR_COURSE_PHRASEBOOK_PATH } from 'lib/routerPaths';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import SanyasIcon from 'components/shared/SanyasIcon';

const PHRASEBOOK_PATHS = {
  facilitator: FACILITATOR_COURSE_PHRASEBOOK_PATH,
  curriculum: CURRICULUM_COURSE_PHRASEBOOK_PATH,
};

function TaskPageLink() {
  const { id: workspaceId } = useWorkspace();
  const { course } = useCourseShowContext();

  if (!course) return null;

  return (
    <div className="border rounded mt-2 py-3 px-2">
      <a href={buildRoutePath(PHRASEBOOK_PATHS[workspaceId], { courseId: course.id })} className="px-2 d-flex text-decoration-none" target="_blank" rel="noreferrer">
        <SanyasIcon name="chatSquareQuote" className="flex-shrink-0 me-2" />
        <span>
          {'Open the '}
          <span className="text-decoration-underline">Phrasebook</span>
          {' in a new tab'}
        </span>
      </a>
    </div>
  );
}

export default TaskPageLink;
