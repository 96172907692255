/* eslint-disable import/no-import-module-exports */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import '@fortawesome/fontawesome-free/css/all';

// needs to be imported so it can be split out
import {} from 'styles';

import App from 'components/App';
import configureStore, { history } from 'store/configure';

const createApp = () => {
  const store = configureStore();

  const reactComponent = (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  );

  return reactComponent;
};

const renderApp = () => {
  const container = document.getElementById('react-root');

  if (container) {
    ReactDOM.render(createApp(), document.getElementById('react-root'));
  }
};

document.addEventListener('DOMContentLoaded', renderApp);

if (module.hot) {
  module.hot.accept('components/App', () => {
    renderApp();
  });
}
