import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './actions';

const initialState = {
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION.SYNC:
      return {
        ...state,
        list: [
          ...state.list,
          action.payload,
        ],
      };
    case REMOVE_NOTIFICATION.SYNC: {
      const index = state.list.findIndex((notification) => notification.id === action.payload);

      if (index === -1) {
        return state;
      }

      return {
        ...state,
        list: [
          ...state.list.slice(0, index),
          ...state.list.slice(index + 1),
        ],
      };
    }
    default:
      return state;
  }
};
