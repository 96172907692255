import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { CourseTypePropType } from 'lib/propTypes';
import CourseTypeCard from 'components/curriculum/CourseTypeCard';

function CourseTypes(props) {
  const { organizationId, heading, courseTypes, setOrganizationId, setShowCreateModal } = props;
  const organizationCourseTypes = courseTypes.filter((ct) => ct.organizationId === organizationId);
  const coreTraining = organizationCourseTypes.filter((ct) => ct.isCore);
  const postTraining = organizationCourseTypes.filter((ct) => !ct.isCore);

  const handleEditModalOpen = useCallback(() => {
    setOrganizationId(organizationId);
    setShowCreateModal(true);
  }, [organizationId, setOrganizationId, setShowCreateModal]);

  return (organizationCourseTypes.length > 0 && (
    <div className="mb-4">
      <div className="d-flex align-items-center border-bottom border-ui-grey pb-1">
        <h2 className="m-0">
          {heading}
        </h2>
        <Button variant="primary" size="sm" className="ms-auto" onClick={handleEditModalOpen}>
          New course
        </Button>
      </div>

      <h3 className="my-3">Core training</h3>
      <div className="row gy-3">
        {coreTraining.map((ct) => (
          <CourseTypeCard key={ct.id} courseType={ct} />
        ))}
      </div>

      {postTraining.length > 0 && (
        <>
          <h3 className="my-3">Post training</h3>
          <div className="row gy-3">
            {postTraining.map((ct) => (
              <CourseTypeCard key={ct.id} courseType={ct} />
            ))}
          </div>
        </>
      )}
    </div>
  )
  );
}

CourseTypes.defaultProps = {
};

CourseTypes.propTypes = {
  organizationId: PropTypes.number.isRequired,
  heading: PropTypes.string.isRequired,
  courseTypes: PropTypes.arrayOf(CourseTypePropType).isRequired,
  setOrganizationId: PropTypes.func.isRequired,
  setShowCreateModal: PropTypes.func.isRequired,
};

export default CourseTypes;
