import React from 'react';

function File() {
  return (
    <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.16032 1H12.4813C13.033 1 13.5621 1.21071 13.9522 1.58579C14.3424 1.96086 14.5615 2.46957 14.5615 3V13C14.5615 13.5304 14.3424 14.0391 13.9522 14.4142C13.5621 14.7893 13.033 15 12.4813 15H4.16032C3.6086 15 3.07949 14.7893 2.68937 14.4142C2.29925 14.0391 2.08008 13.5304 2.08008 13V3C2.08008 2.46957 2.29925 1.96086 2.68937 1.58579C3.07949 1.21071 3.6086 1 4.16032 1ZM4.16032 2C3.88446 2 3.6199 2.10536 3.42484 2.29289C3.22978 2.48043 3.1202 2.73478 3.1202 3V13C3.1202 13.2652 3.22978 13.5196 3.42484 13.7071C3.6199 13.8946 3.88446 14 4.16032 14H12.4813C12.7571 14 13.0217 13.8946 13.2168 13.7071C13.4118 13.5196 13.5214 13.2652 13.5214 13V3C13.5214 2.73478 13.4118 2.48043 13.2168 2.29289C13.0217 2.10536 12.7571 2 12.4813 2H4.16032Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.68066 10.5C4.68066 10.3674 4.73546 10.2402 4.83299 10.1464C4.93052 10.0527 5.0628 10 5.20072 10H8.32109C8.45901 10 8.59129 10.0527 8.68882 10.1464C8.78635 10.2402 8.84115 10.3674 8.84115 10.5C8.84115 10.6326 8.78635 10.7598 8.68882 10.8536C8.59129 10.9473 8.45901 11 8.32109 11H5.20072C5.0628 11 4.93052 10.9473 4.83299 10.8536C4.73546 10.7598 4.68066 10.6326 4.68066 10.5ZM4.68066 8.5C4.68066 8.36739 4.73546 8.24021 4.83299 8.14645C4.93052 8.05268 5.0628 8 5.20072 8H11.4414C11.5794 8 11.7117 8.05268 11.8092 8.14645C11.9067 8.24021 11.9615 8.36739 11.9615 8.5C11.9615 8.63261 11.9067 8.75979 11.8092 8.85355C11.7117 8.94732 11.5794 9 11.4414 9H5.20072C5.0628 9 4.93052 8.94732 4.83299 8.85355C4.73546 8.75979 4.68066 8.63261 4.68066 8.5ZM4.68066 6.5C4.68066 6.36739 4.73546 6.24021 4.83299 6.14645C4.93052 6.05268 5.0628 6 5.20072 6H11.4414C11.5794 6 11.7117 6.05268 11.8092 6.14645C11.9067 6.24021 11.9615 6.36739 11.9615 6.5C11.9615 6.63261 11.9067 6.75979 11.8092 6.85355C11.7117 6.94732 11.5794 7 11.4414 7H5.20072C5.0628 7 4.93052 6.94732 4.83299 6.85355C4.73546 6.75979 4.68066 6.63261 4.68066 6.5ZM4.68066 4.5C4.68066 4.36739 4.73546 4.24021 4.83299 4.14645C4.93052 4.05268 5.0628 4 5.20072 4H11.4414C11.5794 4 11.7117 4.05268 11.8092 4.14645C11.9067 4.24021 11.9615 4.36739 11.9615 4.5C11.9615 4.63261 11.9067 4.75979 11.8092 4.85355C11.7117 4.94732 11.5794 5 11.4414 5H5.20072C5.0628 5 4.93052 4.94732 4.83299 4.85355C4.73546 4.75979 4.68066 4.63261 4.68066 4.5Z" fill="currentColor" />
    </svg>
  );
}

export default File;
