import React from 'react';
import BaseSkeleton from 'components/shared/Skeleton';

function QuestionSkeleton() {
  return (
    <div className="my-2 border-bottom pb-2">
      <BaseSkeleton classes="title w-25 mb-1" />
      <div className="w-100 border rounded bg-white py-1 px-3">
        <BaseSkeleton classes="text w-100" />
        <BaseSkeleton classes="text w-50" />
      </div>

      <div className="d-flex align-items-center justify-content-end mt-2">
        <BaseSkeleton classes="button w-25" />
      </div>
    </div>
  );
}

export default QuestionSkeleton;
