import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { add, format, formatDistanceToNow, parseISO } from 'date-fns';
import { COMPACT_DATE } from 'lib/dateFormats';
import Button from 'react-bootstrap/Button';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import ReportStateBadge from '../ReportStateBadge';

const REPORT_SUB_TYPES = {
  stats: 'Statistics',
  breakdown: 'Breakdown',
  participant: 'Participant Stats',
};

function FacilitatorReportsListItem({ itemId }) {
  const {
    id,
    filters,
    facilitatorNames,
    courseTypes,
    state: reportState,
    createdAt,
    requestedByUserName,
    reportId,
    durationInSeconds,
  } = useSelector((state) => state.facilitatorReport.reports.byId[itemId]);

  const reportReady = reportState === 'completed';

  return (
    <tr>
      <td>
        <span className="text-secondary font-monospace text-nowrap">
          {reportId}
        </span>
      </td>

      <td>
        <ReportStateBadge reportState={reportState} />
      </td>

      <td>
        <ul className="list-inline">
          <li>
            <small className="text-secondary">Report Type: </small>
            <span className="font-monospace ps-2">{REPORT_SUB_TYPES[filters.reportSubType]}</span>
          </li>

          <li>
            <small className="text-secondary">Report Interval:</small>
            <span className="font-monospace ps-2">
              <ul className="list-inline d-inline-block">
                {filters.reportInterval}
              </ul>
            </span>
          </li>

          <li>
            <small className="text-secondary">Start Date:</small>
            <span className="font-monospace ps-2">{format(parseISO(filters.startDate), COMPACT_DATE)}</span>
          </li>

          <li>
            <small className="text-secondary">End Date:</small>
            <span className="font-monospace ps-2">{format(parseISO(filters.endDate), COMPACT_DATE)}</span>
          </li>

          <li>
            <small className="text-secondary">Facilitators:</small>
            <span className="font-monospace ps-2">{filters.facilitatorIds?.length ? null : 'all'}</span>
            { facilitatorNames && (
              <SimpleToolTip
                id={`facilitators-${reportId}`}
                variant="info"
                placement="top"
                text={(
                  <p className="mb-0 text-start">
                    { facilitatorNames && facilitatorNames.join(', ') }
                  </p>
                )}
              >
                <span className="fas fa-info-circle ps-1" />
              </SimpleToolTip>
            )}
          </li>

          <li>
            <small className="text-secondary">Course Types:</small>
            <span className="font-monospace ps-2">{filters.courseTypeIds?.length ? null : 'all'}</span>
            { courseTypes && (
              <SimpleToolTip
                id={`courseTypes-${reportId}`}
                placement="top"
                variant="info"
                text={(<p className="mb-0 text-start">{ courseTypes.join(', ') }</p>)}
              >
                <span className="fas fa-info-circle" />
              </SimpleToolTip>
            )}
          </li>

          <li>
            <small className="text-secondary">Journals:</small>
            <span className="font-monospace ps-2">{filters.journalEntries?.length ? null : 'all'}</span>
            { filters.journalEntries?.length > 0 && (
              <SimpleToolTip
                id={`journal-${reportId}`}
                placement="top"
                variant="info"
                text={(<p className="mb-0 text-start">{ filters.journalEntries.join(', ') }</p>)}
              >
                <span className="fas fa-info-circle" />
              </SimpleToolTip>
            )}
          </li>
        </ul>
      </td>

      <td>
        <small className="text-secondary">
          {formatDistanceToNow(parseISO(createdAt), { addSuffix: true })}
          <br />
          {` by ${requestedByUserName}`}
        </small>
      </td>

      <td>
        {reportReady && (
          <small className="text-secondary">
            {formatDistanceToNow(add(new Date(), { seconds: durationInSeconds }))}
          </small>
        )}

        {!reportReady && <span className="text-muted">&hellip;</span>}
      </td>

      <td className="text-end">
        <Button
          variant={reportReady ? 'success' : 'light'}
          size="sm"
          disabled={!reportReady}
          className="text-nowrap"
          href={reportReady ? `/api/super_facilitator/reports/facilitator/${id}` : undefined}
          download={reportReady}
        >
          <i className="fa fa-file-csv me-2" />
          Download as CSV
        </Button>
      </td>
    </tr>
  );
}

FacilitatorReportsListItem.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default FacilitatorReportsListItem;
