import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { SIZES } from 'lib/constants';
import { useFieldContext } from 'lib/hooks/useFieldContext';
import { GroupedOptionsPropType, ReactSelectFilterOptionsPropType, StandardOptionsPropType } from 'lib/propTypes';
import ReactSelect, { createFilter } from 'react-select';
import { ReactSelectStyles } from './ReactSelectStyles';

function Select({ name, size, options, placeholder, includeBlank, multiple, required, disabled, loading, formatOptionLabel, filterOptions, innerRef }) {
  const { id, value, touched, error, handleChange, handleBlur } = useFieldContext();

  const derivedValue = useMemo(() => {
    const flatOptions = options.reduce((group, option) => {
      if (option.options) {
        return [...group, ...option.options];
      }
      return [...group, option];
    }, []);

    if (Array.isArray(value) && multiple) {
      return flatOptions.filter((option) => value.includes(option.value));
    }

    return flatOptions.find((option) => option.value === value) || null;
  }, [value, options, multiple]);

  const handleSelectChange = useCallback((selected) => {
    if (multiple) {
      handleChange(selected.map((option) => option.value));
    } else {
      handleChange(selected?.value || '');
    }
  }, [multiple, handleChange]);

  return (
    <ReactSelect
      ref={innerRef}
      key={id}
      inputId={name}
      name={name}
      menuPosition="fixed"
      className={`${(touched && error) ? 'is-invalid' : ''}`}
      aria-invalid={!!(touched && error)}
      styles={ReactSelectStyles(size)}
      value={derivedValue}
      options={options}
      placeholder={placeholder || includeBlank || 'Please select'}
      isDisabled={disabled || loading}
      isLoading={loading}
      onChange={handleSelectChange}
      onBlur={handleBlur}
      isMulti={multiple}
      isClearable={!required}
      isSearchable
      formatOptionLabel={formatOptionLabel}
      filterOption={!_isEmpty(filterOptions) ? createFilter(filterOptions) : undefined}
    />
  );
}

Select.defaultProps = {
  size: SIZES.md,
  options: [],
  placeholder: null,
  includeBlank: null,
  multiple: false,
  required: false,
  disabled: false,
  loading: false,
  formatOptionLabel: null,
  filterOptions: null,
  innerRef: null,
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  options: PropTypes.oneOfType([StandardOptionsPropType, GroupedOptionsPropType]),
  placeholder: PropTypes.string,
  includeBlank: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  filterOptions: ReactSelectFilterOptionsPropType,
  innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
};

export default Select;
