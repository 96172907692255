import { takeLatest, put } from 'redux-saga/effects';
import { fetchGet, handleSimpleFetchError } from 'lib/apiHelpers';

import { GET_PARTICIPANT, GET_EVENT_LOGS } from 'store/actions';

export default function* sagas() {
  yield takeLatest(GET_PARTICIPANT.REQUEST, function* getParticipant({ payload: groupParticipationId }) {
    try {
      const res = yield fetchGet(`/api/registrar/group_memberships/${groupParticipationId}`);
      yield put(GET_PARTICIPANT.success(res.data));

      const emailConfirmations = yield fetchGet(`/api/registrar/group_memberships/${groupParticipationId}/email_confirmations`, {});
      const logEntries = yield fetchGet(`/api/registrar/group_memberships/${groupParticipationId}/log_entries`, {});

      yield put(GET_EVENT_LOGS.success({
        emailConfirmations: Object.values(emailConfirmations.data.emailConfirmations),
        logEntries: Object.values(logEntries.data.logEntries),
      }));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_PARTICIPANT);
    }
  });
}
