import React from 'react';
import PropTypes from 'prop-types';
import { CourseTypePropType } from 'lib/propTypes';
import CourseTypeCard from './CourseTypeCard';

function CourseTypes(props) {
  const { organizationId, heading, courseTypes } = props;
  const organizationCourseTypes = courseTypes.filter((ct) => ct.organizationId === organizationId);
  const coreTraining = organizationCourseTypes.filter((ct) => ct.isCore);
  const postTraining = organizationCourseTypes.filter((ct) => !ct.isCore);

  return (organizationCourseTypes.length > 0 && (
    <div className="mb-4">
      <div className="d-flex align-items-center border-bottom border-ui-grey pb-1">
        <h2 className="m-0">
          {heading}
        </h2>
      </div>

      <h3 className="my-3">Core training</h3>
      <div className="row gy-3">
        {coreTraining.map((ct) => (
          <CourseTypeCard key={ct.id} courseType={ct} />
        ))}
      </div>

      {postTraining.length > 0 && (
        <>
          <h3 className="my-3">Post training</h3>
          <div className="row gy-3">
            {postTraining.map((ct) => (
              <CourseTypeCard key={ct.id} courseType={ct} />
            ))}
          </div>
        </>
      )}
    </div>
  )
  );
}
CourseTypes.defaultProps = {
};
CourseTypes.propTypes = {
  organizationId: PropTypes.number.isRequired,
  heading: PropTypes.string.isRequired,
  courseTypes: PropTypes.arrayOf(CourseTypePropType).isRequired,
};

export default CourseTypes;
