import React from 'react';

function GripHorizontal() {
  return (
    <svg viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.412" cy="1.412" r="1.412" fill="currentColor" />
      <circle cx="1.412" cy="8" r="1.412" fill="currentColor" />
      <circle cx="8" cy="1.412" r="1.412" fill="currentColor" />
      <circle cx="8" cy="8" r="1.412" fill="currentColor" />
      <circle cx="14.588" cy="1.412" r="1.412" fill="currentColor" />
      <circle cx="14.588" cy="8" r="1.412" fill="currentColor" />
    </svg>
  );
}

export default GripHorizontal;
