import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';
import { REDUX_STATUS } from 'lib/constants';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_COURSE_PATH, FACILITATOR_MODULE_PATH, FACILITATOR_TASK_PATH } from 'lib/routerPaths';
import { toLetter } from 'lib/utils';
import { selectCourseTypeIsAvailableInFrench } from 'store/courseTypes/selectors';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import Skeleton from 'components/facilitator/Courses/ModuleSkeleton';

function ModulePage() {
  const { sectionId } = useParams();
  const { course, currentSection, setCurrentSection, setActiveKey } = useCourseShowContext();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { status: courseStatus, sections, tasks } = useSelector((state) => state.courses);
  const isAvailableInFrench = useSelector((state) => selectCourseTypeIsAvailableInFrench(state, course?.courseTypeId));
  const section = sections?.[sectionId];
  const firstSection = sections?.[course?.sectionIds?.[0]];

  const sectionsLoaded = useMemo(
    () => {
      if (!course?.sectionIds?.length) return true;
      return sections && _isEqual(Object.keys(sections).map((s) => Number(s)), course.sectionIds);
    },
    [course?.sectionIds, sections],
  );

  useEffect(() => {
    if (section && Number(sectionId) !== currentSection?.id) {
      setCurrentSection(section);
      setActiveKey(Number(sectionId));
    }
  }, [sectionId, currentSection?.id, section, setCurrentSection, setActiveKey]);

  useEffect(() => {
    // Course Show or New Module
    if (path === FACILITATOR_COURSE_PATH && course?.id && firstSection?.id) {
      history.replace(buildRoutePath(FACILITATOR_MODULE_PATH, { courseId: course?.id, sectionId: firstSection?.id }));
    }
  }, [sectionId, course?.id, firstSection?.id, history, path]);

  if (!course?.id || courseStatus === REDUX_STATUS.PENDING || !sectionsLoaded) {
    return <Skeleton />;
  }

  return (
    <>
      <h2>{currentSection?.name || 'New module'}</h2>
      {isAvailableInFrench && <h3>{currentSection?.nameFr}</h3>}

      <ul className="list-unstyled">
        {currentSection?.taskIds?.map((taskId, index) => {
          const task = tasks[taskId] || {};
          return (
            <li key={taskId} className="list-group-item mb-2">
              <Link className="btn-plain" to={buildRoutePath(FACILITATOR_TASK_PATH, { courseId: course?.id, taskId })}>
                <span>{`${toLetter(index + 1).toUpperCase()}. `}</span>
                {task.name}
                {task.nameFr && ` / ${task.nameFr}`}
              </Link>
              <br />
              <small className="text-muted fw-normal">
                {task.summary}
                {task.summaryFr && ` / ${task.summaryFr}`}
              </small>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default ModulePage;
