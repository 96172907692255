import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

function ReplyArea(props) {
  let textArea = null;

  function onReply(e) {
    e.preventDefault();
    if (textArea.value.length > 0) {
      props.createReply({ message: textArea.value });
    }
  }

  return (
    <div>
      <div className="row row-space-top">
        <div className="col-md-12">
          { props.error && <Alert variant="danger">{props.error}</Alert> }
          <textarea
            className="form-control"
            rows="10"
            placeholder="Type reply here"
            ref={(input) => { textArea = input; }}
          />
        </div>
      </div>
      { !props.submitting
        && (
        <div className="row row-space-top row-space-bottom">
          <div className="col-md-12">
            <button type="button" className="btn btn-primary" onClick={onReply}>Reply</button>
            &nbsp;
            <button type="button" className="btn btn-link" onClick={(e) => { e.preventDefault(); props.onCancel(); }}>Cancel</button>
          </div>
        </div>
        )}
      { props.submitting
        && (
        <div className="row row-space-top row-space-bottom">
          <div className="col-md-12">
            <button type="button" className="btn btn-primary" disabled="disabled">Replying...</button>
          </div>
        </div>
        )}
    </div>
  );
}

ReplyArea.defaultProps = {
  createReply: PropTypes.func,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  error: PropTypes.string,
};

ReplyArea.propTypes = {
  createReply: PropTypes.func,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  error: PropTypes.string,
};

export default ReplyArea;
