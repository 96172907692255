// https://github.com/AlexWebLab/bootstrap-5-breakpoint-react-hook/blob/main/src/useBreakpoint.js
import { useState, useEffect } from 'react';

export const breakpoints = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  XXL: 'xxl',
};

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint());

  const resizeEvent = () => {
    setBreakpoint(getBreakpoint());
  };

  useEffect(() => {
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  return breakpoint;
};

// https://getbootstrap.com/docs/5.3/layout/breakpoints/#available-breakpoints
function getBreakpoint() {
  let breakpoint = null;

  if (typeof window !== 'undefined') {
    if (window.matchMedia('(min-width: 1400px)').matches) {
      breakpoint = breakpoints.XXL;
    } else if (window.matchMedia('(min-width: 1200px)').matches) {
      breakpoint = breakpoints.XL;
    } else if (window.matchMedia('(min-width: 992px)').matches) {
      breakpoint = breakpoints.LG;
    } else if (window.matchMedia('(min-width: 768px)').matches) {
      breakpoint = breakpoints.MD;
    } else if (window.matchMedia('(min-width: 576px)').matches) {
      breakpoint = breakpoints.SM;
    } else {
      breakpoint = breakpoints.XS;
    }
  }

  return breakpoint;
}

export default useBreakpoint;
