import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

function ModuleAccordion({ eventKey }) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <button
      type="button"
      className={`fa fa-fw fa-lg fa-caret-${activeEventKey === eventKey ? 'up' : 'down'}`}
      style={{ backgroundColor: 'transparent', border: 'none' }}
      onClick={decoratedOnClick}
      aria-label="Toggle module"
    />
  );
}

ModuleAccordion.propTypes = {
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ModuleAccordion;
