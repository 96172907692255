import React from 'react';
import PropTypes from 'prop-types';
import { FacilitationSubgroupPropType, GroupParticipationPropType } from 'lib/propTypes';
import { Table } from 'react-bootstrap';
import TableSubGroup from './TableSubGroup';

function ParticipantsTable({ subGroups, groupMemberships, unassignedGroupMemberships, sortDirection, updateFilters }) {
  const handleSort = () => {
    updateFilters((prev) => ({ ...prev, sort: sortDirection === 'asc' ? 'desc' : 'asc' }));
  };

  return (
    <Table bordered hover className="sn-table">
      <thead>
        <tr>
          <th className="col-3" scope="col">
            <button type="button" className="btn fw-semibold text-small p-0" onClick={handleSort}>
              Participant

              <div className="float-start me-2">
                {sortDirection === 'asc' && <span className="fas fa-sort-up" />}
                {sortDirection === 'desc' && <span className="fas fa-sort-down" />}
              </div>
            </button>
          </th>
          <th className="col-3" scope="col">Organization</th>
          <th className="col-3" scope="col">Progress</th>
          <th className="col-2" scope="col">State</th>
          <th className="col-1 text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        {subGroups?.map((sg) => <TableSubGroup key={sg.id} subGroup={sg} groupMemberships={groupMemberships} />)}

        {unassignedGroupMemberships?.length > 0 && (
          <TableSubGroup key="unassigned" groupMemberships={unassignedGroupMemberships} />
        )}
      </tbody>
    </Table>
  );
}

ParticipantsTable.defaultProps = {
  groupMemberships: [],
  unassignedGroupMemberships: [],
  sortDirection: 'asc',
  updateFilters: () => {},
};

ParticipantsTable.propTypes = {
  subGroups: PropTypes.arrayOf(FacilitationSubgroupPropType).isRequired,
  groupMemberships: PropTypes.arrayOf(GroupParticipationPropType),
  unassignedGroupMemberships: PropTypes.arrayOf(GroupParticipationPropType),
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  updateFilters: PropTypes.func,
};

export default ParticipantsTable;
