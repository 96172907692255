import React from 'react';

function BookmarkCheck() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_246_3312)">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.75 3C6.55109 3 6.36032 3.07902 6.21967 3.21967C6.07902 3.36032 6 3.55109 6 3.75V20.349L12 16.3485L18 20.349V12.75C18 12.5511 18.079 12.3603 18.2197 12.2197C18.3603 12.079 18.5511 12 18.75 12C18.9489 12 19.1397 12.079 19.2803 12.2197C19.421 12.3603 19.5 12.5511 19.5 12.75V23.151L12 18.1515L4.5 23.151V3.75C4.5 3.15326 4.73705 2.58097 5.15901 2.15901C5.58097 1.73705 6.15326 1.5 6.75 1.5H12.75C12.9489 1.5 13.1397 1.57902 13.2803 1.71967C13.421 1.86032 13.5 2.05109 13.5 2.25C13.5 2.44891 13.421 2.63968 13.2803 2.78033C13.1397 2.92098 12.9489 3 12.75 3H6.75Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M23.781 3.219C23.8508 3.28867 23.9063 3.37143 23.9441 3.46255C23.9819 3.55367 24.0013 3.65135 24.0013 3.75C24.0013 3.84865 23.9819 3.94633 23.9441 4.03745C23.9063 4.12857 23.8508 4.21133 23.781 4.281L19.281 8.781C19.2113 8.85084 19.1286 8.90626 19.0375 8.94407C18.9463 8.98188 18.8487 9.00134 18.75 9.00134C18.6514 9.00134 18.5537 8.98188 18.4626 8.94407C18.3714 8.90626 18.2887 8.85084 18.219 8.781L15.969 6.531C15.8993 6.46127 15.844 6.37848 15.8062 6.28737C15.7685 6.19626 15.7491 6.09861 15.7491 6C15.7491 5.90138 15.7685 5.80373 15.8062 5.71262C15.844 5.62151 15.8993 5.53873 15.969 5.469C16.0387 5.39927 16.1215 5.34395 16.2126 5.30621C16.3037 5.26847 16.4014 5.24905 16.5 5.24905C16.5986 5.24905 16.6963 5.26847 16.7874 5.30621C16.8785 5.34395 16.9613 5.39927 17.031 5.469L18.75 7.1895L22.719 3.219C22.7887 3.14915 22.8714 3.09374 22.9626 3.05593C23.0537 3.01812 23.1514 2.99866 23.25 2.99866C23.3487 2.99866 23.4463 3.01812 23.5375 3.05593C23.6286 3.09374 23.7113 3.14915 23.781 3.219Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_3312">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BookmarkCheck;
