import React, { useCallback, useMemo, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useFormikContext } from 'formik';
import './styles.scss';

function FileUpload({ name, label, type, multiple, showField }) {
  const { errors, touched, setFieldValue, setFieldTouched } = useFormikContext();

  const [filename, setFilename] = useState('');
  const hiddenFileInput = useRef(null);

  const hasError = useMemo(() => errors[name] && touched[name], [errors, touched, name]);

  const handleClick = useCallback(() => {
    hiddenFileInput.current.click();
    setFieldTouched(name, true);
  }, [setFieldTouched, name]);

  const handleChange = useCallback((event) => {
    if (showField) {
      setFilename(multiple ? event.target.files.map((f) => f.name).join(', ') : event.target.files[0].name);
    }

    if (!showField) {
      setFieldValue(name, multiple ? event.target.files : event.target.files[0]);
    }
  }, [showField, setFieldValue, name, multiple]);

  const button = (
    <button className={`btn btn-${type} file-upload-button`} type="button" id={`fileupload-button-${name}`} onClick={handleClick}>
      choose file
    </button>
  );

  return (
    <>
      <input hidden type="file" id={showField ? name : `fileupload-${name}`} ref={hiddenFileInput} onChange={handleChange} />

      { showField && (
        <div className="input-group mb-3">
          { button }

          <input
            name={`fileinfo-${name}`}
            type="text"
            className="form-control"
            aria-label={label}
            aria-describedby={`fileupload-button-${name}`}
            defaultValue={filename}
            readOnly
          />

          { hasError ? (
            <div className="invalid-feedback d-block text-start">{errors[name]}</div>
          ) : null }
        </div>
      )}

      { !showField && button }
    </>
  );
}

FileUpload.defaultProps = {
  label: 'File upload',
  type: 'secondary',
  multiple: false,
  showField: true,
};

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'link']),
  multiple: PropTypes.bool,
  showField: PropTypes.bool,
};

export default FileUpload;
