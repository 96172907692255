import React from 'react';

function Chat() {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.01275 13.3808C3.12952 13.498 3.219 13.6395 3.27481 13.7953C3.33062 13.951 3.35138 14.1172 3.33562 14.2819C3.25656 15.0442 3.10666 15.7974 2.88787 16.5319C4.45725 16.1685 5.41575 15.7478 5.85113 15.5273C6.09807 15.4022 6.38245 15.3725 6.64987 15.444C7.41649 15.6484 8.20661 15.7513 9 15.75C13.4955 15.75 16.875 12.5921 16.875 9C16.875 5.409 13.4955 2.25 9 2.25C4.5045 2.25 1.125 5.409 1.125 9C1.125 10.6515 1.81912 12.1838 3.01275 13.3808ZM2.45812 17.7739C2.19158 17.8267 1.92416 17.8751 1.656 17.919C1.431 17.955 1.26 17.721 1.34888 17.5117C1.44877 17.2762 1.54032 17.0372 1.62337 16.7951L1.62675 16.7839C1.90575 15.9739 2.133 15.0424 2.21625 14.175C0.835875 12.7913 0 10.98 0 9C0 4.65075 4.02975 1.125 9 1.125C13.9703 1.125 18 4.65075 18 9C18 13.3492 13.9703 16.875 9 16.875C8.10861 16.8762 7.22091 16.7605 6.35962 16.5308C5.77462 16.8266 4.51575 17.3655 2.45812 17.7739V17.7739Z" fill="currentColor" />
    </svg>
  );
}

export default Chat;
