import { takeLatest, put, select, delay, call } from 'redux-saga/effects';
import { fetchGet, fetchPost, fetchPut, handleSimpleFetchError } from 'lib/apiHelpers';
import {
  CREATE_COURSE_TYPE,
  UPDATE_COURSE_TYPE,
  LOAD_COURSE_TYPES,
  GET_COURSE_TYPE_FORM_DATA,
  MERGE_COURSE_TYPE,
  RESET_COURSE_TYPE,
} from 'store/courseTypes/actions';
import { SET_NOTIFICATION } from 'store/flashNotifications/actions';

export default function* sagas() {
  yield takeLatest(LOAD_COURSE_TYPES.REQUEST, function* loadCourseTypes() {
    const state = yield select();
    if (!state.courseTypes.allIds.length) {
      try {
        const { data } = yield fetchGet('/api/shared/course_types', {});
        yield put(LOAD_COURSE_TYPES.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, LOAD_COURSE_TYPES);
      }
    } else {
      yield put(LOAD_COURSE_TYPES.success({}, { cached: true }));
    }
  });

  yield takeLatest(CREATE_COURSE_TYPE.REQUEST, function* createCourseType({ payload }) {
    try {
      const { data } = yield fetchPost('/api/curriculum_developer/course_types', payload);
      yield delay(800);
      yield put(CREATE_COURSE_TYPE.success(data));
      yield put(MERGE_COURSE_TYPE.action());
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COURSE_TYPES);
    }
  });

  yield takeLatest(UPDATE_COURSE_TYPE.REQUEST, function* updateCourseType({ payload, meta }) {
    try {
      const { data } = yield fetchPut(`/api/curriculum_developer/course_types/${payload.id}`, payload.values);
      yield delay(800);
      yield put(UPDATE_COURSE_TYPE.success(data, { cached: false }));
      yield put(SET_NOTIFICATION.action({
        message: `${data.name} updated successfully.`,
        type: 'success',
      }));
      yield put(RESET_COURSE_TYPE.action());
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_COURSE_TYPE);
    } finally {
      yield call(meta.formikActions.setSubmitting, false);
    }
  });

  yield takeLatest(GET_COURSE_TYPE_FORM_DATA.REQUEST, function* getCourseTypeFormData({ payload }) {
    if (payload?.organizationId) {
      try {
        const { data } = yield fetchGet('/api/curriculum_developer/course_types/course_types_form_data', payload);
        yield put(GET_COURSE_TYPE_FORM_DATA.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, LOAD_COURSE_TYPES);
      }
    }
  });
}
