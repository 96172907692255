import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import usePrevious from 'lib/hooks/usePrevious';
import { buildRoutePath } from 'lib/routerHelpers';
import { ARTICLE_EDIT_PATH } from 'lib/routerPaths';
import { selectArticleById } from 'store/courses/selectors';
import { CREATE_COURSE_ARTICLE, DELETE_COURSE_ARTICLE, SAVE_COURSE_ARTICLE } from 'store/courses/actions';
import { selectCourseTypeIsAvailableInFrench } from 'store/courseTypes/selectors';
import { Alert, Button, Nav, Spinner, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import ErrorMessage from 'components/shared/ErrorMessage';
import InputField from 'components/shared/FormFields/InputField';
import RichTextField from 'components/shared/FormFields/RichTextField';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import Confirm from 'components/curriculum/CurriculumEditNav/Confirm';
import Skeleton from './Skeleton';
import ActionsMenu from '../ActionsMenu';
import ArticleKey from './ArticleKey';
import CopyArticleForm from './CopyArticleForm';

const FormSchema = Yup.object().shape({
  nameEn: Yup.string().required('Required'),
  nameFr: Yup.string(),
  descriptionEn: Yup.string(),
  descriptionFr: Yup.string(),
  summaryEn: Yup.string(),
  summaryFr: Yup.string(),
});

function ArticleForm() {
  const { articleId } = useParams();
  const { course, currentArticle, setCurrentArticle } = useCourseShowContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const article = useSelector((state) => selectArticleById(state, articleId));
  const { error: courseError, status: courseStatus, article: activeArticle } = useSelector((state) => state.courses);
  const isAvailableInFrench = useSelector((state) => selectCourseTypeIsAvailableInFrench(state, course?.courseTypeId));
  const prevArticleStatus = usePrevious(activeArticle.status);
  const [key, setKey] = useState('english');
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (article && article?.id !== currentArticle?.id) {
      setCurrentArticle(article);
    }
  }, [article, currentArticle?.id, setCurrentArticle]);

  useEffect(() => {
    if (prevArticleStatus === REDUX_STATUS.PENDING && activeArticle.success === REDUX_SUCCESS.CREATED) {
      history.push(buildRoutePath(ARTICLE_EDIT_PATH, { courseId: course?.id, articleId: activeArticle?.id }));
    }
  }, [prevArticleStatus, activeArticle.success, activeArticle?.id, course?.id, history]);

  useEffect(() => {
    if (prevArticleStatus === REDUX_STATUS.PENDING && activeArticle?.success === REDUX_SUCCESS.DELETED) {
      setShowDeleteModal(false);

      const articleIdsCopy = [...course.articleIds];
      const index = articleIdsCopy.indexOf(Number(articleId));
      articleIdsCopy.splice(index, 1);

      // Keep moving forward through the articleId list
      const nextArticleId = articleIdsCopy[index] || articleIdsCopy[0] || undefined;
      if (nextArticleId) {
        history.push(buildRoutePath(ARTICLE_EDIT_PATH, { courseId: course.id, articleId: nextArticleId }));
      }
    }
  }, [prevArticleStatus, activeArticle?.status, activeArticle?.success, articleId, course?.articleIds, article?.sectionId, course?.id, history]);

  const initialValues = {
    id: article?.id ?? '',
    courseId: article?.courseId || course?.id || '',
    nameEn: article?.nameEn ?? '',
    nameFr: article?.nameFr ?? '',
    summaryEn: article?.summaryEn ?? '',
    summaryFr: article?.summaryFr ?? '',
    descriptionEn: article?.descriptionEn ?? '',
    descriptionFr: article?.descriptionFr ?? '',
    isAvailableInFrench,
  };

  const toggleCopyModal = useCallback(() => {
    setShowCopyModal((prev) => !prev);
  }, []);

  const toggleDeleteModal = useCallback(() => {
    setShowDeleteModal((prev) => !prev);
  }, []);

  const handleDelete = useCallback((resetForm) => () => {
    if (typeof resetForm === 'function') resetForm();
    dispatch(DELETE_COURSE_ARTICLE.request(articleId));
  }, [articleId, dispatch]);

  const handleSubmit = ((values, actions) => {
    if (values.id) {
      dispatch(SAVE_COURSE_ARTICLE.request(values, { formikActions: actions, articleId: values.id }));
    } else {
      dispatch(CREATE_COURSE_ARTICLE.request(values, { formikActions: actions }));
    }
  });

  if (!course?.id || courseStatus === REDUX_STATUS.PENDING) {
    return <Skeleton />;
  }

  return (
    <>
      <ErrorMessage error={courseError || activeArticle.error} className="mt-2" />

      <Formik
        onSubmit={handleSubmit}
        validationSchema={FormSchema}
        enableReinitialize
        initialValues={initialValues}
      >
        {({ errors, dirty, isSubmitting, resetForm }) => (
          <Form key={article?.id ?? 'new'}>
            {dirty && <Confirm />}

            <div className="row">
              <div className={`col-12 d-flex align-items-center ${isAvailableInFrench ? 'border-bottom' : ''}`}>
                {isAvailableInFrench && (
                  <Nav
                    variant="tabs"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    style={{ marginBottom: -1 }}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="english">English</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="french">Français</Nav.Link>
                    </Nav.Item>
                  </Nav>
                )}
                {!isAvailableInFrench && (
                  <h2>
                    {`Edit: ${initialValues.nameEn}`}
                  </h2>
                )}

                <ActionsMenu
                  itemId={article?.id}
                  type="article"
                  courseId={article?.courseId || course?.id}
                  initialValuesId={initialValues.id}
                  dirty={dirty}
                  isSubmitting={isSubmitting}
                  toggleCopyModal={toggleCopyModal}
                  toggleDeleteModal={toggleDeleteModal}
                  isLocked={article?.isLocked}
                />
              </div>

              <div className="col-12">
                <div className={`row ${isAvailableInFrench ? 'mt-2' : 'mt-3'}`}>
                  <div className={`col-xxl-9 ${isAvailableInFrench ? 'my-3' : 'my-1'}`}>
                    <ErrorMessage error={courseError || activeArticle.error} />

                    <TabContainer activeKey={key}>
                      <TabContent>
                        <TabPane eventKey="english">
                          {Object.keys(errors).length > 0 && (
                          <Alert variant="danger" className="py-2">Please review the content for errors.</Alert>
                          )}

                          {Object.keys(errors).filter((k) => k.includes('Fr')).length > 0 && (
                          <Alert variant="danger" className="py-2">Please review the French content for errors.</Alert>
                          )}

                          {isAvailableInFrench && (
                            <h2>
                              {`Edit: ${initialValues.nameEn}`}
                            </h2>
                          )}

                          <InputField label="Name" name="nameEn" required />
                          <InputField label="Summary" name="summaryEn" />

                          <div className="mt-4 p-3 border rounded bg-light">
                            <RichTextField variant="simple" label="Content" name="descriptionEn" />
                          </div>
                        </TabPane>
                      </TabContent>

                      {isAvailableInFrench && (
                        <TabContent>
                          <TabPane eventKey="french">
                            <h2>
                              {`Edit: ${initialValues.nameFr || initialValues.nameEn}`}
                            </h2>

                            <InputField key={articleId} label="Article name" name="nameFr" />
                            <InputField label="Article summary" name="summaryFr" />

                            <div className="mt-4 p-3 border rounded bg-light">
                              <RichTextField variant="simple" label="Content" name="descriptionFr" />
                            </div>
                          </TabPane>
                        </TabContent>
                      )}
                    </TabContainer>
                  </div>

                  <div className="col-xxl-3">
                    <ArticleKey />
                  </div>
                </div>
              </div>
            </div>

            <DefaultModal
              size="md"
              isOpen={showDeleteModal}
              header="Delete article?"
              onClose={() => setShowDeleteModal(false)}
              footerComponent={(
                <Button className="d-flex align-items-center" disabled={activeArticle?.status === REDUX_STATUS.PENDING} variant="danger" onClick={handleDelete(resetForm)} type="submit">
                  Delete
                  {activeArticle?.status === REDUX_STATUS.PENDING && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
                </Button>
              )}
            >
              <p
                className="bg-sn-sand flush top py-1"
              >
                Deleting this article will delete
                {' '}
                <strong>all content</strong>
                {' '}
                within the article as well.
                <br />
                This action cannot be undone.
              </p>

              <p className="d-flex justify-content-between">
                <strong>Confirm</strong>
                <span className="ps-2">Please confirm that you want to delete this article.</span>
              </p>
            </DefaultModal>
          </Form>
        )}
      </Formik>

      <CopyArticleForm
        key={articleId}
        currentArticleId={Number(articleId)}
        showCopyModal={showCopyModal}
        header={`Copy ${article?.nameEn}`}
        setShowCopyModal={setShowCopyModal}
      />
    </>
  );
}

export default ArticleForm;
