import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { COMPACT_DATE_TIME } from 'lib/dateFormats';
import { ErrorPropType, StatusPropType } from 'lib/propTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from 'react-bootstrap';

function LogsTable({ logEntries }) {
  return (
    <>
      {!logEntries?.allIds?.length && (
        <Alert variant="info">
          No log entries recorded for this participant in this group.
        </Alert>
      )}

      {logEntries?.allIds?.length > 0 && (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Description</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            { logEntries.allIds.map((log) => (
              <tr key={log}>
                <td>{logEntries.byId[log].description}</td>
                <td className="text-nowrap">{ format(parseISO(logEntries.byId[log].createdAt), COMPACT_DATE_TIME) }</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}

LogsTable.defaultProps = {
  logEntries: {},
};

LogsTable.propTypes = {
  logEntries: PropTypes.shape({
    status: StatusPropType,
    allIds: PropTypes.arrayOf(PropTypes.number),
    byId: PropTypes.objectOf(PropTypes.shape({
      id: PropTypes.number,
      createdAt: PropTypes.string,
      description: PropTypes.string,
      eventContext: PropTypes.string,
    })),
    error: ErrorPropType,
  }),
};

export default LogsTable;
