import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectArticleById } from 'store/courses/selectors';
import { selectCourseTypeIsAvailableInFrench } from 'store/courseTypes/selectors';
import { Nav, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import ArticleKey from 'components/facilitator/Courses/SelfCareArticleKey';
import Skeleton from './Skeleton';
import ActionsMenu from '../ActionsMenu';

function Article() {
  const { articleId } = useParams();
  const { course, currentArticle, setCurrentArticle } = useCourseShowContext();
  const article = useSelector((state) => selectArticleById(state, articleId));
  const isAvailableInFrench = useSelector((state) => selectCourseTypeIsAvailableInFrench(state, course?.courseTypeId));
  const [key, setKey] = useState('english');

  useEffect(() => {
    if (article && article?.id !== currentArticle?.id) {
      setCurrentArticle(article);
    }
  }, [article, currentArticle?.id, setCurrentArticle]);

  if (!course?.id || !article) {
    return <Skeleton />;
  }

  return (
    <div className="row">
      <div className={`col-12 d-flex align-items-center ${isAvailableInFrench ? 'border-bottom' : ''}`}>
        {isAvailableInFrench && (
          <Nav
            variant="tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            style={{ marginBottom: -1 }}
          >
            <Nav.Item>
              <Nav.Link eventKey="english">English</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="french">Français</Nav.Link>
            </Nav.Item>
          </Nav>
        )}

        {!isAvailableInFrench && (
          <h2>
            {article.nameEn}
            <br />
            <small className="text-secondary">{article?.summaryEn}</small>
          </h2>
        )}

        <ActionsMenu
          itemId={article?.id}
          type="article"
          courseId={article?.courseId || course?.id}
          isLocked={article?.isLocked}
        />
      </div>

      <div className="col-12">
        <div className={`row ${isAvailableInFrench ? 'mt-2' : 'mt-3'}`}>
          <div className={`col-xxl-9 ${isAvailableInFrench ? 'my-3' : 'my-1'}`}>
            <TabContainer activeKey={key}>
              <TabContent>
                <TabPane eventKey="english">
                  {isAvailableInFrench && (
                    <h2>
                      {article.nameEn}
                      <br />
                      <small className="text-secondary">{article.summaryEn}</small>
                    </h2>
                  )}

                  {/* eslint-disable-next-line react/no-danger */}
                  <div className="col-12 mt-3 p-3 border rounded bg-light clearfix" dangerouslySetInnerHTML={{ __html: article.descriptionEn }} />
                </TabPane>
              </TabContent>

              {isAvailableInFrench && (
                <TabContent>
                  <TabPane eventKey="french">
                    <h2>
                      {article.nameFr || article.nameEn}
                      <br />
                      <small className="text-secondary">{article.summaryFr || article.summaryEn}</small>
                    </h2>

                    {/* eslint-disable-next-line react/no-danger */}
                    <div className="col-12 mt-3 p-3 border rounded bg-light clearfix" dangerouslySetInnerHTML={{ __html: article.descriptionFr }} />
                  </TabPane>
                </TabContent>
              )}
            </TabContainer>
          </div>

          <div className="col-xxl-3">
            <ArticleKey articleKey={article.key} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Article;
