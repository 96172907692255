/**
 * A wrapper around the Bootstrap Modal.
 *
 * https://v5.getbootstrap.com/docs/5.0/components/modal/
 */
import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType } from 'lib/propTypes';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function DefaultModal(props) {
  return (
    <Modal
      show={props.isOpen}
      onHide={props.onClose}
      size={props.size}
      onEntered={props.onEntered}
      onExited={props.onExited}
      centered={props.centered}
      scrollable={props.scrollable}
    >
      <Modal.Header closeLabel="Cancel">
        <Modal.Title>{props.header}</Modal.Title>
        <button type="button" className="btn btn-primary btn-plain" onClick={props.onClose}>Cancel</button>
      </Modal.Header>
      <Modal.Body>
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        {props.footerComponent ? props.footerComponent : <Button onClick={props.onSave}>{props.saveButtonText}</Button>}
      </Modal.Footer>
    </Modal>
  );
}

DefaultModal.defaultProps = {
  size: 'md',
  onSave: null,
  saveButtonText: 'Save',
  footerComponent: null,
  onEntered: null,
  onExited: null,
  centered: true,
  scrollable: false,
};

DefaultModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  children: ComponentPropType.isRequired,
  footerComponent: ComponentPropType,
  saveButtonText: PropTypes.string,
  header: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  onEntered: PropTypes.func,
  onExited: PropTypes.func,
  centered: PropTypes.bool,
  scrollable: PropTypes.bool,
};

export default DefaultModal;
