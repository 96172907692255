import React, { useCallback, useState } from 'react';
import SanyasIcon from 'components/shared/SanyasIcon';
import { Button } from 'react-bootstrap';
import { UserPropType } from 'lib/propTypes';
import Confirmation from 'components/shared/Modal/Confirmation';
import { useDispatch } from 'react-redux';
import { ACTIVATION } from 'lib/constants';
import { UPDATE_USER_ACTIVATION } from 'store/user/actions';

const ActivationDetails = {
  [ACTIVATION.ACTIVATED]: {
    icon: 'dashCircle',
    iconColor: 'text-danger',
    action: 'Deactivate',
    text: 'Deactivate participant',
    description: 'This will remove all account access from the participant, but leave all information.',
    description2: ' A participant can easily be reactivated.',
  },
  [ACTIVATION.DEACTIVATED]: {
    icon: 'plusCircle',
    iconColor: '',
    action: 'Activate',
    text: 'Activate participant',
    description: 'This will restore all account access to the participant.',
    description2: '',
  },
};

function Activation({ user }) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { icon, iconColor, action, text, description, description2 } = ActivationDetails[user.activation ?? ACTIVATION.DEACTIVATED];

  const closeModal = useCallback(() => setShowModal(false), []);

  const handleConfirm = useCallback(() => {
    dispatch(UPDATE_USER_ACTIVATION.request({
      id: user.id,
      action: user.activation === 'activated' ? 'deactivate' : 'activate',
    }));
  }, [user.id, user.activation, dispatch]);

  return (
    <>
      <SanyasIcon name={icon} className={`me-2 ${iconColor}`} />
      <span>
        <Button variant="link" className="d-block btn-plain p-0 text-start" onClick={() => setShowModal(true)}>
          {text}
        </Button>
        <span className="d-block text-small text-secondary">
          {description + description2}
        </span>
      </span>

      <Confirmation
        open={showModal}
        close={closeModal}
        title={`${action} account?`}
        text={`Are you sure you want to ${action.toLowerCase()} this account? ${description}`}
        confirmText={`${action} account`}
        confirmAction={handleConfirm}
        cancelText={null}
        delay={800}
      />
    </>
  );
}

Activation.propTypes = {
  user: UserPropType.isRequired,
};

export default Activation;
