import React, { useCallback, useState } from 'react';
import _uniq from 'lodash/uniq';
import { useFormikContext } from 'formik';
import * as yup from 'yup';

const toSentence = (arr) => {
  if (arr.length <= 1) {
    return `${arr[0]}`;
  }
  return `${arr.slice(0, arr.length - 1).join(', ')}, and ${arr.slice(-1)}`;
};

function EmailInput() {
  const { values, errors, setFieldValue, setFieldTouched } = useFormikContext();

  const [emailInputValue, updateEmailInputValue] = useState('');
  const [invalidEmails, setInvalidEmails] = useState([]);

  const validateAndAddEmails = useCallback((vals) => {
    let emailsArray;
    if (vals.includes(',')) {
      emailsArray = vals.split(',').map((e) => e.trim().toLowerCase());
    } else {
      emailsArray = vals.split(' ').map((e) => e.trim().toLowerCase());
    }

    const newValues = _uniq([...values.emails, ...emailsArray]);
    const schema = yup.string().email();

    const validEmails = newValues.filter((e) => schema.isValidSync(e));
    setInvalidEmails(newValues.filter((e) => !schema.isValidSync(e)));

    setFieldValue('emails', validEmails);

    updateEmailInputValue('');
  }, [setFieldValue, values.emails]);

  const handleBlur = useCallback((e) => {
    if (e.target.value.length > 0) {
      validateAndAddEmails(e.target.value);
      setFieldTouched('emails', true, true);
    }
  }, [validateAndAddEmails, setFieldTouched]);

  const handleChange = useCallback((e) => {
    updateEmailInputValue(e.target.value.trim());
  }, [updateEmailInputValue]);

  const handleKeyDown = useCallback((e) => {
    if (e.which === 13) { e.preventDefault(); }
    if (e.which === 13 && e.target.value.length > 0) {
      validateAndAddEmails(e.target.value.trim());
    }
  }, [validateAndAddEmails]);

  const removeEmail = useCallback((e) => {
    setFieldValue('emails', values.emails.filter((v) => v !== e));
  }, [setFieldValue, values.emails]);

  return (
    <>
      <div className="mb-3">
        <label className="form-label" htmlFor="notes">Email Addresses</label>
        <input
          value={emailInputValue}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          className="form-control form-control-lg"
          id="notes"
          name="notes"
          placeholder="Paste one or more email addresses here..."
        />

        { invalidEmails.length > 0
          && (
          <div id="notesFeedback" className="invalid-feedback d-block">
            {`The email(s) ${toSentence(invalidEmails)} are invalid and have been removed.`}
          </div>
          )}
      </div>

      <ul className="list-group">
        { values.emails.length === 0
          && (
          <li className="list-group-item list-group-item-info">
            <i className="fa fa-question-circle me-2" />
            No email addresses yet&hellip;
          </li>
          )}

        { values.emails.map((e) => (
          <li key={e} className="list-group-item d-flex justify-content-between align-items-center">
            {e}
            <button
              onClick={() => removeEmail(e)}
              type="button"
              className="btn-close"
              aria-label="Close"
            />
          </li>
        ))}
      </ul>

      { errors.emails
        && (
        <div id="notesFeedback" className="invalid-feedback d-block">
          {errors.emails}
        </div>
        )}
    </>
  );
}

export default EmailInput;
