import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import _capitalize from 'lodash/capitalize';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { EXTENDABLE_COMPLETION_STATES } from 'lib/constants';
import { FULL_DATE } from 'lib/dateFormats';
import { EXTEND_GROUP } from 'store/groupShow/actions';
import Select from 'react-select';
import { Alert, Button, Spinner } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import DefaultModal from 'components/shared/Modal/DefaultModal';

function gmToLabelValue(gm) {
  return { label: `${gm.firstName} ${gm.lastName}`, value: gm.id };
}

const FormSchema = Yup.object().shape({
  extendedUntil: Yup.date()
    .typeError('Extension date is required')
    .required('Required')
    .test(
      'extension_test',
      'Extension date must be after group close date.',
      (value, context) => {
        const { closeSessionAt } = context.parent;
        return value > new Date(closeSessionAt);
      },
    ),
});

function ExtendModal({ isOpen, closeModal }) {
  const { extendGroupErrorMessage: errorMessage, extendingGroup, group, groupMemberships } = useSelector((state) => state.groupShow);
  const dispatch = useDispatch();

  const groupMembershipArray = Object
    .values(groupMemberships)
    .filter((gm) => !gm.isFacilitator && EXTENDABLE_COMPLETION_STATES.includes(gm.completion))
    .sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName));
  const groupMembershipIds = groupMembershipArray.filter((gm) => gm.hasExtension).map((gm) => gm.id);

  const initialValues = {
    extendedUntil: group.extendedUntil,
    groupMembershipIds,
    closeSessionAt: group.closeSessionAt,
  };

  const handleFormSubmit = (values) => {
    closeModal(true);
    dispatch(EXTEND_GROUP.request(values));
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={FormSchema}
      initialValues={initialValues}
    >
      {({ errors, values, setFieldValue, handleSubmit, setFieldTouched }) => (
        <form>
          <DefaultModal
            isOpen={isOpen}
            onClose={closeModal}
            size="lg"
            header="Extend Group for Participants"
            footerComponent={(
              <div>
                <Button className="btn-plain me-2" variant="link" onClick={closeModal}>Cancel</Button>
                <Button type="submit" variant="primary" disabled={extendingGroup} onClick={(e) => { e.preventDefault(); handleSubmit(); }}>
                  Extend Group
                  {extendingGroup && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
                </Button>
              </div>
            )}
          >
            {(errors.extendedUntil || errorMessage) && (
              <div className="row">
                <div className="col">
                  <Alert variant="danger">
                    {_capitalize(errors.extendedUntil || errorMessage)}
                  </Alert>
                </div>
              </div>
            )}

            <div className="row">
              <p className="col">
                Choose which participants will get an extension to complete the course.
                <br />
                {`This group closes on ${format(parseISO(group.closeSessionAt), FULL_DATE)}.`}
              </p>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="extendedUntil" className="form-label">Extend Group Until</label>
                <Flatpickr
                  name="extendedUntil"
                  value={values.extendedUntil}
                  onChange={(date) => { setFieldValue('extendedUntil', date[0]); setFieldTouched(); }}
                  style={{ backgroundColor: 'white' }}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label htmlFor="groupMembershipIds" className="form-label">Participants</label>
                <Select
                  placeholder="Select participants"
                  isMulti
                  options={[{ label: '- Select All -', value: 'all' }, ...groupMembershipArray.map(gmToLabelValue)]}
                  value={groupMembershipArray.filter((gm) => values.groupMembershipIds.indexOf(gm.id) > -1).map(gmToLabelValue)}
                  onChange={(groupMembership, actionMeta) => {
                    const { action, option } = actionMeta;
                    if (action === 'select-option' && option.value === 'all') {
                      setFieldValue('groupMembershipIds', groupMembershipArray.map((gm) => gm.id));
                    } else {
                      setFieldValue('groupMembershipIds', groupMembership.map((s) => s.value));
                    }
                  }}
                />
              </div>
            </div>

            <div className="row">
              <p className="col">
                Note: It will take up to 30 minutes for the system to pick up this extension and allow the selected participants to log in.
              </p>
            </div>
          </DefaultModal>
        </form>
      )}
    </Formik>
  );
}

ExtendModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ExtendModal;
