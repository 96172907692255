import React from 'react';
import { useSelector } from 'react-redux';

function ProactiveTemplate() {
  const { body } = useSelector((state) => state.courses.proactiveTemplate.data || {});

  return (
    <div className="mt-4">
      <h3 id="proactive_template">Proactive Template</h3>

      <p>
        Proactives are messages that the platform posts on behalf of facilitators before the group starts.
        A proactive message is the first message the participant will see when they get to this discussion board.
        Please edit the proactive template below. The particulars for each facilitator will be filled in with the
        template tags indicated by the curly braces.
      </p>

      <div className="border rounded bg-light p-3" style={{ whiteSpace: 'pre-wrap' }}>
        {body}
      </div>
    </div>
  );
}

export default ProactiveTemplate;
