import { takeLatest, put } from 'redux-saga/effects';
import { fetchPost, handleSimpleFetchError } from 'lib/apiHelpers';
import { CREATE_GROUPS } from 'store/actions';

export default function* sagas() {
  yield takeLatest(CREATE_GROUPS.REQUEST, function* createGroups({ payload: { organizationId, groups } }) {
    // Add the organizationId to each group
    const requestGroups = groups.map((g) => ({ ...g, organizationId }));

    try {
      const group = yield fetchPost('/api/registrar/groups', { groups: requestGroups });
      yield put(CREATE_GROUPS.success(group.data));
    } catch (err) {
      yield handleSimpleFetchError(err, CREATE_GROUPS);
    }
  });
}
