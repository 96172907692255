import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from 'components/shared/Spinner';
import FacilitatorHeader from 'components/facilitator/FacilitatorHeader';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import DiscussionBoardsTable from 'components/facilitator/DiscussionBoardsTable';
import SanyasIcon from 'components/shared/SanyasIcon';

function DiscussionBoardsPage() {
  const { loadingSubGroups } = useSelector((state) => state.facilitatorDashboard);

  return (
    <div>
      <FacilitatorTemplate className="dashboard-page">
        <div className="header flush">
          <span className="d-flex align-items-center">
            <SanyasIcon name="discussion" className="mx-1 me-2 text-accent-purple" size="lg" />
            <h1 className="mb-0 text-accent-purple">Discussion Boards</h1>
          </span>

        </div>

        <FacilitatorHeader />
        {loadingSubGroups ? <Spinner className="mt-3" message="Loading" />
          : (
            <DiscussionBoardsTable />
          )}

      </FacilitatorTemplate>
    </div>
  );
}

export default DiscussionBoardsPage;
