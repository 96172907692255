import React from 'react';
import PropTypes from 'prop-types';
import { LocalePropType, QuestionPropType } from 'lib/propTypes';
import { getLocaleSuffix } from 'lib/utils';

function JournalQuestion({ locale, index, question }) {
  return (
    <div className="journal-question mb-2 border-bottom pb-2">
      <div>
        <span>
          {`${index + 1}. Question`}
        </span>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: question[`body${getLocaleSuffix(locale)}`] }} />
      </div>

      <div className="d-flex align-items-center justify-content-end">
        <span className="text-small">
          {question.answerRequired ? 'Required' : 'Optional'}
        </span>
      </div>
    </div>
  );
}

JournalQuestion.defaultProps = {
  locale: 'en',
};

JournalQuestion.propTypes = {
  locale: LocalePropType,
  index: PropTypes.number.isRequired,
  question: QuestionPropType.isRequired,
};

export default JournalQuestion;
