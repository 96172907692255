// Based on https://v5.reactrouter.com/web/example/query-parameters
import { useMemo } from 'react';
import { useLocation } from 'react-router';

// A custom hook that builds on useLocation to parse
// the query string for you.
// Doesn't handle multiple keys, which should result in an array
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return Object.fromEntries(searchParams.entries());
  }, [search]);
}

export default useQuery;
