import React from 'react';
import PropTypes from 'prop-types';
import { useFieldContext } from 'lib/hooks/useFieldContext';

function Switch({ name, disabled }) {
  const { id, value, handleChange, handleBlur } = useFieldContext();

  return (
    <div className="form-switch">
      <input
        id={id}
        name={name}
        className="form-check-input"
        type="checkbox"
        role="switch"
        value={value}
        checked={value}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
}

Switch.defaultProps = {
  disabled: false,
};

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default Switch;
