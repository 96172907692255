import React from 'react';
import BaseSkeleton from 'components/shared/Skeleton';

function Skeleton() {
  return (
    <>
      <div className="row">
        <div className="col-3">
          <BaseSkeleton classes="title w-25 mb-2" />
          <BaseSkeleton classes="input border w-100 mb-2" />
        </div>
        <div className="col-3">
          <BaseSkeleton classes="title w-25 mb-2" />
          <BaseSkeleton classes="input border w-100 mb-2" />
        </div>
        <div className="col-3">
          <BaseSkeleton classes="title w-25 mb-2" />
          <BaseSkeleton classes="input border w-100 mb-2" />
        </div>
        <div className="col-3">
          <BaseSkeleton classes="title w-25 mb-2" />
          <BaseSkeleton classes="input border w-100 mb-2" />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <BaseSkeleton classes="title w-25 mb-2" />
          <BaseSkeleton classes="input border w-100 mb-2" />
        </div>
        <div className="col-4">
          <BaseSkeleton classes="title w-25 mb-2" />
          <BaseSkeleton classes="input border w-100 mb-2" />
        </div>
        <div className="col-4">
          <BaseSkeleton classes="title w-25 mb-2" />
          <BaseSkeleton classes="input border w-100 mb-2" />
        </div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <BaseSkeleton classes="button" />
        <BaseSkeleton classes="button" />
      </div>
    </>
  );
}

export default Skeleton;
