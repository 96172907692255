import { GET_PTQ_FORM_DATA, GET_PREVIOUS_PTQ_REPORTS, REQUEST_PLATFORM_PTQ } from 'store/actions';

const initialState = {
  loading: true,
  formLoading: true,
  filters: {},
  submitting: false,
  reports: {
    byId: {},
    allIds: [],
  },
  courseTypes: {
    byId: {},
    allIds: [],
  },
  formData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PTQ_FORM_DATA.REQUEST:
      return {
        ...state,
        submitting: true,
        formLoading: true,
      };
    case GET_PTQ_FORM_DATA.SUCCESS: {
      if (action.meta.cached) return { ...state, submitting: false, formLoading: false };

      return {
        ...state,
        formData: action.payload,
        submitting: false,
        formLoading: false,
      };
    }
    case GET_PTQ_FORM_DATA.FAILURE:
      return {
        ...state,
        submitting: false,
        formLoading: false,
      };
    case GET_PREVIOUS_PTQ_REPORTS.SUCCESS:
      return {
        ...state,
        reports: {
          allIds: [...action.payload.reportIds],
          byId: { ...action.payload.reports },
        },
        loading: false,
      };
    case REQUEST_PLATFORM_PTQ.REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case REQUEST_PLATFORM_PTQ.SUCCESS:
      return {
        ...state,
        reports: {
          allIds: [...action.payload.reportIds, ...state.reports.allIds],
          byId: { ...state.reports.byId, ...action.payload.reports },
        },
        submitting: false,
        loading: false,
      };
    case REQUEST_PLATFORM_PTQ.FAILURE:
      return {
        ...state,
        submitting: false,
        loading: false,
      };
    default:
      return state;
  }
};
