import React from 'react';

function GripVertical() {
  return (
    <svg viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.412" cy="1.412" r="1.412" fill="currentColor" />
      <circle cx="8" cy="1.412" r="1.412" fill="currentColor" />
      <circle cx="1.412" cy="8" r="1.412" fill="currentColor" />
      <circle cx="8" cy="8" r="1.412" fill="currentColor" />
      <circle cx="1.412" cy="14.588" r="1.412" fill="currentColor" />
      <circle cx="8" cy="14.588" r="1.412" fill="currentColor" />
    </svg>
  );
}

export default GripVertical;
