import React from 'react';
import BaseSkeleton from 'components/shared/Skeleton';

function Skeleton() {
  return (
    <div className="row">
      <div className="col-lg-10 col-xl-9 ps-4">
        <BaseSkeleton classes="title w-50" />
        <BaseSkeleton classes="text strong w-50" />

        <div className="mt-4">
          <p>
            <BaseSkeleton classes="text w-50" />
            <br />
            <BaseSkeleton classes="text w-50" />
          </p>
          <p>
            <BaseSkeleton classes="text w-50" />
            <br />
            <BaseSkeleton classes="text w-50" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default Skeleton;
