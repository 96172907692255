import { createSelector } from 'reselect';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import { REDUX_STATUS } from 'lib/constants';
import { selectOrganizations } from 'store/organizations/selectors';

export const selectCourseTypesStatus = (state) => state.courseTypes.status;
export const selectCourseTypes = (state) => state.courseTypes.byId;

export const selectCourseTypesLoading = createSelector(
  selectCourseTypesStatus,
  (status) => status === REDUX_STATUS.PENDING,
);

export const selectAvailableCourseTypes = createSelector(
  selectCourseTypes,
  (courseTypes) => _filter(courseTypes, { hideFromCurriculumTab: false }),
);

export const selectCourseTypeById = createSelector(
  [selectCourseTypes,
    (_, id) => id],
  (courseTypes, id) => courseTypes[id],
);

export const selectCourseTypeName = createSelector(
  [selectCourseTypes,
    (_, id) => id],
  (courseTypes, id) => courseTypes[id]?.name,
);

export const selectCourseTypeIsAvailableInFrench = createSelector(
  [selectCourseTypes,
    (_, id) => id],
  (courseTypes, id) => courseTypes[id]?.isAvailableInFrench,
);

export const selectCourseTypesByOrganizationId = createSelector(
  [selectCourseTypes,
    (_, orgId) => orgId],
  (courseTypes, orgId) => Object.values(courseTypes).filter((courseType) => courseType.organizationId === orgId),
);

export const selectCourseTypeOptions = createSelector(
  [selectOrganizations, selectCourseTypes, (_, id) => id],
  (organizations, courseTypes, id) => Object.values(organizations).map((organization) => ({
    label: organization.name,
    options: _sortBy(Object.values(courseTypes), 'name')
      .filter((courseType) => courseType.organizationId === organization.id && courseType.id !== Number(id) && !courseType.hideFromCurriculumTab)
      .map((courseType) => ({
        label: courseType.name,
        value: courseType.id,
        orgShortName: organization.shortName,
      })),
  })),
);
