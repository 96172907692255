import { takeLatest, put, select } from 'redux-saga/effects';
import { fetchGet, handleSimpleFetchError } from 'lib/apiHelpers';
import { GET_CURRENT_USER, LOAD_FACILITATOR_COURSE_TYPES } from 'store/actions';

export default function* sagas() {
  yield takeLatest(GET_CURRENT_USER.REQUEST, function* getCurrentUser() {
    const state = yield select();
    if (!state.currentUser) {
      try {
        const { data } = yield fetchGet('/api/user', {});
        yield put(GET_CURRENT_USER.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, GET_CURRENT_USER);
      }
    } else {
      yield put(GET_CURRENT_USER.success({}, { cached: true }));
    }
  });

  yield takeLatest(LOAD_FACILITATOR_COURSE_TYPES.REQUEST, function* loadCourseTypes() {
    const state = yield select();
    if (!state.courseTypes.allIds.length) {
      try {
        const { data } = yield fetchGet('/api/facilitator/course_types', {});
        yield put(LOAD_FACILITATOR_COURSE_TYPES.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, LOAD_FACILITATOR_COURSE_TYPES);
      }
    } else {
      yield put(LOAD_FACILITATOR_COURSE_TYPES.success({}, { cached: true }));
    }
  });
}
