import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import { ComponentPropType } from 'lib/propTypes';

const ELEMENTS = {
  internal: Link,
  external: 'a',
  action: Button,
  divider: 'span',
  header: 'h6',
  default: 'span',
};

const getElement = (item) => {
  if (item.to) return ELEMENTS.internal;
  if (item.href) return ELEMENTS.external;
  if (item.onClick) return ELEMENTS.action;
  if (item.type) return ELEMENTS[item.type];
  return ELEMENTS.default;
};

const getElementProps = (item) => {
  if (item.to) {
    return {
      to: item.to,
      className: 'btn-plain',
    };
  }

  if (item.href) {
    return {
      href: item.href,
      className: 'btn-plain',
      target: '_blank',
      rel: 'noreferrer',
    };
  }

  if (item.onClick) {
    return {
      onClick: item.onClick,
      variant: 'link',
      className: 'd-block btn-plain p-0 text-start',
    };
  }

  if (item.type === 'header') {
    return {
      className: 'text-small text-secondary',
    };
  }

  return null;
};

function List({ items }) {
  return (
    <>
      {items.map((item) => {
        if (React.isValidElement(item)) return item;

        const Element = getElement(item);
        const elementProps = getElementProps(item);

        return (
          <Fragment key={item.label}>
            {item.type === 'divider' && <Element className="d-inline-block w-100 mb-3 border-top" style={{ height: 1 }} />}
            {item.type !== 'divider' && (
              <li className="d-flex align-items-baseline mb-3 px-3 text-primary">
                {item.icon && <SanyasIcon name={item.icon} className="me-2" />}
                <span>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <Element {...elementProps}>
                    {item.label}
                  </Element>
                  <span className="d-block text-small text-secondary">{item.description}</span>
                </span>
              </li>
            )}
          </Fragment>
        );
      })}
    </>
  );
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.oneOfType(
    [
      PropTypes.shape({
        to: PropTypes.string,
        href: PropTypes.string,
        onClick: PropTypes.func,
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
      ComponentPropType,
    ],
  )).isRequired,
};

export default List;
