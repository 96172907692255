import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FETCH_ALL_REPORTS } from 'store/actions';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import ReportsList from 'components/reporting/ResponsesReport/ReportsList';
import SearchForm from 'components/reporting/ResponsesReport/SearchForm';

function ResponsesReport() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FETCH_ALL_REPORTS.request());

    const id = setInterval(() => {
      dispatch(FETCH_ALL_REPORTS.request());
    }, 15 * 1000);

    return () => clearInterval(id);
  }, [dispatch]);

  return (
    <RegistrarTemplate>
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Reports', href: '/registrar/reports' },
                { label: 'Responses' },
              ]}
            />

            <h1 className="mb-0">Responses Report</h1>
          </div>
        </div>
      </div>

      <div className="flush px-4 my-4">

        <p className="lead">
          Perform a keyword search for responses by facilitators or participants across discussion boards and journal entries.
        </p>

        <p>
          Reports are generated in the background.
          Once a report is requested, this page can be closed and the report will continue generating.
          This page is auto-updating and will display reports as they are in-progress and eventually completed.
          Completed reports can be downloaded in comma-separated value (CSV) format.
        </p>

        <SearchForm />

        <ReportsList />
      </div>
    </RegistrarTemplate>
  );
}

export default ResponsesReport;
