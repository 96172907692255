import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ProactiveTemplatePropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { TASK_EDIT_PATH } from 'lib/routerPaths';

function ProactiveTemplateRow({ template }) {
  const { internalName, taskName, taskSummary, courseId, taskId } = template;
  const history = useHistory();
  const proactiveTemplatePath = `${buildRoutePath(TASK_EDIT_PATH, { courseId, taskId })}#proactive_template`;

  const handleClick = useCallback(() => {
    history.push(proactiveTemplatePath);
  }, [history, proactiveTemplatePath]);

  return (
    <tr onClick={handleClick}>
      <td className="font-weight-semibold">{`${internalName}: ${taskName}`}</td>
      <td>{taskSummary}</td>
      <td>
        <Link className="text-decoration-none" to={proactiveTemplatePath} onClick={(e) => e.stopPropagation()}>
          Edit
        </Link>
      </td>
    </tr>
  );
}

ProactiveTemplateRow.propTypes = {
  template: ProactiveTemplatePropType.isRequired,
};

export default ProactiveTemplateRow;
