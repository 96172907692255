import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import SanyasIcon from 'components/shared/SanyasIcon';

function MediaAndResources() {
  const { taskId } = useParams();
  const mediaResources = useSelector((state) => state.courses.tasks?.[taskId]?.mediaResources);

  if (!taskId || !mediaResources?.length) return null;

  return (
    <div className="border rounded mt-2 py-3 px-2">
      <h6 className="px-2">Media and Resources</h6>

      {mediaResources.map((resource) => (
        <div key={resource.id} className="resource px-1">
          <div className="d-flex p-1">
            <SanyasIcon name={resource.type} className="flex-shrink-0 me-2" />
            <a className="text-decoration-none text-break" href={resource.path} target={resource.type === 'link' ? '_blank' : null} rel="noreferrer">{resource.name}</a>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MediaAndResources;
