import React from 'react';

function HowToUsePhrases() {
  return (
    <>
      <p>
        To add a phrasebook entry to a task, hover your mouse pointer over the list entry and click the Copy button that
        appears.
        <br />
        Alternatively, click the entry to open the phrasebook entry&rsquo;s detail view, then click the Copy button in
        the detail view.
        <br />
        <em>The appropriate code will be copied to your clipboard.</em>
      </p>

      <p>
        Return to the Task and paste the code into the Task content.
      </p>

      <p className="mb-0">The code has the following format:</p>
      <div className="border rounded bg-light px-2 py-1">
        <pre className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>
          [[ &quot;type&quot;: &quot;phrase&quot;, &quot;text&quot;: &quot;Acknowledge&quot;, &quot;key&quot;:
          &quot;acknowledge-differences-similarities&quot;, &quot;width&quot;: &quot;auto&quot; ]]
        </pre>
      </div>
    </>
  );
}

export default HowToUsePhrases;
