import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { REMOVE_NOTIFICATION } from 'store/flashNotifications/actions';
import RBToast from 'react-bootstrap/Toast';
import CloseButton from 'react-bootstrap/CloseButton';
import SanyasIcon from '../SanyasIcon';

const ICONS = {
  error: 'toastError',
  success: 'toastCheck',
};

function FlashNotification({ notification }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(REMOVE_NOTIFICATION.action(notification.id));
  };

  return (
    <RBToast className="position-relative">
      <div className="d-flex align-items-center pe-2">
        <RBToast.Body className="d-flex align-items-center">
          <SanyasIcon name={ICONS[notification.type] || 'infoCircleFill'} className="me-1" size="xl" />
          <span className="ps-1">{notification.message}</span>
        </RBToast.Body>

        {notification.timeout === 0 && (
          <CloseButton variant="white" aria-label="Close" onClick={handleClose} />
        )}
      </div>
    </RBToast>
  );
}

FlashNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string,
    type: PropTypes.string,
    timeout: PropTypes.number,
  }).isRequired,
};

export default FlashNotification;
