import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

function EmailTemplateBadge({ tag: { code, description }, onClick }) {
  return (
    <Button variant="link" size="sm" className="btn-plain p-0 text-black text-start" onClick={onClick} data-code={code}>
      {description}
    </Button>
  );
}

EmailTemplateBadge.propTypes = {
  tag: PropTypes.shape({
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EmailTemplateBadge;
