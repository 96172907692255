import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { QUESTION_TYPES, REDUX_STATUS, REDUX_SUCCESS, TASK_TYPES } from 'lib/constants';
import usePrevious from 'lib/hooks/usePrevious';
import useScrollToLocation from 'lib/hooks/useScrollToLocation';
import { buildRoutePath } from 'lib/routerHelpers';
import { MODULE_EDIT_PATH, TASK_EDIT_PATH } from 'lib/routerPaths';
import { selectCourseTypeIsAvailableInFrench } from 'store/courseTypes/selectors';
import { DELETE_TASK, SAVE_TASK } from 'store/courses/actions';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import { Alert, Button, Nav, Spinner, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import ErrorMessage from 'components/shared/ErrorMessage';
import InputField from 'components/shared/FormFields/InputField';
import RichTextField from 'components/shared/FormFields/RichTextField';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import Confirm from './Confirm';
import ActionsMenu from '../ActionsMenu';
import Skeleton from '../Task/Skeleton';
import JournalTask from '../JournalTask/JournalTask';
import ProactiveTemplate from '../ProactiveTemplate/ProactiveTemplate';
import MediaAndResources from '../MediaAndResources/MediaAndResources';
import CopyTaskForm from '../CopyTaskForm';
import FormBuilder from '../FormBuilder/FormBuilder';
import PhrasebookLink from '../Phrasebook/TaskPageLink';
import GlossaryLink from '../Glossary/TaskPageLink';
import CompletionThreshold from '../CompletionThreshold/CompletionThreshold';

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  nameFr: Yup.string(),
  completionThresholdInSeconds: Yup.number()
    .min(0, 'Must be greater than or equal to 0')
    .required('Required'),
  questions: Yup.array().of(
    Yup.object().shape({
      options: Yup.array().of(Yup.object().shape({}))
        .test(
          'options',
          'At least one option is required',
          (value, context) => {
            const { type: questionType } = context.from[0].value;

            if (questionType === QUESTION_TYPES.MULTIPLE_CHOICE) {
              const options = value?.filter((option) => !option._destroy); // eslint-disable-line no-underscore-dangle
              if (options?.length < 1) {
                return false;
              }
            }

            return true;
          },
        ),
    })
      .test(
        'correct answer',
        'A correct answer is required',
        (value, context) => {
          const { type: taskType } = context.from[1].value;

          if (taskType === TASK_TYPES.MULTIPLE_CHOICE_QUIZ) {
            const options = value.options?.filter((option) => !option._destroy); // eslint-disable-line no-underscore-dangle
            const correctOptions = value.options?.filter((option) => option.correct);
            if (options?.length > 0 && correctOptions?.length === 0) {
              return false;
            }
          }

          return true;
        },
      ),
  ),
});

function EditTaskForm() {
  const { taskId } = useParams();
  const { course, currentSection } = useCourseShowContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: courses, error: courseError, status: courseStatus, sections, tasks, task: activeTask } = useSelector((state) => state.courses);
  const isAvailableInFrench = useSelector((state) => selectCourseTypeIsAvailableInFrench(state, course?.courseTypeId));
  const task = tasks?.[taskId];
  const prevTaskStatus = usePrevious(activeTask.status);
  const [key, setKey] = useState('english');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const firstSectionId = courses[course.id]?.sectionIds[0];
  const section = sections?.[task?.sectionId] || currentSection || sections?.[firstSectionId];
  const { data: questions } = useSelector((state) => state.courses.questions);
  const { data: proactiveTemplate } = useSelector((state) => state.courses.proactiveTemplate);

  useEffect(() => {
    if (prevTaskStatus === REDUX_STATUS.PENDING && activeTask?.success === REDUX_SUCCESS.DELETED) {
      setShowDeleteModal(false);

      const taskIdsCopy = [...section.taskIds];
      const index = taskIdsCopy.indexOf(Number(taskId));
      taskIdsCopy.splice(index, 1);

      // Keep moving forward through the taskId list
      const nextTaskId = taskIdsCopy[index + 1] || taskIdsCopy[0] || undefined;
      if (nextTaskId) {
        history.push(buildRoutePath(TASK_EDIT_PATH, { courseId: course.id, taskId: nextTaskId }));
      } else {
        history.push(buildRoutePath(MODULE_EDIT_PATH, { courseId: course.id, sectionId: task.sectionId }));
      }
    }
  }, [prevTaskStatus, activeTask?.status, activeTask?.success, taskId, section?.taskIds, task?.sectionId, course?.id, history]);

  useScrollToLocation();

  const initialValues = {
    id: task?.id ?? '',
    sectionId: task?.sectionId || currentSection?.id || '',
    name: task?.name ?? '',
    nameFr: task?.nameFr ?? '',
    type: task?.type ?? 'PageTask',
    summary: task?.summary ?? '',
    summaryFr: task?.summaryFr ?? '',
    description: task?.description ?? '',
    descriptionFr: task?.descriptionFr ?? '',
    completionThresholdInSeconds: task?.completionThresholdInSeconds ?? 0,
    isAvailableInFrench,
    isSubTask: task?.isSubTask ?? false,
    silenceJournalNotification: task?.silenceJournalNotification ?? false,
    closing: task?.closing ?? '',
    closingFr: task?.closingFr ?? '',
    questions: questions ?? [],
    proactiveTemplate: {
      body: proactiveTemplate?.body ?? '',
    },
  };

  const toggleCopyModal = useCallback(() => {
    setShowCopyModal((prev) => !prev);
  }, []);

  const toggleDeleteModal = useCallback(() => {
    setShowDeleteModal((prev) => !prev);
  }, []);

  const handleDelete = useCallback((resetForm) => () => {
    if (typeof resetForm === 'function') resetForm();
    dispatch(DELETE_TASK.request(taskId));
  }, [taskId, dispatch]);

  const handleSubmit = ((values, actions) => {
    dispatch(SAVE_TASK.request(values, { formikActions: actions }));
  });

  if (!course?.id || courseStatus === REDUX_STATUS.PENDING) {
    return <Skeleton />;
  }

  return (
    <>
      <ErrorMessage error={courseError || activeTask.error} className="mt-2" />

      <Formik
        onSubmit={handleSubmit}
        validationSchema={FormSchema}
        enableReinitialize
        initialValues={initialValues}
      >
        {({ errors, dirty, isSubmitting, resetForm }) => (
          <Form>
            {dirty && <Confirm />}

            <div className="row">
              <div className={`col-12 d-flex align-items-center ${isAvailableInFrench ? 'border-bottom' : ''}`}>
                {isAvailableInFrench && (
                  <Nav
                    variant="tabs"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    style={{ marginBottom: -1 }}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="english">English</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="french">Français</Nav.Link>
                    </Nav.Item>
                  </Nav>
                )}
                {!isAvailableInFrench && (
                  <h2>
                    {`Edit: ${initialValues.name}`}
                  </h2>
                )}

                <ActionsMenu
                  itemId={task?.id}
                  type="task"
                  initialValuesId={initialValues.id}
                  dirty={dirty}
                  isSubmitting={isSubmitting}
                  toggleCopyModal={toggleCopyModal}
                  toggleDeleteModal={toggleDeleteModal}
                  isLocked={task?.isLocked}
                />
              </div>

              <div className="col-12">
                <div className={`row ${isAvailableInFrench ? 'mt-2' : 'mt-3'}`}>
                  <div className={`col-xxl-9 ${isAvailableInFrench ? 'my-3' : 'my-1'}`}>
                    <ErrorMessage error={courseError || activeTask.error} />

                    <TabContainer activeKey={key}>
                      <TabContent>
                        <TabPane eventKey="english">
                          {Object.keys(errors).length > 0 && (
                          <Alert variant="danger" className="py-2">Please review the content for errors.</Alert>
                          )}

                          {Object.keys(errors).filter((k) => k.includes('Fr')).length > 0 && (
                          <Alert variant="danger" className="py-2">Please review the French content for errors.</Alert>
                          )}

                          {isAvailableInFrench && (
                          <h2>
                            {`Edit: ${initialValues.name}`}
                          </h2>
                          )}

                          <div className="col-12">
                            <InputField label="Task name" name="name" required />
                          </div>
                          <div className="mb-4 col-12">
                            <InputField label="Task description" name="summary" />
                          </div>

                          <div className="col-12 p-3 border rounded bg-light">
                            <RichTextField label="Content" name="description" />
                          </div>

                          {task?.type === TASK_TYPES.JOURNAL && (<JournalTask />)}
                          {task?.type === TASK_TYPES.POD_DISCUSSION && <ProactiveTemplate />}
                          {[TASK_TYPES.SURVEY, TASK_TYPES.MULTIPLE_CHOICE_QUIZ].includes(task?.type) && (<FormBuilder />)}
                        </TabPane>
                      </TabContent>

                      {isAvailableInFrench && (
                        <TabContent>
                          <TabPane eventKey="french">
                            <h2>
                              {`Edit: ${initialValues.nameFr || initialValues.name}`}
                            </h2>

                            <div className="row">
                              <div className="col-12">
                                <InputField key={taskId} label="Task name" name="nameFr" />
                              </div>
                            </div>
                            <div className="mb-4 col-12">
                              <InputField label="Task description" name="summaryFr" />
                            </div>

                            {task && (
                              <div className="col-12 p-3 border rounded bg-light">
                                <RichTextField label="Content" name="descriptionFr" />
                              </div>
                            )}

                            {task?.type === TASK_TYPES.JOURNAL && (<JournalTask locale="fr" />)}
                            {[TASK_TYPES.SURVEY, TASK_TYPES.MULTIPLE_CHOICE_QUIZ].includes(task?.type) && (<FormBuilder locale="fr" />)}
                          </TabPane>
                        </TabContent>
                      )}
                    </TabContainer>
                  </div>

                  <div className="col-xxl-3">
                    <CompletionThreshold seconds={task?.completionThresholdInSeconds} />
                    <MediaAndResources />

                    <GlossaryLink />
                    <PhrasebookLink />
                  </div>
                </div>
              </div>
            </div>

            <DefaultModal
              size="md"
              isOpen={showDeleteModal}
              header="Delete task?"
              onClose={() => setShowDeleteModal(false)}
              footerComponent={(
                <Button className="d-flex align-items-center" disabled={activeTask?.status === REDUX_STATUS.PENDING} variant="danger" onClick={handleDelete(resetForm)} type="submit">
                  Delete
                  {activeTask?.status === REDUX_STATUS.PENDING && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
                </Button>
              )}
            >
              <p
                className="bg-sn-sand flush top py-1"
              >
                Deleting this task will delete
                {' '}
                <strong>all content</strong>
                {' '}
                within the task as well.
                <br />
                This action cannot be undone.
              </p>

              <p className="d-flex justify-content-between">
                <strong>Confirm</strong>
                <span className="ps-2">Please confirm that you want to delete this task.</span>
              </p>
            </DefaultModal>
          </Form>
        )}
      </Formik>

      <CopyTaskForm
        key={taskId}
        currentTaskId={Number(taskId)}
        showCopyModal={showCopyModal}
        header={`Copy ${section?.name} - ${task?.name}`}
        setShowCopyModal={setShowCopyModal}
      />
    </>
  );
}

export default EditTaskForm;
