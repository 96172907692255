import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { REPORTING_HARM_REPORT } from 'lib/routerPaths';
import Alert from 'react-bootstrap/Alert';
import ReportingTemplate from 'components/templates/ReportingTemplate';

function ReportingDashboard() {
  const { isHarmTeamUser, isRegistrar, isSuperAdmin } = useSelector((state) => state.currentUser || {});
  const hasAccess = isHarmTeamUser || isRegistrar || isSuperAdmin;

  return (
    <ReportingTemplate className="dashboard-page">
      <div className="header flush">
        <h1 className="mb-0">Reports</h1>
      </div>

      <div className="flush px-4 my-4">
        {!hasAccess && (
          <Alert variant="info">You do not have access to any reports</Alert>
        )}

        {hasAccess && (
          <div className="d-flex align-items-center mb-3">
            <Link to={REPORTING_HARM_REPORT} className="btn-clean btn-centered btn-clean-wide">
              <i className="icon-md fas fa-clipboard-list" />
              <span className="btn-label">Harm Prevention Report</span>
            </Link>

            <p className="mb-0 ms-3">
              See participant responses that need to be reviewed.
            </p>
          </div>
        )}
      </div>
    </ReportingTemplate>
  );
}

export default ReportingDashboard;
