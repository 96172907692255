import React from 'react';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_GLOSSARY_PATH, FACILITATOR_COURSE_GLOSSARY_PATH } from 'lib/routerPaths';
import { useCourseShowContext } from 'pages/curriculum/CourseShowPage/context';
import SanyasIcon from 'components/shared/SanyasIcon';
import { useWorkspace } from 'lib/hooks/useWorkspace';

const GLOSSARY_PATHS = {
  facilitator: FACILITATOR_COURSE_GLOSSARY_PATH,
  curriculum: CURRICULUM_COURSE_GLOSSARY_PATH,
};

function TaskPageLink() {
  const { id: workspaceId } = useWorkspace();
  const { course } = useCourseShowContext();

  if (!course) return null;

  return (
    <div className="border rounded mt-2 py-3 px-2">
      <a
        href={buildRoutePath(GLOSSARY_PATHS[workspaceId], { courseId: course.id })}
        className="px-2 d-flex text-decoration-none"
        target="_blank"
        rel="noreferrer"
      >
        <SanyasIcon name="bookmarks" className="flex-shrink-0 me-2" />
        <span>
          {'Open the '}
          <span className="text-decoration-underline">Glossary</span>
          {' in a new tab'}
        </span>
      </a>
    </div>
  );
}

export default TaskPageLink;
